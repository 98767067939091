import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { useNavigationState } from './hooks/useNavigationState'
import { MainNavigation } from './MainNavigation'
import * as Parts from './Navigation.parts'
import { WorkspaceNavigation } from './WorkspaceNavigation'
import { WorkspaceNavigationHeader } from './WorkspaceNavigationHeader'

import { NavigationCloseButtonStyles, WorkspaceNavigationTouchHeaderStyle } from './Navigation.css'

type NavigationProps = {}

export const Navigation: React.FC<NavigationProps> = React.memo(function Navigation() {
    const {
        workspaceNavState,
        mainNavState,
        closeMobileNavigation,
        showMobileControls,
        navWrapperRef,
        showWorkspaceNavigationHeader,
        toggleWorkspaceNavigation,
        navigationState,
    } = useNavigationState()

    return (
        <>
            <Parts.NavigationWrapper
                workspaceNavState={workspaceNavState}
                mainNavState={mainNavState}
                state={navigationState}
                ref={navWrapperRef}
            >
                {showWorkspaceNavigationHeader && showMobileControls && (
                    <Box className={WorkspaceNavigationTouchHeaderStyle} noShrink>
                        <WorkspaceNavigationHeader pl={0} pt={0} pr={0} mb={0} width="full" />
                        <Button
                            size="xs"
                            variant="ghost"
                            startIcon={{
                                name: workspaceNavState === 'touch-expanded' ? 'X' : 'Menu',
                            }}
                            aria-label="Toggle workspace navigation"
                            onClick={toggleWorkspaceNavigation}
                        />
                    </Box>
                )}
                <WorkspaceNavigation />
                <MainNavigation />
                {showMobileControls && (
                    <Box
                        position="absolute"
                        top={{ mobile: 'm', tablet: 'l' }}
                        pt="2xs"
                        style={{ right: '-32px' }}
                    >
                        <Button
                            size="xs"
                            variant="ghost"
                            startIcon={{ name: 'PanelLeftClose' }}
                            data-state="active"
                            onClick={closeMobileNavigation}
                            className={NavigationCloseButtonStyles.styleFunction({})}
                        />
                    </Box>
                )}
            </Parts.NavigationWrapper>
        </>
    )
})
