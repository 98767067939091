import React from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import { DeleteTaskPayload, TaskSource } from 'data/hooks/tasks/types'

import { Tooltip } from 'v2/ui'

import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useConfirmDeleteTaskModal } from './useConfirmDeleteTaskModal'

type TaskAdditionalOptionsProps = React.ComponentPropsWithoutRef<typeof Dropdown> &
    DeleteTaskPayload & {
        source: string
        source_type: TaskSource
        onEdit?: () => void
        buttonProps?: Partial<React.ComponentPropsWithoutRef<typeof DropdownButton>>
    }

export const TaskAdditionalOptions: React.FC<TaskAdditionalOptionsProps> = ({
    onEdit,
    buttonProps,
    source,
    source_type,
    ...props
}) => {
    const showConfirmDeleteModal = useConfirmDeleteTaskModal()

    const handleDelete = () => {
        showConfirmDeleteModal(props)
    }

    const { user: currentUser } = useAppUserContext()
    const currentUserId = currentUser?._sid
    const isDeleteAllowed = source_type === TaskSource.User && source === currentUserId

    return (
        <Dropdown {...props}>
            <DropdownButton
                variant="ghost"
                {...buttonProps}
                startIcon={{
                    name: 'MoreVertical',
                }}
            />
            <DropdownContent onClick={stopPropagation} align="end">
                <DropdownItem startIcon={{ name: 'Pencil' }} onClick={onEdit} label="Edit task" />
                <Tooltip
                    label="You can only delete tasks that you have created."
                    disabled={isDeleteAllowed}
                >
                    <DropdownItem
                        startIcon={{ name: 'Trash2' }}
                        onClick={handleDelete}
                        disabled={!isDeleteAllowed}
                        label="Delete task"
                    />
                </Tooltip>
            </DropdownContent>
        </Dropdown>
    )
}
