import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'

import { throttle } from 'lodash'

import { isCmdOrCtrlPressed } from 'utils/isCmdOrCtrlPressed'
import { useIsDeviceTouchOnly } from 'utils/useIsDeviceTouchOnly'
import { useSwipeGesture } from 'utils/useSwipeGesture'

import { useNavigationContext } from './useNavigationContext'

const FLOATING_NAVIGATION_TOGGLE_THRESHOLD = 20
const TOUCH_NAVIGATION_THRESHOLD_X = 200
const TOUCH_NAVIGATION_THRESHOLD_Y = 100
const THROTTLE_TIME = 100

export function useNavigationState() {
    const {
        workspaceNavState,
        mainNavState,
        closeMobileNavigation,
        tryCollapseWorkspaceNav,
        toggleMobileMainNavigation,
        isMobileDevice,
        navigationState,
        toggleNavigationState,
        showNavigationStateControl,
        openMobileNavigation,
    } = useNavigationContext()
    const workspaceNavStateRef = useRef(workspaceNavState)
    workspaceNavStateRef.current = workspaceNavState

    const showMobileControls =
        workspaceNavState === 'touch-expanded' || mainNavState === 'touch-expanded'

    const showWorkspaceNavigationHeader = isMobileDevice && workspaceNavState !== 'hidden'

    const navWrapperRef = useRef<HTMLDivElement>(null)

    const toggleWorkspaceNavigation = useCallback(() => {
        toggleMobileMainNavigation(workspaceNavStateRef.current === 'touch-expanded')
    }, [toggleMobileMainNavigation])

    useLayoutEffect(() => {
        if (
            workspaceNavState !== 'expanded' &&
            !showMobileControls &&
            navigationState === 'static'
        ) {
            return
        }

        // Close on click outside the navigation.
        const handleClickOutside = (event: MouseEvent) => {
            const navWrapper = navWrapperRef.current
            if (!navWrapper) return

            const targetEl = event.target as HTMLElement | null
            if (!targetEl) return

            // If the click target is inside a popup, don't close the nav.

            const isRadixPopperOpen = document.querySelector('[data-radix-popper-content-wrapper]')
            if (isRadixPopperOpen) return

            const isAgGridPopupOpen = document.querySelector('.ag-custom-component-popup')
            if (isAgGridPopupOpen) return

            if (!navWrapper.contains(targetEl)) {
                tryCollapseWorkspaceNav()
                if (navigationState !== 'static') {
                    toggleNavigationState('floating-collapsed')
                }
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [
        navigationState,
        showMobileControls,
        toggleNavigationState,
        tryCollapseWorkspaceNav,
        workspaceNavState,
    ])

    const isTouchDevice = useIsDeviceTouchOnly()
    useLayoutEffect(() => {
        if (isTouchDevice) return
        if (!navigationState.startsWith('floating-')) return

        let previousCloseTimeout = 0
        const scheduleClose = () => {
            previousCloseTimeout = window.setTimeout(() => {
                if (navigationState !== 'floating-expanded') return

                toggleNavigationState('floating-collapsed')
                tryCollapseWorkspaceNav()
            }, THROTTLE_TIME)
        }

        // Collapse floating navigation on mouse move outside the navigation.
        const handleMouseMove = (event: MouseEvent) => {
            // Don't open when dragging.
            if (event.buttons !== 0) return

            if (event.clientX <= FLOATING_NAVIGATION_TOGGLE_THRESHOLD) {
                clearTimeout(previousCloseTimeout)
                if (navigationState === 'floating-collapsed') {
                    toggleNavigationState('floating-expanded')
                }
                return
            }

            const navWrapper = navWrapperRef.current
            if (!navWrapper) return

            const targetEl = event.target as HTMLElement | null
            if (!targetEl) return

            // If there is a popup open, don't close the nav.

            const isRadixPopperOpen = document.querySelector('[data-radix-popper-content-wrapper]')
            if (isRadixPopperOpen) return

            const isAgGridPopupOpen = document.querySelector('.ag-custom-component-popup')
            if (isAgGridPopupOpen) return

            if (!navWrapper.contains(targetEl)) {
                scheduleClose()
            }
        }

        const handleMouseMoveThrottled = throttle(handleMouseMove, THROTTLE_TIME)

        document.addEventListener('mousemove', handleMouseMoveThrottled)
        return () => {
            document.removeEventListener('mousemove', handleMouseMoveThrottled)
        }
    }, [isTouchDevice, navigationState, toggleNavigationState, tryCollapseWorkspaceNav])

    useLayoutEffect(() => {
        if (!showNavigationStateControl) return

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === '/' && isCmdOrCtrlPressed(event)) {
                toggleNavigationState((prev) => {
                    return prev === 'static' ? 'floating-collapsed' : 'static'
                })
                event.preventDefault()
                event.stopPropagation()
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [showNavigationStateControl, toggleNavigationState])

    useSwipeGesture(document.body, {
        onSwipeRight: useCallback(
            (event: TouchEvent, startX: number, startY: number) => {
                // Don't allow swiping on interactive elements.
                const target = event.target as HTMLElement | null
                const closestInteractive = target?.closest('[role="button"], button, a')
                if (closestInteractive) return

                event.preventDefault()
                event.stopPropagation()

                // Don't allow vertical swipes or horizontal swipes greater than the threshold.
                const endY = event.changedTouches[0]?.clientY
                const isVerticalSwipe = Math.abs(startY - endY) > TOUCH_NAVIGATION_THRESHOLD_Y
                if (isVerticalSwipe || startX > TOUCH_NAVIGATION_THRESHOLD_X) return

                if (showNavigationStateControl) {
                    toggleNavigationState('floating-expanded')
                } else {
                    openMobileNavigation()
                }
            },
            [openMobileNavigation, showNavigationStateControl, toggleNavigationState]
        ),
    })

    return useMemo(
        () => ({
            workspaceNavState,
            showMobileControls,
            closeMobileNavigation,
            mainNavState,
            navWrapperRef,
            isMobileDevice,
            showWorkspaceNavigationHeader,
            toggleWorkspaceNavigation,
            navigationState,
        }),
        [
            workspaceNavState,
            showMobileControls,
            closeMobileNavigation,
            mainNavState,
            isMobileDevice,
            showWorkspaceNavigationHeader,
            toggleWorkspaceNavigation,
            navigationState,
        ]
    )
}
