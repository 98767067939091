import { useCallback, useMemo } from 'react'
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'

import { buildQueryKey, queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { updateRecordQuery } from 'data/hooks/records/updateRecordQuery'
import { useRealtimeUpdates } from 'data/realtime/realtimeUpdates'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'

import {
    CreateTaskPayload,
    DeleteTaskPayload,
    GetTasksParams,
    GetTasksPayload,
    TaskPayload,
    TaskSource,
    UpdateTaskPayload,
} from './types'

const LIST_NAME = 'useTasks'
const ENDPOINT = 'tasks/'

export type TaskActions = {
    updateTask: ReturnType<typeof useUpdateTask>['mutateAsync']
    createTask: ReturnType<typeof useCreateTask>['mutateAsync']
    deleteTask: ReturnType<typeof useDeleteTask>['mutateAsync']
}

function useQueryKey(params?: GetTasksParams) {
    const deps: unknown[] = [LIST_NAME]
    if (params) {
        deps.push(params)
    }

    return useQueryKeyBuilder(deps, { includeAuthKeys: true }) as string[]
}

function getQueryKey(params?: GetTasksParams) {
    const deps: unknown[] = [LIST_NAME]
    if (params) {
        deps.push(params)
    }

    return buildQueryKey(deps, { includeAuthKeys: true }) as string[]
}

type TaskOperation = 'complete' | 'reassign'

function getEndpoint(taskId?: number, operation?: TaskOperation) {
    if (taskId && operation) {
        return `${ENDPOINT}${taskId}/${operation}/`
    }

    if (taskId) {
        return `${ENDPOINT}${taskId}/`
    }

    return `${ENDPOINT}`
}

export function useTasks(params?: GetTasksParams, options: UseQueryOptions<GetTasksPayload> = {}) {
    const queryKey = useQueryKey(params)
    const endpoint = getEndpoint()
    const url = buildUrl(endpoint, params, true, false)
    const query = useQuery<GetTasksPayload>(
        queryKey,
        async () => {
            const results = (await fetchAndReturn(url, undefined, undefined, undefined, {
                bypassPreviewAs: true,
            })) as GetTasksPayload
            return results
        },
        // We want to refetch on mount because that ensures that
        // if tasks changed while we were unmounted, but we have the
        // query cached, we will get the latest tasks
        { refetchOnMount: 'always', ...options }
    )
    const refetchCallback = useCallback(() => () => query.refetch(), [query])

    const { mutateAsync: updateTask } = useUpdateTask(undefined, queryKey)
    const { mutateAsync: createTask } = useCreateTask(undefined, queryKey, params)
    const { mutateAsync: deleteTask } = useDeleteTask(undefined, queryKey)

    useRealtimeUpdates({ channel: 'tasks', handler: refetchCallback })

    const actions = useMemo<TaskActions>(
        () => ({
            updateTask,
            createTask,
            deleteTask,
        }),
        [createTask, updateTask, deleteTask]
    )
    return {
        ...query,
        actions,
    }
}

export function useCreateTask(
    options: UseMutationOptions<unknown, unknown, CreateTaskPayload> = {},
    suppliedQueryKey?: QueryKey,
    getParams?: GetTasksParams
) {
    const queryKey = suppliedQueryKey ?? getQueryKey()
    return useMutation(
        async (payload: CreateTaskPayload) => {
            const endpoint = getEndpoint()

            await fetchAndReturn(
                endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )
        },
        {
            ...options,
            onMutate: async (payload: CreateTaskPayload) => {
                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<GetTasksPayload>(queryKey)

                const shouldDoOptimisticUpdate = getParams
                    ? createdTaskMatchesGetFilters(payload, getParams)
                    : true
                if (shouldDoOptimisticUpdate) {
                    const updatedPayload: GetTasksPayload = {
                        ...previousPayload,
                        tasks: previousPayload?.tasks.slice() ?? [],
                    }

                    const now = new Date()

                    updatedPayload.tasks.push({
                        auto_id: now.getTime(),
                        title: payload.title,
                        description: payload.description ?? '',
                        created_at: now.toISOString(),
                        completed_at: null,
                        is_completed: false,
                        attachments: payload.attachments ?? [],
                        mentions: [],
                        due_at: payload.due_at || null,
                        assignees: payload.assignees,
                        source: payload.userId,
                        source_type: TaskSource.User,
                        related_to: payload.related_to || null,
                        related_to_type: payload.related_to_type || null,
                        related_to_location: payload.related_to_location || null,
                        related_to_stack: payload.related_to_stack || null,
                        parent_task_id: payload.parent_task_id || null,
                        display_order: 1,
                        _last_comment_at: null,
                        _comment_count: 0,
                    })

                    queryClient.setQueryData(queryKey, updatedPayload)
                }

                return { previousPayload }
            },
            onError: (err, payload, context?: { previousPayload?: GetTasksPayload }) => {
                queryClient.setQueryData(queryKey, context?.previousPayload)
                options?.onError?.(err, payload, context)
            },
            onSettled: (_data, error, task) => {
                // Invalidate all tasks queries
                queryClient.invalidateQueries([LIST_NAME])

                // If this is related to a record, update the record's task count in the caches
                if (!error && task.related_to && task._object_id) {
                    updateRecordQuery(
                        [
                            {
                                _sid: task.related_to,
                                _object_id: task._object_id,
                            } as RecordDto,
                        ],
                        true,
                        undefined,
                        (old) => ({
                            ...old,
                            _task_count: (old._task_count || 0) + 1,
                        })
                    )
                }
            },
        }
    )
}

function updateTaskInList(
    tasks: TaskPayload[],
    taskId: number,
    updateFn: (oldPayload: TaskPayload) => TaskPayload
) {
    const taskIndex = tasks.findIndex((task) => task.auto_id === taskId)
    if (taskIndex > -1) {
        tasks[taskIndex] = updateFn(tasks[taskIndex])
    }
}

export function useUpdateTask(
    options: UseMutationOptions<unknown, unknown, UpdateTaskPayload> = {},
    suppliedQueryKey?: QueryKey
) {
    const queryKey = suppliedQueryKey ?? getQueryKey()
    return useMutation(
        async (payload: UpdateTaskPayload) => {
            const { taskId, _object_id, ...updatedTask } = payload

            const endpoint = getEndpoint(taskId)

            return (await fetchAndReturn(
                endpoint,
                {
                    method: 'PATCH',
                    body: JSON.stringify(updatedTask),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )) as TaskPayload
        },
        {
            ...options,
            onMutate: async ({ taskId, ...updatedTask }: UpdateTaskPayload) => {
                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<GetTasksPayload>(queryKey)

                const updatedPayload: GetTasksPayload = {
                    ...previousPayload,
                    tasks: previousPayload?.tasks.slice() ?? [],
                }

                updateTaskInList(updatedPayload.tasks, taskId, (oldPayload) => {
                    return {
                        ...oldPayload,
                        ...updatedTask,
                    }
                })

                queryClient.setQueryData(queryKey, updatedPayload)

                return { previousPayload }
            },
            onError: (err, payload, context?: { previousPayload?: GetTasksPayload }) => {
                queryClient.setQueryData(queryKey, context?.previousPayload)

                options?.onError?.(err, payload, context)
            },
            onSettled: (task: TaskPayload | undefined, error, args) => {
                // Invalidate all tasks queries
                queryClient.invalidateQueries([LIST_NAME])

                // If completed state is changing and this is related to a record
                // update the record's completed task count in the caches
                if (
                    !error &&
                    args.is_completed !== undefined &&
                    task &&
                    task.related_to &&
                    args._object_id
                ) {
                    const increment = args.is_completed ? 1 : -1
                    updateRecordQuery(
                        [
                            {
                                _sid: task.related_to,
                                _object_id: args._object_id,
                            } as RecordDto,
                        ],
                        true,
                        undefined,
                        (old) => ({
                            ...old,
                            _task_completed_count: (old._task_completed_count || 0) + increment,
                        })
                    )
                }
            },
        }
    )
}

export function useDeleteTask(
    options: UseMutationOptions<unknown, unknown, DeleteTaskPayload> = {},
    suppliedQueryKey?: QueryKey
) {
    const queryKey = suppliedQueryKey ?? getQueryKey()
    return useMutation(
        async (payload: DeleteTaskPayload) => {
            const { taskId } = payload

            const endpoint = getEndpoint(taskId)

            return fetchAndReturn(endpoint, { method: 'DELETE' }, undefined, undefined, {
                bypassPreviewAs: true,
            })
        },
        {
            ...options,
            onMutate: async ({ taskId }: DeleteTaskPayload) => {
                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<GetTasksPayload>(queryKey)

                const updatedPayload: GetTasksPayload = {
                    ...previousPayload,
                    tasks: previousPayload?.tasks.slice() ?? [],
                }

                updatedPayload.tasks = updatedPayload.tasks.filter(
                    (task) => task.auto_id !== taskId
                )

                queryClient.setQueryData(queryKey, updatedPayload)

                return { previousPayload }
            },
            onError: (err, payload, context?: { previousPayload?: GetTasksPayload }) => {
                queryClient.setQueryData(queryKey, context?.previousPayload)

                options?.onError?.(err, payload, context)
            },
            onSettled: (task, error, args: DeleteTaskPayload) => {
                // Invalidate all tasks queries
                queryClient.invalidateQueries([LIST_NAME])

                if (!error && args.related_to && args._object_id) {
                    const completedIncrement = args.is_completed ? -1 : 0
                    updateRecordQuery(
                        [
                            {
                                _sid: args.related_to,
                                _object_id: args._object_id,
                            } as RecordDto,
                        ],
                        true,
                        undefined,
                        (old) => ({
                            ...old,
                            _task_count: (old._task_count || 0) - 1,
                            _task_completed_count:
                                (old._task_completed_count || 0) + completedIncrement,
                        })
                    )
                }
            },
        }
    )
}

export function updateTaskQuery(
    taskId: number,
    updateFn: (cachedTask: TaskPayload) => TaskPayload
) {
    const key = [LIST_NAME]
    const previousPayloads = queryClient.getQueriesData<GetTasksPayload>(key)

    for (const [queryKey, previousPayload] of previousPayloads) {
        const updatedPayload: GetTasksPayload = {
            ...previousPayload,
            tasks: previousPayload?.tasks.slice() ?? [],
        }

        updateTaskInList(updatedPayload.tasks, taskId, updateFn)

        queryClient.setQueryData(queryKey, updatedPayload)
    }
}

function createdTaskMatchesGetFilters(task: CreateTaskPayload, params: GetTasksParams): boolean {
    if (params.related_to && task.related_to !== params.related_to) {
        return false
    }

    if (params.created_by && task.userId !== params.created_by) {
        return false
    }

    const taskAssignees = new Set(task.assignees)
    if (params.assignees && !params.assignees.some((assignee) => taskAssignees.has(assignee))) {
        return false
    }

    return true
}
