import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'
import { DropdownAttributeTag } from 'features/views/attributes/DropdownAttributeTag'

import { useDropdownAttributeState } from './hooks/useDropdownAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { CardViewAttributeComponent } from './types'

const MAX_OPTIONS = 2
const MAX_OPTIONS_TITLE = 1
const MAX_OPTIONS_TAG = 3
const MAX_ITEM_LENGTH = 10
const MAX_ITEM_LENGTH_SINGLE = 30
const MAX_LINES = 1

type DropdownAttributeProps = {}

export const DropdownAttribute: CardViewAttributeComponent<
    string | string[],
    DropdownAttributeProps
> = ({ value, attribute, isLoading, displayStyle, showIcon, ...props }) => {
    const { field, type } = attribute

    const isTitle = type === 'title'
    const isFooter = type === 'footer'

    const { isEmpty, isSingle, size } = useDropdownAttributeState({ value, isLoading, attribute })

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayStyle === 'tags' || isFooter ? (
                <DropdownAttributeTag
                    size={size}
                    value={value}
                    field={field}
                    isLoading={isLoading}
                    maxItemLength={isSingle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    maxOptions={MAX_OPTIONS_TAG}
                    minWidth={isFooter ? 0 : undefined}
                    showIcon={showIcon}
                />
            ) : (
                <DropdownAttributeDisplay
                    size={size}
                    value={value}
                    field={field}
                    maxOptions={isTitle ? MAX_OPTIONS_TITLE : MAX_OPTIONS}
                    maxItemLength={isTitle ? MAX_ITEM_LENGTH : undefined}
                    maxLines={MAX_LINES}
                    isLoading={isLoading}
                />
            )}
        </BaseAttribute>
    )
}
