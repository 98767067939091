import React from 'react'

import { Box } from 'ui/components/Box'
import { theme } from 'ui/styling/Theme.css'

import { useMainNavigationState } from './hooks/useMainNavigationState'
import { AppNavigation } from './AppNavigation'
import * as Parts from './Navigation.parts'
import { NavigationCurrentUserDropdown } from './NavigationCurrentUserDropdown'
import { NavigationHelpAndSupport } from './NavigationHelpAndSupport'
import { NavigationStateControl } from './NavigationStateControl'
import { NavigationTrialBanner } from './NavigationTrialBanner'
import { PortalNavigation } from './PortalNavigation'

type MainNavigationProps = {}

export const MainNavigation: React.FC<MainNavigationProps> = () => {
    const {
        isNavHidden,
        isPortal,
        showTrialBanner,
        showHelpAndSupport,
        isWorkspaceNavHidden,
        isMobileDevice,
        showNavigationStateControl,
    } = useMainNavigationState()

    if (isNavHidden) return null

    return (
        <Parts.MainNavigationWrapper isMobileDevice={isMobileDevice}>
            {isPortal ? <PortalNavigation /> : <AppNavigation />}
            {showTrialBanner && (
                <Box px="xs" noShrink>
                    <NavigationTrialBanner py="m" px="l" />
                </Box>
            )}
            {(showNavigationStateControl || showHelpAndSupport) && (
                <Box
                    flex
                    column
                    noShrink
                    gap="2xs"
                    py="m"
                    style={{
                        minHeight: theme.avatar.size.l,
                        zIndex: 200,
                    }}
                >
                    {showNavigationStateControl && <NavigationStateControl />}
                    {showHelpAndSupport && <NavigationHelpAndSupport />}
                </Box>
            )}
            {isWorkspaceNavHidden && <NavigationCurrentUserDropdown background="transparent" />}
        </Parts.MainNavigationWrapper>
    )
}
