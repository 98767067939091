import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useVirtual } from 'react-virtual'

import { icons as lucideIcons } from 'lucide-react'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { iconNames as hugeIcons } from 'ui/components/Icon/hugeicons'
import { IconName } from 'ui/components/Icon/Icon'
import { ScrollArea } from 'ui/components/ScrollArea'
import { Body } from 'ui/components/Text'

import { iconKeywords as hugeIconsKeywords } from './hugeicons_keywords'
import * as Parts from './IconPicker.parts'
import { iconKeywords as lucideKeywords } from './lucide_keywords'

import { IconPickerRowStyles, IconPickerScrollAreaStyles } from './IconPicker.css'

const SEARCH_DEBOUNCE_RATE = 200
const ICON_SIZE = 24
const ICON_GAP = 2

const LUCIDE_ICONS = Object.keys(lucideIcons) as IconName[]
const HUGE_ICONS = hugeIcons as unknown as IconName<'hugeicons'>[]

const LUCIDE_ICONS_KEYWORDS = lucideKeywords as unknown as IconKeywords<'lucide'>
const HUGE_ICONS_KEYWORDS = hugeIconsKeywords as unknown as IconKeywords<'hugeicons'>

export type IconVariants = 'lucide' | 'hugeicons'

type IconValue<V extends IconVariants> = IconName<V>

type IconKeywords<V extends IconVariants> = Record<IconName<V>, string>

type IconPickerRef = HTMLDivElement

type IconPickerProps<V extends IconVariants> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Container>,
    'asChild' | 'value' | 'defaultValue' | 'onChange' | 'onValueChange'
> & {
    variant?: V
    isClearable?: boolean
    value?: IconValue<V> | null
    defaultValue?: IconValue<V> | null
    onChange?: (value?: IconValue<V>) => void
    columnCount?: number
}

const IconPickerInner = <V extends IconVariants>(
    {
        variant = 'lucide' as V,
        value: providedValue,
        defaultValue,
        onChange,
        isClearable,
        columnCount = 10,
        style,
        ...props
    }: IconPickerProps<V>,
    ref: React.ForwardedRef<IconPickerRef>
) => {
    const options = (variant === 'lucide' ? LUCIDE_ICONS : HUGE_ICONS) as IconName<V>[]
    const keywords = (
        variant === 'lucide' ? LUCIDE_ICONS_KEYWORDS : HUGE_ICONS_KEYWORDS
    ) as IconKeywords<V>

    const isControlled = typeof providedValue !== 'undefined'
    const [value, setValue] = useState<IconValue<V> | null>(providedValue || defaultValue || null)
    useEffect(() => {
        setValue(providedValue || defaultValue || null)
    }, [providedValue, defaultValue])

    const handleChange = useCallback(
        (newValue: string | null) => {
            if (!isControlled) {
                setValue(newValue as IconValue<V>)
            }
            onChange?.(newValue as IconValue<V>)
        },
        [onChange, isControlled]
    )

    const onClear = useCallback(() => {
        handleChange(null)
    }, [handleChange])

    const [normalizedSearchQuery, setNormalizedSearchQuery] = useState('')
    const filteredOptions = useDeepEqualsMemoValue(
        searchIconName(normalizedSearchQuery, options, keywords)
    )
    const filteredOptionsRef = useRef(filteredOptions)
    filteredOptionsRef.current = filteredOptions

    const onSearchChange = useCallback((query: string) => {
        requestAnimationFrame(() => {
            setNormalizedSearchQuery(query.trim().toLowerCase())
        })
    }, [])

    const onRandomize = useCallback(() => {
        const options = filteredOptionsRef.current

        const randomIndex = Math.floor(Math.random() * options.length)
        handleChange(options[randomIndex])
    }, [handleChange])

    const hasNoSearchOptions = !!normalizedSearchQuery && filteredOptions.length < 1

    const width = columnCount * (ICON_SIZE + ICON_GAP)

    return (
        <Parts.Container ref={ref} style={{ ...style, width: `${width}px` }} {...props}>
            <Parts.Header noShrink>
                <Box grow>
                    <SearchInput onChange={onSearchChange} />
                </Box>
                <Box>
                    <Button
                        size="2xs"
                        variant="secondary"
                        startIcon={{ name: 'Shuffle' }}
                        onClick={onRandomize}
                        aria-label="Pick a random icon"
                        disabled={hasNoSearchOptions}
                    />
                </Box>
            </Parts.Header>
            {hasNoSearchOptions ? (
                <Box grow flex center justifyContent="center" p="m">
                    <Body size="s" weight="regular" color="textHelper">
                        No icons match your search query
                    </Body>
                </Box>
            ) : (
                <Picker
                    variant={variant}
                    value={value as string}
                    onValueChange={handleChange}
                    options={filteredOptions as IconName<V>[]}
                    grow
                    columnCount={columnCount}
                />
            )}
            {!!value && isClearable && (
                <Box flex center noShrink>
                    <Button variant="secondary" size="s" width="full" onClick={onClear}>
                        Clear
                    </Button>
                </Box>
            )}
        </Parts.Container>
    )
}

export const IconPicker = forwardRef(IconPickerInner) as <V extends IconVariants = 'lucide'>(
    props: IconPickerProps<V> & { ref?: React.ForwardedRef<IconPickerRef> }
) => ReturnType<typeof IconPickerInner>

type PickerProps<V extends IconVariants> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Picker>,
    'asChild'
> & {
    variant: V
    options: IconName<V>[]
    columnCount: number
}

const Picker = <V extends IconVariants>({
    variant,
    options,
    value,
    columnCount,
    ...props
}: PickerProps<V>) => {
    const scrollAreaRef = useRef<HTMLDivElement>(null)

    // Add 2px to the icon size to account for the gap between the icons.
    const estimateSize = useCallback(() => ICON_SIZE + ICON_GAP, [])

    const rowVirtualizer = useVirtual({
        parentRef: scrollAreaRef,
        size: Math.ceil(options.length / columnCount),
        estimateSize,
        overscan: 2,
    })
    const scrollToIndexRef = useRef(rowVirtualizer.scrollToIndex)
    scrollToIndexRef.current = rowVirtualizer.scrollToIndex

    const activeIndex = options.findIndex((option) => option === value)
    const activeRowIndex = activeIndex > -1 ? Math.floor(activeIndex / columnCount) : -1

    useEffect(() => {
        // Scroll into view if the item is active and not in the viewport.
        if (activeRowIndex !== -1) {
            scrollToIndexRef.current(activeRowIndex, {
                align: 'auto',
            })
        }
    }, [activeRowIndex])

    return (
        <ScrollArea
            ref={scrollAreaRef}
            width="full"
            height="full"
            direction="vertical"
            rootProps={{
                width: 'full',
                height: 'full',
                className: IconPickerScrollAreaStyles.styleFunction({}),
            }}
            type="scroll"
        >
            <Parts.Picker
                {...props}
                value={value}
                asChild={false}
                style={{
                    height: `${rowVirtualizer.totalSize}px`,
                    width: '100%',
                    position: 'relative',
                }}
            >
                {rowVirtualizer.virtualItems.map((rowItem) => {
                    const columnItems = options.slice(
                        rowItem.index * columnCount,
                        (rowItem.index + 1) * columnCount
                    )

                    return (
                        <Box
                            key={rowItem.key}
                            className={IconPickerRowStyles.styleFunction({})}
                            ref={rowItem.measureRef}
                            style={{
                                height: `${rowItem.size}px`,
                                transform: `translateY(${rowItem.start}px)`,
                            }}
                        >
                            {columnItems.map((columnItem) => {
                                return (
                                    <PickerItem
                                        key={columnItem}
                                        variant={variant}
                                        value={columnItem}
                                    />
                                )
                            })}
                        </Box>
                    )
                })}
            </Parts.Picker>
        </ScrollArea>
    )
}

type PickerItemRef = HTMLButtonElement

type PickerItemProps<V extends IconVariants> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.PickerItem>,
    'asChild'
> & {
    variant: V
    value: IconValue<V>
}

const PickerItemInner = <V extends IconVariants>(
    { variant, value, ...props }: PickerItemProps<V>,
    ref: React.ForwardedRef<PickerItemRef>
) => {
    return (
        <Parts.PickerItem {...props} value={value} ref={ref}>
            <Parts.Icon size="m" name={value} family={variant} />
        </Parts.PickerItem>
    )
}

const PickerItem = React.memo(forwardRef(PickerItemInner)) as <V extends IconVariants>(
    props: PickerItemProps<V> & { ref?: React.ForwardedRef<PickerItemRef> }
) => ReturnType<typeof PickerItemInner>

type SearchInputProps = {
    onChange: (newValue: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({ onChange: providedOnChange, ...props }) => {
    const [searchQuery, setSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(providedOnChange, SEARCH_DEBOUNCE_RATE)

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setSearchQuery(value)
            applyDebouncedSearchQuery(value)
        },
        [applyDebouncedSearchQuery]
    )

    return (
        <Parts.SearchInput
            variant="borderless"
            placeholder="Filter icons..."
            inputProps={{
                className: 'ignore-ios-zoom',
            }}
            size="m"
            {...props}
            value={searchQuery}
            onChange={onChange}
        />
    )
}

function searchIconName<V extends IconVariants>(
    query: string,
    options: IconName<V>[],
    keywords: IconKeywords<V>
): IconName<V>[] {
    if (!query) return options

    return options.filter((iconName) => {
        const iconKeywords = keywords[iconName] ?? iconName.toLowerCase()

        return iconKeywords?.includes(query)
    })
}
