import { useCallback, useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useCollapsedNavigationTriggerState() {
    const { openMobileNavigation, isNavigationFloating, toggleNavigationState } =
        useNavigationContext()

    const onClick = useCallback(() => {
        if (isNavigationFloating) {
            toggleNavigationState('static')
        } else {
            openMobileNavigation()
        }
    }, [isNavigationFloating, toggleNavigationState, openMobileNavigation])

    return useMemo(
        () => ({
            onClick,
            isNavigationFloating,
        }),
        [onClick, isNavigationFloating]
    )
}
