import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'
import {
    convertV2FieldColorToTheme,
    getDefaultDropdownFieldOptionColorName,
} from 'utils/fieldUtils'

import { Color } from 'v2/ui/theme/styles/types/colorBase'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { truncateText } from 'ui/helpers/utilities'
import { palette } from 'ui/styling/baseVariables/colors/colorPalette'

const MAX_ITEM_LENGTH = 25
const MAX_ITEMS = 3

const TEXT_COLORS: { [keyof: string]: Color } = {
    Rose: palette.rose600,
    Pink: palette.pink600,
    Fuchsia: palette.fuchsia600,
    Purple: palette.purple600,
    Violet: palette.violet600,
    Indigo: palette.indigo600,
    Blue: palette.blue600,
    Sky: palette.sky600,
    Cyan: palette.cyan600,
    Teal: palette.teal600,
    Emerald: palette.emerald600,
    Lime: palette.lime600,
    Orange: palette.orange600,
    Amber: palette.amber600,
    Almond: palette.almond600,
    Taupe: palette.taupe600,
    Stone: palette.stone600,
    Neutral: palette.gray900,
}

type FilterOption = {
    label: string
    value: string
    color?: string
    textColor?: string
}

type UseAdvancedFilterValueDropdownStateOptions = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
}

export function useAdvancedFilterValueDropdownState(
    options: UseAdvancedFilterValueDropdownStateOptions
) {
    const { filter, onChange, onRemoveFilter } = options

    const operation = filter.options?.option

    const field = filter.field as FieldDto
    const filterOptions = makeFilterOptions(field)

    const internalValue = useDeepEqualsMemoValue(getValueFromFilter(filter))
    const filterValueRef = useRef(internalValue)
    filterValueRef.current = internalValue

    const value = useMemo(() => new Set(internalValue), [internalValue])
    const valueRef = useRef(value)
    valueRef.current = value

    const supportsMultiValue = [
        'listIs',
        'listIsnt',
        'containsAny',
        'containsNone',
        'oneOf',
        'noneOf',
    ].includes(operation ?? '')
    const isSingle = !supportsMultiValue

    const onSetFilterValue = useCallback(
        (value: string, isEnabled: boolean) => {
            const defaultValue = supportsMultiValue ? [] : ''
            const filterValue = filterValueRef.current
            const existingValue = filterValue ?? defaultValue

            let newValue: AdvancedFilter['options']['value']
            if (isEnabled) {
                if (supportsMultiValue) {
                    newValue = [...existingValue, value]
                } else {
                    newValue = value
                }
            } else {
                if (supportsMultiValue) {
                    newValue = existingValue.filter((v) => v !== value)
                } else {
                    newValue = defaultValue
                }
            }

            // Remove filter when clearing the value.
            if (newValue.length < 1) {
                onRemoveFilter()
                return
            }

            onChange(newValue)
        },
        [onChange, onRemoveFilter, supportsMultiValue]
    )

    const valueOptions = getValueOptions(value, filterOptions)
    const overflowCount = internalValue.length - valueOptions.length

    const overflowLabel = overflowCount > 0 ? `+${overflowCount}` : ''

    const [isOpen, setIsOpen] = useState(false)
    useLayoutEffect(() => {
        if (isOpen) {
            // Make sure we set the pointer events to none. This can be reset
            // if this dropdown is opened after another dropdown.
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = 'none'
            }, 0)

            return () => clearTimeout(timer)
        } else {
            document.body.style.pointerEvents = 'auto'
        }
    }, [isOpen])

    // Focus input when filter is created.
    useEffect(() => {
        if (filter.isDraft) {
            setIsOpen(true)
        }
    }, [filter.isDraft])

    // Focus input when operation is changed, and the filter is empty.
    useEffectOnlyOnUpdate(() => {
        const isEmpty = !valueRef.current.size
        if (!isEmpty) return

        setIsOpen(true)
    }, [filter.options.option])

    return useMemo(
        () => ({
            value,
            valueOptions,
            overflowLabel,
            onSetFilterValue,
            filterOptions,
            isSingle,
            isOpen,
            setIsOpen,
        }),
        [
            value,
            valueOptions,
            overflowLabel,
            onSetFilterValue,
            filterOptions,
            isSingle,
            isOpen,
            setIsOpen,
        ]
    )
}

function makeFilterOptions(field: FieldDto): FilterOption[] {
    if (!field.options?.options) return []

    const allowColors = !!field.options?.allow_dropdown_colors

    return field.options.options?.map((option) => {
        let color = getDefaultDropdownFieldOptionColorName()
        if (allowColors && option?.color) {
            // This will try to map the old color format to the new one.
            // If the color is not found, it means that this is a new color,
            // so use it as is.
            color = convertV2FieldColorToTheme(option.color)
        }

        const textColor =
            TEXT_COLORS[color] ?? TEXT_COLORS[getDefaultDropdownFieldOptionColorName()]

        return {
            label: truncateText(option.label ?? option.value ?? '', MAX_ITEM_LENGTH),
            value: option.value ?? '',
            color,
            textColor,
        }
    })
}

function getValueFromFilter(filter: AdvancedFilter): string[] {
    const filterValue = filter.options?.value

    if (Array.isArray(filterValue)) {
        return filterValue
    }

    if (filterValue) {
        return [filterValue]
    }

    return []
}

function getValueOptions(value: Set<string>, filterOptions: FilterOption[]) {
    const activeOptions = filterOptions.filter((option) => value.has(option.value))

    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    return truncatedOptions
}
