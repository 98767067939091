import React from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import { ActivityType } from 'data/hooks/activityTypes'

import { Dropdown, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { DropdownButton } from 'ui/components/Dropdown/DropdownButton'
import { StandardComponentProps } from 'ui/helpers/styles'

import { useActivityFeedContext } from './useActivityFeedContext'

type ActivityControlsProps = StandardComponentProps & {
    activity: ActivityDto
}

export function ActivityControls({ activity, ...props }: ActivityControlsProps) {
    const { deleteActivity } = useActivityFeedContext()
    const { user } = useAppUserContext()

    if (!(user?._sid === activity.created_by && activity.type === ActivityType.Comment)) return null

    return (
        <Dropdown>
            <DropdownButton
                variant="ghost"
                size="xs"
                {...(props as React.ComponentPropsWithoutRef<typeof DropdownButton>)}
                startIcon={{ name: 'MoreVertical' }}
            />
            <DropdownContent align="end">
                {/* <DropdownItem>Mute this thread: {status?.status}</DropdownItem> */}
                {user?._sid === activity.created_by && activity.type === ActivityType.Comment && (
                    <DropdownItem
                        startIcon={{ name: 'Trash2' }}
                        onClick={() => deleteActivity(activity.auto_id)}
                        label="Delete"
                    />
                )}
            </DropdownContent>
        </Dropdown>
    )
}
