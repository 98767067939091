import { palette } from 'ui/styling/baseVariables/colors/colorPalette'

export const colors: Record<string, string> = {
    [palette.amber600]: 'Amber',
    [palette.orange600]: 'Orange',
    [palette.rose600]: 'Rose',
    [palette.pink600]: 'Pink',
    [palette.fuchsia600]: 'Fuchsia',
    [palette.purple600]: 'Purple',
    [palette.violet600]: 'Violet',
    [palette.indigo600]: 'Indigo',
    [palette.blue600]: 'Blue',
    [palette.sky600]: 'Sky',
    [palette.cyan600]: 'Cyan',
    [palette.teal600]: 'Teal',
    [palette.emerald600]: 'Emerald',
    [palette.lime600]: 'Lime',
    [palette.taupe600]: 'Taupe',
    [palette.almond600]: 'Almond',
    [palette.stone600]: 'Stone',
}
/**  @type {string[]}*/
export const colorSwatch = Object.keys(colors)
export const defaultColor = colorSwatch[6]
