import React from 'react'

import { useUpdateStack } from 'data/hooks/stacks'
import { defaultColor } from 'features/workspace/forms/colorConstants'
import { ColorPickerForm } from 'features/workspace/forms/ColorPickerForm'
import { IconPickerForm } from 'features/workspace/forms/IconPickerForm'
import { FormFooter } from 'features/workspace/WorkspaceSettingsModalUi'
import { ExpandSection } from 'legacy/v1/ui'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'
import Form from 'ui/deprecated/forms/Form'

type AppearanceAppColorAndIconProps = {
    selectedStack: StackDto
}
export const AppearanceAppColorAndIcon: React.FC<AppearanceAppColorAndIconProps> = ({
    selectedStack,
}) => {
    const { mutateAsync: updateStack } = useUpdateStack()

    return (
        <ExpandSection
            heading="Appearance"
            helpUrl=""
            helpText=""
            text={null}
            classes={{ heading: ONBOARDING_CLASSES.APP_SETTINGS_GENERAL.DELETE_APP }}
            testId="general-settings.appearance"
        >
            {/*@ts-expect-error*/}
            <Form
                onSubmit={(data) => {
                    const { ...theme } = data
                    return updateStack({
                        id: selectedStack._sid,
                        patch: {
                            options: {
                                ...selectedStack.options,
                                theme,
                            },
                        },
                    })
                }}
                options={{
                    defaultValues: {
                        brandColor: defaultColor,
                        ...selectedStack.options?.theme,
                    },
                }}
                style={{ flex: 1 }}
                resetOnSuccess
            >
                <Box flex mb="2xl">
                    <ColorPickerForm
                        name="brandColor"
                        required
                        label="Color"
                        showCustom={selectedStack.options?.allow_custom_brand_color}
                    />
                    <IconPickerForm name="icon" label="App Icon" />
                </Box>
                <FormFooter hasTopBorder={false} padding={0} />
            </Form>
        </ExpandSection>
    )
}
