import React, { useEffect, useState } from 'react'

import { Agent } from 'data/hooks/agents/types'
import { EditAgentModal } from 'features/Agents/EditAgentModal'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Popup, PopupContent, PopupTrigger } from 'ui/components/Popup'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'
import { Headline } from 'ui/components/Text'

import { AgentChatBox } from './AgentChatBox'
import { AgentIcon } from './AgentIcon'
import { AgentJobs } from './AgentJobs'

type AgentChatDropdownProps = {
    agent: Agent
    children: React.ReactNode
}

export function AgentChatDropdown({ agent, children }: AgentChatDropdownProps) {
    const [conversationSid, setConversationSid] = useState<string>(
        sessionStorage.getItem(`${agent._sid}-lastConversationSid`) || ''
    )

    const [tab, setTab] = useState<'chat' | 'jobs'>('chat')

    useEffect(() => {
        sessionStorage.setItem(`${agent._sid}-lastConversationSid`, conversationSid)
    }, [agent._sid, conversationSid])

    return (
        <Popup>
            <PopupTrigger asChild>{children}</PopupTrigger>
            <PopupContent px="l" flex column gap="l" zIndex={1500}>
                <Tabs
                    type="underlined"
                    value={tab}
                    // @ts-expect-error : unfortunately, the Tabs component dosen't support an union type for the value
                    onValueChange={setTab}
                    flex
                    column
                    height="full"
                    minHeight="200px"
                    maxHeight="full"
                >
                    <Box flex alignItems="stretch" pb="s">
                        <Headline size="xs" flex center gap="s" borderBottomWidth="base" pr="l">
                            <AgentIcon agent={agent} />
                            {agent.title}
                        </Headline>
                        <TabsList grow>
                            <TabsTrigger
                                value="chat"
                                label="Chat"
                                startIcon={{ name: 'MessageCircle' }}
                            />
                            <TabsTrigger value="jobs" label="Jobs" startIcon={{ name: 'Ticket' }} />
                        </TabsList>
                        <Box flex center borderBottomWidth="base">
                            {tab === 'chat' && (
                                <Button
                                    variant="ghost"
                                    size="s"
                                    iconOnly
                                    title="Start new conversation"
                                    startIcon={{ name: 'FilePlus2' }}
                                    onClick={() => setConversationSid('')}
                                />
                            )}
                            <EditAgentModal
                                agent={agent}
                                trigger={
                                    <Button
                                        variant="ghost"
                                        size="s"
                                        iconOnly
                                        title="Edit agent"
                                        startIcon={{ name: 'Settings' }}
                                    />
                                }
                            />
                        </Box>
                    </Box>
                    <TabsContent value="chat" grow height="full" flex column shrink>
                        <AgentChatBox
                            agent={agent}
                            conversationSid={conversationSid}
                            setConversationSid={setConversationSid}
                        />
                    </TabsContent>
                    <TabsContent value="jobs" grow shrink maxHeight="full" flex column>
                        {tab === 'jobs' && <AgentJobs agent={agent} />}
                    </TabsContent>
                </Tabs>
            </PopupContent>
        </Popup>
    )
}
