import React from 'react'

import { DEFAULT_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'

import { Box } from 'ui/components/Box'

type DetailViewSidebarLayoutProps = {}

export const DetailViewSidebarLayout: React.FC<DetailViewSidebarLayoutProps> = () => {
    return (
        <Box
            as="main"
            px="4xl"
            gap="l"
            display="grid"
            height="full"
            maxWidth={{
                mobile: 'full',
                tablet: 'auto',
            }}
            overflow={{
                mobile: 'auto',
                tablet: 'visible',
            }}
            style={{
                gridTemplateColumns: '350px 1fr',
            }}
        >
            <Box as="aside">
                <LayoutEditorWidgetArea id="sidebar" />
            </Box>
            <Box as="section">
                <LayoutEditorWidgetArea id={DEFAULT_WIDGET_AREA_ID} />
            </Box>
        </Box>
    )
}
