import React from 'react'

import { Box } from 'ui/components/Box'

import { DetailViewHeader } from './headers/DetailViewHeader'
import { DetailViewLayout } from './layouts/DetailViewLayout'

type DetailViewContentProps = {}

export const DetailViewContent: React.FC<DetailViewContentProps> = () => {
    return (
        <Box background="surface" width="full" flex column grow gap="3xs">
            <DetailViewHeader />
            <DetailViewLayout />
        </Box>
    )
}
