// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import {
    ToolbarIconStyle,
    ToolbarPanelButtonWrapperStyle,
    ToolbarPanelInputWrapperStyle,
    ToolbarPanelWrapperStyle,
} from 'ui/components/Textarea/Textarea.css'

import { useTextareaToolbarContext } from './textareaToolbarContext'
import { TextareaToolbarInput } from './TextareaToolbarInput'
import { TextareaToolbarPanel } from './TextareaToolbarPanel'

type TextareaToolbarLinkPanelProps = React.ComponentPropsWithoutRef<
    typeof TextareaToolbarPanel
> & {}

export const TextareaToolbarLinkPanel: React.FC<TextareaToolbarLinkPanelProps> = ({
    editor,
    ...props
}) => {
    const { setCurrentPanel } = useTextareaToolbarContext()

    const { href: link } = editor.getAttributes('link')

    const [url, setUrl] = useState(link)

    const isSubmitDisabled = url && !isValidUrl(url)

    const setLink = useCallback(() => {
        if (!url) {
            setCurrentPanel(null)
            return
        }

        const isValid = isValidUrl(url)
        if (!isValid) {
            return
        }

        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()

        setCurrentPanel(null)
    }, [editor, setCurrentPanel, url])

    const unsetLink = useCallback(() => {
        editor.chain().focus().extendMarkRange('link').unsetLink().run()

        setCurrentPanel(null)
    }, [editor, setCurrentPanel])

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === 'Escape') {
                setCurrentPanel(null)
            }
        },
        [setCurrentPanel]
    )

    return (
        <TextareaToolbarPanel
            className={classNames(ToolbarPanelWrapperStyle)}
            editor={editor}
            onKeyDown={handleKeyDown}
            {...props}
        >
            <Icon name="Link" size="xs" className={ToolbarIconStyle} />
            <Box
                as="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    setLink()
                }}
                className={ToolbarPanelInputWrapperStyle}
            >
                <TextareaToolbarInput
                    placeholder="Paste a link, e.g. https://stackerhq.com"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    autoFocus
                />
            </Box>
            <Box className={ToolbarPanelButtonWrapperStyle}>
                {link && (
                    <Button
                        size="2xs"
                        startIcon={{ name: 'Trash2' }}
                        variant="critical"
                        onClick={unsetLink}
                    />
                )}
                <Button size="2xs" variant="primary" onClick={setLink} disabled={isSubmitDisabled}>
                    Done
                </Button>
            </Box>
        </TextareaToolbarPanel>
    )
}

const urlRegexp = /^(\S+):(\/\/)?\S+$/

function isValidUrl(url: string) {
    return urlRegexp.test(url)
}
