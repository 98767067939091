import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styled from '@emotion/styled'

import { colors, colorSwatch } from 'features/workspace/forms/colorConstants'

import V2Icon from 'v2/ui/components/Icon'
import Input from 'v2/ui/components/Input'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTitle, DropdownTrigger } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { TriggerStyles } from 'ui/components/Select/Select.css'
import { Flex } from 'ui/deprecated/atoms/Flex'
import { RenderInlineElement } from 'ui/deprecated/forms/ui'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { determineColorScheme } from 'ui/styling/helpers/ThemeInitializer'

type ColorPickerFormProps = {
    defaultColor?: string | null
    showCustom?: boolean
    name: string
    label?: string
    required?: boolean
    disabled?: boolean
}

export const ColorPickerForm: React.FC<ColorPickerFormProps> = ({
    defaultColor = null,
    showCustom,
    name,
    required,
    disabled,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const {
        control,
        formState: { errors },
    } = useFormContext()

    const colorError = errors[name]

    return (
        // @ts-expect-error: JS compatibility issues.
        <RenderInlineElement error={colorError} style={{ alignItems: 'flex-start' }} {...props}>
            <Controller
                control={control}
                name={name}
                rules={{ required }}
                render={({ field }) => (
                    <Dropdown open={isOpen} onOpenChange={setIsOpen} modal={false}>
                        <DropdownTrigger
                            className={TriggerStyles.styleFunction({ size: 'm' })}
                            style={{
                                width: 'auto',
                            }}
                            disabled={disabled}
                            aria-disabled={disabled}
                            asChild
                        >
                            <Box flex center gap="xs" role="button">
                                <ColorItem value={field.value || defaultColor} />
                                <Icon size="m" name="ChevronDown" />
                            </Box>
                        </DropdownTrigger>
                        <DropdownContent align="end">
                            <DropdownTitle>Colors</DropdownTitle>
                            <Box px="xl">
                                <ColorPickerContents
                                    onChange={field.onChange}
                                    value={field.value}
                                    showCustom={showCustom}
                                />
                            </Box>
                        </DropdownContent>
                    </Dropdown>
                )}
            />
        </RenderInlineElement>
    )
}

const hexColorRegex = /#[0-9A-F]{6}$/i // Starts with `#` and only six chars

type ColorPickerContentsProps = {
    value: string
    onChange: (value: string) => void
    showCustom?: boolean
}

const ColorPickerContents: React.FC<ColorPickerContentsProps> = ({
    value,
    onChange,
    showCustom,
}) => {
    const [inputVal, setVal] = useState(value)
    useEffect(() => {
        setVal(value)
    }, [value, setVal])
    const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value || ''
        if (val.match(hexColorRegex)) {
            onChange(e.target.value)
        }
        if (val.length < 8) {
            setVal(e.target.value)
        }
    }
    return (
        <Flex as="div" column>
            <Flex as="div" column={false} style={{ width: 180, flexWrap: 'wrap' }}>
                {colorSwatch.map((color) => {
                    const isActive = isColorActive(color as keyof typeof colors, value)

                    return (
                        <StyledColorItem
                            key={color}
                            className={isActive ? 'active' : ''}
                            onClick={() => onChange(color)}
                        >
                            <V2Icon icon="check" color="white" />
                            <ColorItem value={color} />
                        </StyledColorItem>
                    )
                })}
            </Flex>
            {showCustom && (
                <Box mt="s">
                    <div style={{ ...V4DesignSystem.label }}>Custom</div>
                    <Input
                        value={inputVal}
                        onChange={handleCustomInputChange}
                        className={inputVal.match(hexColorRegex) ? '' : 'invalid'}
                    />
                </Box>
            )}
        </Flex>
    )
}

type ColorItemProps = {
    value: string
}

const ColorItem: React.FC<ColorItemProps> = ({ value }) => {
    return <div style={{ minWidth: 20, height: 20, borderRadius: 5, background: value }} />
}

const StyledColorItem = styled.div`
    position: relative;
    flex-shrink: 0;
    margin-right: 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    border-radius: 8px;
    cursor: pointer;
    svg {
        transition: 0.2s all ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
    }
    &.active {
        svg {
            opacity: 1;
        }
    }
`

function isColorActive(color: keyof typeof colors, value: string) {
    const valueColorScheme = determineColorScheme(value)
    const colorColorScheme = colors[color]

    if (valueColorScheme && colorColorScheme) {
        return valueColorScheme === colorColorScheme
    }

    return value === color
}
