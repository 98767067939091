import React from 'react'

import { Agent } from 'data/hooks/agents/types'

import { Icon } from 'ui/components/Icon'

type AgentIconProps = Omit<React.ComponentProps<typeof Icon>, 'name'> & {
    agent: Agent
}

export function AgentIcon({ agent, ...props }: AgentIconProps) {
    return <Icon name={agent.icon?.name || 'Bot'} {...props} />
}
