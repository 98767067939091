import { getRootPath, getWorkspaceUrl, Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { useStacks } from 'data/hooks/stacks'

export function usePortalHomepageState() {
    const { workspaceZone, workspaceAccount } = useAppContext()
    const { data: stacks, isLoading } = useStacks()

    const defaultStack = stacks?.[0]
    const defaultStackPath = defaultStack
        ? `${getRootPath(defaultStack, workspaceAccount, workspaceZone)}/`
        : ''

    const portalHomePath = getWorkspaceUrl(Urls.Home, workspaceAccount, workspaceZone)

    return {
        isLoading,
        defaultStackPath,
        portalHomePath,
    }
}
