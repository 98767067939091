import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationSpacesState() {
    const { spaces, permissions } = useNavigationContext()

    const showSpaces = spaces.length > 0 && permissions.canViewInternalZone

    return useMemo(
        () => ({
            spaces,
            showSpaces,
        }),
        [spaces, showSpaces]
    )
}
