import { QueryKey, useMutation, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    buildQueryKey,
    queryClient,
    useCanRunStackScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { ChatCompletionUserMessageParam } from 'features/AiAppBuilder/chatUtils/openAiTypes'

import { AgentConversation, AgentConversationMessage } from './types'

// Conversations
const CONVERSATIONS_LIST_NAME = 'useAgentConversations'
const get_conversations_endpoint = (agent_sid: string) => `agents/${agent_sid}/conversations/`

function useConversationsQueryKey() {
    return useQueryKeyBuilder(CONVERSATIONS_LIST_NAME, {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type ConversationsOptionsType = UseQueryOptions<
    AgentConversation[],
    unknown,
    AgentConversation[],
    QueryKey
>

export function useAgentConversations(agent_sid: string, options: ConversationsOptionsType = {}) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as ConversationsOptionsType),
        keepPreviousData: true,
        ...options,
        enabled,
    }
    return useQuery<AgentConversation[]>(
        useConversationsQueryKey(),
        get_conversations_endpoint(agent_sid),
        query_config
    )
}

export function useCreateAgentConversation(agent_sid: string) {
    return useCreateItem<AgentConversation>(
        useConversationsQueryKey(),
        get_conversations_endpoint(agent_sid),
        {
            onSuccess: () => {
                invalidateConversations()
            },
        },
        undefined,
        false
    )
}

export function useUpdateAgentConversation(agent_sid: string) {
    return useUpdateItem<AgentConversation>(
        useConversationsQueryKey(),
        get_conversations_endpoint(agent_sid),
        {
            onSuccess: () => {
                invalidateConversations()
            },
        }
    )
}

export function useDeleteAgentConversation(agent_sid: string) {
    return useDeleteItem(useConversationsQueryKey(), get_conversations_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateConversations()
        },
    })
}

export function invalidateConversations() {
    return queryClient.invalidateQueries([CONVERSATIONS_LIST_NAME, getCurrentStackId()])
}

// Messages
const MESSAGES_LIST_NAME = 'useAgentConversationMessages'
const get_messages_endpoint = (agent_sid: string, conversation_sid: string) =>
    `agents/${agent_sid}/conversations/${conversation_sid}/messages/`

function useMessagesQueryKey(conversationSid: string) {
    return useQueryKeyBuilder([MESSAGES_LIST_NAME, conversationSid], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type ConversationMessagesResponseType = { messages: AgentConversationMessage[] }
type MessagesOptionsType = UseQueryOptions<
    ConversationMessagesResponseType,
    unknown,
    ConversationMessagesResponseType,
    QueryKey
>

export function useAgentConversationMessages(
    agent_sid: string,
    conversation_sid?: string,
    isNewConversation?: boolean,
    options: MessagesOptionsType = {}
) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as MessagesOptionsType),
        keepPreviousData: false,
        ...options,
        enabled: enabled && !!conversation_sid,
    }
    return useQuery<ConversationMessagesResponseType>(
        useMessagesQueryKey(conversation_sid || ''),
        get_messages_endpoint(agent_sid, conversation_sid || ''),
        query_config
    )
}

type PostMessageArguments = {
    agentSid: string
    conversationSid?: string
    message: ChatCompletionUserMessageParam
}
type PostMessageResponse = {
    messages: AgentConversationMessage[]
    conversation_sid: string
}
export function usePostAgentConversationMessage() {
    return useMutation(
        async ({ agentSid, conversationSid, message }: PostMessageArguments) => {
            const endpoint = get_messages_endpoint(agentSid, conversationSid || 'new')

            //throw new Error('Not implemented')
            const response = await fetchWithAuth(endpoint, {
                method: 'POST',
                body: JSON.stringify({ message }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.status >= 400) {
                return Promise.reject(response)
            }

            return response.json() as unknown as PostMessageResponse
        },
        {
            onMutate: async ({ conversationSid, message }) => {
                if (!conversationSid) {
                    return {}
                }

                const tempId = 'temp-' + Date.now()

                // Create optimistic message
                const optimisticMessage: AgentConversationMessage = {
                    _sid: tempId,
                    conversation_sid: conversationSid,
                    role: 'user',
                    message,
                    created_date: new Date().toISOString(),
                    modified_date: new Date().toISOString(),
                    isSending: true,
                }

                // Add optimistic message to cache
                queryClient.setQueryData<ConversationMessagesResponseType>(
                    buildQueryKey([MESSAGES_LIST_NAME, conversationSid || ''], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => ({
                        messages: [...(old?.messages ?? []), optimisticMessage],
                    })
                )

                return { tempId }
            },
            onSuccess: (data, __, context) => {
                const tempId = context?.tempId
                const { conversation_sid } = data

                // Update cache with actual messages
                queryClient.setQueryData<ConversationMessagesResponseType>(
                    buildQueryKey([MESSAGES_LIST_NAME, conversation_sid], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => ({
                        messages: [
                            ...(old?.messages?.filter((x) => x._sid !== tempId) ?? []),
                            ...data.messages,
                        ],
                    })
                )

                invalidateMessages(conversation_sid)
            },
            onError: (_, { conversationSid }, context) => {
                const tempId = context?.tempId

                if (!conversationSid) {
                    return
                }

                // Mark message as failed
                queryClient.setQueryData<ConversationMessagesResponseType>(
                    buildQueryKey([MESSAGES_LIST_NAME, conversationSid], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => ({
                        messages:
                            old?.messages?.map((msg) =>
                                msg._sid === tempId
                                    ? { ...msg, isSending: false, sendingFailed: true }
                                    : msg
                            ) ?? [],
                    })
                )
            },
        }
    )
}

export function cancelPendingMessage(conversationSid: string, messageId: string) {
    queryClient.setQueryData<ConversationMessagesResponseType>(
        buildQueryKey([MESSAGES_LIST_NAME, conversationSid], {
            includeAuthKeys: true,
            includeStackId: true,
        }),
        (old) => ({
            messages:
                old?.messages?.filter(
                    (msg) => !(msg._sid === messageId && (msg.isSending || msg.sendingFailed))
                ) ?? [],
        })
    )
}

// export function useUpdateAgentConversationMessage(agent_sid: string) {
//     return useUpdateItem<AgentConversationMessage>(
//         useMessagesQueryKey(''),
//         get_messages_endpoint(agent_sid, ''),
//         {
//             onSuccess: (data) => {
//                 invalidateMessages(data._sid)
//             },
//         }
//     )
// }

// export function useDeleteAgentConversationMessage(agent_sid: string) {
//     return useDeleteItem(useMessagesQueryKey(''), get_messages_endpoint(agent_sid, ''), {
//         onSuccess: (data) => {
//             invalidateMessages(data._sid)
//         },
//     })
// }

export function invalidateMessages(conversationSid: string) {
    return queryClient.invalidateQueries([MESSAGES_LIST_NAME, getCurrentStackId(), conversationSid])
}
