import React, { useContext } from 'react'

import { ListViewSort } from 'features/views/ListView/Sort/types'

import { TableViewCalculations } from './hooks/useTableViewCalculations'
import { TableViewColumn } from './types'

export type TableViewInternalContextValue = {
    columns: TableViewColumn[]
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    defaultSortBy?: ListViewSort
    clearFilters: () => void
    requestFilters: Filter[]
    requestIncludedFields: string[]
    hasFilters: boolean
    isEmbedded: boolean
    hasResizableColumns: boolean
    isRecordActionsColumnPinned: boolean
    onRecordClick: Required<ListViewOptions['onRecordClick']>
    thumbnailImageField?: FieldDto
    thumbnailImageAspectRatio?: '1:1' | '4:3'
    calculations?: TableViewCalculations
    isCalculationsFetchingSlow: boolean
    isCalculationsError: boolean
    header: ListViewHeader
    showColumnIcons: boolean
}
export type TableViewContextValue = TableViewInternalContextValue & {
    pendingRecords: RecordDto[]
}
export const TableViewContext = React.createContext<TableViewContextValue | null>(null)

export function useTableViewContext(): TableViewContextValue {
    const context = useContext(TableViewContext)

    if (!context) {
        throw new Error('useTableViewContext must be used within a TableViewContextProvider')
    }

    return context
}
