import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import { useSystemNavigationItemState } from './hooks/useSystemNavigationItemState'
import { NavigationItem } from './NavigationItem'

import { SystemNavigationIconWrapperStyles, SystemNavigationItemStyles } from './Navigation.css'

type SystemNavigationItemRef = HTMLElement

type SystemNavigationItemProps = React.ComponentPropsWithoutRef<typeof NavigationItem> & {
    iconName: React.ComponentPropsWithoutRef<typeof Icon<'hugeicons'>>['name']
    count?: number
    isUnread?: boolean
}

export const SystemNavigationItem = React.forwardRef<
    SystemNavigationItemRef,
    SystemNavigationItemProps
>(
    (
        {
            label,
            iconName,
            count,
            to,
            isUnread,
            isActive: isActiveProp,
            isCollapsed,
            className,
            leftSlotContent,
            rightSlotContent,
            ...props
        },
        ref
    ) => {
        const { formattedCount, isActive } = useSystemNavigationItemState({ count, to })

        const effectiveIsActive = isActiveProp ?? isActive

        const iconContent = (
            <Box
                className={SystemNavigationIconWrapperStyles.styleFunction({
                    isUnread,
                })}
            >
                <Icon name={iconName} family="hugeicons" color="gray900" size="m" />
            </Box>
        )

        return (
            <NavigationItem
                ref={ref}
                to={to}
                isCollapsed={isCollapsed}
                className={classNames(
                    SystemNavigationItemStyles.styleFunction({
                        isCollapsed,
                        isActive: effectiveIsActive,
                    }),
                    className
                )}
                label={
                    <Body size="m" weight="medium" color="text">
                        {label}
                    </Body>
                }
                leftSlotContent={
                    leftSlotContent ||
                    (isCollapsed ? (
                        <Tooltip
                            content={label}
                            asChild
                            zIndex={200}
                            align="start"
                            side="right"
                            sideOffset={16}
                            noShrink
                        >
                            {iconContent}
                        </Tooltip>
                    ) : (
                        iconContent
                    ))
                }
                rightSlotContent={
                    rightSlotContent || (
                        <>
                            {formattedCount && (
                                <Tag
                                    type="solid"
                                    size="2xs"
                                    shade="light"
                                    color="Theme"
                                    shape="rectangle"
                                    mr="xs"
                                    pointerEvents="none"
                                >
                                    {formattedCount}
                                </Tag>
                            )}
                        </>
                    )
                }
                {...props}
            />
        )
    }
)
