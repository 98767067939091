import React from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { Box } from 'ui/components/Box'

import { useLayoutEditorWidgetPickerDnDDisplay } from './hooks/useLayoutEditorWidgetPickerDnDDisplay'
import { LayoutEditorWidgetPickerDisplay } from './LayoutEditorWidgetPickerDisplay'

type LayoutEditorWidgetPickerProps = {
    onClickWidget?: (widgetType: string) => void
}

export const LayoutEditorWidgetPicker: React.FC<LayoutEditorWidgetPickerProps> = ({
    onClickWidget,
}) => {
    const { schema } = useLayoutEditorContext()

    return (
        <Box>
            <Box display="grid" style={{ gridTemplateColumns: '1fr 1fr' }} columnGap="m" rowGap="l">
                {Object.entries(schema.widgets).map(([widgetType, widget]) => (
                    <LayoutEditorWidgetPickerDnDDisplay
                        key={widgetType}
                        widgetSchema={widget}
                        widgetType={widgetType}
                        onClick={onClickWidget ? () => onClickWidget(widgetType) : undefined}
                    />
                ))}
            </Box>
        </Box>
    )
}

type LayoutEditorWidgetPickerDnDDisplayProps = React.ComponentPropsWithoutRef<
    typeof LayoutEditorWidgetPickerDisplay
> & {
    widgetType: string
}

const LayoutEditorWidgetPickerDnDDisplay: React.FC<LayoutEditorWidgetPickerDnDDisplayProps> = ({
    widgetType,
    ...props
}) => {
    const { attributes, listeners, ref } = useLayoutEditorWidgetPickerDnDDisplay({
        widgetType,
    })

    return (
        <LayoutEditorWidgetPickerDisplay
            ref={ref}
            {...attributes}
            {...listeners}
            style={{
                touchAction: 'none',
            }}
            {...props}
        />
    )
}
