import { useMemo } from 'react'

import { useAppNavigationContext } from './useAppNavigationContext'

export function usePortalAppsItemState() {
    const { menuItems } = useAppNavigationContext()

    const hasChildren = menuItems.length > 0

    return useMemo(
        () => ({
            hasChildren,
        }),
        [hasChildren]
    )
}
