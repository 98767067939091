import React, { forwardRef, useCallback, useEffect, useRef } from 'react'

import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'

import TextAttribute from 'v2/ui/components/Attribute/TextAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { DataGridCellEditorComponent, DataGridCellRendererComponent } from './types'

import { StringCellWrapperStyle } from './StringCell.css'

type DateCellRendererProps = {}
export const StringCellRenderer: DataGridCellRendererComponent<DateCellRendererProps> = ({
    value,
}) => {
    if (!value || typeof value !== 'string') return null

    return (
        <Box
            flex
            center
            gap="xs"
            height="full"
            width="full"
            overflowX="auto"
            className={StringCellWrapperStyle}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                {value}
            </div>
        </Box>
    )
}
type StringCellEditorProps = {}
export const StringCellEditor: DataGridCellEditorComponent<StringCellEditorProps> = forwardRef(
    ({ value }, ref) => {
        const [newValue, setNewValue] = useEditorValue<string | unknown>(ref, value)
        const inputElementRef = useRef<HTMLInputElement | null>(null)

        useEffect(() => {
            // this is important for when this component is given a non-string value. This can
            // happen during field type conversion from document to short string
            if (typeof newValue !== 'string') {
                setNewValue('')
            }
        }, [newValue, setNewValue])

        const handleTypeInCell = useCallback(
            (newValue: string) => {
                setNewValue(newValue)
            },
            [setNewValue]
        )

        useTypeInCellToEdit({
            getInputElement: () => inputElementRef.current,
            onChange: handleTypeInCell,
        })

        return (
            <Box
                ref={(el: HTMLDivElement | null) => {
                    inputElementRef.current = el?.querySelector('input') ?? null
                }}
                height="full"
                width="full"
                tabIndex={0}
                className={StringCellWrapperStyle}
            >
                {/* @ts-expect-error */}
                <TextAttribute
                    mode={modes.editing}
                    onChange={setNewValue}
                    renderOptions={{ doNotLinkify: true }}
                >
                    {newValue}
                </TextAttribute>
            </Box>
        )
    }
)
