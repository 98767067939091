import { useCallback, useMemo } from 'react'

import { DEFAULT_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useDetailViewControlsState() {
    const { view, commands } = useLayoutEditorContext()

    const title = view ? `${view.name} detail` : 'Edit layout'

    const onClickWidget = useCallback(
        (widgetType: string) => {
            commands.insertWidgetAtPath(widgetType, [DEFAULT_WIDGET_AREA_ID])
        },
        [commands]
    )

    return useMemo(
        () => ({
            title,
            onClickWidget,
        }),
        [title, onClickWidget]
    )
}
