import { useMutation, UseMutationOptions } from 'react-query'

import { reloadMetadataAfterObjectChange } from 'data/hooks/refreshData'
import { fetchAndReturn } from 'data/utils/utils'

type DataConnectionUpdate = { _sid: string } & Partial<DataConnectionDto>

export function useEditDataConnection(
    options: UseMutationOptions<DataConnectionUpdate, unknown, DataConnectionUpdate> = {}
) {
    return useMutation(
        async (dataConnectionPatch: DataConnectionUpdate) => {
            return fetchAndReturn(`data-connections/${dataConnectionPatch._sid}/`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(dataConnectionPatch),
            }) as Promise<DataConnectionDto>
        },
        {
            ...options,
            onSettled: () => {
                reloadMetadataAfterObjectChange()
            },
        }
    )
}
