import React from 'react'

import { BANNER_HEIGHT } from 'features/core/appBannerConstants'
import { DetailViewTitleAttribute } from 'features/views/DetailView/attributes/DetailViewTitleAttribute'

import { Box } from 'ui/components/Box'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewSlimHeaderState } from './hooks/useDetailViewSlimHeaderState'
import { DetailViewTopBar } from './DetailViewTopBar'

import { DetailViewSlimHeaderStyle } from './DetailViewHeader.css'

type DetailViewSlimHeaderProps = {}

export const DetailViewSlimHeader: React.FC<DetailViewSlimHeaderProps> = () => {
    const { headerRef, isPreviewing, isLoading, titleField, titleValue } =
        useDetailViewSlimHeaderState()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box
            ref={headerRef}
            as="header"
            className={DetailViewSlimHeaderStyle}
            style={{
                top: isPreviewing ? `${BANNER_HEIGHT}px` : undefined,
            }}
        >
            <DetailViewTopBar
                width="full"
                px={{
                    mobile: 'xl',
                    tablet: 0,
                }}
                pb={{
                    mobile: 's',
                    tablet: 0,
                }}
            />
            {isMobile && (
                <Box flex center pt="xl" px="xl" borderTopWidth={1} borderColor="dividerWeak">
                    {titleField && (
                        <DetailViewTitleAttribute
                            field={titleField}
                            isLoading={isLoading}
                            size="m"
                            value={titleValue}
                        />
                    )}
                </Box>
            )}
        </Box>
    )
}
