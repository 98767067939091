import Nango from '@nangohq/frontend'

export const getNangoClient = (integrationId: string, sessionToken: string) => {
    const additionalOptions = integrationId === 'airtable' ? { width: 600, height: 660 } : {}

    return new Nango({
        connectSessionToken: sessionToken,
        ...additionalOptions,
    })
}
