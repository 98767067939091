import React, { useMemo, useState } from 'react'

import shortid from 'shortid'

import {
    cancelPendingMessage,
    useAgentConversationMessages,
    usePostAgentConversationMessage,
} from 'data/hooks/agents/conversation'
import { useJob } from 'data/hooks/agents/job'
import { Agent } from 'data/hooks/agents/types'
import { JobListItem } from 'features/Agents/Jobs/JobListItem'
import { ChatUserMessageContentType } from 'features/AiAppBuilder/chatUtils/chatTypes'
import { ChatBox } from 'features/AiPlayground/components/ChatBox'

import { Box } from 'ui/components/Box'

import { AgentChatMessage } from './types'

type AgentChatBoxProps = {
    agent: Agent
    conversationSid?: string
    setConversationSid?: (sid: string) => void
}

export function AgentChatBox({ agent, conversationSid, setConversationSid }: AgentChatBoxProps) {
    const [pendingFirstMessage, setPendingFirstMessage] = useState<AgentChatMessage | undefined>()

    const { data: conversationData } = useAgentConversationMessages(agent._sid, conversationSid)
    const { mutateAsync: postMessage } = usePostAgentConversationMessage()

    async function handlePostMessage(message: ChatUserMessageContentType) {
        // let currentConversationSid = conversationSid || ''
        // let isTempConversationSid = isNewConversation
        if (!conversationSid) {
            // currentConversationSid = `${agent._sid}-new-${Date.now()}`
            // setConversationSid?.(currentConversationSid)
            // isTempConversationSid = true
            setPendingFirstMessage({
                _sid: shortid(),
                content: message,
                role: 'user',
                isSending: true,
            })
        }

        try {
            const result = await postMessage({
                agentSid: agent._sid,
                conversationSid,
                // isNewConversation: isTempConversationSid,
                message: { content: message, role: 'user' },
            })

            setPendingFirstMessage(undefined)
            // if (isTempConversationSid) {
            setConversationSid?.(result.conversation_sid)
            //     setIsNewConversation(false)
            // }
        } catch (error) {
            console.error(error)
            setPendingFirstMessage((existing) => {
                if (!existing) return
                return {
                    ...existing,
                    sendingFailed: true,
                }
            })
        }
    }

    async function handleRetryMessage(message: AgentChatMessage) {
        if (!message._sid || message.role !== 'user') return

        if (conversationSid) {
            cancelPendingMessage(conversationSid, message._sid)
        }

        await handlePostMessage(message.content)
    }

    const serverMessages = conversationData?.messages
    const messages = useMemo(() => {
        if (pendingFirstMessage && !conversationSid) {
            return [pendingFirstMessage]
        }
        return (
            serverMessages?.map(
                (x) =>
                    ({
                        _sid: x._sid,
                        ...x.message,
                        isSending: x.isSending,
                        sendingFailed: x.sendingFailed,
                    }) as AgentChatMessage
            ) ?? []
        )
    }, [conversationSid, pendingFirstMessage, serverMessages])
    return (
        <ChatBox
            messages={messages}
            onPostMessage={handlePostMessage}
            retryMessage={handleRetryMessage}
            renderMessage={renderMessage}
        />
    )
}

function renderMessage(message: AgentChatMessage) {
    if (message.role === 'tool' && message.function === 'createJob') {
        return <CreateJobChatMessage message={message} />
    }
    return undefined
}

function CreateJobChatMessage({ message }: { message: AgentChatMessage }) {
    const data = JSON.parse(message.content as string)
    const { data: job } = useJob(data.job.agent_id, data.job._sid)

    return (
        <Box
            p="m"
            background="teal100"
            rounded="m"
            alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
            className="markdown"
        >
            {job && <JobListItem job={job} />}
        </Box>
    )
}
