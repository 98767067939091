import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { FooterWrapperStyle } from 'ui/components/Menu/Menu.css'

import { DropdownItem } from './DropdownItem'

type DropdownFooterItemRef = HTMLDivElement

type DropdownFooterItemProps = React.ComponentPropsWithoutRef<typeof DropdownItem> & {}

export const DropdownFooterItem = forwardRef<DropdownFooterItemRef, DropdownFooterItemProps>(
    (props, ref) => {
        return (
            <Box ref={ref} className={FooterWrapperStyle}>
                <DropdownItem {...props} />
            </Box>
        )
    }
)
