import { useEffect, useRef } from 'react'

import { useDndContext } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { useLayoutEditorWidgetAreaContext } from 'features/views/LayoutEditor/useLayoutEditorWidgetAreaContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseLayoutEditorDnDWidgetStateOptions = {
    widgetId: string
    widgetType: string
}

export function useLayoutEditorDnDWidgetState(options: UseLayoutEditorDnDWidgetStateOptions) {
    const { widgetId, widgetType } = options

    const { path = [], isEditing } = useLayoutEditorWidgetAreaContext() ?? {}

    const canDrag = isEditing

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: widgetId,
        data: {
            type: 'widget',
            widgetType,
            path,
        },
        transition: null,
        animateLayoutChanges: () => false,
        disabled: !canDrag,
    })

    const { active } = useDndContext()
    const { selectedWidget } = useLayoutEditorContext()

    const isDraggingCurrentWidgetFromPicker =
        active && active?.id === selectedWidget?.type && widgetId === selectedWidget?.id

    const effectiveIsDragging = isDragging || isDraggingCurrentWidgetFromPicker

    const widgetRef = useRef<HTMLDivElement | null>(null)
    const composedRef = useComposedRefs<HTMLDivElement>(widgetRef, setNodeRef)

    const transformStr = CSS.Transform.toString(
        transform ? { ...transform, scaleX: 1, scaleY: 1 } : null
    )
    useEffect(() => {
        const widgetEl = widgetRef.current
        if (!widgetEl) return

        widgetEl.style.transition = transition ?? ''
        widgetEl.style.transform = transformStr ?? ''
    }, [transformStr, transition])

    const listenersRef = useRef(listeners)
    listenersRef.current = listeners

    const attributesMemo = useDeepEqualsMemoValue(canDrag ? attributes : undefined)

    return {
        attributes: attributesMemo,
        listeners: canDrag ? listenersRef.current : undefined,
        setNodeRef: composedRef,
        isDragging: effectiveIsDragging,
    }
}
