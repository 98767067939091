import React from 'react'

import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { DetailViewTitleAttribute } from 'features/views/DetailView/attributes/DetailViewTitleAttribute'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewTopBarQuickActionsState } from './hooks/useDetailViewTopBarQuickActionsState'
import { useDetailViewTopBarState } from './hooks/useDetailViewTopBarState'

type DetailViewTopBarProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const DetailViewTopBar: React.FC<DetailViewTopBarProps> = (props) => {
    const { titleField, isLoading, titleValue, showMenuButton } = useDetailViewTopBarState()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box flex center justifyContent="space-between" {...props}>
            <Box flex center gap={{ mobile: 'xs', tablet: 'm' }} shrink grow>
                {showMenuButton && (
                    <Box flex center>
                        <CollapsedNavigationTrigger />
                    </Box>
                )}
                {!isMobile && titleField && (
                    <DetailViewTitleAttribute
                        field={titleField}
                        isLoading={isLoading}
                        size="l"
                        value={titleValue}
                    />
                )}
            </Box>
            <Box flex center noShrink>
                <DetailViewTopBarQuickActions />
            </Box>
        </Box>
    )
}

type DetailViewTopBarQuickActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {}

const DetailViewTopBarQuickActions: React.FC<DetailViewTopBarQuickActionsProps> = (props) => {
    const { copyLink, duplicateRecord, deleteRecord } = useDetailViewTopBarQuickActionsState()

    const showAsDropdown = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box flex center gap="s" {...props}>
            {!!copyLink && !showAsDropdown && (
                <Tooltip side="bottom" align="center" content="Copy link" zIndex={200} asChild>
                    <Button
                        size="s"
                        variant="ghost"
                        startIcon={{ name: 'Link' }}
                        aria-label="Copy link"
                        onClick={copyLink}
                    />
                </Tooltip>
            )}
            {!!duplicateRecord && !showAsDropdown && (
                <Tooltip side="bottom" align="center" content="Duplicate" zIndex={200} asChild>
                    <Button
                        size="s"
                        variant="ghost"
                        startIcon={{ name: 'Copy' }}
                        aria-label="Duplicate"
                        onClick={duplicateRecord}
                    />
                </Tooltip>
            )}
            {!!deleteRecord && !showAsDropdown && (
                <Tooltip side="bottom" align="center" content="Delete" zIndex={200} asChild>
                    <Button
                        size="s"
                        variant="critical"
                        startIcon={{ name: 'Trash2' }}
                        aria-label="Delete"
                        onClick={deleteRecord}
                    />
                </Tooltip>
            )}
            {showAsDropdown && (
                <Dropdown>
                    <DropdownButton
                        size="s"
                        variant="ghost"
                        startIcon={{ name: 'MoreHorizontal' }}
                        aria-label="More actions"
                    />
                    <DropdownContent
                        onClick={stopPropagation}
                        align="end"
                        side="bottom"
                        style={{ minWidth: '186px' }}
                    >
                        {!!copyLink && (
                            <DropdownItem
                                label="Copy link"
                                onClick={copyLink}
                                startIcon={{ name: 'Link' }}
                            />
                        )}
                        {!!duplicateRecord && (
                            <DropdownItem
                                label="Duplicate"
                                onClick={duplicateRecord}
                                startIcon={{ name: 'Copy' }}
                            />
                        )}
                        {!!deleteRecord && (
                            <DropdownItem
                                variant="destructive"
                                label="Delete"
                                onClick={deleteRecord}
                                startIcon={{ name: 'Trash2' }}
                            />
                        )}
                    </DropdownContent>
                </Dropdown>
            )}
        </Box>
    )
}
