import React from 'react'

import { RecordLinksAttributeDisplay } from 'features/views/attributes/RecordLinksAttributeDisplay'
import { RecordLinksAttributeTag } from 'features/views/attributes/RecordLinksAttributeTag'

import { useRecordLinksAttributeState } from './hooks/useRecordLinksAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_RECORD_LINKS = 2
const MAX_RECORD_LINKS_SINGLE_LINE = 1
const MAX_ITEM_LENGTH = 10
const MAX_ITEM_LENGTH_SINGLE = 30
const MAX_LINES = 1

type RecordLinksAttributeProps = {}

export const RecordLinksAttribute: BoardViewAttributeComponent<
    string | string[],
    RecordLinksAttributeProps
> = ({ value, attribute, isLoading, displayStyle, showIcon, ...props }) => {
    const { field, type, rawOptions } = attribute
    const isTitle = type === 'title'
    const isSubtitle = type === 'subtitle'
    const isFooter = type === 'footer'

    const { dereferencedRecords, displayAsTag, isSingle, isEmpty, isTagListAttributeStyle } =
        useRecordLinksAttributeState({
            value,
            isLoading,
            displayStyle,
            attribute,
        })

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} {...props}>
            {displayAsTag ? (
                <RecordLinksAttributeTag
                    size={isTagListAttributeStyle ? 's' : 'm'}
                    value={value}
                    field={field}
                    dereferencedRecords={dereferencedRecords}
                    maxItemLength={isSingle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    isLoading={isLoading}
                    showIcon={showIcon}
                />
            ) : (
                <RecordLinksAttributeDisplay
                    size={{
                        mobile: isTitle ? 'm' : 's',
                        tablet: 'm',
                    }}
                    value={value}
                    field={field}
                    maxLinks={
                        isTitle || isSubtitle ? MAX_RECORD_LINKS_SINGLE_LINE : MAX_RECORD_LINKS
                    }
                    dereferencedRecords={dereferencedRecords}
                    maxLines={MAX_LINES}
                    maxItemLength={isTitle || isSubtitle ? MAX_ITEM_LENGTH : undefined}
                    isLoading={isLoading}
                    showSingleUserName={!rawOptions.hideUserName}
                    minWidth={isFooter ? 0 : undefined}
                />
            )}
        </BaseAttribute>
    )
}
