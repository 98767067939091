import React, { useCallback } from 'react'

import { useAppContext } from 'app/useAppContext'
import { useDataConnection } from 'data/hooks/dataConnections/useDataConnection'
import { invalidateDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useEditDataConnection } from 'data/hooks/dataConnections/useEditDataConnection'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'

type DeleteSyncedObjConfirmationModalProps = {
    isOpen: boolean
    onOpenChange: (open: boolean) => void
    object: ObjectDto
}
export const DeleteSyncedObjConfirmationModal: React.FC<DeleteSyncedObjConfirmationModalProps> = ({
    isOpen,
    onOpenChange,
    object,
}) => {
    const { selectedStack } = useAppContext()
    const toast = useToast()
    const { data: dataConnection } = useDataConnection(object?.data_connection)

    const { mutateAsync: editDataConnection, isLoading: isEditingDataConnection } =
        useEditDataConnection({
            onError: () => {
                toast({
                    title: 'There was a problem deleting the synced object. Please try again or contact support.',
                    type: 'error',
                })
            },
        })
    const handleDelete = useCallback(async () => {
        if (!dataConnection) return

        const newExtObjIds = (dataConnection.external_object_ids ?? []).filter(
            (extObjId: string) => extObjId != object.external_id
        )
        await editDataConnection({
            _sid: dataConnection._sid,
            _object_id: dataConnection._object_id,
            external_object_ids: newExtObjIds,
        })
        invalidateDataConnections()
        onOpenChange(false)
    }, [dataConnection, editDataConnection, object.external_id, onOpenChange])

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                <ModalHeader
                    subtitle={
                        <>
                            Are you sure you want to delete synced table{' '}
                            <Body weight="bold">{object.name}</Body> from the{' '}
                            <Body weight="bold">{selectedStack?.name}</Body> app?
                        </>
                    }
                    title="Delete synced table"
                    showCloseButton={false}
                />
                <ModalFooter flexDirection="column">
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button size="l" variant="ghost">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button
                            size="l"
                            variant="destructive"
                            onClick={handleDelete}
                            isLoading={isEditingDataConnection}
                        >
                            Delete synced table
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
