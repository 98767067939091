export const iconKeywords = {
    '1st-bracket': 'parenthesis, bracket',
    '1st-bracket-circle': '1st-bracket-circle',
    '1st-bracket-square': '1st-bracket-square',
    '2nd-bracket': 'parenthesis, bracket',
    '2nd-bracket-circle': '2nd-bracket-circle',
    '2nd-bracket-square': '2nd-bracket-square',
    '3d-move': '3d-move',
    '3d-rotate': '3d-rotate',
    '3d-scale': '3d-scale',
    '3-d-view':
        'perspective mode, dimensional display, tri-dimensional view, spatial perspective, 3d scene, depth visualization, 3d view, 3d',
    '3rd-bracket': 'parenthesis, bracket',
    '3rd-bracket-circle': '3rd-bracket-circle',
    '3rd-bracket-square': '3rd-bracket-square',
    '4k': '4k',
    abacus: 'abacus',
    absolute: 'absolute, ultimate, perfect, paramount',
    acceleration:
        'speed boost symbol, rapid motion icon, quickening emoji, velocity symbol, fast track emblem, swift movement sign, hastening icon, turbo charge emoji, speedy motion symbol, accelerated pace symbol',
    access: 'access',
    accident: 'accident icon, mishap, emergency, incident, calamity, misfortune, mishandling',
    'account-setting-01':
        'profile-settings, user-options, personal-preferences, account-config, profile-customize, user-configurations, settings-profile, customize-account',
    'account-setting-02':
        'profile-settings, user-options, personal-preferences, account-config, profile-customize, user-configurations, settings-profile, customize-account',
    'account-setting-03':
        'profile-settings, user-options, personal-preferences, account-config, profile-customize, user-configurations, settings-profile, customize-account',
    'activity-01': 'activity-01',
    'activity-02': 'activity-02',
    'activity-03': 'activity-03',
    'activity-04': 'activity-04',
    acute: 'acute, sharp, angular',
    'add-01': 'add, plus, plus sign, add button',
    'add-02': 'add, plus, plus sign, add button',
    'add-circle': 'add, plus, plus sign, add button, add-circle, plus circle',
    'add-circle-half-dot': 'add, plus, plus sign, add button, add half-dot, plus circle half-dot',
    'add-female': 'add-female',
    'add-male': 'add-male',
    'address-book': 'address-book',
    'add-square': 'add, plus, plus sign, add button, add square',
    'add-team': 'add users, group users, invite team',
    'add-team-02': 'add-team-02',
    'add-to-list': 'add-to-list',
    'adobe-after-effect': 'adobe-after-effect',
    'adobe-illustrator': 'adobe-illustrator',
    'adobe-indesign': 'adobe-indesign',
    'adobe-photoshop': 'adobe-photoshop',
    'adobe-premier': 'adobe-premier',
    'adobe-xd': 'adobe-xd',
    adventure: 'adventure',
    advertisiment: 'ad, promote',
    adzan: 'adzan',
    affiliate: 'money, affiliate, dollar, commission',
    'agreement-01': 'hand shake, deal',
    'agreement-02': 'hand shake, deal',
    'agreement-03': 'legal',
    'ai-audio': 'ai-audio',
    'ai-beautify': 'ai-beautify',
    'ai-book': 'ai-book',
    'ai-brain-01': 'ai-brain-01',
    'ai-brain-02': 'ai-brain-02',
    'ai-brain-03': 'ai-brain-03',
    'ai-brain-04': 'ai-brain-04',
    'ai-brain-05': 'ai-brain-05',
    'ai-browser': 'ai-browser',
    'ai-chat-01': 'ai-chat-01',
    'ai-chat-02': 'ai-chat-02',
    'ai-chemistry-01': 'ai-chemistry-01',
    'ai-chemistry-02': 'ai-chemistry-02',
    'ai-chemistry-03': 'ai-chemistry-03',
    'ai-chip': 'ai-chip',
    'ai-cloud': 'ai-cloud',
    'ai-cloud-01': 'ai-cloud-01',
    'ai-cloud-02': 'ai-cloud-02',
    'ai-computer': 'ai-computer',
    'ai-content-generator-01': 'ai-content-generator-01',
    'ai-content-generator-02': 'ai-content-generator-02',
    'ai-dna': 'ai-dna',
    aids: 'aids',
    'ai-editing': 'ai-editing',
    'ai-eraser': 'ai-eraser',
    'ai-file': 'ai-file',
    'ai-folder-01': 'ai-folder-01',
    'ai-folder-02': 'ai-folder-02',
    'ai-game': 'ai-game',
    'ai-generative': 'ai-generative',
    'ai-idea': 'ai-idea',
    'ai-image': 'ai-image',
    'ai-innovation-01': 'ai-innovation-01',
    'ai-innovation-02': 'ai-innovation-02',
    'ai-innovation-03': 'ai-innovation-03',
    'ai-laptop': 'ai-laptop',
    'ai-learning': 'ai-learning',
    'ai-lock': 'ai-lock',
    'ai-magic': 'ai-magic',
    'ai-mail': 'ai-mail',
    'ai-mail-01': 'ai-mail-01',
    'ai-mail-02': 'ai-mail-02',
    'ai-mic': 'ai-mic',
    'ai-network': 'ai-network',
    'ai-phone-01': 'ai-phone-01',
    'ai-phone-02': 'ai-phone-02',
    'ai-programming': 'ai-programming',
    airbnb: 'airbnb',
    'aircraft-game': 'aircraft-game',
    airdrop: 'airdrop',
    'airplane-01':
        'airplane icon, jet, aircraft, flying machine, air shuttle, sky cruiser, aviation symbol, plane, aerial transport',
    'airplane-02':
        'airplane icon, jet, aircraft, flying machine, air shuttle, sky cruiser, aviation symbol, plane, aerial transport',
    'airplane-landing-01':
        'airplane-landing icon, landing-plane, arrival-plane, touchdown-aircraft, lander-jet, descent-flight, approach-aircraft, landfall-plane, touch-down jet, final-descent, touchdown-jet, landing-aircraft, arrival-jet',
    'airplane-landing-02':
        'airplane-landing icon, landing-plane, arrival-plane, touchdown-aircraft, lander-jet, descent-flight, approach-aircraft, landfall-plane, touch-down jet, final-descent, touchdown-jet, landing-aircraft, arrival-jet',
    'airplane-mode': 'airplane-mode',
    'airplane-mode-off': 'airplane-mode-off',
    'airplane-seat': 'airplane-seat',
    'airplane-take-off-01':
        'airplane-take-off icon, flight-departure, jet-launch, aircraft-takeoff, flying-start, departure-plane, lift-off, soaring-jet, air-travel-begin, departure-flight',
    'airplane-take-off-02':
        'airplane-take-off icon, flight-departure, jet-launch, aircraft-takeoff, flying-start, departure-plane, lift-off, soaring-jet, air-travel-begin, departure-flight',
    'airplay-line': 'airplay-line',
    'airpod-01': 'airpod-01',
    'airpod-02': 'airpod-02',
    'airpod-03': 'airpod-03',
    airport: 'airport',
    'ai-scan': 'ai-scan',
    'ai-scheduling': 'ai-scheduling',
    'ai-search': 'ai-search',
    'ai-search-02': 'ai-search-02',
    'ai-security-01': 'ai-security-01',
    'ai-security-02': 'ai-security-02',
    'ai-security-03': 'ai-security-03',
    'ai-setting': 'ai-setting',
    'ai-sheets': 'ai-sheets',
    'ai-smartwatch': 'ai-smartwatch',
    'ai-user': 'ai-user',
    'ai-video': 'ai-video',
    'ai-view': 'ai-view',
    'ai-vision-recognition': 'ai-vision-recognition',
    'ai-voice': 'ai-voice',
    'ai-voice-generator': 'ai-voice-generator',
    'ai-web-browsing': 'ai-web-browsing',
    'al-aqsa-mosque': 'al-aqsa-mosque',
    'alarm-clock':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock',
    'album-01': 'images, photos',
    'album-02': 'images, photos',
    'album-not-found-01': 'no images, no photos',
    'album-not-found-02': 'no images, no photos',
    'alert-01':
        'alert, warning, attention, caution, exclamation, advisory, signal, alarm, indicator, prompt, reminder, announcement, signalization, alertness, notifier, vigilance, admonition, heads-up, alert signal, warning symbol.',
    'alert-02':
        'alert, warning, attention, caution, exclamation, advisory, signal, alarm, indicator, prompt, reminder, announcement, signalization, alertness, notifier, vigilance, admonition, heads-up, alert signal, warning symbol.',
    'alert-circle':
        'alert, warning, attention, caution, exclamation, advisory, signal, alarm, indicator, prompt, reminder, announcement, signalization, alertness, notifier, vigilance, admonition, heads-up, alert signal, warning symbol.alert-circle',
    'alert-diamond':
        'alert-diamond, alert, warning, attention, caution, exclamation, advisory, signal, alarm, indicator, prompt, reminder, announcement, signalization, alertness, notifier, vigilance, admonition, heads-up, alert signal, warning symbol.alert-circle',
    'alert-square':
        'alert, warning, attention, caution, exclamation, advisory, signal, alarm, indicator, prompt, reminder, announcement, signalization, alertness, notifier, vigilance, admonition, heads-up, alert signal, warning symbol. alert-square',
    algorithm: 'algorithm',
    'alien-01':
        'extraterrestrial, visitor, spaceling, et, martian, cosmic being, outerworldly, interstellar traveler, space creature, star dweller, otherworldly being, celestial voyager, ufo inhabitant, galactic entity, astral being',
    'alien-02':
        'extraterrestrial, visitor, spaceling, et, martian, cosmic being, outerworldly, interstellar traveler, space creature, star dweller, otherworldly being, celestial voyager, ufo inhabitant, galactic entity, astral being',
    'align-bottom':
        'bottom align, align to bottom, lower alignment, baseline align, downward alignment, bottom alignment, align bottom, bottom align, align',
    'align-box-bottom-center':
        'centered bottom alignment, bottom-centered align box, align to bottom center, box align bottom middle, centered box at bottom, bottom-centered box alignment, align box to bottom center, box aligned bottom middle, centered alignment at bottom, bottom center box alignment',
    'align-box-bottom-left': 'align-box-bottom-left',
    'align-box-bottom-right':
        '"bottom right alignment, align to bottom right corner, position in bottom right, box alignment lower right, corner alignment bottom right, bottom right box alignment, right-bottom positioning, align box to lower right, bottom right box placement, align box bottom right',
    'align-box-middle-center':
        'centered box alignment, middle center align, box center alignment, central box position, centered content box, middle align box, center align box, box middle centered, central box alignment, center aligned box, align box middle center',
    'align-box-middle-left':
        'box center left align, horizontal middle align left, centered box align left, middle-left box alignment, left aligned box center, align to left center box, middle aligned left box, left aligned middle box, box left middle alignment, align box middle left',
    'align-box-middle-right':
        'center right align, middle right alignment, box middle right align, right-centered box, horizontal center right, middle right box position, align to right center, right middle alignment, central right box placement, right aligned box center," rightward middle alignment, align box middle right',
    'align-box-top-center':
        'top center alignment, centered box alignment, top-centered box, centralized box alignment, box align top-center, centered box arrangement, align to top-center, top middle alignment, top central box alignment, align box top center',
    'align-box-top-left':
        'align box top left, top left alignment, upper left align, box align top left corner, align to top left, corner alignment top left, top left box alignment',
    'align-box-top-right':
        'top right alignment box, box align right top, right top box align, align to top right, upper right box align, box aligned at top right, rightward top box alignment, align box towards top right, top-right aligned box, box alignment right up, align box top right',
    'align-horizontal-center':
        'enter horizontal alignment, middle horizontal alignment, align center horizontally, horizontal center alignment, centered horizontal alignment, horizontal centering, center alignment horizontal, align vertical center, align',
    'align-key-object':
        'object alignment tool, key object aligner, object key align, aligner tool, key align object, object key alignment, key element align, object key centering, object key positioner, key element alignment, align kew object',
    'align-left':
        'align left, left align, align to the left, leftward alignment, ext align left, flush left, justify left, align text left, left text alignment, eftward text align, align content left, align, align left, left align, align',
    'align-right':
        'right justify, right align, align to the right, text alignment right, rightward alignment, right-side alignment, align flush right, justify to the right, text right alignment, right position, rightward positioning, rightward justify, align right, right align, align',
    'align-selection':
        'center selection, adjust selection, position selection, arrange selection, organize selection, line up selection, place selection, set selection, group selection, order selection, orient selection, match selection, align chosen, align picked, align marked, align selection',
    'align-top':
        'top align, top position, upper alignment, highest position, uppermost align, vertical alignment, peak alignment, highest placement, upper boundary, align top, top align, align',
    'align-vertical-center':
        'center vertically, vertical middle align, align to middle vertically, vertical centering, center vertically, middle alignment, central vertical alignment, vertical center align, vertical middle, centered vertically, align center vertically, vertical center alignment, vertical centered position, align middle vertically, vertical alignment center, vertical centering, vertical central alignment, vertical centered alignment, vertical center aligning, align vertical center',
    allah: 'allah',
    'all-bookmark': 'bookmark, book,\u00a0later,\u00a0marker,\u00a0reading,\u00a0save,\u00a0tag',
    alms: 'alms',
    alpha: 'alpha',
    'alphabet-arabic':
        'arabic letters, arabic characters, arabic script, islamic alphabet, middle eastern alphabet, arabic language symbols, script of the arab world, alphabet in arabic style, alphabet arabic',
    'alphabet-bangla':
        'bangla letters, bengali alphabet, bangla script, \u09ac\u09be\u0982\u09b2\u09be \u09ac\u09b0\u09cd\u09a3\u09ae\u09be\u09b2\u09be, bangla writing, \u09ac\u09be\u0982\u09b2\u09be \u09b2\u09c7\u0996\u09be, bangla symbols, \u09ac\u09be\u0982\u09b2\u09be \u0985\u0995\u09cd\u09b7\u09b0, bangla characters, bengali language, bangla text, alphabet bangla',
    'alphabet-chinese':
        'chinese characters, mandarin symbols, hanzi letters, oriental alphabet, asian script, pinyin glyphs, kanji characters, logographic symbols, sinograph symbols, east asian writing, traditional script, calligraphic letters, ideographic characters, han script, alphabet chinese',
    'alphabet-greek':
        'greek alphabet, greek letters, hellenic alphabet, alphabeta symbols, grecian characters, greek script, hellenic writing, greek symbol set, greek language icons, alphabeta icons, alphabet greek',
    'alphabet-hebrew':
        'hebrew letters, aleph bet, jewish alphabet, sacred script, torah writing, biblical characters, israeli characters, holy language, semitic script, judaic symbols. alphabet hebrew',
    'alphabet-hindi':
        'hindi lettering, indian script characters, devanagari alphabets,  hindi alphabetical symbols, sanskrit script icons, indian language characters, hindi script letters, devanagari text symbols, hindi writing system, alphabet hindi',
    'alphabet-japanese':
        'apanese alphabet, hiragana chart, kana characters, kanji script, eastern characters, nihongo letters, asian lettering, oriental alphabet, japanese writing system, hiragana & katakana, kanji symbols, asian script, alphabet japanese',
    'alphabet-korean':
        'korean characters, hangul script, korean writing system, korean alphabet, hangul letters, korean symbols, korean text, korean script, hangul alphabet, \ud55c\uad6d\uc5b4 characters, korean glyphs, alphabet korean',
    'alphabet-thai':
        'hai alphabet, thai writing, siamese alphabet, thai lettering, thai language, thai text, thai symbols, thai calligraphy, thai typography, alphabet thai',
    'alpha-circle': 'alpha-circle',
    'alpha-square': 'alpha-square',
    amazon: 'amazon',
    ambulance:
        'ambulance icon, emergency vehicle, medical transport, rescue car, paramedic van, lifesaving transport, emt vehicle, hospital ambulance, emergency response vehicle, medical ambulance, ambulance transport',
    'american-football': 'american-football',
    amie: 'amie',
    ampoule: 'ampoule',
    'analysis-text-link': 'report, overview',
    'analytics-01': 'report, overview, dashboard, bar, graph, chart',
    'analytics-02': 'report, overview, dashboard, bar, graph, chart',
    'analytics-down': 'report, overview, dashboard, bar, graph, chart',
    'analytics-up': 'report, overview, dashboard, bar, graph, chart',
    'anchor-point':
        'fixed position, reference spot, tether spot, secure point, pivot marker, stabilization point, central hub, docking point, focal node, mooring spot, attachment marker, core junction, anchor point',
    android: 'android',
    angel: 'halo symbol, cherub icon, heavenly figure, celestial emoticon, divine messenger, seraphic symbol, winged spirit, blessed emoji, ethereal icon, guardian symbol',
    angle: 'arrowhead, angle, angular, arrow',
    'angle-01': 'arrowhead, angle, angular, arrow',
    angry: 'fuming face, rage symbol, mad emoticon, wrathful icon, furious emoji, irritated face, enraged symbol, angry symbol, livid emoticon, outraged emoji',
    'angry-bird': 'angry-bird',
    anonymous:
        'incognito, hidden, masked, ghost, concealed, veiled, unnamed, stealth, secret, unknown, anonymous',
    api: 'api',
    apple: 'apple',
    'apple-01': 'apple-01',
    'apple-finder': 'apple-finder',
    'apple-intelligence': 'apple-intelligence',
    'apple-news': 'apple-news',
    'apple-pie': 'apple-pie',
    'apple-reminder': 'apple-reminder',
    'apple-stocks': 'apple-stocks',
    'apple-vision-pro': 'apple-vision-pro',
    'appointment-01': 'booking, calendar booked, scheduled',
    'appointment-02': 'booking, calendar booked, scheduled',
    'approximately-equal': 'approximately-equal',
    'approximately-equal-circle': 'approximately-equal-circle',
    'approximately-equal-square': 'approximately-equal-square',
    'app-store': 'app-store',
    apricot: 'apricot',
    apron: 'apron',
    'arc-browser': 'arc-browser',
    archer: 'archer',
    archive: 'archive',
    'archive-01': 'archive-01',
    'archive-02': 'archive-02',
    'armored-boot': 'armored-boot',
    arrange:
        'sort, organize, rearrange, order, align, categorize, position, arrange & align, group, manage, structure, reorder, set in order, arrange items, arrange',
    'arrange-by-letters-a-z':
        'alphabetical order, sort alphabetically, a to z sorting, alphabet sort, order by letters, az arrangement, letter sequence, abc categorization, character organization, alphabetize, alphabetic sorting, sequential letters, arrange by letters a z',
    'arrange-by-letters-z-a':
        'alphabetical order, sort alphabetically, a to z sorting, alphabet sort, order by letters, az arrangement, letter sequence, abc categorization, character organization, alphabetize, alphabetic sorting, sequential letters, arrange by letters z a',
    'arrange-by-numbers-1-9':
        'numeric sorting, order by numerals, sequence organize, numerical arrangement, sort with numbers, 1-9 ordering, numeric sequence, numbered sorting, numerical sort, arrange by numbers 1 9',
    'arrange-by-numbers-9-1':
        'sort by numerical order, order by 9 to 1, number-based sorting, sequence 9 to 1, numeric arrangement, 9-1 ordering, ascending number sort, numerical ranking 9-1, arrange sequentially 9-1, arrange by numbers 9 1',
    'arrow-all-direction':
        'arrow-all-direction, minimize-arrow, expand-arrow, directional-arrow, resize-arrow, universal-arrow, shrinkage-indicator, multi-direction-arrow, compact-arrow, big screen, full screen',
    'arrow-data-transfer-diagonal': 'arrow-data-transfer-diagonal',
    'arrow-data-transfer-horizontal':
        'arrow-data-transfer-horizontal, transfer, horizontal data transfer arrow, data exchange arrow, transfer data icon, horizontal transfer arrow, data flow arrow, data link arrow, horizontal data sync icon, file transfer arrow, data transmission arrow, horizontal data mover icon, information transfer arrow, data share arrow, horizontal file exchange icon',
    'arrow-data-transfer-vertical': 'arrow-data-transfer-vertical',
    'arrow-diagonal': 'big screen, full screen, expand, enlarge',
    'arrow-down-01':
        'down-arrow, arrow, down, decrease arrow, drop arrow, drop down, drop down arrow, chevron',
    'arrow-down-02': 'downward arrow, arrow pointing down, lower arrow, decrease arrow',
    'arrow-down-03': 'return to bottom, arrow to descend, go backward, navigate downward',
    'arrow-down-04': 'arrow-down-04',
    'arrow-down-05': 'arrow-down-05',
    'arrow-down-double':
        'arrow-down-double, double-arrow-down, downward-double-arrow, dual-arrow-down, twin-arrow-down, dual-chevron-down, double-pointing-down, chevron',
    'arrow-down-left-01':
        'down-left arrow, diagonal down arrow, southwestern arrow, lower-left pointing arrow, descending left arrow',
    'arrow-down-left-02': 'arrow-down-left-02',
    'arrow-down-right-01': 'lower right direction arrow, diagonal descent arrow',
    'arrow-down-right-02': 'arrow-down-right-02',
    'arrow-expand':
        'shrink-arrow, minimize-icon, compress-arrow, reduce-symbol, collapse-indicator, condense-arrow,\\nexpand-arrow, maximize-icon, enlarge, unfold-arrow, big screen, full screen',
    'arrow-expand-01': 'big screen, full screen, expand, enlarge',
    'arrow-expand-02': 'big screen, full screen, expand, enlarge',
    'arrow-expand-diagonal-01': 'enlarge, big, expand screen',
    'arrow-expand-diagonal-02': 'enlarge, big, expand screen',
    'arrow-horizontal': 'big screen, full screen, expand, enlarge',
    'arrow-left-01':
        'left-arrow, back-arrow, arrow, leftward indicator, go back, back navigation, chevron',
    'arrow-left-02':
        'left arrow, back arrow, previous arrow, navigate left, return arrow, backward arrow',
    'arrow-left-03': 'arrow-to-left, leftward arrow, back arrow, navigate left',
    'arrow-left-04': 'arrow-left-04',
    'arrow-left-05': 'arrow-left-05',
    'arrow-left-double':
        'arrow-left-double, double-left-arrow, backward-arrow, reverse-arrow, leftward-arrow, undo-arrow, previous-arrow, retreat-arrow, dual-left-arrow, recede-arrow, backtrack-arrow, double-back-arrow, chevron',
    'arrow-left-right': 'arrow-left-right',
    'arrow-move-down-left': 'arrow-move-down-left',
    'arrow-move-down-right': 'arrow-move-down-right',
    'arrow-move-left-down': 'arrow-move-left-down',
    'arrow-move-right-down': 'arrow-move-right-down',
    'arrow-move-up-left':
        'move-diagonal-arrow, data-transfer-arrow, diagonal-up-arrow, horizontal-transfer-arrow, transfer-arrow-up-left, data-arrow-diagonal, up-left-move-icon, horizontal-data-arrow, diagonal-transfer-arrow, left-upward-arrow.',
    'arrow-move-up-right':
        'arrow-move-up-right, upward-diagonal-arrow, diagonal-transfer-arrow, northeast-arrow,\\nhorizontal-data-transfer, data-exchange-arrow, side-to-side-arrow.',
    'arrow-reload-horizontal':
        'refresh-arrow, sync-arrow, update-arrow, circular-arrow, loop-arrow, reload-circle, transfer-arrow-horizontal, data-sync-arrow, horizontal-transfer, data-exchange-arrow, data-refresh-arrow, circular-data-transfer, swap-arrow-horizontal, sync-data-arrow, cyclic-arrow, exchange-arrow, loop-data-arrow, refresh, arrow reload',
    'arrow-reload-vertical':
        'refresh-arrow, sync-arrow, update-arrow, circular-arrow, loop-arrow, reload-circle, transfer-arrow-horizontal, data-sync-arrow, horizontal-transfer, data-exchange-arrow, data-refresh-arrow, circular-data-transfer, swap-arrow-horizontal, sync-data-arrow, cyclic-arrow, exchange-arrow, loop-data-arrow.refresh, arrow reload',
    'arrow-right-01':
        'right-arrow, next-arrow, navigate-right, move-forward, right-direction, chevron',
    'arrow-right-02': 'arrow, next-arrow, move-right, navigate-right, right-direction',
    'arrow-right-03':
        'arrow-to-right, right direction, next, navigate right, move right, advance, right arrow, progress, go forward, forward arrow, right shift, right move, right-way, arrow forward, forward motion, right glide, right path, right journey, right route, right trail, right trek, right course, right advance, right march, right step',
    'arrow-right-04': 'arrow-right-04',
    'arrow-right-05': 'arrow-right-05',
    'arrow-right-double':
        'arrow-right-double, double-right-arrow, forward-double-arrow, right-arrow-twin, dual-arrow-right, paired-arrow-forward, twin-arrow-right, double-forward-arrow, double-arrow-to-right, arrow-right-pair, chevron',
    'arrow-shrink':
        'arrow-shrink, reduce-arrow, transfer-arrow, minimize-arrow, data-move-arrow, compress-arrow, share-arrow-horizontal, condense-arrow, exchange-arrow-horizontal, collapse-arrow, convey-arrow-horizontal',
    'arrow-shrink-01': 'collapse, minimize screen',
    'arrow-shrink-02': 'collapse, minimize screen',
    'arrow-turn-backward':
        'arrow-turn-backward, rewind-arrow, reverse-arrow, back-arrow, u-turn-arrow, left-turn-arrow, retro-arrow, revert-arrow, backward-pointing-arrow, return-arrow',
    'arrow-turn-down':
        'arrow-turn-down, arrow-point-down, downward-arrow, descend-arrow, downturn-arrow, drop-arrow, fall-arrow, lower-arrow, descend-indicator, move-down-arrow, go-down-arrow, downward-pointing-arrow',
    'arrow-turn-forward':
        'arrow-turn-forward, forward-arrow, next-arrow, proceed-arrow, advance-arrow, navigate-arrow, move-forward, progress-arrow, step-forward, continue-arrow, onward-arrow, shift-ahead, propel-arrow, go-forward',
    'arrow-turn-up':
        'arrow-turn-up,turn up, upward-arrow, direction-change-up, flip-arrow-up, ascend-arrow, vertical-turn-arrow, elevate-arrow, northward-arrow, climb-arrow, rise-arrow, shift-up-arrow',
    'arrow-up-01': 'drop up-arrow, up arrow, arrow, upper direction arrow, increase arrow, chevron',
    'arrow-up-02': 'upward arrow, directional up, navigate up, up-arrow',
    'arrow-up-03':
        'back to top, up arrow, scroll up, up arrow, return to top, top scroll, back top',
    'arrow-up-04': 'arrow-up-04',
    'arrow-up-05': 'arrow-up-05',
    'arrow-up-double':
        'double-arrow-up, up-arrow-pair, dual-up-arrow, vertical-twin-arrows, paired-upward-arrows, ascent-duo, double-upward-chevron, chevron',
    'arrow-up-down': 'arrow-up-down',
    'arrow-up-left-01':
        'diagonal-arrow-up-left, upper-left-arrow, leftward-up-arrow, diagonal-up-left-arrow',
    'arrow-up-left-02': 'arrow-up-left-02',
    'arrow-up-right-01':
        'up right arrow, upward diagonal arrow, diagonal up arrow, upper right direction arrow',
    'arrow-up-right-02': 'arrow-up-right-02',
    'arrow-up-right-03': 'arrow-up-right-03',
    'arrow-vertical': 'big screen, full screen, expand, enlarge',
    artboard:
        'creation board,  drawing panel, visual board, drafting area, layout board, artboard, art board',
    'artboard-tool':
        'canvas creator, design board, layout generator, drawing space, template maker, page constructor, sketch pad, blueprint generator, visual planner, composition tool, artboard tool, art board tool',
    'artificial-intelligence-01': 'artificial-intelligence-01',
    'artificial-intelligence-02': 'artificial-intelligence, ai',
    'artificial-intelligence-03': 'ai',
    'artificial-intelligence-04': 'artificial-intelligence, ai',
    'artificial-intelligence-05': 'artificial-intelligence, ai',
    'artificial-intelligence-06': 'artificial-intelligence, ai',
    'artificial-intelligence-07': 'artificial-intelligence-07',
    'artificial-intelligence-08': 'artificial-intelligence-08',
    'aspect-ratio':
        'proportion selector, scale control, dimension modifier, screen ratio adjuster, size ratio setter, image scaling tool, ratio resizer, aspect adjustment, proportion modifier, aspect ration',
    assignments: 'assignments',
    'asteroid-01':
        'space rock, cosmic stone, meteorite, celestial pebble, star shard, galaxy stone, astral fragment, comet core, lunar debris, stellar rock',
    'asteroid-02':
        'space rock, cosmic stone, meteorite, celestial pebble, star shard, galaxy stone, astral fragment, comet core, lunar debris, stellar rock',
    'astronaut-01':
        'space explorer, cosmonaut, star voyager, celestial traveler, lunar pioneer, galaxy rover, cosmic adventurer',
    'astronaut-02':
        'space explorer, cosmonaut, star voyager, celestial traveler, lunar pioneer, galaxy rover, cosmic adventurer',
    at: 'at',
    'atm-01': 'atm-01',
    'atm-02': 'atm-02',
    'atom-01':
        'atomic symbol, molecular icon, particle emblem, nuclear glyph, electron symbol, chemistry emoji, proton emblem, neutron icon, atomic model, molecular structure',
    'atom-02':
        'atomic symbol, molecular icon, particle emblem, nuclear glyph, electron symbol, chemistry emoji, proton emblem, neutron icon, atomic model, molecular structure',
    'atomic-power': 'atomic',
    attachment: 'attachment, file, attach',
    'attachment-01':
        'paperclip, link, fasten, connect, affix, clip, join, tether, annex, bind, hitch, clasp, secure, hook, tie, attachment',
    'attachment-02':
        'paperclip, link, fasten, connect, affix, clip, join, tether, annex, bind, hitch, clasp, secure, hook, tie, attachment',
    'attachment-circle':
        'paperclip, link, fasten, connect, affix, clip, join, tether, annex, bind, hitch, clasp, secure, hook, tie, attachment, attachment circle, circle attachment',
    'attachment-square':
        'paperclip, link, fasten, connect, affix, clip, join, tether, annex, bind, hitch, clasp, secure, hook, tie, attachment, attachment square, square attachment',
    auction: 'legal',
    'audio-book-01':
        'listen now, narration, read aloud, voice text, audio read, sound book, listen & read, spoken word, tale talk, story sound, auditory book, audio readings, listen pages, vocal story, audio book, book, sound book',
    'audio-book-02':
        'listen now, narration, read aloud, voice text, audio read, sound book, listen & read, spoken word, tale talk, story sound, auditory book, audio readings, listen pages, vocal story, audio book, book, sound book, music book',
    'audio-book-03':
        'listen now, narration, read aloud, voice text, audio read, sound book, listen & read, spoken word, tale talk, story sound, auditory book, audio readings, listen pages, vocal story, audio book, book, sound book, music book, media, media book',
    'audio-book-04':
        'listen now, narration, read aloud, voice text, audio read, sound book, listen & read, spoken word, tale talk, story sound, auditory book, audio readings, listen pages, vocal story, audio book, book, sound book, music book, media, media book, head set book, headphone',
    'audit-01': 'legal',
    'audit-02': 'audit-02',
    'augmented-reality-ar': 'augmented-reality-ar',
    authorized: 'authorized',
    'auto-conversations': 'up, price high, trade up, level',
    'automotive-battery-01': 'battery, energy, power',
    'automotive-battery-02': 'battery, energy, power',
    avalanche: 'snow slide, avalanche, landslide, snow',
    avocado: 'avocado',
    'award-01': 'award, trophy, winner, cup, award',
    'award-02': 'award, prize, star award, badge',
    'award-03': 'award, prize, badge',
    'award-04': 'reward, award, badge',
    'award-05': 'reward, award, badge',
    'baby-01': 'baby-01',
    'baby-02': 'baby-02',
    'baby-bed-01': 'baby-bed-01',
    'baby-bed-02': 'baby-bed-02',
    'baby-bottle': 'baby-bottle',
    'baby-boy-dress': 'baby-boy-dress',
    'baby-girl-dress': 'baby-girl-dress',
    'backpack-01':
        'knapsack, rucksack, pack, schoolbag, book bag, carryall, daypack, haversack, travel pack, kitbag, school bag, bag, education, tour',
    'backpack-02':
        'knapsack, rucksack, pack, schoolbag, book bag, carryall, daypack, haversack, travel pack, kitbag, school bag, bag, education, tour',
    'backpack-03':
        'knapsack, rucksack, pack, schoolbag, book bag, carryall, daypack, haversack, travel pack, kitbag, school bag, bag, education, tour',
    'backward-01': 'backward',
    'backward-02': 'backward',
    bacteria:
        'microbe symbol, germ icon, tiny organism, microbial emoticon, pathogen symbol, microscopic creature, bacterial emblem, virus icon, minuscule bug, contagion symbol, tiny pathogen, microbial sign, infectious symbol, tiny germ',
    badminton: 'badminton',
    'badminton-shuttle': 'badminton-shuttle',
    'balance-scale': 'balance-scale',
    balloons: 'balloons',
    banana: 'banana',
    bandage: 'bandage',
    bank: 'business',
    'bar-chart': 'bar-chart',
    'bar-chart-horizontal': 'bar-chart-horizontal',
    'bar-code-01': 'barcode, scan-code, label-bar, product-scan, id',
    'bar-code-02': 'barcode, scan-code, label-bar, product-scan, id',
    barns: 'barns',
    baseball: 'baseball',
    'baseball-bat': 'baseball-bat',
    'baseball-helmet': 'baseball-helmet',
    bash: 'bash',
    'basketball-01': 'basketball-01',
    'basketball-02': 'basketball-02',
    'basketball-hoop': 'basketball-hoop',
    'bathtub-01': 'bathtub-01',
    'bathtub-02': 'bathtub-02',
    'batteries-energy': 'battery, energy, power',
    'battery-charging-01':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery, battery charging, charging battery',
    'battery-charging-02':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery, battery charging, charging battery',
    'battery-eco-charging':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery, battery eco charging, charging eco battery',
    'battery-empty':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery empty, empty battery, battery',
    'battery-full':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery, battery full, full battery',
    'battery-low':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery empty, empty battery, battery, battery low, low battery',
    'battery-medium-01':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery, battery medium, medium battery',
    'battery-medium-02':
        'power source, energy level, charge indicator, cell status, battery life, power gauge, energy reserve, charge level, electrical capacity, battery, battery medium, medium battery',
    'bbq-grill': 'bbq-grill',
    beach: 'beach',
    'beach-02': 'beach-02',
    beater: 'beater',
    bebo: 'bebo',
    bed: 'bed',
    'bed-bunk': 'bed-bunk',
    'bed-double': 'bed-double',
    'bed-single-01': 'bed-single-01',
    'bed-single-02': 'bed-single-02',
    'bedug-01': 'bedug-01',
    'bedug-02': 'bedug-02',
    'behance-01': 'behance-01',
    'behance-02': 'behance-02',
    belt: 'belt',
    'bend-tool':
        'bend tool, curve tool, flex tool, reshape tool, twist tool, contour tool, morph tool, manipulate tool, curve adjuster, flexify tool, bend adjuster, tool bend',
    berlin: 'berlin',
    'berlin-tower': 'berlin-tower',
    beta: 'beta',
    bicycle: 'bicycle',
    'bicycle-01':
        'bicycle icon, bike, cycle, two-wheeler, pedal bike, velocipede, wheeled transport, pedal-powered vehicle, riding machine, pushbike, pedaler, two-wheel motion',
    'billiard-01': 'billiard-01',
    'billiard-02': 'billiard-02',
    'binary-code': 'binary-code',
    bing: 'bing',
    'bio-energy': 'energy, bio energy',
    'biomass-energy': 'biomass, battery, energy, power',
    'biometric-access': 'biometric-access',
    'biometric-device': 'biometric-device',
    'birthday-cake': 'birthday-cake',
    biscuit: 'biscuit',
    bitcoin: 'bitcoin',
    'bitcoin-01':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money',
    'bitcoin-02':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money',
    'bitcoin-03':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money',
    'bitcoin-04':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money',
    'bitcoin-bag':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin bag, coin bag, bag',
    'bitcoin-circle': 'bitcoin-circle',
    'bitcoin-cloud':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin cloud, cloud bitcoin',
    'bitcoin-cpu':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin cpu, cpu bitcoin',
    'bitcoin-credit-card':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin credit card, credit card bitcoin',
    'bitcoin-database':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin database, database bitcoin',
    'bitcoin-down-01':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin down, down bitcoin',
    'bitcoin-down-02':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin down, down bitcoin',
    'bitcoin-ellipse':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin ellipse, ellipse bitcoin',
    'bitcoin-eye':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin eye, eye bitcoin, eye, money eye, eye money',
    'bitcoin-filter':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin filter, filter bitcoin, filter',
    'bitcoin-flashdisk': 'bitcoin-flashdisk',
    'bitcoin-graph':
        'digital gold, crypto coin, btc symbol, blockchain currency, virtual currency, decentralized money, electronic cash, cryptocurrency emblem, btc logo, bitcoin, bit coin, coin, currency, money, bitcoin graph, graph bitcoin, graph, money graph, graph money',
    'bitcoin-invoice': 'bitcoin-invoice',
    'bitcoin-key': 'bitcoin-key',
    'bitcoin-location': 'bitcoin-location',
    'bitcoin-lock': 'bitcoin-lock',
    'bitcoin-mail': 'bitcoin-mail',
    'bitcoin-mind': 'bitcoin-mind',
    'bitcoin-money-01': 'bitcoin-money-01',
    'bitcoin-money-02': 'bitcoin-money-02',
    'bitcoin-pie-chart': 'bitcoin-pie-chart',
    'bitcoin-piggy-bank': 'bitcoin-piggy-bank',
    'bitcoin-presentation': 'bitcoin-presentation',
    'bitcoin-receipt': 'bitcoin-receipt',
    'bitcoin-receive': 'bitcoin-receive',
    'bitcoin-rectangle': 'bitcoin-rectangle',
    'bitcoin-safe': 'bitcoin-safe',
    'bitcoin-search': 'bitcoin-search',
    'bitcoin-send': 'bitcoin-send',
    'bitcoin-setting': 'bitcoin-setting',
    'bitcoin-shield': 'bitcoin-shield',
    'bitcoin-shopping': 'bitcoin-shopping',
    'bitcoin-smartphone-01': 'bitcoin-smartphone-01',
    'bitcoin-smartphone-02': 'bitcoin-smartphone-02',
    'bitcoin-square': 'bitcoin-square',
    'bitcoin-store': 'bitcoin-store',
    'bitcoin-tag': 'bitcoin-tag',
    'bitcoin-target': 'bitcoin-target',
    'bitcoin-transaction':
        'crypto transfer, btc payment, digital exchange, blockchain transaction, coin transfer, virtual currency movement, cryptocurrency transaction, bitcoin transaction, transaction, transaction bitcoin',
    'bitcoin-up-01':
        'btc increase, crypto surge, coin boost, rising bitcoi, upward crypto, btc uptrend, crypto climb, coin upswing, bitcoin rise, crypto up, coin surge, up bitcoin, bitcoin up',
    'bitcoin-up-02':
        'btc increase, crypto surge, coin boost, rising bitcoi, upward crypto, btc uptrend, crypto climb, coin upswing, bitcoin rise, crypto up, coin surge, up bitcoin, bitcoin up',
    'bitcoin-wallet':
        'crypto purse, digital coin wallet, btc wallet, cryptocurrency vault, virtual currency purse, blockchain wallet, digital money holder, crypto safe, coin stash, electronic wallet, bitcoin wallet, wallet',
    'bitcoin-withdraw':
        'crypto cash out, btc withdrawal, digital currency withdraw, bitcoin cashout, withdraw bitcoin, cryptocurrency withdrawal, bitcoin exit, btc cash withdrawal, crypto withdrawal, bitcoin outflow, bitcoin transfer out, withdraw btc, cryptocurrency cashout, bitcoin disbursement, digital money withdrawal, bitcoin liquidation, btc removal, cryptocurrency cashback, bitcoin withdraw, withdraw',
    'black-hole':
        'abyss, void, dark vortex, cosmic whirl, deep void, event horizon, singularity, celestial pit, space well, gravitational sinkhole, stellar abyss',
    'black-hole-01':
        'void vortex, cosmic funnel, abyssal pit, dark whirlpool, singularity sphere, infinite chasm, eclipse center, event horizon, shadowy vacuum, deep abyss',
    blend: 'mix, merge, combine, unify, fuse, integrate, mingle, harmonize, amalgamate, blendify, blend',
    blender: 'blender',
    'blockchain-01':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    'blockchain-02':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    'blockchain-03':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    'blockchain-04':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    'blockchain-05':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    'blockchain-06':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    'blockchain-07':
        'ledger, cryptochain, digital ledger, chainlink, crypto record, secure link, blockchain, block chain, crypto blockchain',
    blocked: 'blocked',
    'block-game': 'block-game',
    blogger: 'blogger',
    blood: 'blood',
    'blood-bag': 'blood-bag',
    'blood-bottle': 'blood-bottle',
    'blood-pressure': 'blood-pressure',
    'blood-type': 'blood-type',
    bluetooth: 'bluetooth',
    'bluetooth-circle': 'bluetooth-circle',
    'bluetooth-not-connected': 'bluetooth-not-connected',
    'bluetooth-search': 'bluetooth-search',
    'bluetooth-square': 'bluetooth-square',
    blur: 'soft-focus, hazy, obscured, misty, defocused, obscured, unclear, foggy, indistinct, fuzzy, smudged, vague, nebulous, cloudy, muddled, faint, blur',
    'blush-brush-01': 'blush-brush-01',
    'blush-brush-02': 'blush-brush-02',
    'board-math': 'board-math',
    boat: 'boat icon, watercraft, vessel, ship, yacht, ferry, cruiser, sailboat, maritime transport, nautical craft, seafaring icon',
    'body-armor': 'body-armor',
    'body-part-leg': 'body-part-leg',
    'body-part-muscle': 'body-part-muscle',
    'body-part-six-pack': 'body-part-six-pack',
    'body-soap': 'body-soap',
    'body-weight': 'body-weight',
    bomb: 'bomb',
    'bone-01': 'bone-01',
    'bone-02': 'bone-02',
    'book-01':
        'reading material, encyclopedia, notebook, literature, textbook, manuscript, storybook, paperback, hardcover, novel, almanac, reference, anthology, folio, tome, libro, codex, compendium, journal, chronicle, book, read, reading book',
    'book-02':
        'reading material, encyclopedia, notebook, literature, textbook, manuscript, storybook, paperback, hardcover, novel, almanac, reference, anthology, folio, tome, libro, codex, compendium, journal, chronicle, book, read, reading book',
    'book-03':
        'reading material, encyclopedia, notebook, literature, textbook, manuscript, storybook, paperback, hardcover, novel, almanac, reference, anthology, folio, tome, libro, codex, compendium, journal, chronicle, book, read, reading book',
    'book-04':
        'reading material, encyclopedia, notebook, literature, textbook, manuscript, storybook, paperback, hardcover, novel, almanac, reference, anthology, folio, tome, libro, codex, compendium, journal, chronicle, book, read, reading book',
    'book-bookmark-01': 'book-bookmark-01',
    'book-bookmark-02': 'book-bookmark-02',
    'book-download': 'book-download',
    'book-edit': 'book-edit',
    'bookmark-01': 'bookmark, book,\u00a0later,\u00a0marker,\u00a0reading,\u00a0save,\u00a0tag',
    'bookmark-02': 'bookmark, book,\u00a0later,\u00a0marker,\u00a0reading,\u00a0save,\u00a0tag',
    'bookmark-03': 'save',
    'bookmark-add-01':
        'add bookmark, book, add\u00a0later,\u00a0marker,\u00a0reading,\u00a0save,\u00a0tag',
    'bookmark-add-02': 'add bookmark, add later, add reading',
    'bookmark-block-01': 'block bookmark',
    'bookmark-block-02': 'bookmark-block-02',
    'bookmark-check-01': 'check bookmark,\u00a0check\u00a0marker',
    'bookmark-check-02': 'bookmark-check-02',
    'bookmark-minus-01': 'bookmark minus, remove bookmark, delete bookmark',
    'bookmark-minus-02': 'bookmark-minus-02',
    'bookmark-off-01': 'bookmark off',
    'bookmark-off-02': 'bookmark-off-02',
    'bookmark-remove-01': 'cancel bookmark, remove bookmark, delete bookmark',
    'bookmark-remove-02': 'bookmark-remove-02',
    'book-open-01': 'book-open-01',
    'book-open-02': 'book-open-02',
    'books-01': 'books-01',
    'books-02': 'books-02',
    'bookshelf-01': 'bookshelf-01',
    'bookshelf-02': 'bookshelf-02',
    'bookshelf-03': 'bookshelf-03',
    'book-upload': 'book-upload',
    bootstrap: 'bootstrap',
    'border-all-01': 'border-all-01',
    'border-all-02': 'border-all-02',
    'border-bottom-01': 'border-bottom-01',
    'border-bottom-02': 'border-bottom-02',
    'border-full':
        'complete border, enclosed frame, whole outline, total perimeter, entire edge, full boundary, border full, full border, all border, border',
    'border-horizontal': 'border-horizontal',
    'border-inner': 'border-inner',
    'border-left-01': 'border-left-01',
    'border-left-02': 'border-left-02',
    'border-none-01': 'border-none-01',
    'border-none-02': 'border-none-02',
    'border-right-01': 'border-right-01',
    'border-right-02': 'border-right-02',
    'border-top-01': 'border-top-01',
    'border-top-02': 'border-top-02',
    'border-vertical': 'border-vertical',
    borobudur: 'borobudur',
    bot: 'virtual assistant, chat companion, ai helper, digital buddy, conversation bot, automated helper, friendly bot, interactive guide, messaging assistant, online companion, talkbot, chat facilitator, conversational ai',
    'bounce-left': 'bounce-left',
    'bounce-right': 'bounce-right',
    'bounding-box':
        'selection-area, frame-marker, outline-tool, region-marker, area-marker, enclosure-icon, boundary-box',
    bowling: 'bowling',
    'bowling-ball': 'bowling-ball',
    'bowling-pins': 'bowling-pins',
    'bow-tie': 'bow-tie',
    boxer: 'boxer',
    'boxing-bag': 'boxing-bag',
    'boxing-glove': 'boxing-glove',
    'boxing-glove-01': 'boxing-glove-01',
    brain: 'brain',
    'brain-01': 'brain-01',
    'brain-02': 'brain-02',
    'brain-03': 'brain-03',
    brandfetch: 'brandfetch',
    'bread-01': 'bread-01',
    'bread-02': 'bread-02',
    'bread-03': 'bread-03',
    'bread-04': 'bread-04',
    'breast-pump': 'breast-pump',
    bridge: 'bridge',
    'briefcase-01': 'luggage, business',
    'briefcase-02': 'luggage, business',
    'briefcase-03': 'luggage, business',
    'briefcase-04': 'luggage, business',
    'briefcase-05': 'luggage, business',
    'briefcase-06': 'luggage, business',
    'briefcase-07': 'luggage, business',
    'briefcase-08': 'luggage',
    'briefcase-09': 'luggage, business',
    'briefcase-dollar': 'luggage, business',
    broccoli: 'broccoli',
    brochure: 'brochure',
    'broken-bone': 'broken-bone',
    browser:
        'internet, web, surfing tool, navigator, search engine, web explorer, online finder, web viewer, browsing tool, web portal, web access, browsing app, net viewer, web searcher, online explorer, browser, brows',
    brush: 'paint tool, stroke tool, drawing implement, art brush, paintbrush, artistic tool, coloring tool, graphical brush, creative wand, design brush, brush',
    'bubble-chat': 'message, chat, communicate, talk, comment',
    'bubble-chat-add': 'add message, add chat, add communicate, talk, new chat',
    'bubble-chat-blocked':
        'no message, no chat, no communicate, no talk, mute chat, mute conversation',
    'bubble-chat-cancel':
        'remove message, remove chat, remove communicate, remove talk, remove chat, remove conversation',
    'bubble-chat-delay': 'delay communication, delay message, delay talk',
    'bubble-chat-done': 'chat done, check check, communication done, chat finished',
    'bubble-chat-download-01': 'bubble-chat-download-01',
    'bubble-chat-download-02': 'bubble-chat-download-02',
    'bubble-chat-edit': 'new chat, new message, write message',
    'bubble-chat-favourite': 'love chat, message love, love communication',
    'bubble-chat-income': 'bubble-chat-income',
    'bubble-chat-lock': 'chat lock, message lock, communication lock',
    'bubble-chat-notification': 'bubble-chat-notification',
    'bubble-chat-outcome': 'bubble-chat-outcome',
    'bubble-chat-preview': 'bubble-chat-preview',
    'bubble-chat-question': 'bubble-chat-question',
    'bubble-chat-search': 'search chat, search message, search communication',
    'bubble-chat-secure': 'safe talk, message safe, communication safe, chat safe',
    'bubble-chat-translate': 'bubble-chat-translate',
    'bubble-chat-unlock': 'chat lock, message lock, communication lock',
    'bubble-chat-upload': 'bubble-chat-upload',
    'bubble-chat-user': 'bubble-chat-user',
    'bubble-tea-01': 'bubble-tea-01',
    'bubble-tea-02': 'bubble-tea-02',
    'bug-01': 'bug-01',
    'bug-02': 'bug-02',
    'building-01': 'building-01',
    'building-02': 'building-02',
    'building-03': 'building-03',
    'building-04': 'building-04',
    'building-05': 'building-05',
    'building-06': 'building-06',
    bulb: 'bulb',
    'bulb-charging': 'bulb-charging',
    'bulletproof-vest': 'bulletproof-vest',
    'burj-al-arab': 'burj-al-arab',
    'burning-cd': 'media library, torch, burning',
    'bus-01':
        'bus icon, transit, coach, transport, shuttle, public transport, vehicle, commuter, ride, transportation',
    'bus-02':
        'bus icon, transit, coach, transport, shuttle, public transport, vehicle, commuter, ride, transportation',
    'bus-03':
        'bus icon, transit, coach, transport, shuttle, public transport, vehicle, commuter, ride, transportation',
    'c++': 'c++',
    'cabinet-01': 'cabinet-01',
    'cabinet-02': 'cabinet-02',
    'cabinet-03': 'cabinet-03',
    'cabinet-04': 'cabinet-04',
    'cable-car': 'cable-car icon, aerial tramway, gondola lift, ropeway, sky tram, cableway',
    cactus: 'cactus',
    caduceus: 'caduceus',
    cafe: 'cafe',
    calculate: 'calculate',
    calculator: 'calculator, math',
    'calculator-01': 'calculator-01',
    'calendar-01':
        'datebook, timetable, agenda, scheduler, planner, event planner, timekeeper, appointment book, organizer, diary, schedule, calendar, date, date and time, event tracker, timekeeper',
    'calendar-02':
        'datebook, timetable, agenda, scheduler, planner, event planner, timekeeper, appointment book, organizer, diary, schedule, calendar, date, date and time, event tracker, timekeeper',
    'calendar-03':
        'datebook, timetable, agenda, scheduler, planner, event planner, timekeeper, appointment book, organizer, diary, schedule, calendar, date, date and time, event tracker, timekeeper',
    'calendar-04':
        'datebook, timetable, agenda, scheduler, planner, event planner, timekeeper, appointment book, organizer, diary, schedule, calendar, date, date and time, event tracker, timekeeper',
    'calendar-add-01': 'calendar-add-01',
    'calendar-add-02': 'calendar-add-02',
    'calendar-block-01': 'calendar-block-01',
    'calendar-block-02': 'calendar-block-02',
    'calendar-check-in-01': 'calendar-check-in-01',
    'calendar-check-in-02': 'calendar-check-in-02',
    'calendar-check-out-01': 'calendar-check-out-01',
    'calendar-check-out-02': 'calendar-check-out-02',
    'calendar-download-01': 'calendar-download-01',
    'calendar-download-02': 'calendar-download-02',
    'calendar-favorite-01': 'calendar-favorite-01',
    'calendar-favorite-02': 'calendar-favorite-02',
    'calendar-lock-01': 'calendar-lock-01',
    'calendar-lock-02': 'calendar-lock-02',
    'calendar-love-01': 'calendar-love-01',
    'calendar-love-02': 'calendar-love-02',
    'calendar-minus-01': 'calendar-minus-01',
    'calendar-minus-02': 'calendar-minus-02',
    'calendar-remove-01': 'calendar-remove-01',
    'calendar-remove-02': 'calendar-remove-02',
    'calendar-setting-01': 'calendar-setting-01',
    'calendar-setting-02': 'calendar-setting-02',
    'calendar-upload-01': 'calendar-upload-01',
    'calendar-upload-02': 'calendar-upload-02',
    call: 'call',
    'call-02': 'call-02',
    'call-add': 'call-add',
    'call-add-02': 'call-add-02',
    'call-blocked': 'call-blocked',
    'call-blocked-02': 'call-blocked-02',
    'call-disabled': 'call-disabled',
    'call-disabled-02': 'call-disabled-02',
    'call-done': 'call-done',
    'call-done-02': 'call-done-02',
    'call-end-01': 'call-end-01',
    'call-end-02': 'call-end-02',
    'call-end-03': 'call-end-03',
    'call-end-04': 'call-end-04',
    'call-incoming-01': 'call-incoming-01',
    'call-incoming-02': 'call-incoming-02',
    'call-incoming-03': 'call-incoming-03',
    'call-incoming-04': 'call-incoming-04',
    calling: 'calling',
    'calling-02': 'calling-02',
    'call-internal': 'call-internal',
    'call-internal-02': 'call-internal-02',
    'call-locked': 'call-locked',
    'call-locked-02': 'call-locked-02',
    'call-minus': 'call-minus',
    'call-minus-02': 'call-minus-02',
    'call-missed-01': 'call-missed-01',
    'call-missed-02': 'call-missed-02',
    'call-missed-03': 'call-missed-03',
    'call-missed-04': 'call-missed-04',
    'call-outgoing-01': 'call-outgoing-01',
    'call-outgoing-02': 'calling',
    'call-outgoing-03': 'call-outgoing-03',
    'call-outgoing-04': 'call-outgoing-04',
    'call-paused': 'call-paused',
    'call-paused-02': 'call-paused-02',
    'call-received': 'call-received',
    'call-received-02': 'call-received-02',
    'call-ringing-01': 'call-ringing-01',
    'call-ringing-02': 'calling',
    'call-ringing-03': 'call-ringing-03',
    'call-ringing-04': 'call-ringing-04',
    'call-unlocked': 'call-unlocked',
    'call-unlocked-02': 'call-unlocked-02',
    camel: 'camel',
    'camera-01': 'camera-01',
    'camera-02': 'camera-02',
    'camera-add-01': 'camera-add-01',
    'camera-add-02': 'camera-add-02',
    'camera-automatically-01': 'camera-automatically-01',
    'camera-automatically-02': 'camera-automatically-02',
    'camera-lens': 'camera-lens',
    'camera-microphone-01': 'camera-microphone-01',
    'camera-microphone-02': 'camera-microphone-02',
    'camera-night-mode-01': 'camera-night-mode-01',
    'camera-night-mode-02': 'camera-night-mode-02',
    'camera-off-01': 'camera-off-01',
    'camera-off-02': 'camera-off-02',
    'camera-rotated-01': 'camera-rotated-01',
    'camera-rotated-02': 'camera-rotated-02',
    'camera-smile-01': 'camera-smile-01',
    'camera-smile-02': 'camera-smile-02',
    'camera-tripod': 'camera-tripod',
    'camera-video': 'camera-video',
    camper: 'campfire',
    'cancel-01': 'cancel, minimize-arrow, close, cross, exit, remove, minimize',
    'cancel-02': 'cancel, minimize-arrow, close-cross, close, cross, exit, remove, minimize',
    'cancel-circle':
        'cancel, minimize-arrow, close-cross, close, cross, exit, cancel circle, close-circle',
    'cancel-circle-half-dot':
        'cancel-circle-half-dot, minimize-arrow, close-cross, close, cross, exit, cancel circle, close-circle',
    'cancel-square':
        'cancel, minimize-arrow, close-cross, close, cross, exit, remove, minimize, cancel-square, minimize-square, close-square, cross-square',
    'candelier-01': 'candelier-01',
    'candelier-02': 'candelier-02',
    canvas: 'drawing board, art pad, painters panel, sketch space, design slate, creative surface, illustration area, visual canvas, artwork platform, graphical stage, image board, picture space, draft surface, canvas',
    cap: 'cap',
    capcut: 'capcut',
    'capcut-rectangle': 'capcut-rectangle',
    'cap-projecting':
        'cap protrusion, hat extending, peak projection, headwear outcrop, brim extend, visor projection, cap bulge, hat overhang, peak extrusion, cap projecting, projecting cap, cap, projecting',
    'cap-round':
        'circular hat, rounded cap, round headwear, dome cap, sphere hat, curved brim, crown circle, ball cap, spherical hat, curved topper, circlet cap, round crown, dome top hat, circular bonnet, sphere headgear, curved peak, cap round, round cap, cap',
    'cap-straight':
        'baseball cap, flat brim hat, snapback, peaked cap, fitted cap, brimmed hat, sporty cap, uncurved cap, bill cap, straight-billed hat, rigid brim cap, modern hat, cap straight, straight cap, cap, straight',
    'car-01':
        'car icon, automobile, vehicle, transport, sedan, coupe, ride, motor, wheels, auto, carriage, roadster, machine',
    'car-02':
        'car icon, automobile, vehicle, transport, sedan, coupe, ride, motor, wheels, auto, carriage, roadster, machine',
    'car-03':
        'car icon, automobile, vehicle, transport, sedan, coupe, ride, motor, wheels, auto, carriage, roadster, machine',
    'car-04':
        'car icon, automobile, vehicle, transport, sedan, coupe, ride, motor, wheels, auto, carriage, roadster, machine',
    'car-05': 'car-05',
    'car-alert': 'car-alert',
    caravan: 'card-exchange-01',
    'card-exchange-02': 'card-exchange-02',
    cardigan: 'cardigan',
    'cardiogram-01': 'cardiogram-01',
    'cardiogram-02': 'cardiogram-02',
    'cards-01': 'cards-01',
    'cards-02': 'cards-02',
    'cargo-ship': 'vessel, freighter, cargo, carrier',
    'carousel-horizontal':
        'slider, image scroller, picture reel, horizontal gallery, slide viewer, scrolling carousel, image carousel, picture slider, horizontal slideshow, media reel, carousel horizontal, horizontal carousel, carousel',
    'carousel-horizontal-02': 'carousel-horizontal-02',
    'carousel-vertical':
        'vertical slider, upward carousel, vertical scroll, towering carousel, elevating carousel, tall carousel, vertical slideshow, uplifting carousel, high-rise carousel, vertical rotation, ascending carousel, vertical gallery, carousel vertical, vertical carousel, carousel',
    'car-parking-01':
        'car-parking icon, auto-parking, vehicle-parking, car-spot, parking-lot, automobile-park, car-space',
    'car-parking-02':
        'car-parking icon, auto-parking, vehicle-parking, car-spot, parking-lot, automobile-park, car-space',
    carrot: 'carrot',
    'car-signal': 'car-signal',
    'car-time': 'car-time',
    'cash-01': 'cash-01',
    'cash-02': 'cash-02',
    cashback: 'cashback',
    cashier: 'cashier',
    'cashier-02': 'cashier-02',
    castle: 'castle',
    'castle-01': 'castle-01',
    'castle-02': 'castle-02',
    catalogue: 'catalogue',
    'cayan-tower': 'cayan-tower',
    'cctv-camera': 'cctv-camera',
    cd: 'cd',
    cells: 'grid, blocks, units, compartments, squares, partitions, segments, modules, elements, divisions, sections, parcels, panels, zones, areas, parcels',
    'cellular-network': 'cellular-network',
    'cellular-network-offline': 'cellular-network-offline',
    celsius: 'celsius, degree, heat, temperature',
    'center-focus': 'center-focus',
    centralized: 'centralized',
    'central-shaheed-minar': 'central-shaheed-minar',
    'certificate-01': 'certificate-01',
    'certificate-02': 'certificate-02',
    'chair-01': 'chair-01',
    'chair-02': 'chair-02',
    'chair-03': 'chair-03',
    'chair-04': 'chair-04',
    'chair-05': 'chair-05',
    'chair-barber': 'chair-barber',
    champion: 'award, trophy, winner, cup, champion',
    'change-screen-mode':
        'switch display, toggle view, adjust screen layout, modify display setting, shift screen mode, adapt view mode, alter screen appearance, switch screen format, transform display mode, adjust visual presentation, change screen mode, screen mode, change screen',
    'character-phonetic':
        'letter sound, alphabet pronunciation, vocal symbol, speech representation, linguistic character, verbal phonics, spoken symbol, word pronunciation, vocalized letter, speech sound, character phonetic, phonetic character, character, phonetic',
    charity: 'charity',
    chart: 'chart',
    'chart-01': 'kanban',
    'chart-02': 'chart-02',
    'chart-03': 'chart-03',
    'chart-average': 'report, overview, dashboard, bar, graph, chart',
    'chart-bar-line': 'report, overview, chart, bar',
    'chart-breakout-circle': 'chart-breakout-circle',
    'chart-breakout-square': 'chart-breakout-square',
    'chart-bubble-01': 'chart-bubble-01',
    'chart-bubble-02': 'report, overview, dashboard',
    'chart-column': 'report, overview, dashboard, bar, graph, chart',
    'chart-decrease': 'report, overview, dashboard, bar, graph, chart',
    'chart-down': 'chart-down',
    'chart-evaluation': 'chart-evaluation',
    'chart-high-low': 'report, overview, dashboard, bar, graph, chart',
    'chart-histogram': 'report, overview, dashboard, bar, graph, chart',
    'chart-increase': 'chart-increase',
    'chart-line-data-01': 'chart-line-data-01',
    'chart-line-data-02': 'chart-line-data-02',
    'chart-line-data-03': 'chart-line-data-03',
    'chart-maximum': 'chart-maximum',
    'chart-medium': 'chart-medium',
    'chart-minimum': 'chart-minimum',
    'chart-radar': 'report, overview, dashboard',
    'chart-relationship': 'report, overview, dashboard, connection',
    'chart-ring': 'chart-ring',
    'chart-rose': 'report, overview, dashboard',
    'chart-scatter': 'report, overview, dashboard, bar, graph, chart',
    'chart-up': 'chart-up',
    'chat-bot': 'chat-bot',
    'chat-gpt': 'chat-gpt',
    'chatting-01': 'chatting-01',
    'check-list':
        'to-do list, tasks, checklist, task list, agenda, action items, checkmark list, task tracker, completion list, inventory, task manager, reminders, planner, schedule, check list, list view, list, check list',
    'checkmark-badge-01':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle, checkmark badge, badge',
    'checkmark-badge-02':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle, checkmark badge, badge',
    'checkmark-badge-03':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle, checkmark badge, badge',
    'checkmark-badge-04':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle, checkmark badge, badge',
    'checkmark-circle-01':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle',
    'checkmark-circle-02':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle',
    'checkmark-circle-03':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle',
    'checkmark-circle-04':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark circle',
    'checkmark-square-01':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark square',
    'checkmark-square-02':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark square',
    'checkmark-square-03':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark square',
    'checkmark-square-04':
        'tick, confirm, approve, validate, select, mark, accept, endorse, okay, pass, verify, affirm, agree, endorse, consent, indicate, checkmark, check mark, checkmark square',
    'check-unread-01': 'check-unread-01',
    'check-unread-02': 'check-unread-02',
    'check-unread-03': 'check-unread-03',
    'check-unread-04': 'check-unread-04',
    cheese: 'cheese',
    'cheese-cake-01': 'cheese-cake-01',
    'cheese-cake-02': 'cheese-cake-02',
    chef: 'chef',
    'chef-hat': 'chef-hat',
    'chemistry-01': 'chemistry-01',
    'chemistry-02': 'chemistry-02',
    'chemistry-03': 'chemistry-03',
    cherry: 'cherry',
    'chess-01': 'chess-01',
    'chess-02': 'chess-02',
    'chess-pawn': 'chess-pawn',
    'chicken-thighs': 'chicken-thighs',
    child: 'child',
    chimney: 'chimney',
    'china-temple': 'china-temple',
    chip: 'chip',
    'chip-02': 'chip-02',
    chocolate: 'chocolate',
    chrome: 'chrome',
    chrysler: 'chrysler',
    church: 'church',
    'cinnamon-roll': 'cinnamon-roll',
    circle: 'circle',
    'circle-arrow-data-transfer-diagonal': 'circle-arrow-data-transfer-diagonal',
    'circle-arrow-data-transfer-horizontal': 'circle-arrow-data-transfer-horizontal',
    'circle-arrow-data-transfer-vertical': 'circle-arrow-data-transfer-vertical',
    'circle-arrow-diagonal-01': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-diagonal-02': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-down-01':
        'circle-arrow-down, shrink-arrow, down-arrow-circle, minimize-arrow, collapse-circle, reduce-arrow, downward-circle, compress-arrow, contract-circle, condense-arrow, descend-circle, chevron',
    'circle-arrow-down-02': 'circle-arrow-down-02',
    'circle-arrow-down-03': 'circle-arrow-down-03',
    'circle-arrow-down-double': 'chevron',
    'circle-arrow-down-left': 'circle-arrow-down-left',
    'circle-arrow-down-right': 'circle-arrow-down-right',
    'circle-arrow-expand-01': 'big screen, full screen, expand, enlarge circle',
    'circle-arrow-expand-02': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-horizontal': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-left-01':
        'arrow-left, left-arrow, back-arrow, arrow, leftward indicator, go back, back navigation symbol, circle arrow left, chevron',
    'circle-arrow-left-02': 'circle-arrow-left-02',
    'circle-arrow-left-03': 'circle-arrow-left-03',
    'circle-arrow-left-double': 'chevron',
    'circle-arrow-left-right': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-move-down-left': 'circle-arrow-move-down-left',
    'circle-arrow-move-down-right': 'circle-arrow-move-down-right',
    'circle-arrow-move-left-down': 'circle-arrow-move-left-down',
    'circle-arrow-move-right-down': 'circle-arrow-move-right-down',
    'circle-arrow-move-up-left': 'circle-arrow-move-up-left',
    'circle-arrow-move-up-right': 'circle-arrow-move-up-right',
    'circle-arrow-reload-01': 'circle-arrow-reload-01',
    'circle-arrow-reload-02': 'circle-arrow-reload-02',
    'circle-arrow-right-01':
        'arrow-right, right-arrow,next-arrow, go-ahead-arrow, navigate-right, move-forward, right-direction, circle arrow, chevron',
    'circle-arrow-right-02': 'circle-arrow-right-02',
    'circle-arrow-right-03': 'circle-arrow-right-03',
    'circle-arrow-right-double': 'chevron',
    'circle-arrow-shrink-01': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-shrink-02': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-up-01':
        'reduce-arrow, upward-circle-arrow, minimize-arrow, expand-circle, shrink-direction, ascend-arrow, downsize-circle, chevron',
    'circle-arrow-up-02': 'circle-arrow-up-02',
    'circle-arrow-up-03': 'circle-arrow-up-03',
    'circle-arrow-up-double': 'chevron',
    'circle-arrow-up-down': 'big screen, full screen circle, expand, enlarge circle',
    'circle-arrow-up-left': 'circle-arrow-up-left',
    'circle-arrow-up-right': 'circle-arrow-up-right',
    'circle-arrow-up-right-02': 'circle-arrow-up-right-02',
    'circle-arrow-vertical': 'big screen, full screen circle, expand, enlarge circle',
    'circle-lock-01': 'circle-lock-01',
    'circle-lock-02': 'circle-lock-02',
    'circle-lock-add-01': 'circle-lock-add-01',
    'circle-lock-add-02': 'circle-lock-add-02',
    'circle-lock-check-01': 'circle-lock-check-01',
    'circle-lock-check-02': 'circle-lock-check-02',
    'circle-lock-minus-01': 'circle-lock-minus-01',
    'circle-lock-minus-02': 'circle-lock-minus-02',
    'circle-lock-remove-01': 'circle-lock-remove-01',
    'circle-lock-remove-02': 'circle-lock-remove-02',
    'circle-password': 'circle-password',
    'circle-unlock-01': 'circle-unlock-01',
    'circle-unlock-02': 'circle-unlock-02',
    'city-01': 'city-01',
    'city-02': 'city-02',
    'city-03': 'city-03',
    'clapping-01': 'clapping-01',
    'clapping-02': 'clapping-02',
    clean: 'clean',
    'cleaning-bucket': 'cleaning-bucket',
    clinic: 'clinic',
    clip: 'clip',
    clipboard:
        'document holder, copy tool, note saver, text keeper, paper clip, writing pad, clipboard symbol, data collector, information board, file manager, clipboard',
    'clock-01':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock',
    'clock-02':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock',
    'clock-03':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock',
    'clock-04':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock',
    'clock-05':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock',
    'closed-caption': 'closed-caption',
    'closed-caption-alt': 'closed-caption-alt',
    clothes: 'clothes',
    cloud: 'cloud, data, sky, server',
    'cloud-angled-rain': 'cloud, rain, cloud rain, little rain',
    'cloud-angled-rain-zap': 'cloud, cloud snow, snow, zap',
    'cloud-angled-zap': 'cloud, cloud snow, snow',
    'cloud-big-rain': 'cloud, data, sky, server',
    'cloud-download': 'download, cloud, cloud save, import, cloud down, transfer',
    'cloud-fast-wind': 'cloud, cloud snow, snow, zap, slow cloud, wind, wind cloud',
    'cloud-hailstone': 'cloud, cloud snow, snow, hailstone',
    'cloud-little-rain': 'cloud, rain, cloud rain, little rain',
    'cloud-little-snow': 'cloud, cloud snow, snow',
    'cloud-loading': 'cloud-loading',
    'cloud-mid-rain': 'cloud, rain, cloud rain, little rain',
    'cloud-mid-snow': 'cloud, cloud snow, snow',
    'cloud-saving-done-01': 'cloud-saving-done-01',
    'cloud-saving-done-02': 'cloud-saving-done-02',
    'cloud-server': 'cloud-server',
    'cloud-slow-wind': 'cloud, cloud snow, snow, zap, slow cloud, wind, wind cloud',
    'cloud-snow': 'cloud, cloud snow, snow',
    'cloud-upload':
        'upload cloud, cloud storage, cloud transfer, upload cloud, send to cloud, cloud backup, cloud up, uploader, upload',
    'clubs-01': 'clubs-01',
    'clubs-02': 'clubs-02',
    code: 'code',
    'code-circle': 'code-circle',
    'code-folder': 'code-folder',
    codesandbox: 'codesandbox',
    'code-square': 'code-square',
    'coffee-01': 'coffee-01',
    'coffee-02': 'coffee-02',
    'coffee-beans': 'coffee-beans',
    'coins-01': 'report, overview, dashboard, bar, graph, chart, earn cash, funding, earn money',
    'coins-02': 'earn cash, funding, earn money',
    'coins-bitcoin': 'bitcoin',
    'coins-dollar': 'earn dollar',
    'coins-euro': 'earn euro',
    'coins-pound': 'coins-pound',
    'coins-swap': 'coins-swap',
    'coins-yen': 'earn yen',
    'collections-bookmark': 'save, bulk',
    'color-picker':
        'hue selector, palette tool, color chooser, shade picker, spectrum selector, tint picker, chroma tool, color, colors, color picker',
    colors: 'palette, hues, shades, color wheel, spectrum, tints, pigments, chroma, color options, rainbow, color picker, paintbox, vividness, hue selection, color swatches, dyes, color chart, tinctures, gradient, color palette, colors, color',
    colosseum: 'colosseum',
    'column-delete':
        'remove column, erase column, delete column, column remove, eliminate column, column erase, omit column, exclude column, dismiss column, drop column, column delete, delete column',
    'column-insert':
        'add column, insert column, new column, column addition, column create, column+, column insert, insert column',
    'comet-01':
        'streak, meteor, shooting star, celestial trail, astral blaze, cosmic dash, sky dart, blazing tail',
    'comet-02':
        'streak, meteor, shooting star, celestial trail, astral blaze, cosmic dash, sky dart, blazing tail',
    'coming-soon-01': 'coming-soon-01',
    'coming-soon-02': 'coming-soon-02',
    command:
        'apple control, keyboard command, macos function, system shortcut, command center, mac directive, control key, apple hotkey, system command, mac functionality, keyboard directive, mac shortcut, command key, mac control, command',
    'command-line': 'command-line',
    'comment-01': 'opinion, comment, feedback, chat',
    'comment-02': 'empty opinion, empty comment, empty feedback, no chat',
    'comment-add-01': 'add opinion, add comment, add feedback',
    'comment-add-02': 'add comment, add opinion, add feedback',
    'comment-block-01': 'block opinion, block comment, block feedback',
    'comment-block-02': 'comment-block-02',
    'comment-remove-01': 'comment-remove-01',
    'comment-remove-02': 'close comment, close opinion, close feedback, close chat',
    compass: 'compass, direction, destination',
    'compass-01': 'navigation, compass, direction, heading indicator, cardinal',
    complaint: 'report',
    computer: 'desktop, screen share, imac, display, screen',
    'computer-add': 'add screen, add desktop, add computer',
    'computer-check': 'computer-check',
    'computer-cloud': 'desktop cloud',
    'computer-desk-01': 'computer-desk-01',
    'computer-desk-02': 'computer-desk-02',
    'computer-desk-03': 'computer-desk-03',
    'computer-dollar': 'earn online, earn money, funding',
    'computer-phone-sync': 'responsive, both devices, computer and mobile',
    'computer-programming-01': 'computer-programming-01',
    'computer-programming-02': 'computer-programming-02',
    'computer-protection': 'computer-protection',
    'computer-remove':
        'close screen, close desktop, close computer, cancel share, cancel screen share',
    'computer-settings': 'screen sharing setting, screen setting',
    'computer-terminal-01': 'computer-terminal-01',
    'computer-terminal-02': 'computer-terminal-02',
    'computer-video': 'desktop camera, video screen share, imac, display, screen, video call',
    'computer-video-call': 'computer-video-call',
    'cone-01': 'cone-01',
    'cone-02': 'cone-02',
    conference: 'conference',
    'configuration-01':
        'settings, preferences, options, setup, customizations, controls, adjustments, configurations, configurations, configurations',
    'configuration-02':
        'settings, preferences, options, setup, customizations, controls, adjustments, configurations, configurations, configurations',
    confused:
        'dazed face, bewildered expression, perplexed symbol, puzzled visage, muddled emoticon, uncertain glyph, lost emoji, frazzled face, flustered icon, disoriented symbol, baffled expression, confounded visage',
    'congruent-to': 'congruent-to',
    'congruent-to-circle': 'congruent-to-circle',
    'congruent-to-square': 'congruent-to-square',
    connect: 'connect',
    console: 'console',
    constellation:
        'star map, celestial pattern, stellar arrangement, galaxy guide, star cluster, astral chart, cosmic array, heavenly layout, space map, celestial mosaic, stellar chart',
    contact: 'contact',
    'contact-01': 'contact-01',
    'contact-02': 'contact-02',
    'contact-book': 'contact-book',
    'container-truck':
        'container-truck icon, cargo-hauler, freight-carrier, shipping-truck, transport-container, delivery-rig, load-carrier, haulage-vehicle, freighter-truck, goods-transporter, logistics-truck',
    'container-truck-01': 'truck, transport, shipment, logistics, delivery, shipping',
    'container-truck-02': 'truck, transport, shipment, logistics, delivery, shipping',
    'content-writing': 'content-writing',
    contracts: 'contracts',
    conversation: 'conversation',
    'cook-book': 'cook-book',
    cookie: 'cookie',
    'coordinate-01': 'coordinate, location',
    'coordinate-02': 'coordinate, location',
    copilot: 'copilot',
    'co-present': 'co-present',
    'copy-01':
        'duplicate, replicate, mirror, clone, mimic, reproduce, imitate, transfer, echo, backup, carbon copy, emulate, photocopy, match, twin, reproduce, copy',
    'copy-02':
        'duplicate, replicate, mirror, clone, mimic, reproduce, imitate, transfer, echo, backup, carbon copy, emulate, photocopy, match, twin, reproduce, copy',
    'copy-link': 'copy-link',
    copyright: 'legal',
    corn: 'corn',
    corporate: 'corporate',
    cos: 'cos',
    'cosine-01': 'cosine-01',
    'cosine-02': 'cosine-02',
    cottage: 'cottage',
    'cotton-candy': 'cotton-candy',
    'coupon-01': 'discount, voucher, promo, coupon, offer',
    'coupon-02': 'discount, voucher, promo, coupon, offer',
    'coupon-03': 'discount, voucher, promo, coupon, offer',
    'coupon-percent': 'discount, voucher, promo, coupon, offer',
    course: 'course',
    'court-house': 'legal',
    'court-law': 'legal',
    covariate: 'covariate',
    'covid-info': 'covid-info',
    'cowboy-hat': 'cowboy-hat',
    'c-programming': 'c-programming',
    cpu: 'cpu',
    'cpu-charge': 'cpu-charge',
    'cpu-settings': 'cpu-settings',
    crab: 'crab',
    crane: 'crane, lifter, hoister, elevator, pulley, derrick, winch, jib, dolly, gantry, boom',
    crazy: 'whimsical face, zany emoticon, madcap symbol, eccentric expression, wild emote, offbeat icon, wacky facial, quirky emojiface, outlandish smiley, playful glyph',
    'creative-market': 'creative-market',
    'credit-card': 'master card, pay with card',
    'credit-card-accept': 'master card, pay with card',
    'credit-card-add': 'master card, pay with card, add card, payment method',
    'credit-card-change': 'master card, pay with card',
    'credit-card-defrost': 'credit-card-defrost',
    'credit-card-freeze': 'credit-card-freeze',
    'credit-card-not-accept': 'master card, pay with card',
    'credit-card-not-found': 'master card, pay with card',
    'credit-card-pos': 'save money, card, payment',
    'credit-card-validation':
        'credit check, card verifier, payment authentication, verify payment, validate card, payment confirmation, card validation, verify credit, payment security, credit card validation, credit card checkmark, credit card tick',
    'cricket-bat': 'cricket-bat',
    'cricket-helmet': 'cricket-helmet',
    croissant: 'croissant',
    crop: 'trim, adjust, resize, cut, frame, tailor, modify, reshape, snip, alter, edit, prune, reconfigure, refine, customize, crop',
    crowdfunding: 'crowdfunding',
    crown: 'reward, award, badge',
    crying: 'tearful face, weeping emoji, sad tear, sobbing emoticon, tearful expression, crying face, sorrowful icon, teardrop emoji, emotional symbol, weepy smiley',
    'csv-01': 'csv-01',
    'csv-02': 'csv-02',
    cube: 'cube',
    'cupcake-01': 'cupcake-01',
    'cupcake-02': 'cupcake-02',
    'cupcake-03': 'cupcake-03',
    curling: 'curling',
    'cursor-01': 'cursor',
    'cursor-02': 'cursor',
    'cursor-add-selection-01': 'cursor add, cursor plus',
    'cursor-add-selection-02': 'cursor add selection, cursor add',
    'cursor-circle-selection-01': 'cursor circle, cursor ellipse, cursor round',
    'cursor-circle-selection-02': 'cursor circle, cursor ellipse , cursor round',
    'cursor-disabled-01': 'cursor disabled',
    'cursor-disabled-02': 'cursor disabled',
    'cursor-edit-01': 'cursor edit',
    'cursor-edit-02': 'cursor edit',
    'cursor-hold-01': 'cursor hold, cursor time',
    'cursor-hold-02': 'cursor hold',
    'cursor-info-01': 'cursor information, cursor info, question mark cursor, cursor question mark',
    'cursor-info-02': 'cursor, cursor info, cursor question',
    'cursor-in-window': 'cursor in window, cursor window',
    'cursor-loading-01': 'cursor loading, cursor pending, cursor hourglass',
    'cursor-loading-02': 'cursor loading, cursor wait, cursor pending',
    'cursor-magic-selection-01': 'cursor magic selection',
    'cursor-magic-selection-02': 'cursor magic',
    'cursor-move-01': 'cursor move',
    'cursor-move-02': 'cursor move',
    'cursor-pointer-01': 'cursor pointer',
    'cursor-pointer-02': 'cursor pointer',
    'cursor-progress-01': 'cursor progress',
    'cursor-progress-02': 'cursor progress',
    'cursor-progress-03': 'cursor progress',
    'cursor-progress-04': 'cursor progress',
    'cursor-rectangle-selection-01': 'cursor rectangle selection, cursor rectangle',
    'cursor-rectangle-selection-02': 'cursor rectangle',
    'cursor-remove-selection-01': 'cursor remove, cursor minus',
    'cursor-remove-selection-02': 'cursor remove',
    'cursor-text': 'cursor text, text cursor',
    curtains: 'curtains',
    'curvy-left-direction': 'curvy-left-direction',
    'curvy-left-right-direction': 'curvy-left-right-direction',
    'curvy-right-direction': 'curvy-right-direction',
    'curvy-up-down-direction': 'curvy-up-down-direction',
    'customer-service': '24 call, 24hr, 24 service',
    'customer-service-01': 'support, help',
    'customer-service-02': 'support, help',
    'customer-support': 'service, support, customer, user-care, help, call center',
    'custom-field': 'custom-field',
    customize:
        'personalize, modify, edit, tailor, adjust, tweak, adapt, revise, alter, fine-tune, revamp, refine',
    'cylinder-01': 'cylinder-01',
    'cylinder-02': 'cylinder-02',
    'cylinder-03': 'cylinder-03',
    'cylinder-04': 'cylinder-04',
    danger: 'danger',
    'dark-mode': 'dark-mode',
    dart: 'dart',
    'dashboard-browsing':
        'overview, control panel, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard',
    'dashboard-circle':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard circle',
    'dashboard-circle-add':
        'overview, control panel, data center, insights hub, administration, status board, main menu, command center, information hub, dashboard, dashboard circle, dashboard add',
    'dashboard-circle-edit':
        'overview, control panel, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard edit, dashboard circle edit, edit dashboard',
    'dashboard-circle-remove':
        'overview, control panel,, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, minus, dashboard minus, dashboard square remove',
    'dashboard-circle-settings':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard circle, dashboard circle setting, dashboard setting, setting dashboard',
    'dashboard-speed-01':
        'overview, control panel, data center, insights hub, administration, summary, analytics, performance, status board, command center, information hub, dashboard, dashboard speed, speed dashboard, speed',
    'dashboard-speed-02':
        'overview, control panel, hub, data center, insights hub, administration, summary, analytics, performance, status board, command center, information hub, speed',
    'dashboard-square-01':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard square',
    'dashboard-square-02':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard square',
    'dashboard-square-03':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard square',
    'dashboard-square-add':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard square, dashboard add, dashboard square add',
    'dashboard-square-edit':
        'overview, control panel, hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, dashboard square, dashboard edit',
    'dashboard-square-remove':
        'overview, control panel, home hub, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, dashboard square, dashboard remove, dashboard square remove, dashboard minus, dashboard square minus',
    'dashboard-square-setting':
        'overview, control panel, hub, data center, insights hub, administration, summary, analytics, status board, main menu, command center, information hub, dashboard, dashboard square',
    'dashed-line-01':
        'broken line, dotted line, interrupted line, segmented line, discontinuous line, stippled line, fragmented line, speckled line, dashed stroke, dashed stroke icon, intermittent line, dashed line, line dashed',
    'dashed-line-02':
        'broken line, dotted line, interrupted line, segmented line, discontinuous line, stippled line, fragmented line, speckled line, dashed stroke, dashed stroke icon, intermittent line, dashed line, line dashed, dashed line square',
    database: 'data stack, data center',
    'database-01':
        'ata store, information repository, data bank, data warehouse, data vault, record hub, info cache, data reservoir, storage center, data depot, information trove, data archive, database',
    'database-02':
        'ata store, information repository, data bank, data warehouse, data vault, record hub, info cache, data reservoir, storage center, data depot, information trove, data archive, database',
    'database-add':
        'ata store, information repository, data bank, data warehouse, data vault, record hub, info cache, data reservoir, storage center, data depot, information trove, data archive, database, database recovery, data, database add',
    'database-export': 'data stack, data center',
    'database-import': 'data stack, data center',
    'database-locked': 'data stack, data center',
    'database-restore': 'data stack, data center',
    'database-setting': 'data stack, data center',
    'database-sync':
        'ata store, information repository, data bank, data warehouse, data vault, record hub, info cache, data reservoir, storage center, data depot, information trove, data archive, database, database synce',
    'database-sync-01': 'data stack, data center',
    'data-recovery':
        'ata store, information repository, data bank, data warehouse, data vault, record hub, info cache, data reservoir, storage center, data depot, information trove, data archive, database, database recovery',
    dates: 'dates',
    'date-time': 'date-time',
    dead: 'expired emoticon, lifeless symbol, grimacing face, deceased icon, grim reaper symbol, inanimate expression, mortal emoticon, end-of-life symbol, ghostly face, departed emoticon',
    'delete-01': 'delete, remove, vanish, archive, bin, trash',
    'delete-02': 'delete, remove, vanish, archive, bin, trash',
    'delete-03': 'delete, remove, vanish, archive, bin, trash',
    'delete-04': 'delete, remove, vanish, archive, bin, trash',
    'delete-column': 'delete-column',
    'delete-put-back': 'restore, delete-back, remove-back, remove, trash',
    'delete-row': 'delete-row',
    'delete-throw': 'remove-throw, restore, trash',
    'delivered-sent': 'sent, arrived, delivered, dispatched, shipped',
    'delivery-box-01': 'shipping, package, parcel, box',
    'delivery-box-02': 'shipping, package, parcel, box',
    'delivery-delay-01': 'delay, not sent, not delivered',
    'delivery-delay-02': 'sent, arrived, delivered, dispatched, shipped',
    'delivery-return-01': 'shipping return, package return, parcel return, box return',
    'delivery-return-02': 'shipping return, package return, parcel return, box return',
    'delivery-secure-01': 'shipping secure, package secure, parcel secure, box secure',
    'delivery-secure-02': 'shipping secure, package secure, parcel secure, box secure',
    'delivery-sent-01': 'sent, arrived, delivered, dispatched, shipped',
    'delivery-sent-02': 'sent, arrived, delivered, dispatched, shipped',
    'delivery-tracking-01': 'shipping tracking, package tracking, parcel tracking, box tracking',
    'delivery-tracking-02': 'shipping tracking, package tracking, parcel tracking, box tracking',
    'delivery-truck-01': 'sent, delivered, dispatched, shipped',
    'delivery-truck-02': 'sent, delivered, dispatched, shipped',
    'delivery-view-01': 'shipping view, package view, parcel view, box view',
    'delivery-view-02': 'shipping view, package view, parcel view, box view',
    'dental-braces': 'dental-braces',
    'dental-broken-tooth': 'dental-broken-tooth',
    'dental-care': 'dental-care',
    'dental-tooth': 'dental-tooth',
    departement: 'departement',
    desert: 'desert, sand, land',
    desk: 'desk',
    'desk-01': 'desk-01',
    'desk-02': 'desk-02',
    developer: 'developer',
    deviantart: 'deviantart',
    'device-access': 'device-access',
    'diagonal-scroll-point-01': 'diagonal-scroll-point-01',
    'diagonal-scroll-point-02': 'diagonal-scroll-point-02',
    'dialpad-circle-01': 'dialpad-circle-01',
    'dialpad-circle-02': 'dialpad-circle-02',
    'dialpad-square-01': 'dialpad-square-01',
    'dialpad-square-02': 'dialpad-square-02',
    diameter: 'diameter',
    diamond: 'diamond',
    'diamond-01': 'diamond-01',
    'diamond-02': 'diamond-02',
    diaper: 'diaper',
    dice: 'dice',
    'dice-faces-01': 'dice-faces-01',
    'dice-faces-02': 'dice-faces-02',
    'dice-faces-03': 'dice-faces-03',
    'dice-faces-04': 'dice-faces-04',
    'dice-faces-05': 'dice-faces-05',
    'dice-faces-06': 'dice-faces-06',
    digestion: 'digestion',
    digg: 'digg',
    'digital-clock': 'digital-clock',
    'dim-sum-01': 'dim-sum-01',
    'dim-sum-02': 'dim-sum-02',
    'dining-table': 'dining-table',
    diploma: 'certificate',
    'direction-left-01': 'direction, destination',
    'direction-left-02': 'direction, destination',
    'direction-right-01': 'direction, destination',
    'direction-right-02': 'direction, destination',
    'directions-01': 'direction, destination',
    'directions-02': 'direction, destination',
    dirham: 'dirham',
    'disability-01': 'disability-01',
    'disability-02': 'disability-02',
    discord: 'discord',
    discount: 'discount, voucher, promo, coupon, offer',
    'discount-01': 'discount-01',
    'discount-tag-01': 'discount, offer, tag, sale, deal',
    'discount-tag-02': 'discount, offer, tag, sale, deal',
    'discover-circle':
        'overview, control panel, data center, insights hub, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, circle, circle discover, explore circle, circle explore',
    'discover-square':
        'overview, control panel, administration, summary, analytics, performance, status board, main menu, command center, information hub, dashboard, discover square, square discover, explore, square explore',
    'dish-01': 'dish-01',
    'dish-02': 'dish-02',
    'dish-washer': 'dish-washer',
    displeased:
        'grumpy face, frowning emoticon, unhappy icon, irritated symbol, discontented smiley, sour expression, moody face, dissatisfied emoti, annoyed icon, discontent emoji',
    'distribute-horizontal-center':
        'align horizontally, center distribute, horizontal distribution center, evenly space horizontally, center horizontal alignment, distribute horizontal center, horizontal distribute center, distribute center',
    'distribute-horizontal-left':
        'align left, spread horizontally left, arrange leftward, evenly space left, leftward distribution, horizontal left alignment, distribute horizontal left, horizontal left distribute',
    'distribute-horizontal-right':
        'spread right, align horizontally right, evenly space right, horizontal distribution right, rightward alignment, equalize right, rightward spacing, even spacing right, align right equally, distribute evenly right, distribute horizontal right, horizontal right distribute',
    'distribute-vertical-bottom':
        'align to bottom, vertical distribution, bottom distribution, arrange vertically, bottom align, vertical bottom placement, stack at bottom, arrange downwards, bottom vertical spread, vertical bottom distribution, distribute vertical bottom, vertical bottom distribute',
    'distribute-vertical-center':
        'align vertically, center vertical distribution, vertical alignment, distribute equally, middle alignment, vertical centering, even vertical spacing, align middle vertical, vertical equidistance, distribute vertical center, distribute center',
    'distribute-vertical-top':
        'vertical top alignment, vertical distribution - top, top aligner, top arrangement, vertical top spread," "topward alignment, top distributor, top positioning, distribute vertical top, vertical top distribute',
    distribution: 'distribution, flow, allocation, supply-chain',
    'divide-sign': 'split, division, segmentation, fraction, divider',
    'divide-sign-circle': 'divide-sign-circle',
    'divide-sign-square': 'divide-sign-square',
    dna: 'dna',
    'dna-01': 'dna-01',
    'doc-01': 'doc-01',
    'doc-02': 'doc-02',
    'doctor-01': 'career, business career',
    'doctor-02': 'career, business career',
    'doctor-03': 'career, business career',
    'document-attachment': 'document-attachment',
    'document-code': 'document-code',
    'document-validation':
        'document verification, file confirmation, paper authentication, record validation, doc approval, paperwork validation, file certification, document authentication, record verification, validation check, document confirmation, document validation, document check, document checkmark',
    'dollar-01': 'payment',
    'dollar-02': 'payment',
    'dollar-circle': 'dollar-circle',
    'dollar-receive-01': 'dollar-receive-01',
    'dollar-receive-02': 'dollar-receive-02',
    'dollar-send-01': 'dollar-send-01',
    'dollar-send-02': 'dollar-send-02',
    'dollar-square': 'dollar-square',
    dome: 'dome',
    domino: 'domino',
    'do-not-touch-01': 'hand',
    'do-not-touch-02': 'hand',
    door: 'door',
    'door-01': 'ntrance, gateway, portal, access point, doorway, passage, threshold, egress, exit',
    'door-02': 'ntrance, gateway, portal, access point, doorway, passage, threshold, egress, exit',
    'door-lock': 'door-lock',
    doughnut: 'doughnut',
    'download-01':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader',
    'download-02':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader',
    'download-03':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader',
    'download-04':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader',
    'download-05':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader',
    'download-circle-01':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader, download circle, circle download',
    'download-circle-02':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader, download circle, circle download',
    'download-square-01':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader, download square, square download',
    'download-square-02':
        'save, get, fetch, export, store, receive, transfer, downlink, downloaded, download, downloader, download square, square download',
    'drag-01': 'drag-01',
    'drag-02': 'drag-02',
    'drag-03': 'drag-03',
    'drag-04': 'drag-04',
    'drag-drop':
        'move object, transfer item, shift content, rearrange element, place & shift, rearrange object, shift item, move & drop, rearrange content, drag & release, drag drop, drop drag',
    'drag-drop-horizontal':
        'horizontal drag and drop, move sideways, slide & place, shift along, sideways transfer, horizontal shift, drag across, sideways move, transfer horizontally, slide drop, drag drop horizontal, horizontal drag drop',
    'drag-drop-vertical':
        'vertical drag, drop down, arrange vertically, vertical arrange, sort vertically, stack order, arrange order, vertical rearrange, drag drop vertical, vertical drag drop, drag drop',
    'drag-left-01': 'drag-left-01',
    'drag-left-02': 'drag-left-02',
    'drag-left-03': 'drag-left-03',
    'drag-left-04': 'drag-left-04',
    'drag-right-01': 'drag-right-01',
    'drag-right-02': 'drag-right-02',
    'drag-right-03': 'drag-right-03',
    'drag-right-04': 'drag-right-04',
    'drawing-compass': 'drawing-compass',
    'drawing-mode': 'dress-01',
    'dress-02': 'dress-02',
    'dress-03': 'dress-03',
    'dress-04': 'dress-04',
    'dress-05': 'dress-05',
    'dress-06': 'dress-06',
    'dress-07': 'dress-07',
    'dressing-table-01': 'dressing-table-01',
    'dressing-table-02': 'dressing-table-02',
    'dressing-table-03': 'dressing-table-03',
    dribbble: 'dribbble',
    drink: 'drink',
    drone: 'drone icon, aerial vehicle, quadcopter, flying robot, uav, unmanned aerial vehicle, sky cam, remote aircraft, airborne device, hovering machine, flight gadget, copter',
    drooling:
        'hungry face emoji, salivating emoji, yummy emoji, appetite emoji, mouthwatering emoji, craving emoji, delicious emoji, tasty emoji, savoring emoji, lip-smacking emoji',
    dropbox: 'dropbox',
    droplet:
        'water bead, liquid dot, aqua spot, raindrop, fluid point, dew symbol, moisture mark, dribble emblem, splash shape, drizzle icon, condensation badge, hydration sign, droplet',
    dropper: 'dropper',
    'ds3-tool': 'ds3-tool',
    dua: 'dua',
    'dumbbell-01': 'dumbbell-01',
    'dumbbell-02': 'dumbbell-02',
    'dumbbell-03': 'dumbbell-03',
    ear: 'ear',
    'ear-rings-01': 'ear-rings-01',
    'ear-rings-02': 'ear-rings-02',
    'ear-rings-03': 'ear-rings-03',
    earth: 'globe, world, planet, orb, sphere, terra, gaia, blue marble, celestial, terrestrial',
    'ease-curve-control-points': 'ease-curve-control-points',
    'ease-in': 'ease-in',
    'ease-in-control-point': 'ease-in-control-point',
    'ease-in-out': 'ease-in-out',
    'ease-out': 'ease-out',
    'ease-out-control-point': 'ease-out-control-point',
    'eco-energy': 'eco, eco energy',
    'eco-lab': 'eco-lab',
    'eco-lab-01': 'eco-lab-01',
    'eco-lab-02': 'eco-lab-02',
    'eco-power': 'eco, eco power',
    'edge-style':
        'boundary design, outline mode, perimeter look, contour style, border presentation, rim appearance, margin form, frame outline, boundary view, edge format, edge style, edge',
    'edit-01': 'modify, revise, alter, update, adjust, change, amend, tweak, enhance, revamp, edit',
    'edit-02': 'modify, update, adjust, revise, change, tweak, alter, amend, refine, edit',
    'edit-off':
        'disable edit, modify off, edit disable, change off, alter off, edit stop, adjust off, edit inactive, edit lock, edit block, edit off switch, edit off',
    'edit-road': 'street edit, highway edit, road edit, path edit',
    'edit-table': 'edit-table',
    'edit-user-02': 'user, user edit, profile, avatar, member, account',
    eggs: 'eggs',
    'eid-mubarak': 'eid-mubarak',
    'eiffel-tower': 'eiffel-tower',
    'elearning-exchange': 'elearning-exchange',
    'electric-home-01': 'electric home, home',
    'electric-home-02': 'electric home, home',
    'electric-plugs': 'electric-plugs',
    'electric-tower-01': 'electric tower, tower',
    'electric-tower-02': 'electric tower, tower',
    'electric-wire': 'wire, electric wire',
    'ellipse-selection': 'ellipse-selection',
    encrypt: 'encrypt',
    energy: 'energy',
    'energy-ellipse': 'energy round, energy',
    'energy-rectangle': 'energy rectangle, energy',
    'entering-geo-fence':
        'location boundary, zone-egress-alert, zone-egress-alert, area, arrival alert, zone entry',
    envato: 'envato',
    'equal-sign': 'twin, matching, equal, equality',
    'equal-sign-circle': 'equal-sign-circle',
    'equal-sign-square': 'equal-sign-square',
    'equipment-bench-press': 'equipment-bench-press',
    'equipment-chest-press': 'equipment-chest-press',
    'equipment-gym-01': 'equipment-gym-01',
    'equipment-gym-02': 'equipment-gym-02',
    'equipment-gym-03': 'equipment-gym-03',
    'equipment-weightlifting': 'equipment-weightlifting',
    eraser: 'eraser',
    'eraser-01': 'eraser, erase, remove, cancel, cancel-eraser',
    'eraser-add': 'add-eraser, eraser-add, plus-eraser',
    'eraser-auto': 'eraser-auto',
    'estimate-01': 'estimate-01',
    'estimate-02': 'estimate-02',
    ethereum: 'ethereum',
    'ethereum-ellipse': 'ethereum-ellipse',
    'ethereum-rectangle': 'ethereum-rectangle',
    euro: 'euro',
    'euro-circle': 'euro-circle',
    'euro-receive': 'euro-receive',
    'euro-send': 'euro-send',
    'euro-square': 'euro-square',
    'ev-charging': 'ev charging, charging',
    evil: 'sinister face, wicked grin, malevolent smile, villainous expression, devilish smirk, menacing icon, dark emoji, malicious symbol, sinister grin',
    'exchange-01':
        'swap, convert, switch, trade, interchange, transfer, replace, barter, shift, alternate, exchange, change',
    'exchange-02':
        'swap, convert, switch, trade, interchange, transfer, replace, barter, shift, alternate, exchange, change, crypto exchange, crypto change, bitcoin exchange',
    'exchange-03':
        'swap, convert, switch, trade, interchange, transfer, replace, barter, shift, alternate, exchange, change, crypto exchange, crypto change, bitcoin exchange',
    expander: 'expander',
    'external-drive': 'external-drive',
    eye: 'eye',
    'facebook-01': 'facebook-01',
    'facebook-02': 'facebook-02',
    'face-id': 'face-id',
    factory: 'factory',
    'factory-01': 'factory-01',
    'factory-02': 'factory-02',
    fahrenheit: 'celsius, degree, heat, temperature, fahrenheit',
    'falling-star':
        'twinkling-night, shooting-glimmer, celestial-fall, meteoric-shine, stardust-plunge, astral-descend',
    'fast-wind': 'wind, air, breeze',
    favourite: 'favourite, love, heart',
    'favourite-circle': 'favourite, love, heart, love circle, heart circle',
    'favourite-square': 'favourite, love, heart, love square, heart square',
    feather: 'feather',
    'female-02': 'female-02',
    'female-symbol': 'female-symbol',
    fencing: 'fencing',
    'fencing-mask': 'fencing-mask',
    'ferris-wheel': 'ferris-wheel',
    'ferry-boat':
        'ferry-boat icon, water taxi, nautical vessel, marine transport, ship shuttle, seafaring craft, harbor cruiser, passenger boat, aquatic transit',
    figma: 'figma',
    'file-01': 'file-01',
    'file-02': 'file-02',
    'file-add': 'file-add',
    'file-attachment': 'file-attachment',
    'file-audio': 'file-audio',
    'file-bitcoin': 'file-bitcoin',
    'file-block': 'file-block',
    'file-bookmark': 'file-bookmark',
    'file-cloud': 'file-cloud',
    'file-corrupt': 'file-corrupt',
    'file-dollar': 'file-dollar',
    'file-download': 'file-download',
    'file-edit': 'file-edit',
    'file-empty-01': 'file-empty-01',
    'file-empty-02': 'file-empty-02',
    'file-euro': 'file-euro',
    'file-export': 'file-export',
    'file-favourite': 'file-favourite',
    'file-import': 'file-import',
    'file-link': 'file-link',
    'file-locked': 'file-locked',
    'file-management': 'file-management',
    'file-minus': 'file-minus',
    'file-music': 'file-music',
    'file-not-found': 'file-not-found',
    'file-paste': 'file-paste',
    'file-pin': 'file-pin',
    'file-pound': 'file-pound',
    'file-remove': 'file-remove',
    'files-01': 'files-01',
    'files-02': 'files-02',
    'file-script': 'file-script',
    'file-search': 'file-search',
    'file-security': 'file-security',
    'file-shredder': 'file-shredder',
    'file-star': 'file-star',
    'file-sync': 'file-sync',
    'file-unknown': 'file-unknown',
    'file-unlocked': 'file-unlocked',
    'file-upload': 'file-upload',
    'file-validation':
        'data check, document verification, file integrity, validate file, verify data, check document, file validation, validation file, file check, file checkmark, checkmark file',
    'file-verified': 'file-verified',
    'file-video': 'file-video',
    'file-view': 'file-view',
    'file-yen': 'file-yen',
    'file-zip': 'file-zip',
    'film-01': 'film-01',
    'film-02': 'film-02',
    'film-roll-01': 'film-roll-01',
    'film-roll-02': 'film-roll-02',
    filter: 'filter, purifier, refine',
    'filter-add': 'filter add, purifier add, refine add',
    'filter-edit': 'filter edit, purifier edit, refine edit',
    'filter-horizontal': 'filter horizontal, filter, purifier, refine',
    'filter-mail-circle': 'filter, purifier, refine, filter round',
    'filter-mail-square': 'filter, purifier, refine, filter square',
    'filter-remove': 'filter remove, purifier remove, refine remove',
    'filter-reset': 'filter reset, purifier reset, refine reset',
    'filter-vertical': 'filter vertical, filter, purifier, refine',
    'finger-access': 'finger-access',
    'finger-print': 'finger-print',
    'finger-print-add': 'finger-print-add',
    'finger-print-check': 'finger-print-check',
    'finger-print-minus': 'finger-print-minus',
    'finger-print-remove': 'finger-print-remove',
    'finger-print-scan': 'finger-print-scan',
    'fingerprint-scan': 'fingerprint-scan',
    fins: 'fins',
    fire: 'fire',
    'fire-02': 'fire-02',
    'fire-03': 'fire-03',
    'fire-pit': 'fire-pit',
    'fire-security': 'fire-security',
    firewall: 'firewall',
    fireworks: 'fireworks',
    'first-aid-kit': 'first-aid-kit',
    'fish-food': 'fish-food',
    'fit-to-screen': 'fit-to-screen',
    fiverr: 'fiverr',
    'flag-01': 'flag',
    'flag-02': 'flag',
    'flag-03': 'flag',
    flash: 'flash',
    flashlight: 'flashlight',
    'flash-off': 'flash-off',
    flaticon: 'flaticon',
    flickr: 'flickr',
    'flim-slate': 'flim-slate',
    'flip-bottom': 'flip-bottom',
    'flip-horizontal': 'flip-horizontal',
    'flip-left': 'flip-left',
    'flip-phone': 'flip-phone',
    'flip-right': 'flip-right',
    'flip-top': 'flip-top',
    'flip-vertical': 'flip-vertical',
    'floor-plan': 'floor-plan',
    'floppy-disk': 'floppy-disk',
    flow: 'flow',
    'flowchart-01': 'flowchart-01',
    'flowchart-02': 'flowchart-02',
    'flow-circle': 'flow-circle',
    'flow-connection': 'flow-connection',
    flower: 'flower',
    'flower-pot': 'flower-pot',
    'flow-square': 'flow-square',
    flushed:
        'blushing face, embarrassed smiley, crimson expression, shy emoticon, bashful symbol, red-faced glyph, embarrassed emoji, bashful smiley, flushed emoticon, rosy-faced icon',
    'flying-human': 'flying-human',
    'focus-point': 'focus-point',
    'folder-01': 'folder-01',
    'folder-02': 'folder-02',
    'folder-03': 'folder-03',
    'folder-add': 'folder-add',
    'folder-attachment': 'folder-attachment',
    'folder-audio': 'folder-audio',
    'folder-block': 'folder-block',
    'folder-check': 'folder-check',
    'folder-cloud': 'folder-cloud',
    'folder-details': 'folder-details',
    'folder-details-reference': 'folder-details-reference',
    'folder-download': 'folder-download',
    'folder-edit': 'folder-edit',
    'folder-export': 'folder-export',
    'folder-favourite': 'folder-favourite',
    'folder-file-storage': 'folder-file-storage',
    'folder-import': 'folder-import',
    'folder-library': 'folder-library',
    'folder-links': 'folder-links',
    'folder-locked': 'folder-locked',
    'folder-management': 'folder-management',
    'folder-minus': 'folder-minus',
    'folder-music': 'folder-music',
    'folder-off': 'folder-off',
    'folder-open': 'folder-open',
    'folder-pin': 'folder-pin',
    'folder-remove': 'folder-remove',
    folders: 'folders',
    'folder-search': 'folder-search',
    'folder-security': 'folder-security',
    'folder-shared-01': 'folder-shared-01',
    'folder-shared-02': 'folder-shared-02',
    'folder-shared-03': 'folder-shared-03',
    'folder-sync': 'folder-sync',
    'folder-transfer': 'folder-transfer',
    'folder-unknown': 'folder-unknown',
    'folder-unlocked': 'folder-unlocked',
    'folder-upload': 'folder-upload',
    'folder-video': 'folder-video',
    'folder-view': 'folder-view',
    'folder-zip': 'folder-zip',
    football: 'football',
    'football-pitch': 'football-pitch',
    'forgot-password': 'forgot-password',
    fork: 'fork',
    forrst: 'forrst',
    fortress: 'fortress',
    'forward-01': 'forward, move on',
    'forward-02': 'forward',
    'four-finger-02': 'hand',
    'four-finger-03': 'hand',
    foursquare: 'foursquare',
    framer: 'framer',
    frameworks: 'frameworks',
    'french-fries-01': 'french-fries-01',
    'french-fries-02': 'french-fries-02',
    fridge: 'fridge',
    frisbee: 'frisbee',
    fry: 'fry',
    fuel: 'fuel',
    'fuel-01': 'fuel',
    'fuel-02': 'fuel',
    'fuel-station':
        'fuel-station icon, gas station, petrol station, refueling point, service station, energy depot, filling station, fuel hub, gas pump, refuel stop, diesel stop',
    'full-screen': 'full-screen',
    function: 'function',
    'function-circle': 'function-circle',
    'function-of-x': 'function-of-x',
    'function-square': 'function-square',
    galaxy: 'cosmos, stellar, nebula, celestial, astral, universe, space, stellaris',
    game: 'game',
    gameboy: 'gameboy',
    'game-controller-01': 'game-controller-01',
    'game-controller-02': 'game-controller-02',
    'game-controller-03': 'game-controller-03',
    garage: 'garage',
    'garbage-truck':
        'garbage-truck icon, trash-collector, waste-disposal, rubbish-wagon, bin-mobile, junk-hauler, recycling-van, dumpster-truck, refuse-vehicle, litter-picker, disposal-unit',
    garlands: 'garlands',
    'gas-pipe': 'gas, gas pipe',
    'gas-stove': 'gas-stove',
    gears: 'gears',
    gem: 'gem',
    'geology-crust': 'geology-crust',
    'geometric-shapes-01': 'geometric-shapes-01',
    'geometric-shapes-02': 'geometric-shapes-02',
    'gibbous-moon': 'moon, night, lunar, moonlight',
    'gif-01': 'gif-01',
    'gif-02': 'gif-02',
    gift: 'present, surprise, reward, token, package, souvenir, offering, bounty, prize, donation, souvenir, bonus, gratuity, keepsake, memento, gift',
    'gift-card': 'gift card, event voucher, festive gift card',
    'gift-card-02': 'gift-card-02',
    gitbook: 'gitbook',
    'git-branch': 'git-branch',
    'git-commit': 'git-commit',
    'git-compare': 'git-compare',
    'git-fork': 'git-fork',
    github: 'github',
    'github-01': 'github-01',
    gitlab: 'gitlab',
    'git-merge': 'git-merge',
    'git-pull-request': 'git-pull-request',
    'git-pull-request-closed': 'git-pull-request-closed',
    'git-pull-request-draft': 'git-pull-request-draft',
    'give-blood': 'give-blood',
    'give-pill': 'give-pill',
    glasses: 'glasses',
    global: 'global, earth',
    'global-editing': 'earth editing, global editing',
    'global-education': 'global-education',
    'global-refresh': 'location reload, global refresh, earth refresh',
    'global-search': 'global, earth, global search, magnifying glass, map explore, look up',
    globe: 'earth, world, planet, global, map, sphere, orb, continents, atlas, geography, terra, international, celestial, hemisphere, navigation, cartography, terra firma, global view, glove',
    'globe-02': 'globe-02',
    glove: 'glove',
    'go-backward-10-sec': 'go backward, go backward 10 sec',
    'go-backward-15-sec': 'go backward, go backward 15 sec',
    'go-backward-30-sec': 'go backward, go backward 30 sec',
    'go-backward-5-sec': 'go backward, go backward 5 sec',
    'go-backward-60-sec': 'go backward, go backward 60 sec',
    'go-forward-10-sec': 'go forward 10 sec, move forward 10 sec',
    'go-forward-15-sec': 'go forward 15 sec, move forward 15 sec',
    'go-forward-30-sec': 'go forward 30 sec, move forward 30 sec',
    'go-forward-5-sec': 'go forward 5 sec, move forward 5 sec',
    'go-forward-60-sec': 'go forward 60 sec, move forward 60 sec',
    gold: 'gold',
    'gold-ingots': 'gold-ingots',
    'golf-ball': 'golf-ball',
    'golf-bat': 'golf-bat',
    'golf-cart':
        'golf-cart icon, golf buggy, golf vehicle, course transporter, fairway cruiser, links cart, green transport',
    'golf-hole': 'golf-hole',
    google: 'google',
    'google-doc': 'google-doc',
    'google-drive': 'google-drive',
    'google-gemini': 'google-gemini',
    'google-home': 'google-home',
    'google-lens': 'google-lens',
    'google-maps': 'google-maps',
    'google-photos': 'google-photos',
    'google-sheet': 'google-sheet',
    'gps-01': 'direction, gps',
    'gps-02': 'direction, gps',
    'gps-disconnected': 'gps-disconnected',
    'gps-off-01': 'map, location, gps off, gps close, gps cancel',
    'gps-off-02': 'map, location, gps off, gps close, gps cancel',
    'gps-signal-01': 'gps-signal-01',
    'gps-signal-02': 'gps-signal-02',
    gpu: 'gpu',
    'graduate-female': 'student, mentor, graduate',
    'graduate-male': 'student, mentor, graduate',
    'graduation-scroll': 'graduation-scroll',
    grapes: 'grapes',
    gravity:
        'gravitation, pull, attractor, force, magnetism, allure, tug, attraction, weight, draw, centripetal',
    'greater-than': 'greater than, forward, go forward',
    'greater-than-circle': 'greater-than-circle',
    'greater-than-square': 'greater-than-square',
    'greek-helmet': 'greek-helmet',
    'green-house': 'green house',
    grid: 'matrix, hash, mesh, array, framework, layout, pattern, mosaic, grating, arrangement, network, structure, checkerboard, blueprint, template, grid',
    'grid-02': 'grid-02',
    'grid-off': 'hash, no grid',
    'grid-table': 'grid-table',
    'grid-view':
        'matrix display, block arrangement, array mode, tile view, mosaic layout, panel grid, collage mode, brick view, ensemble display, patchwork format, chessboard layout, array visualization, quilted view, grid view, view grid',
    grimacing: 'grimacing',
    grinning:
        'happy face, smiling emoji, laughing symbol, joyful emoticon, cheery icon, smiley face, grin symbol, chuckling emoticon, beaming icon, jovial emoji',
    grok: 'grok',
    'group-01': 'group-01',
    'group-items':
        'combine objects, gather selection, merge elements, aggregate items, bundle objects, cluster items, consolidate elements, unify selection, assemble objects, collect elements, link items, organize items, group items, item, group',
    'group-layers':
        'layer cluster, arrange layers, merge layers, stack layers, bundle layers, combine layers, unit layers, consolidate layers, aggregate layers, composite layers, group layers, layers group, layers, group',
    'guest-house': 'guest-house',
    gun: 'gun',
    gymnastic: 'gymnastic',
    'gymnastic-rings': 'gymnastic-rings',
    hackerrank: 'hackerrank',
    'hair-clips': 'hair-clips',
    'hair-dryer': 'hair-dryer',
    haji: 'haji',
    halal: 'halal',
    'hamburger-01': 'hamburger-01',
    'hamburger-02': 'hamburger-02',
    'hand-bag-01': 'hand-bag-01',
    'hand-bag-02': 'hand-bag-02',
    'hand-beater': 'hand-beater',
    handcuffs: 'handcuffs',
    'hand-grip': 'hand-grip',
    'hand-pointing-down-01': 'hand-pointing-down-01',
    'hand-pointing-down-02': 'hand-pointing-down-02',
    'hand-pointing-down-03': 'hand-pointing-down-03',
    'hand-pointing-down-04': 'hand-pointing-down-04',
    'hand-pointing-left-01': 'hand-pointing-left-01',
    'hand-pointing-left-02': 'hand-pointing-left-02',
    'hand-pointing-left-03': 'hand-pointing-left-03',
    'hand-pointing-left-04': 'hand-pointing-left-04',
    'hand-pointing-right-01': 'hand-pointing-right-01',
    'hand-pointing-right-02': 'hand-pointing-right-02',
    'hand-pointing-right-03': 'hand-pointing-right-03',
    'hand-pointing-right-04': 'hand-pointing-right-04',
    'hand-prayer': 'hand-prayer',
    'hand-sanitizer': 'hand-sanitizer',
    hanger: 'hanger',
    'hanging-clock': 'hanging-clock',
    hangout: 'hangout',
    happy: 'joyful face, cheerful emoticon, smiling icon, delighted symbol, blissful expression, radiant emote, grinning symbol, ecstatic face, beaming emoji, contented sign',
    'hard-drive': 'hard-drive',
    hdd: 'hdd',
    'hdr-01': 'hdr-01',
    'hdr-02': 'hdr-02',
    heading:
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline',
    'heading-01':
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline, headline 1',
    'heading-02':
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline, headline 2',
    'heading-03':
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline, headline 3',
    'heading-04':
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline, headline 4',
    'heading-05':
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline, headline 5',
    'heading-06':
        'itle, headline, header, caption, banner, masthead, label, subject, topic, heading text, headline, headline 6',
    'headphone-mute': 'headphone off, headphone mute, mute',
    headphones: 'earphones, audio, sound, headphone, earpiece, headset, listening, speakers',
    headset:
        'earphones, headphones, audio device, sound gear, listening device, earpiece, audio headset, sound equipment, headphone set, listening gear, ear gear, stereo set, headphone icon, sound accessory, headphone symbol, ear gear symbol, audio gadget, headset symbol, headset, head set',
    'headset-connected': 'headset-connected',
    'headset-off':
        'silent mode, mute audio, sound off, quiet mode, audio off, headphone off, mute headset, headset off, off headset, head set off',
    healtcare: 'healtcare',
    health: 'health',
    'heart-add': 'heart-add',
    heartbreak: 'heartbreak, broken love, broken heart',
    'heart-check': 'love',
    'heart-remove': 'love',
    helicopter: 'help-circle, help, questions',
    'help-square': 'help-square, help, questions',
    hexagon: 'hexagon',
    'hexagon-01': 'hexagon-01',
    hierarchy: 'hierarchy',
    'hierarchy-circle-01': 'hierarchy-circle-01',
    'hierarchy-circle-02': 'hierarchy-circle-02',
    'hierarchy-circle-03': 'hierarchy-circle-03',
    'hierarchy-files': 'hierarchy-files',
    'hierarchy-square-01': 'hierarchy-square-01',
    'hierarchy-square-02': 'hierarchy-square-02',
    'hierarchy-square-03': 'hierarchy-square-03',
    'hierarchy-square-04': 'hierarchy-square-04',
    'hierarchy-square-05': 'hierarchy-square-05',
    'hierarchy-square-06': 'hierarchy-square-06',
    'hierarchy-square-07': 'hierarchy-square-07',
    'hierarchy-square-08': 'hierarchy-square-08',
    'hierarchy-square-10': 'hierarchy-square-10',
    'high-heels-01': 'high-heels-01',
    'high-heels-02': 'high-heels-02',
    hijab: 'hijab',
    hockey: 'hockey',
    'hold-01': 'hold-01',
    'hold-02': 'hold-02',
    'hold-03': 'hold-03',
    'hold-04': 'hold-04',
    'hold-05': 'hold-05',
    'hold-locked-01': 'hold-locked-01',
    'hold-locked-02': 'hold-locked-02',
    'hold-phone': 'hold-phone',
    hologram: 'hologram',
    'home-01':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-02':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-03':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-04':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-05':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-06':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-07':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-08':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-09':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-10':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-11':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-12':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-13':
        'house, main, hub, base, central, welling, residence, primary, abode, nexus, core, foundation, origin, launchpad, homestead',
    'home-wifi': 'home-wifi',
    'honey-01': 'honey-01',
    'honey-02': 'honey-02',
    honor: 'honor, reward, award, badge',
    'honour-star': 'reward, award, badge',
    hoodie: 'hoodie',
    'horizonal-scroll-point': 'horizonal-scroll-point',
    'horizontal-resize': 'resize horizontal',
    horse: 'horse',
    'horse-head': 'horse-head',
    'horse-saddle': 'horse-saddle',
    'hospital-01': 'hospital-01',
    'hospital-02': 'hospital-02',
    'hospital-bed-01': 'hospital-bed-01',
    'hospital-bed-02': 'hospital-bed-02',
    'hospital-location': 'hospital-location',
    'hot-air-balloon':
        'hot-air-balloon icon, airship, sky orb, floating basket, lofty balloon, aerial sphere, high-flying globe, sky cruiser, lofty transport, cloud hopper',
    hotdog: 'hotdog',
    'hotel-01': 'hotel-01',
    'hotel-02': 'hotel-02',
    'hotel-bell': 'hotel-bell',
    'hot-price': 'discount, voucher, promo, coupon, offer, hot price',
    hotspot: 'hotspot',
    'hotspot-offline': 'hotspot-offline',
    'hot-tube': 'hot-tube',
    hourglass:
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock',
    'hourglass-off': 'hourglass-off',
    'house-01': 'house-01',
    'house-02': 'house-02',
    'house-03': 'house-03',
    'house-04': 'house-04',
    'house-05': 'house-05',
    'house-solar-panel': 'solar panel, solar',
    'html-5': 'html-5',
    humidity: 'humidity, moisture, air',
    hut: 'hut',
    'hydro-power': 'hydro power, power',
    hyperbole: 'amplify, hyperbole, intensify, augment',
    'ice-cream-01': 'ice-cream-01',
    'ice-cream-02': 'ice-cream-02',
    'ice-cream-03': 'ice-cream-03',
    'ice-cream-04': 'ice-cream-04',
    'ice-hockey': 'ice-hockey',
    'ice-skating': 'ice-skating',
    ico: 'ico',
    iconjar: 'iconjar',
    id: 'id',
    idea: 'bulb, light',
    'idea-01':
        'lightbulb, brainstorm, innovation, creativity, insight, eureka, thought bubble, concept, imagination, revelation, spark, ingenious, inventive, visionary, intellect, genius, idea',
    identification: 'identification',
    'identity-card': 'identity-card',
    'id-not-verified': 'id-not-verified',
    'id-verified': 'id-verified',
    'image-01': 'image-01',
    'image-02': 'image-02',
    'image-add-01': 'image-add-01',
    'image-add-02': 'image-add-02',
    'image-composition': 'image-composition',
    'image-composition-oval': 'image-composition-oval',
    'image-crop': 'image-crop',
    'image-delete-01': 'image-delete-01',
    'image-delete-02': 'image-delete-02',
    'image-done-01': 'image-done-01',
    'image-done-02': 'image-done-02',
    'image-download': 'image-download',
    'image-download-02': 'image-download-02',
    'image-not-found-01': 'image-not-found-01',
    'image-not-found-02': 'image-not-found-02',
    'image-remove-01': 'image-remove-01',
    'image-remove-02': 'image-remove-02',
    'image-upload': 'image-upload',
    'image-upload-01': 'image-upload-01',
    imo: 'imo',
    'important-book': 'important-book',
    inbox: 'inbox',
    'inbox-check': 'inbox-check',
    'inbox-download': 'inbox-download',
    'inbox-unread': 'inbox-unread',
    'inbox-upload': 'inbox-upload',
    incognito: 'incognito',
    'india-gate': 'india-gate',
    'inequality-01': 'imbalance, inequity, bias, social divide, equity gap, justice scale',
    'inequality-02': 'imbalance, inequity, bias, social divide, equity gap, justice scale',
    'inequality-circle-01': 'inequality-circle-01',
    'inequality-circle-02': 'inequality-circle-02',
    'inequality-square-01': 'inequality-square-01',
    'inequality-square-02': 'inequality-square-02',
    infant: 'infant',
    'infinity-01': 'infinity-01',
    'infinity-02': 'infinity, endless, eternal',
    'infinity-circle': 'infinity-circle',
    'infinity-square': 'infinity-square',
    'information-circle': 'information-circle, info, information, information-square, info',
    'information-diamond': 'information-diamond',
    'information-square': 'information-square, info, information, information-square, info',
    injection: 'injection',
    'in-love':
        'heart eyes, smiling face with heart-eyes, adoration symbol, affectionate smile, lovestruck expression, infatuated icon, heartfelt emoticon, amorous symbol, affection emoji, enamored face, loving grin, fond expression, heartwarming symbol, adoring emoticon',
    'insert-bottom-image': 'insert-bottom-image',
    'insert-center-image': 'insert-center-image',
    'insert-column': 'insert-column',
    'insert-column-left': 'insert-column-left',
    'insert-column-right': 'insert-column-right',
    'insert-pi': 'insert-pi',
    'insert-row': 'insert-row',
    'insert-row-down': 'insert-row-down',
    'insert-row-up': 'insert-row-up',
    'insert-top-image': 'insert-top-image',
    'inspect-code': 'inspect-code',
    instagram: 'instagram',
    'installing-updates-01':
        'update in progress, system update, refresh & install, patch installation, updating software, upgrade in process, firmware update, installing improvements, software refresh, update procedure, app upgrade, patch installing',
    'installing-updates-02':
        'update in progress, system update, refresh & install, patch installation, updating software, upgrade in process, firmware update, installing improvements, software refresh, update procedure, app upgrade, patch installing',
    internet: 'internet',
    'internet-antenna-01': 'internet-antenna-01',
    'internet-antenna-02': 'internet-antenna-02',
    'internet-antenna-03': 'internet-antenna-03',
    'internet-antenna-04': 'internet-antenna-04',
    investigation: 'investigation',
    invoice: 'bill',
    'invoice-01': 'invoice, payment, billing, purchase, receipt, expense',
    'invoice-02': 'invoice, payment, billing, purchase, receipt, expense',
    'invoice-03': 'invoice, payment, billing, purchase, receipt, expense',
    'invoice-04': 'invoice, payment, billing, purchase, receipt, expense',
    ipod: 'ipod',
    'iris-scan': 'iris-scan',
    island: 'island',
    jar: 'jar',
    java: 'java',
    'java-script': 'java-script',
    'job-link': 'job-link',
    'job-search': 'find job, job searching',
    'job-share': 'find job',
    'jogger-pants': 'jogger-pants',
    'join-bevel': 'join-bevel',
    'join-round': 'join-round',
    'join-straight': 'join-straight',
    joker: 'joker',
    'joystick-01': 'joystick-01',
    'joystick-02': 'joystick-02',
    'joystick-03': 'joystick-03',
    'joystick-04': 'joystick-04',
    'joystick-05': 'joystick-05',
    'jpg-01': 'jpg-01',
    'jpg-02': 'jpg-02',
    'jsx-01': 'jsx-01',
    'jsx-02': 'jsx-02',
    'jsx-03': 'jsx-03',
    judge: 'judge',
    jupiter: 'legal',
    'justice-scale-02': 'legal',
    'kaaba-01': 'kaaba-01',
    'kaaba-02': 'kaaba-02',
    kanban: 'kanban',
    kayak: 'kayak',
    keffiyeh: 'keffiyeh',
    kettle: 'kettle',
    'kettle-01': 'kettle-01',
    kettlebell: 'kettlebell',
    ketupat: 'ketupat',
    'key-01': 'key-01',
    'key-02': 'key-02',
    keyboard:
        'typing pad, input board, key panel, text entry, character board, typepad, key interface, letter grid, input keys, text panel, key matrix, character input, typing interface, letter pad, keyboard, key board',
    keyframe: 'keyframe',
    'keyframe-add': 'keyframe-add',
    'keyframe-align-center': 'keyframe-align-center',
    'keyframe-align-horizontal': 'keyframe-align-horizontal',
    'keyframe-align-vertical': 'keyframe-align-vertical',
    'keyframe-bottom': 'keyframe-bottom',
    'keyframe-left': 'keyframe-left',
    'keyframe-remove': 'keyframe-remove',
    'keyframe-right': 'keyframe-right',
    'keyframes-double': 'keyframes-double',
    'keyframes-double-add': 'keyframes-double-add',
    'keyframes-double-remove': 'keyframes-double-remove',
    'keyframes-multiple': 'keyframes-multiple',
    'keyframes-multiple-add': 'keyframes-multiple-add',
    'keyframes-multiple-remove': 'keyframes-multiple-remove',
    'keyframe-top': 'keyframe-top',
    'key-generator-fob': 'key-generator-fob',
    'kickstarter-01': 'kickstarter-01',
    'kickstarter-02': 'kickstarter-02',
    kid: 'child face, youngster symbol, junior icon, little one emoticon, youthful avatar, tiny tot emoji, kidlet symbol, miniature human face, offspring icon, youngling emote, junior member symbol, tot emoji',
    kidneys: 'kidneys',
    kissing:
        'smooch symbol, love kiss icon, affectionate emoji, romantic gesture symbol, sweet peck icon, heartfelt kiss symbol, lips emoji, affection expression icon, romantic kiss symbol, emotive smooch icon, loving gesture symbol, tender kiss emoji',
    'kitchen-utensils': 'kitchen-utensils',
    kite: 'kite',
    'knife-01': 'knife-01',
    'knife-02': 'knife-02',
    'knife-bread': 'knife-bread',
    'knight-shield': 'knight-shield',
    knives: 'knives',
    'knowledge-01': 'knowledge-01',
    'knowledge-02': 'knowledge-02',
    'ko-fi': 'ko-fi',
    kurta: 'kurta',
    'kurta-01': 'kurta-01',
    label: 'label, tag',
    'label-important': 'label important, tag, important',
    labor: 'worker',
    labs: 'labs',
    lake: 'lake',
    lamp: 'lamp',
    'lamp-01': 'lamp-01',
    'lamp-02': 'lamp-02',
    'lamp-03': 'lamp-03',
    'lamp-04': 'lamp-04',
    'lamp-05': 'lamp-05',
    'language-circle': 'language, language circle, circle language',
    'language-skill': 'language-skill',
    'language-square': 'language, language square, square language',
    lantern: 'lantern',
    laptop: 'laptop',
    'laptop-add': 'laptop-add',
    'laptop-charging': 'laptop-charging',
    'laptop-check': 'laptop-check',
    'laptop-cloud': 'laptop-cloud',
    'laptop-issue': 'laptop-issue',
    'laptop-performance': 'laptop-performance',
    'laptop-phone-sync': 'responsive, laptop and mobile',
    'laptop-programming': 'laptop-programming',
    'laptop-remove': 'laptop-remove',
    'laptop-settings': 'laptop-settings',
    'laptop-video': 'laptop-video',
    'lasso-tool-01': 'lasso-tool-01',
    'lasso-tool-02': 'lasso-tool-02',
    'last-fm': 'last-fm',
    latitude:
        'global positioning, map grid, position pin, earth coordinates, navigation point, latitude',
    laughing:
        'happy face, lol emoji, giggling emoticon, cheerful symbol, smiling tears emoji, jovial icon, laugh-out-loud emoticon, hilarious face, mirthful emoji, grinning tears symbol, chuckling emojiface, amusing icon, joyful emoji, crying with laughter symbol, guffaw emoticon, delighted face, rofl emoji, euphoric symbol, hysterical icon, bursting in laughter emoji',
    'laurel-wreath-01': 'feather, reward, award, badge',
    'laurel-wreath-02': 'feather, reward, award, badge',
    'laurel-wreath-first-01': 'feather, reward, award, badge',
    'laurel-wreath-first-02': 'feather, reward, award, badge',
    'laurel-wreath-left-01': 'laurel-wreath-left-01',
    'laurel-wreath-left-02': 'laurel-wreath-left-02',
    'laurel-wreath-left-03': 'laurel-wreath-left-03',
    'laurel-wreath-right-01': 'laurel-wreath-right-01',
    'laurel-wreath-right-02': 'laurel-wreath-right-02',
    'laurel-wreath-right-03': 'laurel-wreath-right-03',
    'layer-add': 'layer-add',
    'layer-mask-01': 'layer-mask-01',
    'layer-mask-02': 'layer-mask-02',
    'layers-01':
        'stacks, sheets, overlays, strata, tiered, sheets, arrangement, overlaps, levels, piles, tiers, meld, cascades, tiers, overlays, array, veils, slabs, groupings, layers, layer',
    'layers-02':
        'stacks, sheets, overlays, strata, tiered, sheets, arrangement, overlaps, levels, piles, tiers, meld, cascades, tiers, overlays, array, veils, slabs, groupings, layers, layer',
    'layers-logo': 'layers-logo',
    'layout-01': 'layout-01',
    'layout-02': 'layout-02',
    'layout-03': 'layout-03',
    'layout-04': 'layout-04',
    'layout-05': 'layout-05',
    'layout-06': 'layout-06',
    'layout-07': 'layout-07',
    'layout-2-column': 'layout-2-column',
    'layout-2-row': 'layout-2-row',
    'layout-3-column': 'kanban',
    'layout-3-row': 'layout-3-row',
    'layout-bottom': 'layout-bottom',
    'layout-grid': 'layout-grid',
    'layout-left': 'layout-left',
    'layout-right': 'layout-right',
    'layout-table-01': 'kanban',
    'layout-table-02': 'layout-table-02',
    'layout-top': 'layout-top',
    'leaf-01': 'leaf-01',
    'leaf-02': 'leaf-02',
    'leaf-03': 'leaf-03',
    'leaf-04': 'leaf-04',
    'leaving-geo-fence':
        'location boundary, zone-egress-alert, zone-egress-alert, area, arrival alert, zone entry',
    leetcode: 'leetcode',
    'left-angle': 'arrowhead, angle, angular, arrow',
    'left-to-right-block-quote': 'left-to-right-block-quote',
    'left-to-right-list-bullet': 'left-to-right-list-bullet',
    'left-to-right-list-dash': 'left-to-right-list-dash',
    'left-to-right-list-number': 'left-to-right-list-number',
    'left-to-right-list-star': 'left-to-right-list-star',
    'left-to-right-list-star-01': 'left-to-right-list-star-01',
    'left-to-right-list-triangle': 'left-to-right-list-triangle',
    'left-triangle': 'triangle, trigon',
    'legal-01': 'legal',
    'legal-02': 'legal',
    'legal-document-01': 'legal',
    'legal-document-02': 'legal-document-02',
    'legal-hammer': 'legal-hammer',
    'less-than': 'not greater than, less than',
    'less-than-circle': 'less-than-circle',
    'less-than-square': 'less-than-square',
    'letter-spacing': 'letter-spacing',
    libraries: 'libraries',
    library:
        'bookshelf, reading room, knowledge center, archives, literary hub, reference, information hub, repository, book haven, resource center, study corner, media library, learning hub, book collection, literary archive, library',
    license: 'blog, article, news paper',
    'license-draft': 'blog, article, news paper, agreement',
    'license-maintenance': 'license-maintenance',
    'license-no': 'license-no',
    'license-pin': 'license-pin',
    'license-third-party': 'license-third-party',
    'lift-truck':
        'lift-truck icon, forklift, warehouse mover, pallet jack, lifting machine, cargo lifter, material handler, industrial truck, load lifter, fork hoist, lifting vehicle, pallet lifter, lifting gear, stacker truck, goods transporter, lifting equipment',
    lighthouse: 'lighthouse',
    limitation: 'limitation',
    line: 'line',
    liner: 'liner',
    'link-01':
        'link-icon, connection, web, hyperlink, chain, join, url, anchor, tie, bridge, attach',
    'link-02':
        'link-icon, connection, web, hyperlink, chain, join, url, anchor, tie, bridge, attach',
    'link-03':
        'link-icon, connection, web, hyperlink, chain, join, url, anchor, tie, bridge, attach',
    'link-04':
        'link-icon, connection, web, hyperlink, chain, join, url, anchor, tie, bridge, attach',
    'link-05':
        'link-icon, connection, web, hyperlink, chain, join, url, anchor, tie, bridge, attach',
    'link-06':
        'link-icon, connection, web, hyperlink, chain, join, url, anchor, tie, bridge, attach',
    'link-backward':
        'link-backward-icon, back-arrow, return-arrow, previous-link, reverse-link, backward-navigation',
    'link-circle':
        'link-circle-icon, connection-loop, circular-link, circle-connector, looped-link, round-link, circular-connection, link-ring, link-loop, circle-joiner',
    'link-circle-02':
        'link-circle-icon, connection-loop, circular-link, circle-connector, looped-link, round-link, circular-connection, link-ring, link-loop, circle-joiner',
    'linkedin-01': 'linkedin-01',
    'linkedin-02': 'linkedin-02',
    'link-forward':
        'link-forward-icon, forward-arrow, next-page, proceed, navigate, advance, move-ahead, jump, go-forward, arrow-right, direct-link, onward, follow, progress-arrow, continue, onward-arrow, link-to-next',
    'link-square-01':
        'link-square-icon, link-box, square-link, hyperlink-square, connect-square, url-box, square-connector, link-frame, square-hyperlink, linked-square',
    'link-square-02':
        'link-square-icon, link-box, square-link, hyperlink-square, connect-square, url-box, square-connector, link-frame, square-hyperlink, linked-square',
    'list-setting':
        'settings-list, list-options, list-configuration, list-preferences, list-controls, list-customization, list-management, list-config, list-tools, list-tweaks',
    'list-view': 'list-view',
    litecoin: 'litecoin',
    liver: 'liver',
    'live-streaming-01': 'live-streaming-01',
    'live-streaming-02': 'live-streaming-02',
    'live-streaming-03': 'live-streaming-03',
    'loading-01': 'hourglass, glass, loading, wait, hour, slow',
    'loading-02': 'loading, wait, slow, loop, looping, loading icon',
    'loading-03': 'loading, wait, slow, loop, looping, loading icon',
    'loading-04': 'loading, wait, slow, loop, looping, loading icon',
    'location-01': 'map, location',
    'location-02': 'map, location',
    'location-03': 'map, location, pin',
    'location-04': 'map, location, pin',
    'location-05': 'map, location, pin',
    'location-06': 'map, location, destination',
    'location-07': 'map, location, destination',
    'location-08': 'map, location, destination',
    'location-09': 'map, location, destination',
    'location-10': 'map, location, destination',
    'location-add-01': 'location add, location plus, map plus, map add',
    'location-add-02': 'map add, map plus, location add, location plus',
    'location-check-01': 'map check, location check',
    'location-check-02': 'map check, location check',
    'location-favourite-01': 'map favourite, location favourite',
    'location-favourite-02': 'map favourite, location favourite',
    'location-offline-01': 'location offline, map offline, location off',
    'location-offline-02': 'location offline, map offline, location off',
    'location-offline-03': 'location offline, map offline',
    'location-offline-04': 'location offline, map offline',
    'location-remove-01': 'location remove, location minus, map remove, map minus',
    'location-remove-02': 'map remove, map minus, location remove, location minus',
    'location-share-01': 'map share, location share',
    'location-share-02': 'map, location, gps, location share',
    'location-star-01': 'map star, location start, save map',
    'location-star-02': 'map star, location star',
    'location-update-01': 'map, update, map reload, location update, location reload',
    'location-update-02': 'map, location, gps, location update',
    'location-user-01': 'map, location, gps, location update, location user',
    'location-user-02': 'map, location, gps, location update, location user',
    'location-user-03': 'map, location, gps, location update, location user',
    'location-user-04': 'map, location, gps, location update, location user',
    lock: 'lock',
    'lock-computer': 'lock-computer',
    locked: 'locked',
    locker: 'locker',
    'locker-01': 'locker-01',
    'lock-key': 'lock-key',
    'lock-password': 'lock-password',
    'lock-sync-01': 'lock-sync-01',
    'lock-sync-02': 'lock-sync-02',
    'login-01':
        'sign in, access, authenticate, enter, user-access, login access, member login, account entry, connect, portal-access, authorization, switch, member-access',
    'login-02':
        'sign in, access, authenticate, enter, user-access, login access, member login, account entry, connect, portal-access, authorization, switch, member-access',
    'login-03':
        'sign in, access, authenticate, enter, user-access, login access, member login, account entry, connect, portal-access, authorization, switch, member-access',
    'login-circle-01':
        'sign in, access, enter, user-access, connect, secure entry, user gateway, authorization, user-login, member access, login-square, login-circle',
    'login-circle-02':
        'sign in, access, enter, user-access, connect, secure entry, user gateway, authorization, user-login, member access, login-square, login-circle',
    'login-method': 'login-method',
    'login-square-01':
        'sign in, access, enter, user-access, connect, secure entry, user gateway, authorization, user-login, member access, login-square',
    'login-square-02':
        'sign in, access, enter, user-access, connect, secure entry, user gateway, authorization, user-login, member access, login-square',
    'logout-01':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off',
    'logout-02':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish',
    'logout-03':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish',
    'logout-04':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off',
    'logout-05':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off',
    'logout-circle-01':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off, switch off',
    'logout-circle-02':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off, switch off',
    'logout-square-01':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off',
    'logout-square-02':
        'sign-out, exit, log-off, end-session, disconnect, leave, quit, close session, terminate, finish, power off, switch off',
    lollipop: 'lollipop',
    longitude: 'longitude, longitude indicator, geo-position',
    'long-sleeve-shirt': 'long-sleeve-shirt',
    'look-bottom':
        'gaze-down symbol, peek-beneath icon, view-lower emoticon, observe-bottom glyph, glance-lower emoji, peer-down symbol, inspect-bottom icon, survey-lower emoticon, scrutinize-below glyph, check-beneath emoji',
    'look-left':
        'side-glance, peek-left, glance-left, peek-a-boo, leftward-gaze, side-peek, left-glimpse, sideways-look, left-peek, side-eye',
    'look-right':
        'glance-right, peek-right, view-right, observe-right, check-right, eye-right, watch-right, gaze-right, peeking-right, spy-right, scan-right, survey-right, glimpse-right, peer-right, stare-right',
    'look-top':
        'peek-a-boo face, upward glance emoticon, upward peek smiley, skyward gaze icon, upward glance emoji, peeking smiley, glance-up emoji, topward look emoticon, upward peek emoji, upward glance face, upward peeping icon, topward gaze emoji, upward peeking face, upward glance smiley',
    loom: 'loom',
    lottiefiles: 'lottiefiles',
    'love-korean-finger': 'love-korean-finger',
    'loyalty-card': 'loyalty card, card',
    'luggage-01': 'luggage-01',
    'luggage-02': 'luggage-02',
    lungs: 'lungs',
    'machine-robot': 'machine-robot',
    'magic-wand-01': 'magic wand, wand magic, magic, wand, stars, star, ai, ai',
    'magic-wand-02': 'magic wand, stars, magic star, wand magic, stars, star, magic, ai',
    'magic-wand-03': 'magic wand, star, stars, wand magic, magic, ai',
    'magic-wand-04': 'magic-wand-04',
    magnet: 'attraction, puller, magnetic pull, attractor, magnetizer, magnetic force, magnetic field, magnetism, magnetic pull, magnetize, magnetism symbol, magnetic attraction',
    'magnet-01': 'magnet',
    'magnet-02': 'magnet',
    'mail-01': 'message, email, gmail',
    'mail-02': 'mail-02',
    'mail-account-01': 'mail-account-01',
    'mail-account-02': 'mail-account-02',
    'mail-add-01': 'add message, add email, add gmail, new message, new mail',
    'mail-add-02': 'add message, add email, add gmail, new message, new mail',
    'mail-at-sign-01': 'gmail, mail sign, gmail sign',
    'mail-at-sign-02': 'mail-at-sign-02',
    'mail-block-01': 'mail-block-01',
    'mail-block-02': 'mail-block-02',
    mailbox: 'mailbox',
    'mailbox-01': 'mailbox-01',
    'mail-download-01': 'mail-download-01',
    'mail-download-02': 'mail-download-02',
    'mail-edit-01':
        'write message, write email, add gmail, new message, new mail, write communication',
    'mail-edit-02': 'mail-edit-02',
    'mail-lock-01': 'mail-lock-01',
    'mail-lock-02': 'mail-lock-02',
    'mail-love-01': 'love message, love email, love gmail',
    'mail-love-02': 'mail-love-02',
    'mail-minus-01': 'mail-minus-01',
    'mail-minus-02': 'close message, close email, close gmail, close message, close mail',
    'mail-open': 'open chat, open gmail, open email, open mail',
    'mail-open-01': 'open chat, open gmail, open email, open mail',
    'mail-open-02': 'open chat, open gmail, open email, open mail',
    'mail-open-love': 'open love chat, open love gmail, open love email, open love mail',
    'mail-receive-01': 'mail-receive-01',
    'mail-receive-02': 'gmail, email, incoming mail, incoming gmail, incoming message',
    'mail-remove-01': 'mail-remove-01',
    'mail-remove-02': 'close message, close email, close gmail, close message, close mail',
    'mail-reply-01': 'mail-reply-01',
    'mail-reply-02': 'reply message, reply email, reply gmail, reply message, reply mail',
    'mail-reply-all-01': 'mail-reply-all-01',
    'mail-reply-all-02':
        'reply all message, reply all email, reply all gmail, reply all message, reply all mail',
    'mail-search-01': 'search mail, search message, search gmail',
    'mail-search-02': 'mail-search-02',
    'mail-secure-01': 'mail-secure-01',
    'mail-secure-02': 'mail-secure-02',
    'mail-send-01': 'mail-send-01',
    'mail-send-02': 'mail-send-02',
    'mail-setting-01': 'mail-setting-01',
    'mail-setting-02': 'mail-setting-02',
    'mail-unlock-01': 'mail-unlock-01',
    'mail-unlock-02': 'mail-unlock-02',
    'mail-upload-01': 'mail-upload-01',
    'mail-upload-02': 'mail-upload-02',
    'mail-validation-01': 'mail-validation-01',
    'mail-validation-02': 'mail-validation-02',
    'mail-voice-01': 'mail-voice-01',
    'mail-voice-02':
        'voice record message, voice email, record gmail, voice message, record voice mail',
    'male-02': 'male-02',
    'male-symbol': 'male-symbol',
    man: 'man',
    manager: 'admin, boss',
    'man-woman': 'man-woman',
    maping: 'map, location, navigate',
    'map-pin': 'map, location, gps, destination, pin',
    'map-pinpoint-01': 'map, location, gps, navigate, destination, pinpoint, pin, position tracker',
    'map-pinpoint-02': 'map, location, gps, navigate, destination, pinpoint, pin, position tracker',
    maps: 'map, location, navigate, pin',
    'maps-circle-01': 'map, location, circle map',
    'maps-circle-02': 'map, location, circle map',
    'maps-editing': 'location editing, map editing',
    'maps-global-01': 'global map',
    'maps-global-02': 'global map',
    'maps-location-01': 'map, location, navigate, pin',
    'maps-location-02': 'map, location, navigate, pin',
    'maps-off': 'map break, map off',
    'maps-refresh': 'map, location, gps, location reload, map refresh',
    'maps-search':
        'location, gps, locate, map search, map find, magnifying glass, look up, map discover',
    'maps-square-01': 'map, location, square map',
    'maps-square-02': 'map, location, square map',
    'market-analysis': 'market-analysis',
    marketing: 'marketing, announce, promotional',
    mask: 'mask',
    'mask-love': 'mask-love',
    'master-card': 'master-card',
    matches: 'matches',
    math: 'calculation, numbers, arithmetic, calculations, mathematics, numeric, figures, computations, numerical, algebraic, math',
    matrix: 'binary weave, code lattice, matrix',
    'maximize-01': 'maximize-01',
    'maximize-02': 'maximize-02',
    'maximize-03': 'maximize-03',
    'maximize-04': 'maximize-04',
    'maximize-screen': 'maximize-screen',
    'mayan-pyramid': 'mayan-pyramid',
    maze: 'maze',
    'medal-01': 'reward, award, badge',
    'medal-02': 'reward, award, badge',
    'medal-03': 'reward, award',
    'medal-04': 'reward, award',
    'medal-05': 'reward, award',
    'medal-06': 'reward, award',
    'medal-07': 'reward, award',
    'medal-first-place': 'reward, award',
    'medal-second-place': 'reward, award',
    'medal-third-place': 'reward, award',
    'medical-file': 'medical-file',
    'medical-mask':
        'face mask, health mask, surgical mask, protective mask, doctor mask, healthcare mask, hygiene mask, virus mask, safety mask, breathing mask, covid mask, facial covering, infection prevention mask, respiratory mask, illness mask, public health mask, contagion mask, pandemic mask, safety precaution mask',
    'medicine-01': 'medicine-01',
    'medicine-02': 'medicine-02',
    'medicine-bottle-01': 'medicine-bottle-01',
    'medicine-bottle-02': 'medicine-bottle-02',
    'medicine-syrup': 'medicine-syrup',
    medium: 'medium',
    'medium-square': 'medium-square',
    'meeting-room': 'meeting-room',
    'megaphone-01':
        'loudspeaker, announcement symbol, amplification tool, communication horn, public address device, voice projection symbol, broadcast icon, speaker cone, vocal amplifier, announcement megaphone, megaphone, mega phone, mic',
    'megaphone-02':
        'loudspeaker, announcement symbol, amplification tool, communication horn, public address device, voice projection symbol, broadcast icon, speaker cone, vocal amplifier, announcement megaphone, megaphone, mega phone, mic',
    'megaphone-03': 'megaphone-03',
    meh: 'indifferent face, so-so symbol, meh expression, neutral icon, ambivalent emoticon, shrug symbol, apathetic face, nonchalant emoji, unimpressed symbol, indifferent emoticon',
    mentor: 'mentor, instructor, professor, academic, coach, facilitator, career, business career, teacher',
    mentoring:
        'mentoring, guide, advisor, teacher, coach, guru, expert, counselor, instructor, leader, role model, helper, supporter, consultant, mentorship, mentor',
    'menu-01': 'menu, hamburger, lines, list, grid',
    'menu-02': 'menu, hamburger, lines, list, grid',
    'menu-03': 'menu, hamburger, lines, list, grid',
    'menu-04': 'menu, hamburger, lines, list, grid',
    'menu-05': 'menu, hamburger, lines, list, grid',
    'menu-06': 'menu, hamburger, lines, list, grid',
    'menu-07': 'menu, hamburger, lines, list, grid',
    'menu-08': 'menu, hamburger, lines, list, grid',
    'menu-09': 'menu, hamburger, lines, list, grid',
    'menu-10': 'menu, hamburger, lines, list, grid',
    'menu-11': 'menu, hamburger, lines, list, grid',
    'menu-circle': 'menu, circle, oval',
    'menu-restaurant': 'menu-restaurant',
    'menu-square': 'menu, square',
    'menu-two-line': 'menu-two-line',
    'message-01': 'message-01',
    'message-02': 'chat, communication',
    'message-add-01': 'message-add-01',
    'message-add-02': 'message-add-02',
    'message-blocked': 'mute chat, mute message, hide message',
    'message-cancel-01': 'message-cancel-01',
    'message-cancel-02': 'message-cancel-02',
    'message-delay-01': 'message-delay-01',
    'message-delay-02': 'message-delay-02',
    'message-done-01': 'message-done-01',
    'message-done-02': 'message done, chat done, finished communication',
    'message-download-01': 'message-download-01',
    'message-download-02': 'message-download-02',
    'message-edit-01': 'message-edit-01',
    'message-edit-02': 'message-edit-02',
    'message-favourite-01': 'message-favourite-01',
    'message-favourite-02': 'message-favourite-02',
    'message-incoming-01': 'message-incoming-01',
    'message-incoming-02': 'message-incoming-02',
    'message-lock-01': 'message-lock-01',
    'message-lock-02': 'message-lock-02',
    'message-multiple-01': 'communication, conversation',
    'message-multiple-02': 'message-multiple-02',
    'message-notification-01': 'message-notification-01',
    'message-notification-02': 'message-notification-02',
    'message-outgoing-01': 'message-outgoing-01',
    'message-outgoing-02': 'message-outgoing-02',
    'message-preview-01': 'message-preview-01',
    'message-preview-02': 'message-preview-02',
    'message-programming': 'message-programming',
    'message-question': 'message-question',
    'message-search-01': 'message-search-01',
    'message-search-02': 'message-search-02',
    'message-secure-01': 'message-secure-01',
    'message-secure-02': 'message-secure-02',
    'message-translate': 'message-translate',
    'message-unlock-01': 'message-unlock-01',
    'message-unlock-02': 'message-unlock-02',
    'message-upload-01': 'message-upload-01',
    'message-upload-02': 'message-upload-02',
    'message-user-01': 'message-user-01',
    'message-user-02': 'message-user-02',
    messenger: 'messenger',
    meta: 'meta',
    metro: 'metro icon, subway, rapid transit, underground, urban rail, city train, transit system',
    'mic-01': 'microphone, voice recorder, audio symbol, sound capture, record, speaker',
    'mic-02': 'microphone, voice recorder, audio, sound capture, speech symbol, speaker',
    'mic-off-01':
        'microphone mute, audio off, quiet mode, mute audio, soundless, voice off, mic off',
    'mic-off-02':
        'microphone mute, audio off, sound silence, voice disabled, mute mic, quiet mode, audio hush, no sound, microphone off, mute audio, silent mic, soundless, voice off, audio mute, muted microphone, mic off',
    microscope: 'microscope',
    microsoft: 'microsoft',
    'microsoft-admin': 'microsoft-admin',
    microwave: 'microwave',
    'milk-bottle': 'milk-bottle',
    'milk-carton': 'milk-carton',
    'milk-coconut': 'milk-coconut',
    'milk-oat': 'milk-oat',
    'minimize-01': 'minimize-01',
    'minimize-02': 'minimize-02',
    'minimize-03': 'minimize-03',
    'minimize-04': 'minimize-04',
    'minimize-screen': 'minimize-screen',
    'mining-01': 'mining-01',
    'mining-02': 'mining-02',
    'mining-03': 'mining-03',
    'minus-plus-01': 'minus plus',
    'minus-plus-02': 'minus plus',
    'minus-plus-circle-01': 'minus-plus-circle-01',
    'minus-plus-circle-02': 'minus-plus-circle-02',
    'minus-plus-square-01': 'minus-plus-square-01',
    'minus-plus-square-02': 'minus-plus-square-02',
    'minus-sign': 'minus',
    'minus-sign-circle': 'minus-sign-circle',
    'minus-sign-square': 'minus-sign-square',
    mirror: 'mirror',
    'mirroring-screen': 'mirroring-screen',
    mixer: 'mixer',
    'mobile-navigator-01': 'map, location, gps, pin, mobile navigator map',
    'mobile-navigator-02': 'map, location, gps, pin, mobile navigator map',
    'mobile-programming-01': 'mobile-programming-01',
    'mobile-programming-02': 'mobile-programming-02',
    'mobile-protection': 'mobile-protection',
    'mobile-security': 'mobile-security',
    mochi: 'mochi',
    'modern-tv': 'share screen',
    'modern-tv-4-k': 'modern-tv-4-k',
    'modern-tv-issue': 'modern-tv-issue',
    molecules:
        'tiny elements, atomic fusion, molecular mix, nano structures, particle ensemble, micro units, chemical bonds, elemental fusion, molecular array, atom matrix',
    monas: 'monas',
    'money-01': 'money-01',
    'money-02': 'money-02',
    'money-03': 'money-03',
    'money-04': 'money-04',
    'money-add-01': 'money-add-01',
    'money-add-02': 'money-add-02',
    'money-bag-01': 'fund, funding',
    'money-bag-02': 'fund, funding, safe money',
    'money-exchange-01': 'money-exchange-01',
    'money-exchange-02': 'money-exchange-02',
    'money-exchange-03': 'money-exchange-03',
    'money-not-found-01': 'money-not-found-01',
    'money-not-found-02': 'money-not-found-02',
    'money-not-found-03': 'money-not-found-03',
    'money-not-found-04': 'money-not-found-04',
    'money-receive-01': 'money-receive-01',
    'money-receive-02': 'money-receive-02',
    'money-receive-circle': 'money-receive-circle',
    'money-receive-flow-01': 'money-receive-flow-01',
    'money-receive-flow-02': 'money-receive-flow-02',
    'money-receive-square': 'earn money, fund money, funding',
    'money-remove-01': 'money-remove-01',
    'money-remove-02': 'money-remove-02',
    'money-safe': 'money-safe',
    'money-saving-jar': 'money-saving-jar',
    'money-security': 'safe money',
    'money-send-01': 'money-send-01',
    'money-send-02': 'money-send-02',
    'money-send-circle': 'send money',
    'money-send-flow-01': 'money-send-flow-01',
    'money-send-flow-02': 'money-send-flow-02',
    'money-send-square': 'send money',
    monocle: 'monocle',
    'monocle-01':
        'critter, beastie, fiend, creature, monstro, ghoul, sprite, daemon, titan, ogre, chimera, behemoth, leviathan, gremlin, phantom',
    moon: 'luna, celestial orb, night sky, lunar sphere, crescent glow, midnight beacon, astral globe, moonlight emblem, silver radiance, lunar disk',
    'moon-01':
        'recognizable: luna, celestial orb, nighttime symbol, lunar sphere, crescent, night light, sky emblem, astral globe, lunar icon, moonbeam, moon',
    'moon-02': 'moon, night, lunar, moonlight',
    'moon-angled-rain-zap':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow, zap',
    'moon-cloud': 'moon, night, lunar, moonlight, moon wind, moon cloud',
    'moon-cloud-angled-rain': 'moon, night, lunar, moonlight, moon wind, moon rain, cloud',
    'moon-cloud-angled-zap':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-cloud-big-rain': 'moon, night, lunar, moonlight, moon wind, moon cloud',
    'moon-cloud-fast-wind':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-cloud-hailstone':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-cloud-little-rain': 'moon, night, lunar, moonlight, moon wind, moon rain, cloud',
    'moon-cloud-little-snow':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-cloud-mid-rain': 'moon, night, lunar, moonlight, moon wind, moon rain, cloud',
    'moon-cloud-mid-snow':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-cloud-slow-wind':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-cloud-snow':
        'moon, night, lunar, moonlight, moon wind, moon rain, cloud, snow, cloud snow',
    'moon-eclipse': 'moon, night, lunar, moonlight',
    'moon-fast-wind': 'moon, night, lunar, moonlight, moon wind',
    'moon-landing':
        'lunar-touchdown, celestial-descent, space-landing, orbit-arrival, astral-landing, cosmic-touchdown, star-landing, galaxy-arrival, celestial-landing, space-exploration',
    moonset: 'moon, night, lunar, moonlight, moonset',
    'moon-slow-wind': 'moon, night, lunar, moonlight, moon wind',
    more: 'additional, expand, extra, options, further, menu, details, extras, extended, supplementary, additional actions',
    'more-01': 'menu, oval',
    'more-02': 'menu, oval',
    'more-03': 'menu, square',
    'more-horizontal': 'horizontal, more',
    'more-horizontal-circle-01': 'horizontal, more',
    'more-horizontal-circle-02': 'horizontal, more, circle',
    'more-horizontal-square-01': 'horizontal, more, square',
    'more-horizontal-square-02': 'horizontal, more, square',
    'more-or-less': 'more-or-less',
    'more-or-less-circle': 'more-or-less-circle',
    'more-or-less-square': 'more-or-less-square',
    'more-vertical': 'vertical, more',
    'more-vertical-circle-01': 'vertical, more',
    'more-vertical-circle-02':
        'more-vertical-circle icon, vertical-options-circle, expand-menu-circle, additional-choices-circle, extra-vertical-circle, extended-settings-circle, supplementary-vertical-circle, menu-expand-circle, added-vertical-circle, more-options-circle',
    'more-vertical-square-01': 'horizontal, more, square',
    'more-vertical-square-02': 'vertical, more, square',
    mortar: 'mortar',
    'mortarboard-01': 'student, mentor, graduate',
    'mortarboard-02': 'student, mentor, graduate',
    'mosque-01': 'mosque-01',
    'mosque-02': 'mosque-02',
    'mosque-03': 'mosque-03',
    'mosque-04': 'mosque-04',
    'mosque-05': 'mosque-05',
    'mosque-location': 'mosque-location',
    'motion-01': 'motion-01',
    'motion-02': 'motion-02',
    'motorbike-01':
        'motorbike icon, bike, motorcycle, two-wheeler, cycle, motor, chopper, hog, cruiser, ride, two-wheel-drive, motorized bicycle',
    'motorbike-02':
        'motorbike icon, bike, motorcycle, two-wheeler, cycle, motor, chopper, hog, cruiser, ride, two-wheel-drive, motorized bicycle',
    mountain: 'hill, jabal',
    'mouse-01': 'mouse',
    'mouse-02': 'mouse',
    'mouse-03': 'mouse',
    'mouse-04': 'mouse',
    'mouse-05': 'mouse',
    'mouse-06': 'mouse',
    'mouse-07': 'mouse',
    'mouse-08': 'mouse',
    'mouse-09': 'mouse',
    'mouse-10': 'mouse',
    'mouse-11': 'mouse',
    'mouse-12': 'mouse',
    'mouse-13': 'mouse',
    'mouse-14': 'mouse',
    'mouse-15': 'mouse',
    'mouse-16': 'mouse',
    'mouse-17': 'mouse',
    'mouse-18': 'mouse',
    'mouse-19': 'mouse',
    'mouse-20': 'mouse',
    'mouse-21': 'mouse',
    'mouse-22': 'mouse-22',
    'mouse-23': 'mouse-23',
    'mouse-left-click-01': 'mouse scroll',
    'mouse-left-click-02': 'mouse left click, mouse click, mouse left',
    'mouse-left-click-03': 'mouse left click, mouse click, mouse left',
    'mouse-left-click-04': 'mouse left click, mouse click, mouse left',
    'mouse-left-click-05': 'mouse left click, mouse click, mouse left',
    'mouse-left-click-06': 'mouse left click, mouse click, mouse left',
    'mouse-right-click-01': 'mouse right click, mouse click, mouse right',
    'mouse-right-click-02': 'mouse right click, mouse click, mouse right',
    'mouse-right-click-03': 'mouse, mouse right click',
    'mouse-right-click-04': 'mouse right click, mouse click, mouse right',
    'mouse-right-click-05': 'mouse right click, mouse click, mouse right',
    'mouse-right-click-06': 'mouse right click, mouse click, mouse right',
    'mouse-scroll-01': 'mouse scroll',
    'mouse-scroll-02': 'mouse scroll',
    move: 'move, arrow move, move arrow',
    'move-01': 'move-01',
    'move-02': 'move-02',
    'move-bottom': 'move-bottom',
    'move-left': 'move-left',
    'move-right': 'move-right',
    'move-to': 'move-to',
    'move-top': 'move-top',
    'mp3-01': 'mp3-01',
    'mp-3-02': 'mp-3-02',
    'mp-4-01': 'mp-4-01',
    'mp-4-02': 'mp-4-02',
    muhammad: 'muhammad',
    'multiplication-sign': 'cross, multiplication, math, x',
    'multiplication-sign-circle': 'multiplication-sign-circle',
    'multiplication-sign-square': 'multiplication-sign-square',
    mushroom: 'mushroom',
    'mushroom-01': 'mushroom-01',
    'music-note-01': 'audio, sound, music',
    'music-note-02': 'audio, sound, music, listening',
    'music-note-03': 'audio, sound, music',
    'music-note-04': 'audio, sound, music',
    'music-note-square-01': 'audio, sound, music, square music',
    'music-note-square-02': 'audio, sound, music, square music',
    muslim: 'muslim',
    mute: 'silent symbol, sound off icon, quiet face, muted emoticon, hush symbol, silent expression, mute icon',
    mymind: 'mymind',
    'nano-technology':
        'nano tech, molecular innovation, microscopic advancement, tiny world, smart materials, nano wonders, molecular engineering, microscale marvel, nano innovations, subatomic breakthrough.',
    'napkins-01': 'napkins-01',
    'napkins-02': 'napkins-02',
    'natural-food': 'natural-food',
    'navigation-01': 'navigation',
    'navigation-02': 'navigation',
    'navigation-03': 'map, location, mobile navigator map, send, share',
    'navigation-04': 'compass, direction, map, location, gps, navigate, navigator',
    'navigator-01': 'road, street, highway, path, route, navigator',
    'navigator-02': 'road, street, highway, path, route, navigator',
    necklace: 'necklace',
    nerd: 'brainiac symbol, geek face, intellectual icon, smarty emoticon, knowledge avatar, thinker symbol, brainy expression, scholarly emoji, wise visage, genius face',
    'neural-network': 'neural-network',
    neutral:
        'balanced face, even-keeled expression, middle-ground symbol, calm emoticon, serene face, equanimous icon, composed emoji, tranquil symbol, impartial emoticon, relaxed face',
    'new-job': 'find job',
    'new-office': 'new-office',
    'new-releases': 'new-releases, verify, approval symbol in badge',
    news: 'blog, article, journal, news paper',
    'news-01': 'blog, article, news paper',
    'new-twitter': 'x, elon, twitter',
    'new-twitter-ellipse': 'x, elon, twitter, circle x, circle old twitter',
    'new-twitter-rectangle': 'x, elon, twitter, square x',
    next: 'play next, playback hub',
    nike: 'nike',
    nintendo: 'nintendo',
    'nintendo-switch': 'nintendo-switch',
    niqab: 'niqab',
    'node-add': 'node-add',
    'node-edit': 'node-edit',
    'node-move-down': 'node-move-down',
    'node-move-up': 'node-move-up',
    'node-remove': 'node-remove',
    'no-internet': 'no-internet',
    'no-meeting-room': 'no-meeting-room',
    noodles: 'noodles',
    nose: 'nose',
    note: 'note',
    'note-01': 'note-01',
    'note-02': 'note-02',
    'note-03': 'note-03',
    'note-04': 'note-04',
    'note-05': 'note-05',
    'note-add': 'note-add',
    notebook: 'notebook',
    'notebook-01': 'notebook-01',
    'notebook-02':
        'journal, diary, notepad, jotter, logbook, sketchbook, pad, memo book, record book, writing pad, notebooklet, scribble pad, composition book, exercise book, planner, notebook, note book, note, book',
    'note-done': 'note-done',
    'note-edit': 'note-edit',
    'not-equal-sign': 'not twin, not matching, not equal, not equality',
    'not-equal-sign-circle': 'not-equal-sign-circle',
    'not-equal-sign-square': 'not-equal-sign-square',
    'note-remove': 'note-remove',
    'notification-01': 'notification, notifier, alert, notice',
    'notification-02': 'notification, notifier, alert, bell, notification bell',
    'notification-03': 'notification, notifier, alert, notice',
    'notification-block-01': 'notification block',
    'notification-block-02': 'notification-block',
    'notification-block-03': 'notification-block, block notification, notification',
    'notification-bubble':
        'notification, notifier, alert, notice, notification-bubble, bubble notification',
    'notification-circle': 'notification, notifier, alert, notice, notification circle',
    'notification-off-01': 'notification-off-01',
    'notification-off-02': 'notification off, silent, notification mute',
    'notification-off-03':
        'notification-off, mute-alert, silence-indicator, alert-silenced, quiet-notification, inactive-alert, silent-alert, notice-disabled, hush-alert, notify-off, alert-mute.',
    'notification-snooze-01': 'notification-snooze-01',
    'notification-snooze-02': 'notification-snooze-02',
    'notification-snooze-03': 'notification-snooze,snooze-bell',
    'notification-square': 'notification, notifier, alert, notice, notification-square',
    'notion-01': 'notion-01',
    'notion-02': 'notion-02',
    npm: 'npm',
    'n-th-root': 'n-th-root',
    'n-th-root-circle': 'n-th-root-circle',
    'n-th-root-square': 'n-th-root-square',
    'nuclear-power': 'nuclear power, nuclear',
    nut: 'nut',
    obtuse: 'wide, obtuse',
    octagon: 'octagon',
    octopus: 'octopus',
    office: 'office',
    'office-365': 'office-365',
    'office-chair': 'office-chair',
    'oil-barrel': 'oil barrel, oil, barrel',
    'ok-finger': 'ok-finger',
    'olympic-torch': 'olympic-torch',
    'online-learning-01': 'online-learning-01',
    'online-learning-02': 'online-learning-02',
    'online-learning-03': 'online-learning-03',
    'online-learning-04': 'online-learning-04',
    'open-caption': 'open-caption',
    option: 'option',
    orange: 'orange',
    'orbit-01': 'circulate, cycle, revolve, spin, whirl, loop, rotate, gyrate, spiral, twirl',
    'orbit-02': 'circulate, cycle, revolve, spin, whirl, loop, rotate, gyrate, spiral, twirl',
    'organic-food': 'organic-food',
    'orthogonal-edge': 'orthogonal-edge',
    oval: 'oval',
    oven: 'oven',
    package: 'shipping, package, parcel, box',
    'package-add': 'shipping add, package add, parcel add, box add',
    'package-delivered': 'shipped, package delivered, parcel delivered, box delivered',
    'package-moving': 'shipping moving, package moving, parcel moving, box moving',
    'package-open': 'shipping open, package open, parcel open, box open',
    'package-out-of-stock': 'shipping\\n, package, parcel, box',
    'package-process': 'shipping process, package process, parcel process, box process',
    'package-receive': 'shipping receive, package receive, parcel receive, box receive',
    'package-remove': 'shipping remove, package remove, parcel remove, box remove',
    'package-search': 'shipping search, package search, parcel, box',
    'package-sent': 'shipping sent, package sent, parcel sent, box sent',
    'pacman-01': 'pacman-01',
    'pacman-02': 'pacman-02',
    'paint-board':
        'color canvas, art palette, drawing surface, sketch pad, creative board, paint palette, design canvas, artistic panel, visual board, drawing board, paint board, colors',
    'paint-brush-01':
        'ill tool, color fill, paint can, bucket fill, ink fill, color bucket, fill bucket, paint fill, solid fill, colorize, paint bucket, bucket paint, paint, bucket',
    'pan-01': 'pan-01',
    'pan-02': 'pan-02',
    'pan-03': 'pan-03',
    'parabola-01': 'curve, parabola, arc, mathematical, geometric',
    'parabola-02': 'curve, parabola, arc, mathematical, geometric',
    'parabola-03': 'curve, parabola, arc, mathematical, geometric',
    paragliding: 'paragliding',
    paragraph:
        'text block, content section, body of text, narrative, passage, textual segment, written excerpt, discourse snippet, composition, script section, block of writing, prose excerpt, paragraph',
    'paragraph-bullets-point-01': 'paragraph-bullets-point-01',
    'paragraph-bullets-point-02': 'paragraph-bullets-point-02',
    'paragraph-spacing': 'paragraph-spacing',
    parallelogram: 'parallelogram',
    'parking-area-circle':
        'parking-area-circle icon, parking-circle, vehicle-spot-circle, car-space-round, auto-zone-circle, parking-lot-circle, car-park-circle, vehicle-area-round, automobile-space-circle',
    'parking-area-square':
        'parking-area-square icon, parking-square, vehicle-park, parking-spot-square, car-space, square-parking, auto-zone, parking-lot-square, car-park, square-lot, automobile-area',
    party: 'party',
    passport: 'id',
    'passport-01': 'passport-01',
    'passport-expired': 'passport-expired',
    'passport-valid': 'id',
    'password-validation': 'password-validation',
    path: 'path',
    'pathfinder-crop': 'pathfinder-crop',
    'pathfinder-divide': 'pathfinder-divide',
    'pathfinder-exclude': 'pathfinder-exclude',
    'pathfinder-intersect': 'pathfinder-intersect',
    'pathfinder-merge': 'pathfinder-merge',
    'pathfinder-minus-back': 'pathfinder-minus-back',
    'pathfinder-minus-front': 'pathfinder-minus-front',
    'pathfinder-outline': 'pathfinder-outline',
    'pathfinder-trim': 'pathfinder-trim',
    'pathfinder-unite': 'pathfinder-unite',
    patient: 'patient',
    patio: 'patio',
    pause: 'pause, media library',
    'pause-circle': 'pause-circle',
    pavilon: 'pavilon',
    'pay-by-check': 'agreement',
    'payment-01': 'payment-01',
    'payment-02': 'payment-02',
    'payment-success-01': 'payment-success-01',
    'payment-success-02': 'payment-success-02',
    payoneer: 'payoneer',
    paypal: 'paypal',
    'pdf-01': 'pdf-01',
    'pdf-02': 'pdf-02',
    'peer-to-peer-01': 'peer-to-peer-01',
    'peer-to-peer-02': 'peer-to-peer-02',
    'peer-to-peer-03': 'peer-to-peer-03',
    'pen-01': 'pen-01',
    'pen-02': 'pen-02',
    pencil: 'pencil',
    'pencil-edit-01':
        'edit tool, write edit, modify pencil, update icon, change pen, revise marker, amend writing, alteration symbol, redact instrument, adjust notation, corrective graphite, revamp quill, pencil edit, edit pencil, edit, pencil',
    'pencil-edit-02':
        'edit tool, write edit, modify pencil, update icon, change pen, revise marker, amend writing, alteration symbol, redact instrument, adjust notation, corrective graphite, revamp quill, pencil edit, edit pencil, edit, pencil',
    'pen-connect-bluetooth': 'pen-connect-bluetooth',
    'pen-connect-usb': 'pen-connect-usb',
    'pen-connect-wifi': 'pen-connect-wifi',
    pendulum:
        'swing, oscillator, swayer, rhythmic motion, bob, timekeeper, metronome, back-and-forth, sway, tictoc, arc-motion, balance-swing, pendular movement',
    pensive:
        'reflective face, thoughtful expression, contemplative symbol, meditative icon, pondering emoticon, melancholic face, considerate emoji, introspective symbol, brooding icon, musing emoticon, reflecting face, and thoughtful emo',
    pentagon: 'pentagon',
    'pentagon-01': 'pentagon-01',
    'pen-tool-01':
        'writing instrument, marker tool, drawing pen, freehand tool, sketch tool, pencil icon, creative pen, drafting tool, annotation tool, digital pen, artistic tool, line tool, pentool, pen tool, pen, tool, tool pen',
    'pen-tool-02':
        'writing instrument, marker tool, drawing pen, freehand tool, sketch tool, pencil icon, creative pen, drafting tool, annotation tool, digital pen, artistic tool, line tool, pentool, pen tool, pen, tool, tool pen',
    'pen-tool-03':
        'writing instrument, marker tool, drawing pen, freehand tool, sketch tool, pencil icon, creative pen, drafting tool, annotation tool, digital pen, artistic tool, line tool, pentool, pen tool, pen, tool, tool pen',
    'pen-tool-add': 'pen-tool-add',
    'pen-tool-minus': 'pen-tool-minus',
    percent: 'numeric, proportion, ratio, fraction, relative value, share, percent',
    'percent-circle': 'percent-circle',
    'percent-square': 'percent-square',
    perfume: 'perfume',
    periscope: 'periscope',
    'permanent-job': 'full time job, in house job',
    'perplexity-ai': 'perplexity-ai',
    perspective: 'perspective',
    'petrol-pump': 'petrol-pump',
    pexels: 'pexels',
    'phone-off-01': 'phone-off-01',
    'phone-off-02': 'phone-off-02',
    php: 'php',
    physics: 'physics',
    pi: 'pi',
    picasa: 'picasa',
    'pi-circle': 'pi-circle',
    'pickup-01':
        'pickup icon, grab, collect, acquire, retrieve, select, gather, snatch, procure, obtain, harvest',
    'pickup-02':
        'pickup icon, grab, collect, acquire, retrieve, select, gather, snatch, procure, obtain, harvest',
    'picture-in-picture-exit': 'picture-in-picture-exit',
    'picture-in-picture-on': 'picture-in-picture-on',
    pie: 'pie',
    'pie-chart': 'pie-chart',
    'pie-chart-01': 'pie-chart-01',
    'pie-chart-02': 'pie-chart-02',
    'pie-chart-03': 'pie-chart-03',
    'pie-chart-04': 'pie-chart-04',
    'pie-chart-05': 'pie-chart-05',
    'pie-chart-06': 'pie-chart-06',
    'pie-chart-07': 'pie-chart-07',
    'pie-chart-08': 'pie-chart-08',
    'pie-chart-09': 'pie-chart-09',
    'pie-chart-square': 'pie-chart-square',
    'piggy-bank': 'piggy-bank',
    pin: 'pin',
    'pin-02': 'pin-02',
    'pin-code': 'pin-code',
    'pin-location-01': 'destination, pin',
    'pin-location-02': 'destination, pin',
    'pin-location-03': 'destination, pin',
    'pin-off': 'pin, pin off',
    pinterest: 'pinterest',
    pipeline: 'pipeline',
    'pisa-tower': 'pisa-tower',
    'pi-square': 'pi-square',
    pivot: 'pivot',
    'pizza-01': 'pizza-01',
    'pizza-02': 'pizza-02',
    'pizza-cutter': 'pizza-cutter',
    plane: 'plane icon, aircraft, jet, airplane, flying machine, air shuttle, sky cruiser, aerial vehicle, winged transport, aviation craft, air travel symbol',
    'plant-01': 'plant-01',
    'plant-02': 'plant-02',
    'plant-03': 'plant-03',
    'plant-04': 'plant-04',
    plate: 'plate',
    plaxo: 'plaxo',
    play: 'play',
    'play-circle': 'play-circle',
    'play-circle-02': 'play-circle-02',
    'play-list': 'play-list',
    'playlist-01': 'playlist, music, media library',
    'playlist-02': 'playlist, music, media library',
    'playlist-03': 'playlist, music, media library',
    'play-list-add': 'play-list-add',
    'play-list-favourite-01': 'play-list-favourite-01',
    'play-list-favourite-02': 'play-list-favourite-02',
    'play-list-minus': 'play-list-minus',
    'play-list-remove': 'play-list-remove',
    'play-square': 'play-square',
    'play-store': 'play-store',
    plaza: 'plaza',
    'plug-01': 'plug',
    'plug-02': 'plug round',
    'plug-socket': 'socket, plug socket',
    'plus-minus': 'plus-minus',
    'plus-minus-01': 'plus minus',
    'plus-minus-02': 'plus minus',
    'plus-minus-circle-01': 'plus-minus-circle-01',
    'plus-minus-circle-02': 'plus-minus-circle-02',
    'plus-minus-square-01': 'plus-minus-square-01',
    'plus-minus-square-02': 'plus-minus-square-02',
    'plus-sign': 'add, plus',
    'plus-sign-circle': 'plus-sign-circle',
    'plus-sign-square': 'plus-sign-square',
    'png-01': 'png-01',
    'png-02': 'png-02',
    podcast: 'podcast',
    podium: 'podium',
    'pointing-left-01': 'pointing-left-01',
    'pointing-left-02': 'pointing-left-02',
    'pointing-left-03': 'pointing-left-03',
    'pointing-left-04': 'pointing-left-04',
    'pointing-left-05': 'pointing-left-05',
    'pointing-left-06': 'pointing-left-06',
    'pointing-left-07': 'pointing-left-07',
    'pointing-left-08': 'pointing-left-08',
    'pointing-right-01': 'pointing-right-01',
    'pointing-right-02': 'pointing-right-02',
    'pointing-right-03': 'pointing-right-03',
    'pointing-right-04': 'pointing-right-04',
    'pointing-right-05': 'pointing-right-05',
    'pointing-right-06': 'pointing-right-06',
    'pointing-right-07': 'pointing-right-07',
    'pointing-right-08': 'pointing-right-08',
    pokeball: 'pokeball',
    pokemon: 'pokemon',
    'police-badge': 'police-badge',
    'police-cap': 'police-cap',
    'police-car':
        'cop-car, law-enforcement-vehicle, patrol-car, cruiser, squad-car, lawman-auto, copmobile, enforcement-vehicle',
    'police-station': 'police-station',
    policy: 'policy',
    polygon: 'polygon',
    'poly-tank': 'poly-tank',
    pool: 'pool',
    'pool-table': 'pool-table',
    poop: 'poop',
    popcorn: 'popcorn',
    'pot-01': 'pot-01',
    'pot-02': 'pot-02',
    potion: 'potion',
    pound: 'pound',
    'pound-circle': 'pound-circle',
    'pound-receive': 'pound-receive',
    'pound-send': 'pound-send',
    'pound-square': 'pound-square',
    'power-service': 'power service, service, power',
    'power-socket-01': 'power socket, socket',
    'power-socket-02': 'power socket, socket',
    'ppt-01': 'ppt-01',
    'ppt-02': 'ppt-02',
    prawn: 'prawn',
    'prayer-rug-01': 'prayer-rug-01',
    'prayer-rug-02': 'prayer-rug-02',
    'preference-horizontal': 'filter horizontal, filter, purifier, refine',
    'preference-vertical': 'preference vertical',
    prescription: 'prescription',
    'presentation-01': 'presentation-01',
    'presentation-02': 'presentation-02',
    'presentation-03': 'presentation-03',
    'presentation-04': 'presentation-04',
    'presentation-05': 'presentation-05',
    'presentation-06': 'presentation-06',
    'presentation-07': 'presentation-07',
    'presentation-bar-chart-01': 'presentation-bar-chart-01',
    'presentation-bar-chart-02': 'presentation-bar-chart-02',
    'presentation-line-chart-01': 'presentation-line-chart-01',
    'presentation-line-chart-02': 'presentation-line-chart-02',
    'presentation-online': 'presentation-online',
    'presentation-podium': 'presentation-podium',
    previous: 'play, previous',
    printer: 'printer',
    'printer-off': 'printer-off',
    prism: 'prism',
    'prism-01':
        'colorbeam, spectrumflare, lightsplit, chromalens, rainbowray, huebeam, spectrumfocus, prismalight',
    prison: 'prison',
    prisoner: 'career, business career',
    'prison-guard': 'career, business career',
    'product-loading': 'product loading, product',
    profile:
        'user, account, person, avatar, identity, portrait, my info, personal, my account, user info, character, individual, persona, user profile, my profile, member, account settings, user details, profile, profile information',
    'profile-02': 'profile-02',
    profit: 'earnings, gains, revenue, income, returns, wealth, surplus, benefit, advantage, proceeds, winnings, win, profit',
    'programming-flag': 'programming-flag',
    progress: 'progress',
    projector: 'projector',
    'projector-01': 'projector-01',
    promotion: 'marketing, announce, promotion',
    'property-add': 'property-add',
    'property-delete': 'property-delete',
    'property-edit': 'property-edit',
    'property-new': 'property-new',
    'property-search': 'property-search',
    'property-view': 'property-view',
    'protection-mask': 'protection-mask',
    pulley: 'rope wheel, lifting gear, hoist wheel, cable roller, elevation wheel, traction disc, draw wheel, tension wheel, guiding reel, lifting pulley',
    'pulse-01': 'pulse-01',
    'pulse-02': 'pulse-02',
    'pulse-rectangle-01': 'pulse-rectangle-01',
    'pulse-rectangle-02': 'pulse-rectangle-02',
    pumpkin: 'pumpkin',
    punch: 'punch',
    'punching-ball-01': 'punching-ball-01',
    'punching-ball-02': 'punching-ball-02',
    purse: 'handbag, clutch, wallet, pouch, satchel, tote, pocketbook, baguette, billfold, carryall, reticule, moneybag, knapsack, duffel, coin purse, bitcoin purse, purse, wallet',
    'purse-01': 'purse-01',
    'push-up-bar': 'push-up-bar',
    puzzle: 'puzzle',
    pyramid: 'pyramid',
    'pyramid-maslowo': 'pyramid-maslowo',
    'pyramid-structure-01': 'pyramid-structure-01',
    'pyramid-structure-02': 'pyramid-structure-02',
    python: 'python',
    'qq-plot': 'qq-plot',
    'qr-code': 'qr-code',
    'qr-code-01': 'qr-code-01',
    question: 'support',
    'queue-01': 'queue-01',
    'queue-02': 'queue-02',
    'quill-write-01': 'feather',
    'quill-write-02': 'feather',
    'quiz-01': 'quiz-01',
    'quiz-02': 'quiz-02',
    'quiz-03': 'quiz-03',
    'quiz-04': 'quiz-04',
    'quiz-05': 'quiz-05',
    quora: 'quora',
    'quote-down': 'quote-down',
    'quote-down-circle': 'quote-down-circle',
    'quote-down-square': 'quote-down-square',
    quotes: 'quotes',
    'quote-up': 'quote-up',
    'quote-up-circle': 'quote-up-circle',
    'quote-up-square': 'quote-up-square',
    'quran-01': 'quran-01',
    'quran-02': 'quran-02',
    'quran-03': 'quran-03',
    'racing-flag': 'racing-flag',
    'radar-01': 'direction, position tracker, radar, target locator',
    'radar-02': 'direction, position tracker, radar, target locator',
    radial: 'radial',
    radio: 'radio, wireless signal, radio waves, broadcast, audio device, antenna',
    'radio-01': 'radio-01',
    'radio-02': 'radio-02',
    'radioactive-alert': 'radioactive-alert',
    'radio-button': 'radio-button',
    radius: 'radius',
    rain: 'rain',
    rainbow: 'rainbow, color',
    'rain-double-drop': 'rain, rain drop, drop',
    'rain-drop': 'rain, rain drop, drop',
    'ramadhan-01': 'ramadhan-01',
    'ramadhan-02': 'ramadhan-02',
    'ramadhan-month': 'ramadhan-month',
    ranking: 'ranking',
    'raw-01': 'raw-01',
    'raw-02': 'raw-02',
    re: 're',
    're:': 're:',
    react: 'react',
    'real-estate-01': 'real-estate-01',
    'real-estate-02': 'real-estate-02',
    record: 'capture, audio memo, start recording, voice note, red dot, sound recording, record button, capture audio, voice recording',
    'recovery-mail': 'recovery-mail',
    rectangular: 'rectangular',
    'rectangular-01': 'rectangular-01',
    'recycle-01': 'recycle',
    'recycle-02': 'recycle',
    'recycle-03': 'recycle',
    reddit: 'reddit',
    reflex: 'reflex, instant, prompt',
    refresh: 'refresh',
    refrigerator: 'refrigerator',
    register: 'register',
    registered: 'registered',
    'relieved-01':
        'happy relief, content face, stress-free smile, relaxation expression, calm emoji, sigh of relief, tranquil grin, eased emoticon, serene symbol, unburdened face',
    'relieved-02':
        'happy relief, content face, stress-free smile, relaxation expression, calm emoji, sigh of relief, tranquil grin, eased emoticon, serene symbol, unburdened face',
    reload: 'reload',
    reminder: 'reminder',
    'remote-control': 'remote-control',
    'remove-01': 'remove, minus, minimize, decrease size, hide arrow',
    'remove-02': 'remove, minus, minimize, decrease size, hide arrow',
    'remove-circle':
        'remove, minus, minimize, decrease size, hide arrow, remove circle, minus-circle',
    'remove-circle-half-dot':
        'remove, minus, minimize, decrease size, hide arrow, remove circle, minus-circle, minus-circle-half-dot',
    'remove-female': 'remove-female',
    'remove-male': 'remove-male',
    'remove-pi': 'remove-pi',
    'remove-square':
        'remove, minus, minimize, decrease size, hide arrow, remove-square, minus-square',
    'renewable-energy': 'energy, renewable energy',
    'renewable-energy-01': 'save energy, energy',
    repair: 'repair',
    repeat: 'replay, loop, refresh, recycle, redo, replay arrow, cyclic, reoccur, iterate, repeat, recurrence',
    'repeat-off':
        'replay, loop, refresh, recycle, redo, replay arrow, cyclic, reoccur, iterate, repeat, recurrence,  repeat off, repeat close, repeat cancel',
    'repeat-one-01':
        'replay, loop, refresh, recycle, redo, replay arrow, cyclic, reoccur, iterate, repeat, recurrence',
    'repeat-one-02':
        'replay, loop, refresh, recycle, redo, replay arrow, cyclic, reoccur, iterate, repeat, recurrence',
    replay: 'replay',
    repository: 'repository',
    'reset-password': 'reset-password',
    'resize-01': 'resize-01',
    'resize-02': 'resize-02',
    'resources-add': 'resources-add',
    'resources-remove': 'resources-remove',
    restaurant: 'restaurant',
    'restaurant-01': 'restaurant-01',
    'restaurant-02': 'restaurant-02',
    'restaurant-03': 'restaurant-03',
    'restaurant-table': 'restaurant-table',
    'restore-bin':
        'restore-bin, bin-restore, recycle-restore, trash-recover, bin-undo, archive-restore',
    'return-request': 'return-request',
    'reverse-withdrawal-01': 'reverse-withdrawal-01',
    'reverse-withdrawal-02': 'reverse-withdrawal-02',
    rhombus: 'rhombus',
    'rhombus-01': 'rhombus-01',
    'rice-bowl-01': 'rice-bowl-01',
    'rice-bowl-02': 'rice-bowl-02',
    'right-angle': 'arrowhead, angle, angular, arrow',
    'right-to-left-block-quote': 'right-to-left-block-quote',
    'right-to-left-list-bullet': 'right-to-left-list-bullet',
    'right-to-left-list-dash': 'right-to-left-list-dash',
    'right-to-left-list-number': 'right-to-left-list-number',
    'right-to-left-list-triangle': 'right-to-left-list-triangle',
    'right-triangle': 'triangle, trigon',
    ripple: 'ripple',
    riyal: 'riyal',
    'riyal-rectangle': 'riyal-rectangle',
    road: 'road icon path, highway, route, street, thoroughfare, avenue, boulevard, freeway, expressway, roadway, lane, way',
    'road-01': 'road, street, highway, path, route',
    'road-02': 'road, street, highway, path, route',
    'road-location-01': 'map, location, destination, pin, position tracker, road, path',
    'road-location-02': 'map, location, destination, pin, position tracker, road, path',
    'road-wayside': 'road, path, road wayside, highway',
    'robot-01': 'robot-01',
    'robot-02': 'robot-02',
    robotic: 'robotic',
    rocket: 'rocket icon boost, soar, jet, propel, shuttle, launch, spacecraft, zoom, blast, fly',
    'rocket-01':
        'spacecraft, jet, galactic ship, speeder, blast-off, falcon, orbiter, cosmic cruiser, rocketeer, star voyager, lunar lander, celestial navigator, astronavigator, meteor mover, stellar shuttle, astro blaster, comet cruiser, nebula navigator, interstellar transporter, supernova soarer',
    'rocket-02':
        'spacecraft, jet, galactic ship, speeder, blast-off, falcon, orbiter, cosmic cruiser, rocketeer, star voyager, lunar lander, celestial navigator, astronavigator, meteor mover, stellar shuttle, astro blaster, comet cruiser, nebula navigator, interstellar transporter, supernova soarer',
    'rocking-chair': 'rocking-chair',
    'rocking-horse': 'rocking-horse',
    'roller-skate': 'roller-skate',
    'rolling-pin': 'rolling-pin',
    'root-01': 'root-01',
    'root-02': 'root-02',
    'root-1st-bracket': 'root-1st-bracket',
    'root-2nd-bracket': 'root-2nd-bracket',
    'root-3rd-bracket': 'root-3rd-bracket',
    'root-circle': 'root-circle',
    'rotate-01': 'rotate-01',
    'rotate-02': 'rotate-02',
    'rotate-360': 'rotate-360',
    'rotate-bottom-left': 'rotate-bottom-left',
    'rotate-bottom-right': 'rotate-bottom-right',
    'rotate-clockwise': 'rotate-clockwise',
    'rotate-crop': 'rotate-crop',
    'rotate-left-01': 'rotate-left-01',
    'rotate-left-02': 'rotate-left-02',
    'rotate-left-03': 'rotate-left-03',
    'rotate-left-04': 'rotate-left-04',
    'rotate-left-05': 'rotate-left-05',
    'rotate-left-06': 'rotate-left-06',
    'rotate-right-01': 'rotate-right-01',
    'rotate-right-02': 'rotate-right-02',
    'rotate-right-03': 'rotate-right-03',
    'rotate-right-04': 'rotate-right-04',
    'rotate-right-05': 'rotate-right-05',
    'rotate-right-06': 'rotate-right-06',
    'rotate-square': 'rotate-square',
    'rotate-top-left': 'rotate-top-left',
    'rotate-top-right': 'rotate-top-right',
    'route-01':
        'map, location, gps, navigate, destination, route planner, position tracker, road, path',
    'route-02':
        'map, location, gps, navigate, destination, route planner, position tracker, road, path',
    'route-03':
        'map, location, gps, navigate, destination, route planner, position tracker, road, path',
    'route-block': 'navigate, destination, road, path, route block, road cancel',
    router: 'router',
    'router-01': 'router-01',
    'router-02': 'router-02',
    'row-delete': 'row-delete',
    'row-insert': 'row-insert',
    rss: 'rss',
    'rss-connected-01': 'rss-connected-01',
    'rss-connected-02': 'rss-connected-02',
    'rss-error': 'rss-error',
    'rss-locked': 'rss-locked',
    'rss-unlocked': 'rss-unlocked',
    'rubber-duck': 'rubber-duck',
    'rub-el-hizb': 'rub-el-hizb',
    ruku: 'ruku',
    ruler: 'ruler',
    'running-shoes': 'running-shoes',
    'sad-01':
        'frowning face, unhappy icon, sorrow symbol, teary-eyed emoticon, gloomy face, downcast smiley, melancholic symbol, upset emoji, despondent icon',
    'sad-02':
        'frowning face, unhappy icon, sorrow symbol, teary-eyed emoticon, gloomy face, downcast smiley, melancholic symbol, upset emoji, despondent icon',
    'sad-dizzy':
        'miserable-spin, unhappy-daze, gloomy-whirl, melancholy-spin, sorrowful-dizzy, despondent-whirl, doleful-spin, dejected-daze, woeful-whirl, blue-dizzy',
    safari: 'safari',
    safe: 'safe',
    'safe-delivery-01': 'delivery, delivered',
    'safe-delivery-02': 'delivery, delivered',
    'safety-pin-01': 'safety-pin-01',
    'safety-pin-02': 'safety-pin-02',
    'sailboat-coastal': 'vessel, sail, sailboat, sailer',
    'sailboat-offshore': 'vessel, sail, sailboat, sailer',
    sakura: 'sakura',
    salah: 'salah',
    'salah-time': 'salah-time',
    'sale-tag-01': 'sale tag, offer, discount, tag',
    'sale-tag-02': 'sale tag, offer, discount, tag',
    sandals: 'sandals',
    satellite: 'satellite',
    'satellite-01':
        'orbiter, space probe, cosmic beacon, celestial tracker, orbit watcher, starcom, space sentinel, astral guide, galaxy eye, cosmos link',
    'satellite-02':
        'orbiter, space probe, cosmic beacon, celestial tracker, orbit watcher, starcom, space sentinel, astral guide, galaxy eye, cosmos link',
    'satellite-03': 'satellite-03',
    saturn: 'ringed planet, celestial ring, cosmic hoop, galactic loop, planetary halo, stellar orbit, space circlet, astral ring, universe wheel, nebula band, starry loop, orbital halo, infinity ring, saturn',
    'saturn-01':
        'ringed planet, cosmic circle, celestial hoop, orbiting world, galactic loop, space ring, planetary loop, stellar ring, astral loop, heavenly circle',
    'saturn-02':
        'ringed planet, cosmic circle, celestial hoop, orbiting world, galactic loop, space ring, planetary loop, stellar ring, astral loop, heavenly circle',
    sausage: 'sausage',
    'save-energy-01': 'save energy, energy',
    'save-energy-02': 'save energy, energy',
    'save-money-dollar': 'save-money-dollar',
    'save-money-euro': 'save-money-euro',
    'save-money-pound': 'save-money-pound',
    'save-money-yen': 'save-money-yen',
    savings: 'savings',
    scheme: 'scheme',
    school: 'school',
    'school-01': 'school-01',
    'school-bell-01': 'school-bell-01',
    'school-bell-02': 'school-bell-02',
    'school-bus':
        'school-bus icon bus-school, education-bus, yellow-bus, learning-transport, student-shuttle, academic-bus, kids-bus, classroom-transport, scholar-bus, education-shuttle',
    'school-report-card': 'school-report-card',
    'school-tie':
        'mentor, instructor, professor, academic, coach, facilitator, career, business career, teacher',
    scissor: 'scissor',
    'scissor-01': 'scissor-rectangle',
    'scooter-01':
        'scooter icon, kickboard, two-wheeler, motorized board, ride-on, push scooter, wheeled glide, urban mover, roll board, sidewalk cruiser, electric kick, swift glide',
    'scooter-02':
        'scooter icon, kickboard, two-wheeler, motorized board, ride-on, push scooter, wheeled glide, urban mover, roll board, sidewalk cruiser, electric kick, swift glide',
    'scooter-03':
        'scooter icon, kickboard, two-wheeler, motorized board, ride-on, push scooter, wheeled glide, urban mover, roll board, sidewalk cruiser, electric kick, swift glide',
    'scooter-04':
        'scooter icon, kickboard, two-wheeler, motorized board, ride-on, push scooter, wheeled glide, urban mover, roll board, sidewalk cruiser, electric kick, swift glide',
    'scooter-electric':
        'scooter-electric icon, e-scooter, electric scooter, motorized scooter, battery scooter, eco-scooter, electric ride, powered scooter, electronic scooter, plug-in scooter, electric-powered ride',
    'scratch-card': 'scratch-card',
    'screen-add-to-home': 'screen-add-to-home',
    'screen-lock-rotation': 'screen-lock-rotation',
    'screen-rotation': 'screen-rotation',
    scribd: 'scribd',
    scroll: 'scroll',
    'scroll-horizontal': 'scroll horizontal',
    'scroll-vertical': 'scroll vertical',
    'sd-card': 'sd-card',
    seal: 'seal',
    'search-01':
        'find, explore, lookup, hunt, scan, seek, scour, quest, hunt down, discover, probe, investigate, peruse, search',
    'search-02':
        'find, explore, lookup, hunt, scan, seek, scour, quest, hunt down, discover, probe, investigate, peruse',
    'search-add':
        'find-include, seek-insert, hunt-append, explore-attach, locate-add, discover-include, quest-attach, scan-add, probe-append, investigate-insert,zoom in',
    'search-area':
        'search-box, find-zone, query-space, exploration-zone, seek-area, lookup-zone, hunt-space, browse-region, discover-zone, exploration-area',
    'search-circle':
        'find-round, hunt-disk, explore-loop, quest-ring, locate-round, scan-cycle, seek-donut, pursuit-circular, lookup-ring, probe-circlet, scour-wheel, quest-hoop, examine-roundel, hunt-circlet, discover-doughnut',
    'search-dollar': 'search-dollar',
    'search-focus':
        'find-center, lookup-aim, seek-target, locate-point, hunt-precise, scan-pinpoint',
    searching:
        'find, explore, lookup, hunt, scan, seek, scour, quest, hunt down, discover, probe, investigate, peruse',
    'search-list-01':
        'find-view, lookup-menu, explore-options, query-directory, hunt-index, scan-catalog, seek-listing, inspect-collection, expedition-menu, browse-inventory',
    'search-list-02':
        'find-view, lookup-menu, explore-options, query-directory, hunt-index, scan-catalog, seek-listing, inspect-collection, expedition-menu, browse-inventory',
    'search-minus':
        'minus-search, search-remove, search-minus-symbol, search-decrease, search-less, search-minus-sign, zoom out',
    'search-remove':
        'search erase, remove search, clear search, delete search, search clear, erase query, remove query, search delete, clear query, query erase, erase search, search reset',
    'search-replace': 'search-replace',
    'search-square':
        'find-box, query-block, locate-frame, seek-square, explore-cell, lookup-corner, scan-box, hunt-panel',
    'search-visual':
        'find-view, seek-vision, locate-see, hunt-display, explore-observe, scan-sight, quest-image, lookup-visual, probe-vista, inspect-look',
    'seat-selector': 'seat-selector',
    'secured-network': 'secured-network',
    security: 'security',
    'security-block': 'security-block',
    'security-check': 'security-check',
    'security-key-usb': 'security-key-usb',
    'security-lock': 'security-lock',
    'security-password': 'security-password',
    'security-validation': 'security-validation',
    'security-wifi': 'security-wifi',
    segment: 'segment',
    'select-01': 'select-01',
    'select-02': 'select-02',
    'self-transfer': 'self-transfer',
    'semi-truck': 'semi-truck',
    'sending-order': 'sending-order',
    'send-to-mobile': 'send-to-mobile',
    senseless:
        'confused face, baffled expression, bewildered symbol, perplexed icon, unintelligible emoticon, disoriented image, puzzled sign, nonplussed graphic, dazed symbol, unclear face',
    sent: 'submit, send, share',
    'sent-02': 'sent-02',
    seo: 'marketing',
    'server-stack-01': 'server-stack-01',
    'server-stack-02': 'server-stack-02',
    'server-stack-03': 'server-stack-03',
    service: 'service',
    'setting-06':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'setting-07':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'setting-done-01':
        'settings-completed, settings-finished, configuration-finished, preferences-done, options-done, setup-done, adjustments-complete, configuration-checked, preferences-confirmed',
    'setting-done-02':
        'settings-completed, settings-finished, configuration-finished, preferences-done, options-done, setup-done, adjustments-complete, configuration-checked, preferences-confirmed',
    'setting-done-03':
        'settings-completed, settings-finished, configuration-finished, preferences-done, options-done, setup-done, adjustments-complete, configuration-checked, preferences-confirmed',
    'setting-done-04':
        'settings-completed, settings-finished, configuration-finished, preferences-done, options-done, setup-done, adjustments-complete, configuration-checked, preferences-confirmed',
    'setting-error-03':
        'warning-settings, configuration-issue, error-in-settings, settings-alert, problem-settings, settings-problem, error-configuration',
    'setting-error-04':
        'warning-settings, configuration-issue, error-in-settings, settings-alert, problem-settings, settings-problem, error-configuration',
    'settings-01':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'settings-02':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'settings-03':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'settings-04':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'settings-05':
        'preferences, options, configurations, tools, gear, adjustments, setup, controls, parameters, customizations, configurations, tweaks, properties, utilities, system',
    'settings-error-01':
        'warning-settings, configuration-issue, error-in-settings, settings-alert, problem-settings, settings-problem, error-configuration',
    'settings-error-02':
        'warning-settings, configuration-issue, error-in-settings, settings-alert, problem-settings, settings-problem, error-configuration',
    'setup-01':
        'configuration, preferences, settings, customization, options, setup wizard, control panel, system, setup menu, gear, tools, adjustments, tweak, setup assistant, setup guide',
    'setup-02':
        'configuration, preferences, settings, customization, options, setup wizard, control panel, system, setup menu, gear, tools, adjustments, tweak, setup assistant, setup guide',
    'shaka-01': 'shaka-01',
    'shaka-02': 'shaka-02',
    'shaka-03': 'shaka-03',
    'shaka-04': 'shaka-04',
    shampoo: 'shampoo',
    'shape-collection': 'shape-collection',
    shapes: 'shapes',
    'share-01':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-02':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-03':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-04':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-05':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-06':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-07':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'share-08':
        'share-icon, send, distribute, spread, forward, circulate, pass-on, transmit, exchange, propagate, disseminate, broadcast, convey, hand-over, impart, allocate',
    'shared-wifi': 'shared-wifi',
    'share-knowledge':
        'mentor, instructor, professor, academic, coach, facilitator, career, business career, teacher',
    'share-location-01': 'map, location, gps, navigate, location share, map share',
    'share-location-02': 'map, location, gps, navigate, location share, map share',
    sharing: 'sharing',
    shellfish: 'shellfish',
    'sheriff-01': 'sheriff-01',
    'sheriff-02': 'sheriff-02',
    'shield-01': 'shield-01',
    'shield-02': 'shield-02',
    'shield-blockchain': 'shield-blockchain',
    'shield-energy': 'shield-energy',
    'shield-key': 'shield-key',
    'shield-user': 'shield-user',
    'shipment-tracking': 'shipment tracking',
    'shipping-center':
        'shipping-center icon, delivery hub, transport depot, cargo station, shipping hub, distribution center, logistics hub, freight terminal, shipment hub',
    'shipping-loading': 'shipment loading, shipment',
    'shipping-truck-01': 'truck, transport, shipment, logistics, delivery, shipping',
    'shipping-truck-02': 'truck, transport, shipment, logistics, delivery, shipping',
    'shirt-01': 'shirt-01',
    shocked:
        'surprised face, astonished emoji, gasping emoticon, wide-eyed symbol, startled expression, awe-struck smiley, incredulous face, jaw-dropping icon',
    'shopping-bag-01': 'shopping bag, bag, purchase',
    'shopping-bag-02': 'shopping bag, bag, purchase',
    'shopping-bag-03': 'shopping bag, bag, purchase',
    'shopping-bag-add': 'shopping bag add, bag add, purchase add',
    'shopping-bag-check': 'shopping bag check, bag check, purchase check',
    'shopping-bag-favorite': 'shopping bag favorite, bag favorite, purchase favorite',
    'shopping-bag-remove': 'shopping bag remove, bag remove, purchase remove',
    'shopping-basket-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-03': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-add-01': 'shopping add, cart add, basket add, checkout add, add-to-cart add',
    'shopping-basket-add-02': 'shopping add, cart add, basket add, checkout add, add-to-cart add',
    'shopping-basket-add-03': 'shopping add, cart add, basket add, checkout add, add-to-cart add',
    'shopping-basket-check-in-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-check-in-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-check-in-03': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-check-out-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-check-out-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-check-out-03': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-done-01':
        'shopping done, cart done, basket done, checkout done, add-to-cart done',
    'shopping-basket-done-02':
        'shopping done, cart done, basket done, checkout done, add-to-cart done',
    'shopping-basket-done-03':
        'shopping check, cart check, basket check, checkout check, add-to-cart check',
    'shopping-basket-favorite-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-favorite-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-favorite-03': 'shopping-basket-favorite-03',
    'shopping-basket-remove-01':
        'shopping remove, cart remove, basket remove, checkout remove, add-to-cart remove',
    'shopping-basket-remove-02':
        'shopping remove, cart remove, basket remove, checkout remove, add-to-cart remove',
    'shopping-basket-remove-03':
        'shopping remove, cart remove, basket remove, checkout remove, add-to-cart remove',
    'shopping-basket-secure-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-secure-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-basket-secure-03': 'shopping safe, cart security, secured shopping',
    'shopping-cart-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-cart-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-cart-add-01': 'shopping add, cart add, basket add, checkout add, add-to-cart add',
    'shopping-cart-add-02': 'shopping add, cart add, basket add, checkout add, add-to-cart',
    'shopping-cart-check-01':
        'shopping check, cart check, basket check, checkout check, add-to-cart check',
    'shopping-cart-check-02':
        'shopping check, cart check, basket check, checkout check, add-to-cart check',
    'shopping-cart-check-in-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-cart-check-in-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-cart-check-out-01': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-cart-check-out-02': 'shopping, cart, basket, checkout, add-to-cart',
    'shopping-cart-favorite-01':
        'shopping favorite, cart favorite, favorite basket, checkout favorite, add-to-cart favorite',
    'shopping-cart-favorite-02':
        'shopping favorite, cart favorite, basket favorite, checkout favorite, add-to-cart favorite',
    'shopping-cart-remove-01':
        'shopping remove, cart remove, basket remove, checkout remove, add-to-cart',
    'shopping-cart-remove-02':
        'shopping remove, cart remove, basket remove, checkout remove, add-to-cart remove',
    'shop-sign': 'shop sign, sign',
    'shorts-pants': 'shorts-pants',
    shuffle:
        'box, swap-tiles, reorder-cells, shuffle, grid-shuffle, random-layout, tile-shuffle, box-rearrange',
    'shuffle-square':
        'mix-grid, swap-tiles, reorder-cells, square-shuffle, grid-shuffle, random-layout, tile-shuffle, box-rearrange, square-mix',
    'shut-down': 'shut-down',
    shutterstock: 'shutterstock',
    'sidebar-bottom':
        'side menu, navigation drawer, quick access bar, side toolbar, right sidebar, menu slider, side options, toggle menu, panel navigator, sidebar bttom, bottom sidebar, sidebar',
    'sidebar-left':
        'side menu, navigation drawer, quick access bar, side toolbar, right sidebar, menu slider, side options, toggle menu, panel navigator, sidebar left, left sidebar, sidebar',
    'sidebar-left-01': 'sidebar-left-01',
    'sidebar-right':
        'side menu, navigation drawer, quick access bar, side toolbar, right sidebar, menu slider, side options, toggle menu, panel navigator, sidebar right, right sidebar, sidebar',
    'sidebar-right-01': 'sidebar-right-01',
    'sidebar-top':
        'side menu, navigation drawer, quick access bar, side toolbar, right sidebar, menu slider, side options, toggle menu, panel navigator, sidebar top, top sidebar, sidebar',
    signal: 'signal',
    'signal-full-01': 'signal-full-01',
    'signal-full-02': 'signal-full-02',
    'signal-low-01': 'signal-low-01',
    'signal-low-02': 'signal-low-02',
    'signal-low-medium': 'signal-low-medium',
    'signal-medium-01': 'signal-medium-01',
    'signal-medium-02': 'signal-medium-02',
    'signal-no-01': 'signal-no-01',
    'signal-no-02': 'signal-no-02',
    signature:
        'autograph, endorsement, seal, mark, handwriting, approval, sign-off, inscription, symbol, certification, confirmation, stamp, manuscript, authentication, written confirmation, signature',
    'sign-language-c': 'sign-language-c',
    silence:
        'quiet face, hush symbol, soundless emoticon, serene expression, shush icon, tranquil emoji, mute face, peaceful symbol, calm emoticon, stillness icon',
    'simcard-01': 'simcard-01',
    'simcard-02': 'simcard-02',
    'simcard-dual': 'simcard-dual',
    sin: 'sin',
    'sine-01': 'sine-01',
    'sine-02': 'sine-02',
    'sing-left':
        'left-singing note, melody pointing left, vocal arrow left, music note leftward, crooning direction, tune left emoji, leftward song note, singing arrow left',
    'sing-right':
        'vocal tune, melody marker, harmony hint, croon cue, chorus charm, pitch pointer, serenade signal, tune tip, song sync, vocal guide',
    'sink-01': 'sink-01',
    'sink-02': 'sink-02',
    siri: 'voiceassist, talkmate, audioguide, speakup, soundhelper, vocalbuddy, chatterbot, audiowizard, talkback, voiceaid, speakeasy, audiocompanion',
    'siri-new': 'siri-new',
    sketch: 'sketch',
    skew: 'skew',
    ski: 'ski',
    'skipping-rope': 'skipping-rope',
    skull: 'skull',
    skype: 'skype',
    slack: 'slack',
    sleeping:
        'dozing face, snooze icon, slumber symbol, nap emoticon, restful glyph, bedtime emoji, drowsy face, peaceful sleeper',
    sleeveless: 'sleeveless',
    'sliders-horizontal':
        'horizontal sliders, slide bars, side-to-side adjusters, level adjusters, parallel sliders, flat sliders, bar controllers, width sliders, span adjusters, breadth sliders',
    'sliders-vertical':
        'vertical-sliders, up-down sliders, tall sliders, vertical adjusters, height sliders, portrait sliders, length adjusters, vertical controls, column sliders, upright sliders',
    slideshare: 'slideshare',
    'slow-winds': 'wind, air, breeze, slow wind',
    smart: 'intellimote, cleverglyph, brainyemote, geniussymbol, quickexpression, wiseicon, sharpemoticon, brightglyph, brainwaveemoji, savvysymbol',
    'smart-ac': 'smart-ac',
    'smart-phone-01':
        'ell phone, mobile device, handset, cellular device, smartphone, mobile phone, communication device, portable phone, wireless device, pocket phone, smart phone, phone, smartphone',
    'smart-phone-02':
        'ell phone, mobile device, handset, cellular device, smartphone, mobile phone, communication device, portable phone, wireless device, pocket phone, smart phone, phone, smartphone',
    'smart-phone-03':
        'ell phone, mobile device, handset, cellular device, smartphone, mobile phone, communication device, portable phone, wireless device, pocket phone, smart phone, phone, smartphone',
    'smart-phone-04':
        'ell phone, mobile device, handset, cellular device, smartphone, mobile phone, communication device, portable phone, wireless device, pocket phone, smart phone, phone, smartphone',
    'smart-phone-landscape': 'smart-phone-landscape',
    'smartphone-lost-wifi': 'smartphone-lost-wifi',
    'smartphone-wifi': 'smartphone-wifi',
    'smart-watch-01': 'smart-watch-01',
    'smart-watch-02':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock, smart watch, watch smart',
    'smart-watch-03':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock, smart watch, watch smart',
    'smart-watch-04':
        'timepiece, watch, timer, chronometer, hourglass, stopwatch, chronograph, timekeeper, dial, temporal gauge, clock, alarm clock, smart watch, watch smart',
    smile: 'happy face, joyful emoji, smiling symbol, cheerful emoticon, beaming icon, grinning smiley, radiant face, delighted emoji, positive emoji, laughing symbol',
    'smile-dizzy':
        'dizzy face, happy swirl, giggly whirl, joyful spin, laughter twirl, merry spiral, ecstatic circle, gleeful swirl, cheerful rotation, elated spin',
    'sms-code': 'sms-code',
    snail: 'snail',
    snapchat: 'snapchat',
    snow: 'snow, snowfall, frozen, winter, cold',
    'soda-can': 'soda-can',
    'sofa-01': 'sofa-01',
    'sofa-02': 'sofa-02',
    'sofa-03': 'sofa-03',
    'sofa-single': 'sofa-single',
    'soft-drink-01': 'soft-drink-01',
    'soft-drink-02': 'soft-drink-02',
    software: 'software',
    'software-license': 'software-license',
    'software-uninstall': 'software-uninstall',
    'soil-moisture-field': 'soil, soil moisture, field, plant, dampness',
    'soil-moisture-global': 'soil, soil moisture, field, plant, dampness',
    'soil-temperature-field': 'soil, soil moisture, field, plant, dampness, soil temperature',
    'soil-temperature-global': 'soil, soil moisture, field, plant, dampness',
    'solar-energy': 'solar energy, energy',
    'solar-panel-01': 'solar, solar panel, panel',
    'solar-panel-02': 'solar, solar pannel, pannel',
    'solar-panel-03': 'solar, solar panel, panel',
    'solar-panel-04': 'solar, solar panel, panel',
    'solar-panel-05': 'solar, solar panel, panel',
    'solar-power': 'solar, solar panel, panel',
    'solar-system':
        'sun-and-planets, space-galaxy, cosmic-orbit, celestial-assembly, stellar-arrangement, astral-system, planetary-sun, universe-array',
    'solar-system-01':
        'star-map, cosmic-orbit, sun-and-planets, celestial-chart, galaxy-view, astral-layout, planetary-array, space-constellation, stellar-diagram, universe-display',
    'solid-line-01': 'solid-line-01',
    'solid-line-02': 'solid-line-02',
    'sort-by-down-01': 'sort, sort by, sort by down',
    'sort-by-down-02': 'sort, sort by, sort by down',
    'sort-by-up-01': 'sort, sort by, sort by up',
    'sort-by-up-02': 'sort, sort by, sort by up',
    'sorting-01': 'sort, sort by, sorting',
    'sorting-02': 'sort, sort by, sorting',
    'sorting-03': 'sort, sort by, sorting',
    'sorting-04': 'sort, sort by, sorting',
    'sorting-05': 'sort, sort by, sorting',
    'sorting-1-9': 'sort, sort by, sorting, 1 to 9 sorting, sorting 1 9',
    'sorting-9-1': 'sort, sort by, sorting, 9 to 1 sorting',
    'sorting-a-z-01': 'sort, sort by, sorting, a to z sorting, sorting a z',
    'sorting-a-z-02': 'sort, sort by, sorting, a to z sorting, sorting a z',
    'sorting-down': 'sort, sort by, sorting down',
    'sorting-up': 'sort, sort by, sorting',
    'sorting-z-a-01': 'sort, sort by, sorting, a to z sorting, sorting az',
    soundcloud: 'soundcloud',
    'source-code': 'source-code',
    'source-code-circle': 'source-code-circle',
    'source-code-square': 'source-code-square',
    spaceship:
        'rocket, space shuttle, cosmic craft, stellar vessel, celestial flyer, astral transporter, galactic rocket, interstellar cruiser, starship, planetary explorer',
    spades: 'spades',
    spaghetti: 'spaghetti',
    spam: 'spam, barrier',
    sparkles: 'spark, stars',
    'spartan-helmet': 'spartan-helmet',
    spatula: 'spatula',
    speaker:
        'sound, audio, volume, amplifier, loudspeaker, audio device, sound symbol, speaker symbol, audio speaker, playback icon, sound icon, volume control, audio output, speaker symbol, speaker icon, speaker, mic',
    'speaker-01': 'sound, volume, speaker, audio',
    'speech-to-text': 'speech-to-text',
    'speed-train-01':
        'speed-train icon, rapid-rail, swift-express, fast-track, velocity-locomotive, quick-transit, high-speed-rail, turbo-train, accelerated-commute',
    'speed-train-02':
        'speed-train icon, rapid-rail, swift-express, fast-track, velocity-locomotive, quick-transit, high-speed-rail, turbo-train, accelerated-commute',
    sperm: 'sperm',
    sphere: 'sphere',
    spirals: 'spirals',
    spoon: 'spoon',
    'spoon-and-fork': 'spoon-and-fork',
    'spoon-and-knife': 'spoon-and-knife',
    spotify: 'spotify',
    sql: 'sql',
    square: 'square',
    'square-01': 'square-01',
    'square-arrow-data-transfer-diagonal': 'square-arrow-data-transfer-diagonal',
    'square-arrow-data-transfer-horizontal': 'square-arrow-data-transfer-horizontal',
    'square-arrow-data-transfer-vertical': 'square-arrow-data-transfer-vertical',
    'square-arrow-diagonal-01': 'big screen, full screen square, expand, enlarge square',
    'square-arrow-diagonal-02': 'big screen, full screen square, expand, enlarge square',
    'square-arrow-down-01': 'square-arrow-down-01',
    'square-arrow-down-02': 'square-arrow-down-02',
    'square-arrow-down-03': 'square-arrow-down-03',
    'square-arrow-down-double': 'chevron',
    'square-arrow-down-left': 'square-arrow-down-left',
    'square-arrow-down-right': 'square-arrow-down-right',
    'square-arrow-expand-01': 'big screen, full screen square, expand, enlarge square',
    'square-arrow-expand-02': 'big screen, full screen square, expand, enlarge square',
    'square-arrow-horizontal': 'big screen, full screen square, expand, enlarge square',
    'square-arrow-left-01': 'square-arrow-left-01',
    'square-arrow-left-02': 'square-arrow-left-02',
    'square-arrow-left-03': 'square-arrow-left-03',
    'square-arrow-left-double': 'chevron',
    'square-arrow-left-right': 'square-arrow-left-right',
    'square-arrow-move-down-left': 'square-arrow-move-down-left',
    'square-arrow-move-down-right': 'square-arrow-move-down-right',
    'square-arrow-move-left-down': 'square-arrow-move-left-down',
    'square-arrow-move-left-up': 'square-arrow-move-left-up',
    'square-arrow-move-right-down': 'square-arrow-move-right-down',
    'square-arrow-move-right-up': 'square-arrow-move-right-up',
    'square-arrow-reload-01': 'square-arrow-reload-01',
    'square-arrow-reload-02': 'square-arrow-reload-02',
    'square-arrow-right-01': 'square-arrow-right-01',
    'square-arrow-right-02': 'square-arrow-right-02',
    'square-arrow-right-03': 'square-arrow-right-03',
    'square-arrow-right-double': 'chevron',
    'square-arrow-shrink-01': 'small screen, collapse screen square, minimize square',
    'square-arrow-shrink-02': 'small screen, collapse screen square, minimize square',
    'square-arrow-up-01': 'square-arrow-up-01',
    'square-arrow-up-02': 'square-arrow-up-02',
    'square-arrow-up-03': 'square-arrow-up-03',
    'square-arrow-up-double': 'chevron',
    'square-arrow-up-down': 'square-arrow-up-down',
    'square-arrow-up-left': 'square-arrow-up-left',
    'square-arrow-up-right': 'square-arrow-up-right',
    'square-arrow-up-right-02': 'square-arrow-up-right-02',
    'square-arrow-vertical': 'big screen, full screen square, expand, enlarge square',
    'square-circle': 'square-circle',
    'square-lock-01': 'square-lock-01',
    'square-lock-02': 'square-lock-02',
    'square-lock-add-01': 'square-lock-add-01',
    'square-lock-add-02': 'square-lock-add-02',
    'square-lock-check-01': 'square-lock-check-01',
    'square-lock-check-02': 'square-lock-check-02',
    'square-lock-minus-01': 'square-lock-minus-01',
    'square-lock-minus-02': 'square-lock-minus-02',
    'square-lock-password': 'square-lock-password',
    'square-lock-remove-01': 'square-lock-remove-01',
    'square-lock-remove-02': 'square-lock-remove-02',
    'square-root-square': 'square-root-square',
    'square-square': 'square-square',
    'square-unlock-01': 'square-unlock-01',
    'square-unlock-02': 'square-unlock-02',
    'stack-star': 'stack-star',
    stake: 'stakeholder, bet, post, marker, peg, pole, wager, bettor, gamble, stick, stake',
    stamp: 'stamp',
    'stamp-01': 'legal',
    'stamp-02': 'legal',
    star: 'star, ratting, review, rate, favorite, half-star, half-rate, half-review',
    'star-circle': 'star-circle',
    'star-face':
        'starry smiley, celestial grin, galaxy glow, cosmic smile, stellar expression, twinkle face, astral grin, sparkling visage, nebula emoji, shining countenance, constellation smile',
    'star-half': 'half-star, half-rate, half-review',
    'star-off': 'star-off, slash-star, off star',
    stars: 'luminous, star',
    'star-square': 'star, review, rate, star square, rate sqaure, review square',
    'start-up-01': 'speed',
    'start-up-02': 'start-up-02',
    stationery: 'stationery',
    steak: 'steak',
    steering: 'steering',
    'step-into': 'step-into',
    'step-out': 'step-out',
    'step-over': 'step-over',
    stethoscope: 'stethoscope',
    'stethoscope-02': 'stethoscope-02',
    'sticky-note-01': 'sticky-note-01',
    'sticky-note-02': 'sticky-note-02',
    'sticky-note-03': 'sticky-note-03',
    stop: 'halt, cease, end, terminate, stop, abort, cancel, pause',
    'stop-circle': 'stop-circle',
    'stop-watch': 'stop-watch',
    'store-01': 'store, market, shop, marketplace',
    'store-02': 'store, market, shop, marketplace',
    'store-03': 'store, market, shop, marketplace',
    'store-04': 'store, market, shop, marketplace',
    'store-add-01': 'store add, market add, shop add, marketplace add',
    'store-add-02': 'store add, market add, shop add, marketplace add',
    'store-location-01': 'store location, market location, shop location, marketplace location',
    'store-location-02': 'store location, market location, shop location, marketplace location',
    'store-management-01': 'store-management-01',
    'store-management-02': 'store-management-02',
    'store-remove-01': 'store remove, market remove, shop remove, marketplace remove',
    'store-remove-02': 'store remove, market remove, shop remove, marketplace remove',
    'store-verified-01': 'store-verified-01',
    'store-verified-02': 'store-verified-02',
    'straight-edge': 'straight-edge',
    strategy: 'strategy',
    'streering-wheel': 'streering-wheel',
    'stroke-bottom': 'stroke-bottom',
    'stroke-center': 'stroke-center',
    'stroke-inside': 'stroke-inside',
    'stroke-left': 'stroke-left',
    'stroke-outside': 'stroke-outside',
    'stroke-right': 'stroke-right',
    'stroke-top': 'stroke-top',
    'structure-01': 'structure-01',
    'structure-02': 'structure-02',
    'structure-03': 'structure-03',
    'structure-04': 'structure-04',
    'structure-05': 'structure-05',
    'structure-add': 'structure-add',
    'structure-check': 'structure-check',
    'structure-fail': 'structure-fail',
    'structure-folder': 'structure-folder',
    'structure-folder-circle': 'structure-folder-circle',
    student: 'student',
    'student-card':
        'mentor, instructor, professor, academic, coach, facilitator, career, business career, teacher',
    students: 'students',
    'study-desk': 'study-desk',
    'study-lamp': 'study-lamp',
    stumbleupon: 'stumbleupon',
    submarine:
        'submarine icon, undersea vessel, sea explorer, ocean diver, aquatic craft, depth explorer, underwater ship, marine voyager, submersible, below-surface vessel, underwater explorer',
    submerge: 'immerse, plunge, dip, submerse, soak, saturate, dunk, douse, engulf, submergence',
    'subnode-add': 'subnode-add',
    'subnode-delete': 'subnode-delete',
    subpoena: 'legal',
    subtitle: 'caption, subtitle',
    'suit-01': 'suit-01',
    'suit-02': 'suit-02',
    sujood: 'sujood',
    'summation-01': 'summation-01',
    'summation-02': 'summation-02',
    'summation-circle': 'summation-circle',
    'summation-square': 'summation-square',
    'sun-01': 'sun, solar, daytime, shining',
    'sun-02': 'sun, solar, daytime, shining',
    'sun-03': 'sun, solar, daytime, shining',
    'sun-cloud-01': 'sun, solar, daytime, shining, suncloud',
    'sun-cloud-02': 'sun, solar, daytime, shining, sun cloud, cloudy',
    'sun-cloud-angled-rain-01': 'sun, solar, daytime, shining, sun cloud, rain',
    'sun-cloud-angled-rain-02': 'sun, solar, daytime, shining, sun cloud, cloudy, rain, cloud rain',
    'sun-cloud-angled-rain-zap-01': 'sun, solar, daytime, shining, sun cloud, rain, lighting',
    'sun-cloud-angled-rain-zap-02': 'sun, solar, daytime, shining, sun cloud, cloudy, lighting',
    'sun-cloud-angled-zap-01': 'sun, solar, daytime, shining, sun cloud, rain, lighting',
    'sun-cloud-angled-zap-02': 'sun, solar, daytime, shining, sun cloud, cloudy, lighting',
    'sun-cloud-big-rain-01': 'sun, solar, daytime, shining, sun cloud, rain',
    'sun-cloud-big-rain-02': 'sun, solar, daytime, shining, sun cloud, cloudy, rain',
    'sun-cloud-fast-wind-01': 'sun, solar, daytime, shining, sun cloud, wind',
    'sun-cloud-fast-wind-02': 'sun, solar, daytime, shining, sun cloud, cloudy, lighting',
    'sun-cloud-hailstone-01': 'sun, solar, daytime, shining, sun cloud, rain, snow',
    'sun-cloud-hailstone-02': 'sun, solar, daytime, shining, sun cloud, cloudy, snow',
    'sun-cloud-little-rain-01': 'sun, solar, daytime, shining, sun cloud, rain',
    'sun-cloud-little-rain-02': 'sun, solar, daytime, shining, sun cloud, cloudy, rain, cloud rain',
    'sun-cloud-little-snow-01': 'sun, solar, daytime, shining, sun cloud, rain, snow',
    'sun-cloud-little-snow-02': 'sun, solar, daytime, shining, sun cloud, cloudy, snow',
    'sun-cloud-mid-rain-01': 'sun, solar, daytime, shining, sun cloud, rain',
    'sun-cloud-mid-rain-02': 'sun, solar, daytime, shining, sun cloud, cloudy',
    'sun-cloud-mid-snow-01': 'sun, solar, daytime, shining, sun cloud, rain, snow',
    'sun-cloud-mid-snow-02': 'sun, solar, daytime, shining, sun cloud, cloudy, snow',
    'sun-cloud-slow-wind-01': 'sun, solar, daytime, shining, sun cloud, rain, snow',
    'sun-cloud-slow-wind-02': 'sun, solar, daytime, shining, sun cloud, cloudy, lighting',
    'sun-cloud-snow-01': 'sun, solar, daytime, shining, sun cloud, rain, snow',
    'sun-cloud-snow-02': 'sun, solar, daytime, shining, sun cloud, cloudy, snow',
    sunglasses:
        'shades, sunnies, cool glasses, dark glasses, sun shades, tinted specs, cool shades, eye shades, sunny specs, stylish shades, sunnies emoji',
    sunrise: 'sun, solar, daytime, shining, sunrise',
    sunset: 'sun, solar, daytime, shining, sunset',
    'super-mario': 'super-mario',
    'super-mario-toad': 'super-mario-toad',
    surfboard: 'surfboard',
    surprise:
        'wow face, shocked expression, amazement symbol, startled emoticon, unexpected reaction, astonished icon, wonderment emoji, dazzled face, jaw-drop emoticon, speechless symbol, impressed expression, stunning face, gobsmacked emoji, astounding emoticon, eureka symbol, mind-blown icon, stupefied face, incredible emoticon, bewildered expression, fascinated emoji',
    'sushi-01': 'sushi-01',
    'sushi-02': 'sushi-02',
    'sushi-03': 'sushi-03',
    suspicious:
        'side-eye emoji, doubtful emoji, skeptical emoji, wary emoji, distrustful emoji, uncertain emoji, questioning emoji, dubious emoji, cautious emoji, mistrustful emoji',
    'sustainable-energy': 'sustainable energy, energy',
    'svg-01': 'svg-01',
    'svg-02': 'svg-02',
    swarm: 'swarm',
    swatch: 'swatch',
    swimming: 'swimming',
    'swimming-cap': 'swimming-cap',
    'swipe-down-01': 'swipe-down-01',
    'swipe-down-02': 'swipe-down-02',
    'swipe-down-03': 'swipe-down-03',
    'swipe-down-04': 'swipe-down-04',
    'swipe-down-05': 'swipe-down-05',
    'swipe-down-06': 'swipe-down-06',
    'swipe-down-07': 'swipe-down-07',
    'swipe-down-08': 'swipe-down-08',
    'swipe-left-01': 'swipe-left-01',
    'swipe-left-02': 'swipe-left-02',
    'swipe-left-03': 'swipe-left-03',
    'swipe-left-04': 'swipe-left-04',
    'swipe-left-05': 'swipe-left-05',
    'swipe-left-06': 'swipe-left-06',
    'swipe-left-07': 'swipe-left-07',
    'swipe-left-08': 'swipe-left-08',
    'swipe-left-09': 'swipe-left-09',
    'swipe-right-01': 'swipe-right-01',
    'swipe-right-02': 'swipe-right-02',
    'swipe-right-03': 'swipe-right-03',
    'swipe-right-04': 'swipe-right-04',
    'swipe-right-05': 'swipe-right-05',
    'swipe-right-06': 'swipe-right-06',
    'swipe-right-07': 'swipe-right-07',
    'swipe-right-08': 'swipe-right-08',
    'swipe-right-09': 'swipe-right-09',
    'swipe-up-01': 'swipe-up-01',
    'swipe-up-02': 'swipe-up-02',
    'swipe-up-03': 'swipe-up-03',
    'swipe-up-04': 'swipe-up-04',
    'swipe-up-05': 'swipe-up-05',
    'swipe-up-06': 'swipe-up-06',
    'swipe-up-07': 'swipe-up-07',
    'swipe-up-08': 'swipe-up-08',
    'sword-01': 'sword-01',
    'sword-02': 'sword-02',
    'sword-03': 'sword-03',
    'system-update-01':
        'software-refresh, update-center, system-refresh, refresh-icon, software-update, system-refresh, update-icon',
    'system-update-02':
        'software-refresh, update-center, system-refresh, refresh-icon, software-update, system-refresh, update-icon',
    table: 'kanban',
    'table-01': 'table-01',
    'table-02': 'table-02',
    'table-03': 'table-03',
    'table-lamp-01': 'table-lamp-01',
    'table-lamp-02': 'table-lamp-02',
    'table-round': 'table-round',
    'tablet-01': 'tablet-01',
    'tablet-02': 'tablet-02',
    'tablet-connected-bluetooth': 'tablet-connected-bluetooth',
    'tablet-connected-usb': 'tablet-connected-usb',
    'tablet-connected-wifi': 'tablet-connected-wifi',
    'table-tennis-bat': 'table-tennis-bat',
    'tablet-pen': 'tablet-pen',
    'taco-01': 'taco-01',
    'taco-02': 'taco-02',
    'tag-01': 'tag-01',
    'tag-02': 'tag-02',
    tags: 'tag',
    'taj-mahal': 'taj-mahal',
    tan: 'tan',
    'tanker-truck': 'tanker-truck',
    'tank-top': 'tank-top',
    'tap-01': 'tap-01',
    'tap-02': 'tap-02',
    'tap-03': 'tap-03',
    'tap-04': 'tap-04',
    'tap-05': 'tap-05',
    'tap-06': 'tap-06',
    'tap-07': 'tap-07',
    'tap-08': 'tap-08',
    'tape-measure': 'tape-measure',
    'target-01': 'target-01',
    'target-02': 'marketing',
    'target-03': 'target-03',
    'target-dollar': 'target-dollar',
    tasbih: 'tasbih',
    'task-01': 'clipboard',
    'task-02': 'clipboard',
    'task-add-01': 'to do, clipboard',
    'task-add-02': 'to do, clipboard',
    'task-daily-01': 'to do, clipboard',
    'task-daily-02': 'to do, clipboard',
    'task-done-01': 'to do, clipboard',
    'task-done-02': 'to do, clipboard',
    'task-edit-01': 'to do, clipboard',
    'task-edit-02': 'to do, clipboard',
    'task-remove-01': 'to do, clipboard',
    'task-remove-02': 'to do, clipboard',
    taxes: 'taxes',
    taxi: 'taxi icon, cab, car service, ride-hail, transportation, shuttle, cabby, yellow cab, car hire, taxi-cab, chauffeur, taxicab, transit, city ride',
    'taxi-02': 'taxi-02',
    tea: 'tea',
    teacher:
        'mentor, instructor, professor, academic, coach, facilitator, career, business career, teacher',
    teaching:
        'mentor, instructor, professor, academic, coach, facilitator, career, business career, teacher',
    teamviewer: 'teamviewer',
    'tea-pod': 'tea-pod',
    telegram: 'telegram',
    telephone: 'telephone',
    'telescope-01': 'telescope-01',
    'telescope-02': 'telescope-02',
    'television-table': 'television-table',
    temperature: 'temperature, heat',
    'tennis-ball': 'tennis-ball',
    terrace: 'terrace',
    'test-tube':
        'chemistry flask, science vial, lab beaker, research tube, experiment flask, biochemistry vessel, analysis vial, chemical test tube, lab experiment tube, science sample vial',
    'test-tube-01':
        'chemical vial, science flask, lab cylinder, research beaker, experiment tube, solution container, biology vessel, chemistry pipette, medical ampoule, test tube',
    'test-tube-02':
        'chemical vial, science flask, lab cylinder, research beaker, experiment tube, solution container, biology vessel, chemistry pipette, medical ampoule, test tube',
    'test-tube-03':
        'chemical vial, science flask, lab cylinder, research beaker, experiment tube, solution container, biology vessel, chemistry pipette, medical ampoule, test tube',
    tetris: 'tetris',
    text: 'message, communication, dialogue, chat, conversation, words, typography, writing, script, speech, language, text',
    'text-align-center': 'text-align-center',
    'text-align-justify-center': 'text-align-justify-center',
    'text-align-justify-left': 'text-align-justify-left',
    'text-align-justify-right': 'text-align-justify-right',
    'text-align-left': 'text-align-left',
    'text-align-left-01': 'text-align-left-01',
    'text-align-right': 'text-align-right',
    'text-align-right-01': 'text-align-right-01',
    'text-all-caps': 'text-all-caps',
    'text-bold':
        'bold text, strong text, emphasized text, highlighted text, dark text, weighted text, intensified text, thickened text, enlarged text, empowered text, heavy text, solid text, accentuated text, prominent text, robust text, stressed text, impactful text, text bold',
    'text-centerline-center-top': 'text-centerline-center-top',
    'text-centerline-left': 'text-centerline-left',
    'text-centerline-middle': 'text-centerline-middle',
    'text-centerline-right': 'text-centerline-right',
    'text-check': 'text-check',
    'text-circle': 'text-circle',
    'text-clear': 'text-clear',
    'text-color': 'text-color',
    'text-creation': 'text-creation',
    'text-firstline-left': 'text-firstline-left',
    'text-firstline-right': 'text-firstline-right',
    'text-font':
        'font style, typography, character style, typeface, writing style, lettering, font format, text appearance, typeface selection, text design, font choice, writing format, text styling, font selection, text font',
    'text-footnote': 'text-footnote',
    'text-indent': 'text-indent',
    'text-indent-01': 'text-indent-01',
    'text-indent-less': 'text-indent-less',
    'text-indent-more': 'text-indent-more',
    'text-italic': 'text-italic',
    'text-italic-slash': 'text-italic-slash',
    'text-kerning': 'text-kerning',
    'text-number-sign': 'text-number-sign',
    'text-selection': 'text-selection',
    'text-smallcaps': 'text-smallcaps',
    'text-square': 'text-square',
    'text-strikethrough': 'text-strikethrough',
    'text-subscript': 'text-subscript',
    'text-superscript': 'text-superscript',
    'text-tracking': 'text-tracking',
    'text-underline': 'text-underline',
    'text-variable-front': 'text-variable-front',
    'text-vertical-alignment': 'text-vertical-alignment',
    'text-wrap': 'text-wrap',
    'the-prophets-mosque': 'the-prophets-mosque',
    thermometer: 'thermometer',
    'thermometer-cold': 'degree, cold, temperature, thermometer',
    'thermometer-warm': 'degree, warm, temperature, thermometer',
    thread: 'thread',
    threads: 'threads',
    'threads-ellipse': 'threads-ellipse',
    'threads-rectangle': 'threads-rectangle',
    'three-finger-01': 'three-finger-01',
    'three-finger-02': 'three-finger-02',
    'three-finger-03': 'three-finger-03',
    'three-finger-04': 'three-finger-04',
    'three-finger-05': 'three-finger-05',
    'thumbs-down': 'thumbs-down',
    'thumbs-down-ellipse': 'thumbs-down-ellipse',
    'thumbs-down-rectangle': 'thumbs-down-rectangle',
    'thumbs-up': 'thumbs-up',
    'thumbs-up-down': 'thumbs-up-down',
    'thumbs-up-ellipse': 'thumbs-up-ellipse',
    'thumbs-up-rectangle': 'thumbs-up-rectangle',
    'tick-01':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check',
    'tick-02':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check',
    'tick-03':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check',
    'tick-04':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check',
    'tick-double-01':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check, tick double',
    'tick-double-02':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check, tick double',
    'tick-double-03':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check, tick double',
    'tick-double-04':
        'checkmark, confirm, approve, validate, select, mark, okay, agree, endorse, accept, affirm, validate, pass, done, success, complete, enable, authorize, consent, tick, check, tick double',
    'ticket-01': 'ticket, entry, access, token',
    'ticket-02': 'ticket, entry, access, token',
    'ticket-03': 'ticket, entry, access, token',
    'ticket-star': 'ticket, entry, access, token',
    'tic-tac-toe': 'tic-tac-toe',
    tie: 'tie',
    tiktok: 'tiktok',
    tiltify: 'tiltify',
    'time-01':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time',
    'time-02':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time',
    'time-03':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time',
    'time-04':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time',
    'time-half-pass':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time, timer, time half pass',
    'time-management': 'time-management',
    'time-management-circle': 'time-management-circle',
    'time-quarter':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time, timer, time quarter',
    'time-quarter-02': 'time-quarter-02',
    'time-quarter-pass':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time, timer',
    'timer-01':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time, timer',
    'timer-02':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time, timer',
    'time-schedule':
        'clock, stopwatch, timer, chronometer, hourglass, watch, countdown, hour tracker, timepiece, temporal symbol, time indicator, temporal device, timekeeper, temporal gauge, time gauge, temporal meter, time, timer, time schedule, schedule time',
    'time-setting-01':
        'clock-setup, schedule-adjust, timer-config, chronometer-options, watch-settings, hour-setup, calendar-customize, stopwatch-settings',
    'time-setting-02':
        'clock-setup, schedule-adjust, timer-config, chronometer-options, watch-settings, hour-setup, calendar-customize, stopwatch-settings',
    'time-setting-03':
        'clock-setup, schedule-adjust, timer-config, chronometer-options, watch-settings, hour-setup, calendar-customize, stopwatch-settings',
    tips: 'tips',
    tire: 'tire',
    'tired-01':
        'exhausted face, sleepy icon, weary emoticon, fatigued symbol, drained emoji, drowsy face, worn-out symbol, lethargic icon, spent emoticon, sleepyhead emoji, and weary face',
    'tired-02':
        'exhausted face, sleepy icon, weary emoticon, fatigued symbol, drained emoji, drowsy face, worn-out symbol, lethargic icon, spent emoticon, sleepyhead emoji, and weary face',
    'tissue-paper': 'tissue-paper',
    'toggle-off':
        'switch-off, deactivate, disable, turn-off, unclick, power-down, inactive, shut-down',
    'toggle-on':
        'switch, activate, power, enable, on-off, turn on, activate switch, toggle active, start, engage',
    'toilet-01': 'toilet-01',
    'toilet-02': 'toilet-02',
    'token-circle': 'token-circle',
    'token-square': 'token-square',
    tongue: 'tongue',
    'tongue-01':
        'playful tongue, cheeky expression, waggish symbol, lighthearted tongue, teasing icon, jovial emoji, whimsical tongue, mischievous symbol, frolicsome expression, amusing icon, mirthful tongue, witty emoji',
    'tongue-wink-left':
        'winking tongue left, playful wink tongue, cheeky left wink, jovial tongue wink, mischievous lick left, humorous tongue wink, saucy left wink, playful licking wink, waggish tongue left',
    'tongue-wink-right':
        'winking tongue, cheeky wink, playful tongue, sassy wink, teasing tongue, mischievous wink, jovial tongue, coy wink, lively tongue, saucy wink',
    tools: 'tools',
    'tornado-01': 'tornado, cyclone, storm',
    'tornado-02': 'tornado, cyclone, storm',
    'torri-gate': 'torri-gate',
    'touch-01': 'touch-01',
    'touch-02': 'touch-02',
    'touch-03': 'touch-03',
    'touch-04': 'touch-04',
    'touch-05': 'touch-05',
    'touch-06': 'touch-06',
    'touch-07': 'touch-07',
    'touch-08': 'touch-08',
    'touch-09': 'touch-09',
    'touch-10': 'touch-10',
    'touch-interaction-01': 'touch-interaction-01',
    'touch-interaction-02': 'touch-interaction-02',
    'touch-interaction-03': 'touch-interaction-03',
    'touch-interaction-04': 'touch-interaction-04',
    'touch-locked-01': 'touch-locked-01',
    'touch-locked-02': 'touch-locked-02',
    'touch-locked-03': 'touch-locked-03',
    'touch-locked-04': 'touch-locked-04',
    'touchpad-01': 'touchpad-01',
    'touchpad-02': 'touchpad-02',
    'touchpad-03': 'touchpad-03',
    'touchpad-04': 'touchpad-04',
    towels: 'towels',
    'tow-truck':
        'tow-truck icon, towing vehicle, rescue truck, hauler, wrecker, tow rig, recovery van, tow transporter, roadside assistance, breakdown truck',
    'toy-train':
        'tractor icon, farm machine, plower, agri-vehicle, rural mover, field master, harvester, land rover, cultivator, agro-truck, tiller',
    'trade-down': 'price decrease, price down, down, level',
    'trade-mark': 'trade-mark',
    trademark: 'trademark',
    'trade-up': 'price increase, price up, high, level',
    'traffic-incident':
        'accident-alert, road-trouble, congestion-event, collision-warning, traffic-trouble',
    'traffic-jam-01': 'traffic-jam-01',
    'traffic-jam-02': 'traffic-jam-02',
    'traffic-light':
        'signal, stoplight, semaphore, intersection-light, road-light, crossroad-signal, traffic-signal',
    'train-01':
        'train icon, locomotive, railway, transit, express, railcar, choo-choo, metro, subway, tram, bullet train',
    'train-02':
        'train icon, locomotive, railway, transit, express, railcar, choo-choo, metro, subway, tram, bullet train',
    tram: 'tram icon, streetcar, trolley, light rail, cable car, tramcar, transit car, railway car, people mover, urban rail, electric tram',
    trampoline: 'trampoline',
    transaction: 'transaction',
    'transition-bottom': 'transition-bottom',
    'transition-left': 'transition-left',
    'transition-right': 'transition-right',
    'transition-top': 'transition-top',
    translate: 'translate',
    translation: 'translation',
    transmission: 'transmission',
    transparency: 'transparency',
    'trapezoid-line-horizontal': 'trapezoid-line-horizontal',
    'trapezoid-line-vertical': 'trapezoid-line-vertical',
    'travel-bag': 'travel-bag',
    'treadmill-01': 'treadmill-01',
    'treadmill-02': 'treadmill-02',
    treatment: 'treatment',
    'tree-01': 'tree-01',
    'tree-02': 'tree-02',
    'tree-03': 'tree-03',
    'tree-04': 'tree-04',
    'tree-05': 'tree-05',
    'tree-06': 'tree-06',
    'tree-07': 'tree-07',
    trello: 'trello',
    triangle: 'triangle',
    'triangle-01': 'pyramid, triangle, trigon',
    'triangle-02': 'pyramid, triangle, trigon',
    'triangle-03':
        'three-sided shape, delta, triangular symbol, pyramid, triad marker, trigon, triquetra, trihedral glyph, triangular sign, tri-point emblem, three-cornered icon',
    'trolley-01': 'trolley',
    'trolley-02': 'trolley, shopping bag, shopping cart',
    'tropical-storm': 'tornado, cyclone, storm',
    'tropical-storm-tracks-01': 'tornado, cyclone, storm',
    'tropical-storm-tracks-02': 'tornado, cyclone, storm',
    truck: 'delivery vehicle, transport, lorry, freighter, cargo carrier, moving van, hauler, goods transporter, box truck, logistics vehicle',
    'truck-delivery':
        'truck-delivery icon, delivery-truck, shipping-vehicle, transport-delivery, cargo-truck, logistics-icon, shipment-truck, freight-delivery, haulage-truck, dispatch-vehicle, distribution-truck',
    'truck-monster':
        'beastly-hauler, monster-truck, roaring-rig, colossal-hauler, fierce-mover, titan-transport, mega-monster, savage-hauler, giant-truck, monstrous-mover',
    'truck-return':
        'return truck, truck back, revert transport, vehicle return, return delivery, transport reversal, truck reversal, backtrack vehicle, reverse shipping, return cargo, revert haulage, bring back truck, recall transport, retrieve truck',
    trulli: 'trulli',
    't-shirt': 't-shirt',
    tsunami: 'tornado, cyclone, storm, tsunami, sunami',
    tulip: 'tulip',
    tumblr: 'tumblr',
    'turtle-neck': 'turtle-neck',
    'tv-01':
        'television, screen, monitor, display, viewing device, entertainment box, media screen, visual device, tv',
    'tv-02':
        'television, screen, monitor, display, viewing device, entertainment box, media screen, visual device, tv',
    'tv-fix':
        'television, screen, monitor, display, viewing device, entertainment box, media screen, visual device, tv, tv fix',
    'tv-issue':
        'television, screen, monitor, display, viewing device, entertainment box, media screen, visual device, tv, tv issue',
    'tv-smart':
        'television, screen, monitor, display, viewing device, entertainment box, media screen, visual device, tv, smart tv, tv smart',
    'twin-tower': 'twin-tower',
    twitch: 'twitch',
    twitter: 'twitter',
    'twitter-square': 'twitter-square',
    'two-factor-access': 'two-factor-access',
    'two-finger-01': 'two-finger-01',
    'two-finger-02': 'two-finger-02',
    'two-finger-03': 'two-finger-03',
    'two-finger-04': 'two-finger-04',
    'two-finger-05': 'two-finger-05',
    'txt-01': 'txt-01',
    'txt-02': 'txt-02',
    'type-cursor': 'type-cursor',
    'typescript-01': 'typescript-01',
    'typescript-02': 'typescript-02',
    'typescript-03': 'typescript-03',
    uber: 'uber',
    ufo: 'ufo icon, alien ship, flying saucer, extraterrestrial craft, space vessel, unidentified flying object, celestial shuttle, intergalactic pod, otherworldly cruiser, cosmic disc, galactic transport',
    'ufo-01':
        'flying saucer, alien craft, extraterrestrial ship, spaceship, unidentified flying object, celestial vessel, interstellar object, cosmic shuttle, otherworldly craft, galactic saucer',
    umbrella: 'umbrella',
    'umbrella-dollar': 'umbrella-dollar',
    unamused:
        'displeased face, serious emoji, frowning symbol, dismayed emoticon, unimpressed icon, pouty face, grimacing expression, stern emoji, dissatisfied symbol, grumpy emoticon, irritated face, dour emoticon, glowering icon',
    unavailable:
        'unavailable, crossed-out, off-limits, disabled, not accessible, out of service, restricted, inactive, off-duty, inoperable, non-operational, out of order, closed, off-limits, off the grid, unavailable, not reachable, off, out of commission, unable',
    'underpants-01': 'underpants-01',
    'underpants-02': 'underpants-02',
    'underpants-03': 'underpants-03',
    'unfold-less': 'chevron',
    'unfold-more': 'chevron',
    'ungroup-items': 'ungroup-items',
    'ungroup-layers': 'ungroup-layers',
    unhappy:
        'frowning face, sad emoticon, disappointed symbol, upset icon, gloomy smiley, melancholy face, unsettled expression, sorrowful emoji, dismal character, dejected symbol, morose icon, crestfallen emoticon, miserable face, distressed symbol',
    'universal-access': 'universal-access',
    'universal-access-circle': 'universal-access-circle',
    university: 'university',
    'unlink-01':
        'unlink-icon, detach, disconnect, separate, disjoin, unhook, sever, isolate, unbind, uncouple, unyoke, release',
    'unlink-02':
        'unlink-icon, detach, disconnect, separate, disjoin, unhook, sever, isolate, unbind, uncouple, unyoke, release',
    'unlink-03':
        'unlink-icon, detach, disconnect, separate, disjoin, unhook, sever, isolate, unbind, uncouple, unyoke, release',
    'unlink-04':
        'unlink-icon, detach, disconnect, separate, disjoin, unhook, sever, isolate, unbind, uncouple, unyoke, release',
    'unlink-05':
        'unlink-icon, detach, disconnect, separate, disjoin, unhook, sever, isolate, unbind, uncouple, unyoke, release',
    'unlink-06':
        'unlink-icon, detach, disconnect, separate, disjoin, unhook, sever, isolate, unbind, uncouple, unyoke, release',
    unsplash: 'unsplash',
    'upload-01':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader',
    'upload-02':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader',
    'upload-03':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader',
    'upload-04':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader',
    'upload-05':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader',
    'upload-circle-01':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader, upload circle',
    'upload-circle-02':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader, upload circle',
    'upload-square-01':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader, upload square',
    'upload-square-02':
        'send file, submit, transfer, load, dispatch, contribute, upload data, transmit, deliver, import, upload, uploader, upload square',
    upwork: 'upwork',
    usb: 'usb',
    'usb-bugs': 'usb-bugs',
    'usb-connected-01': 'usb-connected-01',
    'usb-connected-02': 'usb-connected-02',
    'usb-connected-03': 'usb-connected-03',
    'usb-error': 'usb-error',
    'usb-memory-01': 'usb-memory-01',
    'usb-memory-02': 'usb-memory-02',
    'usb-not-connected-01': 'usb-not-connected-01',
    'usb-not-connected-02': 'usb-not-connected-02',
    user: 'user, profile, avatar, member, account',
    'user-02': 'user-02',
    'user-03': 'user-03',
    'user-account': 'user, profile, avatar, member, account, user account',
    'user-add-01': 'user add, user, profile, avatar, member, account',
    'user-add-02': 'user add, user, profile, avatar, member, account',
    'user-arrow-left-right': 'user, profile, avatar, member, account, user right, user left',
    'user-block-01': 'user, user remove, profile, avatar, member, account, user block',
    'user-block-02': 'user, user remove, profile, avatar, member, account, user block',
    'user-check-01': 'user check, user, profile, avatar, member, account',
    'user-check-02': 'user check, user, profile, avatar, member, account',
    'user-circle':
        'user, profile, avatar, member, account, multiple user, group, user round, circle user',
    'user-circle-02': 'user-circle-02',
    'user-edit-01': 'user, user edit, profile, avatar, member, account',
    'user-full-view':
        'user, profile, avatar, member, account, multiple user, group, user full view',
    'user-group': 'user, profile, avatar, member, account, multiple user, group, team',
    'user-group-02': 'user-group-02',
    'user-group-03': 'user-group-03',
    'user-id-verification': 'verification, user, user verification',
    'user-list': 'user, profile, avatar, member, account, multiple user, group, user list',
    'user-lock-01': 'user lock, user, profile, avatar, member, account',
    'user-lock-02': 'user lock, user, profile, avatar, member, account',
    'user-love-01': 'user, user love, profile, avatar, member, account',
    'user-love-02': 'user, user love, profile, avatar, member, account',
    'user-minus-01': 'user minus, user, user remove, profile, avatar, member, account',
    'user-minus-02': 'user minus, user, user remove, profile, avatar, member, account',
    'user-multiple':
        'user, profile, avatar, member, account, multiple user, team, users, group, team, multiple users',
    'user-multiple-02': 'users, group, team, multiple users',
    'user-multiple-03': 'user-multiple-03',
    'user-question-01': 'user question, user, profile, avatar, member, account',
    'user-question-02': 'user question, user, profile, avatar, member, account',
    'user-remove-01': 'user minus, user, user remove, profile, avatar, member, account',
    'user-remove-02': 'user minus, user, user remove, profile, avatar, member, account',
    'user-roadside':
        'direction, location, gps, navigate, position tracker, location user, roadside',
    'user-search-01': 'user, user search, profile, avatar, member, account',
    'user-search-02': 'user, user search, profile, avatar, member, account',
    'user-settings-01': 'user, user settings, profile, avatar, member, account',
    'user-settings-02': 'user, user settings, profile, avatar, member, account',
    'user-sharing': 'user, profile, avatar, member, account, multiple user, group, user sharing',
    'user-shield-01': 'user, user shield, profile, avatar, member, account',
    'user-shield-02': 'user, user shield, profile, avatar, member, account',
    'user-square': 'user, profile, avatar, member, account, multiple user',
    'user-star-01': 'user, user star, profile, avatar, member, account',
    'user-star-02': 'user, user star, profile, avatar, member, account',
    'user-status': 'user, profile, avatar, member, account, multiple user, group, user status',
    'user-story': 'user, profile, avatar, member, account, multiple user, group, user story',
    'user-switch':
        'user, profile, avatar, member, account, multiple user, group, user switch, team',
    'user-unlock-01': 'user, user unlock, profile, avatar, member, account',
    'user-unlock-02': 'user, user unlock, profile, avatar, member, account',
    'uv-01': 'sun, solar, daytime, shining, uv',
    'uv-02': 'sun, solar, daytime, shining, uv',
    'uv-03': 'sun, solar, daytime, shining, uv',
    vaccine: 'vaccine',
    validation: 'validation',
    'validation-approval': 'validation-approval',
    van: 'van, transporter, delivery, cargo, minibus, shuttle, moving, commute, caravan, hauler, camper, wheels',
    variable: 'variable',
    'vegetarian-food': 'vegetarian-food',
    'vertical-resize': 'resize vertical',
    'vertical-scroll-point': 'vertical-scroll-point',
    vest: 'vest',
    'victory-finger-01': 'victory-finger-01',
    'victory-finger-02': 'victory-finger-02',
    'victory-finger-03': 'victory-finger-03',
    'video-01': 'video-01',
    'video-02': 'video-02',
    'video-console': 'video-console',
    'video-off': 'video-off',
    'video-replay': 'video-replay',
    view: 'eye, show, show password',
    'view-off': 'eye off, hide, hide password',
    'view-off-slash': 'eye off, hide, hide password',
    vimeo: 'vimeo',
    vine: 'vine',
    'vine-square': 'vine-square',
    'vintage-clock': 'vintage-clock',
    'virtual-reality-vr-01':
        'vr experience, immersive tech, simulated reality, 3d simulation, virtual world, cyber experience, digital realm, augmented environment, vr universe, simulated realm, alternate reality, virtual reality, virtual',
    'virtual-reality-vr-02':
        'vr experience, immersive tech, simulated reality, 3d simulation, virtual world, cyber experience, digital realm, augmented environment, vr universe, simulated realm, alternate reality, virtual reality, virtual',
    vision: 'vision',
    vk: 'vk',
    'vk-square': 'vk-square',
    voice: 'audio, sound, talk, speak, vocal, microphone, recorder, voice control, speech, talkback',
    'voice-id': 'voice-id',
    volleyball: 'volleyball',
    'volume-high': 'sound high, audio loud, volume max, high volume, volume up, high sound',
    'volume-low':
        'volume decrease, audio lower, volume reduction, audio quieter, volume less, volume low',
    'volume-minus':
        'sound down, volume decrease, audio lower, sound minus, volume reduction, audio quieter, volume less',
    'volume-mute-01': 'no volume, volume, sound, audio , mute',
    'volume-mute-02': 'no volume, sound, audio , mute',
    'volume-off': 'no volume, volume, sound, audio , mute, volume off',
    'volume-up': 'increase volume, sound, audio , mute, sound, volume high, volume plus',
    vomiting:
        'sick face, puke emoji, nauseated symbol, barfing icon, queasy emoticon, throwing up emoji, upset stomach symbol, vomit face, regurgitating icon, nauseous emoji',
    voucher: 'discount, voucher, promo, coupon, offer',
    vr: 'vr',
    'vr-glasses': 'vr-glasses',
    'vynil-01':
        'music disc, record, vinyl record, lp, sound disc, audio disk, vintage disc, vinyl album',
    'vynil-02':
        'vinyl record, lp, phonograph record, sound disc, audio disk, vintage disc, vinyl album, music',
    'vynil-03':
        'vinyl record, lp, phonograph record, sound disc, audio disk, vintage disc, vinyl album, music',
    waiter: 'waiter',
    waiters: 'waiters',
    'wallet-01': 'earning cash, earn',
    'wallet-02': 'fund wallet, funding money, earning cash, earn',
    'wallet-03': 'money bag, earning cash',
    'wallet-04': 'wallet-04',
    'wallet-05': 'wallet-05',
    'wallet-add-01': 'fund wallet, funding money, earning cash',
    'wallet-add-02': 'fund wallet, funding money, earning, earn cash',
    'wallet-done-01': 'wallet-done-01',
    'wallet-done-02': 'fund wallet, funding money',
    'wallet-not-found-01': 'wallet-not-found-01',
    'wallet-not-found-02': 'no wallet',
    'wallet-remove-01': 'wallet-remove-01',
    'wallet-remove-02': 'wallet-remove-02',
    'wall-lamp': 'wall-lamp',
    wanted: 'wanted',
    'wardrobe-01': 'wardrobe-01',
    'wardrobe-02': 'wardrobe-02',
    'wardrobe-03': 'wardrobe-03',
    'wardrobe-04': 'wardrobe-04',
    warehouse: 'warehouse',
    'washington-monument': 'washington-monument',
    waste: 'trash, restore',
    'waste-restore':
        'restore-bin, bin-restore, recycle-restore, trash-recover, bin-undo, archive-restore',
    'watch-01': 'watch-01',
    'watch-02': 'watch-02',
    'water-energy': 'water, water energy',
    'waterfall-down-01': 'report, overview, dashboard, bar, graph, chart',
    'waterfall-down-03': 'waterfall-down-03',
    'waterfall-up-01': 'report, overview, dashboard, bar, graph, chart',
    'waterfall-up-02': 'report, overview, dashboard, bar, graph, chart',
    watermelon: 'watermelon',
    'water-polo': 'water-polo',
    'water-pump': 'water, water pump',
    wattpad: 'wattpad',
    'wattpad-square': 'wattpad-square',
    'waving-hand-01': 'waving-hand-01',
    'waving-hand-02': 'waving-hand-02',
    waze: 'waze',
    'web-design-01': 'web-design-01',
    'web-design-02': 'web-design-02',
    webflow: 'webflow',
    'webflow-ellipse': 'webflow-ellipse',
    'webflow-rectangle': 'webflow-rectangle',
    webhook: 'webhook',
    'web-programming': 'web-programming',
    'web-protection': 'web-protection',
    'web-security': 'web-security',
    'web-validation': 'web-validation',
    wechat: 'wechat',
    wedding: 'wedding',
    'weight-scale': 'weight-scale',
    'weight-scale-01': 'weight-scale-01',
    wellness: 'wellness',
    whatsapp: 'whatsapp',
    'whatsapp-business': 'whatsapp-business',
    wheelchair: 'wheelchair',
    whisk: 'whisk',
    whistle: 'whistle',
    whiteboard: 'whiteboard',
    'wifi-01': 'wifi-01',
    'wifi-02': 'wifi-02',
    'wifi-circle': 'wifi-circle',
    'wifi-connected-01': 'wifi-connected-01',
    'wifi-connected-02': 'wifi-connected-02',
    'wifi-connected-03': 'wifi-connected-03',
    'wifi-disconnected-01': 'wifi-disconnected-01',
    'wifi-disconnected-02': 'wifi-disconnected-02',
    'wifi-disconnected-03': 'wifi-disconnected-03',
    'wifi-disconnected-04': 'wifi-disconnected-04',
    'wifi-error-01': 'wifi-error-01',
    'wifi-error-02': 'wifi-error-02',
    'wifi-full-signal': 'wifi-full-signal',
    'wifi-location': 'wifi-location',
    'wifi-lock': 'wifi-lock',
    'wifi-low-signal': 'wifi-low-signal',
    'wifi-medium-signal': 'wifi-medium-signal',
    'wifi-no-signal': 'wifi-no-signal',
    'wifi-off-01': 'wifi-off-01',
    'wifi-off-02': 'wifi-off-02',
    'wifi-square': 'wifi-square',
    'wifi-unlock': 'wifi-unlock',
    wikipedia: 'wikipedia',
    'windows-new': 'windows-new',
    'windows-old': 'windows-old',
    'wind-power': 'wind, fan, wind power',
    'wind-power-01': 'wind power, power',
    'wind-power-02': 'wind power, power, wind',
    'wind-power-03': 'wind power, power, wind',
    'wind-surf': 'wind-surf',
    'wind-turbine':
        'wind-turbine" icon: windmill, renewable energy, wind generator, breezy spin, eco turbine, air-powered, gusty wheel, sustainable spin, wind energy, airy propeller',
    wink: 'playful face, cheeky symbol, sly expression, flirtatious icon, mischievous emoji, knowing smiley, coy symbol, teasing emoticon, cunning face, suggestive wink, conspiratorial emoji, playful wink symbol',
    winking:
        'winky face, blinking emoji, smiley wink, playful emoji, flirty face, cheeky wink, mischievous smile, cute wink, eye wink emoji, grinning wink',
    wireless: 'wireless',
    'wireless-cloud-access': 'wireless-cloud-access',
    wise: 'wise',
    woman: 'woman',
    wordpress: 'wordpress',
    work: 'work',
    'work-alert': 'work-alert',
    'workflow-circle-01': 'workflow-circle-01',
    'workflow-circle-02': 'workflow-circle-02',
    'workflow-circle-03': 'workflow-circle-03',
    'workflow-circle-04': 'workflow-circle-04',
    'workflow-circle-05': 'workflow-circle-05',
    'workflow-circle-06': 'workflow-circle-06',
    'workflow-square-01': 'workflow-square-01',
    'workflow-square-02': 'workflow-square-02',
    'workflow-square-03': 'workflow-square-03',
    'workflow-square-04': 'workflow-square-04',
    'workflow-square-05': 'workflow-square-05',
    'workflow-square-06': 'workflow-square-06',
    'workflow-square-07': 'workflow-square-07',
    'workflow-square-08': 'workflow-square-08',
    'workflow-square-09': 'workflow-square-09',
    'workflow-square-10': 'workflow-square-10',
    'work-history': 'work-history',
    'workout-battle-ropes': 'workout-battle-ropes',
    'workout-gymnastics': 'workout-gymnastics',
    'workout-kicking': 'workout-kicking',
    'workout-run': 'workout-run',
    'workout-sport': 'workout-sport',
    'workout-squats': 'workout-squats',
    'workout-stretching': 'workout-stretching',
    'workout-warm-up': 'workout-warm-up',
    'work-update': 'work-update',
    worry: 'concerned face, anxious expression, nervous emoticon, distressed symbol, uneasy icon, apprehensive emoji, tensed face, worried emoticon, fretful symbol, anxieted expression, agitated icon',
    'wps-office': 'wps-office',
    'wps-office-rectangle': 'wps-office-rectangle',
    'wrench-01': 'tool, spanner, adjuster, fixer, implement, turner, key, hardware, twisty',
    'wrench-02': 'tool, spanner, adjuster, fixer, implement, turner, key, hardware, twisty',
    wudu: 'wudu',
    xing: 'xing',
    'xls-01': 'xls-01',
    'xls-02': 'xls-02',
    'xml-01': 'xml-01',
    'xml-02': 'xml-02',
    'x-ray': 'x-ray',
    'xsl-01': 'xsl-01',
    'xsl-02': 'xsl-02',
    'x-variable': 'x-variable',
    'x-variable-circle': 'x-variable-circle',
    'x-variable-square': 'x-variable-square',
    yelp: 'yelp',
    yen: 'yen',
    'yen-circle': 'yen-circle',
    'yen-receive': 'yen-receive',
    'yen-send': 'yen-send',
    'yen-square': 'yen-square',
    'yoga-01': 'yoga-01',
    'yoga-02': 'yoga-02',
    'yoga-03': 'yoga-03',
    'yoga-ball': 'yoga-ball',
    'yoga-mat': 'yoga-mat',
    yogurt: 'yogurt',
    youtube: 'youtube',
    yurt: 'yurt',
    zakat: 'zakat',
    zap: 'zap, lighting, speed, fast',
    zeppelin:
        'zeppelin, airship, blimp, dirigible, skyship, aerial cruiser, floating balloon, gasbag, cloud rider, helium craft, flying vessel',
    'zip-01': 'zip-01',
    'zip-02': 'zip-02',
    zoom: 'zoom',
    'zoom-circle': 'zoom-circle',
    'zoom-in-area':
        'zoom-in-area, icon: magnify-region, focus-zoom, enlarge-section, expand-view, area-zoom, pinpoint-zoom, detailed-focus, targeted-magnify, specific-zoom',
    'zoom-out-area':
        'zoom-out-area, reduce view, minimize area, shrink display, decrease scope, scale down zone, diminish region',
    'zoom-square': 'zoom-square',
    zsh: 'zsh',
    zzz: 'zzz',
}
