import React from 'react'

import { Box } from 'ui/components/Box'
import { theme } from 'ui/styling/Theme.css'

type ListViewFrameProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const ListViewFrame: React.FC<ListViewFrameProps> = React.memo(({ children, ...props }) => {
    return (
        <Box flex flexDirection="column" alignItems="flex-start" {...props}>
            <style>
                {`
            .stk-page {
                overflow: visible!important;
            }
            .stk-page.device-mobile {
                max-width: 100%;
            }
            .stk-block-loader__overlay {
                display: none;
            }
            .stk-content {
                background-color: ${theme.color.surface};
                padding: 0!important;
                overflow: visible!important;
            }
            .stk-content > div {
                padding: 0!important;
            }
            .powered-by {
                display: none;
            }
            `}
            </style>
            {children}
        </Box>
    )
})
