import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tooltip } from 'ui/components/Tooltip'

import { usePortalNavigationHeaderState } from './hooks/usePortalNavigationHeaderState'
import { PortalLogo } from './PortalLogo'

import { MainNavigationHeaderStyle } from './Navigation.css'

type PortalNavigationHeaderProps = {}

export const PortalNavigationHeader: React.FC<PortalNavigationHeaderProps> = () => {
    const { canEditPortalSettings, handleOpenPortalSettings, canCreateApp, handleCreateApp } =
        usePortalNavigationHeaderState()

    return (
        <Box
            flex
            center
            mb="xl"
            justifyContent="space-between"
            className={MainNavigationHeaderStyle}
        >
            <PortalLogo />
            {canEditPortalSettings && (
                <Box py="2xs" flex center gap="xs" noShrink>
                    {canEditPortalSettings && (
                        <Tooltip
                            asChild
                            side="bottom"
                            align="center"
                            content="Portal settings"
                            zIndex={201}
                        >
                            <Button
                                size="xs"
                                variant="ghost"
                                startIcon={{ name: 'Settings' }}
                                aria-label="Portal settings"
                                onClick={handleOpenPortalSettings}
                                noShrink
                            />
                        </Tooltip>
                    )}
                    {canCreateApp && (
                        <Tooltip
                            asChild
                            side="bottom"
                            align="center"
                            content="Create new app"
                            zIndex={201}
                        >
                            <Button
                                size="xs"
                                variant="ghost"
                                startIcon={{ name: 'Plus' }}
                                aria-label="Create new app"
                                onClick={handleCreateApp}
                                noShrink
                            />
                        </Tooltip>
                    )}
                </Box>
            )}
        </Box>
    )
}
