import React, { forwardRef, useImperativeHandle, useState } from 'react'

import styled from '@emotion/styled'

import { useSyncConnection } from 'data/hooks/dataConnections/useSyncConnection'
import useLDFlags from 'data/hooks/useLDFlags'
import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'
import { DC_TYPE_TO_INTEGRATION_ID } from 'features/DataConnections/constants'
import { DataConnectionSyncInfo } from 'features/DataConnections/DataConnectionsManager/DataConnectionSyncInfo'
import { calculateSyncState } from 'features/DataConnections/DataConnectionsManager/getDataConnectionsSyncInfo'
import ObjectLabel from 'features/utils/ObjectLabel'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

import { Flex } from 'v2/ui'
import { SearchBox } from 'v2/ui/components/SearchBox'
import { Layout, UserLock } from 'v2/ui/svgs'

import { Button } from 'ui/components/Button'
import { useToast } from 'ui/components/Toast'

import { useToolbarActions } from './Toolbar/useToolbarActions'
import { ExportDataToolbarButton } from './ExportDataToolbarButton'
import { ToolbarButton } from './ToolbarButton'
import { TrashButton } from './TrashButton'

export type ObjectDataGridToolbarProps = {
    object: ObjectDto
    /**
     * When true, we hide the current object label + icon
     */
    hideLabel?: boolean
    /**
     * If set, only show schema and data sync buttons along with syncing time labels (i.e. hide all advanced buttons)
     */
    showOnlySyncButtons?: boolean
    dataConnection: DataConnectionDto | null
    search?: string
    onSearch: (search?: string) => void
    showDataSourceInfo?: boolean
    onModalToggled: (isOpened: boolean) => void
    trashMode: boolean
    setTrashMode: (prevMode: boolean) => void
}

type SyncInfoAndButtonProps = {
    dataConnection: DataConnectionDto
}
const SyncInfoAndButton: React.FC<SyncInfoAndButtonProps> = ({ dataConnection }) => {
    const toast = useToast()

    const { mutateAsync: syncConnection } = useSyncConnection({
        onError: () => {
            toast({
                title: 'There was a problem triggering sync for the connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { isSyncInProgress } = calculateSyncState([dataConnection])

    return (
        <>
            <DataConnectionSyncInfo dataConnections={[dataConnection]} prefix="Synced " />
            {!isSyncInProgress && (
                <Button
                    variant="secondary"
                    size="2xs"
                    startIcon={{ name: 'RefreshCcw' }}
                    onClick={() =>
                        syncConnection({
                            nangoConnectionId: dataConnection.nango_connection_id ?? '',
                            integrationId: DC_TYPE_TO_INTEGRATION_ID[dataConnection.type],
                        })
                    }
                >
                    Force full re-sync
                </Button>
            )}
        </>
    )
}

export type ObjectDataGridToolbarHandle = {
    showFields: () => void
    showSettings: () => void
}

export const ObjectDataGridToolbar: React.VFC<
    React.RefAttributes<any> & ObjectDataGridToolbarProps
> = forwardRef<ObjectDataGridToolbarHandle, ObjectDataGridToolbarProps>(
    (
        {
            object,
            hideLabel,
            showOnlySyncButtons,
            search,
            onSearch,
            onModalToggled,
            trashMode,
            setTrashMode,
            dataConnection,
        },
        ref
    ) => {
        const {
            toolbarModals,
            showSettings,
            showFields,
            showPermissions,
            showActions,
            showLayouts,
            showImportData,
        } = useToolbarActions({ object, onModalToggled })

        useImperativeHandle(ref, () => ({ showSettings, showFields }))

        const { flags } = useLDFlags()

        const [showSearch, setShowSearch] = useState<boolean>(false)

        const isStackerTable = getIsStackerNativeObject(object)
        const isStackerUserTable = getIsStackerUserObject(object)

        const shouldShowTrashButton = isStackerTable && !isStackerUserTable

        if (isStackerTable && hideLabel && showOnlySyncButtons) {
            // in this case, nothing would be rendered - this early return prevents us rendering an empty div with padding
            return null
        }
        return (
            <ResponsiveWrapper wrap="nowrap" p={1} pr={2}>
                {!hideLabel && (
                    <ObjectLabel objectId={object?._sid} flexGrow={1} fontWeight="bold" ml={2} />
                )}

                <SearchBox
                    placeholder={`Find something in ${object.name} `}
                    search={search}
                    onSearch={onSearch}
                    ml={1}
                    mr={isStackerTable || showSearch ? 'auto' : undefined}
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                    dataTestIdPrefix="data-grid"
                />

                {/* Don't show this when search is open to avoid too much info getting compressed */}
                {!isStackerTable && !!dataConnection && !showSearch && (
                    <Flex flexDirection="row" mr="auto">
                        <SyncInfoAndButton dataConnection={dataConnection} />
                    </Flex>
                )}
                <>
                    <Flex flexDirection="column">
                        <Flex alignItems="center" wrap="nowrap" id="datagrid_buttonbar">
                            {!trashMode && (
                                <>
                                    <ToolbarButton
                                        icon="table"
                                        onClick={showFields}
                                        data-testid="datagrid.fields.button"
                                    >
                                        Fields
                                    </ToolbarButton>
                                    <ToolbarButton
                                        icon="cog"
                                        data-testid="datagrid.settings.button"
                                        onClick={showSettings}
                                    >
                                        Settings
                                    </ToolbarButton>
                                    <ToolbarButton
                                        svgIcon={<Layout />}
                                        data-testid="datagrid.layouts.button"
                                        onClick={showLayouts}
                                    >
                                        Layouts
                                    </ToolbarButton>
                                    <ToolbarButton
                                        icon="bolt"
                                        data-testid="datagrid.actions.button"
                                        onClick={showActions}
                                    >
                                        Actions
                                    </ToolbarButton>
                                    <ToolbarButton
                                        svgIcon={<UserLock />}
                                        onClick={showPermissions}
                                        data-testid="datagrid.permissions.button"
                                    >
                                        Permissions
                                    </ToolbarButton>
                                    {flags?.csvImporter && (
                                        <ToolbarButton
                                            icon="faFileCsv"
                                            data-testid="datagrid.actions.button"
                                            onClick={showImportData}
                                        >
                                            Import
                                        </ToolbarButton>
                                    )}

                                    <ExportDataToolbarButton objectId={object?._sid} />
                                </>
                            )}
                            {shouldShowTrashButton && (
                                <TrashButton trashMode={trashMode} setTrashMode={setTrashMode} />
                            )}
                        </Flex>
                    </Flex>
                    {toolbarModals}
                </>
            </ResponsiveWrapper>
        )
    }
)

const ResponsiveWrapper = styled(Flex)`
    justify-content: ${(props) =>
        !props.shouldShowSyncSchemaButtons ? 'flex-start' : 'space-between'};
    flex-direction: row;
    height: 44px;

    @media (max-width: 1200px) {
        flex-direction: ${(props) =>
            !props.shouldShowSyncSchemaButtons ? 'row' : 'column-reverse'};
        align-items: flex-start;
        height: unset;
    }
`
