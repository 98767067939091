import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tooltip } from 'ui/components/Tooltip'

import { useWorkspaceNavigationHeaderState } from './hooks/useWorkspaceNavigationHeaderState'
import { WorkspaceLogo } from './WorkspaceLogo'

type WorkspaceNavigationHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const WorkspaceNavigationHeader: React.FC<WorkspaceNavigationHeaderProps> = (props) => {
    const { isWorkspaceNavCollapsed, canEditWorkspaceSettings, handleOpenWorkspaceSettings } =
        useWorkspaceNavigationHeaderState()

    return (
        <Box
            flex
            center
            justifyContent={isWorkspaceNavCollapsed ? 'center' : 'space-between'}
            pt="l"
            pl={isWorkspaceNavCollapsed ? 's' : 'l'}
            pr={isWorkspaceNavCollapsed ? 's' : 's'}
            mb="xl"
            {...props}
        >
            <WorkspaceLogo />
            {!isWorkspaceNavCollapsed && canEditWorkspaceSettings && (
                <Tooltip
                    asChild
                    side="bottom"
                    align="center"
                    content="Workspace settings"
                    zIndex={201}
                >
                    <Button
                        size="xs"
                        variant="ghost"
                        startIcon={{ name: 'Settings' }}
                        aria-label="Workspace settings"
                        onClick={handleOpenWorkspaceSettings}
                    />
                </Tooltip>
            )}
        </Box>
    )
}
