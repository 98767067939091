import React, { useState } from 'react'

import styled from '@emotion/styled'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { DeleteSyncedObjConfirmationModal } from './DeleteSyncedObjConfirmationModal'

const ObjectRowContainer = styled.div`
    padding-top: ${theme.space.m};
    padding-bottom: ${theme.space.m};
    padding-left: ${theme.space.s};
    display: flex;
    justify-content: space-between;
    border-radius: ${theme.radius.s};

    &:hover {
        background-color: ${theme.color.surfaceStrong};
    }

    &:hover .object-buttons-container {
        opacity: 1;
    }
`

const ObjectButtonsContainer = styled.div`
    opacity: 0;
    padding-right: ${theme.space['6xl']};
    display: flex;
    align-items: center;
`

export type ObjectRowProps = {
    object: ObjectDto
}
export const ObjectRow: React.FC<ObjectRowProps> = ({ object }) => {
    const { showManageData } = useSlidingPane()
    const [isRemoveObjModalOpen, setIsRemoveObjModalOpen] = useState(false)
    return (
        <ObjectRowContainer>
            <Body weight="medium">{object.name}</Body>
            <ObjectButtonsContainer className="object-buttons-container">
                <Button
                    variant="secondary"
                    size="2xs"
                    startIcon={{ name: 'Sheet' }}
                    onClick={() => showManageData({ objectId: object._sid })}
                    style={{ marginRight: theme.space.xs }}
                >
                    Manage table
                </Button>
                <Button
                    variant="destructive"
                    size="2xs"
                    startIcon={{ name: 'Trash2' }}
                    onClick={() => setIsRemoveObjModalOpen(true)}
                ></Button>
            </ObjectButtonsContainer>

            <DeleteSyncedObjConfirmationModal
                isOpen={isRemoveObjModalOpen}
                onOpenChange={setIsRemoveObjModalOpen}
                object={object}
            />
        </ObjectRowContainer>
    )
}
