import React, { useContext } from 'react'
import { createPortal } from 'react-dom'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { useNavigationContext } from 'features/navigation/hooks/useNavigationContext'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { getDefaultedPageHeader } from 'features/views/ViewHeader/viewHeaderDefaults'
import { isHeaderEmpty } from 'features/views/ViewHeader/viewHeaderUtils'

import { NavMenuButtonDarkStyle } from 'v2/ui/components/Breadcrumbs.css'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { theme } from 'ui/styling/Theme.css'

import ViewHeaderDefault from './ViewHeaderDefault'
import ViewHeaderImage from './ViewHeaderImage'
import { ViewHeaderModular } from './ViewHeaderModular'

const StyledHeader = styled.header`
    position: relative;
`

type ViewHeaderProps = {
    viewLayoutContext?: ViewLayoutContext
    headerOptions: PageHeader | undefined
    renderInPlace?: boolean
    parentIsMounted: boolean
    onChange?: (patch: Partial<ListViewOptions>) => void
    canShowCollapsedNavTrigger?: boolean
}

const ViewHeader = ({
    viewLayoutContext,
    headerOptions,
    renderInPlace,
    parentIsMounted,
    onChange,
    canShowCollapsedNavTrigger = false,
}: ViewHeaderProps) => {
    const isEmpty = isHeaderEmpty(headerOptions)
    const { pageHeroHeader } = useContext(LayoutEditorContext)

    const { showCollapsedNavigationTrigger } = useNavigationContext()

    if (isEmpty || !parentIsMounted) return null

    const defaultedHeaderOptions = getDefaultedPageHeader(headerOptions)

    const output = (
        <StyledHeader className={STYLE_CLASSES.PAGE_HEADER} data-testId="view-header">
            {canShowCollapsedNavTrigger && showCollapsedNavigationTrigger && (
                <CollapsedNavigationTrigger
                    position="absolute"
                    left="l"
                    className={
                        defaultedHeaderOptions.type === 'default'
                            ? undefined
                            : NavMenuButtonDarkStyle
                    }
                    style={{
                        top: defaultedHeaderOptions.type === 'default' ? '-48px' : theme.space.l,
                        zIndex: 9,
                        color:
                            defaultedHeaderOptions.type === 'default'
                                ? undefined
                                : theme.color.iconInverse,
                    }}
                />
            )}
            {defaultedHeaderOptions.type === 'default' && (
                <ViewHeaderDefault headerOptions={defaultedHeaderOptions} />
            )}
            {defaultedHeaderOptions.type === 'image' && (
                <ViewHeaderImage headerOptions={defaultedHeaderOptions} />
            )}
            {defaultedHeaderOptions.type === 'modular' && (
                <ViewHeaderModular
                    viewLayoutContext={viewLayoutContext!}
                    headerOptions={defaultedHeaderOptions}
                    onChange={(patch) => onChange?.(patch)}
                />
            )}
        </StyledHeader>
    )

    if (renderInPlace) return output

    if (!pageHeroHeader) return null
    return createPortal(output, pageHeroHeader)
}

export default ViewHeader
