/**
 * Calculate the sync state and last sync time for given data connections.
 * More than one data connection can be provided, in which case the aggregate sync state is returned
 *
 * @param dataConnections Array of DataConnectionDto to calculate sync info for
 * @returns Object containing whether sync is in progress and the latest sync time
 */
export const calculateSyncState = (dataConnections: DataConnectionDto[]) => {
    const STATUS_PRIORITY: Record<DataConnectionDto['status'], number> = {
        SYNC_NOT_STARTED: 1,
        NANGO_STARTED: 2,
        NANGO_COMPLETED: 3,
        SYNCING_SCHEMA: 4,
        SCHEMA_SYNC_COMPLETED: 5,
    }

    let maxLastSyncTime: Date | null = null
    let earliestStatus: DataConnectionDto['status'] = 'SCHEMA_SYNC_COMPLETED'

    for (const dataConnection of dataConnections) {
        if (STATUS_PRIORITY[dataConnection.status] < STATUS_PRIORITY[earliestStatus]) {
            earliestStatus = dataConnection.status
        }
        if (
            dataConnection.status === 'SCHEMA_SYNC_COMPLETED' &&
            dataConnection.last_sync_completed_time
        ) {
            const dcLastSyncTime = new Date(dataConnection.last_sync_completed_time)
            if (maxLastSyncTime === null || dcLastSyncTime > maxLastSyncTime) {
                maxLastSyncTime = dcLastSyncTime
            }
        }
    }

    return {
        isSyncInProgress: earliestStatus !== 'SCHEMA_SYNC_COMPLETED',
        earliestStatus,
        lastSyncTime: maxLastSyncTime,
    }
}
