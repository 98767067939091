import React, { useRef, useState } from 'react'
// @ts-ignore
import ContentEditable from 'react-sane-contenteditable'

import { JSONContent } from '@tiptap/react'
// @ts-ignore
import { debounce } from 'lodash-es'

import { useAppContext } from 'app/useAppContext'
import { SingleDocumentResponse, useUpdateDocument } from 'data/hooks/documents'
import { FavoriteType } from 'data/hooks/favorites/types'
import { DocumentCommentFeed } from 'features/documents/DocumentCommentFeed'
import { DocumentCommentFeedButton } from 'features/documents/DocumentCommentFeedButton'
import { useDocumentCommentFeedCount } from 'features/documents/useDocumentCommentFeedCount'
import { FavoriteButton } from 'features/favorites/FavoriteButton'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'
import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'
import { useTipTapCollabToken } from 'features/tiptap/useTipTapCollabToken'
import { useTipTapDocumentContent } from 'features/tiptap/useTipTapDocumentContent'

import { LoadingSplash } from 'v2/ui'

import { Box } from 'ui/components/Box'

import { DocumentTitleStyle } from './DocumentEditor.css'

type DocumentEditorProps = SingleDocumentResponse

export function DocumentEditor({
    document: doc,
    records: dereferencedRecords,
    documents,
}: DocumentEditorProps) {
    const favoritesEnabled = useFavoritesEnabled()
    const { workspaceAccount } = useAppContext()
    const { mutateAsync: updateDocument } = useUpdateDocument(doc.auto_id)

    const { token, isLoading: isTokenLoading } = useTipTapCollabToken()

    const [documentControlsContainer, setDocumentControlsContainer] =
        useState<HTMLDivElement | null>(null)
    const onChangeRef = useRef()
    onChangeRef.current = debounce(
        (
            content: JSONContent | null,
            plainTextContent: string = '',
            references: DocumentDto['references']
        ) => {
            updateDocument({
                ...doc,
                content: content,
                searchable_content: plainTextContent,
                format: 'tiptap',
                references,
            })
        },
        1000
    )

    const [showCommentFeed, setShowCommentFeed] = useState(false)

    const toggleCommentFeed = () => {
        setShowCommentFeed((prev) => !prev)
    }

    const onCommentFeedButtonClick = (e: React.MouseEvent) => {
        toggleCommentFeed()
        e.preventDefault()
        e.stopPropagation()
    }

    const commentCount = useDocumentCommentFeedCount({
        document: doc,
    })

    const onChangeTitle = (title: string) => {
        updateDocument({ ...doc, title })
    }
    let content = doc?.content

    const editorRef = useRef<TipTapEditorHandle>(null)
    const tipTapContent = useTipTapDocumentContent({
        content,
        format: doc?.format,
    })
    const isLoading = isTokenLoading
    return (
        <>
            {isLoading && <LoadingSplash />}
            {!!doc && !isLoading && (
                <Box display="flex" height="full" width="full" overflowY="auto">
                    <Box grow flex column py="xl" height="fit-content" maxWidth="full">
                        <Box flex alignItems="flex-start" mb="l">
                            <DocHeading title={doc.title} onChange={onChangeTitle}></DocHeading>
                            {favoritesEnabled && (
                                <FavoriteButton
                                    targetType={FavoriteType.Document}
                                    stack_id={doc.stack_id}
                                    document_id={doc.auto_id}
                                    size="2xl"
                                    alignSelf="center"
                                    ml="xl"
                                />
                            )}

                            <Box py="m" px="l" grow flex center justifyContent="flex-end" gap="xs">
                                <Box ref={setDocumentControlsContainer} />
                                <DocumentCommentFeedButton
                                    onClick={onCommentFeedButtonClick}
                                    isExpanded={showCommentFeed}
                                    count={commentCount}
                                />
                            </Box>
                        </Box>
                        <TipTapDocumentEditor
                            ref={editorRef}
                            content={tipTapContent}
                            onChange={onChangeRef.current}
                            collaborationId={`${workspaceAccount?._sid}_${doc.auto_id}`}
                            collaborationToken={
                                window.location.search.includes('no_collab') ||
                                localStorage.getItem('no_collab')
                                    ? undefined
                                    : token
                            }
                            px="5xl"
                            height="full"
                            placeholder="Press '/' for commands or just start typing..."
                            dereferencedRecords={dereferencedRecords}
                            dereferencedDocuments={documents}
                            documentId={doc.auto_id}
                            parentRecordId={doc.parent_record_id}
                            documentControlsContainer={documentControlsContainer}
                        />
                    </Box>
                    <DocumentCommentFeed
                        position="sticky"
                        top={0}
                        isExpanded={showCommentFeed}
                        document={doc}
                        onClose={toggleCommentFeed}
                        flexShrink={0}
                    />
                </Box>
            )}
        </>
    )
}

type DocHeadingProps = {
    title: string
    onChange: (title: string) => void
}

const DocHeading: React.FC<DocHeadingProps> = ({ title, onChange }) => {
    const [statefulTitle, setTitle] = useState(title)
    return (
        <ContentEditable
            content={statefulTitle}
            editable={true}
            className={DocumentTitleStyle}
            onBlur={() => onChange(statefulTitle)}
            onChange={(_: unknown, value: string) => {
                setTitle(value)
            }}
        />
    )
}
