import { useCallback, useMemo, useState } from 'react'

import type { DismissableLayerProps } from '@radix-ui/react-dismissable-layer'

import settings from 'app/settings'
import { isPortalRole } from 'features/auth/utils/roleUtils'
import { NavigationStyles } from 'features/navigation/Navigation.css'
import { openProfileSettingsModal } from 'features/profile/openProfileSettingsModal'
import { useCreateWorkspaceModal } from 'features/workspace/useCreateWorkspaceModal'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useNavigationContext } from './useNavigationContext'

type Workspace = {
    id: string
    name: string
    url: string
    account: Account
}

type UseNavigationCurrentUserDropdownStateProps = {
    isCollapsed?: boolean
}

export function useNavigationCurrentUserDropdownState({
    isCollapsed,
}: UseNavigationCurrentUserDropdownStateProps) {
    const {
        tryCollapseWorkspaceNav,
        currentUser,
        workspaceAccounts,
        workspaceAccountRole,
        workspaceAccount: currentWorkspace,
        permissions,
    } = useNavigationContext()

    const { fullName, firstName, lastName, avatarUrl, email } = currentUser

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const onInteractOutside: DismissableLayerProps['onInteractOutside'] = useCallback(
        (e) => {
            const isNavigationChild = e.target.closest(`.${NavigationStyles.styleFunction()}`)
            if (!isNavigationChild) {
                tryCollapseWorkspaceNav()
            }
        },
        [tryCollapseWorkspaceNav]
    )

    const onMouseEnter = useCallback(() => {
        if (!document.hasFocus()) return

        if (!isCollapsed) {
            setIsDropdownOpen(true)
        }
    }, [isCollapsed, setIsDropdownOpen])

    const onOpenAccountSettings = useCallback(() => {
        openProfileSettingsModal()
    }, [])

    const hasInternalZoneAccess = permissions.canViewInternalZone

    const showWorkspaces = hasInternalZoneAccess && !!workspaceAccountRole
    const canCreateWorkspaces = !!workspaceAccountRole && !isPortalRole(workspaceAccountRole)

    const { show: createWorkspace } = useCreateWorkspaceModal()

    const currentWorkspaceName = currentWorkspace?.name ?? 'None'
    const currentWorkspaceSid = currentWorkspace?._sid

    const workspaces = useDeepEqualsMemoValue(workspaceAccounts.map(makeWorkspaceFromAccount))

    const showAccountSettings = hasInternalZoneAccess

    return useMemo(
        () => ({
            fullName,
            firstName,
            lastName,
            type: !!avatarUrl ? ('image' as const) : ('initial' as const),
            imageUrl: avatarUrl,
            email,
            isDropdownOpen,
            setIsDropdownOpen,
            onInteractOutside,
            onMouseEnter,
            onOpenAccountSettings,
            canCreateWorkspaces,
            showWorkspaces,
            createWorkspace,
            workspaceAccounts,
            currentWorkspaceName,
            currentWorkspaceSid,
            workspaces,
            showAccountSettings,
            hasInternalZoneAccess,
        }),
        [
            fullName,
            firstName,
            lastName,
            avatarUrl,
            email,
            isDropdownOpen,
            onInteractOutside,
            onMouseEnter,
            onOpenAccountSettings,
            canCreateWorkspaces,
            showWorkspaces,
            createWorkspace,
            workspaceAccounts,
            currentWorkspaceName,
            currentWorkspaceSid,
            workspaces,
            showAccountSettings,
            hasInternalZoneAccess,
        ]
    )
}

function makeWorkspaceFromAccount(account: Account): Workspace {
    let url = `${settings.STUDIO_URL}/${account.slug}`
    // If SSO is required, we serve this workspace at either the custom URL
    // or the base URL (subdomain on the studio domain)
    if (account.sso_required) {
        if (account.custom_base_url) {
            url = `https://${account.custom_base_url}`
        } else {
            url = `https://${account.base_url}`
        }
    }

    return {
        id: account._sid,
        name: account.name,
        url,
        account,
    }
}
