import React from 'react'

import { ContextGroup } from 'features/expressions/types'
import { Highlighter } from 'features/Search/Highlighter'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

export function RenderContextGroup({
    item,
    queryTerms,
    renderItem,
}: {
    item: ContextGroup
    queryTerms?: string[]
    renderItem?: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
}) {
    const customContent = renderItem?.({ item, queryTerms })
    return (
        <Box flex center maxWidth="full" width="full" color="text" tabIndex={-1}>
            {customContent ? (
                customContent
            ) : (
                <>
                    <Box flex column grow shrink>
                        <Box
                            fontWeight="bodyBold"
                            mr="l"
                            trim
                            grow
                            style={{
                                marginTop: '-2px',
                                paddingTop: '2px',
                                marginBottom: '-2px',
                                paddingBottom: '2px',
                            }}
                        >
                            <Highlighter terms={queryTerms || []}>{item.name}</Highlighter>
                        </Box>
                    </Box>
                </>
            )}
            <Icon name="ChevronRight" size="s" ml="m" color="textWeakest" />
        </Box>
    )
}
