import React from 'react'

import { useExternalDatabases } from 'data/hooks/dataConnections/useExternalDatabases'
import { INTEGRATION_ID_TO_NAME, TRANSLATIONS } from 'features/DataConnections/constants'

import { DropdownFooterItem } from 'ui/components/Dropdown/DropdownFooterItem'
import { Select, SelectOption } from 'ui/components/Select'

export type SelectedDatabaseType = {
    id: string
    name: string
}

type ExternalDatabaseSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    nangoConnectionId: string
    onAddExternalDatabase: () => void
    isDisabled: boolean
    isLoading?: boolean
    selectedDatabase: SelectedDatabaseType
    onSelectedDatabase: (newState: SelectedDatabaseType) => void
}
// This component is used to select which specific "database" user wants to import form external source (e.g. in
// airtable case a base is a database)
export const ExternalDatabaseSelect: React.FC<ExternalDatabaseSelectProps> = ({
    externalIntegrationId,
    nangoConnectionId,
    selectedDatabase,
    onSelectedDatabase,
    onAddExternalDatabase,
    isDisabled,
    isLoading,
    contentProps,
    ...props
}) => {
    const { data: externalDatabases, isLoading: isLoadingExtDbs } = useExternalDatabases(
        { integrationId: externalIntegrationId, nangoConnectionId },
        {
            enabled: !!nangoConnectionId && !!externalIntegrationId,
            // if a different account is selected we want to go into a loading state and stop showing stale data immediately
            keepPreviousData: false,
        }
    )

    const handleDatabaseChange = (databaseId: string) => {
        const database = externalDatabases?.find((db) => db.id === databaseId)
        onSelectedDatabase({
            id: databaseId,
            name: database ? database.name : '',
        })
    }

    return (
        <Select
            placeholder={`Select ${TRANSLATIONS[externalIntegrationId].database} to sync`}
            onChange={handleDatabaseChange}
            value={selectedDatabase.id}
            {...props}
            disabled={isDisabled}
            isLoading={isLoading || isLoadingExtDbs}
            contentProps={{
                ...contentProps,
                footer: (
                    <DropdownFooterItem
                        label={`Add ${INTEGRATION_ID_TO_NAME[externalIntegrationId]} ${TRANSLATIONS[externalIntegrationId].database}`}
                        startIcon={{ name: 'Plus' }}
                        onClick={onAddExternalDatabase}
                        variant="action"
                    />
                ),
            }}
        >
            {externalDatabases?.map((extDatabase) => (
                <SelectOption
                    key={extDatabase.id}
                    label={extDatabase.name}
                    startIcon={{ name: 'Database' }}
                    value={extDatabase.id}
                />
            ))}
        </Select>
    )
}
