import React from 'react'

import { CreateRoleModal } from 'features/users/app-users-modal/TableGrants/CreateRoleModal'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { DropdownSeparator } from 'ui/components/Dropdown'
import { DropdownFooterItem } from 'ui/components/Dropdown/DropdownFooterItem'
import { Input } from 'ui/components/Input'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
    ModalParts,
} from 'ui/components/Modal'
import {
    Popup,
    PopupCloseTrigger,
    PopupContent,
    PopupFooter,
    PopupInner,
    PopupTitle,
    PopupTrigger,
} from 'ui/components/Popup'
import { Select, SelectOption, SelectOptionComplex, SelectSeparator } from 'ui/components/Select'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useAppUsersAddUsersButtonState } from './hooks/useAppUsersAddUsersButtonState'
import { useAppUsersAddUsersRoleSelectState } from './hooks/useAppUsersAddUsersRoleSelectState'
import { useAppUsersAddUsersUserSelectState } from './hooks/useAppUsersAddUsersUserSelectState'
import {
    InviteUserModalHandle,
    useAppUsersInviteUserModalState,
} from './hooks/useAppUsersInviteUserModalState'
import { AppRole } from './types'

import { AppUsersInviteAdminOptionStyle } from './AppUsers.css'

type AppUsersAddUsersButtonProps = {
    roles: AppRole[]
}

export const AppUsersAddUsersButton: React.FC<AppUsersAddUsersButtonProps> = ({ roles }) => {
    const {
        isSubmitEnabled,
        selectedRole,
        setSelectedRole,
        onOpenChange,
        selectedUser,
        setSelectedUser,
        onSubmit,
        isOpen,
        onClosePopup,
        inviteUserModalRef,
        openInviteUsersModal,
        isRoleModalOpen,
        setIsRoleModalOpen,
        onRoleCreated,
        onAddRole,
    } = useAppUsersAddUsersButtonState()

    return (
        <>
            <Popup open={isOpen} onOpenChange={onOpenChange}>
                <PopupTrigger asChild>
                    <Button size="s" variant="primary" startIcon={{ name: 'Plus' }}>
                        Add users
                    </Button>
                </PopupTrigger>
                <PopupContent align="end">
                    <PopupTitle>Add users</PopupTitle>
                    <PopupInner flex center gap="m">
                        <Box width="66%" noShrink>
                            <UserSelect
                                roles={roles}
                                value={selectedUser}
                                onClosePopup={onClosePopup}
                                onChange={setSelectedUser}
                                openInviteUsersModal={openInviteUsersModal}
                            />
                        </Box>
                        <Box grow>
                            <RoleSelect
                                modal={false}
                                roles={roles}
                                value={selectedRole}
                                onChange={setSelectedRole}
                                onAddRole={onAddRole}
                            />
                            <CreateRoleModal
                                isOpen={isRoleModalOpen}
                                setIsOpen={setIsRoleModalOpen}
                                onRoleCreated={onRoleCreated}
                            />
                        </Box>
                    </PopupInner>
                    <PopupFooter layout="right">
                        <PopupCloseTrigger asChild>
                            <Button size="s" variant="ghost">
                                Cancel
                            </Button>
                        </PopupCloseTrigger>
                        <Button
                            size="s"
                            startIcon={{ name: 'Plus' }}
                            disabled={!isSubmitEnabled}
                            onClick={onSubmit}
                        >
                            Add
                        </Button>
                    </PopupFooter>
                </PopupContent>
            </Popup>
            <InviteUserModal ref={inviteUserModalRef} roles={roles} />
        </>
    )
}

type UserSelectProps = {
    roles: AppRole[]
    value: string
    onChange: (value: string) => void
    onClosePopup: () => void
    openInviteUsersModal: (prefillEmail?: string) => void
}

const UserSelect: React.FC<UserSelectProps> = ({
    value,
    onChange,
    onClosePopup,
    openInviteUsersModal,
}) => {
    const {
        userOptions,
        selectedUserOption,
        isFetchingSlow,
        searchQuery,
        setSearchQuery,
        canInvite,
        isError,
        onValueChange,
    } = useAppUsersAddUsersUserSelectState({
        value,
        onChange,
        onClosePopup,
        openInviteUsersModal,
    })

    return (
        <Select
            placeholder="Select user"
            modal={false}
            isSearchable={!isFetchingSlow}
            startAvatar={selectedUserOption?.avatar}
            value={value}
            onChange={onValueChange}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
        >
            {!isError &&
                userOptions.map((user) => (
                    <SelectOptionComplex
                        key={user.value}
                        label={
                            isFetchingSlow ? (
                                <Skeleton isLoading={isFetchingSlow}>{user.label}</Skeleton>
                            ) : (
                                user.label
                            )
                        }
                        subtitle={
                            isFetchingSlow ? (
                                <Skeleton isLoading={isFetchingSlow}>{user.email}</Skeleton>
                            ) : (
                                user.email
                            )
                        }
                        value={user.value}
                        startAvatar={user.avatar}
                        disabled={user.isInApp || isFetchingSlow}
                    />
                ))}

            {!userOptions.length && !!searchQuery && !isFetchingSlow && !isError && (
                <SelectOption
                    value="_placeholder"
                    label={
                        <Body size="m" color="text">
                            No users found
                        </Body>
                    }
                    disabled
                />
            )}

            {!!searchQuery && !isFetchingSlow && canInvite && !isError && (
                <>
                    <SelectSeparator />
                    <SelectOptionComplex
                        value="__invite"
                        label="Invite new user"
                        subtitle={searchQuery}
                        startIcon={{ name: 'UserRoundPlus' }}
                    />
                </>
            )}

            {isError && (
                <SelectOption
                    value="__placeholder"
                    label={
                        <Body size="m" color="textError">
                            There was a problem fetching users
                        </Body>
                    }
                    disabled
                />
            )}
        </Select>
    )
}

type RoleSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    roles: AppRole[]
}

const RoleSelect: React.FC<RoleSelectProps> = ({ roles, value, onChange, onAddRole, ...props }) => {
    const { selectedRoleOption, roleOptions } = useAppUsersAddUsersRoleSelectState({
        roles,
        value,
    })

    return (
        <Select
            placeholder="Select role"
            onChange={onChange}
            value={value}
            startIcon={selectedRoleOption ? { name: selectedRoleOption?.icon } : undefined}
            className={selectedRoleOption?.isAdmin ? AppUsersInviteAdminOptionStyle : ''}
            footer={onAddRole && <CreateRoleButton onAddRole={onAddRole} />}
            {...props}
        >
            {roleOptions.map((role) => (
                <SelectOption
                    key={role.value}
                    label={role.label}
                    value={role.value}
                    startIcon={{ name: role.icon }}
                    variant={role.variant}
                />
            ))}
        </Select>
    )
}

const CreateRoleButton = ({ onAddRole }: { onAddRole: () => void }) => {
    return (
        <>
            <DropdownSeparator />
            <DropdownFooterItem
                label="Create new role"
                startIcon={{ name: 'Plus' }}
                onClick={onAddRole}
                variant="action"
            />
        </>
    )
}

type InviteUserModalProps = {
    roles: AppRole[]
}

const InviteUserModal = React.forwardRef<InviteUserModalHandle, InviteUserModalProps>(
    ({ roles }, ref) => {
        const {
            onConfirm,
            email,
            onEmailChange,
            isOpen,
            onOpenChange,
            selectedRole,
            setSelectedRole,
            emailInputRef,
            isSubmitDisabled,
        } = useAppUsersInviteUserModalState({
            ref,
        })

        return (
            <Modal open={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    <ModalHeader
                        subtitle={
                            <ModalParts.Subtitle>
                                Invite new user to your workspace and app. Assign their role
                                individually.
                            </ModalParts.Subtitle>
                        }
                        title="Invite user"
                        showCloseButton={true}
                    />
                    <ModalContentInner flex flexDirection="column" gap="xl">
                        <Box>
                            <Input
                                label="E-mail address"
                                value={email}
                                onChange={onEmailChange}
                                ref={emailInputRef}
                            />
                        </Box>
                        <Box>
                            <RoleSelect
                                roles={roles}
                                label="Assign individual role"
                                modal={true}
                                value={selectedRole}
                                onChange={setSelectedRole}
                            />
                        </Box>
                    </ModalContentInner>
                    <ModalFooter>
                        <ModalFooterButtonGroup layout="inline">
                            <ModalCloseTrigger asChild>
                                <Button size="l" variant="ghost">
                                    Cancel
                                </Button>
                            </ModalCloseTrigger>
                            <Button
                                size="l"
                                variant="primary"
                                onClick={onConfirm}
                                disabled={isSubmitDisabled}
                            >
                                Send invite
                            </Button>
                        </ModalFooterButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }
)
