import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { IconPickerDropdown } from 'ui/components/IconPicker/IconPickerDropdown'
import { Input } from 'ui/components/Input'
import { Textarea } from 'ui/components/Textarea'

export function EditAgentForm() {
    const { register, setValue, watch } = useFormContext<Partial<Agent>>()
    const icon = watch('icon') || { type: 'lucide', name: 'Bot' }

    return (
        <Box as="form" flex column gap="m">
            <Field label="Agent Title" width="5xl" shrink>
                <Box flex gap="m">
                    <IconPickerDropdown
                        value={icon}
                        onChange={(newIcon) => setValue('icon', newIcon)}
                    />
                    <Input
                        grow
                        placeholder="Enter agent title"
                        {...register('title', { required: true })}
                    />
                </Box>
            </Field>

            <Textarea
                label="Custom Instructions"
                placeholder="Enter custom instructions"
                {...register('custom_instructions')}
            />
        </Box>
    )
}
