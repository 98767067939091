import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { TableViewMultiValueAttributeDisplayStyle } from 'features/views/ListView/TableView/TableView.css'

const WIDTH_TO_SHOW_COUNT = 200 // px

type UseDropdownCellStateProps = {
    field: FieldDto
    value?: string | string[]
    isLoading?: boolean
    isResizable?: boolean
}

export function useDropdownCellState(options: UseDropdownCellStateProps) {
    const { field, value, isLoading, isResizable } = options

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const columnRef = useRef<HTMLDivElement>(null)

    const canShowCount = field.type === 'multi_select' && Array.isArray(value) && value.length > 1
    const canShowCountRef = useRef(canShowCount)
    canShowCountRef.current = canShowCount

    const [showOnlyCount, setShowOnlyCount] = useState(false)

    useLayoutEffect(() => {
        const column = columnRef.current
        if (!column || !isResizable) return

        const resizeObserver = new ResizeObserver((entries) => {
            if (!canShowCountRef.current) return

            for (let entry of entries) {
                const { width } = entry.contentRect

                if (width >= WIDTH_TO_SHOW_COUNT) {
                    setShowOnlyCount(false)
                    return
                }

                const attributeEl = entry.target.querySelector(
                    `.${TableViewMultiValueAttributeDisplayStyle}`
                ) as HTMLElement | null
                if (!attributeEl) return

                const attributeWidth = attributeEl.scrollWidth
                const isOverflowing = attributeWidth >= width
                setShowOnlyCount((prev) => {
                    if (prev) return true

                    return isOverflowing
                })
            }
        })

        resizeObserver.observe(column)

        return () => {
            resizeObserver.disconnect()
        }
    }, [isResizable])

    return useMemo(
        () => ({
            columnRef,
            isEmpty,
            showOnlyCount,
        }),
        [isEmpty, showOnlyCount]
    )
}
