import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Rights } from 'app/accountUserContextConstants'
import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useDeleteZone } from 'data/hooks/zones'
import { closeWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

export const DeletePortal = ({ portal }: { portal: AccountZone & { type: 'Portal' } }) => {
    const { hasRight } = useAccountUserContext()

    const [isOpen, setIsOpen] = useState(false)
    const [urlMatch, setUrlMatch] = useState('')
    const [error, setError] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)

    const { mutateAsync: deleteZone } = useDeleteZone()

    const history = useHistory()
    const onDelete = () => {
        setProcessing(true)
        setError(false)
        deleteZone(portal._sid)
            .then(() => {
                history.push(getWorkspaceUrl(Urls.Home))
                closeWorkspaceSettingsModal()
            })
            .catch(() => {
                setProcessing(false)
                setError(true)
            })
    }

    // Only allow the deletion of the current portal
    if (!hasRight(Rights.ManageSettings) || !portal || portal.type != 'Portal') return null

    const toggleModal = () => setIsOpen((prevState) => !prevState)

    return (
        <>
            <Divider variant="weak" my="l" />
            <Button variant="critical" startIcon={{ name: 'Trash2' }} onClick={toggleModal}>
                Delete portal
            </Button>
            <Modal open={isOpen}>
                <ModalContent>
                    <ModalHeader
                        title="Delete portal"
                        subtitle={`Are you sure you want to delete your portal? Deleting your portal is irreversible.`}
                        showCloseButton={false}
                    />
                    <Body mx="3xl">
                        Please type <b>{portal.name}</b> to confirm
                    </Body>
                    <Input
                        my="xs"
                        mx="3xl"
                        style={{ maxWidth: '90%' }}
                        value={urlMatch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setUrlMatch(e.target.value)
                        }
                    />
                    <ModalFooter>
                        <ModalFooterButtonGroup>
                            <Button
                                variant="destructive"
                                onClick={onDelete}
                                disabled={urlMatch !== portal.name || processing}
                            >
                                I understand, delete
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setUrlMatch('')
                                    toggleModal()
                                }}
                            >
                                Back to safety
                            </Button>
                            {error && (
                                <Body color="textError" size="m">
                                    Sorry, there was an error deleting your portal. Please try
                                    again.
                                </Body>
                            )}
                        </ModalFooterButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
