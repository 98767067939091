import React from 'react'

import { Box } from 'ui/components/Box'

import { useNavigationSpacesState } from './hooks/useNavigationSpacesState'
import { NavigationAllAppsItem } from './NavigationAllAppsItem'
import { NavigationSpacesItem } from './NavigationSpacesItem'

type NavigationSpacesProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isCollapsed?: boolean
}

export const NavigationSpaces: React.FC<NavigationSpacesProps> = React.memo(
    function NavigationSpaces({ isCollapsed, ...props }) {
        const { spaces, showSpaces } = useNavigationSpacesState()

        if (!showSpaces) return null

        return (
            <Box flex column px={isCollapsed ? 0 : 'xs'} gap={isCollapsed ? '2xs' : 'm'} {...props}>
                {spaces.map((space, index) => (
                    <NavigationSpacesItem
                        key={space.id}
                        {...space}
                        isCollapsed={isCollapsed}
                        isLast={index === spaces.length - 1}
                    />
                ))}
                {isCollapsed && <NavigationAllAppsItem isCollapsed={isCollapsed} />}
            </Box>
        )
    }
)
