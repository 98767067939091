import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownItemLink,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { NavigationItem } from './NavigationItem'

import {
    AppNavigationItemIconStyles,
    AppNavigationItemIconWrapperStyle,
    AppNavigationItemLabelStyles,
    AppNavigationItemStyles,
    NavigationFooterItemLabelStyle,
    NavigationFooterItemStyle,
} from './Navigation.css'

type NavigationHelpAndSupportProps = {}

export const NavigationHelpAndSupport: React.FC<NavigationHelpAndSupportProps> = () => {
    return (
        <Box flex center gap="m" width="full" px="m" noShrink>
            <Dropdown>
                <DropdownTrigger asChild>
                    <NavigationItem
                        className={classNames(
                            AppNavigationItemStyles.styleFunction({
                                isActive: false,
                                hasChildren: false,
                                hasIcon: true,
                            }),
                            NavigationFooterItemStyle
                        )}
                        trim
                        label={
                            <Box
                                flex
                                center
                                gap="s"
                                trim
                                className={NavigationFooterItemLabelStyle}
                            >
                                <Box
                                    position="relative"
                                    className={AppNavigationItemIconWrapperStyle}
                                >
                                    <Box
                                        className={AppNavigationItemIconStyles.styleFunction({
                                            isActive: false,
                                        })}
                                        style={{ color: 'inherit' }}
                                    >
                                        <Icon size="s" name="HelpCircle" />
                                    </Box>
                                </Box>
                                <Body
                                    size="m"
                                    weight="medium"
                                    trim
                                    className={AppNavigationItemLabelStyles.styleFunction({
                                        isActive: false,
                                    })}
                                    style={{ color: 'inherit' }}
                                >
                                    Help &amp; Support
                                </Body>
                            </Box>
                        }
                    />
                </DropdownTrigger>
                <DropdownContent align="start" side="top">
                    <DropdownItemLink
                        label="Managing data"
                        target="_blank"
                        startIcon={{ name: 'Sheet' }}
                        href="https://docs.stackerhq.com/stacker/getting-started/introduction-to-data"
                    />
                    <DropdownItemLink
                        label="Design your app"
                        target="_blank"
                        startIcon={{ name: 'Paintbrush2' }}
                        href="https://docs.stackerhq.com/stacker/getting-started/introduction-to-layouts"
                    />
                    <DropdownItemLink
                        label="Users & Permissions"
                        target="_blank"
                        startIcon={{ name: 'Users' }}
                        href="https://docs.stackerhq.com/stacker/getting-started/introduction-to-permissions"
                    />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}
