import React, { useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { sendVerificationEmail } from 'supertokens-web-js/recipe/emailverification'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'
import { useRealtimeUpdates } from 'data/realtime/realtimeUpdates'
import { AuthFrame } from 'features/auth/new-stacker-auth/AuthFrame'

import useRunOnce from 'v2/ui/utils/useRunOnce'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

export function EmailVerificationPage({ verificationFailed }: { verificationFailed?: boolean }) {
    const { user } = useAuthContext()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const rtuHandler = useCallback(() => window.location.reload(), [])

    useRealtimeUpdates({
        channel: 'email-verified-' + user?._sid,
        handler: rtuHandler,
    })

    const handleSendVerificationEmail = useCallback(async () => {
        setIsLoading(true)
        try {
            await sendVerificationEmail()
        } catch (error) {
            console.error('Failed to send verification email:', error)
            setErrorMessage('An error occurred. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }, [])

    useRunOnce(() => {
        handleSendVerificationEmail()
    }, !!user)

    if (!user) {
        return <Redirect to={getUrl(Urls.Login)} />
    }
    return (
        <AuthFrame title="Email Verification">
            <Box flex column gap="m">
                {verificationFailed ? (
                    <>
                        <Box mb="m">
                            <Body size="m">
                                Email verification failed. Please click the button below to receive
                                a new verification email.
                            </Body>
                        </Box>
                        <Button
                            variant="primary"
                            onClick={handleSendVerificationEmail}
                            isLoading={isLoading}
                        >
                            Send Verification Email
                        </Button>
                    </>
                ) : (
                    <>
                        <Banner
                            show={!verificationFailed}
                            icon={{ name: 'Mail' }}
                            title="Check your email"
                            helper="We've sent you an email with a link to verify your email address."
                        />
                        <Box
                            flex
                            flexDirection={{ mobile: 'column', tablet: 'row' }}
                            my="m"
                            px="m"
                            justifyContent="center"
                            alignItems={{ mobile: 'center', tablet: 'baseline' }}
                        >
                            <Body size="m">Didn&apos;t get an email?</Body>
                            <Button
                                size="xs"
                                type="button"
                                variant="link"
                                onClick={handleSendVerificationEmail}
                                isLoading={isLoading}
                            >
                                Click here to re-send
                            </Button>
                        </Box>
                    </>
                )}
                {errorMessage && (
                    <Box alignSelf="center" mb="m">
                        <Body color="textError">{errorMessage}</Body>
                    </Box>
                )}
            </Box>
        </AuthFrame>
    )
}
