import React, { useState } from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

import { DeleteDataConnectionConfirmationModal } from './DeleteDataConnectionConfirmationModal'

type DataConnectionOptionsProps = {
    dataConnection: DataConnectionDto
}
export const DataConnectionOptions: React.FC<DataConnectionOptionsProps> = ({ dataConnection }) => {
    const [isDeleteConnectionModalOpen, setIsDeleteConnectionModalOpen] = useState(false)

    return (
        <Box
            style={{
                paddingRight: theme.space['6xl'],
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    <DropdownItem
                        variant="destructive"
                        startIcon={{ name: 'Trash2' }}
                        label="Remove"
                        onClick={() => {
                            setIsDeleteConnectionModalOpen(true)
                        }}
                    />
                </DropdownContent>
            </Dropdown>
            <DeleteDataConnectionConfirmationModal
                isOpen={isDeleteConnectionModalOpen}
                onOpenChange={setIsDeleteConnectionModalOpen}
                dataConnection={dataConnection}
            />
        </Box>
    )
}
