import { useIsDeviceTouchOnly } from 'utils/useIsDeviceTouchOnly'

import { useBrowserInfo } from 'v2/ui'

export type KeyboardShortcutLabel = string | { mac: string; default: string }

export function useKeyboardShortcutLabel(label?: KeyboardShortcutLabel) {
    const browser = useBrowserInfo()
    const isMac = browser.os.name === 'macOS'

    const isTouchDevice = useIsDeviceTouchOnly()
    if (isTouchDevice) {
        return ''
    }

    if (typeof label === 'object') {
        return isMac ? label.mac : label.default
    }

    return label ?? ''
}
