import React from 'react'

import { useDetailViewHeaderState } from './hooks/useDetailViewHeaderState'
import { DetailViewSlimHeader } from './DetailViewSlimHeader'

type DetailViewHeaderProps = {}

export const DetailViewHeader: React.FC<DetailViewHeaderProps> = () => {
    const { headerType } = useDetailViewHeaderState()

    switch (headerType) {
        case 'slim':
            return <DetailViewSlimHeader />
    }
}
