import React, { useContext, useEffect } from 'react'
import useScrollbarSize from 'react-scrollbar-size'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { AppUserContext } from 'app/AppUserContext'
import { catchComponentErrors } from 'app/errorBoundaryFunctions'
import MetadataLoading from 'app/MetadataLoading'
import { useAppContext } from 'app/useAppContext'
import { withUser } from 'data/wrappers/withUser'
import { useIsEditingBlankPages } from 'features/blank-pages/useIsEditingBlankPages'
import { DefaultHeaderMeta } from 'features/core/DefaultHeaderMeta'
import { FrameContext } from 'features/core/frameContext'
import HeroContainer from 'features/core/HeroContainer'
import { PoweredBy } from 'features/core/PoweredBy'
import { Navigation } from 'features/navigation/Navigation'
import { HeaderContextProvider } from 'features/utils/HeaderContextProvider'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { SIDE_PANE_DEFAULT_WIDTH } from 'features/workspace/AdminSideTray/constants'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { LoadingSplash } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDimension from 'v2/ui/utils/useDimension'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { BANNER_HEIGHT } from './appBannerConstants'
import AppBannerService from './AppBannerService'
import { EDIT_MODE_GUTTER } from './frameConstants'
import { useIsBannerShowing } from './useIsBannerShowing'
import { useLeftOffset } from './useLeftOffset'

export const InnerFrame = ({
    children,
    isEditing,
    width,
    page,
    view,
    background,
    ignoreLoadingState,
    paddingOverride,
    hidePoweredBy,
    overrideShowBreadcrumbs,
}) => {
    const { workspaceAccount } = useAppContext()
    const isMobile = useIsMobile()

    const leftOffset = useLeftOffset()
    const bannerLeftOffset = leftOffset

    const [isBannerShowing] = useIsBannerShowing()

    const headerHeight = isBannerShowing ? BANNER_HEIGHT : 0

    const frameContext = React.useMemo(
        () => ({
            headerHeight,
        }),
        [headerHeight]
    )
    const isInbox = view?.options?.display === 'inbox' && !isMobile

    const { selectedStack } = useAppContext()
    const showBreadcrumbs =
        overrideShowBreadcrumbs ?? selectedStack?.options?.showBreadcrumbs ?? false
    const { isLoading, authStateKey } = useContext(AppUserContext)

    const [backdropWidth, setBackdropWidth] = React.useState()

    const defaultWidth = '100%'
    const contentWidth = width || defaultWidth

    const padding = paddingOverride || isInbox ? '0' : '0px 10px 50px'

    const isEditingBlankPages = useIsEditingBlankPages()
    const portalContext = useContext(LayoutEditorContext)

    //close active portal if closing editor
    if (!isEditing && !isEditingBlankPages && portalContext.activeEditor) {
        portalContext.closeEditor()
    }

    // Close the active editor on unmount
    useEffect(() => {
        return () => portalContext.closeEditor()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FrameContext.Provider value={frameContext}>
            <MetadataLoading onlyShowOnInitialLoad={false}>
                <Backdrop
                    showSidebar={isEditing}
                    background={background}
                    isMobile={isMobile}
                    setBackdropWidth={setBackdropWidth}
                    leftOffset={leftOffset}
                >
                    <DefaultHeaderMeta page={page} view={view}></DefaultHeaderMeta>

                    <Navigation />

                    {/* All app banners */}
                    {workspaceAccount && (
                        <AppBannerService leftOffset={bannerLeftOffset} showingAppBar={!isMobile} />
                    )}

                    {/* if we're in a workspace, and we're previewing as a user or role
                                    then we need to insert some space at the top of the page since
                                    we have a fixed banner above. */}
                    {workspaceAccount && isBannerShowing && (
                        <div style={{ height: BANNER_HEIGHT }} />
                    )}
                    {isLoading && !ignoreLoadingState ? (
                        <LoadingSplash />
                    ) : (
                        <>
                            <HeaderContextProvider>
                                <HeroContainer
                                    hide={isInbox}
                                    backdropWidth={backdropWidth}
                                    showBreadcrumbs={showBreadcrumbs}
                                />

                                {/* we want to completely recreate the components below this level if our auth state changes
                                            (ie., impersonation/preview status changes): so we key this div with the authStateKey provided
                                            from AppUserContext */}
                                <div
                                    className={STYLE_CLASSES.CONTENT}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flex: '1 0 auto',
                                        padding: padding,
                                        overflow: 'hidden',
                                    }}
                                    key={authStateKey}
                                >
                                    <div
                                        style={{
                                            padding: padding,
                                            width: contentWidth,
                                            maxWidth: '100%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    >
                                        {catchComponentErrors(children)}
                                    </div>
                                </div>
                            </HeaderContextProvider>
                        </>
                    )}
                    {!isInbox && !hidePoweredBy && <PoweredBy />}
                </Backdrop>
            </MetadataLoading>
        </FrameContext.Provider>
    )
}

InnerFrame.propTypes = {
    children: PropTypes.node,
    context: PropTypes.object,
}
InnerFrame.defaultProps = {
    children: null,
    context: {},
}

export const Frame = withUser(InnerFrame)

const BackdropStyled = styled('div')`
    background-color: ${(props) =>
        props.background ? props.background : props.theme.backgroundColor};
    width: ${(props) =>
        props.leftMargin || props.rightMargin
            ? `calc(100% - ${props.leftMargin || '0px'} - ${props.rightMargin || '0px'})`
            : '100%'};
    height: min-content;
    min-height: 100dvh;
    margin-left: ${(props) => props.leftMargin};
    margin-right: ${(props) => props.rightMargin};
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding-right: ${(props) => (props.rightPadded ? `${EDIT_MODE_GUTTER}px` : 0)};
    transition: margin-left 400ms;
    padding-top: ${(props) => props.paddingTop};
`
const Backdrop = ({
    showSidebar,
    leftOffset,
    background,
    children,
    setBackdropWidth,
    paddingTop,
}) => {
    const {
        state: { animationComplete },
    } = useSlidingPane()

    // Use the non-admin call here as the admin call has a delay before it returns true
    // (waiting on roles to load, etc) and all of the conditions below just need to know if
    // we're in an SB app, period.
    const { width: scrollbarWidth } = useScrollbarSize()
    const backdropRef = React.useRef()
    const { width: backdropWidth } = useDimension(backdropRef)

    useEffect(() => {
        setBackdropWidth(backdropWidth)
    }, [backdropWidth, setBackdropWidth])

    const showRightOffsetForEditLayout = showSidebar && animationComplete
    const showRightOffset = showRightOffsetForEditLayout
    const rightOffset = SIDE_PANE_DEFAULT_WIDTH

    return (
        <BackdropStyled
            className="BackdropStyled"
            leftMargin={`${leftOffset}px`}
            rightMargin={
                showRightOffset
                    ? // subtract scrollbar width and an extra 10px to account for the
                      // rounded corners of the side pane
                      // and another 30px to fully overlap the page
                      `${rightOffset - scrollbarWidth}px`
                    : '0px'
            }
            background={background}
            ref={backdropRef}
            paddingTop={paddingTop}
        >
            {children}
        </BackdropStyled>
    )
}
