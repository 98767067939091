// @ts-strict-ignore
import { useCallback, useMemo } from 'react'
import { UseQueryOptions } from 'react-query'

import { orderBy } from 'lodash'

import { useWorkspaceContext } from 'app/WorkspaceContext'
import { REACT_QUERY } from 'data/utils/constants'

import { STACK_LIST_NAME } from './stacks/stackConstants'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    queryClient,
    useCanRunWorkspaceScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'
import { workspaceGroups } from './accounts'

const ENDPOINT = 'stacks/'

type UseStacksQueryOptions = UseQueryOptions<StackDto[]> & {
    filterByZoneSid?: string
}

export function useStacks(queryOptions?: UseStacksQueryOptions) {
    const { filterByZoneSid = false, ...options } = queryOptions ?? {}

    const { workspaceZone } = useWorkspaceContext()

    const enabled = useCanRunWorkspaceScopedQueries()
    const queryResult = useQuery<StackDto[]>(
        STACK_LIST_NAME,
        ENDPOINT,
        {
            ...options,
            ...ACCOUNT_QUERY_RETRY_OPTIONS,
            enabled: enabled && options.enabled !== false,
        },
        {
            bypassMatchingStackCheck: true,
            bypassPreviewAs: true,
        }
    )

    const stacks = queryResult.data
    const filteredStacks = useMemo(() => {
        if (!!filterByZoneSid) {
            return stacks?.filter((stack) => stack.zone_id === filterByZoneSid)
        }

        return stacks?.filter((stack) => stack.zone_id === workspaceZone?._sid)
    }, [filterByZoneSid, stacks, workspaceZone?._sid])

    return { ...queryResult, data: filteredStacks }
}

export function useGroupedWorkspaceStacks(queryOptions?: UseStacksQueryOptions) {
    const query = useStacks(queryOptions)

    const stacks = query.data
    return useMemo(() => {
        const sorted = orderBy(stacks, [(g) => g.options.group || '', 'name'])
        const groups = sorted.reduce(
            (acc, stack) => {
                const name = stack.options.group || ''
                const group = acc.find((g) => g.name === name)
                if (group) {
                    group.stacks.push(stack)
                } else {
                    acc.push({
                        name,
                        stacks: [stack],
                    })
                }
                return acc
            },
            [] as { name: string; stacks: StackDto[] }[]
        )

        return { ...query, data: groups }
    }, [query, stacks])
}

export function useCreateStack() {
    return useCreateItem<StackDto>(
        STACK_LIST_NAME,
        ENDPOINT,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(REACT_QUERY.roles.listName)
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        }
    )
}

export function useUpdateStack(allowOptimisticUpdates: boolean = false) {
    return useUpdateItem<StackDto>(
        STACK_LIST_NAME,
        ENDPOINT,
        {},
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
            // specify the stack id for the request based on the stack
            // we're updating, not the stack we currently are viewing (which may be none)
            provideFetchOptions: ({ id: stackId }) => ({ stackId }),
        },
        allowOptimisticUpdates
    )
}

export function useUpdateStackOptions(allowOptimisticUpdates: boolean = false) {
    const { mutateAsync: updateStack } = useUpdateStack(allowOptimisticUpdates)

    return useCallback(
        (stack: StackDto, optionsPatch: Partial<StackDto['options']>) => {
            const { _sid: id, options } = stack
            return updateStack({ id, patch: { options: { ...options, ...optionsPatch } } })
        },
        [updateStack]
    )
}

export function useDeleteStack() {
    return useDeleteItem<StackDto>(STACK_LIST_NAME, ENDPOINT, undefined, {
        // Submit this request using the studio user's token
        // and ignore any user or role previewing.
        bypassPreviewAs: true,
    })
}

export type SharingSettingsPatch = {
    [key: string]: { deleted?: boolean; role?: string }
}

export function useUpdateStackSharingSettings() {
    const { mutateAsync: updateStack } = useUpdateStack()

    const method = useCallback(
        (stack: StackDto, patch: SharingSettingsPatch, additionalData?: any): Promise<StackDto> => {
            const sharing = Object.keys(patch).reduce(
                (result, key) => {
                    const record = patch[key]
                    const value = record.deleted ? null : record.role
                    // if this is a group, update the groups list
                    if (workspaceGroups.find((x) => x._sid === key)) {
                        result.groups[key] = value
                        // Otherwise, update the users list
                    } else {
                        result.users[key] = value
                    }
                    return result
                },
                { groups: {}, users: {} }
            )

            return updateStack({
                id: stack._sid,
                patch: { sharing_patch: sharing, ...additionalData },
            })
        },
        [updateStack]
    )

    return method
}

export function createNewStackData(
    accountId: string,
    zoneId: string,
    userId: string,
    name?: string,
    icon: string = 'book-02',
    logo?: string,
    brandColor?: string
): {
    account_id: string
    name: string
    options: Partial<StackDto['options']>
} & Partial<Omit<StackDto, 'options'>> {
    return {
        account_id: accountId,
        name: name ?? '',
        options: {
            theme: {
                logo,
                icon,
                icon_family: 'hugeicons',
                navColor: 'light',
                navIcons: true,
                brandColor: brandColor ?? '',
            },
        },
        sharing: {
            users: { [userId]: 'internal_admin' },
        },
        zone_id: zoneId,
    }
}
