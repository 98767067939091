import { useCallback, useMemo, useRef } from 'react'

import { useDndContext } from '@dnd-kit/core'

import { Widget } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { useLayoutEditorWidgetAreaContext } from 'features/views/LayoutEditor/useLayoutEditorWidgetAreaContext'
import { insertYWidgetAtPath } from 'features/views/LayoutEditor/utils'

import { LayoutEditorWidgetContextMenuHandle } from './useLayoutEditorWidgetContextMenuState'

type UseLayoutEditorWidgetStateOptions = {
    widget: Widget
}

export function useLayoutEditorWidgetState(options: UseLayoutEditorWidgetStateOptions) {
    const { widget } = options

    const { schema, selectedWidget, isEditing, commands } = useLayoutEditorContext()

    const widgetSchema = useMemo(() => {
        return schema.widgets[widget.type]
    }, [schema.widgets, widget.type])

    const Component = widgetSchema?.component

    const isSelected = selectedWidget?.id === widget.id

    const { active } = useDndContext()

    const isDraggingAnotherWidget = !!active && active.id !== widget.id
    const isSelectable = isEditing && !isSelected && !isDraggingAnotherWidget

    const { path = [], editorSchema } = useLayoutEditorWidgetAreaContext() ?? {}
    const pathRef = useRef(path)
    pathRef.current = path
    const editorSchemaRef = useRef(editorSchema)
    editorSchemaRef.current = editorSchema

    const onClick = useCallback(
        (e) => {
            e.stopPropagation()

            if (!isSelectable) return
            e.preventDefault()

            const path = pathRef.current
            commands.selectWidgetAtPath(widget.id, path)
        },
        [commands, isSelectable, widget.id]
    )

    const widgetLabel = widgetSchema?.label

    const contextMenuRef = useRef<LayoutEditorWidgetContextMenuHandle>(null)

    const onContextMenu = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            e.stopPropagation()

            if (isSelectable && !isSelected) {
                const path = pathRef.current
                commands.selectWidgetAtPath(widget.id, path)
            } else if (!isSelected) {
                return
            }

            contextMenuRef?.current?.openAt(e.clientX, e.clientY)
        },
        [commands, isSelectable, isSelected, widget.id]
    )

    const onRemoveWidget = useCallback(() => {
        if (!isSelected) return

        commands.removeSelectedWidget()
    }, [commands, isSelected])

    const onDuplicateWidget = useCallback(() => {
        if (!isSelected) return

        commands.duplicateSelectedWidget()
    }, [commands, isSelected])

    const onCopyWidget = useCallback(async () => {
        if (!isSelected) return

        try {
            await navigator.clipboard.writeText(JSON.stringify(widget))
        } catch {}
    }, [isSelected, widget])

    const onPasteWidget = useCallback(async () => {
        if (!isSelected) return

        try {
            const text = await navigator.clipboard.readText()
            const widget = JSON.parse(text) as Widget

            commands.transaction((data) => {
                const editorSchema = editorSchemaRef.current
                if (!editorSchema) return

                const path = pathRef.current

                const newWidget = insertYWidgetAtPath(path, editorSchema, widget, data)
                if (!newWidget) return

                commands.selectWidgetAtPath(newWidget.get('id'), path)
            })
        } catch {}
    }, [commands, isSelected])

    const isContainer = !!widgetSchema?.isContainer

    return useMemo(
        () => ({
            Component,
            isSelected,
            onClick,
            isSelectable,
            widgetLabel,
            isEditing,
            isDraggingAnotherWidget,
            onContextMenu,
            contextMenuRef,
            onRemoveWidget,
            onDuplicateWidget,
            isContainer,
            onCopyWidget,
            onPasteWidget,
        }),
        [
            Component,
            isSelected,
            onClick,
            isSelectable,
            widgetLabel,
            isEditing,
            isDraggingAnotherWidget,
            onContextMenu,
            onRemoveWidget,
            onDuplicateWidget,
            isContainer,
            onCopyWidget,
            onPasteWidget,
        ]
    )
}
