import { QueryKey, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'
import { useRealtimeUpdates } from 'data/realtime/realtimeUpdates'

import { AgentJob } from './types'

const LIST_NAME = 'useJobs'
const ENDPOINT = 'agents/{agent_id}/jobs/'

function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}

type OptionsType = UseQueryOptions<AgentJob[], unknown, AgentJob[], QueryKey>

export function useJobs(agentId: string, options: OptionsType = {}) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as OptionsType),
        ...options,
        enabled,
    }
    const queryResult = useQuery<AgentJob[]>(
        useQueryKey(),
        ENDPOINT.replace('{agent_id}', agentId),
        query_config
    )

    return queryResult
}

export function useJob(
    agentId: string,
    jobId: string,
    options: UseQueryOptions<AgentJob, unknown, AgentJob, QueryKey> = {}
) {
    const queryKey = [...useQueryKey(), jobId]
    const query = useQuery<AgentJob>(
        queryKey,
        ENDPOINT.replace('{agent_id}', agentId) + jobId,
        options
    )

    useRealtimeUpdates({
        channel: jobId,
        handler: (_, update: AgentJob) => {
            queryClient.setQueryData(queryKey, (existing: AgentJob | undefined) => {
                if (!existing) return update
                return { ...existing, ...update }
            })
        },
    })

    return query
}

export function useCreateJob() {
    return useCreateItem<AgentJob>(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateJobs()
            },
        },
        undefined,
        false
    )
}

export function useUpdateJob() {
    return useUpdateItem<AgentJob>(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateJobs()
        },
    })
}

export function useDeleteJob() {
    return useDeleteItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateJobs()
        },
    })
}

export function invalidateJobs() {
    return queryClient.invalidateQueries([LIST_NAME, getCurrentStackId()])
}
