import React from 'react'

import { Highlighter } from 'features/Search/Highlighter'

import { Box } from 'ui/components/Box'

import { CommandListIcon } from './CommandListIcon'
import { InternalLinkCommand } from './internalLinkCommandTypes'

export function RenderCommand({
    item,
    queryTerms,
}: {
    item: InternalLinkCommand
    queryTerms?: string[]
}) {
    return (
        <Box flex center maxWidth="full" width="full" color="text" tabIndex={-1}>
            <CommandListIcon name={item.icon} mr="m" />
            <Box flex column grow shrink>
                <Box
                    mr="l"
                    trim
                    grow
                    style={{
                        marginTop: '-2px',
                        paddingTop: '2px',
                        marginBottom: '-2px',
                        paddingBottom: '2px',
                    }}
                >
                    <Highlighter terms={queryTerms || []}>{item.label}</Highlighter>
                </Box>
                {/* <Box color="textWeak" fontSize="bodyS">
                    {item.description}
                </Box> */}
            </Box>
        </Box>
    )
}
