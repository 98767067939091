import React from 'react'

import { getUrl } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'
import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { useNavigationContext } from 'features/navigation/hooks/useNavigationContext'

import { Box, BoxProps } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItemLink } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'

import { DocumentLink } from './DocumentLink'
import { RecordLink } from './RecordLink'

type Props = BoxProps & {
    document: DocumentDto
    documents: Partial<DocumentDto>[]
    records: RecordDto[]
}
export function DocumentBreadcrumbs({ document, documents, records, ...props }: Props) {
    const ancestorIds = document._ancestors ?? ([] as number[])
    const { parent_record_id } = document
    const ancestors = ancestorIds
        .map((ancestorId) => documents.find((doc) => doc.auto_id === ancestorId))
        .reverse()
        .filter(Boolean) as DocumentDto[]

    const { showCollapsedNavigationTrigger } = useNavigationContext()

    const parentRecord = records.find((x) => x._sid === parent_record_id)
    if (!ancestors.length && !parentRecord) return null

    const directParent = ancestors.length > 0 ? ancestors[ancestors.length - 1] : undefined

    const rootItem = parentRecord || ancestors[0]

    const collapsedItems = ancestors.slice(parentRecord ? 0 : 1, ancestors.length - 1)

    return (
        <Box flex center {...props} color="textWeaker" fontSize="bodyS">
            {showCollapsedNavigationTrigger && <CollapsedNavigationTrigger noShrink />}
            {parentRecord ? (
                <RecordLink record={parentRecord} stackId={document.stack_id} />
            ) : (
                <DocumentLink document={rootItem} />
            )}
            {collapsedItems.length > 0 && (
                <>
                    <Divider />
                    <Dropdown>
                        <DropdownButton
                            variant="ghost"
                            size="xs"
                            startIcon={{ name: 'MoreHorizontal' }}
                        />
                        <DropdownContent>
                            {collapsedItems.map((item) => (
                                <DocumentLinkMenuItem key={item.auto_id} document={item} />
                            ))}
                        </DropdownContent>
                    </Dropdown>
                </>
            )}
            {directParent && directParent !== rootItem && (
                <>
                    <Divider />
                    <DocumentLink document={directParent} />
                </>
            )}
            <Divider />
            <Icon name="Notebook" mr="xs" color="textWeaker" size="s" noShrink />
            <Box trim>{document.title}</Box>
        </Box>
    )
}

function Divider() {
    return (
        <Box mx="xs" color="textWeaker">
            /
        </Box>
    )
}
function DocumentLinkMenuItem({ document }: { document: DocumentDto }) {
    const { data: stacks } = useStacks()
    const stack = stacks?.find((stack) => stack._sid === document.stack_id)
    if (!stack) return null
    return (
        <DropdownItemLink to={getUrl(`/docs/${document.auto_id}`, stack)} label={document.title} />
    )
}
