import React, { useContext } from 'react'

import { ListViewSort } from 'features/views/ListView/Sort/types'

import { BoardViewAttribute, BoardViewColumn } from './types'

export type BoardViewInternalContextValue = {
    columns: BoardViewColumn[]
    attributes: BoardViewAttribute[]
    statusField?: FieldDto
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    allowReorder: boolean
    sortBy?: ListViewSort
    manualSortKey?: string
    errorMessage?: string
    attributeStyle: ListViewOptions['boardFieldStyle']
    labelStyle?: ListViewOptions['boardLabelStyle']
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    hasFilters: boolean
    inlineFilterType?: ListViewInlineFilters
    availableInlineFilterFields: FieldDto[]
    isEmbedded: boolean
    allFields: FieldDto[]
    requestFilters: Filter[]
    requestIncludedFields: string[]
    coverImageField?: FieldDto
    profileImageField?: FieldDto
    titleSize: Required<ListViewOptions['boardTitleSize']>
    titleField?: FieldDto
    eyebrowField?: FieldDto
    subtitleField?: FieldDto
    header: ListViewHeader
    onRecordClick: Required<ListViewOptions['onRecordClick']>
}
export type BoardViewContextValue = BoardViewInternalContextValue & {
    updateRecord: (record: RecordDto, patch: Partial<RecordDto>) => Promise<void>
    createRecord: (record: Partial<RecordDto>) => Promise<RecordDto>
    pendingRecords: RecordDto[]
}
export const BoardViewContext = React.createContext<BoardViewContextValue | null>(null)

export function useBoardViewContext(): BoardViewContextValue {
    const context = useContext(BoardViewContext)

    if (!context) {
        throw new Error('useBoardViewContext must be used within a BoardViewContextProvider')
    }

    return context
}
