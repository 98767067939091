import { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { captureException } from '@sentry/react'

import { Rights } from 'app/appUserContextConstants'
import { useAppUserContext } from 'app/useAppUserContext'
import { recordApi } from 'data/api/recordApi'
import { useFavorite } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import { useNavigation } from 'data/hooks/navigation'
import { useViews } from 'data/hooks/views'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { PathContext } from 'features/utils/PathContext'
import { useCopyLink } from 'features/utils/useCopyLink'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/useListHeaderContext'
import { useDuplicateViewModal } from 'features/views/useDuplicateViewModal'

import { useToast } from 'ui/components/Toast'

export function useListHeaderMoreActionsState() {
    const {
        allowDownload,
        object,
        stack,
        sortBy,
        isEditMode,
        requestFilters,
        requestIncludedFields,
        view,
    } = useListHeaderContext()
    const viewRef = useRef(view)
    viewRef.current = view

    const toast = useToast()

    const [isLoading, setIsLoading] = useState(false)

    const onDownload = useCallback(async () => {
        if (!stack || !object) return

        try {
            const filename = formatCsvFilename(object, stack)

            setIsLoading(true)

            await recordApi.downloadCsvRecords({
                object,
                filename,
                filters: requestFilters,
                includeFields: requestIncludedFields,
                excludeRecordsIdFromCsv: true,
                disablePartials: isEditMode,
                orderBy: sortBy,
                csvFieldsOrder: requestIncludedFields,
            })
        } catch (err) {
            toast({
                title: 'There was a problem generating the CSV file',
                type: 'error',
                showDismissButton: true,
                startIcon: { name: 'AlertCircle' },
                helperText: 'Please try again later. If the issue persists, contact support.',
            })

            captureException(err)
        } finally {
            setIsLoading(false)
        }
    }, [isEditMode, object, requestFilters, requestIncludedFields, sortBy, stack, toast])

    const executeCopy = useCopyLink()
    const onCopyLink = useCallback(async () => {
        const url = window.location.href
        const hash = window.location.hash

        // Keep all URL parameters except the hash.
        const link = url.replace(hash, '')

        return executeCopy(link)
    }, [executeCopy])

    const deleteModalViewSid = view._sid
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const { data: views = [] } = useViews()

    const { isAdmin, hasRight } = useAppUserContext()
    const hasOtherListViewsForObject = views.some(
        (v) => v.object_id === view.object_id && v._sid !== view._sid && v.type === 'list'
    )
    const allowDelete = isAdmin && hasRight(Rights.Admin.Any) && hasOtherListViewsForObject

    const onDelete = useCallback(() => {
        // Using queueMicrotask to ensure that the modal is opened
        // after the dropdown is closed, otherwise the dropdown
        // focus lock will override the modal focus lock.
        queueMicrotask(() => {
            setIsDeleteModalOpen(true)
        })
    }, [])

    const pathContext = useContext(PathContext)
    const { data: navigation } = useNavigation()
    const currentNavItem = (navigation as NavigationDto[] | undefined)?.find(
        (n) => n.parent_id && n.url === pathContext.pageUrl
    )

    const { favorite, toggleFavorite } = useFavorite({
        targetType: FavoriteType.NavigationItem,
        stackId: stack._sid,
        navigationId: currentNavItem?._sid,
    })
    const allowFavorite = useFavoritesEnabled()
    const isFavorite = !!favorite

    const showDuplicateViewModal = useDuplicateViewModal()
    const allowDuplicate = isAdmin
    const onDuplicate = useCallback(() => {
        showDuplicateViewModal({ view: viewRef.current })
    }, [showDuplicateViewModal])

    return useMemo(
        () => ({
            allowDownload,
            onDownload,
            isLoading,
            onCopyLink,
            onDelete,
            allowDelete,
            deleteModalViewSid,
            isDeleteModalOpen,
            onDeleteModalOpenChange: setIsDeleteModalOpen,
            allowFavorite,
            isFavorite,
            onToggleFavorite: toggleFavorite,
            allowDuplicate,
            onDuplicate,
        }),
        [
            allowDelete,
            allowDownload,
            deleteModalViewSid,
            isDeleteModalOpen,
            isLoading,
            onCopyLink,
            onDelete,
            onDownload,
            allowFavorite,
            isFavorite,
            toggleFavorite,
            allowDuplicate,
            onDuplicate,
        ]
    )
}

const filenameRegexp = /[^a-z0-9]/gi

function formatCsvFilename(object: ObjectDto, stack: StackDto) {
    let filename = `${stack.name}_${object.name}`
    filename = filename.replace(filenameRegexp, '_').toLowerCase()

    return `${filename}.csv`
}
