import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { useKeyboardShortcutLabel } from 'ui/helpers/useKeyboardShortcutLabel'

import { useCollapsedNavigationTriggerState } from './hooks/useCollapsedNavigationTriggerState'

type CollapsedNavigationTriggerProps = React.ComponentPropsWithoutRef<typeof Button> & {}

export const CollapsedNavigationTrigger: React.FC<CollapsedNavigationTriggerProps> = (props) => {
    const { onClick, isNavigationFloating } = useCollapsedNavigationTriggerState()
    const keyboardShortcutLabel = useKeyboardShortcutLabel({
        mac: '⌘/',
        default: 'Ctrl+/',
    })

    const button = (
        <Button
            variant="ghost"
            size="s"
            startIcon={{ name: 'PanelLeftOpen' }}
            onClick={onClick}
            {...props}
        />
    )

    if (isNavigationFloating) {
        return (
            <Tooltip
                zIndex={200}
                asChild
                align="start"
                side="bottom"
                content={
                    <Box>
                        Lock navigation open{' '}
                        <Body
                            style={{
                                fontSize: 'inherit',
                                lineHeight: 'inherit',
                                fontWeight: 'inherit',
                            }}
                            color="textWeaker"
                        >
                            {keyboardShortcutLabel}
                        </Body>
                    </Box>
                }
            >
                {button}
            </Tooltip>
        )
    }

    return button
}
