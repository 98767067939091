import React from 'react'
import { Redirect } from 'react-router-dom'

import { LoadingSplash } from 'v2/ui'

import { usePortalHomepageState } from './hooks/usePortalHomepageState'

export function PortalHomepage() {
    const { isLoading, defaultStackPath, portalHomePath } = usePortalHomepageState()

    if (defaultStackPath) {
        return <Redirect to={defaultStackPath} />
    }

    if (isLoading) {
        return <LoadingSplash />
    }

    return <Redirect to={portalHomePath} />
}
