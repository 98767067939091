export const iconKeywords = {
    AArrowDown: 'letter font size text formatting smaller',
    AArrowUp: 'letter font size text formatting larger bigger',
    ALargeSmall: 'letter font size text formatting',
    Accessibility: 'disability disabled dda wheelchair',
    Activity:
        'pulse action motion movement exercise fitness healthcare heart rate monitor vital signs vitals emergency room er intensive care hospital defibrillator earthquake siesmic magnitude richter scale aftershock tremor shockwave audio waveform synthesizer synthesiser music',
    AirVent: 'air conditioner ac central air cooling climate-control',
    Airplay: 'stream cast mirroring screen monitor macos osx',
    AlarmClockCheck: 'done todo tick complete task',
    AlarmClockMinus: 'remove',
    AlarmClockOff: 'morning turn-off',
    AlarmClockPlus: 'add',
    AlarmClock: 'morning',
    AlarmSmoke: 'fire alert warning detector carbon monoxide safety equipment amenities',
    Album: 'photo book',
    AlignCenterHorizontal: 'items flex justify',
    AlignCenterVertical: 'items flex justify',
    AlignCenter: 'text alignment center',
    AlignEndHorizontal: 'items bottom flex justify',
    AlignEndVertical: 'items right flex justify',
    AlignHorizontalDistributeCenter: 'items flex justify space evenly around',
    AlignHorizontalDistributeEnd: 'right items flex justify',
    AlignHorizontalDistributeStart: 'left items flex justify',
    AlignHorizontalJustifyCenter: 'center items flex justify',
    AlignHorizontalJustifyEnd: 'right items flex justify',
    AlignHorizontalJustifyStart: 'left items flex justify',
    AlignHorizontalSpaceAround: 'center items flex justify distribute between',
    AlignHorizontalSpaceBetween: 'around items bottom flex justify',
    AlignJustify: 'text alignment justified menu list',
    AlignLeft: 'text alignment left list',
    AlignRight: 'text alignment right',
    AlignStartHorizontal: 'top items flex justify',
    AlignStartVertical: 'left items flex justify',
    AlignVerticalDistributeCenter: 'items flex justify space evenly around',
    AlignVerticalDistributeEnd: 'bottom items flex justify',
    AlignVerticalDistributeStart: 'top items flex justify',
    AlignVerticalJustifyCenter: 'center items flex justify distribute between',
    AlignVerticalJustifyEnd: 'bottom items flex justify distribute between',
    AlignVerticalJustifyStart: 'top items flex justify distribute between',
    AlignVerticalSpaceAround: 'center items flex justify distribute between',
    AlignVerticalSpaceBetween: 'center items flex justify distribute between',
    Ambulance:
        'ambulance emergency medical vehicle siren healthcare transportation rescue urgent first aid',
    Ampersand: 'and typography operator join concatenate code &',
    Ampersands: 'and operator then code &&',
    Amphora: 'pottery artifact artefact vase ceramics clay archaeology museum wine oil',
    Anchor: 'ship',
    Angry: 'emoji anger face emotion',
    Annoyed: 'emoji nuisance face emotion',
    Antenna:
        'signal connection connectivity tv television broadcast live frequency tune scan channels aerial receiver transmission transducer terrestrial satellite cable',
    Anvil: 'metal iron alloy materials heavy weight blacksmith forge acme',
    Aperture: 'camera photo pictures shutter exposure',
    AppWindowMac: 'application menu bar pane preferences macos osx executable',
    AppWindow: 'application menu bar pane executable',
    Apple: 'fruit food',
    ArchiveRestore: 'unarchive index backup box storage records',
    ArchiveX: 'index backup box storage records junk',
    Archive: 'index backup box storage records',
    Armchair: 'sofa furniture leisure lounge loveseat couch',
    ArrowBigDownDash: 'backwards reverse slow direction south download',
    ArrowBigDown: 'backwards reverse direction south',
    ArrowBigLeftDash: 'previous back direction west turn corner',
    ArrowBigLeft: 'previous back direction west indicate turn',
    ArrowBigRightDash: 'next forward direction east turn corner',
    ArrowBigRight: 'next forward direction east indicate turn',
    ArrowBigUpDash:
        'caps lock capitals keyboard button mac forward direction north faster speed boost',
    ArrowBigUp: 'shift keyboard button mac capitalize capitalise forward direction north',
    ArrowDown01: 'filter sort ascending descending increasing decreasing rising falling numerical',
    ArrowDown10: 'filter sort ascending descending increasing decreasing rising falling numerical',
    ArrowDownAZ:
        'filter sort ascending descending increasing decreasing rising falling alphabetical',
    ArrowDownFromLine: 'backwards reverse direction south download expand fold vertical',
    ArrowDownLeft: 'direction south-west diagonal',
    ArrowDownNarrowWide: 'filter sort ascending descending increasing decreasing rising falling',
    ArrowDownRight: 'direction south-east diagonal',
    ArrowDownToDot: 'direction south waypoint location step into',
    ArrowDownToLine:
        'behind direction south download save git version control pull collapse fold vertical',
    ArrowDownUp:
        'bidirectional two-way 2-way swap switch network traffic flow mobile data internet sort reorder move',
    ArrowDownWideNarrow: 'filter sort ascending descending increasing decreasing rising falling',
    ArrowDownZA:
        'filter sort ascending descending increasing decreasing rising falling alphabetical reverse',
    ArrowDown: 'backwards reverse direction south',
    ArrowLeftFromLine: 'previous back direction west expand fold horizontal <-|',
    ArrowLeftRight: 'bidirectional two-way 2-way swap switch transaction reorder move <- ->',
    ArrowLeftToLine: 'previous back direction west collapse fold horizontal |<-',
    ArrowLeft: 'previous back direction west <-',
    ArrowRightFromLine: 'next forward direction east export expand fold horizontal |->',
    ArrowRightLeft: 'bidirectional two-way 2-way swap switch transaction reorder move <- ->',
    ArrowRightToLine:
        'next forward direction east tab keyboard mac indent collapse fold horizontal ->|',
    ArrowRight: 'forward next direction east ->',
    ArrowUp01: 'filter sort ascending descending increasing decreasing rising falling numerical',
    ArrowUp10: 'filter sort ascending descending increasing decreasing rising falling numerical',
    ArrowUpAZ: 'filter sort ascending descending increasing decreasing rising falling alphabetical',
    ArrowUpDown:
        'bidirectional two-way 2-way swap switch network mobile data internet sort reorder move',
    ArrowUpFromDot: 'direction north step out',
    ArrowUpFromLine: 'forward direction north upload git version control push expand fold vertical',
    ArrowUpLeft: 'direction north-west diagonal',
    ArrowUpNarrowWide: 'filter sort ascending descending increasing decreasing rising falling',
    ArrowUpRight: 'direction north-east diagonal',
    ArrowUpToLine: 'forward direction north upload collapse fold vertical',
    ArrowUpWideNarrow: 'filter sort ascending descending increasing decreasing rising falling',
    ArrowUpZA:
        'filter sort ascending descending increasing decreasing rising falling alphabetical reverse',
    ArrowUp: 'forward direction north',
    ArrowsUpFromLine:
        'direction orientation this way up vertical package box fragile postage shipping',
    Asterisk: 'reference times multiply multiplication operator code glob pattern wildcard *',
    AtSign: 'mention at email message @',
    Atom: 'atomic nuclear physics particle element molecule electricity energy chemistry',
    AudioLines:
        'graphic equaliser sound noise listen hearing hertz frequency wavelength vibrate sine synthesizer synthesiser levels track music playback radio broadcast airwaves voice vocals singer song',
    AudioWaveform:
        'sound noise listen hearing hertz frequency wavelength vibrate sine synthesizer synthesiser levels track music playback radio broadcast airwaves voice vocals singer song',
    Award: 'achievement badge rosette prize winner',
    Axe: 'hatchet weapon chop sharp equipment fireman firefighter brigade lumberjack woodcutter logger forestry',
    Axis3d: 'gizmo coordinates',
    Baby: 'child childproof children',
    Backpack: 'bag hiking travel camping school childhood',
    BadgeAlert: 'check verified unverified security safety issue',
    BadgeCent:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment cents dollar usd $ ¢',
    BadgeCheck: 'verified check',
    BadgeDollarSign:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment usd $',
    BadgeEuro:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment €',
    BadgeHelp: 'verified unverified help',
    BadgeIndianRupee:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment inr ₹',
    BadgeInfo: 'verified unverified help',
    BadgeJapaneseYen:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment jpy ¥',
    BadgeMinus: 'verified unverified delete remove erase',
    BadgePercent: 'verified unverified sale discount offer marketing sticker price tag',
    BadgePlus: 'verified unverified add create new',
    BadgePoundSterling:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment british gbp £',
    BadgeRussianRuble:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment rub ₽',
    BadgeSwissFranc:
        'discount offer sale voucher tag monetization marketing finance financial exchange transaction payment chf ₣',
    BadgeX: 'verified unverified lost delete remove',
    Badge: 'check verified unverified',
    BaggageClaim: 'baggage luggage travel cart trolley suitcase',
    Ban: 'cancel no stop forbidden prohibited error incorrect mistake wrong failure circle slash null void',
    Banana: 'fruit food',
    Bandage:
        'plaster band-aid first aid medical health wound injury care treatment healing protection emergency aid safety',
    Banknote: 'currency money payment',
    Barcode: 'scan checkout till cart transaction purchase buy product packaging retail consumer',
    Baseline: 'text format color',
    Bath: 'amenities services bathroom shower',
    BatteryCharging: 'power electricity energy accumulator charge',
    BatteryFull: 'power electricity energy accumulator charge',
    BatteryLow: 'power electricity energy accumulator charge',
    BatteryMedium: 'power electricity energy accumulator charge',
    BatteryWarning: 'power electricity energy accumulator charge exclamation mark',
    Battery: 'power electricity energy accumulator charge',
    Beaker: 'cup lab chemistry experiment test',
    BeanOff: 'soy free legume soy food seed allergy intolerance diet',
    Bean: 'legume soy food seed',
    BedDouble: 'sleep hotel furniture',
    BedSingle: 'sleep hotel furniture',
    Bed: 'sleep hotel furniture',
    Beef: 'food dish restaurant course meal meat bbq steak',
    BeerOff: 'alcohol bar beverage brewery drink',
    Beer: 'alcohol bar beverage brewery drink',
    BellDot: 'alarm notification sound reminder unread',
    BellElectric:
        'fire alarm flames smoke firefighter fireman department brigade station emergency alert safety school bell period break recess doorbell entrance entry ring reception',
    BellMinus: 'alarm notification silent reminder delete remove erase',
    BellOff: 'alarm notification silent reminder',
    BellPlus: 'notification silent reminder add create new',
    BellRing: 'alarm notification sound reminder',
    Bell: 'alarm notification sound reminder',
    BetweenHorizontalEnd:
        'insert add left slot squeeze space vertical grid table rows cells excel spreadsheet accountancy data enter entry entries blocks rectangles chevron',
    BetweenHorizontalStart:
        'insert add right slot squeeze space vertical grid table rows cells excel spreadsheet accountancy data enter entry entries blocks rectangles chevron',
    BetweenVerticalEnd:
        'insert add top slot squeeze space vertical grid table columns cells data enter entry entries blocks rectangles chevron',
    BetweenVerticalStart:
        'insert add bottom slot squeeze space vertical grid table columns cells data enter entry entries blocks rectangles chevron',
    BicepsFlexed: 'arm muscle strong working out athletic toned muscular forelimb curled',
    Bike: 'bicycle transport trip',
    Binary: 'code digits computer zero one boolean',
    Binoculars:
        'field glasses lorgnette pince-nez observation sightseeing nature wildlife birdwatching scouting surveillance search discovery monitoring lookout viewpoint travel tourism research',
    Biohazard: 'fallout waste biology chemistry chemical element',
    Bird: 'peace freedom wing avian tweet',
    Bitcoin: 'currency money payment',
    Blend: 'mode overlay multiply screen opacity transparency alpha filters lenses mixed shades tints hues saturation brightness overlap colors colours',
    Blinds: 'shades screen curtain shutter roller blind window lighting household home',
    Blocks: 'addon plugin integration extension package build stack toys kids children learning',
    BluetoothConnected: 'paired',
    BluetoothOff: 'lost',
    BluetoothSearching: 'pairing',
    Bluetooth: 'wireless',
    Bold: 'text strong format',
    Bolt: 'nut screw settings preferences configuration controls edit diy fixed build contruction parts',
    Bomb: 'fatal error crash blockbuster mine explosion explode explosive',
    Bone: 'health skeleton skull death pets dog',
    BookA: 'dictionary define definition thesaurus encyclopedia encyclopaedia reading booklet magazine leaflet pamphlet tome library writing written writer author story script fiction novel information knowledge education high school university college academy student study learning homework research language translate alphabetical a-z ordered',
    BookAudio:
        'audiobook reading listening sound story fiction novel information knowledge education student study learning research',
    BookCheck:
        'read booklet magazine leaflet pamphlet library written authored published informed knowledgeable educated schooled homework examined tested marked passed graduated studied learned lesson researched documented revealed blank plain language true truth verified corrected task todo done completed finished ticked',
    BookCopy:
        'code coding version control git repository clone fork duplicate multiple books library copies copied plagiarism plagiarised plagiarized reading list information informed knowledge knowledgeable knowledgable education high school university college academy student study learning research smart intelligent intellectual',
    BookDashed:
        'code coding version control git repository template draft script screenplay writing writer author unwritten unpublished untold',
    BookDown: 'code coding version control git repository pull',
    BookHeadphones:
        'audiobook reading listening sound story fiction novel information knowledge education student study learning research',
    BookHeart:
        'diary romance novel journal entry entries personal private secret crush like love emotion feminine girls teens teenager therapy theraputic therapist planner organizer organiser notes notepad stationery sketchbook writing written reading favorite favourite high school',
    BookImage:
        'images pictures photos album collection event magazine catalog catalogue brochure browse gallery',
    BookKey:
        'code coding version control git repository private public secret unlocked hidden revealed knowledge learning',
    BookLock: 'code coding version control git repository private secret hidden knowledge',
    BookMarked:
        'dictionary reading booklet magazine leaflet pamphlet tome library writing written writer author story script fiction novel information knowledge education high school university college academy student study learning homework research documentation saved later future reference index code coding version control git repository',
    BookMinus:
        'code coding version control git repository remove delete censor cancel forbid prohibit ban uneducated re-educate unlearn downgrade',
    BookOpenCheck:
        'read pages booklet magazine leaflet pamphlet library written authored published informed knowledgeable educated schooled homework examined tested marked passed graduated studied learned lesson researched documented revealed blank plain language true truth verified corrected task todo done completed finished ticked',
    BookOpenText:
        'reading pages booklet magazine leaflet pamphlet library writing written writer author story script fiction novel information knowledge education high school university college academy student study learning homework research documentation revealed',
    BookOpen:
        'reading pages booklet magazine leaflet pamphlet library writing written writer author story script screenplay fiction novel information knowledge education high school university college academy student study learning homework research documentation revealed blank plain',
    BookPlus:
        'code coding version control git repository remove delete read write author publish inform graduate re-educate study learn research knowledge improve upgrade level up',
    BookText:
        'reading booklet magazine leaflet pamphlet tome library writing written writer author story script fiction novel information knowledge education high school university college academy student study learning homework research documentation',
    BookType:
        'thesaurus synonym reading booklet magazine leaflet pamphlet tome library writing written writer author story script fiction novel information knowledge education high school university college academy student study learning homework research language translate typography fonts collection',
    BookUp2: 'code coding version control git repository push force',
    BookUp: 'code coding version control git repository push',
    BookUser:
        'person people family friends acquaintances contacts details addresses phone numbers directory listing networking',
    BookX: 'code coding version control git repository remove delete reading misinformation disinformation misinformed charlatan sophistry false lies untruth propaganda censored cancelled forbidden prohibited banned uneducated re-education unlearn',
    Book: 'reading paperback booklet magazine leaflet pamphlet tome library writing written writer author story script fiction novel information knowledge education high school university college academy student study learning homework research documentation',
    BookmarkCheck: 'read finished complete clip marker tag task todo',
    BookmarkMinus: 'delete remove',
    BookmarkPlus: 'add',
    BookmarkX: 'read clip marker tag cancel close delete remove clear',
    Bookmark: 'read clip marker tag',
    BoomBox: 'radio speakers audio music sound broadcast live frequency',
    BotMessageSquare: 'robot ai chat assistant',
    BotOff: 'robot ai chat assistant',
    Bot: 'robot ai chat assistant',
    Box: 'cube package container storage geometry 3d isometric',
    Boxes: 'cubes packages parts group units collection cluster geometry',
    Braces: 'json code token curly brackets data { }',
    Brackets: 'code token array list square [ ]',
    BrainCircuit:
        'mind intellect artificial intelligence ai deep learning machine learning computing',
    BrainCog: 'mind intellect artificial intelligence ai deep learning machine learning computing',
    Brain: 'medical mind intellect cerebral consciousness genius artificial intelligence ai',
    BrickWall:
        'bricks mortar cement materials construction builder labourer quantity surveyor blocks stone',
    BriefcaseBusiness: 'work bag baggage folder portfolio',
    BriefcaseConveyorBelt: 'baggage luggage travel suitcase conveyor carousel',
    BriefcaseMedical: 'doctor medicine first aid',
    Briefcase: 'work bag baggage folder',
    BringToFront: 'bring send move over forward front overlap layer order',
    Brush: 'draw paint color artist',
    BugOff: 'debug code insect kill exterminate pest control',
    BugPlay: 'debug code insect',
    Bug: 'issue report debug code insect',
    Building2: 'business company enterprise skyscraper organisation organization',
    Building: 'organisation organization',
    BusFront: 'coach vehicle trip road',
    Bus: 'bus vehicle transport trip',
    CableCar: 'ski lift winter holiday alpine resort mountains',
    Cable: 'cord wire connector connection link signal console computer equipment electricity energy electronics recharging charger power supply disconnected unplugged plugs interface input output audio video av rca scart tv television optical',
    CakeSlice:
        'birthday birthdate celebration party surprise gateaux dessert candles wish fondant icing sugar sweet baking',
    Cake: 'birthday birthdate celebration party surprise gateaux dessert fondant icing sugar sweet baking',
    Calculator: 'count calculating machine',
    Calendar1: 'date month year event single singular once 1 first',
    CalendarArrowDown:
        'date month year event sort order ascending descending increasing decreasing rising falling',
    CalendarArrowUp:
        'date month year event sort order ascending descending increasing decreasing rising falling',
    CalendarCheck2:
        'date day month year event confirm subscribe schedule done todo tick complete task',
    CalendarCheck:
        'date day month year event confirm subscribe schedule done todo tick complete task',
    CalendarClock: 'date day month year event clock hour',
    CalendarCog: 'date day month year events settings gear cog',
    CalendarDays: 'date month year event',
    CalendarFold: 'date month year event birthday birthdate ics',
    CalendarHeart: 'date month year event heart favourite subscribe valentines day',
    CalendarMinus2: 'date day month year event delete remove',
    CalendarMinus: 'date day month year event delete remove',
    CalendarOff: 'date day month year event delete remove',
    CalendarPlus2: 'date day month year event add subscribe create new',
    CalendarPlus: 'date day month year event add subscribe create new',
    CalendarRange: 'date day month year event range period',
    CalendarSearch: 'date day month year events search',
    CalendarSync:
        'repeat refresh reconnect transfer backup date month year event subscribe recurring schedule reminder automatic auto',
    CalendarX2: 'date day month year event remove',
    CalendarX: 'date day month year event remove busy',
    Calendar: 'date month year event birthday birthdate',
    CameraOff: 'photo webcam video',
    Camera: 'photo webcam video',
    CandyCane: 'sugar food sweet christmas xmas',
    CandyOff: 'sugar free food sweet allergy intolerance diet',
    Candy: 'sugar food sweet',
    Cannabis: 'cannabis weed leaf',
    CaptionsOff:
        'closed captions subtitles subhead transcription transcribe dialogue accessibility',
    Captions: 'closed captions subtitles subhead transcription transcribe dialogue accessibility',
    CarFront: 'vehicle drive trip journey',
    CarTaxiFront: 'cab vehicle drive trip journey',
    Car: 'vehicle drive trip journey',
    Caravan: 'trailer tow camping campsite mobile home holiday nomadic wilderness outdoors',
    Carrot: 'vegetable food eat',
    CaseLower: 'text letters characters font typography',
    CaseSensitive: 'text letters characters font typography',
    CaseUpper: 'text letters characters font typography',
    CassetteTape: 'audio music recording play',
    Cast: 'chromecast airplay screen',
    Castle: 'fortress stronghold palace chateau building',
    Cat: 'animal pet kitten feline',
    Cctv: 'camera surveillance recording film videotape crime watching',
    ChartArea: 'statistics analytics diagram graph area',
    ChartBarBig: 'statistics analytics diagram graph',
    ChartBarDecreasing: 'statistics analytics diagram graph trending down',
    ChartBarIncreasing: 'statistics analytics diagram graph trending up',
    ChartBarStacked: 'statistics analytics diagram graph multivariate categorical comparison',
    ChartBar: 'statistics analytics diagram graph',
    ChartCandlestick:
        'trading trader financial markets portfolio assets prices value valuation commodities currencies currency exchange hedge fund statistics analytics diagram graph',
    ChartColumnBig: 'statistics analytics diagram graph',
    ChartColumnDecreasing: 'statistics analytics diagram graph trending down',
    ChartColumnIncreasing: 'statistics analytics diagram graph trending up',
    ChartColumnStacked: 'statistics analytics diagram graph multivariate categorical comparison',
    ChartColumn: 'statistics analytics diagram graph',
    ChartGantt: 'diagram graph timeline planning',
    ChartLine: 'statistics analytics diagram graph',
    ChartNetwork: 'statistics analytics diagram graph topology cluster web nodes connections edges',
    ChartNoAxesColumnDecreasing: 'statistics analytics diagram graph trending down',
    ChartNoAxesColumnIncreasing: 'statistics analytics diagram graph trending up',
    ChartNoAxesColumn: 'statistics analytics diagram graph',
    ChartNoAxesCombined: 'statistics analytics diagram graph trending up',
    ChartNoAxesGantt:
        'projects manage overview roadmap plan intentions timeline deadline date event range period productivity work agile code coding',
    ChartPie: 'statistics analytics diagram presentation',
    ChartScatter: 'statistics analytics diagram graph',
    ChartSpline:
        'statistics analytics diagram graph curve continuous smooth polynomial quadratic function interpolation',
    CheckCheck: 'done received double todo tick complete task',
    Check: 'done todo tick complete task',
    ChefHat: 'cooking food kitchen restaurant',
    Cherry: 'fruit food',
    ChevronDown: 'backwards reverse slow dropdown',
    ChevronFirst: 'previous music',
    ChevronLast: 'skip next music',
    ChevronLeft: 'back previous less than fewer menu <',
    ChevronRight:
        'forward next more than greater menu code coding command line terminal prompt shell >',
    ChevronUp:
        'caret keyboard mac control ctrl superscript exponential power ahead fast ^ dropdown',
    ChevronsDownUp: 'collapse fold vertical',
    ChevronsDown: 'backwards reverse slower',
    ChevronsLeftRightEllipsis:
        'internet network connection cable lan port router switch hub modem web online networking communication socket plug slot controller connector interface console signal data input output',
    ChevronsLeftRight: 'expand horizontal unfold',
    ChevronsLeft: 'turn corner',
    ChevronsRightLeft: 'collapse fold horizontal',
    ChevronsRight: 'turn corner',
    ChevronsUpDown: 'expand unfold vertical',
    ChevronsUp: 'forward ahead faster speed boost',
    Chrome: 'browser logo',
    Church: 'temple building',
    CigaretteOff: 'smoking no-smoking',
    Cigarette: 'smoking',
    CircleAlert: 'warning alert danger exclamation mark',
    CircleArrowDown: 'backwards reverse direction south sign button',
    CircleArrowLeft: 'previous back direction west sign turn button <-',
    CircleArrowOutDownLeft: 'outwards direction south-west diagonal',
    CircleArrowOutDownRight: 'outwards direction south-east diagonal',
    CircleArrowOutUpLeft: 'outwards direction north-west diagonal keyboard button escape',
    CircleArrowOutUpRight: 'outwards direction north-east diagonal',
    CircleArrowRight: 'next forward direction east sign turn button ->',
    CircleArrowUp: 'forward direction north sign button',
    CircleCheckBig: 'done todo tick complete task',
    CircleCheck: 'done todo tick complete task',
    CircleChevronDown: 'back menu',
    CircleChevronLeft: 'back previous less than fewer menu <',
    CircleChevronRight: 'back more than greater menu >',
    CircleChevronUp: 'caret ahead menu ^',
    CircleDashed: 'pending dot progress issue draft code coding version control',
    CircleDivide: 'calculate maths ÷ /',
    CircleDollarSign: 'monetization marketing currency money payment',
    CircleDotDashed: 'pending dot progress issue draft code coding version control',
    CircleDot:
        'pending dot progress issue code coding version control choices multiple choice choose',
    CircleEllipsis:
        'ellipsis et cetera etc loader loading progress pending throbber menu options operator code spread rest more further extra overflow dots … ...',
    CircleEqual: 'calculate shape =',
    CircleFadingArrowUp: 'north up upgrade improve circle button',
    CircleFadingPlus: 'stories social media instagram facebook meta snapchat sharing content',
    CircleGauge: 'dashboard dial meter speed pressure measure level',
    CircleHelp: 'question mark',
    CircleMinus:
        'subtract remove decrease reduce calculate line operator code coding minimum downgrade -',
    CircleOff:
        'diameter zero Ø nothing null void cancel ban no stop forbidden prohibited error incorrect mistake wrong failure',
    CircleParkingOff: 'parking lot car park no parking',
    CircleParking: 'parking lot car park',
    CirclePause: 'music audio stop',
    CirclePercent: 'verified unverified sale discount offer marketing sticker price tag',
    CirclePlay: 'music start run',
    CirclePlus:
        'add new increase increment positive calculate crosshair aim target scope sight reticule maximum upgrade extra operator join concatenate code coding +',
    CirclePower:
        'on off device switch toggle binary boolean reboot restart button keyboard troubleshoot',
    CircleSlash2: 'diameter zero Ø nothing null void ban maths divide division half split /',
    CircleSlash:
        'diameter zero Ø nothing null void cancel ban no stop forbidden prohibited error incorrect mistake wrong failure divide division or /',
    CircleStop: 'media music',
    CircleUserRound: 'person account contact',
    CircleUser: 'person account contact',
    CircleX:
        'cancel close delete remove times clear error incorrect wrong mistake failure linter multiply multiplication',
    Circle: 'off zero record shape',
    CircuitBoard: 'computing electricity electronics',
    Citrus: 'lemon orange grapefruit fruit',
    Clapperboard: 'movie film video camera cinema cut action television tv show entertainment',
    ClipboardCheck: 'copied pasted done todo tick complete task',
    ClipboardCopy: 'copy paste',
    ClipboardList: 'copy paste tasks',
    ClipboardMinus: 'copy delete remove erase document medical report doctor',
    ClipboardPaste: 'copy paste',
    ClipboardPenLine: 'paste',
    ClipboardPen: 'paste signature',
    ClipboardPlus: 'copy paste add create new document medical report doctor',
    ClipboardType: 'paste format text',
    ClipboardX: 'copy paste discard remove',
    Clipboard: 'copy paste',
    Clock1: 'time watch alarm',
    Clock10: 'time watch alarm',
    Clock11: 'time watch alarm',
    Clock12: 'time watch alarm noon midnight',
    Clock2: 'time watch alarm',
    Clock3: 'time watch alarm',
    Clock4: 'time watch alarm',
    Clock5: 'time watch alarm',
    Clock6: 'time watch alarm',
    Clock7: 'time watch alarm',
    Clock8: 'time watch alarm',
    Clock9: 'time watch alarm',
    ClockAlert: 'time watch alarm warning wrong',
    ClockArrowDown:
        'time watch alarm sort order ascending descending increasing decreasing rising falling',
    ClockArrowUp:
        'time watch alarm sort order ascending descending increasing decreasing rising falling',
    Clock: 'time watch alarm',
    CloudAlert: 'weather danger warning alert error sync network exclamation',
    CloudCog: 'computing ai cluster network',
    CloudDownload: 'import',
    CloudDrizzle: 'weather shower',
    CloudFog: 'weather mist',
    CloudHail: 'weather rainfall',
    CloudLightning: 'weather bolt',
    CloudMoonRain: 'weather partly night rainfall',
    CloudMoon: 'weather night',
    CloudOff: 'disconnect',
    CloudRainWind: 'weather rainfall',
    CloudRain: 'weather rainfall',
    CloudSnow: 'weather blizzard',
    CloudSunRain: 'weather partly rainfall',
    CloudSun: 'weather partly',
    CloudUpload: 'file',
    Cloud: 'weather',
    Cloudy: 'weather clouds',
    Clover: 'leaf luck plant',
    Club: 'shape suit playing cards',
    CodeXml: 'source programming html xml',
    Code: 'source programming html xml',
    Codepen: 'logo',
    Codesandbox: 'logo',
    Coffee: 'drink cup mug tea cafe hot beverage',
    Cog: 'computing settings cog edit gear preferences',
    Coins: 'money cash finance gamble',
    Columns2:
        'lines list queue preview panel parallel series split vertical horizontal half center middle even sidebar drawer gutter fold reflow typography pagination pages',
    Columns3:
        'lines list queue preview parallel series split vertical horizontal thirds triple center middle alignment even sidebars drawers gutters fold reflow typography pagination pages',
    Columns4:
        'lines list queue preview parallel series split vertical horizontal thirds triple center middle alignment even sidebars drawers gutters fold reflow typography pagination pages prison jail bars sentence police cops cell crime criminal justice law enforcement grill',
    Combine: 'cubes packages parts units collection cluster combine gather merge',
    Command: 'keyboard key mac cmd button',
    Compass: 'direction north east south west safari browser',
    Component: 'design element group module part symbol',
    Computer: 'pc chassis codespaces github',
    ConciergeBell: 'reception bell porter',
    Cone: 'conical triangle triangular geometry filter funnel hopper spotlight searchlight',
    Construction: 'roadwork maintenance blockade barricade',
    ContactRound: 'user person family friend acquaintance listing networking',
    Contact: 'user person family friend acquaintance listing networking',
    Container: 'storage shipping freight supply chain docker environment devops code coding',
    Contrast: 'display accessibility',
    Cookie: 'biscuit privacy legal food',
    CookingPot: 'pod cooking recipe food kitchen chef restaurant dinner lunch breakfast meal eat',
    CopyCheck: 'clone duplicate done multiple',
    CopyMinus: 'clone duplicate remove delete collapse subtract multiple -',
    CopyPlus: 'clone duplicate add multiple expand +',
    CopySlash:
        'clone duplicate cancel ban no stop forbidden prohibited error multiple divide division split or /',
    CopyX: 'cancel close delete remove clear multiple multiply multiplication times',
    Copy: 'clone duplicate multiple',
    Copyleft: 'licence',
    Copyright: 'licence license',
    CornerDownLeft: 'arrow return',
    CornerDownRight: 'arrow indent tab',
    CornerLeftDown: 'arrow',
    CornerLeftUp: 'arrow',
    CornerRightDown: 'arrow',
    CornerRightUp: 'arrow',
    CornerUpLeft: 'arrow',
    CornerUpRight: 'arrow',
    Cpu: 'processor cores technology computer chip circuit memory ram specs gigahertz ghz',
    CreativeCommons: 'licence license',
    CreditCard: 'bank purchase payment cc',
    Croissant: 'bakery cooking food pastry',
    Crop: 'photo image',
    Cross: 'healthcare first aid',
    Crosshair: 'aim target',
    Crown: 'diadem tiara circlet corona king ruler winner favourite',
    Cuboid: 'brick block container storage geometry rectangular hexahedron',
    CupSoda: 'beverage cup drink soda straw water',
    Currency: 'finance money',
    Cylinder: 'shape elliptical geometry container storage tin pot',
    Dam: 'electricity energy water',
    DatabaseBackup: 'storage memory bytes servers backup timemachine rotate arrow left',
    DatabaseZap: 'cache busting storage memory bytes servers power crash',
    Database: 'storage memory container tin pot bytes servers',
    Delete: 'backspace remove',
    Dessert:
        'pudding christmas xmas custard iced bun icing fondant cake ice cream gelato sundae scoop dollop sugar food sweet',
    Diameter: 'shape circle geometry trigonometry width height size calculate measure',
    DiamondMinus:
        'keyframe subtract remove decrease reduce calculator button keyboard line divider separator horizontal rule hr html markup markdown --- toolbar operator code coding minimum downgrade',
    DiamondPercent: 'verified unverified sale discount offer marketing sticker price tag',
    DiamondPlus:
        'keyframe add new increase increment positive calculate toolbar crosshair aim target scope sight reticule maximum upgrade extra +',
    Diamond: 'square rectangle oblique rhombus shape suit playing cards',
    Dice1: 'dice random tabletop 1 board game',
    Dice2: 'dice random tabletop 2 board game',
    Dice3: 'dice random tabletop 3 board game',
    Dice4: 'dice random tabletop 4 board game',
    Dice5: 'dice random tabletop 5 board game',
    Dice6: 'dice random tabletop 6 board game',
    Dices: 'dice random tabletop board game',
    Diff: 'patch difference compare plus minus plus-minus maths',
    Disc2: 'album music vinyl record cd dvd format dj spin rotate rpm',
    Disc3: 'album music vinyl record cd dvd format dj spin rotate rpm',
    DiscAlbum:
        'album music songs format cd dvd vinyl sleeve cover platinum compilation ep recording playback spin rotate rpm dj',
    Disc: 'album music songs format cd dvd vinyl sleeve cover platinum compilation ep recording playback spin rotate rpm dj',
    Divide: 'calculate maths division operator code ÷ /',
    DnaOff: 'gene gmo free helix heredity chromosome nucleic acid',
    Dna: 'gene gmo helix heredity chromosome nucleic acid',
    Dock: 'desktop applications launch home menu bar bottom line macos osx',
    Dog: 'animal pet puppy hound canine',
    DollarSign: 'currency money payment',
    Donut: 'doughnut sprinkles topping fast food junk food snack treat sweet sugar dessert hollow ring',
    DoorClosed: 'entrance entry exit ingress egress gate gateway emergency exit',
    DoorOpen: 'entrance entry exit ingress egress gate gateway emergency exit',
    Dot: 'interpunct interpoint middot step punctuation period full stop end finish final characters font typography type center .',
    Download: 'import export save',
    DraftingCompass:
        'geometry trigonometry radius diameter circumference calculate measure arc curve draw sketch',
    Drama: 'drama masks theater theatre entertainment show',
    Dribbble: 'design social',
    Drill: 'power bit head hole diy toolbox build construction',
    Droplet: 'water weather liquid fluid wet moisture damp bead globule',
    Droplets: 'water weather liquid fluid wet moisture damp bead globule',
    Drum: 'drummer kit sticks instrument beat bang bass backing track band play performance concert march music audio sound noise loud',
    Drumstick: 'food chicken meat',
    Dumbbell: 'barbell weight workout gym',
    EarOff: 'hearing hard of hearing hearing loss deafness noise silence audio accessibility',
    Ear: 'hearing noise audio accessibility',
    EarthLock:
        'vpn private privacy network world browser security encryption protection connection',
    Earth: 'world browser language translate globe',
    Eclipse:
        'lunar solar crescent moon sun earth day night planet space mode dark light toggle switch color css styles display accessibility contrast brightness blend shade',
    EggFried: 'food breakfast',
    EggOff: 'egg free vegan hatched bad egg',
    Egg: 'bird chicken nest hatch shell incubate soft boiled hard breakfast brunch morning easter',
    EllipsisVertical: 'menu options spread more further extra overflow dots … ...',
    Ellipsis:
        'et cetera etc loader loading progress pending throbber menu options operator code coding spread rest more further extra overflow dots … ...',
    EqualApproximately: 'about calculate maths operater',
    EqualNot: 'calculate off maths operator code ≠',
    Equal: 'calculate maths operator assignment code =',
    Eraser: 'pencil drawing undo delete clear',
    EthernetPort:
        'internet network connection cable lan port router switch hub modem web online networking communication socket plug slot controller connector interface console signal data input output',
    Euro: 'currency money payment',
    Expand: 'scale fullscreen',
    ExternalLink: 'outbound open share',
    EyeClosed: 'view watch see hide conceal mask hidden visibility vision',
    EyeOff: 'view watch see hide conceal mask hidden visibility vision',
    Eye: 'view watch see show expose reveal display visible visibility vision preview read',
    Facebook: 'logo social',
    Factory: 'building business energy industry manufacture sector',
    Fan: 'air cooler ventilation ventilator blower',
    FastForward: 'music',
    Feather: 'logo',
    Fence: 'picket panels woodwork diy materials suburban garden property territory',
    FerrisWheel:
        'big wheel daisy wheel observation attraction entertainment amusement park theme park funfair',
    Figma: 'logo design tool',
    FileArchive: 'zip package archive',
    FileAudio2: 'music audio sound headphones',
    FileAudio: 'music audio sound headphones',
    FileAxis3d: 'model 3d axis coordinates',
    FileBadge2: 'award achievement badge rosette prize winner',
    FileBadge: 'award achievement badge rosette prize winner',
    FileBox: 'box package model',
    FileChartColumnIncreasing: 'statistics analytics diagram graph presentation trending up',
    FileChartColumn: 'statistics analytics diagram graph presentation',
    FileChartLine: 'statistics analytics diagram graph presentation',
    FileChartPie: 'statistics analytics diagram graph presentation',
    FileCheck2: 'done document todo tick complete task',
    FileCheck: 'done document todo tick complete task',
    FileClock: 'history log clock',
    FileCode2: 'script document html xml property list plist',
    FileCode: 'script document gist html xml property list plist',
    FileCog: 'executable settings cog edit gear',
    FileDiff: 'diff patch',
    FileDigit: 'number document',
    FileDown: 'download import export',
    FileHeart: 'heart favourite bookmark quick link',
    FileImage: 'image graphics photo picture',
    FileInput: 'document',
    FileJson2: 'code json curly braces curly brackets',
    FileJson: 'code json curly braces curly brackets',
    FileKey2: 'key private public security',
    FileKey: 'key private public security',
    FileLock2: 'lock password security',
    FileLock: 'lock password security',
    FileMinus2: 'document',
    FileMinus: 'delete remove erase document',
    FileMusic:
        'audio sound noise track digital recording playback piano keyboard keys notes chord midi octave',
    FileOutput: 'document',
    FilePenLine: 'edit',
    FilePen: 'signature',
    FilePlus2: 'add create new document',
    FilePlus: 'add create new document',
    FileQuestion: 'readme help question',
    FileScan: 'scan code qr-code',
    FileSearch2: 'lost document find browser',
    FileSearch: 'lost document find browser',
    FileSliders:
        'cogged gear mechanical machinery configuration controls preferences settings system admin edit executable',
    FileSpreadsheet: 'spreadsheet sheet table',
    FileStack: 'versions multiple copy documents revisions version control history',
    FileSymlink: 'symlink symbolic link',
    FileTerminal: 'terminal bash script executable',
    FileText: 'data txt pdf document',
    FileType2: 'font text typography type',
    FileType: 'font text typography type',
    FileUp: 'upload import export',
    FileUser:
        'person personal information people listing networking document contact cover letter resume cv curriculum vitae application form',
    FileVideo2: 'movie video film',
    FileVideo: 'movie video film',
    FileVolume2: 'audio music volume',
    FileVolume: 'audio music volume',
    FileWarning: 'hidden warning alert danger protected exclamation mark',
    FileX2: 'lost delete remove document',
    FileX: 'lost delete remove document',
    File: 'document',
    Files: 'multiple copy documents',
    Film: 'movie video reel camera cinema entertainment',
    FilterX: 'funnel hopper',
    Filter: 'funnel hopper',
    Fingerprint: '2fa authentication biometric identity security',
    FireExtinguisher:
        'flames smoke foam water spray hose firefighter fireman department brigade station emergency suppress compressed tank cylinder safety equipment amenities',
    FishOff:
        'food dish restaurant course meal seafood animal pet sea marine allergy intolerance diet',
    FishSymbol: 'dish restaurant course meal seafood pet sea marine',
    Fish: 'dish restaurant course meal seafood pet sea marine',
    FlagOff: 'unflag',
    FlagTriangleLeft: 'report timeline',
    FlagTriangleRight: 'report timeline',
    Flag: 'report',
    FlameKindling: 'campfire camping wilderness outdoors lit warmth wood twigs sticks',
    Flame: 'fire lit burning spark embers smoke firefighter fireman department brigade station emergency',
    FlashlightOff: 'torch',
    Flashlight: 'torch',
    FlaskConicalOff: 'beaker erlenmeyer non toxic lab chemistry experiment test',
    FlaskConical: 'beaker erlenmeyer lab chemistry experiment test',
    FlaskRound: 'beaker lab chemistry experiment test',
    FlipHorizontal2: 'reflect mirror alignment dashed',
    FlipHorizontal: 'reflect mirror alignment dashed',
    FlipVertical2: 'reflect mirror alignment dashed',
    FlipVertical: 'reflect mirror alignment dashed',
    Flower2: 'sustainability nature plant',
    Flower: 'sustainability nature plant spring',
    Focus: 'camera lens photo dashed',
    FoldHorizontal: 'arrow collapse fold vertical dashed',
    FoldVertical: 'arrow collapse fold vertical dashed',
    FolderArchive: 'archive zip package',
    FolderCheck: 'done directory todo tick complete task',
    FolderClock: 'history directory clock',
    FolderClosed: 'directory closed',
    FolderCode: 'directory coding develop software',
    FolderCog: 'directory settings control preferences cog edit gear',
    FolderDot:
        'directory root project pinned active current cogged gear mechanical machinery configuration controls preferences settings system admin edit',
    FolderDown: 'directory download import export',
    FolderGit2: 'directory root project git repo',
    FolderGit: 'directory root project git repo',
    FolderHeart: 'directory heart favourite bookmark quick link',
    FolderInput: 'directory import export',
    FolderKanban:
        'projects manage overview board tickets issues roadmap plan intentions productivity work agile code coding directory project root',
    FolderKey: 'directory key private security protected',
    FolderLock: 'directory lock private security protected',
    FolderMinus: 'directory remove delete',
    FolderOpenDot: 'directory root project active current pinned',
    FolderOpen: 'directory',
    FolderOutput: 'directory import export',
    FolderPen: 'directory rename',
    FolderPlus: 'directory add create new',
    FolderRoot: 'directory root project git repo',
    FolderSearch2: 'directory search find lost browser',
    FolderSearch: 'directory search find lost browser',
    FolderSymlink: 'directory symlink symbolic link',
    FolderSync: 'directory synchronize synchronise refresh reconnect transfer backup',
    FolderTree: 'directory tree browser',
    FolderUp: 'directory upload import export',
    FolderX: 'directory remove delete',
    Folder: 'directory',
    Folders: 'multiple copy directories',
    Footprints: 'steps walking foot feet trail shoe',
    Forklift: 'vehicle transport logistics',
    Forward: 'send share email',
    Frame: 'logo design tool',
    Framer: 'logo design tool',
    Frown: 'emoji face bad sad emotion',
    Fuel: 'filling-station gas petrol tank',
    Fullscreen: 'expand zoom preview focus camera lens image',
    GalleryHorizontalEnd:
        'carousel pictures images scroll swipe album portfolio history versions backup time machine',
    GalleryHorizontal: 'carousel pictures images scroll swipe album portfolio',
    GalleryThumbnails: 'carousel pictures images album portfolio preview',
    GalleryVerticalEnd:
        'carousel pictures images scroll swipe album portfolio history versions backup time machine',
    GalleryVertical: 'carousel pictures images scroll swipe album portfolio',
    Gamepad2: 'console',
    Gamepad: 'console',
    Gauge: 'dashboard dial meter speed pressure measure level',
    Gavel: 'hammer mallet',
    Gem: 'diamond crystal ruby jewellery price special present gift ring wedding proposal marriage rubygems',
    Ghost: 'pac-man spooky',
    Gift: 'present box birthday party',
    GitBranchPlus: 'add create +',
    GitBranch: 'code version control',
    GitCommitHorizontal: 'code version control waypoint stop station',
    GitCommitVertical: 'code version control waypoint stop station',
    GitCompareArrows: 'code version control diff',
    GitCompare: 'code version control diff',
    GitFork: 'code version control',
    GitGraph: 'code version control commit graph commits gitlens',
    GitMerge: 'code version control',
    GitPullRequestArrow: 'code version control open',
    GitPullRequestClosed: 'code version control rejected closed cancelled x',
    GitPullRequestCreateArrow: 'code version control open plus add +',
    GitPullRequestCreate: 'code version control open plus add +',
    GitPullRequestDraft: 'code version control open draft dashed',
    GitPullRequest: 'code version control open',
    Github: 'logo version control',
    Gitlab: 'logo version control',
    GlassWater: 'beverage drink glass water',
    Glasses: 'glasses spectacles',
    GlobeLock:
        'vpn private privacy network world browser security encryption protection connection',
    Globe: 'world browser language translate',
    Goal: 'flag bullseye',
    Grab: 'hand',
    GraduationCap:
        'school university learn study mortarboard education ceremony academic hat diploma doctorate',
    Grape: 'fruit wine food',
    Grid2x2Check:
        'table rows columns blocks plot land geometry measure data size width height distance surface area square meter acre',
    Grid2x2Plus:
        'table rows columns blocks plot land geometry measure data size width height distance surface area square meter acre',
    Grid2x2X:
        'table rows columns data blocks plot land geometry measure size width height distance surface area square meter acre',
    Grid2x2:
        'table rows columns blocks plot land geometry measure size width height distance surface area square meter acre window skylight',
    Grid3x3: 'table rows columns',
    GripHorizontal: 'grab dots handle move drag',
    GripVertical: 'grab dots handle move drag',
    Grip: 'grab dots handle move drag',
    Group: 'cubes packages parts units collection cluster gather dashed',
    Guitar: 'acoustic instrument strings riff rock band country concert performance play lead loud music audio sound noise',
    Ham: 'food pork pig meat bone hock knuckle gammon cured',
    Hammer: 'mallet nails diy toolbox build construction',
    HandCoins:
        'savings banking money finance offers mortgage payment received wage payroll allowance pocket money handout pennies',
    HandHeart: 'love like emotion',
    HandHelping: 'agreement help proposal charity begging terms',
    HandMetal: 'rock',
    HandPlatter: 'waiter waitress restaurant table service served dinner dining meal course luxury',
    Hand: 'wave move mouse grab',
    Handshake: 'agreement partnership deal business assistance cooperation friendship union terms',
    HardDriveDownload: 'computer server memory data ssd disk hard disk save',
    HardDriveUpload: 'computer server memory data ssd disk hard disk save',
    HardDrive: 'computer server memory data ssd disk hard disk',
    HardHat: 'helmet construction safety savety',
    Hash: 'hashtag number pound',
    Haze: 'mist fog',
    HdmiPort:
        'socket plug slot controller connector interface console signal audio video visual av data input output',
    Heading1: 'h1 html markup markdown',
    Heading2: 'h2 html markup markdown',
    Heading3: 'h3 html markup markdown',
    Heading4: 'h4 html markup markdown',
    Heading5: 'h5 html markup markdown',
    Heading6: 'h6 html markup markdown',
    Heading: 'h1 html markup markdown',
    HeadphoneOff: 'music audio sound mute off',
    Headphones: 'music audio sound',
    Headset: 'music audio sound gaming headphones headset call center phone telephone voip video',
    HeartCrack: 'heartbreak sadness emotion',
    HeartHandshake: 'agreement charity help deal terms emotion together handshake',
    HeartOff: 'unlike dislike hate emotion',
    HeartPulse: 'heartbeat pulse health medical blood pressure cardiac systole diastole',
    Heart: 'like love emotion suit playing cards',
    Heater: 'heating warmth comfort fire stove electric electronics amenities',
    Hexagon: 'shape node.js logo',
    Highlighter: 'mark text',
    History: 'time redo undo rewind timeline version time machine backup rotate ccw',
    HopOff: 'beer brewery drink hop free allergy intolerance diet',
    Hop: 'beer brewery drink',
    Hospital:
        'infirmary sanatorium healthcare doctor hospice clinic emergency room ward building medical vet',
    Hotel: 'building hostel motel inn',
    Hourglass: 'timer time sandglass',
    HousePlug: 'home living building residence architecture autarky energy',
    HousePlus: 'home living medical new addition building residence architecture',
    House: 'home living building residence architecture',
    IceCreamBowl: 'gelato food dessert dish restaurant course meal',
    IceCreamCone: 'gelato food',
    IdCard: 'card badge identity authentication secure',
    ImageDown: 'picture photo download save export',
    ImageMinus: 'remove delete',
    ImageOff: 'picture photo',
    ImagePlay: 'picture gif photo',
    ImagePlus: 'add create picture',
    ImageUp: 'picture photo upload import',
    ImageUpscale: 'resize picture sharpen increase',
    Image: 'picture photo',
    Images: 'picture photo multiple copy gallery album collection slideshow showcase',
    Import: 'save',
    Inbox: 'email',
    IndentDecrease: 'text tab',
    IndentIncrease: 'text tab',
    IndianRupee: 'currency money payment',
    Infinity: 'unlimited forever loop maths',
    Info: 'help',
    InspectionPanel: 'access cover tile metal materials screws',
    Instagram: 'logo camera social',
    Italic: 'oblique text format',
    IterationCcw: 'arrow right',
    IterationCw: 'arrow left',
    JapaneseYen: 'currency money payment',
    Joystick: 'game console control stick',
    Kanban: 'projects manage overview board tickets issues roadmap plan intentions productivity work agile code coding',
    KeyRound: 'password login authentication secure unlock',
    KeySquare: 'password login authentication secure unlock car key',
    Key: 'password login authentication secure unlock keychain key ring fob',
    KeyboardMusic:
        'music audio sound noise notes keys chord octave midi controller instrument electric signal digital studio production producer pianist piano play performance concert',
    KeyboardOff: 'unkeys layout spell settings mouse',
    Keyboard: 'layout spell settings mouse',
    LampCeiling: 'lighting household home furniture',
    LampDesk: 'lighting household office desk home furniture',
    LampFloor: 'lighting household floor home furniture',
    LampWallDown: 'lighting household wall home furniture',
    LampWallUp: 'lighting household wall home furniture',
    Lamp: 'lighting household home furniture',
    LandPlot:
        'area surface square metres allotment parcel property plane acres measure distance isometric flag golf course hole',
    Landmark: 'bank building capitol finance money',
    Languages: 'translate',
    LaptopMinimalCheck: 'computer screen remote success done todo tick complete task',
    LaptopMinimal: 'computer screen remote',
    Laptop: 'computer screen remote',
    LassoSelect: 'select cursor',
    Lasso: 'select cursor',
    Laugh: 'emoji face happy good emotion',
    Layers2: 'stack pile pages sheets paperwork copies copy duplicate double shortcuts',
    Layers3: 'stack pile pages sheets paperwork copies copy duplicate triple',
    Layers: 'stack pile pages sheets paperwork copies copy',
    LayoutDashboard: 'masonry brick',
    LayoutGrid: 'app home start',
    LayoutList: 'todo tasks items pending image photo',
    LayoutPanelLeft: 'app home start grid',
    LayoutPanelTop: 'window webpage block section grid template structure',
    LayoutTemplate: 'window webpage block section',
    Leaf: 'sustainability nature energy plant autumn',
    LeafyGreen: 'salad lettuce vegetable chard cabbage bok choy',
    Lectern: 'pulpit podium stand',
    LetterText: 'drop cap text format typography letter font size',
    LibraryBig:
        'books reading written authors stories fiction novels information knowledge education high school university college academy learning study research collection vinyl records albums music package',
    Library:
        'books reading written authors stories fiction novels information knowledge education high school university college academy learning study research collection vinyl records albums music package',
    LifeBuoy: 'preserver life belt lifesaver help rescue ship ring raft inflatable wheel donut',
    Ligature: 'text font typography alternates alternatives',
    LightbulbOff: 'lights',
    Lightbulb: 'idea bright lights',
    Link2Off: 'unchain chain',
    Link2: 'chain url',
    Link: 'chain url',
    Linkedin: 'logo social media social',
    ListCheck: 'done check tick complete list to-do bom',
    ListChecks: 'todo done check tick complete tasks items pending',
    ListCollapse:
        'items collapse expand details disclosure show hide toggle accordion more less fold unfold',
    ListEnd: 'queue bottom end playlist',
    ListFilter: 'options',
    ListMinus: 'playlist remove song subtract delete unqueue',
    ListMusic: 'playlist queue music audio playback',
    ListOrdered: 'number order queue',
    ListPlus: 'playlist add song track new',
    ListRestart: 'reset refresh reload playlist replay',
    ListStart: 'queue top start next playlist',
    ListTodo: 'todo done check tick complete tasks items pending',
    ListTree: 'tree browser',
    ListVideo: 'playlist video playback',
    ListX: 'playlist subtract remove delete unqueue',
    List: 'options',
    LoaderCircle: 'loading wait busy progress spinner spinning throbber circle',
    LoaderPinwheel: 'loading wait busy progress throbber spinner spinning beach ball frozen freeze',
    Loader: 'loading wait busy progress spinner spinning throbber',
    LocateFixed: 'map gps location cross',
    LocateOff: 'map gps location cross',
    Locate: 'map gps location cross',
    LockKeyholeOpen: 'security',
    LockKeyhole: 'security password secure admin',
    LockOpen: 'security',
    Lock: 'security password secure admin',
    LogIn: 'sign in arrow enter auth',
    LogOut: 'sign out arrow exit auth',
    Logs: 'options list menu order queue tasks logs',
    Lollipop: 'lolly candy sugar food sweet dessert spiral',
    Luggage: 'baggage luggage travel suitcase',
    Magnet: 'horseshoe lock science snap',
    MailCheck: 'email message letter subscribe delivered success read done todo tick complete task',
    MailMinus: 'email message letter remove delete',
    MailOpen: 'email message letter read',
    MailPlus: 'email message letter add create new compose',
    MailQuestion: 'email message letter delivery undelivered',
    MailSearch: 'email message letter search',
    MailWarning: 'email message letter delivery error exclamation mark',
    MailX: 'email message letter remove delete',
    Mail: 'email message letter unread',
    Mailbox: 'emails messages letters mailing list newsletter',
    Mails: 'emails messages letters multiple mailing list newsletter copy',
    MapPinCheckInside: 'location waypoint marker drop done tick complete task added',
    MapPinCheck: 'location waypoint marker drop done tick complete task added',
    MapPinHouse:
        'location waypoint marker drop home living building residence architecture address poi real estate property navigation destination geolocation place landmark',
    MapPinMinusInside: 'location waypoint marker drop delete remove erase',
    MapPinMinus: 'location waypoint marker drop delete remove erase',
    MapPinOff: 'location waypoint marker remove',
    MapPinPlusInside: 'location waypoint marker drop add create new',
    MapPinPlus: 'location waypoint marker drop add create new',
    MapPinXInside: 'location waypoint marker drop delete remove erase',
    MapPinX: 'location waypoint marker drop delete remove erase',
    MapPin: 'location waypoint marker drop',
    MapPinned: 'location waypoint marker drop',
    Map: 'location navigation travel',
    Martini: 'cocktail alcohol beverage bar drink glass',
    Maximize2: 'fullscreen arrows expand',
    Maximize: 'fullscreen expand dashed',
    Medal: 'prize sports winner trophy award achievement',
    MegaphoneOff: 'advertisement attention alert notification disable silent',
    Megaphone: 'advertisement attention alert notification',
    Meh: 'emoji face neutral emotion',
    MemoryStick: 'ram random access technology computer chip circuit specs capacity gigabytes gb',
    Menu: 'bars navigation hamburger options',
    Merge: 'combine join unite',
    MessageCircleCode: 'comment chat conversation dialog feedback speech bubble code review coding',
    MessageCircleDashed: 'comment chat conversation dialog feedback speech bubble draft',
    MessageCircleHeart:
        'comment chat conversation dialog feedback positive like love interest valentine dating date speech bubble',
    MessageCircleMore:
        'comment chat conversation dialog feedback speech bubble typing writing responding ellipsis etc et cetera ... …',
    MessageCircleOff:
        'comment chat conversation dialog feedback speech bubble clear close delete remove cancel silence mute moderate',
    MessageCirclePlus: 'comment chat conversation dialog feedback speech bubble add',
    MessageCircleQuestion: 'comment chat conversation dialog feedback speech bubble help',
    MessageCircleReply: 'comment chat conversation dialog feedback speech bubble reply response',
    MessageCircleWarning:
        'comment chat conversation dialog feedback speech bubble report abuse offense alert danger caution protected exclamation mark',
    MessageCircleX:
        'comment chat conversation dialog feedback speech bubble clear close delete remove cancel silence mute moderate',
    MessageCircle: 'comment chat conversation dialog feedback speech bubble',
    MessageSquareCode: 'comment chat conversation dialog feedback speech bubble code review coding',
    MessageSquareDashed: 'comment chat conversation dialog feedback speech bubble draft',
    MessageSquareDiff:
        'comment chat conversation dialog feedback speech bubble add patch difference plus minus plus-minus maths code review coding version control git',
    MessageSquareDot: 'unread unresolved comment chat conversation dialog feedback speech bubble',
    MessageSquareHeart:
        'comment chat conversation dialog feedback positive like love interest valentine dating date speech bubble',
    MessageSquareLock: 'comment chat conversation dialog feedback speech bubble secure encrypted',
    MessageSquareMore:
        'comment chat conversation dialog feedback speech bubble typing writing responding ellipsis etc et cetera ... …',
    MessageSquareOff:
        'comment chat conversation dialog feedback speech bubble clear close delete remove cancel silence mute moderate',
    MessageSquarePlus: 'comment chat conversation dialog feedback speech bubble add',
    MessageSquareQuote:
        'comment chat conversation dialog feedback speech bubble blockquote quotation indent reply response',
    MessageSquareReply: 'comment chat conversation dialog feedback speech bubble reply response',
    MessageSquareShare: 'comment chat conversation dialog feedback speech bubble network forward',
    MessageSquareText: 'comment chat conversation dialog feedback speech bubble',
    MessageSquareWarning:
        'comment chat conversation dialog feedback speech bubble report abuse offense alert danger caution protected exclamation mark',
    MessageSquareX:
        'comment chat conversation dialog feedback speech bubble clear close delete remove cancel silence mute moderate',
    MessageSquare: 'comment chat conversation dialog feedback speech bubble',
    MessagesSquare: 'comment chat conversation dialog feedback speech bubbles copy multiple',
    MicOff: 'record sound mute microphone',
    MicVocal: 'lyrics voice listen sound music radio podcast karaoke singing microphone',
    Mic: 'record sound listen radio podcast microphone',
    Microchip:
        'processor cores technology computer chip integrated circuit memory ram specs gpu gigahertz ghz',
    Microscope: 'medical education science imaging research',
    Microwave: 'oven cooker toaster oven bake',
    Milestone: 'signpost direction right east forward version control waypoint',
    MilkOff: 'lactose free bottle beverage drink water allergy intolerance diet',
    Milk: 'lactose bottle beverage drink water diet',
    Minimize2: 'exit fullscreen arrows close shrink',
    Minimize: 'exit fullscreen close shrink',
    Minus: 'subtract remove decrease decrement reduce negative calculate line divider separator horizontal rule hr html markup markdown --- toolbar operator code coding minimum downgrade',
    MonitorCheck: 'tv screen display desktop running active virtual machine vm',
    MonitorCog:
        'tv screen display virtual machine vm executable settings cog edit gear configuration preferences system control panel network computing',
    MonitorDot: 'tv screen display desktop running active virtual machine vm',
    MonitorDown: 'tv screen display desktop download',
    MonitorOff: 'share',
    MonitorPause:
        'tv screen display desktop video movie film suspend hibernate boot virtual machine vm',
    MonitorPlay: 'tv screen display desktop video movie film running start boot virtual machine vm',
    MonitorSmartphone:
        'smartphone phone cellphone device mobile desktop monitor responsive screens',
    MonitorSpeaker: 'devices connect cast',
    MonitorStop: 'tv screen display desktop video movie film stop shutdown virtual machine vm',
    MonitorUp: 'tv screen display upload connect remote screen share',
    MonitorX: 'tv screen display desktop virtual machine vm close stop suspend remove delete',
    Monitor: 'tv screen display virtual machine vm',
    MoonStar: 'dark night star',
    Moon: 'dark night',
    MountainSnow: 'alpine climb snow',
    Mountain: 'climb hike rock',
    MouseOff: 'device scroll click disabled',
    MousePointer2: 'click select',
    MousePointerBan: 'wait busy loading blocked frozen freeze',
    MousePointerClick: 'click select',
    MousePointer: 'click select',
    Mouse: 'device scroll click',
    Move3d: 'arrows axis gizmo coordinates transform translate',
    MoveDiagonal2: 'double arrow',
    MoveDiagonal: 'double arrow',
    MoveDownLeft: 'arrow direction',
    MoveDownRight: 'arrow direction',
    MoveDown: 'arrow direction downwards south',
    MoveHorizontal: 'double arrow',
    MoveLeft: 'arrow direction back west',
    MoveRight: 'arrow direction trend flat east',
    MoveUpLeft: 'arrow direction',
    MoveUpRight: 'arrow direction',
    MoveUp: 'arrow direction upwards north',
    MoveVertical: 'double arrow',
    Move: 'arrows',
    Music2: 'quaver eighth note note',
    Music3: 'crotchet minim quarter note half note note',
    Music4: 'semiquaver sixteenth note note',
    Music: 'note quaver eighth note',
    Navigation2Off: 'location travel',
    Navigation2: 'location travel',
    NavigationOff: 'location travel',
    Navigation: 'location travel',
    Network: 'tree',
    Newspaper: 'news feed home magazine article headline',
    Nfc: 'contactless payment near-field communication',
    NotebookPen:
        'pencil notepad notes noted stationery sketchbook organizer organiser planner diary journal writing write written reading high school university college academy student study research homework eraser rubber',
    NotebookTabs:
        'notepad notes people family friends acquaintances contacts details addresses phone numbers directory listing networking alphabetical a-z organizer organiser planner diary stationery',
    NotebookText:
        'notepad notes pages paper stationery sketchbook organizer organiser planner diary journal writing write written reading high school university college academy student study research homework lines opened',
    Notebook:
        'notepad notes stationery sketchbook moleskine closure strap band elastic organizer organiser planner diary journal writing written writer reading high school university college academy student study homework research',
    NotepadTextDashed:
        'notebook notes pages paper stationery diary journal writing write written draft template lines',
    NotepadText:
        'notebook notes pages paper stationery sketchbook organizer organiser planner diary journal writing write written reading high school university college academy student study homework research lines opened',
    NutOff: 'hazelnut acorn food allergy intolerance diet',
    Nut: 'hazelnut acorn food diet',
    OctagonAlert: 'warning alert danger exclamation mark',
    OctagonMinus: 'stop forbidden subtract remove decrease reduce - traffic halt restricted',
    OctagonPause: 'music audio stop',
    OctagonX: 'delete stop alert warning times clear maths',
    Octagon: 'stop shape',
    Omega: 'greek symbol mathematics education physics engineering ohms electrical resistance angular frequency dynamical systems astronomy constellations philosophy',
    Option: 'keyboard key mac alt button',
    Orbit: 'planet space physics satellites moons',
    Origami: 'paper bird',
    Package2: 'box container storage sealed packed unopened undelivered archive zip',
    PackageCheck: 'confirm verified done todo tick complete task delivered',
    PackageMinus: 'delete remove',
    PackageOpen: 'box container storage unpack unarchive unzip opened delivered',
    PackagePlus: 'new add create',
    PackageSearch: 'find product process',
    PackageX: 'delete remove',
    Package: 'box container storage sealed delivery undelivered unopened packed archive zip module',
    PaintBucket: 'fill paint bucket color colour',
    PaintRoller: 'brush color colour decoration diy',
    PaintbrushVertical: 'brush paintbrush design color colour decoration diy',
    Paintbrush: 'brush paintbrush design color colour decoration diy',
    Palette: 'colors colours theme scheme paint watercolor watercolour artist',
    PanelBottomClose: 'drawer dock hide chevron down',
    PanelBottomDashed: 'drawer dock show reveal',
    PanelBottomOpen: 'drawer dock show reveal chevron up',
    PanelBottom: 'drawer dock',
    PanelLeftClose: 'primary drawer hide chevron <',
    PanelLeftDashed: 'sidebar primary drawer show reveal',
    PanelLeftOpen: 'primary drawer show reveal chevron right >',
    PanelLeft: 'primary drawer',
    PanelRightClose: 'sidebar secondary drawer hide chevron >',
    PanelRightDashed: 'sidebar secondary drawer show reveal',
    PanelRightOpen: 'sidebar secondary drawer show reveal chevron left <',
    PanelRight: 'sidebar secondary drawer',
    PanelTopClose: 'menu bar drawer hide chevron up',
    PanelTopDashed: 'menu bar drawer show reveal',
    PanelTopOpen: 'menu bar drawer show reveal chevron down',
    PanelTop: 'drawer browser webpage',
    PanelsLeftBottom: 'drawers sidebar primary',
    PanelsRightBottom: 'drawers sidebar secondary',
    PanelsTopLeft: 'menu bar sidebar primary drawers window webpage projects overview',
    Paperclip: 'attachment file',
    Parentheses:
        'code token parenthesis parens brackets parameters arguments args input call maths formula function ( )',
    ParkingMeter: 'driving car park pay sidewalk pavement',
    PartyPopper:
        'emoji congratulations celebration party tada 🎉 🎊 excitement exciting excites confetti',
    Pause: 'music stop',
    PawPrint: 'pets vets veterinarian domesticated cat dog bear',
    PcCase: 'computer chassis',
    PenLine: 'pencil change create draw writer writing biro ink marker felt tip stationery artist',
    PenOff: 'disabled inactive non-editable locked read-only unmodifiable frozen restricted pencil change create draw writer writing biro ink marker felt tip stationery artist',
    PenTool: 'vector drawing path',
    Pen: 'pencil change create draw writer writing biro ink marker felt tip stationery artist',
    PencilLine:
        'pencil change create draw sketch draft writer writing biro ink marker felt tip stationery artist',
    PencilOff:
        'disabled inactive non-editable locked read-only unmodifiable frozen restricted rubber edit create draw sketch draft writer writing stationery artist',
    PencilRuler:
        'edit create draw sketch draft writer writing stationery artist measurements centimeters cm millimeters mm metre foot feet inches units size length width height dimensions depth breadth extent',
    Pencil: 'rubber edit create draw sketch draft writer writing stationery artist',
    Pentagon: 'shape',
    Percent: 'percentage modulo modulus remainder % sale discount offer marketing',
    PersonStanding: 'people human accessibility stick figure',
    PhilippinePeso: 'currency peso money php',
    PhoneCall: 'ring',
    PhoneForwarded: 'call',
    PhoneIncoming: 'call',
    PhoneMissed: 'call',
    PhoneOff: 'call mute',
    PhoneOutgoing: 'call',
    Phone: 'call',
    Pi: 'constant code coding programming symbol trigonometry geometry formula',
    Piano: 'music audio sound noise notes chord keys octave acoustic instrument play pianist performance concert',
    Pickaxe:
        'mining mine land worker extraction labor construction progress advancement crafting building creation',
    PictureInPicture2: 'display play video pop out always on top window inset multitask',
    PictureInPicture: 'display play video pop out always on top window inset multitask',
    PiggyBank: 'money savings',
    PilcrowLeft: 'direction paragraph mark paraph blind typography type text prose symbol',
    PilcrowRight: 'direction paragraph mark paraph blind typography type text prose symbol',
    Pilcrow: 'paragraph mark paraph blind typography type text prose symbol',
    PillBottle:
        'medicine medication prescription drug supplement vitamin capsule jar container healthcare pharmaceutical tablet',
    Pill: 'medicine medication drug prescription tablet pharmacy',
    PinOff: 'unpin map unlock unfix unsave remove',
    Pin: 'save map lock fix',
    Pipette: 'eye dropper color picker lab chemistry',
    Pizza: 'pie quiche food',
    PlaneLanding: 'arrival plane trip airplane landing',
    PlaneTakeoff: 'departure plane trip airplane takeoff',
    Plane: 'plane trip airplane',
    Play: 'music audio video start run',
    Plug2: 'electricity energy socket outlet',
    PlugZap: 'electricity energy electronics charge charging battery connect',
    Plug: 'electricity energy electronics socket outlet',
    Plus: 'add new increase increment positive calculate toolbar crosshair aim target scope sight reticule maximum upgrade extra +',
    PocketKnife: 'swiss army knife penknife multi-tool multitask blade cutter gadget corkscrew',
    Pocket: 'logo save',
    Podcast: 'audio music mic talk voice subscribe subscription stream',
    PointerOff: 'mouse',
    Pointer: 'mouse',
    Popcorn: 'cinema movies films salted sweet sugar candy snack',
    Popsicle: 'ice lolly ice cream sweet food',
    PoundSterling: 'currency money payment',
    PowerOff: 'on off device switch',
    Power: 'on off device switch toggle binary boolean reboot restart button keyboard troubleshoot',
    Presentation:
        'screen whiteboard marker pens markers blackboard chalk easel school learning lesson office meeting project planning',
    PrinterCheck: 'fax office device success printed',
    Printer: 'fax office device',
    Projector:
        'cinema film movie home video presentation slideshow office meeting project planning',
    Proportions:
        'screens sizes rotate rotation adjust aspect ratio 16:9 widescreen 4:3 resolution responsive mobile desktop dimensions monitor orientation portrait landscape',
    Puzzle: 'component module part piece',
    Pyramid:
        'prism triangle triangular hierarchy structure geometry ancient egyptian landmark tourism',
    QrCode: 'barcode scan link url information digital',
    Quote: 'quotation',
    Rabbit: 'animal rodent pet pest bunny hare fast speed hop',
    Radar: 'scan sonar detect find locate',
    Radiation: 'radioactive nuclear fallout waste atomic physics particle element molecule',
    Radical: 'calculate formula maths operator root square symbol',
    RadioReceiver: 'device music connect',
    RadioTower: 'signal broadcast connectivity live frequency',
    Radio: 'signal broadcast connectivity live frequency',
    Radius: 'shape circle geometry trigonometry radii calculate measure size',
    RailSymbol: 'railway train track line',
    Rainbow: 'colors colours spectrum light prism arc clear sunshine',
    Rat: 'mouse mice gerbil rodent pet pest plague disease',
    Ratio: 'screens sizes rotate rotation adjust aspect ratio proportions 16:9 widescreen 4:3 resolution responsive mobile desktop dimensions monitor orientation portrait landscape',
    ReceiptCent: 'bill voucher slip check counterfoil currency cents dollar usd $ ¢',
    ReceiptEuro: 'bill voucher slip check counterfoil currency €',
    ReceiptIndianRupee: 'bill voucher slip check counterfoil inr ₹',
    ReceiptJapaneseYen: 'bill voucher slip check counterfoil jpy ¥',
    ReceiptPoundSterling: 'bill voucher slip check counterfoil british gbp £',
    ReceiptRussianRuble: 'bill voucher slip check counterfoil rub ₽',
    ReceiptSwissFranc: 'bill voucher slip check counterfoil chf ₣',
    ReceiptText:
        'bill voucher slip check counterfoil details small print terms conditions contract',
    Receipt: 'bill voucher slip check counterfoil currency dollar usd $',
    RectangleEllipsis:
        'login password authenticate 2fa field fill ellipsis et cetera etc loader loading progress pending throbber menu options operator code spread rest more further extra overflow dots … ...',
    RectangleHorizontal: 'rectangle aspect ratio 16:9 horizontal shape',
    RectangleVertical: 'rectangle aspect ratio 9:16 vertical shape',
    Recycle: 'sustainability salvage arrows',
    Redo2: 'undo rerun history',
    RedoDot: 'redo history step over forward',
    Redo: 'undo rerun history',
    RefreshCcwDot:
        'arrows rotate reload synchronise synchronize circular cycle issue code coding version control',
    RefreshCcw: 'arrows rotate reload rerun synchronise synchronize circular cycle',
    RefreshCwOff:
        'rotate reload rerun synchronise synchronize arrows circular cycle cancel no stop error disconnect ignore',
    RefreshCw: 'rotate reload rerun synchronise synchronize arrows circular cycle',
    Refrigerator: 'frigerator fridge freezer cooler icebox chiller cold storage',
    Regex: 'search text code',
    RemoveFormatting: 'text font typography format x remove delete times clear',
    Repeat1: 'replay',
    Repeat2: 'arrows retweet repost share repeat loop',
    Repeat: 'loop arrows',
    ReplaceAll: 'search substitute swap change',
    Replace: 'search substitute swap change',
    ReplyAll: 'email',
    Reply: 'email',
    Rewind: 'music',
    Ribbon: 'awareness strip band tape strap cordon',
    Rocket: 'release boost launch space version',
    RockingChair: 'chair furniture seat',
    RollerCoaster: 'attraction entertainment amusement park theme park funfair',
    Rotate3d: 'gizmo transform orientation orbit axis',
    RotateCcwSquare: 'left counter-clockwise rotate image 90 45 degrees °',
    RotateCcw: 'arrow left counter-clockwise restart reload rerun refresh backup undo',
    RotateCwSquare: 'right clockwise rotate image 90 45 degrees °',
    RotateCw: 'arrow right clockwise refresh reload rerun redo',
    RouteOff: 'path journey planner points stops stations reset clear cancelled closed blocked',
    Route: 'path journey planner points stops stations',
    Router: 'computer server cloud',
    Rows2: 'lines list queue preview panel paragraphs parallel series split vertical horizontal half center middle even drawer',
    Rows3: 'lines list queue preview paragraphs parallel series split vertical horizontal half center middle even drawers',
    Rows4: 'lines list queue preview paragraphs parallel series split vertical horizontal half center middle even drawers grill',
    Rss: 'feed subscribe news updates notifications content blog articles broadcast syndication reader channels posts publishing digest alert following inbox newsletter weblog podcast',
    Ruler: 'measurements centimeters cm millimeters mm metre foot feet inches units size length width height dimensions depth breadth extent stationery',
    RussianRuble: 'currency money payment',
    Sailboat: 'ship boat harbor harbour dock',
    Salad: 'food vegetarian dish restaurant course meal side vegetables health',
    Sandwich: 'food snack dish restaurant lunch meal',
    SatelliteDish: 'antenna receiver dish aerial saucer',
    Satellite: 'space station orbit transmitter',
    SaveAll: 'floppy disks copy',
    SaveOff: 'floppy disk unsalvageable',
    Save: 'floppy disk',
    Scale3d: 'gizmo transform size axis',
    Scale: 'balance legal license right rule law',
    Scaling: 'scale resize design',
    ScanBarcode: 'checkout till cart transaction purchase buy product packaging retail consumer',
    ScanEye:
        'preview zoom expand fullscreen gallery image camera watch surveillance retina focus lens biometric identification authentication access login',
    ScanFace: 'face biometric identification authentication 2fa access login dashed',
    ScanLine:
        'checkout till cart transaction purchase buy product packaging retail consumer qr-code dashed',
    ScanQrCode: 'barcode scan qrcode url information digital scanner',
    ScanSearch: 'preview zoom expand fullscreen gallery image focus lens',
    ScanText: 'recognition read translate copy lines',
    Scan: 'qr-code barcode checkout augmented reality ar target surveillance camera lens focus frame select box boundary bounds area square dashed',
    School: 'building education childhood university learning campus scholar student lecture degree course academia study knowledge classroom research diploma graduation professor tutorial homework assignment exam',
    ScissorsLineDashed: 'cut here along snip chop stationery crafts instructions diagram',
    Scissors: 'cut snip chop stationery crafts',
    ScreenShareOff: 'desktop disconnect monitor',
    ScreenShare: 'host desktop monitor',
    ScrollText: 'paper log scripture document notes parchment list long script story code coding',
    Scroll: 'paper log scripture document notes parchment list long script story code coding',
    SearchCheck: 'find scan magnifier magnifying glass found correct complete tick',
    SearchCode: 'find scan magnifier magnifying glass grep chevrons <>',
    SearchSlash: 'find scan magnifier magnifying glass stop clear cancel abort /',
    SearchX: 'find scan magnifier magnifying glass stop clear cancel abort',
    Search: 'find scan magnifier magnifying glass',
    Section: 'mark typography punctuation legal type text prose symbol',
    SendHorizontal: 'email message mail paper airplane paper aeroplane submit',
    SendToBack: 'bring send move under back backwards overlap layer order',
    Send: 'email message mail paper airplane paper aeroplane submit',
    SeparatorHorizontal: 'move split',
    SeparatorVertical: 'move split',
    ServerCog: 'cloud storage computing cog gear',
    ServerCrash: 'cloud storage problem error',
    ServerOff: 'cloud storage',
    Server: 'cloud storage',
    Settings2: 'cog edit gear preferences',
    Settings: 'cog edit gear preferences',
    Shapes: 'triangle equilateral square circle classification different collection toy blocks learning',
    Share2: 'network connections',
    Share: 'network connections',
    Sheet: 'spreadsheets table excel',
    Shell: 'beach sand holiday sealife fossil ammonite biology ocean terminal command line session bash zsh roll wrap chewing gum bubble gum sweet sugar hosepipe carpet string spiral spinner hypnotise hypnosis',
    ShieldAlert:
        'unshielded cybersecurity insecure unsecured safety unsafe protection unprotected guardian unguarded unarmored unarmoured defenseless defenceless undefended defender blocked stopped intercepted interception saved thwarted threat prevention unprevented antivirus vigilance vigilant detection detected scanned found exploit vulnerability vulnerable weakness infection infected comprimised data leak audited admin verification unverified uncertified warning emergency attention urgent alarm crest bravery strength tough attacked damaged injured hit expired disabled inactive error exclamation mark !',
    ShieldBan:
        'unshielded cybersecurity insecure unsecured safety unsafe protection unprotected guardian unguarded unarmored unarmoured defenseless defenceless undefended defender blocked stopped intercepted interception saved thwarted threat prevention unprevented antivirus vigilance vigilant detection detected scanned found exploit vulnerability vulnerable weakness infection infected comprimised data leak audited admin verification unverified uncertified cancel error crest bravery attacked damaged injured hit expired eliminated disabled inactive /',
    ShieldCheck:
        'cybersecurity secured safety protection protected guardian guarded armored armoured defense defence defended blocked threat prevention prevented antivirus vigilance vigilant active activated enabled detection scanned found strength strong tough invincible invincibility invulnerable undamaged audited admin verification verified certification certified tested passed qualified cleared cleaned disinfected uninfected task completed todo done ticked checked crest bravery',
    ShieldEllipsis:
        'cybersecurity securing protecting guarding armoring armouring defending blocking preventing antivirus detecting scanning finding auditing admin verifying crest upgrading loader loading throbber progress dots more etc ... …',
    ShieldHalf:
        'cybersecurity secure safety protection guardian armored armoured defense defence defender block threat prevention antivirus vigilance vigilant detection scan strength strong tough invincible invincibility invulnerable undamaged audit admin verification crest logo sigil flag team faction fraternity university college academy school education uniform bravery knight foot soldier infantry trooper pawn battle war military ranking army cadet scout',
    ShieldMinus:
        'unshield cybersecurity unsecure unguard unblock antivirus clean clear disinfect patch fix stop cancel remove relax admin crest bravery weakened damaged hit unarm disable deactivate decommission downgraded minimum -',
    ShieldOff:
        'unshielded cybersecurity insecure unsecured safety unsafe protection unprotected guardian unguarded unarmored unarmoured defenseless defenceless undefended defender interception threat prevention unprevented antivirus detection undetected exploit vulnerability vulnerable weakness infected infection comprimised data leak unaudited admin verification unverified inactive cancelled error crest bravery damaged injured hit expired eliminated',
    ShieldPlus:
        'cybersecurity secure safety protection guardian armored armoured defense defence defender block threat prevention antivirus vigilance vigilant detection scan strength strong tough invincible invincibility invulnerable undamaged extra added professional enterprise full maximum upgraded ultra activate enable audit admin verification crest medic +',
    ShieldQuestion:
        'unshielded cybersecurity insecure unsecured safety unsafe protection unprotected guardian unguarded unarmored unarmoured defenseless defenceless undefended defender threat prevention unprevented antivirus vigilance vigilant detection undetected scan find exploit vulnerability vulnerable weakness infection comprimised data leak audit admin verification unverified uncertified uncertain unknown inactive crest question mark ?',
    ShieldX:
        'unshielded cybersecurity insecure unsecured safety unsafe protection unprotected guardian unguarded unarmored unarmoured defenseless defenceless undefended defender blocked stopped intercepted interception saved thwarted threat prevention prevented antivirus vigilance vigilant detection detected scanned found exploit vulnerability vulnerable weakness infection infected comprimised data leak audited admin verification unverified inactive cancel error wrong false crest bravery attacked damaged injured hit dead deceased expired eliminated exterminated',
    Shield: 'cybersecurity secure safety protection guardian armored armoured defense defence defender block threat prevention antivirus vigilance vigilant detection scan find strength strong tough invincible invincibility invulnerable undamaged audit admin verification crest bravery knight foot soldier infantry trooper pawn battle war military army cadet scout',
    ShipWheel:
        'steering rudder boat knots nautical mile maritime sailing yacht cruise ocean liner tanker vessel navy trip',
    Ship: 'boat knots nautical mile maritime sailing yacht cruise ocean liner tanker vessel navy trip',
    Shirt: 't-shirt shopping store clothing clothes',
    ShoppingBag: 'ecommerce cart purchase store',
    ShoppingBasket: 'cart e-commerce store purchase products items ingredients',
    ShoppingCart: 'trolley cart basket e-commerce store purchase products items ingredients',
    Shovel: 'dig spade treasure',
    ShowerHead: 'shower bath bathroom amenities services',
    Shrink: 'scale fullscreen',
    Shrub: 'forest undergrowth park nature',
    Shuffle: 'music random reorder',
    Sigma: 'sum calculate formula maths enumeration enumerate',
    SignalHigh: 'connection wireless gsm phone 2g 3g 4g 5g',
    SignalLow: 'connection wireless gsm phone 2g 3g 4g 5g',
    SignalMedium: 'connection wireless gsm phone 2g 3g 4g 5g',
    SignalZero: 'connection wireless gsm phone 2g 3g 4g 5g lost',
    Signal: 'connection wireless gsm phone 2g 3g 4g 5g',
    Signature:
        'text format input contract autograph handwriting sign cursive ink scribble authorize personal agreement legal document identity authentic approval verification unique',
    SignpostBig: 'bidirectional left right east west',
    Signpost: 'bidirectional left right east west',
    Siren: 'police ambulance emergency security alert alarm light',
    SkipBack: 'arrow previous music',
    SkipForward: 'arrow skip next music',
    Skull: 'death danger bone',
    Slack: 'logo',
    Slash: 'divide division or /',
    Slice: 'cutter scalpel knife',
    SlidersHorizontal: 'settings filters controls',
    SlidersVertical: 'settings controls',
    SmartphoneCharging: 'phone cellphone device power screen',
    SmartphoneNfc: 'contactless payment near-field communication screen',
    Smartphone: 'phone cellphone device screen',
    SmilePlus: 'emoji face happy good emotion react reaction add',
    Smile: 'emoji face happy good emotion',
    Snail: 'animal insect slow speed delicacy spiral',
    Snowflake: 'cold weather freeze snow winter',
    Sofa: 'armchair furniture leisure lounge loveseat couch',
    Soup: 'food dish restaurant course meal bowl starter',
    Space: 'text selection letters characters font typography',
    Spade: 'shape suit playing cards',
    Sparkle: 'star effect filter night magic shiny glitter twinkle celebration',
    Sparkles: 'stars effect filter night magic',
    Speaker: 'sound audio music tweeter subwoofer bass production producer dj',
    Speech: 'disability disabled dda human accessibility people sound',
    SpellCheck2: 'spelling error mistake oversight typo correction code linter a',
    SpellCheck: 'spelling error mistake oversight typo correction code linter a',
    Spline: 'path pen tool shape curve draw',
    Split: 'break disband divide separate branch disunite',
    SprayCan: 'paint color graffiti decoration aerosol deodorant shaving foam air freshener',
    Sprout: 'leaf nature plant',
    SquareActivity:
        'pulse action motion movement exercise fitness healthcare heart rate monitor vital signs vitals emergency room er intensive care hospital defibrillator earthquake siesmic magnitude richter scale aftershock tremor shockwave audio waveform synthesizer synthesiser music',
    SquareArrowDownLeft: 'direction south-west diagonal sign turn keyboard button',
    SquareArrowDownRight: 'direction south-east diagonal sign turn keyboard button',
    SquareArrowDown: 'backwards reverse direction south sign keyboard button',
    SquareArrowLeft: 'previous back direction west sign keyboard button <-',
    SquareArrowOutDownLeft: 'outwards direction south-west diagonal',
    SquareArrowOutDownRight: 'outwards direction south-east diagonal',
    SquareArrowOutUpLeft: 'outwards direction north-west diagonal',
    SquareArrowOutUpRight: 'outwards direction north-east diagonal share open external link',
    SquareArrowRight: 'next forward direction west sign keyboard button ->',
    SquareArrowUpLeft: 'direction north-west diagonal sign keyboard button',
    SquareArrowUpRight: 'direction north-east diagonal sign keyboard button share',
    SquareArrowUp: 'forward direction north sign keyboard button',
    SquareAsterisk: 'password secret access key multiply multiplication glob pattern wildcard *',
    SquareBottomDashedScissors: 'cut snippet chop stationery crafts',
    SquareChartGantt:
        'projects manage overview roadmap plan intentions timeline deadline date event range period productivity work agile code coding toolbar button',
    SquareCheckBig: 'done todo tick complete task',
    SquareCheck: 'done todo tick complete task',
    SquareChevronDown: 'back menu panel',
    SquareChevronLeft: 'back previous less than fewer menu panel button keyboard <',
    SquareChevronRight:
        'forward next more than greater menu panel code coding command line terminal prompt shell console >',
    SquareChevronUp:
        'caret keyboard button mac control ctrl superscript exponential power ahead menu panel ^',
    SquareCode: 'gist source programming html xml coding',
    SquareDashedBottomCode: 'rectangle aspect ratio 1:1 shape snippet code coding',
    SquareDashedBottom: 'rectangle aspect ratio 1:1 shape snippet code coding',
    SquareDashedKanban:
        'projects manage overview board tickets issues roadmap plan intentions productivity work agile draft template boilerplate code coding',
    SquareDashedMousePointer:
        'inspector element mouse click pointer box browser selector target dom node',
    SquareDashed: 'selection square rectangular marquee tool dashed box',
    SquareDivide: 'calculate maths ÷ /',
    SquareDot: 'git diff modified .',
    SquareEqual: 'calculate =',
    SquareFunction: 'programming code automation maths',
    SquareKanban:
        'projects manage overview board tickets issues roadmap plan intentions productivity work agile code coding toolbar button',
    SquareLibrary:
        'books reading written authors stories fiction novels information knowledge education high school university college academy learning study research collection vinyl records albums music package',
    SquareM: 'metro subway underground track line',
    SquareMenu: 'bars navigation hamburger options menu bar panel',
    SquareMinus:
        'subtract remove decrease reduce calculator button keyboard line divider separator horizontal rule hr html markup markdown --- toolbar operator code coding minimum downgrade',
    SquareMousePointer:
        'inspector element mouse click pointer box browser selector target dom node',
    SquareParkingOff: 'parking lot car park no parking',
    SquareParking: 'parking lot car park',
    SquarePen:
        'pencil change create draw sketch draft writer writing biro ink marker felt tip stationery artist',
    SquarePercent: 'verified unverified sale discount offer marketing sticker price tag',
    SquarePi: 'constant code coding programming symbol trigonometry geometry formula',
    SquarePilcrow: 'paragraph mark paraph blind typography type text prose symbol',
    SquarePlay: 'music audio video start run',
    SquarePlus:
        'add new increase increment positive calculate calculator button keyboard toolbar maximum upgrade extra operator join concatenate code coding +',
    SquarePower:
        'on off device switch toggle binary boolean reboot restart button keyboard troubleshoot',
    SquareRadical: 'calculate formula maths operator root square symbol',
    SquareScissors: 'cut snippet chop stationery crafts toolbar button',
    SquareSigma: 'sum calculate formula maths enumeration enumerate',
    SquareSlash: 'git diff ignored divide division shortcut or /',
    SquareSplitHorizontal: 'split divide',
    SquareSplitVertical: 'split divide',
    SquareSquare: 'float center rectangle',
    SquareStack: 'versions clone copy duplicate multiple revisions version control backup history',
    SquareTerminal: 'code command line prompt shell',
    SquareUserRound: 'person account contact',
    SquareUser: 'person account contact',
    SquareX: 'cancel close delete remove times clear maths multiply multiplication',
    Square: 'rectangle aspect ratio 1:1 shape',
    Squircle: 'shape',
    Squirrel: 'animal rodent pet pest nuts retrieve updates storage stash',
    Stamp: 'mark print clone loyalty library',
    StarHalf: 'bookmark favorite like review rating',
    StarOff: 'dislike unlike remove unrate',
    Star: 'bookmark favorite like review rating',
    StepBack: 'arrow previous music left reverse',
    StepForward: 'arrow next music right continue',
    Stethoscope: 'phonendoscope medical heart lungs sound',
    Sticker: 'reaction emotion smile happy feedback',
    StickyNote:
        'post-it comment annotation reaction memo reminder todo task idea brainstorm document page paper sheet stationary office',
    Store: 'shop supermarket stand boutique building',
    StretchHorizontal: 'items flex justify distribute',
    StretchVertical: 'items flex justify distribute',
    Strikethrough: 'cross out delete remove format',
    Subscript: 'text',
    SunDim: 'brightness dim low brightness low',
    SunMedium: 'brightness medium',
    SunMoon: 'night dark light moon sun brightness theme auto theme system theme appearance',
    SunSnow: 'weather air conditioning temperature hot cold seasons',
    Sun: 'brightness weather light summer',
    Sunrise: 'weather time morning day',
    Sunset: 'weather time evening night',
    Superscript: 'text exponent',
    SwatchBook:
        'colors colours swatches pantone shades tint hue saturation brightness theme scheme palette samples textile carpet',
    SwissFranc: 'currency money payment',
    SwitchCamera: 'photo selfie front back',
    Sword: 'battle challenge game war weapon',
    Swords: 'battle challenge game war weapon',
    Syringe: 'medicine medical needle pump plunger nozzle blood',
    Table2: 'spreadsheet grid',
    TableCellsMerge: 'spreadsheet grid row',
    TableCellsSplit: 'spreadsheet grid row',
    TableColumnsSplit: 'spreadsheet grid cut break divide separate segment',
    TableOfContents:
        'toc outline navigation document structure index overview sections chapters content documentation manual knowledge base faq',
    TableProperties: 'property list plist spreadsheet grid dictionary object hash',
    TableRowsSplit: 'spreadsheet grid cut break divide separate segment',
    Table: 'spreadsheet grid',
    TabletSmartphone: 'responsive screens browser testing mobile',
    Tablet: 'device',
    Tablets: 'medicine medication drug prescription pills pharmacy',
    Tag: 'label badge ticket mark',
    Tags: 'labels badges tickets marks copy multiple',
    Tally1: 'count score enumerate days one 1 first bar prison cell sentence',
    Tally2: 'count score enumerate days two 2 second double bars prison cell sentence',
    Tally3: 'count score enumerate days three 3 third triple bars prison cell sentence',
    Tally4: 'count score enumerate days 4 fourth quadruple bars prison cell sentence',
    Tally5: 'count score enumerate days five 5 fifth bars prison cell sentence slash /',
    Tangent: 'tangential shape circle geometry trigonometry bezier curve',
    Target: 'logo bullseye deadline projects overview work productivity',
    Telescope:
        'astronomy space discovery exploration explore vision perspective focus stargazing observe view',
    TentTree: 'camping campsite holiday retreat nomadic wilderness outdoors',
    Tent: 'tipi teepee wigwam lodge camping campsite holiday retreat nomadic native american indian wilderness outdoors',
    Terminal: 'code command line prompt shell',
    TestTubeDiagonal: 'tube vial phial flask ampoule ampule lab chemistry experiment test',
    TestTube: 'tube vial phial flask ampoule ampule lab chemistry experiment test',
    TestTubes: 'tubes vials phials flasks ampoules ampules lab chemistry experiment test',
    TextCursorInput: 'select',
    TextCursor: 'select',
    TextQuote: 'blockquote quotation indent reply response',
    TextSearch: 'find data copy txt pdf document scan magnifier magnifying glass',
    TextSelect: 'find search selection dashed',
    Text: 'find search data txt pdf document',
    Theater: 'theater theatre entertainment podium stage musical',
    ThermometerSnowflake: 'temperature celsius fahrenheit weather cold freeze freezing',
    ThermometerSun: 'temperature celsius fahrenheit weather warm hot',
    Thermometer: 'temperature celsius fahrenheit weather',
    ThumbsDown: 'dislike bad emotion',
    ThumbsUp: 'like good emotion',
    TicketCheck:
        'entry pass voucher event concert show booked purchased receipt redeemed validated verified certified checked used',
    TicketMinus: 'entry pass voucher event concert show remove cancel unbook subtract decrease -',
    TicketPercent: 'discount reduced offer voucher entry pass event concert show book purchase %',
    TicketPlus: 'entry pass voucher event concert show book purchase add +',
    TicketSlash:
        'entry pass voucher event concert show redeemed used marked checked verified spoiled invalidated void denied refused banned barred forbidden prohibited cancelled cancellation refunded delete remove clear error',
    TicketX:
        'entry pass voucher event concert show cancelled cancellation refunded used void invalidated spoiled denied refused banned barred forbidden prohibited delete remove clear error x',
    Ticket: 'entry pass voucher event concert show perforated dashed',
    TicketsPlane:
        'plane trip airplane flight travel fly takeoff vacation passenger pass check-in airport',
    Tickets: 'trip travel pass entry voucher event concert show perforated dashed',
    TimerOff: 'time timer stopwatch',
    TimerReset: 'time timer stopwatch',
    Timer: 'time timer stopwatch',
    ToggleLeft: 'on off switch boolean',
    ToggleRight: 'on off switch boolean',
    Toilet: 'toilet potty bathroom washroom',
    Tornado: 'weather wind storm hurricane',
    Torus: 'donut doughnut ring hollow 3d fast food junk food snack treat sweet sugar dessert',
    TouchpadOff: 'trackpad cursor',
    Touchpad: 'trackpad cursor',
    TowerControl: 'airport travel tower transportation lighthouse',
    ToyBrick: 'lego block addon plugin integration',
    Tractor: 'farming farmer ranch harvest equipment vehicle',
    TrafficCone: 'roadworks tarmac safety block',
    TrainFrontTunnel: 'railway metro subway underground speed bullet fast track line',
    TrainFront: 'railway metro subway underground high-speed bullet fast track line',
    TrainTrack: 'railway line',
    TramFront: 'railway metro subway underground track line tourism',
    Trash2: 'garbage delete remove bin',
    Trash: 'garbage delete remove bin',
    TreeDeciduous: 'tree forest park nature',
    TreePalm: 'vacation leisure island',
    TreePine: 'tree pine forest park nature',
    Trees: 'tree forest park nature',
    Trello: 'logo brand',
    TrendingDown: 'statistics',
    TrendingUpDown:
        'arrows estimated indeterminate data fluctuation uncertain forecast variable prediction dynamic volatile',
    TrendingUp: 'statistics',
    TriangleAlert: 'warning alert danger exclamation mark linter',
    TriangleRight:
        'volume controls controller tv remote geometry delta ramp slope incline increase',
    Triangle: 'equilateral delta shape pyramid hierarchy',
    Trophy: 'prize sports winner achievement award',
    Truck: 'delivery van shipping haulage lorry',
    Turtle: 'animal pet tortoise slow speed',
    TvMinimalPlay:
        'flatscreen television stream display widescreen high-definition hd 1080p 4k 8k smart digital video movie live ott running start film home cinema entertainment showtime channels catchup',
    TvMinimal:
        'flatscreen television stream display widescreen high-definition hd 1080p 4k 8k smart digital video home cinema entertainment showtime channels catchup',
    Tv: 'television stream display widescreen high-definition hd 1080p 4k 8k smart digital video entertainment showtime channels terrestrial satellite cable broadcast live frequency tune scan aerial receiver transmission signal connection connectivity',
    Twitch: 'logo social',
    Twitter: 'logo social',
    TypeOutline: 'text font typography silhouette profile contour stroke line',
    Type: 'text font typography',
    UmbrellaOff: 'rain weather uncovered uninsured antivirus unprotected risky',
    Umbrella: 'rain weather',
    Underline: 'text format',
    Undo2: 'redo rerun history back return reverse revert direction u-turn',
    UndoDot: 'redo history step back',
    Undo: 'redo rerun history',
    UnfoldHorizontal: 'arrow collapse fold vertical dashed',
    UnfoldVertical: 'arrow expand vertical dashed',
    Ungroup: 'cubes packages parts units collection cluster separate',
    University: 'building education childhood school',
    Unlink2: 'url unchain',
    Unlink: 'url unchain',
    Unplug: 'electricity energy electronics socket outlet disconnect',
    Upload: 'file',
    Usb: 'universal serial bus controller connector interface',
    UserCheck: 'followed subscribed done todo tick complete task',
    UserCog: 'settings edit cog gear',
    UserMinus: 'delete remove unfollow unsubscribe',
    UserPen: 'person account contact profile edit change',
    UserPlus: 'new add create follow subscribe',
    UserRoundCheck: 'followed subscribed done todo tick complete task',
    UserRoundCog: 'settings edit cog gear',
    UserRoundMinus: 'delete remove unfollow unsubscribe',
    UserRoundPen: 'person account contact profile edit change',
    UserRoundPlus: 'new add create follow subscribe',
    UserRoundSearch: 'person account contact find scan magnifier magnifying glass',
    UserRoundX: 'delete remove unfollow unsubscribe unavailable',
    UserRound: 'person account contact',
    UserSearch: 'person account contact find scan magnifier magnifying glass',
    UserX: 'delete remove unfollow unsubscribe unavailable',
    User: 'person account contact',
    UsersRound: 'group people',
    Users: 'group people',
    UtensilsCrossed:
        'fork knife cutlery flatware tableware silverware food restaurant meal breakfast dinner supper',
    Utensils:
        'fork knife cutlery flatware tableware silverware food restaurant meal breakfast dinner supper',
    UtilityPole: 'electricity energy transmission line telegraph pole power lines phone',
    Variable:
        'code coding programming symbol calculate algebra x parentheses parenthesis brackets parameter ( )',
    Vault: 'safe lockbox deposit locker coffer strongbox safety secure storage valuables bank',
    Vegan: 'vegetarian fruitarian herbivorous animal rights diet',
    VenetianMask: 'mask masquerade impersonate secret incognito',
    VibrateOff: 'smartphone notification rumble haptic feedback notifications screen',
    Vibrate: 'smartphone notification rumble haptic feedback screen',
    VideoOff: 'camera movie film',
    Video: 'camera movie film recording motion picture camcorder reel',
    Videotape: 'vhs movie film recording motion picture showreel cassette',
    View: 'eye look',
    Voicemail: 'phone cassette tape reel recording audio',
    Volleyball:
        'beach sand net holiday vacation summer soccer football futbol kick pitch goal score bounce leather wool yarn knitting sewing thread embroidery textile',
    Volume1: 'music sound speaker',
    Volume2: 'music sound speaker',
    VolumeOff: 'music sound mute speaker',
    VolumeX: 'music sound mute speaker',
    Volume: 'music sound mute speaker',
    Vote: 'vote poll ballot political social check tick',
    WalletCards: 'money finance pocket credit purchase payment shopping retail consumer cc',
    WalletMinimal: 'finance pocket',
    Wallet: 'money finance pocket',
    Wallpaper: 'cover lock screen',
    WandSparkles: 'magic wizard magician',
    Wand: 'magic selection',
    Warehouse: 'storage logistics building',
    WashingMachine: 'tumble dryer amenities electronics cycle clothes rinse spin drum',
    Watch: 'clock time',
    Waves: 'water sea sound hertz wavelength vibrate',
    Waypoints:
        'indirection vpn virtual private network proxy connections bounce reroute path journey planner stops stations shared spread viral',
    Webcam: 'camera security',
    WebhookOff: 'push api interface callback',
    Webhook: 'push api interface callback',
    Weight: 'mass heavy lead metal measure geometry scales balance',
    WheatOff: 'corn cereal grain gluten free allergy intolerance diet',
    Wheat: 'corn cereal grain gluten',
    WholeWord: 'text selection letters characters font typography',
    WifiHigh: 'connection signal wireless',
    WifiLow: 'connection signal wireless',
    WifiOff: 'disabled',
    WifiZero: 'connection signal wireless',
    Wifi: 'connection signal wireless',
    WindArrowDown: 'weather air pressure blow',
    Wind: 'weather air blow',
    WineOff:
        'alcohol beverage drink glass alcohol free abstinence abstaining teetotalism allergy intolerance',
    Wine: 'alcohol beverage bar drink glass sommelier vineyard winery',
    Workflow: 'action continuous integration ci automation devops network node connection',
    Worm: 'invertebrate grub larva snake crawl wiggle slither pest control computer virus malware',
    WrapText: 'words lines break paragraph',
    Wrench: 'account settings spanner diy toolbox build construction',
    X: 'cancel close delete remove times clear maths multiply multiplication',
    Youtube: 'logo social video play',
    ZapOff: 'flash camera lightning electricity energy',
    Zap: 'flash camera lightning electricity energy',
    ZoomIn: 'magnifying glass plus',
    ZoomOut: 'magnifying glass plus',
}
