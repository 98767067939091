import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewLayoutState() {
    const { view } = useDetailViewContext()

    const layoutType = view?.layout?.type ?? 'sidebar'

    return useMemo(() => ({ layoutType }), [layoutType])
}
