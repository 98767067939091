export const COLORS_ORDER = [
    'Neutral',
    'Lime',
    'Pink',
    'Taupe',
    'Emerald',
    'Fuchsia',
    'Teal',
    'Almond',
    'Purple',
    'Orange',
    'Violet',
    'Cyan',
    'Indigo',
    'Amber',
    'Blue',
    'Rose',
    'Sky',
    'Stone',
] as const
