import React from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { DetailViewCollapsibleControl } from 'features/views/DetailView/DetailViewCollapsibleControl'
import { DetailViewControlSeparator } from 'features/views/DetailView/DetailViewControlSeparator'
import { useDetailViewHeaderControlsState } from 'features/views/DetailView/headers/hooks/useDetailViewHeaderControlsState'
import { getYHeader } from 'features/views/DetailView/utils'
import { DetailViewHeader, DetailViewHeaderSlim } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { theme } from 'ui/styling/Theme.css'

type DetailViewHeaderControlsProps = {}

export const DetailViewHeaderControls: React.FC<DetailViewHeaderControlsProps> = () => {
    const { header, fields } = useDetailViewHeaderControlsState()

    return (
        <Box flex column gap="l">
            <DetailViewCollapsibleControl label="Style" startIcon={{ name: 'Paintbrush' }}>
                <HeaderTypeControls header={header} />
            </DetailViewCollapsibleControl>
            <DetailViewControlSeparator />
            {header.type === 'slim' && <SlimHeaderControls fields={fields} header={header} />}
        </Box>
    )
}

type HeaderTypeControlsProps = {
    header: DetailViewHeader
}

const HeaderTypeControls: React.FC<HeaderTypeControlsProps> = ({ header }) => {
    const { commands } = useLayoutEditorContext()

    return (
        <Field label="Header type">
            <RadioCardGroup
                value={header.type}
                onValueChange={(value: string) => {
                    commands.transaction((data) => {
                        const header = getYHeader(data)
                        header?.set('type', value)
                    })
                }}
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(1, 82px)',
                    gap: theme.space.l,
                }}
            >
                <RadioCard
                    value="slim"
                    icon={() => <HeaderTypeSlimIcon isActive={header.type === 'slim'} />}
                    cardIconWrapperProps={{
                        style: { padding: 0, overflow: 'hidden' },
                    }}
                >
                    Slim bar
                </RadioCard>
            </RadioCardGroup>
        </Field>
    )
}

type HeaderTypeIconProps = {
    isActive: boolean
}

const HeaderTypeSlimIcon: React.FC<HeaderTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="82"
                height="8"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                x="8.03125"
                y="2.48535"
                width="26.4"
                height="3.77143"
                style={{ fill: theme.color.surface }}
            />
            <rect
                x="8.50268"
                y="13.6428"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
            <rect
                x="8.07299"
                y="35.2146"
                width="66.9429"
                height="17.9143"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
        </svg>
    )
}

type SlimHeaderControlsProps = {
    fields: FieldDto[]
    header: DetailViewHeaderSlim
}

const SlimHeaderControls: React.FC<SlimHeaderControlsProps> = ({ fields, header }) => {
    const { commands } = useLayoutEditorContext()

    return (
        <DetailViewCollapsibleControl label="Content" startIcon={{ name: 'List' }}>
            <Select
                placeholder="Select field..."
                label="Title"
                isSearchable
                value={header.title?.fieldApiName}
                onChange={(value) => {
                    commands.transaction((data) => {
                        const header = getYHeader(data)
                        header?.set('title', { fieldApiName: value })
                    })
                }}
            >
                {fields.map((field) => (
                    <SelectOption
                        key={field.api_name}
                        value={field.api_name}
                        label={field.label}
                        startIcon={getFieldIcon(field)}
                    />
                ))}
            </Select>
        </DetailViewCollapsibleControl>
    )
}
