import * as Y from 'yjs'

import { DetailViewHeader, DetailViewLayout } from 'features/views/LayoutEditor/types'

import { getSupportedTitleFields } from './headers/utils'

export function getDetailViewHeader(
    layout?: DetailViewLayout,
    fields?: FieldDto[]
): DetailViewHeader {
    const supportedTitleFields = getSupportedTitleFields(fields ?? [])
    const defaultTitleField = supportedTitleFields[0]

    const defaultHeader: DetailViewHeader = {
        type: 'slim',
        title: !!defaultTitleField
            ? {
                  fieldApiName: defaultTitleField.api_name,
              }
            : undefined,
    }

    return {
        ...defaultHeader,
        ...layout?.header,
    }
}

export function getYHeader(view?: Y.Map<any>): Y.Map<any> | undefined {
    if (!view) return

    let layout = view.get('layout')
    if (!layout) {
        layout = view.set('layout', new Y.Map())
    }

    let header = layout.get('header')
    if (!header) {
        header = layout.set('header', new Y.Map())
    }

    return header
}

export function filterSupportedFields(fields: FieldDto[]) {
    return fields
        .filter((f) => {
            // Filter out disabled fields.
            return !f.connection_options?.is_disabled
        })
        .sort((a, b) => {
            // Primary fields should always be first.
            if (a.is_primary) return -1
            if (b.is_primary) return 1

            // Otherwise, sort by created date.
            const aCreatedDate = a.created_date || ''
            const bCreatedDate = b.created_date || ''

            return aCreatedDate.localeCompare(bCreatedDate)
        })
}
