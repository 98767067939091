import { useMemo } from 'react'

import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import useLDFlags from 'data/hooks/useLDFlags'

import { useNavigationContext } from './useNavigationContext'

export function useSystemNavigationState() {
    const { permissions, workspaceAccount, internalZone } = useNavigationContext()
    const { flags } = useLDFlags()
    const { workspaceZone } = useWorkspaceContext()

    const isPortal = useMemo(() => workspaceZone?.type === 'Portal', [workspaceZone])

    const shouldShouldSearch = !isPortal && flags.search
    const shouldShouldNotifications = flags.notifications
    const shouldShouldTasks = flags.tasks
    const shouldShouldFavorites = flags.favorites

    const shouldShouldHome = permissions.canViewInternalZone

    const shouldShouldSystemNavigation = permissions.canViewInternalZone

    const homePath = getWorkspaceUrl(Urls.Home, workspaceAccount, internalZone)
    const tasksPath = getWorkspaceUrl(Urls.Tasks, workspaceAccount, internalZone)

    return useMemo(
        () => ({
            shouldShouldSearch,
            shouldShouldNotifications,
            shouldShouldTasks,
            shouldShouldFavorites,
            shouldShouldHome,
            shouldShouldSystemNavigation,
            homePath,
            tasksPath,
        }),
        [
            shouldShouldSearch,
            shouldShouldNotifications,
            shouldShouldTasks,
            shouldShouldFavorites,
            shouldShouldHome,
            shouldShouldSystemNavigation,
            homePath,
            tasksPath,
        ]
    )
}
