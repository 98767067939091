import { useLayoutEffect, useRef } from 'react'

export type SwipeGestureCallback = (event: TouchEvent, startX: number, startY: number) => void

export type SwipeGestureOptions = {
    onSwipeRight?: SwipeGestureCallback
    onSwipeLeft?: SwipeGestureCallback
    onSwipeUp?: SwipeGestureCallback
    onSwipeDown?: SwipeGestureCallback
    threshold?: number // Pixels offset to trigger swipe.
}

export function useSwipeGesture(element: HTMLElement, options: SwipeGestureOptions = {}) {
    const { threshold = 10, onSwipeRight, onSwipeLeft, onSwipeUp, onSwipeDown } = options

    const onSwipeRightRef = useRef(onSwipeRight)
    onSwipeRightRef.current = onSwipeRight
    const onSwipeLeftRef = useRef(onSwipeLeft)
    onSwipeLeftRef.current = onSwipeLeft
    const onSwipeUpRef = useRef(onSwipeUp)
    onSwipeUpRef.current = onSwipeUp
    const onSwipeDownRef = useRef(onSwipeDown)
    onSwipeDownRef.current = onSwipeDown

    const touchStartX = useRef(0)
    const clientStartX = useRef(0)
    const touchEndX = useRef(0)
    const touchStartY = useRef(0)
    const clientStartY = useRef(0)
    const touchEndY = useRef(0)

    useLayoutEffect(() => {
        if (!element) return

        const handleGesture = (event: TouchEvent, startX: number, startY: number) => {
            if (
                touchEndX.current < touchStartX.current &&
                Math.max(touchStartY.current, touchEndY.current) -
                    Math.min(touchStartY.current, touchEndY.current) <
                    threshold
            ) {
                onSwipeLeftRef.current?.(event, startX, startY)
            }

            if (
                touchEndX.current > touchStartX.current &&
                Math.max(touchStartY.current, touchEndY.current) -
                    Math.min(touchStartY.current, touchEndY.current) <
                    threshold
            ) {
                onSwipeRightRef.current?.(event, startX, startY)
            }

            if (
                touchEndY.current < touchStartY.current &&
                Math.max(touchStartX.current, touchEndX.current) -
                    Math.min(touchStartX.current, touchEndX.current) <
                    threshold
            ) {
                onSwipeUpRef.current?.(event, startX, startY)
            }

            if (
                touchEndY.current > touchStartY.current &&
                Math.max(touchStartX.current, touchEndX.current) -
                    Math.min(touchStartX.current, touchEndX.current) <
                    threshold
            ) {
                onSwipeDownRef.current?.(event, startX, startY)
            }
        }

        const onTouchStart = (event: TouchEvent) => {
            touchStartX.current = event.changedTouches[0].screenX
            touchStartY.current = event.changedTouches[0].screenY
            clientStartX.current = event.changedTouches[0].clientX
            clientStartY.current = event.changedTouches[0].clientY
        }

        const onTouchEnd = (event: TouchEvent) => {
            touchEndX.current = event.changedTouches[0].screenX
            touchEndY.current = event.changedTouches[0].screenY
            handleGesture(event, clientStartX.current, clientStartY.current)
        }

        element.addEventListener('touchstart', onTouchStart, false)
        element.addEventListener('touchend', onTouchEnd, false)
        return () => {
            element.removeEventListener('touchstart', onTouchStart, false)
            element.removeEventListener('touchend', onTouchEnd, false)
        }
    }, [element, threshold])
}
