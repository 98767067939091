import React, { useMemo, useState } from 'react'

import { Icon } from 'ui/components/Icon'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { Spinner } from 'ui/components/Spinner'
import { makeComponent } from 'ui/helpers/recipes'
import { generateUniqueId } from 'ui/helpers/utilities'
import { useTheme } from 'ui/styling/themes/useTheme'

import { ButtonIconStyles, ButtonStyles } from './Button.css'

type IconProps = IconNameVariantType

export const ButtonBase = makeComponent('button', ButtonStyles)

export type ButtonProps = React.ComponentPropsWithoutRef<typeof ButtonBase> & {
    startIcon?: IconProps
    endIcon?: IconProps
    isLoading?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    startIconOverlay?: React.ReactElement
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            onClick,
            disabled,
            startIcon,
            endIcon,
            size = 'm',
            variant = 'primary',
            startIconOverlay,
            ...props
        },
        ref
    ) => {
        const [isLoadingInternal, setIsLoadingInternal] = useState(false)

        const isLoading = props.isLoading ?? isLoadingInternal

        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            if (isLoading || disabled) {
                e.preventDefault()
                e.stopPropagation()
                return null
            }

            // if the onClick handler returned a promise, then
            // set our internal loading state to true and wait
            // for the promise to resolve, then set loading to false
            const promise: Promise<unknown> = onClick?.(e) ?? ({} as Promise<unknown>)

            if (typeof promise === 'object' && 'then' in promise) {
                setIsLoadingInternal(true)
                promise.finally(() => setIsLoadingInternal(false))
            }

            return promise
        }

        const { themeRawVariables } = useTheme()

        const buttonVariables = themeRawVariables.button

        const iconSize = buttonVariables.sizes[`${size}Icon`] as React.ComponentPropsWithoutRef<
            typeof Icon
        >['size']

        const spinnerSize = buttonVariables.sizes[
            `${size}Spinner`
        ] as React.ComponentPropsWithoutRef<typeof Spinner>['size']

        const contentId = useMemo(() => generateUniqueId(), [])

        const hasStringChildren = typeof children === 'string'

        return (
            <ButtonBase
                onClick={handleClick}
                disabled={disabled}
                iconOnly={!children}
                type="button"
                size={size}
                aria-labelledby={children && hasStringChildren ? contentId : undefined}
                aria-busy={isLoading}
                variant={variant}
                {...props}
                ref={ref}
            >
                {isLoading && (
                    <Spinner
                        className={ButtonIconStyles.styleFunction({ variant })}
                        size={spinnerSize}
                        role="presentation"
                    />
                )}
                {startIcon && !isLoading && (
                    <div style={{ position: 'relative', overflow: 'visible' }}>
                        <Icon
                            {...startIcon}
                            className={ButtonIconStyles.styleFunction({ variant })}
                            size={iconSize}
                            role="presentation"
                        />
                        {startIconOverlay}
                    </div>
                )}

                {hasStringChildren && children && <div id={contentId}>{children}</div>}

                {!hasStringChildren && children}
                {endIcon && !isLoading && (
                    <Icon
                        {...endIcon}
                        className={ButtonIconStyles.styleFunction({ variant })}
                        size={iconSize}
                        role="presentation"
                    />
                )}
            </ButtonBase>
        )
    }
)
