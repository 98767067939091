import React, { VFC } from 'react'

import styled from '@emotion/styled'

import type { FieldTypeComponentData } from 'features/admin/fields/definitions/fieldTypeComponents'
import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'
import { getIconForDefinition } from 'features/admin/fields/icons/utils'

import { Text } from 'v2/ui'
import { SolidChevronDown } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const Wrapper = styled.div<{ disabled: boolean }>`
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 8px;
    padding-right: 5px;

    background-color: ${(props) => (props.disabled ? 'rgba(239, 239, 239, 0.7)' : 'transparent')};
`

const StyledSolidChevronDown = styled(SolidChevronDown)`
    transform: scale(1.2);
`

const SelectedFieldTypeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    width: calc(100% - 24px);
`

const Label = styled(Text)<{ disabled: boolean }>`
    color: ${(props) =>
        props.disabled ? colors.userInterface.neutral[800] : colors.userInterface.neutral[1000]};
    font-size: 14px;

    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

type Props = {
    selectedFieldType: FieldTypeComponentData | undefined
    placeholder: string
    disabled: boolean | undefined
    onClick: () => void
}

const SelectedValue: VFC<Props> = ({ selectedFieldType, placeholder, disabled, onClick }) => {
    const fieldIcon = getIconForDefinition(selectedFieldType)

    return (
        <Wrapper disabled={!!disabled} onClick={onClick}>
            {selectedFieldType ? (
                <SelectedFieldTypeWrapper>
                    {!!fieldIcon && (
                        <FieldIcon
                            value={fieldIcon}
                            size="m"
                            alignSelf="center"
                            color="admin700"
                            mr="m"
                        />
                    )}
                    <Label disabled={!!disabled}>{selectedFieldType.label}</Label>
                </SelectedFieldTypeWrapper>
            ) : (
                <Label disabled={!!disabled}>{placeholder}</Label>
            )}
            <StyledSolidChevronDown />
        </Wrapper>
    )
}

export default SelectedValue
