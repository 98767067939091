import React from 'react'

import {
    DropdownHeadSearch,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuSectionEditState } from './hooks/useActionMenuSectionEditState'
import { ActionMenuEditField } from './ActionMenuEditField'

type ActionMenuSectionEditProps = {
    record: RecordDto
    editableFields: FieldDto[]
}

export const ActionMenuSectionEdit: React.FC<ActionMenuSectionEditProps> = ({
    record,
    editableFields,
}) => {
    const { recentFields, otherFields, updateRecord, editOptionLabel } =
        useActionMenuSectionEditState({ record, editableFields })

    if (!recentFields.length && !otherFields.length) return null

    return (
        <DropdownSub>
            {recentFields.map(({ field, values }) => (
                <ActionMenuEditField
                    key={field._sid}
                    field={field}
                    updateRecord={updateRecord}
                    value={record[field.api_name] ?? undefined}
                    recentValues={values}
                    label={field.label}
                    record={record}
                />
            ))}
            {otherFields.length > 0 && (
                <>
                    <DropdownSubTrigger
                        startIcon={{ name: 'PencilLine' }}
                        label={editOptionLabel}
                    />
                    <DropdownSubContent head={<DropdownHeadSearch />} onClick={stopPropagation}>
                        {otherFields.map(({ field, values }) => (
                            <ActionMenuEditField
                                key={field._sid}
                                field={field}
                                updateRecord={updateRecord}
                                value={record[field.api_name] ?? undefined}
                                recentValues={values}
                                label={field.label}
                            />
                        ))}
                    </DropdownSubContent>
                </>
            )}
        </DropdownSub>
    )
}
