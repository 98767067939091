import React from 'react'

import classNames from 'classnames'

import { Users } from 'features/views/attributes/Users'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
} from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAdvancedFilterValueRecordLinksState } from './hooks/useAdvancedFilterValueRecordLinksState'
import { AdvancedFilter } from './types'

import { AdvancedFiltersButtonStyle } from './AdvancedFilters.css'

const MAX_ITEM_LENGTH = 25

type AdvancedFilterValueRecordLinksProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    className?: string
}

export const AdvancedFilterValueRecordLinks: React.FC<AdvancedFilterValueRecordLinksProps> = ({
    filter,
    onChange,
    onRemoveFilter,
    className,
}) => {
    const {
        value,
        onSetFilterValue,
        filterOptions,
        isFetchingSlow,
        isError,
        searchQuery,
        setSearchQuery,
        isSingle,
        overflowLabel,
        valueOptions,
        isLabelLoading,
        isUsersObject,
        usersValueOptions,
        isOpen,
        setIsOpen,
    } = useAdvancedFilterValueRecordLinksState({
        filter,
        onChange,
        maxItemLength: MAX_ITEM_LENGTH,
        onRemoveFilter,
    })

    const size = useResponsiveValue<React.ComponentPropsWithoutRef<typeof DropdownButton>['size']>({
        mobile: 'xs',
        tablet: 's',
    })

    return (
        <Dropdown open={isOpen} onOpenChange={setIsOpen} modal={false}>
            <DropdownButton
                size={size}
                variant="ghost"
                aria-label="Select value"
                className={classNames(className, AdvancedFiltersButtonStyle)}
            >
                {isError ? (
                    <Box flex center gap="xs">
                        <Icon name="AlertCircle" color="iconError" size="s" aria-hidden={true} />
                        <Body size={{ mobile: 's', tablet: 'm' }} color="textError">
                            Error
                        </Body>
                    </Box>
                ) : (
                    <>
                        {isUsersObject ? (
                            <Users
                                isLoading={isLabelLoading}
                                value={usersValueOptions}
                                size={{ mobile: 's', tablet: 'm' }}
                                avatarSize={{ mobile: '2xs', tablet: 'xs' }}
                                overflow
                                showTooltips={false}
                                maxLength={MAX_ITEM_LENGTH}
                            />
                        ) : (
                            <Skeleton isLoading={isLabelLoading} flex center maxWidth="200px">
                                <Box flex center gap="xs">
                                    {valueOptions.map((item, idx) => (
                                        <Box flex center key={item.value}>
                                            <Body
                                                size={{ mobile: 's', tablet: 'm' }}
                                                style={{ fontWeight: 'inherit' }}
                                                color="theme600"
                                            >
                                                {item.label}
                                            </Body>
                                            {idx < valueOptions.length - 1 && (
                                                <Body
                                                    size={{ mobile: 's', tablet: 'm' }}
                                                    style={{
                                                        fontWeight: 'inherit',
                                                        color: 'inherit',
                                                    }}
                                                >
                                                    ,
                                                </Body>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                                {overflowLabel && (
                                    <Body
                                        size={{ mobile: 's', tablet: 'm' }}
                                        style={{ fontWeight: 'inherit', color: 'inherit' }}
                                    >
                                        {overflowLabel}
                                    </Body>
                                )}
                            </Skeleton>
                        )}
                    </>
                )}
            </DropdownButton>
            <DropdownContent
                align="end"
                onClick={stopPropagation}
                head={!isFetchingSlow && <DropdownHeadSearch />}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                style={{ pointerEvents: 'auto' }}
            >
                {isError && (
                    <DropdownItem
                        label={
                            <Body size={{ mobile: 's', tablet: 'm' }} color="textError">
                                There was a problem displaying options
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError && filterOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size={{ mobile: 's', tablet: 'm' }} color="textHelper">
                                No items found
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError &&
                    filterOptions.map((item) => (
                        <DropdownItem
                            key={item.value}
                            multiSelect={!isFetchingSlow}
                            label={
                                isFetchingSlow ? (
                                    <Skeleton isLoading={isFetchingSlow}>{item.label}</Skeleton>
                                ) : (
                                    item.label
                                )
                            }
                            checked={value.has(item.value)}
                            onCheckedChange={(checked) => {
                                onSetFilterValue(item.value, checked)
                            }}
                            disabled={isFetchingSlow}
                            startAvatar={item.avatar}
                            closeOnSelect={isSingle}
                        />
                    ))}
            </DropdownContent>
        </Dropdown>
    )
}
