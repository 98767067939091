// @ts-strict-ignore
import React, { useMemo, useState } from 'react'
import { components } from 'react-select'

import { FlexProps } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { Rights } from 'app/accountUserContextConstants'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useAppContext } from 'app/useAppContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { AccountRoleType, BuiltInAccountRoleNames } from 'data/hooks/accountRoleTypes'
import { workspaceGroups } from 'data/hooks/accounts'
import { useRoleListOptions } from 'data/hooks/roles'
import { useUpdateStack } from 'data/hooks/stacks'
import {
    refetchAppUsersForAdmin,
    useAddWorkspaceUser,
    useWorkspaceUsers,
} from 'data/hooks/users/main'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { assertIsDefined } from 'data/utils/ts_utils'
import analytics from 'utils/analytics'

import { Avatar, Box, Button, Dropdown, Flex, Icon } from 'v2/ui'

const isEmailRegEx = /^(.+)@(.+)$/
type Props = {
    showRoles?: boolean
    defaultRole?: string
    workspaceRole?: AccountRoleType
    showGroups?: boolean
    filterUsers?: (user: any) => boolean
    containerProps?: FlexProps
}

type AddUserOption = {
    group?: any
    user?: any
    record?: any
    name?: string
    email?: string
    value?: string
}

export default function AddUserDropdown({
    showRoles = true,
    defaultRole = 'user',
    workspaceRole,
    showGroups = true,
    filterUsers,
    containerProps,
}: Props) {
    const { workspaceZone } = useWorkspaceContext()
    const { data: appUsers = [] } = useAppUsersForAdmin(true, undefined, {
        includeUsersWithNoAccess: true,
    })
    const [selectedUser, setSelectedUser] = useState<AddUserOption | undefined>(undefined)
    const { hasRight } = useAccountUserContext()
    const [selectedRole, setSelectedRole] = useState(defaultRole)
    const [emailAddress, setEmailAddress] = useState('')
    const { selectedStack } = useAppContext()

    const { mutateAsync: updateStack } = useUpdateStack()
    const { mutateAsync: addUser } = useAddWorkspaceUser()
    const canInvite = hasRight(Rights.InviteUsers)

    const { data: roles } = useRoleListOptions(undefined, true)
    const { options: userOptions } = useAddAppUsersOptions({
        appUsers,
        showGroups,
        filterUserOverride: filterUsers,
    })

    const addUserToSharingSettings = (userId, role) => {
        const item = userOptions.find((u) => u.value === userId)
        const patch = updateStackSharingSettings(selectedStack, item?.group ? 'groups' : 'users', {
            [userId]: role,
        })
        assertIsDefined(selectedStack)
        return updateStack({
            id: selectedStack._sid,
            patch: { ...patch },
        } as any).then(async () => {
            await refetchAppUsersForAdmin()
        })
    }

    const refreshAfterSave = () => {
        return Promise.resolve(undefined)
            .then(refetchAppUsersForAdmin)
            .then(() => {
                setEmailAddress('')
                setSelectedUser(undefined)
            })
    }
    const handleInvite = (emailAddress) => {
        let desiredWorkspaceRole = workspaceRole

        if (!desiredWorkspaceRole) {
            const isPortal = workspaceZone?.type === 'Portal'
            const invitingAsAdmin = selectedRole === 'internal_admin'

            if (!isPortal || invitingAsAdmin) {
                desiredWorkspaceRole = BuiltInAccountRoleNames.MEMBER
            } else {
                desiredWorkspaceRole = BuiltInAccountRoleNames.PORTAL_USER
            }
        }

        const skipEmail = selectedStack?.options?.disable_invite_emails

        // Add the user to the workspace first, then add to the app
        return addUser({
            email: emailAddress,
            options: { role: desiredWorkspaceRole },
            send_email: !skipEmail,
        }).then((response) => {
            return addUserToSharingSettings(response._sid, selectedRole).then(refreshAfterSave)
        })
    }

    const handleSubmit = () => {
        const selectedOption = userOptions.find((option) => option.value === selectedUser)
        if (emailAddress) {
            return handleInvite(emailAddress)
        } else if (selectedOption?.record) {
            return handleInvite(selectedOption?.email)
        } else {
            return addUserToSharingSettings(selectedUser, selectedRole).then(refreshAfterSave)
        }
    }

    const isEmailAddressDuplicate =
        emailAddress &&
        appUsers.some((user) => user.email.toLowerCase() === emailAddress.trim().toLowerCase())

    const finalUserOptions = [
        ...userOptions,
        ...(canInvite && emailAddress && !isEmailAddressDuplicate
            ? [{ isInvitation: true, value: emailAddress, label: emailAddress }]
            : []),
        ...(canInvite && !emailAddress
            ? [
                  {
                      isInvitation: true,
                      value: '',
                      label: 'someone by typing their email address',
                  },
              ]
            : []),
    ]

    const getNoOptionsMessage = ({ inputValue }) => {
        const existingUser = appUsers.find(
            (x) => x.email.toLowerCase() === inputValue.trim().toLowerCase()
        )
        if (existingUser) {
            return `${existingUser.name || existingUser.email} already has access to this app`
        }

        if (canInvite) {
            return 'Enter an email address to invite someone.'
        }

        return 'No matching users found.'
    }

    const handleInputChange = (value) => {
        if (isEmailRegEx.test(value) && !appUsers.find((x) => x.email === value)) {
            setEmailAddress(value)
        }
    }

    const placeholder = canInvite
        ? userOptions.length > 0
            ? 'Select user or enter email address'
            : 'Enter an email address'
        : 'Select user'
    return (
        <>
            <Flex wrap="nowrap" align="stretch" {...containerProps}>
                <Dropdown
                    placeholder={placeholder}
                    style={{
                        flexShrink: 1,
                        flexGrow: 1,
                        minWidth: 0,
                        background: 'white',
                    }}
                    onChange={(userId) => {
                        if (userId !== emailAddress) setEmailAddress('')
                        setSelectedUser(userId)
                    }}
                    value={selectedUser || emailAddress}
                    padding="5px"
                    noOptionsMessage={getNoOptionsMessage}
                    optionComponent={UserDropdownOption}
                    options={finalUserOptions}
                    onInputChange={handleInputChange}
                    shouldHidePlaceholderOnFocus={true}
                    renderValue={(data) => (
                        <UserDropdownOptionContent data={data} isSelected={true} />
                    )}
                />
                <Box width={2} height={2} />
                {showRoles && (
                    <SelectRole
                        roles={roles}
                        placeholder="Select a role"
                        selected={selectedRole}
                        onChange={setSelectedRole}
                        variant="settings"
                    />
                )}
                <Button
                    ml={2}
                    variant="adminPrimaryV4"
                    alignSelf="center"
                    buttonSize="sm"
                    flexShrink={0}
                    disabled={
                        (!selectedUser && !canInvite) ||
                        (!selectedUser && !emailAddress) ||
                        // @ts-ignore
                        selectedRole === selectedStack?.sharing?.users?.[selectedUser]
                    }
                    onClick={() => {
                        // @ts-ignore
                        analytics.track('app sharing user added', {
                            selectedUser,
                            selectedRole,
                        })

                        return handleSubmit()
                    }}
                >
                    {canInvite && (emailAddress || userOptions.length === 0) ? 'Invite' : 'Add'}
                </Button>
            </Flex>
        </>
    )
}

type useAddAppUsersOptionsProps = {
    appUsers: any[]
    showGroups: boolean
    filterUserOverride?: (user: any) => boolean
}
function useAddAppUsersOptions({
    appUsers,
    showGroups,
    filterUserOverride,
}: useAddAppUsersOptionsProps) {
    const { data: workspaceUsers = [], isLoading: isWorkspaceUsersLoading } = useWorkspaceUsers()

    const { selectedStack } = useAppContext()

    const sharingWithGroups = selectedStack?.sharing?.groups || {}
    const userOptions = useMemo(() => {
        const groups = showGroups
            ? workspaceGroups
                  .filter(({ _sid }) => !sharingWithGroups[_sid])
                  .map((group) => ({ group, value: group._sid, label: group.name }))
            : []

        const users = orderBy(
            workspaceUsers
                .filter((workspaceUser) =>
                    filterUserOverride
                        ? filterUserOverride(workspaceUser)
                        : !appUsers.find((user) => user._sid === workspaceUser._sid && !user.group)
                )
                .map((user) => ({
                    user,
                    name: user.name || user.email,
                    email: user.email,
                    value: user._sid,
                    label: `${user.name} ${user.email}`,
                })),
            (item) => item.name.toLowerCase()
        )

        return [...groups, ...users]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceUsers, appUsers, sharingWithGroups])

    return {
        options: userOptions,
        isLoading: isWorkspaceUsersLoading,
    }
}

function UserDropdownOption(props) {
    // dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anway.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props

    return (
        <components.Option {...rest}>
            <UserDropdownOptionContent data={props.data}>
                {props.children}
            </UserDropdownOptionContent>
        </components.Option>
    )
}

function UserDropdownOptionContent(props): React.ReactElement {
    const { data, children, isSelected } = props
    const { email, group, label, name, record, user } = data
    if (group) {
        return (
            <Flex align="center">
                <Flex align="center" justify="center" width="24px" height="24px" mr={2}>
                    <Icon icon="users" size="sm" color="gray.400" />
                </Flex>

                <strong>{group.name}</strong>
            </Flex>
        )
    } else if (user || record) {
        return (
            <Flex align="center">
                <Avatar src={user?.avatar} name={name} size="xs" mr={2} />
                <strong>{name}</strong>
                <span style={{ marginLeft: '8px' }}>{email}</span>
            </Flex>
        )
    } else if (!isSelected) {
        return (
            <Flex align="center" cursor="pointer">
                <Flex align="center" justify="center" width="24px" height="24px" mr={2}>
                    <Icon icon="email" size="sm" color="gray.400" />
                </Flex>
                Invite {children}
            </Flex>
        )
    } else if (isEmailRegEx.test(label)) {
        return <>{label}</>
    } else {
        return <></>
    }
}

export function SelectRole({ selected, onChange, roles, placeholder = 'No access', ...props }) {
    return (
        <Dropdown
            value={selected}
            options={roles}
            placeholder={placeholder}
            onChange={onChange}
            isSearchable={false}
            data-testid="select-role"
            {...props}
        />
    )
}
function updateStackSharingSettings(stack, path, update) {
    return {
        sharing_patch: {
            [path]: {
                ...update,
            },
        },
    }
}
