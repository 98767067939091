import { useCallback, useMemo } from 'react'

import { useDroppable } from '@dnd-kit/core'

import { DEFAULT_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { useLayoutEditorWidgetAreaContext } from 'features/views/LayoutEditor/useLayoutEditorWidgetAreaContext'
import { useLayoutEditorWidgetContext } from 'features/views/LayoutEditor/useLayoutEditorWidgetContext'

type UseLayoutEditorWidgetAreaStateOptions = {
    id: string
}

export function useLayoutEditorWidgetAreaState(options: UseLayoutEditorWidgetAreaStateOptions) {
    const { id } = options

    const { commands } = useLayoutEditorContext()
    const { children, path, isEditing } = useLayoutEditorWidgetAreaContext()!
    const { isDragging = false, widget } = useLayoutEditorWidgetContext() ?? {}

    const sortableContextId = widget ? `${widget.id}.${id}` : id

    const { setNodeRef: ref, isOver: isDraggingOver } = useDroppable({
        id: sortableContextId,
        data: {
            id,
            type: 'widgetArea',
            path,
            childrenCount: children.length,
        },
        disabled: isDragging,
    })

    const onClickPlaceholderWidget = useCallback(
        (widgetType: string) => {
            commands.insertWidgetAtPath(widgetType, path)
        },
        [commands, path]
    )

    const isMainWidgetArea = id === DEFAULT_WIDGET_AREA_ID
    const isRootWidgetArea = path.length === 1

    return useMemo(
        () => ({
            children,
            ref,
            isDraggingOver,
            sortableContextId,
            isDragging,
            isEditing,
            onClickPlaceholderWidget,
            isMainWidgetArea,
            isRootWidgetArea,
        }),
        [
            children,
            isDragging,
            isDraggingOver,
            isEditing,
            onClickPlaceholderWidget,
            ref,
            sortableContextId,
            isMainWidgetArea,
            isRootWidgetArea,
        ]
    )
}
