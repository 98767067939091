import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { useKeyboardShortcutLabel } from 'ui/helpers/useKeyboardShortcutLabel'

import { useNavigationStateControlState } from './hooks/useNavigationStateControlState'
import { NavigationItem } from './NavigationItem'

import {
    AppNavigationItemIconStyles,
    AppNavigationItemIconWrapperStyle,
    AppNavigationItemLabelStyles,
    AppNavigationItemStyles,
    NavigationFooterItemLabelStyle,
    NavigationFooterItemStyle,
} from './Navigation.css'

type NavigationStateControlProps = {}

export const NavigationStateControl: React.FC<NavigationStateControlProps> = () => {
    const { toggleNavigationState, navigationState } = useNavigationStateControlState()
    const keyboardShortcutLabel = useKeyboardShortcutLabel({
        mac: '⌘/',
        default: 'Ctrl+/',
    })

    return (
        <Box flex center gap="m" width="full" px="m" noShrink>
            <NavigationItem
                onClick={() => {
                    toggleNavigationState((prev) => {
                        return prev === 'static' ? 'floating-expanded' : 'static'
                    })
                }}
                className={classNames(
                    AppNavigationItemStyles.styleFunction({
                        isActive: false,
                        hasChildren: false,
                        hasIcon: true,
                    }),
                    NavigationFooterItemStyle
                )}
                trim
                label={
                    <Box flex center gap="s" trim className={NavigationFooterItemLabelStyle}>
                        <Box position="relative" className={AppNavigationItemIconWrapperStyle}>
                            <Box
                                className={AppNavigationItemIconStyles.styleFunction({
                                    isActive: false,
                                })}
                                style={{ color: 'inherit' }}
                            >
                                <Icon
                                    size="s"
                                    name={
                                        navigationState === 'static'
                                            ? 'PanelLeftClose'
                                            : 'PanelLeftOpen'
                                    }
                                />
                            </Box>
                        </Box>
                        <Box flex center gap="2xs" justifyContent="space-between" grow pr="2xs">
                            <Body
                                size="m"
                                weight="medium"
                                trim
                                className={AppNavigationItemLabelStyles.styleFunction({
                                    isActive: false,
                                })}
                                style={{ color: 'inherit' }}
                            >
                                {navigationState === 'static'
                                    ? 'Close navigation'
                                    : 'Lock navigation open'}
                            </Body>
                            <Body size="s" weight="medium" color="darkAlpha400" noShrink>
                                {keyboardShortcutLabel}
                            </Body>
                        </Box>
                    </Box>
                }
            />
        </Box>
    )
}
