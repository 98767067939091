import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'
import type { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'

import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import { ContentIsOverflowingBottomStyle, ScrollAreaStyle } from 'ui/components/Menu/Menu.css'
import { ScrollArea } from 'ui/components/ScrollArea'
import { StandardComponentProps } from 'ui/helpers/styles'
import { useTheme } from 'ui/styling/themes/useTheme'

import { Content, Portal } from './Dropdown.parts'
import { useDropdownContentContext } from './dropdownContentContext'
import { DropdownContentContextProvider } from './DropdownContentContextProvider'
import { useDropdownContext } from './dropdownContext'
import { DropdownEmptySearch } from './DropdownEmptySearch'
import { useContentClose } from './useContentClose'
import { useContentKeyDown } from './useContentKeyDown'
import { filterDropdownItems } from './utils'

type DropdownContentRef = HTMLDivElement

type DropdownContentProps = Omit<DropdownMenuContentProps, 'asChild'> &
    StandardComponentProps & {
        usePortal?: boolean
        head?: React.ReactNode
        footer?: React.ReactNode
        maxHeight?: React.ComponentPropsWithoutRef<typeof ScrollArea>['maxHeight']
        maxWidth?: React.ComponentPropsWithoutRef<typeof ScrollArea>['maxWidth']
        searchQuery?: string
        setSearchQuery?: (value: string) => void
        scrollCheckedIntoView?: boolean
    }

const DropdownContentInner = forwardRef<DropdownContentRef, DropdownContentProps>(
    (
        {
            usePortal = true,
            align = 'start',
            sideOffset = 4,
            children,
            head,
            onCloseAutoFocus,
            className,
            scrollCheckedIntoView = false,
            footer,
            ...props
        },
        ref
    ) => {
        const { searchQuery, isSearchQueryControlled } = useDropdownContentContext()

        const filteredChildren = useMemo(() => {
            const childrenArray = React.Children.toArray(children)
            if (isSearchQueryControlled) return childrenArray

            return filterDropdownItems(childrenArray as React.ReactElement[], searchQuery)
        }, [children, isSearchQueryControlled, searchQuery])

        const handleKeyDown = useContentKeyDown()
        const handleClose = useContentClose(onCloseAutoFocus)

        const hasSearchQuery = searchQuery.trim().length > 0

        const { isOpen } = useDropdownContext()!

        const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)

        useEffect(() => {
            if (!isOpen || !scrollCheckedIntoView) return

            // Automatically scroll to the selected item.
            const checkedItem = contentRef?.querySelector('[data-state="checked"]')
            if (!checkedItem) return

            requestAnimationFrame(() => {
                checkedItem.scrollIntoView({
                    block: 'start',
                })
            })
        }, [isOpen, contentRef, scrollCheckedIntoView])

        const { themeClassName } = useTheme()

        const { scrollAreaRef, targetRef } = useOverflowY({
            bottomClassName: ContentIsOverflowingBottomStyle,
        })

        const content = (
            <Content
                ref={composeRefs(
                    setContentRef as React.RefCallback<HTMLDivElement>,
                    ref,
                    targetRef as React.Ref<HTMLDivElement>
                )}
                align={align}
                sideOffset={sideOffset}
                onKeyDown={handleKeyDown}
                onCloseAutoFocus={handleClose}
                {...props}
                className={classNames(themeClassName, 'ag-custom-component-popup', className)}
            >
                <ScrollArea
                    ref={scrollAreaRef}
                    direction="vertical"
                    className={ScrollAreaStyle}
                    type="auto"
                >
                    {head}
                    {filteredChildren}
                    {footer}
                </ScrollArea>
                {hasSearchQuery && filteredChildren.length === 0 && <DropdownEmptySearch />}
            </Content>
        )

        if (usePortal) {
            return <Portal>{content}</Portal>
        }

        return content
    }
)

export const DropdownContent = forwardRef<DropdownContentRef, DropdownContentProps>(
    ({ searchQuery, setSearchQuery, ...props }, ref) => {
        return (
            <DropdownContentContextProvider
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            >
                <DropdownContentInner ref={ref} {...props} />
            </DropdownContentContextProvider>
        )
    }
)
