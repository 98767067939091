// @ts-strict-ignore
import React, { FC, ReactNode, useMemo } from 'react'

import styled from '@emotion/styled'
import sortBy from 'lodash/sortBy'

import { ToggleSortPicker } from 'features/views/List/ToggleSortPicker'
import { IconPickerForm } from 'features/workspace/forms/IconPicker'

import { Flex } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

// @ts-ignore
import Form from 'ui/deprecated/forms/Form'

import ChangeNavigationRole from './ChangeNavigationRole'
import Handle, { HANDLE_WIDTH } from './Handle'
import MenuLabelViewEdit from './MenuLabelViewEdit'

const { colors } = stackerTheme()

const BoundingBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - ${HANDLE_WIDTH}px - 10px);
    min-height: 35px;

    border: 1px solid ${colors.userInterface.neutral[400]};
    border-radius: 5px;
`

const LeftRightDivider = styled.div<{ hasParent: boolean }>`
    display: flex;
    align-items: center;

    height: 100%;

    padding-left: 8px;

    border-right: 1px solid ${colors.userInterface.neutral[400]};
    border-left: ${(props) =>
        props.hasParent ? `1px solid ${colors.userInterface.neutral[400]}` : 'none'};
`

const TopLevel = styled.div`
    z-index: 1400;

    display: flex;
    flex-direction: column;

    margin: 10px 0px 0px 0px;
`

const SecondLevel = styled.div`
    z-index: 1400;

    display: block;
    width: calc(100% - 25px);

    margin: 5px 0px 0px 25px;
`

type Props = {
    isTopLevel: boolean
    items: any[]
    itemWrapper?: ReactNode
    changeHidden: (item: any) => void
    changeOrder: (item: any, oldIndex: number, newIndex: number) => void
    changeLabel: (item: any, newLabel: string) => void
    updateNavIcon: (icon: any, navItem: any) => void
    iconPack?: string
    isFetching?: boolean
    firstEnabledNavItem?: NavigationDto
}

/*
The logic to display either the folder or the link depending on its children is as follows:
    - if the item is a folder (with children) and only 1 child, display the child instead of the folder
    - if the item is a folder with more than 1 children, display the folder and another sortable list of links (without children)
    - if there are no children, then the item is a link, so display it
*/
const Item: FC<
    Omit<Props, 'items' | 'itemWrapper'> & {
        item: any
        toggle: ReactNode
        updateNavIcon: (icon: any, item: any) => void
        iconPack?: string
        isFetching?: boolean
        showIconEditor?: boolean
    }
> = ({
    firstEnabledNavItem,
    item,
    toggle,
    changeHidden,
    changeLabel,
    changeOrder,
    updateNavIcon,
    iconPack,
    isFetching = false,
    showIconEditor,
}) => {
    const isHome = firstEnabledNavItem?._sid === item._sid
    const hasChildren = item.children?.length > 0
    const { topNav, secondNav } = useMemo((): { topNav: ReactNode; secondNav: ReactNode } => {
        if (!item.children) {
            return {
                topNav: <MenuLabelViewEdit isHome={isHome} item={item} changeLabel={changeLabel} />,
                secondNav: null,
            }
        }

        if (item.children.length > 1) {
            return {
                topNav: <MenuLabelViewEdit isHome={isHome} item={item} changeLabel={changeLabel} />,
                secondNav: (
                    <NavigationPicker
                        changeHidden={changeHidden}
                        changeLabel={changeLabel}
                        changeOrder={changeOrder}
                        items={sortBy(item.children, ['display_order'])}
                        isTopLevel={false}
                        itemWrapper={SecondLevel}
                        updateNavIcon={updateNavIcon}
                        iconPack="far"
                    />
                ),
            }
        }

        const navLink = item.children.find((navItem) => navItem.parent_id !== null)
        if (navLink) {
            return {
                topNav: (
                    <MenuLabelViewEdit isHome={isHome} item={navLink} changeLabel={changeLabel} />
                ),
                secondNav: null,
            }
        }

        return { topNav: null, secondNav: null }
    }, [item, isHome, changeLabel, changeHidden, changeOrder, updateNavIcon])

    const onIconChange = (icon, item) => {
        // icon can be undefined or null as well, undefined means value was not initialised and therefore should be
        // ignored, null means the icon is being unset
        if (icon === undefined) {
            return
        }
        updateNavIcon(icon, item)
    }

    return (
        <>
            <Flex flexDirection="row" alignItems="center" style={{ width: '100%' }}>
                <Handle />
                <BoundingBox>
                    <Flex
                        alignItems="center"
                        style={{ paddingLeft: showIconEditor ? '8px' : '18px' }}
                    >
                        {showIconEditor && (
                            <Flex>
                                <Form
                                    onSubmit={() => {}}
                                    options={{
                                        mode: 'onChange',
                                        defaultValues: {
                                            ...item.options,
                                        },
                                    }}
                                >
                                    <IconPickerForm
                                        justify="flex-start"
                                        border="nav-icons"
                                        margin="0"
                                        name="icon"
                                        onIconChange={onIconChange}
                                        item={item}
                                        iconPack={iconPack}
                                        isFetching={isFetching}
                                    />
                                </Form>
                            </Flex>
                        )}
                        {topNav}
                    </Flex>
                    <Flex alignItems="center">
                        <LeftRightDivider hasParent={item.parent_id !== null}>
                            <ChangeNavigationRole item={item} />
                        </LeftRightDivider>
                        {!hasChildren && (
                            <div style={{ marginRight: '-5px', paddingLeft: '3px' }}>{toggle}</div>
                        )}
                    </Flex>
                </BoundingBox>
            </Flex>
            {secondNav}
        </>
    )
}

const NavigationPicker: FC<Props> = ({
    firstEnabledNavItem,
    items,
    itemWrapper,
    isTopLevel,
    changeHidden,
    changeLabel,
    changeOrder,
    updateNavIcon,
    isFetching = false,
}) => {
    const tree = useMemo(
        () => items.map((item) => (item.children?.length === 1 ? item.children[0] : item)),
        [items]
    )

    const toggleItem = (item: any): any => ({ ...item, hidden: !item.hidden })

    const isItemSelected = (item: any): boolean => {
        if (item.children && item.children.length === 1) {
            return !item.children[0].hidden
        }

        return !item.hidden
    }

    const itemToId = (item: any): string => item._sid

    const onReorder = (oldIndex: number, newIndex: number): void => {
        changeOrder(items, oldIndex, newIndex)
    }

    const onToggle = (index: number): void => {
        changeHidden(tree[index])
    }

    const showIconEditor = isTopLevel

    return (
        <ToggleSortPicker
            items={tree}
            renderItem={(item: any, toggle: ReactNode) => (
                <Item
                    firstEnabledNavItem={firstEnabledNavItem}
                    isFetching={isFetching}
                    item={item}
                    toggle={toggle}
                    isTopLevel={isTopLevel}
                    changeHidden={changeHidden}
                    changeLabel={changeLabel}
                    changeOrder={changeOrder}
                    updateNavIcon={updateNavIcon}
                    iconPack="far"
                    showIconEditor={showIconEditor}
                />
            )}
            toggleItem={toggleItem}
            isSelected={isItemSelected}
            itemToId={itemToId}
            onReorder={onReorder}
            onToggle={onToggle}
            ItemWrapper={itemWrapper || TopLevel}
            isInSettingsModal
        />
    )
}

export default NavigationPicker
