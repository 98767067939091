import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tooltip } from 'ui/components/Tooltip'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useWorkspaceNavigationState } from './hooks/useWorkspaceNavigationState'
import * as Parts from './Navigation.parts'
import { NavigationCurrentUserDropdown } from './NavigationCurrentUserDropdown'
import { NavigationPortals } from './NavigationPortals'
import { NavigationSpaces } from './NavigationSpaces'
import { NavigationTrialBanner } from './NavigationTrialBanner'
import { SystemNavigation } from './SystemNavigation'
import { WorkspaceNavigationHeader } from './WorkspaceNavigationHeader'
import { WorkspaceNavigationLinks } from './WorkspaceNavigationLinks'

import { WorkspaceNavigationCollapsedToggleStyle } from './Navigation.css'

type WorkspaceNavigationProps = {}

export const WorkspaceNavigation: React.FC<WorkspaceNavigationProps> = () => {
    const {
        isWorkspaceNavCollapsed,
        workspaceNavState,
        toggleWorkspaceNavCollapsed,
        tooltipText,
        hasAppSelected,
        showCollapseToggle,
        isNavHidden,
        showTrialBanner,
        isMobileDevice,
    } = useWorkspaceNavigationState()

    if (isNavHidden) return null

    return (
        <ThemeProvider
            as={Parts.WorkspaceNavigationWrapper}
            theme={hasAppSelected ? 'default' : 'admin'}
            state={workspaceNavState}
            grow
            shrink
        >
            {showCollapseToggle && (
                <Tooltip asChild side="bottom" align="center" content={tooltipText} zIndex={201}>
                    <Button
                        startIcon={{
                            name: isWorkspaceNavCollapsed ? 'ChevronRight' : 'ChevronLeft',
                        }}
                        size="s"
                        variant="secondary"
                        onClick={toggleWorkspaceNavCollapsed}
                        className={WorkspaceNavigationCollapsedToggleStyle}
                        aria-label={tooltipText}
                    />
                </Tooltip>
            )}

            <Box flex column height="full" grow shrink>
                <Box flex column height="full" grow shrink>
                    {!isMobileDevice && <WorkspaceNavigationHeader />}
                    <Box flex column grow shrink pt={isMobileDevice ? 'xl' : undefined}>
                        <SystemNavigation isCollapsed={isWorkspaceNavCollapsed} />
                        <WorkspaceNavigationLinks isCollapsed={isWorkspaceNavCollapsed}>
                            <NavigationSpaces isCollapsed={isWorkspaceNavCollapsed} />
                            <NavigationPortals isCollapsed={isWorkspaceNavCollapsed} />
                        </WorkspaceNavigationLinks>
                    </Box>
                </Box>
                {workspaceNavState === 'static' && showTrialBanner && (
                    <Box px="l" mb="m">
                        <NavigationTrialBanner background="gray50" p="xl" rounded="m" />
                    </Box>
                )}
                <NavigationCurrentUserDropdown isCollapsed={isWorkspaceNavCollapsed} />
            </Box>
        </ThemeProvider>
    )
}
