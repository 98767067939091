import React, {
    FC,
    KeyboardEvent,
    TransitionEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/useAppContext'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { useLeftOffset } from 'features/core/useLeftOffset'
import {
    SIDE_PANE_ANIMATION_DURATION,
    SIDE_PANE_DEFAULT_WIDTH,
} from 'features/workspace/AdminSideTray/constants'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import UnsavedChangesModal from 'features/workspace/AdminSideTray/modals/UnsavedChangesModal'
import type { SlidingPaneKey } from 'features/workspace/AdminSideTray/types'

import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDebounce from 'v2/ui/utils/useDebounce'

import { SLIDING_PANES } from './slidingPanes'

const ADMIN_SIDE_TRAY_WIDTH = 46

const ResizingArea = styled.div<{ resizedWidth?: string | null }>`
    cursor: col-resize;
    width: 25px;
    margin-right: -12px;
    height: 100%;
    pointer-events: auto;
    display: flex;
    align-items: center;
    position: absolute;
    right: ${(p) => `calc(${p.resizedWidth || '0px'} - ${ADMIN_SIDE_TRAY_WIDTH}px)`};
    z-index: 999; /** Just below the side bar buttons*/
`

const Wrapper = styled.div<{
    isOpen: boolean
    width: string
    disableAnimations: boolean
    isDrag: boolean
}>`
    user-select: ${(p) => (p.isDrag ? 'none' : 'initial')};
    position: fixed;
    left: 100vw;
    right: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    min-width: ${(props) => props.width};
    height: 100dvh;
    height: -webkit-fill-available;

    outline: none;
    pointer-events: none;

    z-index: 1000;

    transform: ${(props) =>
        props.isOpen
            ? `translateX(calc(${props.width}*-1 - ${ADMIN_SIDE_TRAY_WIDTH}px))`
            : `translateX(${-ADMIN_SIDE_TRAY_WIDTH}px)`};

    ${(props) =>
        !props.disableAnimations &&
        css`
            transition: transform ${SIDE_PANE_ANIMATION_DURATION}ms ease-in-out;
        `}
`

const ContentWrapper = styled.div<{ contentWidth: string }>`
    box-shadow: -4px 0 6px 0 rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    height: 100%;
    min-width: ${(props) => props.contentWidth};
    max-width: ${(props) => props.contentWidth};
    border-top-left-radius: 10px;
    cursor: default;
`

type SlidingPaneProps = {}
const SlidingPane: FC<SlidingPaneProps> = ({ children }) => {
    const slidingPaneRef = useRef<HTMLDivElement | null>(null)
    const resizeTimerRef = useRef<number>(-1)
    const clearContentTimerRef = useRef<number>(-1)
    const [disabledAnimations, setDisableAnimations] = useState(false)

    const {
        state: { key, contentProps, contentWidth, transitionToWidth, blockedContent },
        close,
        clearContent,
        setAnimationComplete,
        showEditLayout,
    } = useSlidingPane()

    const keyRef = useRef<SlidingPaneKey | null>(null)
    keyRef.current = key

    const { isOpen: isEditModeOpen } = useEditMode()

    const leftOffset = useLeftOffset() || 50

    // on small screens, we want to overlap the nav bar by a bit
    const defaultContentWidth = `calc(100vw - ${leftOffset - 1}px)`

    // RESIZE
    const isResizablePane = useMemo(() => key === 'data-grid', [key])

    const { selectedStack } = useAppContext()
    const [isDrag, setIsDrag] = useState<boolean>(false)

    const toPixels = (width: number | null) =>
        width !== null
            ? `${Math.min(width, window.innerWidth - ADMIN_SIDE_TRAY_WIDTH - 20)}px`
            : null

    let storedWidth = toPixels(contentWidth) || defaultContentWidth
    if (localStorage.getItem(`${selectedStack?._sid}_expanded_pane_width`)) {
        storedWidth = localStorage.getItem(`${selectedStack?._sid}_expanded_pane_width`) || ''
    }

    const [resizedWidth, setResizedWidth] = useState<string | null>(storedWidth)
    const resizableContentRef = useRef<HTMLDivElement>(null)

    const customContentWidth = toPixels(transitionToWidth) || resizedWidth
    const PaneComponent = SLIDING_PANES.get(key)

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (
                event.key !== 'Escape' ||
                !slidingPaneRef.current?.contains(event.target as HTMLElement) ||
                event.target instanceof HTMLButtonElement ||
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement
            ) {
                return
            }

            close()
        },
        [close]
    )

    const onMouseDown = useCallback((event: MouseEvent) => {
        if (resizableContentRef.current === event.target) {
            setIsDrag(true)
        }
    }, [])

    const debounceStoreWidth = useDebounce(
        () => {
            localStorage.setItem(
                `${selectedStack?._sid}_expanded_pane_width`,
                resizedWidth?.toString() || ''
            )
        },
        100,
        [selectedStack, resizedWidth]
    )

    const onMouseUp = useCallback(
        (_event: MouseEvent) => {
            if (isDrag) {
                debounceStoreWidth()
            }
            setIsDrag(false)
        },
        [debounceStoreWidth, isDrag]
    )

    const onMouseMove = useCallback(
        (event: MouseEvent) => {
            if (isDrag) {
                const resizedWidth = window.innerWidth - event.clientX
                if (
                    resizedWidth > SIDE_PANE_DEFAULT_WIDTH &&
                    // So that the app bar is always visible
                    resizedWidth < window.innerWidth - ADMIN_SIDE_TRAY_WIDTH * 2
                ) {
                    setResizedWidth(toPixels(resizedWidth))
                }
            }
        },
        [isDrag]
    )

    const handleTransitionEnd = (e: TransitionEvent) => {
        // When the transitions end on the wrapper, then set the animation as completed
        // in the state
        if (e.target === slidingPaneRef.current) {
            setAnimationComplete()

            if (!key) {
                // Seem to need a slight delay here because have observed
                // the content clearing before the pane fully closes.
                // @ts-ignore
                clearContentTimerRef.current = setTimeout(clearContent, 200)
            } else {
                slidingPaneRef.current?.focus()
            }
        }
    }

    useEffect(() => {
        if (isResizablePane) {
            setResizedWidth(storedWidth)
        } else {
            setResizedWidth(toPixels(contentWidth))
        }
    }, [storedWidth, contentWidth, key, isResizablePane])

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('mouseup', onMouseUp)

        return () => {
            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('mouseup', onMouseUp)
        }
    }, [onMouseMove, onMouseUp])

    useEffect(() => {
        // If edit mode is open and we aren't already in the edit-layout pane
        // (and also not in the process of transitioning to that pane,
        // and also it was not previously blocked), then
        // open that pane now.
        if (isEditModeOpen && blockedContent?.key !== 'edit-layout') {
            showEditLayout()
        }
    }, [isEditModeOpen, showEditLayout, blockedContent, key])

    useEffect(() => {
        if (!isResizablePane) return

        // If the pane is open and the width is greater than the window width,
        // then set the width to the default width. We need to to do this
        // when we mount the component and when we resize the viewport
        if (Number(storedWidth.replace('px', '')) >= window.innerWidth) {
            setResizedWidth(defaultContentWidth)
        }
        const resizeHandler = () => {
            if (Number(storedWidth.replace('px', '')) >= window.innerWidth) {
                setResizedWidth(defaultContentWidth)
            }
            clearTimeout(resizeTimerRef.current)
            setDisableAnimations(true)

            // @ts-ignore
            resizeTimerRef.current = setTimeout(() => {
                setDisableAnimations(false)
            }, SIDE_PANE_ANIMATION_DURATION)
        }

        const handlePopstate = () => {
            if (keyRef.current !== 'edit-layout') {
                close()
            }
        }
        window.addEventListener('resize', resizeHandler)
        window.addEventListener('popstate', handlePopstate)

        return () => {
            window.removeEventListener('resize', resizeHandler)
            window.removeEventListener('popstate', handlePopstate)
            clearTimeout(resizeTimerRef.current)
            clearTimeout(clearContentTimerRef.current)
        }
    }, [close, defaultContentWidth, isResizablePane, storedWidth])

    useEffect(() => {
        if (key) {
            clearTimeout(clearContentTimerRef.current)
        }
    }, [key])

    return (
        <>
            <Wrapper
                ref={slidingPaneRef}
                isOpen={!!key}
                isDrag={isDrag}
                width={customContentWidth ? `${customContentWidth}` : defaultContentWidth}
                onKeyDown={onKeyDown}
                tabIndex={0}
                disableAnimations={disabledAnimations || isDrag}
                onTransitionEnd={handleTransitionEnd}
                // @ts-expect-error
                onMouseDown={onMouseDown}
                // @ts-expect-error
                onMouseUp={onMouseUp}
                // @ts-expect-error
                onMouseMove={onMouseMove}
            >
                {isResizablePane && (
                    <ResizingArea
                        ref={resizableContentRef}
                        resizedWidth={resizedWidth}
                    ></ResizingArea>
                )}
                <div style={{ zIndex: 1000 }}>{children}</div>
                <ContentWrapper
                    className={STYLE_CLASSES.ADMIN_SIDE_TRAY_CONTENT}
                    contentWidth={resizedWidth ? `${resizedWidth}` : defaultContentWidth}
                >
                    {PaneComponent && <PaneComponent {...contentProps} />}
                </ContentWrapper>
            </Wrapper>
            {/* used when components need to place an overlay behind the sliding pane */}
            <div id="slidingPaneBackdropPortal"></div>
            <UnsavedChangesModal />
        </>
    )
}

export default SlidingPane
