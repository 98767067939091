import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'
import { LongTextAttributeTag } from 'features/views/attributes/LongTextAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import {
    MAX_LENGTH,
    MAX_LENGTH_SUBTITLE,
    MAX_LENGTH_TITLE,
    MAX_LINES,
    MAX_LINES_SUBTITLE,
    MAX_LINES_TITLE,
    MAX_OVERFLOW_LENGTH,
} from './constants'
import { CardViewAttributeComponent } from './types'

type LongTextAttributeProps = {}

export const LongTextAttribute: CardViewAttributeComponent<string, LongTextAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const { field, type } = attribute
    const isTitle = type === 'title'
    const isFooter = type === 'footer'
    const isSubtitle = type === 'subtitle'
    const isHeader = isTitle || isSubtitle

    const isEmpty = !isLoading && !value

    let maxLines = MAX_LINES
    if (isTitle) {
        maxLines = MAX_LINES_TITLE
    } else if (isSubtitle) {
        maxLines = MAX_LINES_SUBTITLE
    }

    let maxLength = MAX_LENGTH
    if (isTitle) {
        maxLength = MAX_LENGTH_TITLE
    } else if (isSubtitle) {
        maxLength = MAX_LENGTH_SUBTITLE
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayStyle === 'tags' || isFooter ? (
                <LongTextAttributeTag
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    size={isFooter ? 'xs' : '2xs'}
                    showIcon={showIcon}
                />
            ) : (
                <LongTextAttributeDisplay
                    alwaysShowOverflowTooltip={isHeader}
                    value={value}
                    style={{
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        letterSpacing: 'inherit',
                        fontWeight: 'inherit',
                    }}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    field={field}
                    maxLines={maxLines}
                    maxLength={isHeader ? undefined : maxLength}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    trim={!isTitle && !isSubtitle}
                />
            )}
        </BaseAttribute>
    )
}
