import React from 'react'

import useLDFlags from 'data/hooks/useLDFlags'

import { ScrollArea } from 'ui/components/ScrollArea'
import { Body } from 'ui/components/Text'

import { AppNavigationTree } from './AppNavigationTree'

type AppNavigationMenuRef = HTMLDivElement

type AppNavigationMenuProps = {}

export const AppNavigationMenu = React.forwardRef<AppNavigationMenuRef, AppNavigationMenuProps>(
    (props, ref) => {
        const { flags } = useLDFlags()
        return (
            <ScrollArea px="m" pb="xl" direction="vertical" ref={ref} {...props}>
                {flags.agents && (
                    <Body weight="bold" px="m" mt="l" color="textWeaker">
                        Pages
                    </Body>
                )}
                <AppNavigationTree />
            </ScrollArea>
        )
    }
)
