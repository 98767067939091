import React, { forwardRef, useCallback, useMemo } from 'react'

import { Field } from 'ui/components/Field'
import { Link } from 'ui/components/Link'
import { extractStandardProps } from 'ui/helpers/styles'
import { generateUniqueId } from 'ui/helpers/utilities'

import * as Parts from './Select.parts'
import { SelectContextProvider } from './SelectContextProvider'
import { SelectTrigger } from './SelectTrigger'
import { SelectOptionItem } from './types'
import { extractOptionItemsFromChildren, valueToArray } from './utils'

type LinkProps = {
    href?: string
    to?: string
    label: string
    target?: string
}

type SelectRef = HTMLButtonElement

type SelectProps<MS extends boolean> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Trigger>,
    'onChange' | 'value' | 'defaultValue'
> &
    Omit<React.ComponentPropsWithoutRef<typeof Field>, 'onChange' | 'value' | 'defaultValue'> & {
        link?: LinkProps
        defaultValue?: MS extends true ? string[] : string
        value?: MS extends true ? string[] : string
        onChange?: MS extends true ? (value: string[]) => void : (value: string) => void
        placeholder?: string
        multiSelect?: MS
        startIcon?: React.ComponentPropsWithoutRef<typeof SelectTrigger>['startIcon']
        isLoading?: boolean
        startAvatar?: React.ComponentPropsWithoutRef<typeof SelectTrigger>['startAvatar']
        isClearable?: boolean
        isSearchable?: boolean
        onPointerDownOutside?: React.ComponentPropsWithoutRef<
            typeof Parts.Content
        >['onPointerDownOutside']
        onOpenChange?: (open: boolean) => void
        modal?: boolean
        searchQuery?: React.ComponentPropsWithoutRef<typeof Parts.Content>['searchQuery']
        setSearchQuery?: React.ComponentPropsWithoutRef<typeof Parts.Content>['setSearchQuery']
        optionsOverride?: SelectOptionItem[]
        contentProps?: React.ComponentPropsWithoutRef<typeof Parts.Content>
        defaultOpen?: boolean
    }

const SelectInner = <MS extends boolean>(
    {
        id,
        isError,
        size = 'm',
        readOnly,
        disabled,
        label,
        infoText,
        helperText,
        required,
        link,
        optional,
        placeholder,
        children,
        value,
        defaultValue,
        onChange,
        multiSelect = false as MS,
        isSearchable,
        isClearable,
        startIcon,
        isLoading,
        onOpenChange,
        modal,
        startAvatar,
        searchQuery,
        setSearchQuery,
        optionsOverride,
        contentProps,
        defaultOpen,
        ...props
    }: SelectProps<MS>,
    ref: React.ForwardedRef<SelectRef>
) => {
    // this is so the id property can be optional, but still use the
    // label htmlFor property to link the label to the input.
    const effectiveId = useMemo(() => id || generateUniqueId(), [id])

    const [standardProps, { className, usePortal, onPointerDownOutside, ...rootProps }] =
        extractStandardProps(props)

    const options = useMemo(() => {
        return optionsOverride || extractOptionItemsFromChildren(children)
    }, [children, optionsOverride])

    const effectiveValue = useMemo(() => valueToArray(value), [value])
    const effectiveDefaultValue = useMemo(() => valueToArray(defaultValue), [defaultValue])
    const effectiveOnChange = useCallback(
        (newValues: string[]) => {
            if (multiSelect) {
                ;(onChange as SelectProps<true>['onChange'])?.(newValues)
            } else {
                ;(onChange as SelectProps<false>['onChange'])?.(newValues[0])
            }
        },
        [multiSelect, onChange]
    )

    return (
        <Field
            htmlFor={effectiveId}
            label={label}
            isError={isError}
            infoText={infoText}
            helperText={helperText}
            required={required}
            disabled={disabled}
            optional={optional}
            className={className}
            rightSlotContent={
                link && (
                    <Link size="m" target={link.target} to={link.to} href={link.href}>
                        {link.label}
                    </Link>
                )
            }
            {...standardProps}
        >
            <Parts.Root onOpenChange={onOpenChange} modal={modal} defaultOpen={defaultOpen}>
                <SelectContextProvider
                    value={effectiveValue}
                    defaultValue={effectiveDefaultValue}
                    onChange={effectiveOnChange}
                    multiSelect={multiSelect}
                    options={options}
                    isClearable={isClearable}
                >
                    <SelectTrigger
                        id={effectiveId}
                        size={size}
                        isError={isError}
                        readOnly={readOnly}
                        disabled={disabled}
                        placeholder={placeholder}
                        isClearable={isClearable}
                        startIcon={startIcon}
                        isLoading={isLoading}
                        startAvatar={startAvatar}
                        {...rootProps}
                        ref={ref}
                    />
                    <Parts.Content
                        usePortal={usePortal}
                        head={isSearchable ? <Parts.Search /> : undefined}
                        onPointerDownOutside={onPointerDownOutside}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        {...contentProps}
                    >
                        {children}
                    </Parts.Content>
                </SelectContextProvider>
            </Parts.Root>
        </Field>
    )
}

export const Select = forwardRef(SelectInner) as <MS extends boolean = false>(
    props: SelectProps<MS> & { ref?: React.ForwardedRef<SelectRef> }
) => ReturnType<typeof SelectInner>
