import React, { FC, useMemo } from 'react'

import { useTheme } from '@chakra-ui/react'

import { useObject } from 'data/hooks/objects'
import {
    filtersToValue,
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'

import { Box, Text } from 'v2/ui'

import EmptyFilters from './svgs/EmptyFilters.svg?react'

type FiltersPanelProps = {
    emptyStateTitle?: string
    aggregationWidget: AggregateBlockAttributes
    onChange: (
        aggregationWidget: Pick<AggregateBlockAttributes, 'objectId'> &
            Partial<AggregateBlockAttributes>
    ) => void
}

export const FiltersPanel: FC<FiltersPanelProps> = ({
    emptyStateTitle,
    aggregationWidget: { objectId, filters },
    onChange,
}) => {
    const { object } = useObject(objectId)
    const theme = useTheme()

    const fields = useMemo(() => {
        if (!object) {
            return []
        }

        return object.fields.filter(({ connection_options: { is_disabled } }) => !is_disabled)
    }, [object])

    const onChangeFilters = (newFilters: Filter[]) => {
        onChange({ objectId, filters: formatFilters(newFilters) as ChartFilter[] })
    }

    return (
        <>
            {filters.length === 0 && (
                <Box mb={3}>
                    <Text fontWeight="bold" color="userInterface.neutral.1000" mt="10px" mb={3}>
                        {emptyStateTitle ?? 'Add filters'}
                    </Text>
                    <EmptyFilters />
                </Box>
            )}
            <Filters
                object={object}
                fields={fields ?? []}
                value={filtersToValue(filters as FilterValue[], object)}
                onChange={onChangeFilters}
                includeIncompleteFilters={false}
                showRelativeDateFilters
                addButtonVariant="Tertiary"
                buttonProps={{
                    justifyContent: 'flex-start',
                    color: theme.colors.userInterface.accent[1000],
                    w: 'fit-content',
                    p: 1.5,
                }}
                closeOnOuterAction
            />
        </>
    )
}
