import React from 'react'

import { TaskSource } from 'data/hooks/tasks/types'
import { WorkflowUserDisplay } from 'features/Activity/WorkflowUserDisplay'
import { TasksListItem } from 'features/tasks/TasksListItem'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'

import { NotificationControls } from './NotificationControls'
import { NotificationTimestamp } from './NotificationTimestamp'
import { NotificationProps } from './types'

export function TaskNotification({
    notification,
    relatedData,
    markAsRead,
    markAsArchived,
}: NotificationProps) {
    // const { activity_id } = notification
    const [isHovered, hoverHandlers] = useHover()

    const task = relatedData.tasks.find((a) => a.auto_id === notification.task_id)
    if (!task) return null // This should have already been filtered out by the useNotifications hook

    const userId = notification.content?.user_id ?? task.source
    const user = userId ? relatedData.users.find((u) => u._sid === userId) : undefined
    const fromWorkflow = task.source_type === TaskSource.Workflow

    return (
        <Box flex column>
            <Box px="xs" {...hoverHandlers}>
                <Box flex center px="m" mb="xs">
                    {user && <UserDisplay user={user} />}
                    {fromWorkflow && <WorkflowUserDisplay />}
                    <Box ml="xs" flex center grow>
                        <Box as="span">added you to a task</Box>
                        <NotificationTimestamp value={notification.created_at} ml="m" />
                    </Box>
                    <NotificationControls
                        showUnreadIndicator={!notification.is_read}
                        notifications={[notification]}
                        isHovered={isHovered}
                        markAsRead={markAsRead}
                        markAsArchived={markAsArchived}
                    />
                </Box>
                <TasksListItem task={task} editOnClick />
            </Box>
            <Divider my="m" />
        </Box>
    )
}
