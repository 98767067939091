import { useLayoutEffect, useMemo, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { NavigationScrollAreaIsOverflowingBottomStyle } from 'features/navigation/Navigation.css'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import { useNavigationContext } from './useNavigationContext'

export function useAppNavigationState() {
    const { selectedApp } = useNavigationContext()

    const { targetRef: wrapperRef, scrollAreaRef } = useOverflowY({
        bottomClassName: NavigationScrollAreaIsOverflowingBottomStyle,
    })

    const localScrollAreaRef = useRef<HTMLDivElement>(null)
    const effectiveScrollAreaRef = useComposedRefs(
        scrollAreaRef as React.RefCallback<HTMLDivElement>,
        localScrollAreaRef
    ) as unknown as React.RefObject<HTMLDivElement>

    useLayoutEffect(() => {
        const scrollArea = localScrollAreaRef.current
        if (!scrollArea) return

        const scrollPosition = restoreScrollPosition(selectedApp?.id)
        scrollArea.scrollTop = scrollPosition

        return () => {
            // Persist scroll position.
            persistScrollPosition(selectedApp?.id, scrollArea.scrollTop)
        }
    }, [selectedApp?.id])

    return useMemo(
        () => ({
            selectedApp,
            wrapperRef,
            scrollAreaRef: effectiveScrollAreaRef,
        }),
        [effectiveScrollAreaRef, selectedApp, wrapperRef]
    )
}

function getScrollPositionKey(appId: string) {
    return `AppNavigationScrollPosition-${appId}`
}

function restoreScrollPosition(appId?: string) {
    if (!appId) return 0

    const scrollPosition = sessionStorage.getItem(getScrollPositionKey(appId))
    return scrollPosition ? parseInt(scrollPosition) : 0
}

function persistScrollPosition(appId?: string, scrollPosition?: number) {
    if (!appId) return

    sessionStorage.setItem(getScrollPositionKey(appId), scrollPosition?.toString() ?? '0')
}
