import { useLayoutEffect, useMemo, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { NavigationScrollAreaIsOverflowingBottomStyle } from 'features/navigation/Navigation.css'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationLinksState() {
    const { workspaceAccount, permissions } = useNavigationContext()

    const { targetRef: wrapperRef, scrollAreaRef } = useOverflowY({
        bottomClassName: NavigationScrollAreaIsOverflowingBottomStyle,
    })

    const localScrollAreaRef = useRef<HTMLDivElement>(null)
    const effectiveScrollAreaRef = useComposedRefs(
        scrollAreaRef as React.RefCallback<HTMLDivElement>,
        localScrollAreaRef
    ) as unknown as React.RefObject<HTMLDivElement>

    useLayoutEffect(() => {
        const scrollArea = localScrollAreaRef.current
        if (!scrollArea) return

        const scrollPosition = restoreScrollPosition(workspaceAccount?._sid)
        scrollArea.scrollTop = scrollPosition

        return () => {
            // Persist scroll position.
            persistScrollPosition(workspaceAccount?._sid, scrollArea.scrollTop)
        }
    }, [workspaceAccount?._sid])

    const hasInternalZoneAccess = permissions.canViewInternalZone

    return useMemo(
        () => ({
            wrapperRef: wrapperRef as React.MutableRefObject<HTMLDivElement>,
            scrollAreaRef: effectiveScrollAreaRef,
            hasInternalZoneAccess,
        }),
        [effectiveScrollAreaRef, wrapperRef, hasInternalZoneAccess]
    )
}

function getScrollPositionKey(workspaceId: string) {
    return `WorkspaceNavigationScrollPosition-${workspaceId}`
}

function restoreScrollPosition(workspaceId?: string) {
    if (!workspaceId) return 0

    const scrollPosition = sessionStorage.getItem(getScrollPositionKey(workspaceId))
    return scrollPosition ? parseInt(scrollPosition) : 0
}

function persistScrollPosition(workspaceId?: string, scrollPosition?: number) {
    if (!workspaceId) return

    sessionStorage.setItem(getScrollPositionKey(workspaceId), scrollPosition?.toString() ?? '0')
}
