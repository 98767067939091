import { useCallback, useMemo } from 'react'

import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { useNavigationContext } from './useNavigationContext'

export function useWorkspaceNavigationHeaderState() {
    const { permissions, isPortal } = useNavigationContext()

    const { workspaceNavState, tryCollapseWorkspaceNav } = useNavigationContext()

    const isWorkspaceNavCollapsed = workspaceNavState === 'collapsed'

    const canEditWorkspaceSettings = permissions.canEditWorkspaceSettings && !isPortal

    const handleOpenWorkspaceSettings = useCallback(() => {
        tryCollapseWorkspaceNav()
        openWorkspaceSettingsModal({ page: 'general' })
    }, [tryCollapseWorkspaceNav])

    return useMemo(
        () => ({
            canEditWorkspaceSettings,
            isWorkspaceNavCollapsed,
            handleOpenWorkspaceSettings,
        }),
        [isWorkspaceNavCollapsed, handleOpenWorkspaceSettings, canEditWorkspaceSettings]
    )
}
