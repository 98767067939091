import React from 'react'

import { BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box, Button, Flex, Input, Tooltip } from 'v2/ui'
import { SolidClose, SolidSearch } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const colors = stackerTheme().colors

export type SearchBoxProps = {
    placeholder?: string
    search?: string
    onSearch: (search?: string) => void
    showSearch: boolean
    setShowSearch: (value: boolean) => void
    location?: 'left' | 'right'
    dataTestIdPrefix: string
    buttonSize?: string
    buttonVariant?: string
} & BoxProps

const HEIGHT = '32px'

export const SearchBox = React.forwardRef<HTMLInputElement, SearchBoxProps>(
    (
        {
            placeholder,
            search,
            onSearch,
            showSearch,
            setShowSearch,
            location = 'left',
            dataTestIdPrefix,
            buttonSize = 'mediumSquare',
            buttonVariant = 'clear',
            ...props
        },
        ref
    ) => {
        const toggleSearch = () => {
            onSearch('')
            setShowSearch(!showSearch)
        }

        return (
            <SearchBoxWrapper
                showSearch={showSearch}
                flexDirection={location === 'left' ? 'row' : 'row-reverse'}
                justifyContent="flex-start"
                flexWrap="nowrap"
                {...props}
            >
                <Tooltip placement="bottom-start" label={showSearch ? 'Hide search' : placeholder}>
                    <StyledSearchButton
                        mr={location === 'left' ? 1 : 0}
                        ml={location === 'right' ? 1 : 0}
                        data-testid={`${dataTestIdPrefix}.search-box.button`}
                        className={showSearch ? 'enter' : 'exit'}
                        variant={buttonVariant}
                        onClick={toggleSearch}
                        buttonSize={buttonSize}
                        showSearch={showSearch}
                        dataTestIdPrefix={dataTestIdPrefix}
                    >
                        <SearchIcon dataTestIdPrefix={dataTestIdPrefix} showSearch={showSearch} />
                    </StyledSearchButton>
                </Tooltip>
                {showSearch && (
                    <SearchBoxInputWrapper>
                        <StyledInput
                            style={{ border: 'none' }}
                            ref={ref}
                            autoFocus
                            value={search}
                            width="100%"
                            height="30px"
                            placeholder={placeholder}
                            dataTestId={`${dataTestIdPrefix}.search-box.input`}
                            rightAdorner={
                                <SolidClose
                                    color={colors.userInterface.neutral[700]}
                                    onClick={toggleSearch}
                                />
                            }
                            rightAdornerProps={{
                                height: HEIGHT,
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onSearch(event.target.value)
                            }}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === 'Escape') {
                                    onSearch('')
                                    toggleSearch()
                                    event.stopPropagation()
                                }
                            }}
                        />
                    </SearchBoxInputWrapper>
                )}
            </SearchBoxWrapper>
        )
    }
)

const StyledInput = styled(Input)`
    border: ${() => `1px solid  ${colors.appColors.lightGrey[700]}`};
    color: ${() => colors.appColors.lightGrey[700]};
    height: ${HEIGHT};
`

const StyledSearchButton = styled(Button)<{ showSearch: boolean; dataTestIdPrefix: string }>`
    border: ${(p) =>
        p.showSearch && p.dataTestIdPrefix === 'DataWidget'
            ? `1px solid ${colors.appColors.lightGrey[700]}`
            : ''};
`
const SearchIcon = styled(SolidSearch)<{ showSearch: boolean; dataTestIdPrefix: string }>`
    fill: ${(p) =>
        p.dataTestIdPrefix === 'DataWidget'
            ? colors.appColors.lightGrey[700]
            : p.showSearch
              ? colors.userInterface.accent[1000]
              : colors.userInterface.neutral[700]};
    :hover {
        fill: ${(p) =>
            p.dataTestIdPrefix === 'DataWidget'
                ? colors.appColors.lightGrey[700]
                : colors.userInterface.accent[1000]};
    }
`

const SearchBoxInputWrapper = styled(Box)`
    height: ${HEIGHT};
`

const SearchBoxWrapper = styled(Flex)<{ showSearch?: boolean }>`
    width: ${(p) => (p.showSearch ? '248px' : '32px')};
    min-width: ${(p) => (p.showSearch ? '248px' : '32px')};
`
