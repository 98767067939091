import React from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'

import { DropdownSeparator } from 'ui/components/Dropdown'
import { DropdownFooterItem } from 'ui/components/Dropdown/DropdownFooterItem'
import { Select, SelectOption } from 'ui/components/Select'

type RoleOption = { value: string; label: string }

export const RolePicker = ({
    roles,
    error,
    onAddRole,
    ...rest
}: ControllerRenderProps & {
    roles: RoleOption[]
    error?: FieldError
    modal?: boolean
    onAddRole?: () => void
}) => {
    return (
        <Select
            label="Role"
            startIcon={{ name: 'Lock' }}
            placeholder="Select role..."
            isError={!!error}
            helperText={!!error ? error.message : null}
            contentProps={{
                footer: onAddRole && (
                    <>
                        <DropdownSeparator />
                        <DropdownFooterItem
                            label="Create new role"
                            startIcon={{ name: 'Plus' }}
                            onClick={onAddRole}
                            variant="action"
                        />
                    </>
                ),
            }}
            {...rest}
        >
            {roles.map(({ value, label }) => (
                <SelectOption key={value} value={value} label={label} />
            ))}
        </Select>
    )
}
