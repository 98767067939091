import { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import shortid from 'shortid'

import { useRealtimeUpdates } from 'data/realtime/realtimeUpdates'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'

import { buildQueryKey, queryCache, queryClient, useQueryKeyBuilder } from './_helpers'
const LIST_NAME = 'documents'

export type DocumentsFilter = { record_id?: string }
export type DocumentsResponse = {
    documents: DocumentDto[]
}
export type SingleDocumentResponse = {
    document: DocumentDto
    records: RecordDto[]
    documents: DocumentDto[]
}
export function useDocument(documentId?: number, filters?: DocumentsFilter, options?: object) {
    const singleRecordQueryKey = useQueryKeyBuilder([LIST_NAME, documentId?.toString(), filters], {
        includeAuthKeys: true,
    })

    const queryResult = useQuery<SingleDocumentResponse>(
        singleRecordQueryKey,
        async () => {
            const results = (await fetchAndReturn(
                `documents/${documentId}/`
            )) as SingleDocumentResponse
            return results
        },
        { refetchOnMount: 'always', ...options }
    )
    const refetchCallback = useCallback(() => () => queryResult.refetch(), [queryResult])

    const realtimeUpdatesChannel = `documents__${documentId}`
    useRealtimeUpdates({
        channel: realtimeUpdatesChannel,
        handler: refetchCallback,
        disabled: !documentId,
    })

    return queryResult
}

export function useDocuments(filters?: DocumentsFilter, options?: object) {
    const queryKey = useQueryKeyBuilder([LIST_NAME, filters], {
        includeAuthKeys: true,
    })

    const queryResult = useQuery<DocumentsResponse>(
        queryKey,
        async () => {
            const url = buildUrl(`documents/`, filters, true, false)
            const results = (await fetchAndReturn(url)) as DocumentsResponse

            return results
        },
        { refetchOnMount: 'always', ...options }
    )
    const refetchCallback = useCallback(() => () => queryResult.refetch(), [queryResult])

    const realtimeUpdatesChannel = `documents`
    useRealtimeUpdates({ channel: realtimeUpdatesChannel, handler: refetchCallback })

    return queryResult
}

// Returns a document from the react-query cache if it exists
export function useCachedDocument(documentId: number) {
    const documentIdRef = useRef(documentId)
    const [docFromCache, setDocFromCache] = useState<Partial<DocumentDto> | undefined>()
    documentIdRef.current = documentId
    useEffect(() => {
        const [query] = queryCache.findAll([LIST_NAME, documentId.toString()])
        const data = query?.state.data as SingleDocumentResponse

        if (data) {
            setDocFromCache(data.document)
        }

        // We want to subscribe to the react-query cache and whenever a document query is updated,
        // if it is the supplied document id, we want to grab the upated doc and add it to our
        // state, which we return to the client
        const unsubscribe = queryCache.subscribe((event) => {
            if (event && event.type === 'queryUpdated') {
                const { queryKey } = event.query
                if (queryKey?.[0] === LIST_NAME) {
                    const queryDocId = queryKey[1]
                    if (queryDocId === documentIdRef.current.toString()) {
                        // @ts-expect-error
                        const data = event.action.data
                        if (data) {
                            setDocFromCache(data.document)
                        }
                    }
                }
            }
        })

        return unsubscribe
    }, [documentId])

    return docFromCache
}
export function createDocument(document: Partial<DocumentDto>) {
    return fetchAndReturn(`documents/`, {
        method: 'POST',
        body: JSON.stringify(document),
        headers: { 'Content-type': 'application/json' },
    }) as Promise<SingleDocumentResponse>
}

export function useCreateDocument(filters?: DocumentsFilter) {
    const queryKey = useQueryKeyBuilder([LIST_NAME, filters], { includeAuthKeys: true })
    return useMutation((document: Partial<DocumentDto>) => createDocument(document), {
        onMutate: (document: Partial<DocumentDto>) => {
            const queryData = {
                ...(queryClient.getQueryData<DocumentsResponse>(queryKey) ?? {}),
            } as DocumentsResponse

            if (!document.pendingCreateId) {
                document.pendingCreateId = shortid.generate()

                queryData.documents = [document as DocumentDto, ...(queryData?.documents ?? [])]
            } else {
                queryData.documents = queryData?.documents?.map((d) =>
                    d.pendingCreateId === document.pendingCreateId
                        ? ({ ...document, saveFailed: false } as DocumentDto)
                        : d
                )
            }

            queryClient.setQueryData(queryKey, queryData)
        },
        onSettled: (data, error, variables) => {
            if (data) {
                const queryData = {
                    ...(queryClient.getQueryData<DocumentsResponse>(queryKey) ?? {}),
                }
                queryData.documents = [
                    data.document,
                    ...(queryData?.documents?.filter(
                        (document) => document.pendingCreateId !== variables.pendingCreateId
                    ) ?? []),
                ]
                // Update/prepopulate the single record query
                const singleRecordQueryKey = buildQueryKey(
                    [LIST_NAME, data.document.auto_id.toString(), filters],
                    {
                        includeAuthKeys: true,
                    }
                )
                queryClient.setQueryData(singleRecordQueryKey, (existing) => {
                    return {
                        ...(existing as SingleDocumentResponse),
                        ...data,
                    }
                })
                queryClient.setQueryData(queryKey, queryData)
                return Promise.resolve(data)
            }
        },
        onError: (err, variables) => {
            const queryData = {
                ...(queryClient.getQueryData<DocumentsResponse>(queryKey) ?? {}),
            }

            queryData.documents = queryData?.documents?.map((document) =>
                document.pendingCreateId === variables.pendingCreateId
                    ? { ...document, saveFailed: true }
                    : document
            )

            queryClient.setQueryData(queryKey, queryData)
        },
    })
}

export function useUpdateDocument(documentId: number, filters?: DocumentsFilter) {
    const queryKey = useQueryKeyBuilder([LIST_NAME, filters], { includeAuthKeys: true })
    const singleRecordQueryKey = useQueryKeyBuilder([LIST_NAME, documentId.toString(), filters], {
        includeAuthKeys: true,
    })
    return useMutation(
        async (document: Partial<DocumentDto>) => {
            const results = (await fetchAndReturn(`documents/${documentId}/`, {
                method: 'PATCH',
                body: JSON.stringify(document),
                headers: { 'Content-type': 'application/json' },
            })) as SingleDocumentResponse

            return results
        },
        {
            onMutate: (document: Partial<DocumentDto>) => {
                const queryData = {
                    ...(queryClient.getQueryData<DocumentsResponse>(queryKey) ?? {}),
                } as DocumentsResponse

                queryData.documents = queryData?.documents?.map((d) =>
                    d.auto_id === document.auto_id ? { ...(document as DocumentDto) } : d
                )

                console.log('# onMutate', document, queryKey)

                queryClient.setQueryData(queryKey, queryData)
            },
            onSettled: (data, _error, _variables) => {
                if (data) {
                    const queryData = {
                        ...(queryClient.getQueryData<DocumentsResponse>(queryKey) ?? {}),
                    }

                    queryData.documents = queryData?.documents?.map((d) =>
                        d.auto_id === data.document.auto_id
                            ? { ...data.document, saveFailed: false }
                            : d
                    )

                    queryClient.setQueryData(queryKey, queryData)

                    // update the single record query
                    queryClient.setQueryData(singleRecordQueryKey, (existing) => {
                        return {
                            ...(existing as SingleDocumentResponse),
                            document: data.document,
                        }
                    })

                    queryClient.invalidateQueries(singleRecordQueryKey)
                    return Promise.resolve(data)
                }
            },
            onError: (err, variables) => {
                const queryData = {
                    ...(queryClient.getQueryData<DocumentsResponse>(queryKey) ?? {}),
                }

                queryData.documents = queryData?.documents?.map((document) =>
                    document.pendingCreateId === variables.pendingCreateId
                        ? { ...document, saveFailed: true }
                        : document
                )
                queryClient.setQueryData(queryKey, queryData)
            },
        }
    )
}

export function updateDocumentQuery(
    documentId: number,
    updateFn: (cachedDocument: SingleDocumentResponse) => SingleDocumentResponse
) {
    const key = [LIST_NAME, documentId]
    const previousPayloads = queryClient.getQueriesData<SingleDocumentResponse>(key)

    for (const [queryKey, previousPayload] of previousPayloads) {
        const updatedPayload: SingleDocumentResponse = updateFn(previousPayload)
        queryClient.setQueryData(queryKey, updatedPayload)
    }
}
