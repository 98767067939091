// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { useCreateZone } from 'data/hooks/zones'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { CREATE_ZONE_MODAL_KEY } from './createZoneModalConstants'

type FormData = { name: string }

export const CreateZoneModal = React.memo(function CreateZoneModal() {
    const modal = useModalToggle(CREATE_ZONE_MODAL_KEY)
    const { isOpen, toggle } = modal

    const { mutateAsync: createZone } = useCreateZone()
    const [error, setError] = useState<string>()

    const history = useHistory()
    const onSuccess = useCallback(
        (zone: AccountZone) => {
            toggle()
            history.push(getWorkspaceUrl(Urls.Home, undefined, zone))
        },
        [toggle, history]
    )

    const onCreateZone = async (data: FormData) => {
        setError(undefined)
        try {
            const zone = await createZone(data)
            onSuccess(zone as AccountZone)
        } catch {
            setError(
                'An error occurred trying to create your portal. If this problem continues, please contact support.'
            )
        }
    }

    const {
        register,
        handleSubmit,
        reset: resetForm,
        formState: { isSubmitting, errors },
    } = useForm({})

    useEffect(() => {
        resetForm()
    }, [isOpen, resetForm])

    return (
        <Modal open={isOpen} onOpenChange={toggle}>
            <ModalContent>
                <ModalHeader title="Create portal" showCloseButton={true} />
                <Box pb="m" px="3xl">
                    <Input
                        label="Portal name"
                        size="m"
                        type="text"
                        placeholder="Enter portal name"
                        isError={!!errors?.name}
                        helperText={errors?.name?.message?.toString()}
                        required
                        {...register('name', { required: 'Name is required' })}
                    />
                </Box>
                <ModalFooter flex flexDirection="column">
                    <Button
                        size="l"
                        variant="primary"
                        style={{ width: '100%' }}
                        onClick={() => handleSubmit(onCreateZone)()}
                    >
                        {isSubmitting ? 'Creating portal' : 'Create'}
                    </Button>
                    {error && (
                        <Body color="textError" size="s" mt="m" weight="medium">
                            {error}
                        </Body>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
})
