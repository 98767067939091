import { useLayoutEffect, useMemo, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { NavigationScrollAreaIsOverflowingBottomStyle } from 'features/navigation/Navigation.css'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

import { useNavigationContext } from './useNavigationContext'

export function usePortalNavigationState() {
    const { workspaceZone } = useNavigationContext()

    const { targetRef: wrapperRef, scrollAreaRef } = useOverflowY({
        bottomClassName: NavigationScrollAreaIsOverflowingBottomStyle,
    })

    const localScrollAreaRef = useRef<HTMLDivElement>(null)
    const effectiveScrollAreaRef = useComposedRefs(
        scrollAreaRef as React.RefCallback<HTMLDivElement>,
        localScrollAreaRef
    ) as unknown as React.RefObject<HTMLDivElement>

    useLayoutEffect(() => {
        const scrollArea = localScrollAreaRef.current
        if (!scrollArea) return

        const scrollPosition = restoreScrollPosition(workspaceZone?._sid)
        scrollArea.scrollTop = scrollPosition

        return () => {
            // Persist scroll position.
            persistScrollPosition(workspaceZone?._sid, scrollArea.scrollTop)
        }
    }, [workspaceZone?._sid])

    return useMemo(
        () => ({ wrapperRef, scrollAreaRef: effectiveScrollAreaRef }),
        [effectiveScrollAreaRef, wrapperRef]
    )
}

function getScrollPositionKey(portalId: string) {
    return `PortalNavigationScrollPosition-${portalId}`
}

function restoreScrollPosition(portalId?: string) {
    if (!portalId) return 0

    const scrollPosition = sessionStorage.getItem(getScrollPositionKey(portalId))
    return scrollPosition ? parseInt(scrollPosition) : 0
}

function persistScrollPosition(portalId?: string, scrollPosition?: number) {
    if (!portalId) return

    sessionStorage.setItem(getScrollPositionKey(portalId), scrollPosition?.toString() ?? '0')
}
