import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

export type NangoSessionToken = {
    token: string
    // token expiry time in ISO formatted datetime
    expires_at: string
}
export function useCreateNangoSessionToken(
    options: UseMutationOptions<NangoSessionToken, unknown, void> = {}
) {
    return useMutation(
        async () => {
            return fetchAndReturn('nango-session-token/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
            }) as Promise<NangoSessionToken>
        },
        {
            ...options,
        }
    )
}
