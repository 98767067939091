import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

type ExternalUserInfo = {
    external_user_id: string
    external_user_email?: string
}

type usePopulateUserInfoForExtConnectionInput = {
    nangoConnectionId: string
    integrationId: ExternalIntegrationId
}
export function usePopulateUserInfoForExtConnection(
    options: UseMutationOptions<
        ExternalUserInfo,
        unknown,
        usePopulateUserInfoForExtConnectionInput
    > = {}
) {
    return useMutation(
        async ({ nangoConnectionId, integrationId }: usePopulateUserInfoForExtConnectionInput) => {
            return fetchAndReturn('data-connections/populate-user-info/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    nango_connection_id: nangoConnectionId,
                    integration_id: integrationId,
                }),
            }) as Promise<ExternalUserInfo>
        },
        {
            ...options,
        }
    )
}
