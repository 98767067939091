import React, { useState } from 'react'

import { Agent } from 'data/hooks/agents/types'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
    ModalTrigger,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { EditAgentForm } from './EditAgentForm'
import { EditAgentFormWrapper } from './EditAgentFormWrapper'

type EditAgentModalProps = {
    agent?: Agent
    trigger: React.ReactNode
}

export function EditAgentModal({ agent, trigger }: EditAgentModalProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState<string>('')

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{trigger}</ModalTrigger>
            <ModalContent>
                <EditAgentFormWrapper
                    agent={agent}
                    onSuccess={() => {
                        setIsOpen(false)
                    }}
                    setError={setError}
                >
                    <ModalHeader title={agent ? 'Edit Agent' : 'Create Agent'} showCloseButton />
                    <ModalContentInner>
                        <EditAgentForm />
                    </ModalContentInner>
                    <ModalFooter>
                        <ModalFooterButtonGroup>
                            <Button type="submit" size="l" variant="primary">
                                {agent ? 'Save Changes' : 'Create Agent'}
                            </Button>
                            {error && <Body color="textError">{error}</Body>}
                        </ModalFooterButtonGroup>
                    </ModalFooter>
                </EditAgentFormWrapper>
            </ModalContent>
        </Modal>
    )
}
