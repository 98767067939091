import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { LayoutEditorBlock, ViewLayoutContext } from 'v2/blocks/types'

import EmbeddableContent from 'features/embeddable-content/EmbeddableContent'
import {
    conditionalVisibility,
    fieldPicker,
    objectPicker,
    options,
    text,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'

import { Collapse, ConfigureWidgetPlaceholder, ContainerLabel } from 'v2/ui'
import * as SVGIcons from 'v2/ui/svgs'

import IframeBlockUrlHelp from './IframeBlockUrlHelp'

const StyledEmbeddableContent = styled(EmbeddableContent)<{ height?: string }>`
    ${({ height }) => height && `height: ${height};`}
`

type IframeBlockProps = {}

const IframeBlock: LayoutEditorBlock<IframeBlockProps, EmbedBlock> = ({ attrs, context }) => {
    const { fieldId, type, title, url, height } = attrs

    const field: FieldDto | undefined = useMemo(
        () => context.object?.fields?.find((f) => f._sid === fieldId),
        [fieldId, context.object?.fields]
    )
    const { isEditing } = context.editor

    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters

    const iframeUrl = useMemo(() => {
        if (type === 'url') {
            if (!url) return ''

            return url
        }

        if (!fieldId || !field || !context.record) {
            return ''
        }

        let fieldValue = (context.record as RecordDto)[field.api_name]
        if (
            !fieldValue ||
            typeof fieldValue !== 'string' ||
            (!fieldValue.startsWith('http') && !fieldValue.startsWith('//'))
        ) {
            // Invalid URL
            return ''
        }

        if (fieldValue.startsWith('http://')) {
            // Force HTTPS
            fieldValue = fieldValue.replace('http://', 'https://')
        }

        return fieldValue
    }, [context.record, field, fieldId, type, url])

    if (isEditing && !iframeUrl) {
        const iframeEditText = type === 'field' && field ? `Embed ${field.label}` : 'Embed'

        return (
            <>
                {title && (
                    <ContainerLabel
                        borderStyle="noBorder"
                        variant="widgetContainerTitle"
                        value={title}
                    />
                )}
                <ConfigureWidgetPlaceholder
                    message={iframeEditText}
                    subtitle="Display a link in an embedded iframe"
                    Icon={SVGIcons.Embed}
                />
            </>
        )
    }

    if (hidden || !iframeUrl) return null

    return (
        <Collapse isOpen={!hidden} duration={0.4}>
            {title && (
                <ContainerLabel
                    borderStyle="noBorder"
                    variant="widgetContainerTitle"
                    value={title}
                />
            )}
            {isEditing && <EditingOverlay />}
            <StyledEmbeddableContent title={title} src={iframeUrl} height={height} />
        </Collapse>
    )
}

IframeBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    options({
        label: 'Source',
        field: 'type',
        simple: true,
        isClearable: false,
        ['data-testid']: 'embed-source',
        defaultValue: { value: 'field', label: 'From Field' },
        options: [
            { value: 'field', label: 'From Field' },
            { value: 'url', label: 'By URL' },
        ],
        hide: (_: EmbedBlock['config']['attributes'], context: ViewLayoutContext) => {
            return context.viewType === 'blankpage'
        },
    }),
    objectPicker({ field: 'objectId', label: 'Object' }),
    fieldPicker({
        field: 'fieldId',
        label: 'Field',
        simple: true,
        filter: (f: EmbedBlock['config']['attributes']) => f.type === 'url' || f.type === 'string', // formula,
        hide: (params: EmbedBlock['config']['attributes']) => params.type === 'url',
    }),
    text({
        field: 'url',
        label: 'URL',
        simple: true,
        hide: (params: EmbedBlock['config']['attributes']) => params.type !== 'url',
        rightAdorner: <IframeBlockUrlHelp />,
    }),
    text({ field: 'title', label: 'Title', simple: true }),
    text({ field: 'height', label: 'Height', simple: true }),
]

export default IframeBlock

const EditingOverlay = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
`
