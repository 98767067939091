import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useFields } from 'data/hooks/fields'
import { createRecord } from 'data/hooks/records/recordOperations'
import { useIsBannerShowing } from 'features/core/useIsBannerShowing'
import { PathContext } from 'features/utils/PathContext'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { RecordManagerContextProvider } from 'features/views/RecordManager/RecordManagerContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import { useToast } from 'ui/components/Toast'

import { DetailViewContext } from './hooks/useDetailViewContext'
import { DeleteRecordConfirmModal } from './DeleteRecordConfirmModal'
import { filterSupportedFields, getDetailViewHeader } from './utils'

export type DetailViewContextProviderProps = {}

export const DetailViewContextProvider: React.FC<DetailViewContextProviderProps> = ({
    children,
}) => {
    const { isEditing } = useLayoutEditorContext()
    const { recordId: recordSid, object, pageUrl } = useContext(PathContext)

    const includeFields = isEditing ? undefined : undefined

    return (
        <RecordManagerContextProvider recordSid={recordSid} includeFields={includeFields}>
            <DetailViewContextProviderInner object={object as ObjectDto} pageUrl={pageUrl}>
                {children}
            </DetailViewContextProviderInner>
        </RecordManagerContextProvider>
    )
}

type DetailViewContextProviderInnerProps = {
    object?: ObjectDto
    pageUrl?: string
}

const DetailViewContextProviderInner: React.FC<DetailViewContextProviderInnerProps> = React.memo(
    function DetailViewContextProviderInner({ children, object, pageUrl }) {
        const { view } = useLayoutEditorContext()

        const recordManager = useRecordManagerContext()
        const recordRef = useRef(recordManager.record)
        recordRef.current = recordManager.record

        const { data: fields = [] } = useFields({ objectId: view?.object_id })
        const supportedFields = filterSupportedFields(fields)

        const header = getDetailViewHeader(view?.layout, supportedFields)

        const titleFieldApiName = header.title?.fieldApiName
        const titleField = fields.find((field) => field.api_name === titleFieldApiName)

        const viewPath = pageUrl

        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
        const deleteRecord = useCallback(() => {
            setIsDeleteModalOpen(true)
        }, [])

        const history = useHistory()
        const toast = useToast()
        const duplicateRecord = useCallback(async () => {
            const record = recordRef.current
            if (!record || !object?._sid) return

            const recordClone = { ...record, _sid: null, object_id: object?._sid }

            const newRecord = await createRecord(recordClone, undefined, false)

            toast({
                title: 'Record duplicated successfully',
                startIcon: { name: 'CheckCircle2' },
                type: 'success',
            })

            const newViewPath = getUrl(`${object?.url}/view/${newRecord?._sid}`)
            history.push(newViewPath)
        }, [history, object?._sid, object?.url, toast])

        const [isPreviewing] = useIsBannerShowing()

        const value = useMemo(
            () => ({
                view,
                viewPath,
                recordManager,
                header,
                titleField,
                object,
                deleteRecord,
                duplicateRecord,
                isPreviewing,
            }),
            [
                recordManager,
                titleField,
                view,
                header,
                object,
                viewPath,
                deleteRecord,
                duplicateRecord,
                isPreviewing,
            ]
        )

        return (
            <DetailViewContext.Provider value={value}>
                {children}
                <DeleteRecordConfirmModal
                    isOpen={isDeleteModalOpen}
                    onOpenChange={setIsDeleteModalOpen}
                />
            </DetailViewContext.Provider>
        )
    }
)
