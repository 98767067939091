import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

import { NangoSessionToken } from './useCreateNangoSessionToken'

type NangoReconnectSessionRequest = {
    nangoConnectionId: string
    integrationId: ExternalIntegrationId
}
export function useCreateNangoReconnectSessionToken(
    options: UseMutationOptions<NangoSessionToken, unknown, NangoReconnectSessionRequest> = {}
) {
    return useMutation(
        async (reconnectRequest: NangoReconnectSessionRequest) => {
            return fetchAndReturn('nango-session-token/reconnect/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    connection_id: reconnectRequest.nangoConnectionId,
                    integration_id: reconnectRequest.integrationId,
                }),
            }) as Promise<NangoSessionToken>
        },
        {
            ...options,
        }
    )
}
