import React from 'react'

import { Box } from 'ui/components/Box'
import { ScrollArea } from 'ui/components/ScrollArea'

import { useNavigationLinksState } from './hooks/useNavigationLinksState'

import { NavigationScrollAreaStyle } from './Navigation.css'

type WorkspaceNavigationLinksProps = {
    isCollapsed?: boolean
}

export const WorkspaceNavigationLinks: React.FC<WorkspaceNavigationLinksProps> = ({
    children,
    isCollapsed,
}) => {
    const { wrapperRef, scrollAreaRef, hasInternalZoneAccess } = useNavigationLinksState()

    const pt = hasInternalZoneAccess ? ('xl' as const) : (0 as const)
    const ptCollapsed = hasInternalZoneAccess ? ('2xl' as const) : (0 as const)

    return (
        <Box ref={wrapperRef} className={NavigationScrollAreaStyle}>
            <ScrollArea
                ref={scrollAreaRef}
                direction="vertical"
                width="full"
                rootProps={{
                    style: {
                        marginTop: isCollapsed && hasInternalZoneAccess ? '-8px' : undefined,
                        height: isCollapsed && hasInternalZoneAccess ? 'calc(100% + 8px)' : '100%',
                    },
                    width: 'full',
                    display: 'grid',
                }}
            >
                <Box
                    pt={isCollapsed ? ptCollapsed : pt}
                    pb={isCollapsed ? 0 : 'm'}
                    px={isCollapsed ? 's' : 'xs'}
                    flex
                    column
                    gap={isCollapsed ? 'l' : 'xl'}
                >
                    {children}
                </Box>
            </ScrollArea>
        </Box>
    )
}
