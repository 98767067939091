import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { Box } from 'ui/components/Box'
import { Icon as OriginalIcon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import EmptyStyles from 'ui/helpers/emptyStyles.css'
import { makeComponent } from 'ui/helpers/recipes'

import {
    IconPickerContainerStyles,
    IconPickerHeaderStyles,
    IconPickerIconStyles,
    IconPickerItemStyles,
    IconPickerSearchStyles,
} from './IconPicker.css'

export const Container = makeComponent(Box, IconPickerContainerStyles)
export const Header = makeComponent(Box, IconPickerHeaderStyles)
export const SearchInput = makeComponent(Input, IconPickerSearchStyles)
export const Picker = makeComponent(RadioGroupPrimitive.Root, EmptyStyles)
export const PickerItem = makeComponent(RadioGroupPrimitive.Item, IconPickerItemStyles)
export const Icon = makeComponent(OriginalIcon, IconPickerIconStyles)
