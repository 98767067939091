import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useDeleteRecentRecord } from 'data/hooks/recentRecords/recentRecords'
import { assertIsDefined } from 'data/utils/ts_utils'
import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'

import { Highlighter } from './Highlighter'
import { RecentRecordSearchResult } from './recentItemTypes'

export function RenderRecentRecord({
    item,
    queryTerms,
    blockLinkClick,
}: {
    item: RecentRecordSearchResult
    queryTerms?: string[]
    blockLinkClick?: boolean
}) {
    const { mutate: deleteRecentRecord } = useDeleteRecentRecord()

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            // If the caller doesn't want a click to activate the link,
            // we prevent default here--unless ctrl or meta is pressed, then
            // we want to let the user click on the link for opening in a new tab
            if (blockLinkClick && !(e.ctrlKey || e.metaKey)) {
                e.preventDefault()
            } else {
                // @ts-ignore
                e.nativeEvent.preventDownshiftDefault = true
            }
        },
        [blockLinkClick]
    )

    const handleDelete = useCallback(
        (e: React.MouseEvent) => {
            // @ts-ignore
            e.nativeEvent.preventDownshiftDefault = true
            e.preventDefault()
            assertIsDefined(item.record._sid)
            deleteRecentRecord(item.record._sid)
        },
        [deleteRecentRecord, item.record._sid]
    )
    return (
        <Box
            as={Link}
            className={HoverContainerStyle}
            to={item.url}
            flex
            center
            maxWidth="full"
            width="full"
            color="text"
            onClick={handleClick}
            tabIndex={-1}
        >
            <StackIconBadge stack={item.stack as StackDto} size={20} mr="m" />
            <Box flex column grow shrink>
                <Box flex center>
                    <Box
                        fontWeight="bodyBold"
                        mr="l"
                        trim
                        grow
                        style={{
                            marginTop: '-2px',
                            paddingTop: '2px',
                            marginBottom: '-2px',
                            paddingBottom: '2px',
                        }}
                    >
                        <Highlighter terms={queryTerms || []}>{item.record._primary}</Highlighter>
                    </Box>
                    <Box flex center fontSize="bodyS">
                        <Box color="textWeak">{item.stack.name}</Box>
                        <Icon color="textWeakest" noShrink name="ChevronRight" size="xs" mx="3xs" />
                        <Box color="textWeak">{item.object.name}</Box>
                    </Box>
                    <Button
                        variant="ghost"
                        startIcon={{ name: 'X' }}
                        onClick={handleDelete}
                        className={OpacityOnContainerHover}
                        size="2xs"
                        ml="m"
                        style={{ marginRight: '-8px' }}
                    />
                </Box>
            </Box>
        </Box>
    )
}
