// @ts-strict-ignore
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { arrayMove } from 'react-sortable-hoc'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { isEqual } from 'lodash'

import { AppContext } from 'app/AppContext'
import { useNavigation, useUpdateNavigation } from 'data/hooks/navigation'
import {
    findFirstEnablednavItem,
    useNavTree,
} from 'features/admin/settings/navigation/NavigationUtils'
import { AddCustomPageButton } from 'features/workspace/AddCustomPageButton/AddCustomPageButton'

import { Divider, Flex, Text, Toast } from 'v2/ui'

import NavigationPicker from './NavigationPicker'

const Header = styled(Flex)`
    flex: 1;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 10px;
`

type Props = {
    onCloseSettingsModal: () => void
}

const NewNavigationEditor: FC<Props> = () => {
    const [navigation, setNavigation] = useState<NavigationDto[]>([])
    const [error, setError] = useState<boolean>(false)
    const navTree = useNavTree()
    const { selectedStack } = useContext(AppContext)

    let firstEnabledNavItem = findFirstEnablednavItem(navTree)

    const { data: allNavigation, isFetching } = useNavigation() as UseQueryResult<NavigationDto[]>
    const { mutateAsync: updateNavigation } = useUpdateNavigation()

    const logSentryError = (msg: string, data: object): void => {
        Sentry.withScope((scope) => {
            Object.keys(data).forEach((key) => {
                scope.setExtra(key, data[key])
            })
            scope.setLevel('error')
            Sentry.captureMessage(`Navigation error: ${msg}`)
        })
    }
    const onUpdateNavigation = useCallback(
        (item: any): void => {
            const updatedNavigation = navigation

            const index = navigation.findIndex((i) => i._sid === item._sid)
            if (index < 0) {
                logSentryError('Unable to find sid on navigation', {
                    sid: item._sid,
                    navigation,
                })
                return
            }

            updatedNavigation[index] = item

            if (item.object_id === null) delete item.object_id
            updateNavigation({ id: item._sid, patch: item })

            setNavigation(updatedNavigation)
        },
        [updateNavigation, navigation]
    )

    const updateNavIcon = useCallback(
        (icon, navItem) => {
            const updatedNavigation = navigation
            const index = navigation.findIndex((i) => i._sid === navItem._sid)
            if (updatedNavigation[index]) {
                updatedNavigation[index].options.icon = icon
                onUpdateNavigation(updatedNavigation[index])
            }
        },
        [navigation, onUpdateNavigation]
    )

    const changeHidden = (item: any): void => {
        const updatedItem = { ...item, hidden: !item.hidden }
        onUpdateNavigation(updatedItem)
    }

    const changeLabel = (item: any, newLabel: string): void => {
        if (!newLabel) {
            return
        }

        const updatedItem = { ...item, label: newLabel }
        onUpdateNavigation(updatedItem)
    }

    const changeOrder = (items: NavigationDto[], oldIndex: number, newIndex: number): void => {
        // Now sort by display_order so we have an array that recreates the
        // same list rendered in the nav tree, and can do arrayMove below
        // on this array and have it accurately reflect the reordering that
        // the user has done
        const sortedNav = arrayMove(items, oldIndex, newIndex)
        const updatedDisplayOrder = navigation.map((navigationItem) => {
            const index = sortedNav.findIndex((ni) => ni._sid === navigationItem._sid)
            if (index < 0 || navigationItem.display_order === index + 1) {
                return navigationItem
            }

            const updatedNavItem = { ...navigationItem, display_order: index + 1 }
            updateNavigation({ id: updatedNavItem._sid, patch: updatedNavItem })

            return updatedNavItem
        })

        setNavigation(updatedDisplayOrder)
    }

    useEffect(() => {
        if (!allNavigation) {
            return
        }

        const stackNavigation = allNavigation.filter(
            (item: any) => item.stack_id === selectedStack?._sid
        )

        if (!isEqual(stackNavigation, navigation)) {
            setNavigation(stackNavigation)
        }
    }, [allNavigation, navigation, selectedStack])

    return (
        <>
            <Header>
                <Text variant="adminHeading">Navigation</Text>
            </Header>
            <Flex mb={2}>
                <Divider style={{ width: '100%', margin: '8px 0' }} />
            </Flex>
            <NavigationPicker
                firstEnabledNavItem={firstEnabledNavItem}
                items={navTree}
                changeHidden={changeHidden}
                changeLabel={changeLabel}
                changeOrder={changeOrder}
                isTopLevel
                updateNavIcon={updateNavIcon}
                isFetching={isFetching}
            />

            <Divider style={{ width: '100%', margin: '8px 0' }} />
            <AddCustomPageButton />

            <Toast
                title="There was an error, please try again."
                show={error}
                onCloseComplete={() => setError(false)}
                status="error"
            />
        </>
    )
}

export default NewNavigationEditor
