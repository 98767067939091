import React, { useCallback, useMemo } from 'react'

import {
    deleteRecords as originalDeleteRecords,
    updateRecord as originalUpdateRecord,
} from 'data/hooks/records/recordOperations'
import { RecordEditManagerContextProvider } from 'features/records/RecordEditManagerContextProvider'
import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'
import { useListViewRecords } from 'features/views/ListView/hooks/useListViewRecords'
import { useListViewContext } from 'features/views/ListView/useListViewContext'
import { useIsDeviceTouchOnly } from 'utils/useIsDeviceTouchOnly'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useTableViewFilters } from './Filters/hooks/useTableViewFilters'
import { useColumnsFromView } from './hooks/useColumnsFromView'
import { useTableViewCalculations } from './hooks/useTableViewCalculations'
import { useTableViewPagination } from './Pagination/hooks/useTableViewPagination'
import { useTableViewSearch } from './Search/hooks/useTableViewSearch'
import { useTableViewSort } from './Sort/hooks/useTableViewSort'
import { TableViewContext, TableViewInternalContextValue } from './useTableViewContext'

export type TableViewContextProviderProps = {}

export const TableViewContextProvider: React.FC<TableViewContextProviderProps> = ({ children }) => {
    const { object, stack, isEmbedded, allFields, view, header, searchableColumnFields } =
        useListViewContext()

    const isTouchDevice = useIsDeviceTouchOnly()
    const isRecordActionsColumnPinned = !isTouchDevice

    const hasResizableColumns = !isEmbedded

    const columns = useColumnsFromView({ isRecordActionsColumnPinned, hasResizableColumns })

    const thumbnailFieldApiName = view.options.coverImage?.id
    const additionalFieldApiNames = thumbnailFieldApiName ? [thumbnailFieldApiName] : undefined
    const onRecordClick = view.options.onRecordClick || 'preview'
    const disableRealtimeUpdates = view.options.disableRealtimeUpdates

    const {
        records,
        dereferencedRecords,
        recordCount = 0,
        retryFetchRecords,
        hasError,
        isLoading,
        isLoadingSlow: isFetchingSlow,
        effectiveFilters,
        includedFieldsApiNames,
    } = useListViewRecords({
        additionalFieldApiNames,
        disableRealtimeUpdates,
    })

    const { pageIndex, pageSize, setPageIndex } = useTableViewPagination({
        recordCount,
        isLoading,
    })

    const { sortBy, setSortBy, defaultSortBy } = useTableViewSort()
    const { query, setQuery } = useTableViewSearch()
    const { hasFilters: hasInlineFilters, clearFilters: clearInlineFilters } = useTableViewFilters()

    const hasFilters = !!query || hasInlineFilters
    const requestFilters = useDeepEqualsMemoValue(effectiveFilters)
    const requestIncludedFields = useDeepEqualsMemoValue(includedFieldsApiNames)

    const clearFilters = useCallback(() => {
        setQuery('')
        clearInlineFilters()
    }, [setQuery, clearInlineFilters])

    // empty addRecord callback as table views don't need this
    const addRecord = useCallback(async (record: RecordDto) => record, [])

    const updateRecord = useCallback(
        async (recordId: string, patch: Partial<RecordDto>) => {
            await originalUpdateRecord(recordId, patch, requestIncludedFields, {
                deferStoreUpdate: true,
            })
        },
        [requestIncludedFields]
    )

    const deleteRecords = useCallback(
        async (recordIds: string[]) => {
            await originalDeleteRecords(object._sid, recordIds)
            await retryFetchRecords()
        },
        [object._sid, retryFetchRecords]
    )

    const thumbnailImageField = allFields.find((f) => f.api_name === thumbnailFieldApiName)
    const thumbnailImageAspectRatio = view.options.coverImage?.aspectRatio ?? '1:1'

    const viewMemo = useDeepEqualsMemoValue(view)

    const {
        calculations,
        isLoadingSlow: isCalculationsFetchingSlow,
        isError: isCalculationsError,
    } = useTableViewCalculations({
        stack,
        object,
        requestFilters,
        requestIncludedFields,
        requestSearchFields: searchableColumnFields,
        columns,
        sortBy,
    })

    const showColumnIcons = view.options.tableShowFieldIcons ?? false

    const value = useMemo(
        () => ({
            columns,
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            currentPageIndex: pageIndex,
            pageSize,
            setCurrentPageIndex: setPageIndex,
            sortBy,
            setSortBy,
            defaultSortBy,
            isLoading,
            isFetchingSlow,
            hasFilters,
            clearFilters,
            requestFilters,
            requestIncludedFields,
            retryFetchRecords,
            hasError,
            isEmbedded,
            isRecordActionsColumnPinned,
            onRecordClick,
            thumbnailImageField,
            thumbnailImageAspectRatio,
            view: viewMemo,
            calculations,
            isCalculationsFetchingSlow,
            isCalculationsError,
            header,
            showColumnIcons,
            hasResizableColumns,
        }),
        [
            columns,
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            pageIndex,
            pageSize,
            setPageIndex,
            sortBy,
            setSortBy,
            defaultSortBy,
            isLoading,
            isFetchingSlow,
            hasFilters,
            clearFilters,
            requestFilters,
            requestIncludedFields,
            retryFetchRecords,
            hasError,
            isEmbedded,
            isRecordActionsColumnPinned,
            onRecordClick,
            thumbnailImageField,
            thumbnailImageAspectRatio,
            viewMemo,
            calculations,
            isCalculationsFetchingSlow,
            isCalculationsError,
            header,
            showColumnIcons,
            hasResizableColumns,
        ]
    )

    return (
        <RecordEditManagerContextProvider
            records={records ?? []}
            addRecord={addRecord}
            updateRecord={updateRecord}
            deleteRecords={deleteRecords}
        >
            <TableViewInternalContext value={value}>{children}</TableViewInternalContext>
        </RecordEditManagerContextProvider>
    )
}

type TableViewInternalContextProps = {
    value: TableViewInternalContextValue
    children: React.ReactNode
}

const TableViewInternalContext: React.FC<TableViewInternalContextProps> = ({ value, children }) => {
    const { records, pendingNewRecords } = useRecordEditManagerContext()

    let isLoading = value.isLoading
    // On the initial record load, it may take a few frames
    // until the record manager picks up the first batch of records.
    if (records.length < 1 && !!value.records?.length) {
        isLoading = true
    }

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    const finalValue = {
        ...value,
        isLoading,
        pendingRecords,
    }

    return <TableViewContext.Provider value={finalValue}>{children}</TableViewContext.Provider>
}
