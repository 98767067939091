import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationStateControlState() {
    const { toggleNavigationState, navigationState } = useNavigationContext()

    return useMemo(
        () => ({
            toggleNavigationState,
            navigationState,
        }),
        [toggleNavigationState, navigationState]
    )
}
