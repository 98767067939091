import React from 'react'

import { useJobs } from 'data/hooks/agents/job'
import { Agent } from 'data/hooks/agents/types'
import { JobListItem } from 'features/Agents/Jobs/JobListItem'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type Props = {
    agent: Agent
}
export function AgentJobs({ agent }: Props) {
    const { data: jobs } = useJobs(agent._sid, { refetchOnMount: 'always' })

    const sortedJobs = React.useMemo(() => {
        if (!jobs) return []

        return [...jobs].sort((a, b) => {
            // First sort by status priority
            const statusPriority = {
                not_started: 0,
                running: 1,
                waiting: 1,
                completed: 2,
                failed: 2,
            }

            const statusDiff = statusPriority[a.status] - statusPriority[b.status]
            if (statusDiff !== 0) return statusDiff

            // Then sort by created date (most recent first)
            return new Date(b.created_date).getTime() - new Date(a.created_date).getTime()
        })
    }, [jobs])

    const openJobs = React.useMemo(() => {
        return sortedJobs.filter((job) => !['completed', 'failed'].includes(job.status))
    }, [sortedJobs])

    const closedJobs = React.useMemo(() => {
        return sortedJobs.filter((job) => ['completed', 'failed'].includes(job.status))
    }, [sortedJobs])

    return (
        <Box as="ul" flex column gap="m" overflowY="auto" maxHeight="full" pb="m">
            {openJobs.length > 0 && (
                <Body weight="medium" size="s" color="textWeaker" mt="m">
                    Active jobs
                </Body>
            )}
            {openJobs?.map((x) => (
                <Box key={x._sid} as="li" p="m" background="gray100" rounded="m">
                    <JobListItem job={x} showStatusText={false} />
                </Box>
            ))}
            {closedJobs.length > 0 && (
                <Body weight="medium" size="s" color="textWeaker" mt="m">
                    Closed jobs
                </Body>
            )}
            {closedJobs?.map((x) => (
                <Box key={x._sid} as="li" p="m" background="gray100" rounded="m">
                    <JobListItem job={x} showStatusText={false} />
                </Box>
            ))}
        </Box>
    )
}
