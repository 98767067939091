import { useQuery, UseQueryOptions } from 'react-query'

import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { fetchAndReturn } from 'data/utils/utils'

const LIST_NAME = 'data-connections/integrations'

function useQueryKey() {
    return useQueryKeyBuilder([LIST_NAME], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type ExtDataIntegrationsData = {
    connections: ExtDataIntegrationConnectionDto[]
    integrations: ExtDataIntegrationsDto[]
}

export const useExtDataIntegrations = (options: UseQueryOptions<ExtDataIntegrationsData> = {}) => {
    const queryKey = useQueryKey()

    return useQuery<ExtDataIntegrationsData>(
        queryKey,
        async () => {
            return fetchAndReturn(
                `data-connections/integrations/`
            ) as Promise<ExtDataIntegrationsData>
        },
        options
    )
}

export const invalidateExtDataIntegrations = () => {
    return queryClient.invalidateQueries([LIST_NAME])
}
