import React, { useContext } from 'react'

import { DetailViewHeader } from 'features/views/LayoutEditor/types'
import { RecordManager } from 'features/views/RecordManager/hooks/useRecordManagerState'

export type DetailViewContextValue = {
    view?: ViewDto
    viewPath?: string
    object?: ObjectDto
    titleField?: FieldDto
    recordManager: RecordManager
    header: DetailViewHeader
    deleteRecord: () => void
    duplicateRecord: () => void
    isPreviewing: boolean
}
export const DetailViewContext = React.createContext<DetailViewContextValue | null>(null)

export function useDetailViewContext(): DetailViewContextValue {
    const context = useContext(DetailViewContext)

    if (!context) {
        throw new Error('useDetailViewContext must be used within a DetailViewContextProvider')
    }

    return context
}
