import React from 'react'

import moment from 'moment-timezone'

import { Box } from 'ui/components/Box'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'

import { calculateSyncState } from './getDataConnectionsSyncInfo'

type DataConnectionSyncInfoProps = {
    dataConnections: DataConnectionDto[]
    prefix?: string
}
/**
 * Render sync info for given data connection(s).
 *
 * More than one data connection can be provided, in which case the aggregate status of the data connections is
 * rendered
 * If any of the data connections is syncing we set the whole group as syncing
 * Otherwise last synced time is the max/latest time of the data connections sync completed
 *
 * @param dataConnections DataConnections to aggregated show sync info for
 * @param prefix Optional prefix to add before the sync info when there's no ongoing sync
 */
export const DataConnectionSyncInfo: React.FC<DataConnectionSyncInfoProps> = ({
    dataConnections,
    prefix,
}) => {
    const { isSyncInProgress, lastSyncTime, earliestStatus } = calculateSyncState(dataConnections)

    if (isSyncInProgress) {
        return <SyncInfo dataConnectionStatus={earliestStatus || 'SYNC_NOT_STARTED'} />
    }
    if (!lastSyncTime) {
        return <SyncInfo dataConnectionStatus="SYNC_NOT_STARTED" />
    }

    return (
        <Body size="s" mr="m">
            {prefix ?? ''}
            {moment(lastSyncTime).fromNow()}
        </Body>
    )
}

type SyncingProps = {
    dataConnectionStatus: DataConnectionDto['status']
}
export const SyncInfo: React.FC<SyncingProps> = ({ dataConnectionStatus }) => {
    const DC_STATUS_TO_STAGE: Record<DataConnectionDto['status'], string> = {
        SYNC_NOT_STARTED: 'Connecting',
        NANGO_STARTED: 'Gathering data',
        NANGO_COMPLETED: 'Starting sync',
        SYNCING_SCHEMA: 'Syncing',
        // A DC with SCHEMA_SYNC_COMPLETED status is not expected to render this component but
        // adding placeholder in case it happens
        SCHEMA_SYNC_COMPLETED: 'Sync completed',
    }
    return (
        <Box flex style={{ alignItems: 'center' }}>
            <Spinner size="2xs" mr="s" />
            <Body size="s" mr="m">
                {DC_STATUS_TO_STAGE[dataConnectionStatus]}
            </Body>
        </Box>
    )
}
