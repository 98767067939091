import { useMemo } from 'react'

import { useNavigationContext } from 'features/navigation/hooks/useNavigationContext'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewTopBarState() {
    const { titleField, recordManager } = useDetailViewContext()

    const isLoading = recordManager.isFetchingSlow
    const titleValue = isLoading ? 'Loading...' : recordManager.record?.[titleField?.api_name!]

    const { showCollapsedNavigationTrigger } = useNavigationContext()
    const showMenuButton = showCollapsedNavigationTrigger

    return useMemo(
        () => ({ titleField, titleValue, isLoading, showMenuButton }),
        [titleField, titleValue, isLoading, showMenuButton]
    )
}
