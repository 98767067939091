import React, { ChangeEvent, FC, useState } from 'react'

import { Switch } from '@chakra-ui/react'
import { isUndefined } from 'lodash'

import { useObjects } from 'data/hooks/objects'
import { LIST_TYPES } from 'data/utils/fieldDefinitions'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'
import isRichTextField from 'utils/isRichTextField'

import { Box, Button, Checkbox, Collapse, Divider, Flex, Input, ScrollBox, Text } from 'v2/ui'
import Attribute from 'v2/ui/components/Attribute/Attribute'

import { RadioButton, RadioGroup } from 'ui/components/Radio'

import { usePatchableActionContext } from './hooks/usePatchableActionContext'
import { updateStepField } from './helpers'
import { FlexSpacer, Title } from './ui'

type Props = {
    item: any
    step: ActionStep
    object: ObjectDto
    onRequestClose: () => void
}

export const EditField: FC<Props> = ({ item, step, object, onRequestClose }) => {
    const { data: objects } = useObjects()
    const patchableActionContext = usePatchableActionContext()
    if (!patchableActionContext) {
        throw new Error('usePatchableActionContext must be used within a patchableActionContext')
    }

    const { applyPatch, draftObject: action } = patchableActionContext

    const [label, setName] = useState(item.label)
    const [multiValueMode, setMultiValueMode] = useState(item.multiValueMode || 'update')
    const [value, _setValue] = useState(item.value)
    const [required, setRequired] = useState(item.required)
    const [promptUser, setPromptUser] = useState(item.promptUser)
    const [showCreateButton, setShowCreateButton] = useState(item.showCreateButton)
    const [enableRichTextEditor, setEnableRichTextEditor] = useState(item.enableRichTextEditor)
    const [toggleDefaultValue, setToggleDefaultValue] = useState(!isUndefined(item.value))
    const [autoSelectSingleRecord, setAutoSelectSingleRecord] = useState(
        !isUndefined(item.autoSelectSingleRecord)
    )

    const handleToggleDefaultValue = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked
        setToggleDefaultValue(newValue)

        // If default value is on, we want to default to 'null' which is
        // a blank blank value. If it is off, then we set to undefined, which
        // signifies that we aren't supplying a default value.
        _setValue(newValue ? null : undefined)
    }

    const field = object.fields.find((x) => x._sid === item.fieldId)

    const isRecordLinkField = ['lookup', 'multi_lookup'].includes(field.type)

    const showCurrentUserOption =
        isRecordLinkField &&
        objects?.find((x) => x._sid === field.link_target_object_id && getIsStackerUserObject(x))

    // see if we should display the "show add button" option.
    // it's only relevant on non-readonly record link fields.
    const allowCreateNewOption = field && !field.is_read_only && isRecordLinkField

    const isMultiField = LIST_TYPES.includes(field?.type)

    return (
        <ScrollBox margin="-5px" padding="5px" height="100%">
            <Flex column wrap="nowrap" align="stretch" height="100%">
                <Title>Label</Title>
                <Input
                    value={label}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                />
                <Flex alignItems="center" mt={3}>
                    <Switch
                        isChecked={toggleDefaultValue}
                        onChange={handleToggleDefaultValue}
                        disabled={!!autoSelectSingleRecord}
                        mr={2}
                    />
                    <Text fontSize="0.875rem">Enable default value</Text>
                </Flex>
                {toggleDefaultValue && (
                    <>
                        <Title>Default value for {field.label}</Title>
                        <Box flexBasis={0}>
                            <Attribute
                                field={object.fields.find(({ _sid }) => _sid === item.fieldId)}
                                editable={toggleDefaultValue}
                                onChange={_setValue}
                                additionalRecords={
                                    showCurrentUserOption
                                        ? [{ _sid: 'current_user', _primary: 'Current User' }]
                                        : undefined
                                }
                                size="sm"
                            >
                                {value}
                            </Attribute>
                        </Box>
                    </>
                )}
                {isMultiField && (
                    <>
                        <Title>Multi-value field options</Title>
                        <RadioGroup value={multiValueMode} onValueChange={setMultiValueMode}>
                            <RadioButton value="update">Update existing field values</RadioButton>
                            <RadioButton value="append">Add selection to field</RadioButton>
                            <RadioButton value="remove">Remove selection from field</RadioButton>
                        </RadioGroup>
                    </>
                )}

                <Title mt={3}>Other settings</Title>
                <Collapse isOpen={!!isRecordLinkField}>
                    <Checkbox
                        isChecked={autoSelectSingleRecord}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setAutoSelectSingleRecord(event.target.checked)
                        }
                        disabled={!!toggleDefaultValue}
                    >
                        <Text fontSize="0.875rem">Auto-select if only one option available</Text>
                    </Checkbox>
                </Collapse>
                <Checkbox
                    isChecked={promptUser}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setPromptUser(event.target.checked)
                    }
                    mt={2}
                >
                    <Text fontSize="0.875rem">Allow user to edit value</Text>
                </Checkbox>
                {isRichTextField(field) && (
                    <Checkbox
                        isChecked={enableRichTextEditor}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setEnableRichTextEditor(event.target.checked)
                        }
                        mt={4}
                    >
                        <Text fontSize="0.875rem">Enable rich text editor</Text>
                    </Checkbox>
                )}
                <Collapse isOpen={!!promptUser}>
                    <Checkbox
                        isChecked={required}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setRequired(event.target.checked)
                        }
                        mt={2}
                    >
                        <Text fontSize="0.875rem">Required</Text>
                    </Checkbox>
                </Collapse>
                <Collapse isOpen={!!(promptUser && allowCreateNewOption)}>
                    <Checkbox
                        isChecked={showCreateButton}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setShowCreateButton(event.target.checked)
                        }
                        mt={2}
                    >
                        <Text fontSize="0.875rem">Show add new button</Text>
                    </Checkbox>
                </Collapse>
                <Box flexGrow={1} />
                <Divider my={8} />
                <Flex justifyContent="flex-end">
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        onClick={() => {
                            onRequestClose()
                        }}
                    >
                        Back
                    </Button>
                    <FlexSpacer />
                    <Button
                        variant="adminPrimary"
                        buttonSize="sm"
                        onClick={() => {
                            applyPatch(
                                updateStepField(action, step.id, item.fieldId, {
                                    label,
                                    value,
                                    required,
                                    promptUser,
                                    autoSelectSingleRecord,
                                    enableRichTextEditor,
                                    showCreateButton,
                                    multiValueMode,
                                })
                            )
                            onRequestClose()
                        }}
                    >
                        Save Field
                    </Button>
                </Flex>
            </Flex>
        </ScrollBox>
    )
}
