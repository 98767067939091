import React from 'react'

import { Box } from 'ui/components/Box'

import { Attribute } from './attributes/Attribute'
import { useBoardViewEyebrowState } from './hooks/useBoardViewEyebrowState'

import { BoardViewEyebrowAttributeStyle } from './BoardView.css'

type BoardViewEyebrowProps = {
    record: RecordDto
    isLoading?: boolean
    hasCoverImage?: boolean
}

export const BoardViewEyebrow: React.FC<BoardViewEyebrowProps> = ({
    record,
    isLoading,
    hasCoverImage,
}) => {
    const { attribute, value, showIcon } = useBoardViewEyebrowState({ record })

    if (!attribute) return null

    return (
        <Box
            px="s"
            pt="s"
            position={hasCoverImage ? 'absolute' : undefined}
            top={0}
            left={0}
            style={{ zIndex: 1 }}
            className={BoardViewEyebrowAttributeStyle}
        >
            <Attribute
                attribute={attribute}
                isLoading={isLoading}
                displayStyle="tags"
                value={value}
                showIcon={showIcon}
            />
        </Box>
    )
}
