// @ts-strict-ignore
import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Dropdown,
    DropdownContent,
    DropdownEndIndicatorShortcut,
    DropdownItem,
} from 'ui/components/Dropdown'
import { Input } from 'ui/components/Input'
import { SplitButton } from 'ui/components/SplitButton'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

import { useBoardViewAddNewCardState } from './hooks/useBoardViewAddNewCardState'
import * as Parts from './BoardView.parts'
import { BoardViewAddNewCardFieldDropdown } from './BoardViewAddNewCardFieldDropdown'
import { BoardViewAddNewCardFieldRecordLinks } from './BoardViewAddNewCardFieldRecordLinks'
import { BoardViewColumn } from './types'

import { BoardViewAddNewCardPopover } from './BoardView.css'

type BoardViewAddNewCardProps = React.ComponentPropsWithoutRef<typeof Parts.Card> & {
    column: BoardViewColumn
    onCancel?: () => void
    onAddNewRecord?: (record: RecordDto, isFirstInColumn: boolean) => void
    isFirstInColumn?: boolean
    fistRecordLocalDisplayOrder?: number
}

export const BoardViewAddNewCard: React.FC<BoardViewAddNewCardProps> = React.memo(
    function BoardViewAddNewCard({
        column,
        onCancel,
        onAddNewRecord,
        isFirstInColumn,
        fistRecordLocalDisplayOrder,
        ...props
    }) {
        const {
            elementRef,
            title,
            setTitle,
            isSubmitDisabled,
            onSubmit,
            onKeyDown,
            cancelEditing,
            editableFields,
            record,
            onChangeFieldValue,
            canCreateMultiple,
            onCreateMultiple,
        } = useBoardViewAddNewCardState({
            column,
            onCancel,
            onAddNewRecord,
            isFirstInColumn,
            fistRecordLocalDisplayOrder,
        })

        return (
            <Parts.Card ref={elementRef} isAddNew {...props}>
                <Box as="form" onSubmit={onSubmit} onKeyDown={onKeyDown}>
                    <Box p="s">
                        <Input
                            variant="borderless"
                            placeholder="Add a title"
                            size="l"
                            style={{ fontWeight: theme.fontWeights.bodyBold }}
                            autoFocus
                            value={title}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setTitle(e.target.value)
                            }
                        />
                    </Box>
                    {editableFields.length > 0 && (
                        <Box flex center p="s" gap="xs" wrap>
                            {editableFields.map((field) => (
                                <BoardViewAddNewCardField
                                    key={field._sid}
                                    field={field}
                                    value={record[field.api_name] ?? undefined}
                                    onChange={(value) => onChangeFieldValue(field, value)}
                                />
                            ))}
                        </Box>
                    )}
                    <Box flex center p="s" gap="s">
                        {canCreateMultiple ? (
                            <Dropdown>
                                <SplitButton
                                    type="submit"
                                    size="s"
                                    variant="primary"
                                    disabled={isSubmitDisabled}
                                >
                                    Create
                                </SplitButton>
                                <DropdownContent
                                    align="end"
                                    onClick={stopPropagation}
                                    className={BoardViewAddNewCardPopover}
                                    style={{
                                        minWidth: '295px',
                                    }}
                                >
                                    <DropdownItem
                                        label="Create"
                                        onClick={onSubmit}
                                        rightSlotContent={(props) => {
                                            return (
                                                <DropdownEndIndicatorShortcut
                                                    label={{
                                                        mac: '⏎',
                                                        default: 'Enter',
                                                    }}
                                                    {...props}
                                                />
                                            )
                                        }}
                                    />
                                    <DropdownItem
                                        label="Create multiple"
                                        onClick={onCreateMultiple}
                                        rightSlotContent={(props) => {
                                            return (
                                                <DropdownEndIndicatorShortcut
                                                    label={{
                                                        mac: '⌘⇧⏎',
                                                        default: 'Ctrl + Shift + Enter',
                                                    }}
                                                    {...props}
                                                />
                                            )
                                        }}
                                    />
                                </DropdownContent>
                            </Dropdown>
                        ) : (
                            <Button
                                type="submit"
                                size="s"
                                variant="primary"
                                disabled={isSubmitDisabled}
                            >
                                Create
                            </Button>
                        )}
                        <Button size="s" variant="ghost" onClick={cancelEditing}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Parts.Card>
        )
    }
)

type BoardViewAddNewCardFieldProps = {
    field: FieldDto
    value: unknown
    onChange: (value: unknown) => void
}

const BoardViewAddNewCardField: React.FC<BoardViewAddNewCardFieldProps> = React.memo(
    function BoardViewAddNewCardField({ field, value, onChange }) {
        switch (field.type) {
            case 'dropdown':
            case 'multi_select':
                return (
                    <BoardViewAddNewCardFieldDropdown
                        field={field}
                        value={value as string | string[]}
                        onChange={onChange}
                    />
                )
            case 'lookup':
            case 'multi_lookup':
                return (
                    <BoardViewAddNewCardFieldRecordLinks
                        field={field}
                        value={value as string | string[]}
                        onChange={onChange}
                    />
                )
            default:
                return null
        }
    }
)
