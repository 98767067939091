import React, { useContext } from 'react'

import { ListViewSort } from 'features/views/ListView/Sort/types'

import { CardViewAttribute } from './types'

export type CardViewInternalContextValue = {
    attributes: CardViewAttribute[]
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    sortBy?: ListViewSort
    clearFilters?: () => void
    errorMessage?: string
    labelStyle?: ListViewOptions['cardLabelStyle']
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    hasFilters: boolean
    inlineFilterType?: ListViewInlineFilters
    availableInlineFilterFields: FieldDto[]
    isEmbedded: boolean
    allFields: FieldDto[]
    requestFilters: Filter[]
    requestIncludedFields: string[]
    coverImageField?: FieldDto
    profileImageField?: FieldDto
    titleSize: Required<ListViewOptions['cardTitleSize']>
    cardSize: Required<ListViewOptions['cardCardSize']>
    cardStyle: Required<ListViewOptions['cardCardStyle']>
    eyebrowField?: FieldDto
    titleField?: FieldDto
    subtitleField?: FieldDto
    header: ListViewHeader
    onRecordClick: Required<ListViewOptions['onRecordClick']>
}
export type CardViewContextValue = CardViewInternalContextValue & {
    pendingRecords: RecordDto[]
}
export const CardViewContext = React.createContext<CardViewContextValue | null>(null)

export function useCardViewContext(): CardViewContextValue {
    const context = useContext(CardViewContext)

    if (!context) {
        throw new Error('useCardViewContext must be used within a CardViewContextProvider')
    }

    return context
}
