import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

type SyncResponse = {
    success: boolean
}

type useSyncConnectionInput = {
    nangoConnectionId: string
    integrationId: ExternalIntegrationId
}

export function useSyncConnection(
    options: UseMutationOptions<SyncResponse, unknown, useSyncConnectionInput> = {}
) {
    return useMutation(
        async ({ nangoConnectionId, integrationId }: useSyncConnectionInput) => {
            return fetchAndReturn('data-connections/sync/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    nango_connection_id: nangoConnectionId,
                    integration_id: integrationId,
                }),
            }) as Promise<SyncResponse>
        },
        {
            ...options,
        }
    )
}
