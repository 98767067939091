import React from 'react'

import { useAgents } from 'data/hooks/agents/agents'
import { Agent } from 'data/hooks/agents/types'
import useLDFlags from 'data/hooks/useLDFlags'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'

import { AgentChatDropdown } from './chat/AgentChatDropdown'
import { AgentIcon } from './chat/AgentIcon'
import { EditAgentModal } from './EditAgentModal'

export function SidebarAgentsPanel() {
    const { data: agents } = useAgents()
    const { flags } = useLDFlags()

    if (!flags.agents) return null
    const hasAgents = !!agents?.length
    return (
        <Box flex column px="l" gap="m">
            <Box flex center justifyContent="space-between">
                <Body weight="bold" color="textWeaker">
                    Agents
                </Body>
                {hasAgents && (
                    <EditAgentModal
                        trigger={
                            <Button
                                variant="secondary"
                                size="xs"
                                startIcon={{ name: 'Plus' }}
                                iconOnly
                            />
                        }
                    />
                )}
            </Box>
            {agents?.map((agent) => <AgentItem key={agent._sid} agent={agent} />)}
            <Box style={{ display: !hasAgents ? 'block' : 'none' }}>
                <EditAgentModal
                    trigger={
                        <Button variant="secondary" size="xs" startIcon={{ name: 'Plus' }}>
                            Create Agent
                        </Button>
                    }
                />
            </Box>
        </Box>
    )
}

function AgentItem({ agent }: { agent: Agent }) {
    return (
        <AgentChatDropdown agent={agent}>
            <Box flex center gap="m" role="button" className={HoverContainerStyle}>
                <AgentIcon agent={agent} />
                <Body grow>{agent.title}</Body>
                <Icon name="MessageSquare" className={OpacityOnContainerHover} />
            </Box>
        </AgentChatDropdown>
    )
}
