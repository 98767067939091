import React from 'react'

import { useObjects } from 'data/hooks/objects'
import { DATA_CONNECTION_DETAIL_MODAL_KEY, TRANSLATIONS } from 'features/DataConnections/constants'
import { DataConnectionDetailModal } from 'features/DataConnections/DataConnectionDetailModal/DataConnectionDetailModal'

import { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { DataConnectionOptions } from './DataConnectionOptions'
import { DataConnectionSyncInfo } from './DataConnectionSyncInfo'
import { ObjectRow } from './ObjectRow'

type DataConnectionSectionProps = {
    dataConnection: DataConnectionDto
}
export const DataConnectionSection: React.FC<DataConnectionSectionProps> = ({ dataConnection }) => {
    const { data: objects } = useObjects()
    const objsForDC = objects.filter((obj) => obj.data_connection == dataConnection._sid)
    const { show: showDataConnectionDetailModal } = useModalDeclaration(
        DATA_CONNECTION_DETAIL_MODAL_KEY,
        DataConnectionDetailModal
    )

    return (
        <Box style={{ paddingTop: theme.space.m, paddingBottom: theme.space.m }}>
            <Box flex style={{ justifyContent: 'space-between' }}>
                <Body style={{ color: theme.color.textWeaker }}>{dataConnection.label}</Body>
                <Box
                    flex
                    style={{
                        marginRight: theme.space['3xl'],
                        width: '240px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Body size="s">
                        <DataConnectionSyncInfo dataConnections={[dataConnection]} />
                    </Body>
                    <DataConnectionOptions dataConnection={dataConnection} />
                </Box>
            </Box>
            <Box>
                {!objsForDC.length && (
                    <Body size="s">No {TRANSLATIONS['airtable'].tables} added yet</Body>
                )}
                {objsForDC.map((object) => (
                    <ObjectRow key={object._sid} object={object} />
                ))}
            </Box>
            <Box style={{ paddingBottom: theme.space.s, position: 'relative', left: '-5px' }}>
                <Button
                    onClick={() =>
                        showDataConnectionDetailModal({ initialDataConnection: dataConnection })
                    }
                    startIcon={{ name: 'Plus' }}
                    type="button"
                    size="s"
                    variant="link"
                >
                    Sync new table
                </Button>
            </Box>
        </Box>
    )
}
