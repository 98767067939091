import React, { useState } from 'react'
import Markdown from 'react-markdown'

import {
    ChatMessageBase,
    ChatUserMessageContentType,
} from 'features/AiAppBuilder/chatUtils/chatTypes'

import { useScrollIntoView } from 'v2/ui/hooks/useScrollIntoView'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'

import './chatBoxStyles.css'

type ChatBoxProps<TMessage extends ChatMessageBase> = {
    onPostMessage: (message: ChatUserMessageContentType) => Promise<void>
    messages: TMessage[]
    placeholder?: string
    retryMessage?: (message: TMessage) => Promise<void>
    renderMessage?: (message: TMessage) => React.ReactNode
}

export function ChatBox<TMessage extends ChatMessageBase>({
    onPostMessage,
    messages,
    placeholder = 'enter a message',
    retryMessage,
    renderMessage,
}: ChatBoxProps<TMessage>) {
    const [inputValue, setInputValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function handleSendMessage() {
        setIsLoading(true)
        try {
            const promise = onPostMessage(inputValue)
            setInputValue('')
            await promise
        } catch (error) {
            console.error('Error sending message:', error)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleRetryMessage(message: TMessage) {
        setIsLoading(true)
        try {
            await retryMessage?.(message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box
            id="chat-box"
            flex
            column
            alignItems="stretch"
            height="full"
            grow
            shrink
            maxHeight="full"
        >
            <Box overflowY="auto" grow flex column gap="s" shrink>
                {messages.map(
                    (message, index) =>
                        renderMessage?.(message) || (
                            <ChatMessageItem
                                key={index}
                                message={message}
                                retryMessage={handleRetryMessage}
                            />
                        )
                )}
                {isLoading && <TypingIndicator />}
                <ScrollIntoView key={messages.length} mt="l" />
            </Box>
            <Box flex alignItems="center">
                <Input
                    value={inputValue}
                    autoFocus
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={placeholder}
                    name="question"
                    autoComplete="off"
                    style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage()
                        }
                    }}
                />
                <Button
                    startIcon={{ name: 'Send' }}
                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                    onClick={handleSendMessage}
                />
            </Box>
        </Box>
    )
}

function TypingIndicator(props: BoxProps) {
    return (
        <Box className="typing-indicator" {...props}>
            <span></span>
            <span></span>
            <span></span>
        </Box>
    )
}

function ChatMessageItem<TMessage extends ChatMessageBase>({
    message,
    retryMessage,
}: {
    message: TMessage
    retryMessage?: (message: TMessage) => Promise<void>
}) {
    if (!message.content || message.role === 'tool') return null

    return (
        <>
            <Container
                p="m"
                variant={message.role == 'user' ? 'accentMuted' : 'neutralMuted'}
                alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
                className="markdown"
            >
                <Markdown>{message.content.toString()}</Markdown>
            </Container>
            {'sendingFailed' in message && message.sendingFailed && (
                <Body
                    size="xs"
                    color="textError"
                    alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
                >
                    Failed to send.{' '}
                    {retryMessage && (
                        <Box
                            as="span"
                            textDecoration="underline"
                            role="button"
                            onClick={(e: React.MouseEvent) => {
                                retryMessage(message)
                                e.preventDefault()
                            }}
                        >
                            Try again
                        </Box>
                    )}
                </Body>
            )}
        </>
    )
}

function ScrollIntoView(props: BoxProps) {
    const ref = useScrollIntoView()
    return <Box ref={ref} {...props} />
}
