import React, { useMemo } from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'
import { CheckboxAttributeTag } from 'features/views/attributes/CheckboxAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import { CardViewAttributeComponent } from './types'

type CheckboxAttributeProps = {}

export const CheckboxAttribute: CardViewAttributeComponent<boolean, CheckboxAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const {
        field,
        rawOptions: { checkboxDisplay },
    } = attribute

    const valueDisplay = useMemo(() => {
        switch (checkboxDisplay) {
            case 'default':
                return 'default'
            case 'big':
                return 'bigFilled'
            case 'filled':
                return 'filled'
        }
    }, [checkboxDisplay])

    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayStyle === 'tags' ? (
                <CheckboxAttributeTag
                    value={value}
                    valueDisplay={valueDisplay}
                    field={field}
                    isLoading={isLoading}
                    showIcon={showIcon}
                />
            ) : (
                <CheckboxAttributeDisplay
                    value={value}
                    valueDisplay={valueDisplay}
                    field={field}
                    isLoading={isLoading}
                />
            )}
        </BaseAttribute>
    )
}
