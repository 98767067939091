// Generated by `scripts/extractIconNames.mjs`.
export const iconNames = [
    'abacus',
    'absolute',
    'acceleration',
    'access',
    'accident',
    'account-setting-01',
    'account-setting-02',
    'account-setting-03',
    'activity-01',
    'activity-02',
    'activity-03',
    'activity-04',
    'acute',
    'add-01',
    'add-02',
    'add-circle',
    'add-circle-half-dot',
    'add-square',
    'add-team',
    'address-book',
    'adobe-after-effect',
    'adobe-illustrator',
    'adobe-indesign',
    'adobe-photoshop',
    'adobe-premier',
    'adobe-xd',
    'adventure',
    'advertisiment',
    'adzan',
    'affiliate',
    'agreement-01',
    'agreement-02',
    'agreement-03',
    'ai-beautify',
    'ai-book',
    'ai-brain-01',
    'ai-brain-02',
    'ai-brain-03',
    'ai-brain-04',
    'ai-brain-05',
    'ai-browser',
    'ai-chat-01',
    'ai-chat-02',
    'ai-cloud',
    'ai-cloud-01',
    'ai-cloud-02',
    'ai-computer',
    'ai-dna',
    'ai-eraser',
    'ai-folder-01',
    'ai-folder-02',
    'ai-game',
    'ai-generative',
    'ai-idea',
    'ai-image',
    'ai-innovation-01',
    'ai-innovation-02',
    'ai-innovation-03',
    'ai-laptop',
    'ai-learning',
    'ai-lock',
    'ai-magic',
    'ai-mail',
    'ai-mail-01',
    'ai-mail-02',
    'ai-network',
    'ai-phone-01',
    'ai-phone-02',
    'ai-programming',
    'ai-search',
    'ai-security-01',
    'ai-security-02',
    'ai-setting',
    'ai-smartwatch',
    'ai-user',
    'ai-video',
    'ai-view',
    'aids',
    'airbnb',
    'aircraft-game',
    'airdrop',
    'airplane-01',
    'airplane-02',
    'airplane-landing-01',
    'airplane-landing-02',
    'airplane-mode',
    'airplane-mode-off',
    'airplane-seat',
    'airplane-take-off-01',
    'airplane-take-off-02',
    'airplay-line',
    'airpod-01',
    'airpod-02',
    'airpod-03',
    'airport',
    'al-aqsa-mosque',
    'alarm-clock',
    'album-01',
    'album-02',
    'album-not-found-01',
    'album-not-found-02',
    'alert-01',
    'alert-02',
    'alert-circle',
    'alert-diamond',
    'alert-square',
    'algorithm',
    'alien-01',
    'alien-02',
    'align-bottom',
    'align-box-bottom-center',
    'align-box-bottom-left',
    'align-box-bottom-right',
    'align-box-middle-center',
    'align-box-middle-left',
    'align-box-middle-right',
    'align-box-top-center',
    'align-box-top-left',
    'align-box-top-right',
    'align-horizontal-center',
    'align-key-object',
    'align-left',
    'align-right',
    'align-selection',
    'align-top',
    'align-vertical-center',
    'all-bookmark',
    'allah',
    'alms',
    'alpha',
    'alpha-circle',
    'alpha-square',
    'alphabet-arabic',
    'alphabet-bangla',
    'alphabet-chinese',
    'alphabet-greek',
    'alphabet-hebrew',
    'alphabet-hindi',
    'alphabet-japanese',
    'alphabet-korean',
    'alphabet-thai',
    'amazon',
    'ambulance',
    'american-football',
    'amie',
    'ampoule',
    'analysis-text-link',
    'analytics-01',
    'analytics-02',
    'analytics-down',
    'analytics-up',
    'anchor-point',
    'android',
    'angel',
    'angle',
    'angle-01',
    'angry',
    'angry-bird',
    'anonymous',
    'api',
    'app-store',
    'apple',
    'apple-01',
    'apple-finder',
    'apple-news',
    'apple-pie',
    'apple-reminder',
    'apple-stocks',
    'apple-vision-pro',
    'approximately-equal',
    'approximately-equal-circle',
    'approximately-equal-square',
    'apricot',
    'apron',
    'arc-browser',
    'archer',
    'archive',
    'archive-01',
    'archive-02',
    'armored-boot',
    'arrange',
    'arrange-by-letters-a-z',
    'arrange-by-letters-z-a',
    'arrange-by-numbers-1-9',
    'arrange-by-numbers-9-1',
    'arrow-all-direction',
    'arrow-data-transfer-diagonal',
    'arrow-data-transfer-horizontal',
    'arrow-data-transfer-vertical',
    'arrow-diagonal',
    'arrow-down-01',
    'arrow-down-02',
    'arrow-down-03',
    'arrow-down-04',
    'arrow-down-05',
    'arrow-down-double',
    'arrow-down-left-01',
    'arrow-down-left-02',
    'arrow-down-right-01',
    'arrow-down-right-02',
    'arrow-expand',
    'arrow-expand-01',
    'arrow-expand-02',
    'arrow-expand-diagonal-01',
    'arrow-expand-diagonal-02',
    'arrow-horizontal',
    'arrow-left-01',
    'arrow-left-02',
    'arrow-left-03',
    'arrow-left-04',
    'arrow-left-05',
    'arrow-left-double',
    'arrow-left-right',
    'arrow-move-down-left',
    'arrow-move-down-right',
    'arrow-move-left-down',
    'arrow-move-right-down',
    'arrow-move-up-left',
    'arrow-move-up-right',
    'arrow-reload-horizontal',
    'arrow-reload-vertical',
    'arrow-right-01',
    'arrow-right-02',
    'arrow-right-03',
    'arrow-right-04',
    'arrow-right-05',
    'arrow-right-double',
    'arrow-shrink',
    'arrow-shrink-01',
    'arrow-shrink-02',
    'arrow-turn-backward',
    'arrow-turn-down',
    'arrow-turn-forward',
    'arrow-turn-up',
    'arrow-up-01',
    'arrow-up-02',
    'arrow-up-03',
    'arrow-up-04',
    'arrow-up-05',
    'arrow-up-double',
    'arrow-up-down',
    'arrow-up-left-01',
    'arrow-up-left-02',
    'arrow-up-right-01',
    'arrow-up-right-02',
    'arrow-vertical',
    'artboard',
    'artboard-tool',
    'artificial-intelligence-01',
    'artificial-intelligence-02',
    'artificial-intelligence-03',
    'artificial-intelligence-04',
    'artificial-intelligence-05',
    'artificial-intelligence-06',
    'artificial-intelligence-07',
    'aspect-ratio',
    'assignments',
    'asteroid-01',
    'asteroid-02',
    'astronaut-01',
    'astronaut-02',
    'at',
    'atm-01',
    'atm-02',
    'atom-01',
    'atom-02',
    'atomic-power',
    'attachment',
    'attachment-01',
    'attachment-02',
    'attachment-circle',
    'attachment-square',
    'auction',
    'audio-book-01',
    'audio-book-02',
    'audio-book-03',
    'audio-book-04',
    'audit-01',
    'audit-02',
    'augmented-reality-ar',
    'authorized',
    'auto-conversations',
    'automotive-battery-01',
    'automotive-battery-02',
    'avalanche',
    'avocado',
    'award-01',
    'award-02',
    'award-03',
    'award-04',
    'award-05',
    'baby-01',
    'baby-02',
    'baby-bed-01',
    'baby-bed-02',
    'baby-bottle',
    'baby-boy-dress',
    'baby-girl-dress',
    'backpack-01',
    'backpack-02',
    'backpack-03',
    'backward-01',
    'backward-02',
    'bacteria',
    'badminton',
    'badminton-shuttle',
    'banana',
    'bandage',
    'bank',
    'bar-code-01',
    'bar-code-02',
    'barns',
    'baseball',
    'baseball-bat',
    'baseball-helmet',
    'basketball-01',
    'basketball-02',
    'basketball-hoop',
    'bathtub-01',
    'bathtub-02',
    'batteries-energy',
    'battery-charging-01',
    'battery-charging-02',
    'battery-eco-charging',
    'battery-empty',
    'battery-full',
    'battery-low',
    'battery-medium-01',
    'battery-medium-02',
    'bbq-grill',
    'beach',
    'beach-02',
    'beater',
    'bebo',
    'bed',
    'bed-bunk',
    'bed-double',
    'bed-single-01',
    'bed-single-02',
    'bedug-01',
    'bedug-02',
    'behance-01',
    'behance-02',
    'belt',
    'bend-tool',
    'berlin',
    'berlin-tower',
    'beta',
    'bicycle',
    'bicycle-01',
    'billiard-01',
    'billiard-02',
    'binary-code',
    'bing',
    'bio-energy',
    'biomass-energy',
    'biometric-access',
    'biometric-device',
    'birthday-cake',
    'biscuit',
    'bitcoin',
    'bitcoin-01',
    'bitcoin-02',
    'bitcoin-03',
    'bitcoin-04',
    'bitcoin-bag',
    'bitcoin-circle',
    'bitcoin-cloud',
    'bitcoin-cpu',
    'bitcoin-credit-card',
    'bitcoin-database',
    'bitcoin-down-01',
    'bitcoin-down-02',
    'bitcoin-ellipse',
    'bitcoin-eye',
    'bitcoin-filter',
    'bitcoin-flashdisk',
    'bitcoin-graph',
    'bitcoin-invoice',
    'bitcoin-key',
    'bitcoin-location',
    'bitcoin-lock',
    'bitcoin-mail',
    'bitcoin-mind',
    'bitcoin-money-01',
    'bitcoin-money-02',
    'bitcoin-pie-chart',
    'bitcoin-piggy-bank',
    'bitcoin-presentation',
    'bitcoin-receipt',
    'bitcoin-receive',
    'bitcoin-rectangle',
    'bitcoin-safe',
    'bitcoin-search',
    'bitcoin-send',
    'bitcoin-setting',
    'bitcoin-shield',
    'bitcoin-shopping',
    'bitcoin-smartphone-01',
    'bitcoin-smartphone-02',
    'bitcoin-square',
    'bitcoin-store',
    'bitcoin-tag',
    'bitcoin-target',
    'bitcoin-transaction',
    'bitcoin-up-01',
    'bitcoin-up-02',
    'bitcoin-wallet',
    'bitcoin-withdraw',
    'black-hole',
    'black-hole-01',
    'blend',
    'blender',
    'block-game',
    'blockchain-01',
    'blockchain-02',
    'blockchain-03',
    'blockchain-04',
    'blockchain-05',
    'blockchain-06',
    'blockchain-07',
    'blocked',
    'blogger',
    'blood',
    'blood-bag',
    'blood-bottle',
    'blood-pressure',
    'blood-type',
    'bluetooth',
    'bluetooth-circle',
    'bluetooth-not-connected',
    'bluetooth-search',
    'bluetooth-square',
    'blur',
    'blush-brush-01',
    'blush-brush-02',
    'board-math',
    'boat',
    'body-armor',
    'body-part-leg',
    'body-part-muscle',
    'body-part-six-pack',
    'body-soap',
    'body-weight',
    'bomb',
    'bone-01',
    'bone-02',
    'book-01',
    'book-02',
    'book-03',
    'book-04',
    'book-bookmark-01',
    'book-bookmark-02',
    'book-download',
    'book-edit',
    'book-open-01',
    'book-open-02',
    'book-upload',
    'bookmark-01',
    'bookmark-02',
    'bookmark-03',
    'bookmark-add-01',
    'bookmark-add-02',
    'bookmark-block-01',
    'bookmark-block-02',
    'bookmark-check-01',
    'bookmark-check-02',
    'bookmark-minus-01',
    'bookmark-minus-02',
    'bookmark-off-01',
    'bookmark-off-02',
    'bookmark-remove-01',
    'bookmark-remove-02',
    'books-01',
    'books-02',
    'bookshelf-01',
    'bookshelf-02',
    'bookshelf-03',
    'bootstrap',
    'border-all-01',
    'border-all-02',
    'border-bottom-01',
    'border-bottom-02',
    'border-full',
    'border-horizontal',
    'border-inner',
    'border-left-01',
    'border-left-02',
    'border-none-01',
    'border-none-02',
    'border-right-01',
    'border-right-02',
    'border-top-01',
    'border-top-02',
    'border-vertical',
    'borobudur',
    'bot',
    'bounce-left',
    'bounce-right',
    'bounding-box',
    'bow-tie',
    'bowling',
    'bowling-pins',
    'boxer',
    'boxing-bag',
    'boxing-glove',
    'boxing-glove-01',
    'brain',
    'brain-01',
    'brain-02',
    'brandfetch',
    'bread-01',
    'bread-02',
    'bread-03',
    'bread-04',
    'breast-pump',
    'bridge',
    'briefcase-01',
    'briefcase-02',
    'briefcase-03',
    'briefcase-04',
    'briefcase-05',
    'briefcase-06',
    'briefcase-07',
    'briefcase-08',
    'briefcase-09',
    'briefcase-dollar',
    'broccoli',
    'brochure',
    'broken-bone',
    'browser',
    'brush',
    'bubble-chat',
    'bubble-chat-add',
    'bubble-chat-blocked',
    'bubble-chat-cancel',
    'bubble-chat-delay',
    'bubble-chat-done',
    'bubble-chat-download-01',
    'bubble-chat-download-02',
    'bubble-chat-edit',
    'bubble-chat-favourite',
    'bubble-chat-income',
    'bubble-chat-lock',
    'bubble-chat-notification',
    'bubble-chat-outcome',
    'bubble-chat-preview',
    'bubble-chat-question',
    'bubble-chat-search',
    'bubble-chat-secure',
    'bubble-chat-translate',
    'bubble-chat-unlock',
    'bubble-chat-upload',
    'bubble-chat-user',
    'bubble-tea-01',
    'bubble-tea-02',
    'bug-01',
    'bug-02',
    'building-01',
    'building-02',
    'building-03',
    'building-04',
    'building-05',
    'building-06',
    'bulb',
    'bulb-charging',
    'bulletproof-vest',
    'burj-al-arab',
    'burning-cd',
    'bus-01',
    'bus-02',
    'bus-03',
    'c-programming',
    'cabinet-01',
    'cabinet-02',
    'cabinet-03',
    'cabinet-04',
    'cable-car',
    'cactus',
    'caduceus',
    'cafe',
    'calculate',
    'calculator',
    'calculator-01',
    'calendar-01',
    'calendar-02',
    'calendar-03',
    'calendar-04',
    'calendar-add-01',
    'calendar-add-02',
    'calendar-block-01',
    'calendar-block-02',
    'calendar-check-in-01',
    'calendar-check-in-02',
    'calendar-check-out-01',
    'calendar-check-out-02',
    'calendar-download-01',
    'calendar-download-02',
    'calendar-favorite-01',
    'calendar-favorite-02',
    'calendar-lock-01',
    'calendar-lock-02',
    'calendar-love-01',
    'calendar-love-02',
    'calendar-minus-01',
    'calendar-minus-02',
    'calendar-remove-01',
    'calendar-remove-02',
    'calendar-setting-01',
    'calendar-setting-02',
    'calendar-upload-01',
    'calendar-upload-02',
    'call',
    'call-02',
    'call-add',
    'call-add-02',
    'call-blocked',
    'call-blocked-02',
    'call-disabled',
    'call-disabled-02',
    'call-done',
    'call-done-02',
    'call-end-01',
    'call-end-02',
    'call-end-03',
    'call-end-04',
    'call-incoming-01',
    'call-incoming-02',
    'call-incoming-03',
    'call-incoming-04',
    'call-internal',
    'call-internal-02',
    'call-locked',
    'call-locked-02',
    'call-minus',
    'call-minus-02',
    'call-missed-01',
    'call-missed-02',
    'call-missed-03',
    'call-missed-04',
    'call-outgoing-01',
    'call-outgoing-02',
    'call-outgoing-03',
    'call-outgoing-04',
    'call-paused',
    'call-paused-02',
    'call-received',
    'call-received-02',
    'call-ringing-01',
    'call-ringing-02',
    'call-ringing-03',
    'call-ringing-04',
    'call-unlocked',
    'call-unlocked-02',
    'calling',
    'calling-02',
    'camel',
    'camera-01',
    'camera-02',
    'camera-add-01',
    'camera-add-02',
    'camera-automatically-01',
    'camera-automatically-02',
    'camera-lens',
    'camera-microphone-01',
    'camera-microphone-02',
    'camera-night-mode-01',
    'camera-night-mode-02',
    'camera-off-01',
    'camera-off-02',
    'camera-rotated-01',
    'camera-rotated-02',
    'camera-smile-01',
    'camera-smile-02',
    'camera-tripod',
    'camera-video',
    'camper',
    'campfire',
    'cancel-01',
    'cancel-02',
    'cancel-circle',
    'cancel-circle-half-dot',
    'cancel-square',
    'candelier-01',
    'candelier-02',
    'canvas',
    'cap',
    'cap-projecting',
    'cap-round',
    'cap-straight',
    'capcut',
    'capcut-rectangle',
    'car-01',
    'car-02',
    'car-03',
    'car-04',
    'car-parking-01',
    'car-parking-02',
    'caravan',
    'card-exchange-01',
    'card-exchange-02',
    'cardigan',
    'cardiogram-01',
    'cardiogram-02',
    'cards-01',
    'cards-02',
    'cargo-ship',
    'carousel-horizontal',
    'carousel-horizontal-02',
    'carousel-vertical',
    'carrot',
    'cash-01',
    'cash-02',
    'cashback',
    'cashier',
    'cashier-02',
    'castle',
    'castle-01',
    'castle-02',
    'catalogue',
    'cayan-tower',
    'cctv-camera',
    'cd',
    'cells',
    'cellular-network',
    'cellular-network-offline',
    'celsius',
    'center-focus',
    'central-shaheed-minar',
    'centralized',
    'certificate-01',
    'certificate-02',
    'chair-01',
    'chair-02',
    'chair-03',
    'chair-04',
    'chair-05',
    'chair-barber',
    'champion',
    'change-screen-mode',
    'character-phonetic',
    'charity',
    'chart',
    'chart-average',
    'chart-bar-line',
    'chart-breakout-circle',
    'chart-breakout-square',
    'chart-bubble-01',
    'chart-bubble-02',
    'chart-column',
    'chart-decrease',
    'chart-evaluation',
    'chart-high-low',
    'chart-histogram',
    'chart-increase',
    'chart-line-data-01',
    'chart-line-data-02',
    'chart-line-data-03',
    'chart-maximum',
    'chart-medium',
    'chart-minimum',
    'chart-radar',
    'chart-relationship',
    'chart-ring',
    'chart-rose',
    'chart-scatter',
    'chat-bot',
    'chat-gpt',
    'chatting-01',
    'check-list',
    'check-unread-01',
    'check-unread-02',
    'check-unread-03',
    'check-unread-04',
    'checkmark-badge-01',
    'checkmark-badge-02',
    'checkmark-badge-03',
    'checkmark-badge-04',
    'checkmark-circle-01',
    'checkmark-circle-02',
    'checkmark-circle-03',
    'checkmark-circle-04',
    'checkmark-square-01',
    'checkmark-square-02',
    'checkmark-square-03',
    'checkmark-square-04',
    'cheese',
    'cheese-cake-01',
    'cheese-cake-02',
    'chef',
    'chef-hat',
    'chemistry-01',
    'chemistry-02',
    'chemistry-03',
    'cherry',
    'chess-01',
    'chess-02',
    'chess-pawn',
    'chicken-thighs',
    'chimney',
    'china-temple',
    'chip',
    'chip-02',
    'chocolate',
    'chrome',
    'chrysler',
    'church',
    'cinnamon-roll',
    'circle',
    'circle-arrow-data-transfer-diagonal',
    'circle-arrow-data-transfer-horizontal',
    'circle-arrow-data-transfer-vertical',
    'circle-arrow-diagonal-01',
    'circle-arrow-diagonal-02',
    'circle-arrow-down-01',
    'circle-arrow-down-02',
    'circle-arrow-down-03',
    'circle-arrow-down-double',
    'circle-arrow-down-left',
    'circle-arrow-down-right',
    'circle-arrow-expand-01',
    'circle-arrow-expand-02',
    'circle-arrow-horizontal',
    'circle-arrow-left-01',
    'circle-arrow-left-02',
    'circle-arrow-left-03',
    'circle-arrow-left-double',
    'circle-arrow-left-right',
    'circle-arrow-move-down-left',
    'circle-arrow-move-down-right',
    'circle-arrow-move-left-down',
    'circle-arrow-move-right-down',
    'circle-arrow-move-up-left',
    'circle-arrow-move-up-right',
    'circle-arrow-reload-01',
    'circle-arrow-reload-02',
    'circle-arrow-right-01',
    'circle-arrow-right-02',
    'circle-arrow-right-03',
    'circle-arrow-right-double',
    'circle-arrow-shrink-01',
    'circle-arrow-shrink-02',
    'circle-arrow-up-01',
    'circle-arrow-up-02',
    'circle-arrow-up-03',
    'circle-arrow-up-double',
    'circle-arrow-up-down',
    'circle-arrow-up-left',
    'circle-arrow-up-right',
    'circle-arrow-vertical',
    'circle-lock-01',
    'circle-lock-02',
    'circle-lock-add-01',
    'circle-lock-add-02',
    'circle-lock-check-01',
    'circle-lock-check-02',
    'circle-lock-minus-01',
    'circle-lock-minus-02',
    'circle-lock-remove-01',
    'circle-lock-remove-02',
    'circle-password',
    'circle-unlock-01',
    'circle-unlock-02',
    'city-01',
    'city-02',
    'city-03',
    'clapping-01',
    'clapping-02',
    'clean',
    'cleaning-bucket',
    'clinic',
    'clip',
    'clipboard',
    'clock-01',
    'clock-02',
    'clock-03',
    'clock-04',
    'clock-05',
    'closed-caption',
    'closed-caption-alt',
    'clothes',
    'cloud',
    'cloud-angled-rain',
    'cloud-angled-rain-zap',
    'cloud-angled-zap',
    'cloud-big-rain',
    'cloud-download',
    'cloud-fast-wind',
    'cloud-hailstone',
    'cloud-little-rain',
    'cloud-little-snow',
    'cloud-loading',
    'cloud-mid-rain',
    'cloud-mid-snow',
    'cloud-saving-done-01',
    'cloud-saving-done-02',
    'cloud-server',
    'cloud-slow-wind',
    'cloud-snow',
    'cloud-upload',
    'clubs-01',
    'clubs-02',
    'co-present',
    'code',
    'code-circle',
    'code-folder',
    'code-square',
    'codesandbox',
    'coffee-01',
    'coffee-02',
    'coffee-beans',
    'coins-01',
    'coins-02',
    'coins-bitcoin',
    'coins-dollar',
    'coins-euro',
    'coins-pound',
    'coins-swap',
    'coins-yen',
    'collections-bookmark',
    'color-picker',
    'colors',
    'colosseum',
    'column-delete',
    'column-insert',
    'comet-01',
    'comet-02',
    'coming-soon-01',
    'coming-soon-02',
    'command',
    'command-line',
    'comment-01',
    'comment-02',
    'comment-add-01',
    'comment-add-02',
    'comment-block-01',
    'comment-block-02',
    'comment-remove-01',
    'comment-remove-02',
    'compass',
    'compass-01',
    'complaint',
    'computer',
    'computer-add',
    'computer-check',
    'computer-cloud',
    'computer-desk-01',
    'computer-desk-02',
    'computer-desk-03',
    'computer-dollar',
    'computer-phone-sync',
    'computer-programming-01',
    'computer-programming-02',
    'computer-protection',
    'computer-remove',
    'computer-settings',
    'computer-video',
    'computer-video-call',
    'cone-01',
    'cone-02',
    'conference',
    'configuration-01',
    'configuration-02',
    'confused',
    'congruent-to',
    'congruent-to-circle',
    'congruent-to-square',
    'connect',
    'console',
    'constellation',
    'contact',
    'contact-01',
    'contact-02',
    'contact-book',
    'container-truck',
    'container-truck-01',
    'container-truck-02',
    'contracts',
    'conversation',
    'cook-book',
    'cookie',
    'coordinate-01',
    'coordinate-02',
    'copilot',
    'copy-01',
    'copy-02',
    'copy-link',
    'copyright',
    'corn',
    'corporate',
    'cos',
    'cosine-01',
    'cosine-02',
    'cottage',
    'cotton-candy',
    'coupon-01',
    'coupon-02',
    'coupon-03',
    'coupon-percent',
    'course',
    'court-house',
    'court-law',
    'covariate',
    'covid-info',
    'cowboy-hat',
    'cplusplus',
    'cpu',
    'cpu-charge',
    'cpu-settings',
    'crab',
    'crane',
    'crazy',
    'creative-market',
    'credit-card',
    'credit-card-accept',
    'credit-card-add',
    'credit-card-change',
    'credit-card-defrost',
    'credit-card-freeze',
    'credit-card-not-accept',
    'credit-card-not-found',
    'credit-card-pos',
    'credit-card-validation',
    'cricket-bat',
    'cricket-helmet',
    'croissant',
    'crop',
    'crowdfunding',
    'crown',
    'crying',
    'csv-01',
    'csv-02',
    'cube',
    'cupcake-01',
    'cupcake-02',
    'cupcake-03',
    'curling',
    'cursor-01',
    'cursor-02',
    'cursor-add-selection-01',
    'cursor-add-selection-02',
    'cursor-circle-selection-01',
    'cursor-circle-selection-02',
    'cursor-disabled-01',
    'cursor-disabled-02',
    'cursor-edit-01',
    'cursor-edit-02',
    'cursor-hold-01',
    'cursor-hold-02',
    'cursor-in-window',
    'cursor-info-01',
    'cursor-info-02',
    'cursor-loading-01',
    'cursor-loading-02',
    'cursor-magic-selection-01',
    'cursor-magic-selection-02',
    'cursor-move-01',
    'cursor-move-02',
    'cursor-pointer-01',
    'cursor-pointer-02',
    'cursor-progress-01',
    'cursor-progress-02',
    'cursor-progress-03',
    'cursor-progress-04',
    'cursor-rectangle-selection-01',
    'cursor-rectangle-selection-02',
    'cursor-remove-selection-01',
    'cursor-remove-selection-02',
    'cursor-text',
    'curtains',
    'curvy-left-direction',
    'curvy-left-right-direction',
    'curvy-right-direction',
    'curvy-up-down-direction',
    'customer-service',
    'customer-service-01',
    'customer-service-02',
    'customer-support',
    'customize',
    'cylinder-01',
    'cylinder-02',
    'cylinder-03',
    'cylinder-04',
    'danger',
    'dark-mode',
    'dart',
    'dashboard-browsing',
    'dashboard-circle',
    'dashboard-circle-add',
    'dashboard-circle-edit',
    'dashboard-circle-remove',
    'dashboard-circle-settings',
    'dashboard-speed-01',
    'dashboard-speed-02',
    'dashboard-square-01',
    'dashboard-square-02',
    'dashboard-square-03',
    'dashboard-square-add',
    'dashboard-square-edit',
    'dashboard-square-remove',
    'dashboard-square-setting',
    'dashed-line-01',
    'dashed-line-02',
    'data-recovery',
    'database',
    'database-01',
    'database-02',
    'database-add',
    'database-export',
    'database-import',
    'database-locked',
    'database-restore',
    'database-setting',
    'database-sync',
    'database-sync-01',
    'dates',
    'dead',
    'delete-01',
    'delete-02',
    'delete-03',
    'delete-04',
    'delete-column',
    'delete-put-back',
    'delete-row',
    'delete-throw',
    'delivered-sent',
    'delivery-box-01',
    'delivery-box-02',
    'delivery-delay-01',
    'delivery-delay-02',
    'delivery-return-01',
    'delivery-return-02',
    'delivery-secure-01',
    'delivery-secure-02',
    'delivery-sent-01',
    'delivery-sent-02',
    'delivery-tracking-01',
    'delivery-tracking-02',
    'delivery-truck-01',
    'delivery-truck-02',
    'delivery-view-01',
    'delivery-view-02',
    'dental-braces',
    'dental-broken-tooth',
    'dental-care',
    'dental-tooth',
    'departement',
    'desert',
    'desk',
    'desk-01',
    'desk-02',
    'developer',
    'deviantart',
    'device-access',
    'diagonal-scroll-point-01',
    'diagonal-scroll-point-02',
    'dialpad-circle-01',
    'dialpad-circle-02',
    'dialpad-square-01',
    'dialpad-square-02',
    'diameter',
    'diamond',
    'diamond-01',
    'diamond-02',
    'diaper',
    'dice',
    'dice-faces-01',
    'dice-faces-02',
    'dice-faces-03',
    'dice-faces-04',
    'dice-faces-05',
    'dice-faces-06',
    'digestion',
    'digg',
    'digital-clock',
    'dim-sum-01',
    'dim-sum-02',
    'dining-table',
    'diploma',
    'direction-left-01',
    'direction-left-02',
    'direction-right-01',
    'direction-right-02',
    'directions-01',
    'directions-02',
    'dirham',
    'disability-01',
    'disability-02',
    'discord',
    'discount',
    'discount-01',
    'discount-tag-01',
    'discount-tag-02',
    'discover-circle',
    'discover-square',
    'dish-01',
    'dish-02',
    'dish-washer',
    'displeased',
    'distribute-horizontal-center',
    'distribute-horizontal-left',
    'distribute-horizontal-right',
    'distribute-vertical-bottom',
    'distribute-vertical-center',
    'distribute-vertical-top',
    'distribution',
    'divide-sign',
    'divide-sign-circle',
    'divide-sign-square',
    'dna',
    'dna-01',
    'do-not-touch-01',
    'do-not-touch-02',
    'doc-01',
    'doc-02',
    'doctor-01',
    'doctor-02',
    'doctor-03',
    'document-attachment',
    'document-code',
    'document-validation',
    'dollar-01',
    'dollar-02',
    'dollar-circle',
    'dollar-receive-01',
    'dollar-receive-02',
    'dollar-send-01',
    'dollar-send-02',
    'dollar-square',
    'dome',
    'domino',
    'door',
    'door-01',
    'door-02',
    'door-lock',
    'doughnut',
    'download-01',
    'download-02',
    'download-03',
    'download-04',
    'download-05',
    'download-circle-01',
    'download-circle-02',
    'download-square-01',
    'download-square-02',
    'drag-01',
    'drag-02',
    'drag-03',
    'drag-04',
    'drag-drop',
    'drag-drop-horizontal',
    'drag-drop-vertical',
    'drag-left-01',
    'drag-left-02',
    'drag-left-03',
    'drag-left-04',
    'drag-right-01',
    'drag-right-02',
    'drag-right-03',
    'drag-right-04',
    'drawing-compass',
    'drawing-mode',
    'dress-01',
    'dress-02',
    'dress-03',
    'dress-04',
    'dress-05',
    'dress-06',
    'dress-07',
    'dressing-table-01',
    'dressing-table-02',
    'dressing-table-03',
    'dribbble',
    'drink',
    'drone',
    'drooling',
    'dropbox',
    'droplet',
    'dropper',
    'ds3-tool',
    'dua',
    'dumbbell-01',
    'dumbbell-02',
    'dumbbell-03',
    'ear',
    'ear-rings-01',
    'ear-rings-02',
    'ear-rings-03',
    'earth',
    'ease-curve-control-points',
    'ease-in',
    'ease-in-control-point',
    'ease-in-out',
    'ease-out',
    'ease-out-control-point',
    'eco-energy',
    'eco-lab',
    'eco-lab-01',
    'eco-lab-02',
    'eco-power',
    'edge-style',
    'edit-01',
    'edit-02',
    'edit-off',
    'edit-road',
    'edit-table',
    'edit-user-02',
    'eggs',
    'eid-mubarak',
    'eiffel-tower',
    'elearning-exchange',
    'electric-home-01',
    'electric-home-02',
    'electric-plugs',
    'electric-tower-01',
    'electric-tower-02',
    'electric-wire',
    'ellipse-selection',
    'encrypt',
    'energy',
    'energy-ellipse',
    'energy-rectangle',
    'entering-geo-fence',
    'envato',
    'equal-sign',
    'equal-sign-circle',
    'equal-sign-square',
    'equipment-bench-press',
    'equipment-chest-press',
    'equipment-gym-01',
    'equipment-gym-02',
    'equipment-gym-03',
    'equipment-weightlifting',
    'eraser',
    'eraser-01',
    'eraser-add',
    'eraser-auto',
    'estimate-01',
    'estimate-02',
    'ethereum',
    'ethereum-ellipse',
    'ethereum-rectangle',
    'euro',
    'euro-circle',
    'euro-receive',
    'euro-send',
    'euro-square',
    'ev-charging',
    'evil',
    'exchange-01',
    'exchange-02',
    'exchange-03',
    'expander',
    'external-drive',
    'eye',
    'face-id',
    'facebook-01',
    'facebook-02',
    'factory',
    'factory-01',
    'factory-02',
    'fahrenheit',
    'falling-star',
    'fast-wind',
    'favourite',
    'favourite-circle',
    'favourite-square',
    'feather',
    'fencing',
    'fencing-mask',
    'ferris-wheel',
    'ferry-boat',
    'figma',
    'file-01',
    'file-02',
    'file-add',
    'file-attachment',
    'file-audio',
    'file-bitcoin',
    'file-block',
    'file-bookmark',
    'file-cloud',
    'file-corrupt',
    'file-dollar',
    'file-download',
    'file-edit',
    'file-euro',
    'file-export',
    'file-favourite',
    'file-import',
    'file-link',
    'file-locked',
    'file-management',
    'file-minus',
    'file-music',
    'file-not-found',
    'file-paste',
    'file-pin',
    'file-pound',
    'file-remove',
    'file-script',
    'file-search',
    'file-security',
    'file-shredder',
    'file-star',
    'file-sync',
    'file-unknown',
    'file-unlocked',
    'file-upload',
    'file-validation',
    'file-verified',
    'file-video',
    'file-view',
    'file-yen',
    'file-zip',
    'files-01',
    'files-02',
    'film-01',
    'film-02',
    'film-roll-01',
    'film-roll-02',
    'filter',
    'filter-add',
    'filter-edit',
    'filter-horizontal',
    'filter-mail-circle',
    'filter-mail-square',
    'filter-remove',
    'filter-reset',
    'filter-vertical',
    'finger-access',
    'finger-print',
    'finger-print-add',
    'finger-print-check',
    'finger-print-minus',
    'finger-print-remove',
    'finger-print-scan',
    'fingerprint-scan',
    'fins',
    'fire',
    'fire-pit',
    'fire-security',
    'firewall',
    'first-aid-kit',
    'first-bracket',
    'first-bracket-circle',
    'first-bracket-square',
    'fish-food',
    'fiverr',
    'flag-01',
    'flag-02',
    'flag-03',
    'flash',
    'flash-off',
    'flaticon',
    'flickr',
    'flim-slate',
    'flip-bottom',
    'flip-horizontal',
    'flip-left',
    'flip-phone',
    'flip-right',
    'flip-top',
    'flip-vertical',
    'floor-plan',
    'floppy-disk',
    'flow',
    'flow-circle',
    'flow-connection',
    'flow-square',
    'flowchart-01',
    'flowchart-02',
    'flower',
    'flower-pot',
    'flushed',
    'flying-human',
    'focus-point',
    'folder-01',
    'folder-02',
    'folder-03',
    'folder-add',
    'folder-attachment',
    'folder-audio',
    'folder-block',
    'folder-check',
    'folder-cloud',
    'folder-details',
    'folder-details-reference',
    'folder-download',
    'folder-edit',
    'folder-export',
    'folder-favourite',
    'folder-file-storage',
    'folder-import',
    'folder-library',
    'folder-links',
    'folder-locked',
    'folder-management',
    'folder-minus',
    'folder-music',
    'folder-off',
    'folder-open',
    'folder-pin',
    'folder-remove',
    'folder-search',
    'folder-security',
    'folder-shared-01',
    'folder-shared-02',
    'folder-shared-03',
    'folder-sync',
    'folder-transfer',
    'folder-unknown',
    'folder-unlocked',
    'folder-upload',
    'folder-video',
    'folder-view',
    'folder-zip',
    'folders',
    'football',
    'football-pitch',
    'forgot-password',
    'fork',
    'forrst',
    'fortress',
    'forward-01',
    'forward-02',
    'four-finger-02',
    'four-finger-03',
    'four-k',
    'foursquare',
    'framer',
    'frameworks',
    'french-fries-01',
    'french-fries-02',
    'fridge',
    'fry',
    'fuel-01',
    'fuel-02',
    'fuel-station',
    'function',
    'function-circle',
    'function-of-x',
    'function-square',
    'galaxy',
    'game',
    'game-controller-01',
    'game-controller-02',
    'game-controller-03',
    'gameboy',
    'garage',
    'garbage-truck',
    'gas-pipe',
    'gas-stove',
    'gem',
    'geology-crust',
    'geometric-shapes-01',
    'geometric-shapes-02',
    'gibbous-moon',
    'gif-01',
    'gif-02',
    'gift',
    'gift-card',
    'git-branch',
    'git-commit',
    'git-compare',
    'git-fork',
    'git-merge',
    'git-pull-request',
    'git-pull-request-closed',
    'git-pull-request-draft',
    'gitbook',
    'github',
    'github-01',
    'gitlab',
    'give-blood',
    'give-pill',
    'glasses',
    'global',
    'global-editing',
    'global-education',
    'global-refresh',
    'global-search',
    'globe',
    'globe-02',
    'glove',
    'go-backward-10-sec',
    'go-backward-15-sec',
    'go-backward-30-sec',
    'go-backward-5-sec',
    'go-backward-60-sec',
    'go-forward-10-sec',
    'go-forward-15-sec',
    'go-forward-30-sec',
    'go-forward-5-sec',
    'go-forward-60-sec',
    'gold',
    'gold-ingots',
    'golf-ball',
    'golf-bat',
    'golf-cart',
    'golf-hole',
    'google',
    'google-doc',
    'google-drive',
    'google-gemini',
    'google-home',
    'google-lens',
    'google-maps',
    'google-photos',
    'google-sheet',
    'gps-01',
    'gps-02',
    'gps-disconnected',
    'gps-off-01',
    'gps-off-02',
    'gps-signal-01',
    'gps-signal-02',
    'gpu',
    'graduate-female',
    'graduate-male',
    'graduation-scroll',
    'grapes',
    'gravity',
    'greater-than',
    'greater-than-circle',
    'greater-than-square',
    'greek-helmet',
    'green-house',
    'grid',
    'grid-off',
    'grid-table',
    'grid-view',
    'grimacing',
    'grinning',
    'grok',
    'group-items',
    'group-layers',
    'guest-house',
    'gun',
    'gymnastic',
    'gymnastic-rings',
    'hackerrank',
    'hair-clips',
    'hair-dryer',
    'haji',
    'halal',
    'hamburger-01',
    'hamburger-02',
    'hand-bag-01',
    'hand-bag-02',
    'hand-beater',
    'hand-grip',
    'hand-pointing-down-01',
    'hand-pointing-down-02',
    'hand-pointing-down-03',
    'hand-pointing-down-04',
    'hand-pointing-left-01',
    'hand-pointing-left-02',
    'hand-pointing-left-03',
    'hand-pointing-left-04',
    'hand-pointing-right-01',
    'hand-pointing-right-02',
    'hand-pointing-right-03',
    'hand-pointing-right-04',
    'hand-prayer',
    'hand-sanitizer',
    'handcuffs',
    'hanger',
    'hanging-clock',
    'hangout',
    'happy',
    'hard-drive',
    'hdd',
    'hdr-01',
    'hdr-02',
    'heading',
    'heading-01',
    'heading-02',
    'heading-03',
    'heading-04',
    'heading-05',
    'heading-06',
    'headphone-mute',
    'headphones',
    'headset',
    'headset-connected',
    'headset-off',
    'healtcare',
    'health',
    'heart-add',
    'heart-check',
    'heart-remove',
    'heartbreak',
    'helicopter',
    'help-circle',
    'help-square',
    'hexagon',
    'hexagon-01',
    'hierarchy',
    'hierarchy-circle-01',
    'hierarchy-circle-02',
    'hierarchy-circle-03',
    'hierarchy-files',
    'hierarchy-square-01',
    'hierarchy-square-02',
    'hierarchy-square-03',
    'hierarchy-square-04',
    'hierarchy-square-05',
    'hierarchy-square-06',
    'hierarchy-square-07',
    'hierarchy-square-08',
    'hierarchy-square-10',
    'high-heels-01',
    'high-heels-02',
    'hijab',
    'hockey',
    'hold-01',
    'hold-02',
    'hold-03',
    'hold-04',
    'hold-05',
    'hold-locked-01',
    'hold-locked-02',
    'hold-phone',
    'hologram',
    'home-01',
    'home-02',
    'home-03',
    'home-04',
    'home-05',
    'home-06',
    'home-07',
    'home-08',
    'home-09',
    'home-10',
    'home-11',
    'home-12',
    'home-13',
    'home-wifi',
    'honey-01',
    'honey-02',
    'honor',
    'honour-star',
    'hoodie',
    'horizonal-scroll-point',
    'horizontal-resize',
    'hospital-01',
    'hospital-02',
    'hospital-bed-01',
    'hospital-bed-02',
    'hospital-location',
    'hot-air-balloon',
    'hot-price',
    'hot-tube',
    'hotdog',
    'hotel-01',
    'hotel-02',
    'hotspot',
    'hotspot-offline',
    'hourglass',
    'hourglass-off',
    'house-01',
    'house-02',
    'house-03',
    'house-04',
    'house-05',
    'house-solar-panel',
    'html-5',
    'humidity',
    'hut',
    'hydro-power',
    'hyperbole',
    'ice-cream-01',
    'ice-cream-02',
    'ice-cream-03',
    'ice-cream-04',
    'ice-hockey',
    'ice-skating',
    'ico',
    'iconjar',
    'id',
    'id-not-verified',
    'id-verified',
    'idea',
    'idea-01',
    'identification',
    'identity-card',
    'image-01',
    'image-02',
    'image-add-01',
    'image-add-02',
    'image-composition',
    'image-composition-oval',
    'image-crop',
    'image-delete-01',
    'image-delete-02',
    'image-done-01',
    'image-done-02',
    'image-download',
    'image-download-02',
    'image-not-found-01',
    'image-not-found-02',
    'image-remove-01',
    'image-remove-02',
    'image-upload',
    'image-upload-01',
    'imo',
    'important-book',
    'in-love',
    'inbox',
    'inbox-check',
    'inbox-download',
    'inbox-unread',
    'inbox-upload',
    'incognito',
    'india-gate',
    'inequality-01',
    'inequality-02',
    'inequality-circle-01',
    'inequality-circle-02',
    'inequality-square-01',
    'inequality-square-02',
    'infinity-01',
    'infinity-02',
    'infinity-circle',
    'infinity-square',
    'information-circle',
    'information-diamond',
    'information-square',
    'injection',
    'insert-bottom-image',
    'insert-center-image',
    'insert-column',
    'insert-column-left',
    'insert-pi',
    'insert-row',
    'insert-row-down',
    'insert-row-up',
    'insert-top-image',
    'inspect-code',
    'instagram',
    'installing-updates-01',
    'installing-updates-02',
    'internet',
    'internet-antenna-01',
    'internet-antenna-02',
    'internet-antenna-03',
    'internet-antenna-04',
    'investigation',
    'invoice',
    'invoice-01',
    'invoice-02',
    'invoice-03',
    'invoice-04',
    'ipod',
    'iris-scan',
    'island',
    'jar',
    'java',
    'java-script',
    'job-link',
    'job-search',
    'job-share',
    'jogger-pants',
    'join-bevel',
    'join-round',
    'join-straight',
    'joker',
    'joystick-01',
    'joystick-02',
    'joystick-03',
    'joystick-04',
    'joystick-05',
    'jpg-01',
    'jpg-02',
    'jsx-01',
    'jsx-02',
    'jsx-03',
    'judge',
    'jupiter',
    'justice-scale-01',
    'justice-scale-02',
    'kaaba-01',
    'kaaba-02',
    'kayak',
    'keffiyeh',
    'kettle',
    'kettle-01',
    'kettlebell',
    'ketupat',
    'key-01',
    'key-02',
    'key-generator-fob',
    'keyboard',
    'keyframe',
    'keyframe-add',
    'keyframe-align-center',
    'keyframe-align-horizontal',
    'keyframe-align-vertical',
    'keyframe-bottom',
    'keyframe-left',
    'keyframe-remove',
    'keyframe-right',
    'keyframe-top',
    'keyframes-double',
    'keyframes-double-add',
    'keyframes-double-remove',
    'keyframes-multiple',
    'keyframes-multiple-add',
    'keyframes-multiple-remove',
    'kickstarter-01',
    'kickstarter-02',
    'kid',
    'kidneys',
    'kissing',
    'kitchen-utensils',
    'kite',
    'knife-01',
    'knife-02',
    'knife-bread',
    'knight-shield',
    'knives',
    'knowledge-01',
    'knowledge-02',
    'kurta',
    'kurta-01',
    'label',
    'label-important',
    'labor',
    'labs',
    'lake',
    'lamp',
    'lamp-01',
    'lamp-02',
    'lamp-03',
    'lamp-04',
    'lamp-05',
    'language-circle',
    'language-skill',
    'language-square',
    'lantern',
    'laptop',
    'laptop-add',
    'laptop-charging',
    'laptop-check',
    'laptop-cloud',
    'laptop-issue',
    'laptop-performance',
    'laptop-phone-sync',
    'laptop-programming',
    'laptop-remove',
    'laptop-settings',
    'laptop-video',
    'lasso-tool-01',
    'lasso-tool-02',
    'last-fm',
    'latitude',
    'laughing',
    'laurel-wreath-01',
    'laurel-wreath-02',
    'laurel-wreath-first-01',
    'laurel-wreath-first-02',
    'laurel-wreath-left-01',
    'laurel-wreath-left-02',
    'laurel-wreath-left-03',
    'laurel-wreath-right-01',
    'laurel-wreath-right-02',
    'laurel-wreath-right-03',
    'layer-add',
    'layer-mask-01',
    'layer-mask-02',
    'layers-01',
    'layers-02',
    'layers-logo',
    'layout-01',
    'layout-02',
    'layout-03',
    'layout-04',
    'layout-05',
    'layout-06',
    'layout-07',
    'layout-2-column',
    'layout-2-row',
    'layout-3-column',
    'layout-3-row',
    'layout-bottom',
    'layout-grid',
    'layout-left',
    'layout-right',
    'layout-table-01',
    'layout-table-02',
    'layout-top',
    'leaf-01',
    'leaf-02',
    'leaf-03',
    'leaf-04',
    'leaving-geo-fence',
    'leetcode',
    'left-angle',
    'left-to-right-block-quote',
    'left-to-right-list-bullet',
    'left-to-right-list-dash',
    'left-to-right-list-number',
    'left-to-right-list-star',
    'left-to-right-list-star-01',
    'left-to-right-list-triangle',
    'left-triangle',
    'legal-01',
    'legal-02',
    'legal-document-01',
    'legal-document-02',
    'legal-hammer',
    'less-than',
    'less-than-circle',
    'less-than-square',
    'letter-spacing',
    'libraries',
    'library',
    'license',
    'license-draft',
    'license-maintenance',
    'license-no',
    'license-pin',
    'license-third-party',
    'lift-truck',
    'lighthouse',
    'limitation',
    'line',
    'liner',
    'link-01',
    'link-02',
    'link-03',
    'link-04',
    'link-05',
    'link-06',
    'link-backward',
    'link-circle',
    'link-circle-02',
    'link-forward',
    'link-square-01',
    'link-square-02',
    'linkedin-01',
    'linkedin-02',
    'list-setting',
    'list-view',
    'litecoin',
    'liver',
    'loading-01',
    'loading-02',
    'loading-03',
    'loading-04',
    'location-01',
    'location-02',
    'location-03',
    'location-04',
    'location-05',
    'location-06',
    'location-07',
    'location-08',
    'location-09',
    'location-10',
    'location-add-01',
    'location-add-02',
    'location-check-01',
    'location-check-02',
    'location-favourite-01',
    'location-favourite-02',
    'location-offline-01',
    'location-offline-02',
    'location-offline-03',
    'location-offline-04',
    'location-remove-01',
    'location-remove-02',
    'location-share-01',
    'location-share-02',
    'location-star-01',
    'location-star-02',
    'location-update-01',
    'location-update-02',
    'location-user-01',
    'location-user-02',
    'location-user-03',
    'location-user-04',
    'lock',
    'lock-computer',
    'lock-key',
    'lock-password',
    'lock-sync-01',
    'lock-sync-02',
    'locked',
    'locker',
    'locker-01',
    'login-01',
    'login-02',
    'login-03',
    'login-circle-01',
    'login-circle-02',
    'login-method',
    'login-square-01',
    'login-square-02',
    'logout-01',
    'logout-02',
    'logout-03',
    'logout-04',
    'logout-05',
    'logout-circle-01',
    'logout-circle-02',
    'logout-square-01',
    'logout-square-02',
    'lollipop',
    'long-sleeve-shirt',
    'longitude',
    'look-bottom',
    'look-left',
    'look-right',
    'look-top',
    'loom',
    'lottiefiles',
    'love-korean-finger',
    'loyalty-card',
    'luggage-01',
    'luggage-02',
    'lungs',
    'machine-robot',
    'magic-wand-01',
    'magic-wand-02',
    'magic-wand-03',
    'magnet',
    'magnet-01',
    'magnet-02',
    'mail-01',
    'mail-02',
    'mail-account-01',
    'mail-account-02',
    'mail-add-01',
    'mail-add-02',
    'mail-at-sign-01',
    'mail-at-sign-02',
    'mail-block-01',
    'mail-block-02',
    'mail-download-01',
    'mail-download-02',
    'mail-edit-01',
    'mail-edit-02',
    'mail-lock-01',
    'mail-lock-02',
    'mail-love-01',
    'mail-love-02',
    'mail-minus-01',
    'mail-minus-02',
    'mail-open',
    'mail-open-01',
    'mail-open-02',
    'mail-open-love',
    'mail-receive-01',
    'mail-receive-02',
    'mail-remove-01',
    'mail-remove-02',
    'mail-reply-01',
    'mail-reply-02',
    'mail-reply-all-01',
    'mail-reply-all-02',
    'mail-search-01',
    'mail-search-02',
    'mail-secure-01',
    'mail-secure-02',
    'mail-send-01',
    'mail-send-02',
    'mail-setting-01',
    'mail-setting-02',
    'mail-unlock-01',
    'mail-unlock-02',
    'mail-upload-01',
    'mail-upload-02',
    'mail-validation-01',
    'mail-validation-02',
    'mail-voice-01',
    'mail-voice-02',
    'mailbox',
    'mailbox-01',
    'manager',
    'map-pin',
    'map-pinpoint-01',
    'map-pinpoint-02',
    'maping',
    'maps',
    'maps-circle-01',
    'maps-circle-02',
    'maps-editing',
    'maps-global-01',
    'maps-global-02',
    'maps-location-01',
    'maps-location-02',
    'maps-off',
    'maps-refresh',
    'maps-search',
    'maps-square-01',
    'maps-square-02',
    'market-analysis',
    'marketing',
    'mask',
    'mask-love',
    'master-card',
    'matches',
    'math',
    'matrix',
    'maximize-01',
    'maximize-02',
    'maximize-03',
    'maximize-04',
    'maximize-screen',
    'mayan-pyramid',
    'maze',
    'medal-01',
    'medal-02',
    'medal-03',
    'medal-04',
    'medal-05',
    'medal-06',
    'medal-07',
    'medal-first-place',
    'medal-second-place',
    'medal-third-place',
    'medical-file',
    'medical-mask',
    'medicine-01',
    'medicine-02',
    'medicine-bottle-01',
    'medicine-bottle-02',
    'medicine-syrup',
    'medium',
    'medium-square',
    'meeting-room',
    'megaphone-01',
    'megaphone-02',
    'meh',
    'mentor',
    'mentoring',
    'menu-01',
    'menu-02',
    'menu-03',
    'menu-04',
    'menu-05',
    'menu-06',
    'menu-07',
    'menu-08',
    'menu-09',
    'menu-10',
    'menu-11',
    'menu-circle',
    'menu-restaurant',
    'menu-square',
    'message-01',
    'message-02',
    'message-add-01',
    'message-add-02',
    'message-blocked',
    'message-cancel-01',
    'message-cancel-02',
    'message-delay-01',
    'message-delay-02',
    'message-done-01',
    'message-done-02',
    'message-download-01',
    'message-download-02',
    'message-edit-01',
    'message-edit-02',
    'message-favourite-01',
    'message-favourite-02',
    'message-incoming-01',
    'message-incoming-02',
    'message-lock-01',
    'message-lock-02',
    'message-multiple-01',
    'message-multiple-02',
    'message-notification-01',
    'message-notification-02',
    'message-outgoing-01',
    'message-outgoing-02',
    'message-preview-01',
    'message-preview-02',
    'message-programming',
    'message-question',
    'message-search-01',
    'message-search-02',
    'message-secure-01',
    'message-secure-02',
    'message-translate',
    'message-unlock-01',
    'message-unlock-02',
    'message-upload-01',
    'message-upload-02',
    'message-user-01',
    'message-user-02',
    'messenger',
    'meta',
    'metro',
    'mic-01',
    'mic-02',
    'mic-off-01',
    'mic-off-02',
    'microsoft',
    'microsoft-admin',
    'microwave',
    'milk-bottle',
    'milk-carton',
    'milk-coconut',
    'milk-oat',
    'minimize-01',
    'minimize-02',
    'minimize-03',
    'minimize-04',
    'minimize-screen',
    'mining-01',
    'mining-02',
    'mining-03',
    'minus-plus-01',
    'minus-plus-02',
    'minus-plus-circle-01',
    'minus-plus-circle-02',
    'minus-plus-square-01',
    'minus-plus-square-02',
    'minus-sign',
    'minus-sign-circle',
    'minus-sign-square',
    'mirror',
    'mirroring-screen',
    'mixer',
    'mobile-navigator-01',
    'mobile-navigator-02',
    'mobile-programming-01',
    'mobile-programming-02',
    'mobile-protection',
    'mobile-security',
    'mochi',
    'modern-tv',
    'modern-tv-four-k',
    'modern-tv-issue',
    'molecules',
    'monas',
    'money-01',
    'money-02',
    'money-03',
    'money-04',
    'money-add-01',
    'money-add-02',
    'money-bag-01',
    'money-bag-02',
    'money-exchange-01',
    'money-exchange-02',
    'money-exchange-03',
    'money-not-found-01',
    'money-not-found-02',
    'money-not-found-03',
    'money-not-found-04',
    'money-receive-01',
    'money-receive-02',
    'money-receive-circle',
    'money-receive-flow-01',
    'money-receive-flow-02',
    'money-receive-square',
    'money-remove-01',
    'money-remove-02',
    'money-safe',
    'money-saving-jar',
    'money-security',
    'money-send-01',
    'money-send-02',
    'money-send-circle',
    'money-send-flow-01',
    'money-send-flow-02',
    'money-send-square',
    'monocle',
    'monocle-01',
    'monster',
    'moon',
    'moon-01',
    'moon-02',
    'moon-angled-rain-zap',
    'moon-cloud',
    'moon-cloud-angled-rain',
    'moon-cloud-angled-zap',
    'moon-cloud-big-rain',
    'moon-cloud-fast-wind',
    'moon-cloud-hailstone',
    'moon-cloud-little-rain',
    'moon-cloud-little-snow',
    'moon-cloud-mid-rain',
    'moon-cloud-mid-snow',
    'moon-cloud-slow-wind',
    'moon-cloud-snow',
    'moon-eclipse',
    'moon-fast-wind',
    'moon-landing',
    'moon-slow-wind',
    'moonset',
    'more',
    'more-01',
    'more-02',
    'more-03',
    'more-horizontal',
    'more-horizontal-circle-01',
    'more-horizontal-circle-02',
    'more-horizontal-square-01',
    'more-horizontal-square-02',
    'more-or-less',
    'more-or-less-circle',
    'more-or-less-square',
    'more-vertical',
    'more-vertical-circle-01',
    'more-vertical-circle-02',
    'more-vertical-square-01',
    'more-vertical-square-02',
    'mortar',
    'mortarboard-01',
    'mortarboard-02',
    'mosque-01',
    'mosque-02',
    'mosque-03',
    'mosque-04',
    'mosque-05',
    'mosque-location',
    'motion-01',
    'motion-02',
    'motorbike-01',
    'motorbike-02',
    'mountain',
    'mouse-01',
    'mouse-02',
    'mouse-03',
    'mouse-04',
    'mouse-05',
    'mouse-06',
    'mouse-07',
    'mouse-08',
    'mouse-09',
    'mouse-10',
    'mouse-11',
    'mouse-12',
    'mouse-13',
    'mouse-14',
    'mouse-15',
    'mouse-16',
    'mouse-17',
    'mouse-18',
    'mouse-19',
    'mouse-20',
    'mouse-21',
    'mouse-22',
    'mouse-23',
    'mouse-left-click-01',
    'mouse-left-click-02',
    'mouse-left-click-03',
    'mouse-left-click-04',
    'mouse-left-click-05',
    'mouse-left-click-06',
    'mouse-right-click-01',
    'mouse-right-click-02',
    'mouse-right-click-03',
    'mouse-right-click-04',
    'mouse-right-click-05',
    'mouse-right-click-06',
    'mouse-scroll-01',
    'mouse-scroll-02',
    'move',
    'move-01',
    'move-02',
    'move-bottom',
    'move-left',
    'move-right',
    'move-to',
    'move-top',
    'mp-3-01',
    'mp-3-02',
    'mp-4-01',
    'mp-4-02',
    'muhammad',
    'multiplication-sign',
    'multiplication-sign-circle',
    'multiplication-sign-square',
    'mushroom',
    'mushroom-01',
    'music-note-01',
    'music-note-02',
    'music-note-03',
    'music-note-04',
    'music-note-square-01',
    'music-note-square-02',
    'muslim',
    'mute',
    'mymind',
    'n-th-root',
    'n-th-root-circle',
    'n-th-root-square',
    'nano-technology',
    'natural-food',
    'navigation-01',
    'navigation-02',
    'navigation-03',
    'navigation-04',
    'navigator-01',
    'navigator-02',
    'necklace',
    'nerd',
    'neural-network',
    'neutral',
    'new-job',
    'new-office',
    'new-releases',
    'new-twitter',
    'new-twitter-ellipse',
    'new-twitter-rectangle',
    'news',
    'news-01',
    'next',
    'nike',
    'nintendo',
    'nintendo-switch',
    'niqab',
    'no-internet',
    'no-meeting-room',
    'node-add',
    'node-edit',
    'node-move-down',
    'node-move-up',
    'node-remove',
    'noodles',
    'nose',
    'not-equal-sign',
    'not-equal-sign-circle',
    'not-equal-sign-square',
    'note',
    'note-01',
    'note-02',
    'note-03',
    'note-04',
    'note-05',
    'note-add',
    'note-done',
    'note-edit',
    'note-remove',
    'notebook',
    'notebook-01',
    'notebook-02',
    'notification-01',
    'notification-02',
    'notification-03',
    'notification-block-01',
    'notification-block-02',
    'notification-block-03',
    'notification-bubble',
    'notification-circle',
    'notification-off-01',
    'notification-off-02',
    'notification-off-03',
    'notification-snooze-01',
    'notification-snooze-02',
    'notification-snooze-03',
    'notification-square',
    'notion-01',
    'notion-02',
    'npm',
    'nuclear-power',
    'nut',
    'obtuse',
    'octagon',
    'octopus',
    'office',
    'office-365',
    'office-chair',
    'oil-barrel',
    'ok-finger',
    'olympic-torch',
    'online-learning-01',
    'online-learning-02',
    'online-learning-03',
    'online-learning-04',
    'open-caption',
    'option',
    'orange',
    'orbit-01',
    'orbit-02',
    'organic-food',
    'orthogonal-edge',
    'oval',
    'oven',
    'package',
    'package-add',
    'package-delivered',
    'package-moving',
    'package-open',
    'package-out-of-stock',
    'package-process',
    'package-receive',
    'package-remove',
    'package-search',
    'package-sent',
    'pacman-01',
    'pacman-02',
    'paint-board',
    'paint-brush-01',
    'paint-brush-02',
    'paint-brush-03',
    'paint-brush-04',
    'paint-bucket',
    'pan-01',
    'pan-02',
    'pan-03',
    'parabola-01',
    'parabola-02',
    'parabola-03',
    'paragliding',
    'paragraph',
    'paragraph-bullets-point-01',
    'paragraph-bullets-point-02',
    'paragraph-spacing',
    'parallelogram',
    'parking-area-circle',
    'parking-area-square',
    'passport',
    'passport-01',
    'passport-expired',
    'passport-valid',
    'password-validation',
    'path',
    'pathfinder-crop',
    'pathfinder-divide',
    'pathfinder-exclude',
    'pathfinder-intersect',
    'pathfinder-merge',
    'pathfinder-minus-back',
    'pathfinder-minus-front',
    'pathfinder-outline',
    'pathfinder-trim',
    'pathfinder-unite',
    'patient',
    'patio',
    'pause',
    'pavilon',
    'pay-by-check',
    'payment-01',
    'payment-02',
    'payment-success-01',
    'payment-success-02',
    'payoneer',
    'paypal',
    'pdf-01',
    'pdf-02',
    'peer-to-peer-01',
    'peer-to-peer-02',
    'peer-to-peer-03',
    'pen-connect-bluetooth',
    'pen-connect-usb',
    'pen-connect-wifi',
    'pen-tool-01',
    'pen-tool-02',
    'pen-tool-03',
    'pen-tool-add',
    'pen-tool-minus',
    'pencil',
    'pencil-edit-01',
    'pencil-edit-02',
    'pendulum',
    'pensive',
    'pentagon',
    'pentagon-01',
    'percent',
    'percent-circle',
    'percent-square',
    'perfume',
    'periscope',
    'permanent-job',
    'perspective',
    'pexels',
    'phone-off-01',
    'phone-off-02',
    'php',
    'physics',
    'pi',
    'pi-circle',
    'pi-square',
    'picasa',
    'pickup-01',
    'pickup-02',
    'picture-in-picture-exit',
    'picture-in-picture-on',
    'pie',
    'pie-chart',
    'pie-chart-square',
    'piggy-bank',
    'pin',
    'pin-code',
    'pin-location-01',
    'pin-location-02',
    'pin-location-03',
    'pin-off',
    'pinterest',
    'pipeline',
    'pisa-tower',
    'pizza-01',
    'pizza-02',
    'pizza-cutter',
    'plane',
    'plant-01',
    'plant-02',
    'plant-03',
    'plant-04',
    'plate',
    'plaxo',
    'play',
    'play-circle',
    'play-circle-02',
    'play-list',
    'play-list-add',
    'play-list-favourite-01',
    'play-list-favourite-02',
    'play-list-minus',
    'play-list-remove',
    'play-square',
    'play-store',
    'playlist-01',
    'playlist-02',
    'playlist-03',
    'plaza',
    'plug-01',
    'plug-02',
    'plug-socket',
    'plus-minus',
    'plus-minus-01',
    'plus-minus-02',
    'plus-minus-circle-01',
    'plus-minus-circle-02',
    'plus-minus-square-01',
    'plus-minus-square-02',
    'plus-sign',
    'plus-sign-circle',
    'plus-sign-square',
    'png-01',
    'png-02',
    'podium',
    'pointing-left-01',
    'pointing-left-02',
    'pointing-left-03',
    'pointing-left-04',
    'pointing-left-05',
    'pointing-left-06',
    'pointing-left-07',
    'pointing-left-08',
    'pointing-right-01',
    'pointing-right-02',
    'pointing-right-03',
    'pointing-right-04',
    'pointing-right-05',
    'pointing-right-06',
    'pointing-right-07',
    'pointing-right-08',
    'pokeball',
    'pokemon',
    'police-badge',
    'police-cap',
    'police-car',
    'police-station',
    'policy',
    'polygon',
    'pool',
    'pool-table',
    'poop',
    'popcorn',
    'pot-01',
    'pot-02',
    'potion',
    'pound',
    'pound-circle',
    'pound-receive',
    'pound-send',
    'pound-square',
    'power-service',
    'power-socket-01',
    'power-socket-02',
    'ppt-01',
    'ppt-02',
    'prawn',
    'prayer-rug-01',
    'prayer-rug-02',
    'preference-horizontal',
    'preference-vertical',
    'prescription',
    'presentation-01',
    'presentation-02',
    'presentation-03',
    'presentation-04',
    'presentation-05',
    'presentation-06',
    'presentation-07',
    'presentation-bar-chart-01',
    'presentation-bar-chart-02',
    'presentation-line-chart-01',
    'presentation-line-chart-02',
    'presentation-online',
    'presentation-podium',
    'previous',
    'printer',
    'printer-off',
    'prism',
    'prism-01',
    'prison',
    'prison-guard',
    'prisoner',
    'product-loading',
    'profile',
    'profile-02',
    'profit',
    'programming-flag',
    'projector',
    'projector-01',
    'promotion',
    'property-add',
    'property-delete',
    'property-edit',
    'property-new',
    'property-search',
    'property-view',
    'protection-mask',
    'pulley',
    'pulse-01',
    'pulse-02',
    'pulse-rectangle-01',
    'pulse-rectangle-02',
    'pumpkin',
    'punch',
    'punching-ball-01',
    'punching-ball-02',
    'purse',
    'purse-01',
    'push-up-bar',
    'puzzle',
    'pyramid',
    'pyramid-maslowo',
    'pyramid-structure-01',
    'pyramid-structure-02',
    'qq-plot',
    'qr-code',
    'qr-code-01',
    'question',
    'queue-01',
    'queue-02',
    'quill-write-01',
    'quill-write-02',
    'quiz-01',
    'quiz-02',
    'quiz-03',
    'quiz-04',
    'quiz-05',
    'quora',
    'quote-down',
    'quote-down-circle',
    'quote-down-square',
    'quote-up',
    'quote-up-circle',
    'quote-up-square',
    'quotes',
    'quran-01',
    'quran-02',
    'quran-03',
    'racing-flag',
    'radar-01',
    'radar-02',
    'radial',
    'radio',
    'radio-01',
    'radio-02',
    'radioactive-alert',
    'radius',
    'rain',
    'rain-double-drop',
    'rain-drop',
    'rainbow',
    'ramadhan-01',
    'ramadhan-02',
    'ramadhan-month',
    'ranking',
    'raw-01',
    'raw-02',
    're',
    'react',
    'real-estate-01',
    'real-estate-02',
    'record',
    'recovery-mail',
    'rectangular',
    'rectangular-01',
    'recycle-01',
    'recycle-02',
    'recycle-03',
    'reddit',
    'reflex',
    'refresh',
    'refrigerator',
    'register',
    'registered',
    'relieved-01',
    'relieved-02',
    'reload',
    'reminder',
    'remote-control',
    'remove-01',
    'remove-02',
    'remove-circle',
    'remove-circle-half-dot',
    'remove-pi',
    'remove-square',
    'renewable-energy',
    'renewable-energy-01',
    'repair',
    'repeat',
    'repeat-off',
    'repeat-one-01',
    'repeat-one-02',
    'replay',
    'repository',
    'reset-password',
    'resize-01',
    'resize-02',
    'resources-add',
    'resources-remove',
    'restaurant-01',
    'restaurant-02',
    'restaurant-03',
    'restaurant-table',
    'restore-bin',
    'return-request',
    'reverse-withdrawal-01',
    'reverse-withdrawal-02',
    'rhombus',
    'rhombus-01',
    'rice-bowl-01',
    'rice-bowl-02',
    'right-angle',
    'right-to-left-block-quote',
    'right-to-left-list-bullet',
    'right-to-left-list-dash',
    'right-to-left-list-number',
    'right-to-left-list-triangle',
    'right-triangle',
    'ripple',
    'riyal',
    'riyal-rectangle',
    'road',
    'road-01',
    'road-02',
    'road-location-01',
    'road-location-02',
    'road-wayside',
    'robotic',
    'rocket',
    'rocket-01',
    'rocket-02',
    'rocking-chair',
    'roller-skate',
    'rolling-pin',
    'root-01',
    'root-02',
    'root-circle',
    'root-first-bracket',
    'root-second-bracket',
    'root-third-bracket',
    'rotate-01',
    'rotate-02',
    'rotate-360',
    'rotate-bottom-left',
    'rotate-bottom-right',
    'rotate-clockwise',
    'rotate-crop',
    'rotate-left-01',
    'rotate-left-02',
    'rotate-left-03',
    'rotate-left-04',
    'rotate-left-05',
    'rotate-left-06',
    'rotate-right-01',
    'rotate-right-02',
    'rotate-right-03',
    'rotate-right-04',
    'rotate-right-05',
    'rotate-right-06',
    'rotate-square',
    'rotate-top-left',
    'rotate-top-right',
    'route-01',
    'route-02',
    'route-03',
    'route-block',
    'router',
    'router-01',
    'router-02',
    'row-delete',
    'row-insert',
    'rss',
    'rss-connected-01',
    'rss-connected-02',
    'rss-error',
    'rss-locked',
    'rss-unlocked',
    'rub-el-hizb',
    'ruku',
    'ruler',
    'running-shoes',
    'sad-01',
    'sad-02',
    'sad-dizzy',
    'safari',
    'safe',
    'safe-delivery-01',
    'safe-delivery-02',
    'safety-pin-01',
    'safety-pin-02',
    'sailboat-coastal',
    'sailboat-offshore',
    'sakura',
    'salah',
    'salah-time',
    'sale-tag-01',
    'sale-tag-02',
    'sandals',
    'satellite',
    'satellite-01',
    'satellite-02',
    'satellite-03',
    'saturn',
    'saturn-01',
    'saturn-02',
    'sausage',
    'save-energy-01',
    'save-energy-02',
    'save-money-dollar',
    'save-money-euro',
    'save-money-pound',
    'save-money-yen',
    'savings',
    'scheme',
    'school',
    'school-01',
    'school-bell-01',
    'school-bell-02',
    'school-bus',
    'school-report-card',
    'school-tie',
    'scissor',
    'scissor-01',
    'scissor-rectangle',
    'scooter-01',
    'scooter-02',
    'scooter-03',
    'scooter-04',
    'scooter-electric',
    'scratch-card',
    'screen-add-to-home',
    'screen-lock-rotation',
    'screen-rotation',
    'scribd',
    'scroll',
    'scroll-horizontal',
    'scroll-vertical',
    'sd-card',
    'seal',
    'search-01',
    'search-02',
    'search-add',
    'search-area',
    'search-circle',
    'search-dollar',
    'search-focus',
    'search-list-01',
    'search-list-02',
    'search-minus',
    'search-remove',
    'search-replace',
    'search-square',
    'search-visual',
    'searching',
    'seat-selector',
    'second-bracket',
    'second-bracket-circle',
    'second-bracket-square',
    'secured-network',
    'security',
    'security-block',
    'security-check',
    'security-key-usb',
    'security-lock',
    'security-password',
    'security-validation',
    'security-wifi',
    'segment',
    'select-01',
    'select-02',
    'send-to-mobile',
    'senseless',
    'sent',
    'seo',
    'service',
    'setting-06',
    'setting-07',
    'setting-done-01',
    'setting-done-02',
    'setting-done-03',
    'setting-done-04',
    'setting-error-03',
    'setting-error-04',
    'settings-01',
    'settings-02',
    'settings-03',
    'settings-04',
    'settings-05',
    'settings-error-01',
    'settings-error-02',
    'setup-01',
    'setup-02',
    'shaka-01',
    'shaka-02',
    'shaka-03',
    'shaka-04',
    'shampoo',
    'shapes',
    'share-01',
    'share-02',
    'share-03',
    'share-04',
    'share-05',
    'share-06',
    'share-07',
    'share-08',
    'share-knowledge',
    'share-location-01',
    'share-location-02',
    'shared-wifi',
    'sharing',
    'shellfish',
    'sheriff-01',
    'sheriff-02',
    'shield-01',
    'shield-02',
    'shield-blockchain',
    'shield-energy',
    'shield-key',
    'shield-user',
    'shipment-tracking',
    'shipping-center',
    'shipping-loading',
    'shipping-truck-01',
    'shipping-truck-02',
    'shirt-01',
    'shocked',
    'shop-sign',
    'shopping-bag-01',
    'shopping-bag-02',
    'shopping-bag-03',
    'shopping-bag-add',
    'shopping-bag-check',
    'shopping-bag-favorite',
    'shopping-bag-remove',
    'shopping-basket-01',
    'shopping-basket-02',
    'shopping-basket-03',
    'shopping-basket-add-01',
    'shopping-basket-add-02',
    'shopping-basket-add-03',
    'shopping-basket-check-in-01',
    'shopping-basket-check-in-02',
    'shopping-basket-check-in-03',
    'shopping-basket-check-out-01',
    'shopping-basket-check-out-02',
    'shopping-basket-check-out-03',
    'shopping-basket-done-01',
    'shopping-basket-done-02',
    'shopping-basket-done-03',
    'shopping-basket-favorite-01',
    'shopping-basket-favorite-02',
    'shopping-basket-favorite-03',
    'shopping-basket-remove-01',
    'shopping-basket-remove-02',
    'shopping-basket-remove-03',
    'shopping-basket-secure-01',
    'shopping-basket-secure-02',
    'shopping-basket-secure-03',
    'shopping-cart-01',
    'shopping-cart-02',
    'shopping-cart-add-01',
    'shopping-cart-add-02',
    'shopping-cart-check-01',
    'shopping-cart-check-02',
    'shopping-cart-check-in-01',
    'shopping-cart-check-in-02',
    'shopping-cart-check-out-01',
    'shopping-cart-check-out-02',
    'shopping-cart-favorite-01',
    'shopping-cart-favorite-02',
    'shopping-cart-remove-01',
    'shopping-cart-remove-02',
    'shorts-pants',
    'shuffle',
    'shuffle-square',
    'shutterstock',
    'sidebar-bottom',
    'sidebar-left',
    'sidebar-left-01',
    'sidebar-right',
    'sidebar-right-01',
    'sidebar-top',
    'sign-language-c',
    'signal',
    'signal-full-01',
    'signal-full-02',
    'signal-low-01',
    'signal-low-02',
    'signal-low-medium',
    'signal-medium-01',
    'signal-medium-02',
    'signal-no-01',
    'signal-no-02',
    'signature',
    'silence',
    'simcard-01',
    'simcard-02',
    'simcard-dual',
    'sin',
    'sine-01',
    'sine-02',
    'sing-left',
    'sing-right',
    'sink-01',
    'sink-02',
    'siri',
    'sketch',
    'skew',
    'ski-dice-faces-01',
    'skipping-rope',
    'skull',
    'skype',
    'slack',
    'sleeping',
    'sleeveless',
    'sliders-horizontal',
    'sliders-vertical',
    'slideshare',
    'slow-winds',
    'smart',
    'smart-ac',
    'smart-phone-01',
    'smart-phone-02',
    'smart-phone-03',
    'smart-phone-04',
    'smart-phone-landscape',
    'smart-watch-01',
    'smart-watch-02',
    'smart-watch-03',
    'smart-watch-04',
    'smartphone-lost-wifi',
    'smartphone-wifi',
    'smile',
    'smile-dizzy',
    'sms-code',
    'snail',
    'snapchat',
    'snow',
    'soda-can',
    'sofa-01',
    'sofa-02',
    'sofa-03',
    'sofa-single',
    'soft-drink-01',
    'soft-drink-02',
    'software',
    'software-license',
    'software-uninstall',
    'soil-moisture-field',
    'soil-moisture-global',
    'soil-temperature-field',
    'soil-temperature-global',
    'solar-energy',
    'solar-panel-01',
    'solar-panel-02',
    'solar-panel-03',
    'solar-panel-04',
    'solar-panel-05',
    'solar-power',
    'solar-system',
    'solar-system-01',
    'solid-line-01',
    'solid-line-02',
    'sort-by-down-01',
    'sort-by-down-02',
    'sort-by-up-01',
    'sort-by-up-02',
    'sorting-01',
    'sorting-02',
    'sorting-03',
    'sorting-04',
    'sorting-05',
    'sorting-1-9',
    'sorting-9-1',
    'sorting-a-z-01',
    'sorting-a-z-02',
    'sorting-down',
    'sorting-up',
    'sorting-z-a-01',
    'soundcloud',
    'source-code',
    'source-code-circle',
    'source-code-square',
    'spaceship',
    'spades',
    'spaghetti',
    'spam',
    'sparkles',
    'spartan-helmet',
    'spatula',
    'speaker',
    'speaker-01',
    'speech-to-text',
    'speed-train-01',
    'speed-train-02',
    'sperm',
    'sphere',
    'spirals',
    'spoon',
    'spoon-and-fork',
    'spoon-and-knife',
    'spotify',
    'sql',
    'square',
    'square-01',
    'square-arrow-data-transfer-diagonal',
    'square-arrow-data-transfer-horizontal',
    'square-arrow-data-transfer-vertical',
    'square-arrow-diagonal-01',
    'square-arrow-diagonal-02',
    'square-arrow-down-01',
    'square-arrow-down-02',
    'square-arrow-down-03',
    'square-arrow-down-double',
    'square-arrow-down-left',
    'square-arrow-down-right',
    'square-arrow-expand-01',
    'square-arrow-expand-02',
    'square-arrow-horizontal',
    'square-arrow-left-01',
    'square-arrow-left-02',
    'square-arrow-left-03',
    'square-arrow-left-double',
    'square-arrow-left-right',
    'square-arrow-move-down-left',
    'square-arrow-move-down-right',
    'square-arrow-move-left-down',
    'square-arrow-move-left-up',
    'square-arrow-move-right-down',
    'square-arrow-move-right-up',
    'square-arrow-reload-01',
    'square-arrow-reload-02',
    'square-arrow-right-01',
    'square-arrow-right-02',
    'square-arrow-right-03',
    'square-arrow-right-double',
    'square-arrow-shrink-01',
    'square-arrow-shrink-02',
    'square-arrow-up-01',
    'square-arrow-up-02',
    'square-arrow-up-03',
    'square-arrow-up-double',
    'square-arrow-up-down',
    'square-arrow-up-left',
    'square-arrow-up-right',
    'square-arrow-vertical',
    'square-circle',
    'square-lock-01',
    'square-lock-02',
    'square-lock-add-01',
    'square-lock-add-02',
    'square-lock-check-01',
    'square-lock-check-02',
    'square-lock-minus-01',
    'square-lock-minus-02',
    'square-lock-password',
    'square-lock-remove-01',
    'square-lock-remove-02',
    'square-root-square',
    'square-square',
    'square-unlock-01',
    'square-unlock-02',
    'stack-star',
    'stake',
    'stamp',
    'stamp-01',
    'stamp-02',
    'star',
    'star-circle',
    'star-face',
    'star-half',
    'star-off',
    'star-square',
    'stars',
    'start-up-01',
    'start-up-02',
    'stationery',
    'steak',
    'steering',
    'step-into',
    'step-out',
    'step-over',
    'stethoscope',
    'stethoscope-02',
    'sticky-note-01',
    'sticky-note-02',
    'stop',
    'stop-watch',
    'store-01',
    'store-02',
    'store-03',
    'store-04',
    'store-add-01',
    'store-add-02',
    'store-location-01',
    'store-location-02',
    'store-remove-01',
    'store-remove-02',
    'store-verified-01',
    'store-verified-02',
    'straight-edge',
    'strategy',
    'stroke-bottom',
    'stroke-center',
    'stroke-inside',
    'stroke-left',
    'stroke-outside',
    'stroke-right',
    'stroke-top',
    'structure-01',
    'structure-02',
    'structure-03',
    'structure-04',
    'structure-05',
    'structure-add',
    'structure-check',
    'structure-fail',
    'structure-folder',
    'structure-folder-circle',
    'student',
    'student-card',
    'students',
    'study-desk',
    'study-lamp',
    'stumbleupon',
    'submarine',
    'submerge',
    'subnode-add',
    'subnode-delete',
    'subpoena',
    'subtitle',
    'suit-01',
    'suit-02',
    'sujood',
    'summation-01',
    'summation-02',
    'summation-circle',
    'summation-square',
    'sun-01',
    'sun-02',
    'sun-03',
    'sun-cloud-01',
    'sun-cloud-02',
    'sun-cloud-angled-rain-01',
    'sun-cloud-angled-rain-02',
    'sun-cloud-angled-rain-zap-01',
    'sun-cloud-angled-rain-zap-02',
    'sun-cloud-angled-zap-01',
    'sun-cloud-angled-zap-02',
    'sun-cloud-big-rain-01',
    'sun-cloud-big-rain-02',
    'sun-cloud-fast-wind-01',
    'sun-cloud-fast-wind-02',
    'sun-cloud-hailstone-01',
    'sun-cloud-hailstone-02',
    'sun-cloud-little-rain-01',
    'sun-cloud-little-rain-02',
    'sun-cloud-little-snow-01',
    'sun-cloud-little-snow-02',
    'sun-cloud-mid-rain-01',
    'sun-cloud-mid-rain-02',
    'sun-cloud-mid-snow-01',
    'sun-cloud-mid-snow-02',
    'sun-cloud-slow-wind-01',
    'sun-cloud-slow-wind-02',
    'sun-cloud-snow-01',
    'sun-cloud-snow-02',
    'sunglasses',
    'sunrise',
    'sunset',
    'super-mario',
    'super-mario-toad',
    'surfboard',
    'surprise',
    'sushi-01',
    'sushi-02',
    'sushi-03',
    'suspicious',
    'sustainable-energy',
    'svg-01',
    'svg-02',
    'swarm',
    'swatch',
    'swimming',
    'swimming-cap',
    'swipe-down-01',
    'swipe-down-02',
    'swipe-down-03',
    'swipe-down-04',
    'swipe-down-05',
    'swipe-down-06',
    'swipe-down-07',
    'swipe-down-08',
    'swipe-left-01',
    'swipe-left-02',
    'swipe-left-03',
    'swipe-left-04',
    'swipe-left-05',
    'swipe-left-06',
    'swipe-left-07',
    'swipe-left-08',
    'swipe-left-09',
    'swipe-right-01',
    'swipe-right-02',
    'swipe-right-03',
    'swipe-right-04',
    'swipe-right-05',
    'swipe-right-06',
    'swipe-right-07',
    'swipe-right-08',
    'swipe-right-09',
    'swipe-up-01',
    'swipe-up-02',
    'swipe-up-03',
    'swipe-up-04',
    'swipe-up-05',
    'swipe-up-06',
    'swipe-up-07',
    'swipe-up-08',
    'sword-01',
    'sword-02',
    'sword-03',
    'system-update-01',
    'system-update-02',
    't-shirt',
    'table',
    'table-01',
    'table-02',
    'table-03',
    'table-lamp-01',
    'table-lamp-02',
    'table-round',
    'table-tennis-bat',
    'tablet-01',
    'tablet-02',
    'tablet-connected-bluetooth',
    'tablet-connected-usb',
    'tablet-connected-wifi',
    'tablet-pen',
    'taco-01',
    'taco-02',
    'tag-01',
    'tag-02',
    'tags',
    'taj-mahal',
    'tan',
    'tank-top',
    'tap-01',
    'tap-02',
    'tap-03',
    'tap-04',
    'tap-05',
    'tap-06',
    'tap-07',
    'tap-08',
    'tape-measure',
    'target-01',
    'target-02',
    'target-03',
    'target-dollar',
    'tasbih',
    'task-01',
    'task-02',
    'task-add-01',
    'task-add-02',
    'task-daily-01',
    'task-daily-02',
    'task-done-01',
    'task-done-02',
    'task-edit-01',
    'task-edit-02',
    'task-remove-01',
    'task-remove-02',
    'taxes',
    'taxi',
    'tea',
    'tea-pod',
    'teacher',
    'teaching',
    'teamviewer',
    'telegram',
    'telephone',
    'telescope-01',
    'telescope-02',
    'television-table',
    'temperature',
    'tennis-ball',
    'terrace',
    'test-tube',
    'test-tube-01',
    'test-tube-02',
    'test-tube-03',
    'tetris',
    'text',
    'text-align-center',
    'text-align-justify-center',
    'text-align-justify-left',
    'text-align-justify-right',
    'text-align-left',
    'text-align-left-01',
    'text-align-right',
    'text-align-right-01',
    'text-all-caps',
    'text-bold',
    'text-centerline-center-top',
    'text-centerline-left',
    'text-centerline-middle',
    'text-centerline-right',
    'text-check',
    'text-circle',
    'text-clear',
    'text-color',
    'text-creation',
    'text-firstline-left',
    'text-firstline-right',
    'text-font',
    'text-footnote',
    'text-indent',
    'text-indent-01',
    'text-indent-less',
    'text-indent-more',
    'text-italic',
    'text-italic-slash',
    'text-kerning',
    'text-number-sign',
    'text-selection',
    'text-smallcaps',
    'text-square',
    'text-strikethrough',
    'text-subscript',
    'text-superscript',
    'text-tracking',
    'text-underline',
    'text-variable-front',
    'text-vertical-alignment',
    'text-wrap',
    'the-prophets-mosque',
    'thermometer',
    'thermometer-cold',
    'thermometer-warm',
    'third-bracket',
    'third-bracket-circle',
    'third-bracket-square',
    'thread',
    'threads',
    'threads-ellipse',
    'threads-rectangle',
    'three-d-move',
    'three-d-rotate',
    'three-d-scale',
    'three-d-view',
    'three-finger-01',
    'three-finger-02',
    'three-finger-03',
    'three-finger-04',
    'three-finger-05',
    'thumbs-down',
    'thumbs-down-ellipse',
    'thumbs-down-rectangle',
    'thumbs-up',
    'thumbs-up-down',
    'thumbs-up-ellipse',
    'thumbs-up-rectangle',
    'tic-tac-toe',
    'tick-01',
    'tick-02',
    'tick-03',
    'tick-04',
    'tick-double-01',
    'tick-double-02',
    'tick-double-03',
    'tick-double-04',
    'ticket-01',
    'ticket-02',
    'ticket-03',
    'ticket-star',
    'tie',
    'tiktok',
    'time-01',
    'time-02',
    'time-03',
    'time-04',
    'time-half-pass',
    'time-management',
    'time-management-circle',
    'time-quarter',
    'time-quarter-pass',
    'time-schedule',
    'time-setting-01',
    'time-setting-02',
    'time-setting-03',
    'timer-01',
    'timer-02',
    'tips',
    'tired-01',
    'tired-02',
    'tissue-paper',
    'toggle-off',
    'toggle-on',
    'toilet-01',
    'toilet-02',
    'tongue',
    'tongue-01',
    'tongue-wink-left',
    'tongue-wink-right',
    'tools',
    'tornado-01',
    'tornado-02',
    'torri-gate',
    'touch-01',
    'touch-02',
    'touch-03',
    'touch-04',
    'touch-05',
    'touch-06',
    'touch-07',
    'touch-08',
    'touch-09',
    'touch-10',
    'touch-interaction-01',
    'touch-interaction-02',
    'touch-interaction-03',
    'touch-interaction-04',
    'touch-locked-01',
    'touch-locked-02',
    'touch-locked-03',
    'touch-locked-04',
    'touchpad-01',
    'touchpad-02',
    'touchpad-03',
    'touchpad-04',
    'tow-truck',
    'towels',
    'toy-train',
    'tractor',
    'trade-down',
    'trade-mark',
    'trade-up',
    'trademark',
    'traffic-incident',
    'traffic-light',
    'train-01',
    'train-02',
    'tram',
    'trampoline',
    'transaction',
    'transition-bottom',
    'transition-left',
    'transition-right',
    'transition-top',
    'translate',
    'translation',
    'transmission',
    'transparency',
    'trapezoid-line-horizontal',
    'trapezoid-line-vertical',
    'travel-bag',
    'treadmill-01',
    'treadmill-02',
    'treatment',
    'tree-01',
    'tree-02',
    'tree-03',
    'tree-04',
    'tree-05',
    'tree-06',
    'tree-07',
    'trello',
    'triangle',
    'triangle-01',
    'triangle-02',
    'triangle-03',
    'trolley-01',
    'trolley-02',
    'tropical-storm',
    'tropical-storm-tracks-01',
    'tropical-storm-tracks-02',
    'truck',
    'truck-delivery',
    'truck-monster',
    'truck-return',
    'trulli',
    'tsunami',
    'tulip',
    'tumblr',
    'turtle-neck',
    'tv-01',
    'tv-02',
    'tv-fix',
    'tv-issue',
    'tv-smart',
    'twin-tower',
    'twitch',
    'twitter',
    'twitter-square',
    'two-factor-access',
    'two-finger-01',
    'two-finger-02',
    'two-finger-03',
    'two-finger-04',
    'two-finger-05',
    'txt-01',
    'txt-02',
    'type-cursor',
    'typescript-01',
    'typescript-02',
    'typescript-03',
    'uber',
    'ufo',
    'ufo-01',
    'umbrella',
    'umbrella-dollar',
    'unamused',
    'unavailable',
    'underpants-01',
    'underpants-02',
    'underpants-03',
    'unfold-less',
    'unfold-more',
    'ungroup-items',
    'ungroup-layers',
    'unhappy',
    'university',
    'unlink-01',
    'unlink-02',
    'unlink-03',
    'unlink-04',
    'unlink-05',
    'unlink-06',
    'unsplash',
    'upload-01',
    'upload-02',
    'upload-03',
    'upload-04',
    'upload-05',
    'upload-circle-01',
    'upload-circle-02',
    'upload-square-01',
    'upload-square-02',
    'upwork',
    'usb',
    'usb-bugs',
    'usb-connected-01',
    'usb-connected-02',
    'usb-connected-03',
    'usb-error',
    'usb-memory-01',
    'usb-memory-02',
    'usb-not-connected-01',
    'usb-not-connected-02',
    'user',
    'user-account',
    'user-add-01',
    'user-add-02',
    'user-arrow-left-right',
    'user-block-01',
    'user-block-02',
    'user-check-01',
    'user-check-02',
    'user-circle',
    'user-circle-02',
    'user-edit-01',
    'user-full-view',
    'user-group',
    'user-id-verification',
    'user-list',
    'user-lock-01',
    'user-lock-02',
    'user-love-01',
    'user-love-02',
    'user-minus-01',
    'user-minus-02',
    'user-multiple',
    'user-multiple-02',
    'user-question-01',
    'user-question-02',
    'user-remove-01',
    'user-remove-02',
    'user-roadside',
    'user-search-01',
    'user-search-02',
    'user-settings-01',
    'user-settings-02',
    'user-sharing',
    'user-shield-01',
    'user-shield-02',
    'user-square',
    'user-star-01',
    'user-star-02',
    'user-status',
    'user-story',
    'user-switch',
    'user-unlock-01',
    'user-unlock-02',
    'uv-01',
    'uv-02',
    'uv-03',
    'vaccine',
    'validation',
    'validation-approval',
    'van',
    'variable',
    'vegetarian-food',
    'vertical-resize',
    'vertical-scroll-point',
    'vest',
    'victory-finger-01',
    'victory-finger-02',
    'victory-finger-03',
    'video-01',
    'video-02',
    'video-console',
    'video-off',
    'video-replay',
    'view',
    'view-off',
    'view-off-slash',
    'vimeo',
    'vine',
    'vine-square',
    'vintage-clock',
    'virtual-reality-vr-01',
    'virtual-reality-vr-02',
    'vision',
    'vk',
    'vk-square',
    'voice',
    'voice-id',
    'volleyball',
    'volume-high',
    'volume-low',
    'volume-minus',
    'volume-mute-01',
    'volume-mute-02',
    'volume-off',
    'volume-up',
    'vomiting',
    'voucher',
    'vr',
    'vr-glasses',
    'vynil-01',
    'vynil-02',
    'vynil-03',
    'waiter',
    'waiters',
    'wall-lamp',
    'wallet-01',
    'wallet-02',
    'wallet-03',
    'wallet-add-01',
    'wallet-add-02',
    'wallet-done-01',
    'wallet-done-02',
    'wallet-not-found-01',
    'wallet-not-found-02',
    'wallet-remove-01',
    'wallet-remove-02',
    'wanted',
    'wardrobe-01',
    'wardrobe-02',
    'wardrobe-03',
    'wardrobe-04',
    'warehouse',
    'washington-monument',
    'waste',
    'waste-restore',
    'watch-01',
    'watch-02',
    'water-energy',
    'water-polo',
    'water-pump',
    'waterfall-down-01',
    'waterfall-down-03',
    'waterfall-up-01',
    'waterfall-up-02',
    'watermelon',
    'wattpad',
    'wattpad-square',
    'waving-hand-01',
    'waving-hand-02',
    'waze',
    'web-design-01',
    'web-design-02',
    'web-programming',
    'web-protection',
    'web-security',
    'web-validation',
    'webflow',
    'wechat',
    'weight-scale',
    'weight-scale-01',
    'wellness',
    'whatsapp',
    'whatsapp-business',
    'wheelchair',
    'whisk',
    'whistle',
    'wifi-01',
    'wifi-02',
    'wifi-circle',
    'wifi-connected-01',
    'wifi-connected-02',
    'wifi-connected-03',
    'wifi-disconnected-01',
    'wifi-disconnected-02',
    'wifi-disconnected-03',
    'wifi-disconnected-04',
    'wifi-error-01',
    'wifi-error-02',
    'wifi-full-signal',
    'wifi-location',
    'wifi-lock',
    'wifi-low-signal',
    'wifi-medium-signal',
    'wifi-no-signal',
    'wifi-off-01',
    'wifi-off-02',
    'wifi-square',
    'wifi-unlock',
    'wikipedia',
    'wind-power',
    'wind-power-01',
    'wind-power-02',
    'wind-power-03',
    'wind-surf',
    'wind-turbine',
    'wink',
    'winking',
    'wireless',
    'wireless-cloud-access',
    'wise',
    'wordpress',
    'work-alert',
    'work-history',
    'work-update',
    'workflow-circle-01',
    'workflow-circle-02',
    'workflow-circle-03',
    'workflow-circle-04',
    'workflow-circle-05',
    'workflow-circle-06',
    'workflow-square-01',
    'workflow-square-02',
    'workflow-square-03',
    'workflow-square-04',
    'workflow-square-05',
    'workflow-square-06',
    'workflow-square-07',
    'workflow-square-08',
    'workflow-square-09',
    'workflow-square-10',
    'workout-battle-ropes',
    'workout-gymnastics',
    'workout-kicking',
    'workout-run',
    'workout-sport',
    'workout-squats',
    'workout-stretching',
    'workout-warm-up',
    'worry',
    'wps-office',
    'wps-office-rectangle',
    'wrench-01',
    'wrench-02',
    'wudu',
    'x-ray',
    'x-variable',
    'x-variable-circle',
    'x-variable-square',
    'xing',
    'xls-01',
    'xls-02',
    'xml-01',
    'xml-02',
    'xsl-01',
    'xsl-02',
    'yelp',
    'yen',
    'yen-circle',
    'yen-receive',
    'yen-send',
    'yen-square',
    'yoga-01',
    'yoga-02',
    'yoga-03',
    'yoga-ball',
    'yoga-mat',
    'yogurt',
    'youtube',
    'yurt',
    'zakat',
    'zap',
    'zeppelin',
    'zip-01',
    'zip-02',
    'zoom',
    'zoom-circle',
    'zoom-in-area',
    'zoom-out-area',
    'zoom-square',
] as const
