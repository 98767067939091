import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useMainNavigationState() {
    const {
        workspaceNavState,
        mainNavState,
        selectedApp,
        isPortal,
        showTrialBanner,
        permissions,
        isMobileDevice,
        showNavigationStateControl,
    } = useNavigationContext()

    const isNavHidden =
        mainNavState === 'collapsed' ||
        mainNavState === 'touch-collapsed' ||
        (!selectedApp && !isPortal)

    const isWorkspaceNavHidden = workspaceNavState === 'hidden'

    const showHelpAndSupport = permissions.canViewHelpAndSupport

    return useMemo(
        () => ({
            isNavHidden,
            isPortal,
            showTrialBanner,
            showHelpAndSupport,
            isWorkspaceNavHidden,
            isMobileDevice,
            showNavigationStateControl,
        }),
        [
            isNavHidden,
            isPortal,
            showHelpAndSupport,
            showTrialBanner,
            isWorkspaceNavHidden,
            isMobileDevice,
            showNavigationStateControl,
        ]
    )
}
