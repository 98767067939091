type DataConnectionsGroup = {
    key: string
    type: string
    nango_connection_id?: string
    dataConnections: DataConnectionDto[]
}
export const groupDataConnectionsByTypeAndNangoConnectionId = (
    dataConnections: DataConnectionDto[] | undefined
): DataConnectionsGroup[] => {
    const groups: Record<string, DataConnectionsGroup> = {}

    dataConnections?.forEach((connection) => {
        const key = `${connection.type}__${connection.nango_connection_id}`

        if (!groups[key]) {
            groups[key] = {
                key,
                type: connection.type,
                nango_connection_id: connection.nango_connection_id,
                dataConnections: [],
            }
        }

        groups[key].dataConnections.push(connection)
    })

    const groupsArray = Object.values(groups)
    // sort the returned list by key of each group so that the order of data connections returned is stable
    groupsArray.sort((a: DataConnectionsGroup, b: DataConnectionsGroup) =>
        a.key.localeCompare(b.key)
    )
    return groupsArray
}
