import { useCallback, useMemo, useRef } from 'react'

import {
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import {
    generateWidgetId,
    getYWidgetAtPath,
    insertYWidgetAtPath,
    moveWidgetToPath,
} from 'features/views/LayoutEditor/utils'

export function useLayoutEditorDnDContextState() {
    const { commands, selectedWidget, schema } = useLayoutEditorContext()

    const selectedWidgetSchema = useMemo(() => {
        if (!selectedWidget) return undefined

        return schema.widgets[selectedWidget.type]
    }, [schema.widgets, selectedWidget])

    const wrapperRef = useRef<HTMLDivElement>(null)

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                // This lets us select widgets on click, but still keeps the dragging functionality working in the same way.
                distance: 5,
            },
        })
    )

    const modifiers = useMemo(() => [snapCenterToCursor], [])

    const initialDragActive = useRef<
        | { id: string; path: string[]; idx: number; needsCreation: boolean; widgetType: string }
        | undefined
    >()
    const destinationDragActive = useRef<{ id: string; path: string[]; idx: number } | undefined>()

    const onDragStart = useCallback((event: DragStartEvent) => {
        initialDragActive.current = {
            id: event.active.id as string,
            path: event.active.data.current?.path ?? [],
            idx: event.active.data.current?.sortable?.index ?? 0,
            widgetType: event.active.data.current?.widgetType ?? '',
            needsCreation: false,
        }

        // If the drag is from the widget picker, we need to generate a new widget ID.
        if (event.active.data.current?.isFromPicker) {
            initialDragActive.current.id = generateWidgetId()
            initialDragActive.current.needsCreation = true
        }

        wrapperRef.current?.style.setProperty('cursor', 'grabbing')
    }, [])

    const onDragOver = useCallback(
        (event: DragOverEvent) => {
            if (!initialDragActive.current) return

            const targetWidgetArea = getTargetWidgetArea(event)
            if (!targetWidgetArea) return

            let targetIdx = 0
            const sortable = event.over?.data.current?.sortable
            if (sortable) {
                targetIdx = sortable.index
            } else {
                targetIdx = 0
            }

            // The path of the widget being dragged.
            const prevPath =
                destinationDragActive.current?.path ?? initialDragActive.current.path ?? []

            const widgetType = initialDragActive.current.widgetType
            if (!widgetType) return

            const active = {
                id: initialDragActive.current.id,
                path: prevPath,
                type: widgetType,
            }

            // If we are dragging over the same widget area, we don't need to do anything.
            if (active.path === targetWidgetArea.path) {
                return
            }

            if (initialDragActive.current!.needsCreation) {
                commands.transaction((data) => {
                    const { widget: existingWidget } = getYWidgetAtPath(
                        active.path,
                        active.id,
                        data
                    )
                    if (existingWidget) return

                    // If we are dragging from the widget picker, we need to create a new widget that we can then move.
                    initialDragActive.current!.needsCreation = false

                    insertYWidgetAtPath(targetWidgetArea.path, schema, active, data, targetIdx)

                    // Update the selected widget, since the path might have changed.
                    commands.selectWidgetAtPath(active.id, targetWidgetArea.path)
                })
            }

            requestAnimationFrame(() => {
                commands.transaction((data) => {
                    moveWidgetToPath(active.id, active.path, targetWidgetArea.path, targetIdx, data)
                    destinationDragActive.current = {
                        id: active.id,
                        path: targetWidgetArea.path,
                        idx: targetIdx,
                    }

                    // Update the selected widget, since the path might have changed.
                    commands.selectWidgetAtPath(active.id, targetWidgetArea.path)
                })
            })
        },
        [commands, schema]
    )

    const onDragEnd = useCallback(() => {
        initialDragActive.current = undefined
        destinationDragActive.current = undefined

        wrapperRef.current?.style.removeProperty('cursor')
    }, [])

    const onDragCancel = useCallback(() => {
        wrapperRef.current?.style.removeProperty('cursor')

        const active = initialDragActive?.current
        const destination = destinationDragActive?.current
        if (!active || !destination) {
            initialDragActive.current = undefined
            destinationDragActive.current = undefined

            return
        }

        commands.transaction(
            (data) => {
                moveWidgetToPath(active.id, destination.path, active.path, active.idx, data)

                // Update the selected widget, since the path might have changed.
                commands.selectWidgetAtPath(active.id, active.path)

                initialDragActive.current = undefined
                destinationDragActive.current = undefined
            },
            {
                skipHistory: true,
            }
        )
    }, [commands])

    return useMemo(
        () => ({
            sensors,
            modifiers,
            onDragStart,
            onDragOver,
            onDragEnd,
            onDragCancel,
            selectedWidgetSchema,
            wrapperRef,
        }),
        [sensors, modifiers, onDragStart, onDragOver, onDragEnd, onDragCancel, selectedWidgetSchema]
    )
}

function getTargetWidgetArea(event: DragOverEvent | DragEndEvent) {
    const { over } = event
    if (!over || over.disabled) return undefined

    if (over.data.current?.type === 'widgetArea') {
        // We are dragging over an empty widget area.
        return {
            id: over.data.current.id,
            path: over.data.current.path,
        }
    }

    if (over.data.current) {
        const path = over.data.current.path

        // We are dragging over a widget in a widget area.
        return {
            id: path.at(-1),
            path: over.data.current.path,
        }
    }

    return undefined
}
