import React, { useMemo } from 'react'

import { useIsBannerShowing } from 'features/core/useIsBannerShowing'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import { useListHeaderFilters } from './Filters/hooks/useListHeaderFilters'
import { useListHeaderSearch } from './Search/hooks/useListHeaderSearch'
import { useListHeaderSort } from './Sort/hooks/useListHeaderSort'
import { ListHeaderContext } from './useListHeaderContext'

export type ListHeaderContextProviderProps = {
    recordCount?: number
    requestIncludedFields?: string[]
    requestFilters?: Filter[]
}

export const ListHeaderContextProvider: React.FC<ListHeaderContextProviderProps> = ({
    children,
    recordCount = 0,
    requestIncludedFields = [],
    requestFilters = [],
}) => {
    const {
        view,
        object,
        isEmbedded,
        embeddedByField,
        embeddedByFieldValue,
        allowCreate,
        allowDownload,
        isEditMode,
        stack,
        title: viewTitle,
        header,
    } = useListViewContext()

    const {
        availableFields: availableSortFields,
        setSortBy,
        sortBy,
        sortType,
    } = useListHeaderSort()

    const { query: searchQuery, setQuery: setSearchQuery, allowSearch } = useListHeaderSearch()

    const {
        inlineFilters,
        addInlineFilter,
        removeInlineFilter,
        updateInlineFilter,
        setFilters,
        clearInlineFilters,
        availableInlineFilterFields,
        inlineFilterType,
    } = useListHeaderFilters()

    const [isPreviewing] = useIsBannerShowing()

    const value = useMemo(
        () => ({
            viewTitle,
            value: header,
            object,
            stack,
            recordCount,
            sortBy,
            setSortBy,
            availableSortFields,
            sortType,
            view,
            searchQuery,
            setSearchQuery,
            allowSearch,
            inlineFilters,
            inlineFilterType,
            addInlineFilter,
            removeInlineFilter,
            updateInlineFilter,
            setFilters,
            clearInlineFilters,
            availableInlineFilterFields,
            isEmbedded,
            embeddedByField,
            embeddedByFieldValue,
            allowCreate,
            allowDownload,
            isEditMode,
            requestIncludedFields,
            requestFilters,
            isPreviewing,
        }),
        [
            viewTitle,
            header,
            object,
            stack,
            recordCount,
            sortBy,
            setSortBy,
            availableSortFields,
            sortType,
            view,
            searchQuery,
            setSearchQuery,
            allowSearch,
            inlineFilters,
            inlineFilterType,
            addInlineFilter,
            removeInlineFilter,
            updateInlineFilter,
            setFilters,
            clearInlineFilters,
            availableInlineFilterFields,
            isEmbedded,
            embeddedByField,
            embeddedByFieldValue,
            allowCreate,
            allowDownload,
            isEditMode,
            requestIncludedFields,
            requestFilters,
            isPreviewing,
        ]
    )

    return <ListHeaderContext.Provider value={value}>{children}</ListHeaderContext.Provider>
}
