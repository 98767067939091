import { useCallback, useMemo } from 'react'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useNavigationContext } from './useNavigationContext'

export function useWorkspaceNavigationState() {
    const { selectedApp, showTrialBanner, isMobileDevice } = useNavigationContext()

    const { workspaceNavState, setWorkspaceNavState } = useNavigationContext()

    const isWorkspaceNavCollapsed = workspaceNavState === 'collapsed'

    const toggleWorkspaceNavCollapsed = useCallback(() => {
        setWorkspaceNavState((prevState) => (prevState === 'collapsed' ? 'expanded' : 'collapsed'))
    }, [setWorkspaceNavState])

    const tooltipText = isWorkspaceNavCollapsed ? 'Expand navigation' : 'Collapse navigation'

    const hasAppSelected = !!selectedApp

    const showCollapseToggle = useResponsiveValue({
        mobile: false,
        tablet: workspaceNavState !== 'static',
    })

    const isNavHidden = workspaceNavState === 'touch-collapsed' || workspaceNavState === 'hidden'

    return useMemo(
        () => ({
            workspaceNavState,
            isWorkspaceNavCollapsed,
            toggleWorkspaceNavCollapsed,
            tooltipText,
            hasAppSelected,
            showCollapseToggle,
            isNavHidden,
            showTrialBanner,
            isMobileDevice,
        }),
        [
            workspaceNavState,
            isWorkspaceNavCollapsed,
            toggleWorkspaceNavCollapsed,
            tooltipText,
            hasAppSelected,
            showCollapseToggle,
            isNavHidden,
            showTrialBanner,
            isMobileDevice,
        ]
    )
}
