import React, { useMemo } from 'react'

import { NotificationsResponse, useNotifications } from 'data/hooks/activities/notifications'
import { ActivityFeedContextProvider } from 'features/Activity/ActivityFeedContextProvider'
import { TaskListContextProvider } from 'features/tasks/TaskListContextProvider'

import { LoadingScreen } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'

import { Notification } from './Notification'
import { ReplyNotification } from './ReplyNotification'
import { RelatedNotificationData } from './types'

type ActivityResponseNotifications = {
    notifications: NotificationDto[]
    targetActivityId: number
}

type NotificationListProps = { isArchived: boolean }

export const NotificationList: React.FC<NotificationListProps> = ({ isArchived }) => {
    const {
        data: notificationsResponse,
        createActivity,
        retryFailedActivity,
        markAsRead,
        markAsArchived,
        deleteActivity,
        updateActivity,
        createTask,
        updateTask,
        deleteTask,
        isLoading,
    } = useNotifications({ isArchived })
    const { notifications, ...relatedData } = notificationsResponse ?? ({} as NotificationsResponse)
    const { activities } = notificationsResponse ?? {}

    const groupedNotifications: (NotificationDto | ActivityResponseNotifications)[] =
        useMemo(() => {
            if (!notifications) return []

            const notificationsByTargetActivityId: { [key: number]: NotificationDto[] } = {}

            return notifications
                .slice(0, 30) // only load the top 30 notifications, as the UI can't handle more than that
                ?.map((notification) => {
                    if (notification.activity_id) {
                        // If this is a notification for an activity which is already grouped because
                        // there are reply activities, then put this notification into the group
                        if (notification.activity_id in notificationsByTargetActivityId) {
                            notificationsByTargetActivityId[notification.activity_id].push(
                                notification
                            )
                            return null
                        }
                        const activity = activities?.find(
                            (a) => a.auto_id === notification.activity_id
                        )
                        if (!activity) return null
                        if (!activity?.parent_activity_id) return notification

                        const rootActivityId = activity?.parent_activity_id
                        let isNewGroup = false
                        if (!notificationsByTargetActivityId[rootActivityId]) {
                            notificationsByTargetActivityId[rootActivityId] = []
                            isNewGroup = true
                        }
                        notificationsByTargetActivityId[rootActivityId].push(notification)
                        if (isNewGroup) {
                            return {
                                notifications: notificationsByTargetActivityId[rootActivityId],
                                targetActivityId: rootActivityId,
                            }
                        }
                        return null
                    }
                    return notification
                })
                .filter(Boolean) as (NotificationDto | ActivityResponseNotifications)[]
        }, [notifications, activities])

    const unreadNotifications = notifications?.filter((n) => !n.is_read) ?? []
    const hasReadGroups = !!groupedNotifications?.find(
        (group) =>
            (group as ActivityResponseNotifications).notifications?.every((n) => n.is_read) ||
            (group as NotificationDto).is_read
    )

    const markAllAsRead = () => {
        if (!unreadNotifications?.length) return

        markAsRead({ notificationIds: unreadNotifications?.map((n) => n.auto_id), isRead: true })
    }
    const archiveAll = () => {
        markAsArchived({ isArchived: true })
    }

    if (isLoading) return <LoadingScreen isLoading={true} />
    return (
        <ActivityFeedContextProvider
            value={{
                ...relatedData,
                createActivity,
                retryFailedActivity,
                deleteActivity,
                updateActivity,
            }}
        >
            <TaskListContextProvider value={{ ...relatedData, createTask, updateTask, deleteTask }}>
                {!isArchived && (
                    <>
                        <Box flex center mx="l" noShrink mt="m">
                            <Button
                                variant="secondary"
                                size="xs"
                                mr="m"
                                disabled={!unreadNotifications.length}
                                onClick={markAllAsRead}
                                startIcon={{ name: 'CheckSquare' }}
                            >
                                Mark all as read
                            </Button>
                            <Button
                                variant="secondary"
                                size="xs"
                                mr="m"
                                onClick={archiveAll}
                                disabled={!hasReadGroups}
                                startIcon={{ name: 'Archive' }}
                            >
                                Archive all read
                            </Button>
                        </Box>
                        <Divider mt="m" noShrink />
                    </>
                )}
                <Box height="m" noShrink />
                <Box
                    flex
                    column
                    stretch
                    overflowY="auto"
                    overflowX="hidden"
                    maxHeight="full"
                    // padding bottom needed so even if there are too many notifications reply box is always visible for last notification
                    style={{ paddingBottom: '50px' }}
                >
                    {groupedNotifications?.map((notification) => {
                        if ('targetActivityId' in notification) {
                            return (
                                <ReplyNotification
                                    key={notification.targetActivityId}
                                    targetActivityId={notification.targetActivityId}
                                    notifications={notification.notifications}
                                    relatedData={relatedData as RelatedNotificationData}
                                    markAsRead={markAsRead}
                                    markAsArchived={markAsArchived}
                                />
                            )
                        } else {
                            return (
                                <Notification
                                    key={notification.auto_id}
                                    notification={notification}
                                    relatedData={relatedData as RelatedNotificationData}
                                    markAsRead={markAsRead}
                                    markAsArchived={markAsArchived}
                                />
                            )
                        }
                    })}
                </Box>
            </TaskListContextProvider>
        </ActivityFeedContextProvider>
    )
}
