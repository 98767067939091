import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { usePortalLogoState } from './hooks/usePortalLogoState'

import { PortalHeaderTitleStyle, PortalLogoImageStyles } from './Navigation.css'

const NAME_MAX_LINES = 2

type PortalLogoProps = {}

export const PortalLogo: React.FC<PortalLogoProps> = () => {
    const { logoImageUrl, portalName } = usePortalLogoState()

    return <PortalLogoImage name={portalName} imageUrl={logoImageUrl} />
}

type PortalLogoImageProps = {
    name: string
    imageUrl?: string
}

const PortalLogoImage: React.FC<PortalLogoImageProps> = ({ name, imageUrl }) => {
    return (
        <Box
            className={PortalLogoImageStyles.styleFunction({
                hasImage: !!imageUrl,
            })}
            trim
        >
            {imageUrl ? (
                <Box
                    as="img"
                    height="full"
                    width="full"
                    src={imageUrl}
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'left center',
                    }}
                />
            ) : (
                <Tooltip
                    zIndex={201}
                    content={name}
                    side="bottom"
                    align="center"
                    display="inline-flex"
                    center
                    className={PortalHeaderTitleStyle}
                >
                    <Headline
                        size="2xs"
                        maxLines={NAME_MAX_LINES}
                        display="inline-block"
                        style={{
                            fontWeight: theme.fontWeights.headline,
                        }}
                    >
                        {name}
                    </Headline>
                </Tooltip>
            )}
        </Box>
    )
}
