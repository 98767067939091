import * as React from 'react'

import { useAppContext } from 'app/useAppContext'
import { useObjects } from 'data/hooks/objects'
import { useStackPages } from 'data/hooks/pages'
import { useUpdateStack } from 'data/hooks/stacks'
import { CodeEditor } from 'features/admin/settings/appearance/CodeEditor'
import getAutocompleteClasses from 'features/admin/settings/appearance/getAutocompleteClasses'
import { defaultColor } from 'features/workspace/forms/colorConstants'
import { ColorPickerForm } from 'features/workspace/forms/ColorPickerForm'
import { IconPickerForm } from 'features/workspace/forms/IconPickerForm'
import { UploadIcon } from 'features/workspace/forms/UploadIcon'
import { CollapsableSection, Divider, FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box } from 'v2/ui'

import { Body } from 'ui/components/Text'
import { Flex } from 'ui/deprecated/atoms/Flex'
import Form from 'ui/deprecated/forms/Form'
import { FormField } from 'ui/deprecated/forms/FormField'

export default function AppSettingsModalAppearanceSettings({ formRef, children }) {
    const { selectedStack } = useAppContext()
    const { mutateAsync: updateStack } = useUpdateStack()

    const { data: allObjects } = useObjects()
    const clientLogo = selectedStack.options?.data_mapping?.logo_field
    const { pages } = useStackPages()

    return (
        <>
            <Form
                onSubmit={(data) => {
                    const { custom_css, logo_field, ...theme } = data
                    const data_mapping = selectedStack.options?.data_mapping
                    if (logo_field) {
                        data_mapping['logo_field'] = logo_field.split(',')
                    } else {
                        delete data_mapping['logo_field']
                    }
                    return updateStack({
                        id: selectedStack._sid,
                        patch: {
                            options: {
                                ...selectedStack.options,
                                data_mapping,
                                custom_css,
                                theme,
                            },
                        },
                    })
                }}
                options={{
                    defaultValues: {
                        brandColor: defaultColor,
                        custom_css: selectedStack.options?.custom_css,
                        logo_field: clientLogo ? clientLogo.join(',') : null,
                        ...selectedStack.options?.theme,
                    },
                }}
                style={{ flex: 1 }}
                resetOnSuccess
            >
                <FormFrame title="Appearance" formRef={formRef}>
                    <Flex>
                        <ColorPickerForm
                            name="brandColor"
                            required
                            label="Color"
                            showCustom={selectedStack.options?.allow_custom_brand_color}
                        />
                        <IconPickerForm name="icon" label="App Icon" />
                    </Flex>
                    <UploadIcon disabled={false} isAppModal />

                    <Divider />

                    <CollapsableSection
                        header={
                            <Flex>
                                <Body mr="s" my="s" weight="regular">
                                    Custom CSS
                                </Body>
                            </Flex>
                        }
                    >
                        <Box border="1px solid #ccc" mt={1}>
                            <FormField
                                as={CodeEditor}
                                name="custom_css"
                                formRef={formRef}
                                language="css"
                                autocompleters={getAutocompleteClasses(allObjects, pages)}
                                controlled
                            />
                        </Box>
                    </CollapsableSection>
                </FormFrame>
                {children}
            </Form>
        </>
    )
}
