import React, { useCallback } from 'react'

import { useAppContext } from 'app/useAppContext'
import { invalidateDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useDeleteDataConnection } from 'data/hooks/dataConnections/useDeleteDataConnection'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'

type DeleteDataConnectionConfirmationModalProps = {
    isOpen: boolean
    onOpenChange: (open: boolean) => void
    dataConnection: DataConnectionDto
}
export const DeleteDataConnectionConfirmationModal: React.FC<
    DeleteDataConnectionConfirmationModalProps
> = ({ isOpen, onOpenChange, dataConnection }) => {
    const { selectedStack } = useAppContext()
    const toast = useToast()

    const { mutateAsync: deleteDataConnection, isLoading: isDeletingDataConnection } =
        useDeleteDataConnection({
            onError: () => {
                toast({
                    title: 'There was a problem deleting the data connection. Please try again later.',
                    type: 'error',
                })
            },
        })

    const handleDelete = useCallback(async () => {
        await deleteDataConnection(dataConnection._sid)
        invalidateDataConnections()
        onOpenChange(false)
    }, [dataConnection._sid, deleteDataConnection, onOpenChange])

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                <ModalHeader
                    subtitle={
                        <>
                            Are you sure you want to delete connection for{' '}
                            <Body weight="bold">{dataConnection.label}</Body> from the{' '}
                            <Body weight="bold">{selectedStack?.name}</Body> app?
                        </>
                    }
                    title="Delete connection"
                    showCloseButton={false}
                />
                <ModalFooter flexDirection="column">
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button size="l" variant="ghost">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button
                            size="l"
                            variant="destructive"
                            onClick={handleDelete}
                            isLoading={isDeletingDataConnection}
                        >
                            Delete connection
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
