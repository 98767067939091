import React, { useCallback, useState } from 'react'

import settings from 'app/settings'
import { useAppContext } from 'app/useAppContext'
import { useConfirmModal } from 'app/useConfirmModal'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { useUpdateStack } from 'data/hooks/stacks'
import { invalidateStacks } from 'data/hooks/stacks/stackOperations'
import useLDFlags from 'data/hooks/useLDFlags'
import { useWorkspaceZones } from 'data/hooks/zones'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { Button, Section, Text } from 'legacy/v1/ui'

import AttributeList from 'v2/ui/components/Attribute/AttributeList'
import { useToast } from 'v2/ui/components/useToast'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

// see readme in this directory
const d = (s) => JSON.parse(atob(s)).join('')
const _k = d(
    'WyJfIiwiXyIsIl8iLCJzIiwidCIsImEiLCJjIiwiayIsInMiLCJlIiwidCIsInQiLCJpIiwibiIsImciLCJzIl0='
)
const _p = d(
    'WyI2IiwibiIsIm4iLCIqIiwidSIsIjIiLCJyIiwiLiIsIjQiLCJLIiwidiIsIjgiLCJNIiwiOSIsIm4iLCJLIiwiQiIsInEiLCJXIiwiNCIsIjgiLCJZIiwiLiIsIl8iLCJUIiwiLiIsIkAiLCJKIiwiZSIsIkoiXQ=='
)
const _m = d('WyJQIiwiYSIsInMiLCJzIiwidyIsIm8iLCJyIiwiZCJd')

export const StackSettingsPage = () => {
    const [show, setShow] = useState(!settings.IS_PROD || localStorage.getItem(_k) === 'true')
    const { data: zones } = useWorkspaceZones()
    const { workspaceZone } = useWorkspaceContext()
    const { selectedStack: stack } = useAppContext()
    const { mutateAsync: updateStack } = useUpdateStack()
    const { show: showZoneChangeConfirm } = useConfirmModal()
    const { flags } = useLDFlags()

    const toast = useToast()
    const updateZone = useCallback(
        (stackSid, newZoneSid) => {
            fetchWithAuth(`stacks/${stackSid}/update-zone/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },

                body: JSON.stringify({
                    new_zone_sid: newZoneSid,
                }),
            }).then((response) => {
                if (response.ok) {
                    toast({
                        status: 'success',
                        title: 'Stack zone has successfully been updated',
                    })
                    invalidateStacks()
                } else {
                    toast({
                        status: 'error',
                        title: 'Stack zone updated failed',
                    })
                }
            })
        },
        [toast]
    )

    const getStackZone = useCallback(
        (stack) => {
            return zones.find((zone) => zone._sid === stack.zone_id)
        },
        [zones]
    )

    if (!show) {
        const __p = prompt(_m)

        if (__p === _p) {
            localStorage.setItem(_k, 'true')
            setShow(true)
        }
    }

    if (!show) {
        return null
    }

    return (
        <Box style={{ margin: '30px' }}>
            <div>
                <Section margin="none" padding="none">
                    <h1>{stack?.name}</h1>
                </Section>
            </div>
            <h1>Custom Scripts</h1>
            <Box style={{ marginTop: 10, marginBottom: 30 }}>
                <Text>You must enclose in {'<script>'} tags</Text>
                <Box flex>
                    <AttributeList
                        editable
                        data={stack.options}
                        onChange={(vals) =>
                            updateStack({
                                id: stack._sid,
                                patch: {
                                    options: { ...stack.options, ...vals },
                                },
                            })
                        }
                        fields={[
                            {
                                api_name: 'whitelabel',
                                label: 'Whitelabel',
                                type: 'checkbox',
                            },
                            {
                                api_name: 'custom_scripts',
                                label: 'Scripts to run once',
                                type: 'long_text',
                            },
                            {
                                api_name: 'custom_page_scripts',
                                label: 'Scripts to run per page change',
                                type: 'long_text',
                            },
                        ]}
                    />
                </Box>
            </Box>
            <h1>Optional Features</h1>
            <Box style={{ marginTop: 10, marginBottom: 30 }}>
                <Box flex>
                    <AttributeList
                        editable
                        data={stack.options}
                        onChange={(vals) => {
                            return updateStack({
                                id: stack._sid,
                                patch: {
                                    options: { ...stack.options, ...vals },
                                },
                            })
                        }}
                        fields={[
                            {
                                api_name: 'allow_custom_brand_color',
                                label: 'Allow custom app color',
                                type: 'checkbox',
                            },
                            {
                                api_name: 'disable_invite_emails',
                                label: 'Disable invite emails',
                                type: 'checkbox',
                            },
                        ]}
                    />
                </Box>
            </Box>
            <h1>
                Premium Features{' '}
                <>
                    (App overrides){' '}
                    {JSON.stringify(stack.optional_features) !== JSON.stringify({}) && (
                        <Button
                            onClick={() => {
                                updateStack({
                                    id: stack._sid,
                                    patch: {
                                        optional_features: {},
                                    },
                                })
                            }}
                        >
                            Clear All
                        </Button>
                    )}
                </>
            </h1>
            <Box style={{ marginTop: 10, marginBottom: 30 }}>
                <Box flex>
                    <AttributeList
                        editable
                        data={stack.combined_optional_features}
                        onChange={(vals) =>
                            updateStack({
                                id: stack._sid,
                                patch: {
                                    optional_features: {
                                        ...stack.optional_features,
                                        ...vals,
                                    },
                                },
                            })
                        }
                        fields={[
                            {
                                api_name: 'custom_scripts',
                                label: 'Custom Scripts',
                                type: 'checkbox',
                            },
                            {
                                api_name: 'custom_css',
                                label: 'Custom CSS',
                                type: 'checkbox',
                            },
                            {
                                api_name: 'custom_domain',
                                label: 'Custom domain',
                                type: 'checkbox',
                            },
                        ]}
                    />
                </Box>
            </Box>

            <h1>Portal/Zone settings</h1>
            <Box style={{ marginTop: 10, marginBottom: 30 }}>
                Move Stack to a different zone <br />
                <Text size="fontS">
                    (Moving from a portal zone to an internal zone is not supported)
                </Text>
                <Box flex>
                    <Dropdown>
                        <DropdownButton
                            variant="secondary"
                            size="xs"
                            endIcon={{ name: 'ChevronDown' }}
                        >
                            {getStackZone(stack).display_name} ({getStackZone(stack).type})
                        </DropdownButton>
                        <DropdownContent align="end">
                            {/*right now listing zones can return zones in other accounts, we want to
                                             only show zones in current account*/}
                            {zones.map((zone) => (
                                <DropdownItem
                                    key={zone._sid}
                                    label={`${zone.display_name} (${zone.type})`}
                                    multiSelect
                                    checked={zone._sid === workspaceZone._sid}
                                    onCheckedChange={(checked) => {
                                        if (checked) {
                                            showZoneChangeConfirm({
                                                title: `Update ${stack.name}'s portal?`,
                                                endUser: true,
                                                message: () => (
                                                    <Text>
                                                        Are you sure you want to update the zone for{' '}
                                                        <b>{stack.name}</b> to{' '}
                                                        <b>{zone.display_name}</b>?
                                                        {flags?.permanentLoginLinks && (
                                                            <>
                                                                <br />
                                                                <br />
                                                                Existing permanent login links will
                                                                become invalid and new ones will be
                                                                generated.
                                                            </>
                                                        )}{' '}
                                                    </Text>
                                                ),
                                                onConfirm: (modal) => {
                                                    updateZone(stack._sid, zone._sid)
                                                    modal.toggle()
                                                },
                                            })
                                        }
                                    }}
                                />
                            ))}
                        </DropdownContent>
                    </Dropdown>
                </Box>
            </Box>
        </Box>
    )
}
