import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewHeaderState() {
    const { header } = useDetailViewContext()

    const headerType = header?.type

    return useMemo(() => ({ headerType }), [headerType])
}
