import { useEffect, useRef } from 'react'

import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { ListViewUrlParams } from './useListViewUrlParams'

export function useSyncListViewUrlParams(
    onLoad: (urlParams: ListViewUrlParams) => void,
    onSync: (urlParams: ListViewUrlParams) => void
) {
    const { urlParams } = useListViewContext()

    const urlParamsRef = useRef(urlParams)
    urlParamsRef.current = urlParams

    // Deserialize url params on the initial load.
    useEffect(() => {
        const urlParams = urlParamsRef.current
        if (!urlParams) return

        onLoad(urlParams)
    }, [onLoad])

    // Sync state with the url parameters.
    useEffectOnlyOnUpdate(() => {
        const urlParams = urlParamsRef.current
        if (!urlParams) return

        requestAnimationFrame(() => {
            onSync(urlParams)
        })
    }, [onSync])
}
