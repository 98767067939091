// @ts-strict-ignore
import { useCallback, useMemo, useRef, useState } from 'react'

import { getUrl } from 'app/UrlService'
import { useObjects } from 'data/hooks/objects'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

import { getAvatarFromUserRecord } from './useUserRecordLinksAvatars'

type UseRecordLinksAttributeDisplayStateProps = {
    field: FieldDto
    value?: string | string[]
    maxLinks?: number
    maxItemLength?: number
    dereferencedRecords?: RecordDto[]
    isLoading?: boolean
    showOnlyCount?: boolean
}

type RecordValue = {
    name: string
    url: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

export function useRecordLinksAttributeDisplayState({
    field,
    value,
    dereferencedRecords = [],
    maxLinks,
    maxItemLength,
    isLoading,
    showOnlyCount = false,
}: UseRecordLinksAttributeDisplayStateProps) {
    const memoizedField = useDeepEqualsMemoValue(field)
    const memoizedValue = useDeepEqualsMemoValue(value)

    const { data: objects = [] } = useObjects()
    const memoizedObjects = useDeepEqualsMemoValue(objects)

    const usersObject = useStackerUsersObject()

    const records = useMemo(() => {
        if (isLoading) {
            const placeholderData = getPlaceholderValue(memoizedField)
            return placeholderData
        }

        if (!memoizedValue) return []

        return convertLinksToRecordValues(
            memoizedValue,
            dereferencedRecords,
            memoizedObjects,
            maxItemLength,
            usersObject
        )
    }, [
        memoizedValue,
        memoizedField,
        isLoading,
        dereferencedRecords,
        memoizedObjects,
        maxItemLength,
        usersObject,
    ])

    const limitedRecords = useMemo(() => {
        if (showOnlyCount) return []
        if (!maxLinks || records.length <= maxLinks) return records

        return records.slice(0, maxLinks)
    }, [maxLinks, records, showOnlyCount])

    const overflowingRecords = useMemo(() => {
        if (showOnlyCount) return records
        if (!maxLinks || records.length <= maxLinks) return []

        return records.slice(maxLinks)
    }, [maxLinks, records, showOnlyCount])

    const overflowingRecordsCountLabel = formatOverflowLabel(overflowingRecords, showOnlyCount)

    const [isOverflowPopupOpen, setOverflowPopupOpen] = useState(false)

    const isOverflowOpenedByHover = useRef(false)

    const onOverflowPopupOpenChange = useCallback(
        (isOpen: boolean) => {
            if (isOpen) {
                isOverflowOpenedByHover.current = false
            }

            setOverflowPopupOpen(isOpen)
        },
        [setOverflowPopupOpen]
    )

    const onOverflowLabelMouseEnter = useCallback(() => {
        isOverflowOpenedByHover.current = true
        setOverflowPopupOpen(true)
    }, [setOverflowPopupOpen])

    const onOverflowLabelMouseLeave = useCallback(() => {
        setOverflowPopupOpen(false)
    }, [setOverflowPopupOpen])

    const onOverflowLabelCloseAutoFocus = useCallback((e: Event) => {
        // Don't focus on the trigger if we opened the popup by hovering.
        if (isOverflowOpenedByHover.current) {
            e.preventDefault()
        }
    }, [])

    const objectSid = field.link_target_object_id
    const isUsersObject = objectSid === usersObject?._sid

    const users = useMemo(() => {
        if (!isUsersObject) return []

        return records.map((r) => ({ ...r.avatar!, to: r.url }))
    }, [isUsersObject, records])

    const columnCount = limitedRecords.length
    let gridTemplateColumns = `repeat(${columnCount}, minmax(0, max-content))`
    if (!!overflowingRecordsCountLabel) {
        gridTemplateColumns = `${gridTemplateColumns} minmax(0, max-content)`
    }

    return useMemo(
        () => ({
            records: limitedRecords,
            overflowingRecordsCountLabel,
            overflowingRecords,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelCloseAutoFocus,
            isUsersObject,
            users,
            gridTemplateColumns,
        }),
        [
            limitedRecords,
            overflowingRecordsCountLabel,
            overflowingRecords,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelCloseAutoFocus,
            isUsersObject,
            users,
            gridTemplateColumns,
        ]
    )
}

function convertLinksToRecordValues(
    links: string | string[],
    dereferencedRecords: RecordDto[],
    objects: ObjectDto[],
    maxItemLength?: number,
    usersObject?: ObjectDto
): RecordValue[] {
    const dereferencedRecordsMap = dereferencedRecords.reduce((acc, record) => {
        return acc.set(record._sid, record)
    }, new Map<string, RecordDto>())

    const objectsMap = objects.reduce((acc, object) => {
        return acc.set(object._sid, object)
    }, new Map<string, ObjectDto>())

    const linksList = Array.isArray(links) ? links : [links]
    linksList.sort((a, b) => a.localeCompare(b))

    return linksList.reduce((acc, link) => {
        const record = dereferencedRecordsMap.get(link)
        if (!record) return acc

        const object = objectsMap.get(record._object_id)

        const recordPrimary = record?._primary || ''
        let name = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()
        if (maxItemLength) {
            name = truncateText(name, maxItemLength)
        }

        // If the object is truthy, this implies it's a link to a cross-app table, in those cases we want the record to
        // not be a link to anywhere. (Note that empty string is not sufficient)
        const url = object ? getUrl(`${object.url}/view/${record._sid}`) : '#'

        const avatar = getAvatarFromUserRecord(record, usersObject)

        return [
            ...acc,
            {
                name,
                url,
                avatar,
            },
        ]
    }, [])
}

function getPlaceholderValue(field: FieldDto): RecordValue[] {
    if (field.type === 'lookup') {
        return [
            {
                name: 'Record 1',
                url: 'https://stackerhq.com/record-1',
            },
        ]
    }

    return [
        {
            name: 'Record 1',
            url: 'https://stackerhq.com/record-1',
        },
        {
            name: 'Record 2',
            url: 'https://stackerhq.com/record-2',
        },
        {
            name: 'Record 3',
            url: 'https://stackerhq.com/record-3',
        },
    ]
}

function formatOverflowLabel(overflowingOptions: RecordValue[], showOnlyCount?: boolean) {
    if (showOnlyCount && !!overflowingOptions.length) {
        return overflowingOptions.length.toString()
    }

    if (!overflowingOptions?.length) {
        return ''
    }

    return `+${overflowingOptions.length}`
}
