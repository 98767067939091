import React from 'react'

import { UserAttributeDisplay } from 'features/views/attributes/UserAttributeDisplay'
import { UserAttributeTag } from 'features/views/attributes/UserAttributeTag'

import { BaseAttribute } from './BaseAttribute'
import { CardViewAttributeComponent } from './types'

const MAX_LINES = 1
const MAX_LENGTH = 40
const MAX_LENGTH_TAG = 20

type UserAttributeProps = {}

export const UserAttribute: CardViewAttributeComponent<UserRefDto, UserAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const { field, type, rawOptions } = attribute

    const isTitle = type === 'title'
    const isFooter = type === 'footer'

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayStyle === 'tags' ? (
                <UserAttributeTag
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={MAX_LENGTH_TAG}
                    isLoading={isLoading}
                    showIcon={showIcon}
                />
            ) : (
                <UserAttributeDisplay
                    size={{
                        mobile: isTitle ? 'm' : 's',
                        tablet: 'm',
                    }}
                    value={value}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={isFooter ? undefined : MAX_LENGTH}
                    isLoading={isLoading}
                    showSingleUserName={!rawOptions.hideUserName}
                />
            )}
        </BaseAttribute>
    )
}
