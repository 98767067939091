import { QueryKey, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import { Agent } from './types'

const LIST_NAME = 'useAgents'
const ENDPOINT = 'agents/'

function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
type OptionsType = UseQueryOptions<Agent[], unknown, Agent[], QueryKey>
export function useAgents(options: OptionsType = {}) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as OptionsType),
        keepPreviousData: true,
        ...options,
        enabled,
    }
    const queryResult = useQuery<Agent[]>(useQueryKey(), ENDPOINT, query_config)

    return queryResult
}

export function useCreateAgent() {
    return useCreateItem<Agent>(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateAgents()
            },
        },
        undefined,
        false
    )
}

export function useUpdateAgent() {
    return useUpdateItem<Agent>(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateAgents()
        },
    })
}

export function useDeleteAgent() {
    return useDeleteItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateAgents()
        },
    })
}

export function invalidateAgents() {
    return queryClient.invalidateQueries([LIST_NAME, getCurrentStackId()])
}
