import Nango from '@nangohq/frontend'

import settings from 'app/settings'

type AuthStrategyMetadataMap = {
    nango: NangoMetadata
}

export type AuthStrategy = keyof AuthStrategyMetadataMap

type NangoMetadata = {
    providerId: string
    connectionId: string
    authScopes: string[]
    hmac: string
}

export type ConnectionAuthStrategy<T extends AuthStrategy> = {
    auth: (metadata: AuthStrategyMetadataMap[T]) => Promise<void>
}

export function useConnectionAuthStrategy<T extends AuthStrategy>(
    strategy: T
): ConnectionAuthStrategy<T> {
    switch (strategy) {
        case 'nango':
            return nangoStrategy

        default:
            throw new Error(`Unknown connection auth strategy: ${strategy}`)
    }
}

const nangoStrategy: ConnectionAuthStrategy<'nango'> = {
    auth: async (metadata: NangoMetadata) => {
        const nango = new Nango({ publicKey: settings.NANGO_KEY })

        await nango.auth(metadata.providerId, metadata.connectionId, {
            params: {},
            user_scope: metadata.authScopes,
            hmac: metadata.hmac,
        })
    },
}
