import React, { forwardRef } from 'react'

import classNames from 'classnames'
import { icons } from 'lucide-react'

import { Box } from 'ui/components/Box'
import { StandardComponentProps, transformStandardProps } from 'ui/helpers/styles'
import {
    ResponsiveValue,
    ResponsiveValuesFromProps,
    useResponsiveValue,
} from 'ui/styling/helpers/useResponsiveValue'

import { iconNames as hugeIcons } from './hugeicons'

import { IconStyles, IconVariants } from './Icon.css'

export type IconFamily = 'lucide' | 'hugeicons'

type LucideIcons = keyof typeof icons
type HugeIcons = (typeof hugeIcons)[number]

type IconNames = {
    lucide: LucideIcons
    // We are using the icon font instead of the React library, because
    // importing the entire library would dramatically increase the bundle size.
    // Just use the class names directly, without the `hgi-` prefix.
    hugeicons: HugeIcons
}

export type IconName<F extends IconFamily = 'lucide'> = IconNames[F]

export type IconNameVariantType<F extends IconFamily = 'lucide'> = {
    name: IconName<F>
    family?: F
}

type IconRef<F extends IconFamily> = F extends 'lucide' ? SVGSVGElement : HTMLElement

type BaseElementProps<F extends IconFamily> = F extends 'lucide'
    ? React.ComponentPropsWithoutRef<'svg'>
    : React.ComponentPropsWithoutRef<'i'>

type IconPropsBase<F extends IconFamily> = BaseElementProps<F> &
    StandardComponentProps &
    IconNameVariantType<F> &
    IconVariants & {}

type IconProps<F extends IconFamily> = Omit<IconPropsBase<F>, 'size'> &
    ResponsiveValuesFromProps<IconPropsBase<F>, 'size'>

const IconInner = <F extends IconFamily>(
    { name, size = 's', family = 'lucide' as F, ...props }: IconProps<F>,
    ref: React.ForwardedRef<IconRef<F>>
) => {
    const { className, ...transformedProps } = transformStandardProps(props)
    const effectiveSize = useResponsiveValue(size as ResponsiveValue<IconPropsBase<F>['size']>)

    if (family === 'lucide') {
        const LucideIcon = icons[name as LucideIcons]

        return (
            <LucideIcon
                absoluteStrokeWidth={true}
                {...(transformedProps as React.ComponentPropsWithoutRef<typeof IconInner<F>>)}
                className={classNames(
                    IconStyles.styleFunction({ size: effectiveSize, family }),
                    className
                )}
                ref={ref as React.ForwardedRef<SVGSVGElement>}
            />
        )
    } else if (family === 'hugeicons') {
        const hugeiconsFontStyle = 'hgi-solid'
        const hugeiconsClassName = `hgi-${name}`

        return (
            <Box
                as="i"
                {...(transformedProps as React.ComponentPropsWithoutRef<typeof IconInner<F>>)}
                className={classNames(
                    IconStyles.styleFunction({ size: effectiveSize, family }),
                    className,
                    hugeiconsFontStyle,
                    hugeiconsClassName
                )}
                ref={ref as React.ForwardedRef<HTMLElement>}
            />
        )
    }

    return null
}

export const Icon = forwardRef(IconInner) as <F extends IconFamily = 'lucide'>(
    props: IconProps<F> & { ref?: React.ForwardedRef<IconRef<F>> }
) => ReturnType<typeof IconInner>
